import { setLoaded, setLoading } from 'utils/immutable/map';

export function removeRequest({ options, state, action }) {
    const { pluralName } = options;

    return state
        .set(`${pluralName}ById`, setLoading(state
            .get(`${pluralName}ById`), action.payload.id));
}

export function removeFailure({ options, state, action }) {
    const { pluralName } = options;

    return state
        .set(`${pluralName}ById`, setLoaded(state
            .get(`${pluralName}ById`), action.payload.id));
}

export function removeSuccess({ options, state, action }) {
    const { pluralName } = options;

    const removedId = action.payload.id;

    return state
        .deleteIn([`${pluralName}ById`, removedId])
        .set(`${pluralName}Count`, state.get(`${pluralName}Count`) - 1);
}
