import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import type { CustomerRequestComplaintGetDto } from './customerRequestComplaint.type';

export class CustomerRequestComplaintActions {
    private static instance: CustomerRequestComplaintActions | undefined;

    private constructor(private readonly ajaxService: AjaxService, private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>) {
        this.getById = this.getById.bind(this);
    }

    static getInstance(ajaxService: AjaxService, errorHandlers: ReturnType<typeof errorHandlersHelper>): CustomerRequestComplaintActions {
        if (!CustomerRequestComplaintActions.instance) {
            CustomerRequestComplaintActions.instance = new CustomerRequestComplaintActions(ajaxService, errorHandlers);
        }

        return CustomerRequestComplaintActions.instance;
    }

    async getById(id: string): Promise<CustomerRequestComplaintGetDto> {
        const response: { data: CustomerRequestComplaintGetDto } = await this.ajaxService.get({
            url: URLS.CUSTOMER_REQUEST_COMPLAINT.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.get,
            },
            onError: this.errorHandlers.get,
        });

        return response.data;
    }
}
