
export const mapCreateChannelPreviewToApi = ({
    previewTypeCode,
    priority
}) => ({
    preview_type_code: previewTypeCode,
    priority: parseInt(priority)
});

export const mapGetPreviewTypesList = ({ results, total }) => ({
    items: results.map(({
        id,
        preview_type_code: previewTypeCode,
        created_at: createdAt,
        priority,

    }) => ({
        id,
        previewTypeCode,
        createdAt,
        priority,
    })),
    totalItems: total
});
