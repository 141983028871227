import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AlertIcon } from 'components/Icons/Icons';
import {
    ContainerStyled,
    MessageStyled,
    TitleStyled,
} from 'components/ErrorBoundary/errorBoundary.styles';
import Button from 'components/Button/button';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            errorMessage: '',
            isDynamicModuleError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        this.setState({
            errorMessage: error.message,
            isDynamicModuleError:
                (error instanceof TypeError &&
                    error.message.startsWith(
                        'Failed to fetch dynamically imported module',
                    )) ||
                error?.message?.startsWith(
                    'error loading dynamically imported module',
                ),
        });
    }

    render() {
        const { children, t } = this.props;
        const { hasError, errorMessage, isDynamicModuleError } = this.state;

        return hasError ? (
            <ContainerStyled spacing={3}>
                {isDynamicModuleError ? (
                    <>
                        <TitleStyled variant="h6">
                            {t('alerts.dynamicLoadError', { ns: 'app' })}
                        </TitleStyled>
                        <Button onClick={() => location.reload()}>
                            {t('actions.refreshPage', { ns: 'app' })}
                        </Button>
                    </>
                ) : (
                    <>
                        <TitleStyled variant="h6">
                            <AlertIcon
                                color="error"
                                fontSize="large"
                            />
                        </TitleStyled>
                        <TitleStyled variant="h6">
                            {t('common:alerts.anErrorOccurred')}
                        </TitleStyled>
                        <MessageStyled variant="p">
                            {errorMessage}
                        </MessageStyled>
                    </>
                )}
            </ContainerStyled>
        ) : (
            children
        );
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    t: PropTypes.func,
};

const ErrorBoundaryWithHOC = withTranslation()(ErrorBoundary);

export default ErrorBoundaryWithHOC;
