import { fromJS, OrderedMap } from 'immutable';
import * as C from './constants';
import { toIdMap } from 'utils/immutable/list';

const initialState = fromJS({
    itemsById: OrderedMap(),
    itemsListComplete: false,
    itemsCount: 0,

    itemsParams: {
        page: 1,
        per_page: 20,
        order: {},
    },

    loaders: {
        itemsTable: false,
    },
});

export default function itemsReducer(state = initialState, action) {
    let itemsListComplete = false;
    let oldItems = [];
    let newItems = [];
    let newItemsById = [];
    let newItemsCount = 0;

    switch (action.type) {
        case C.FETCH_ITEMS.request:
            return state
                .setIn(['loaders', 'itemsTable'], true);
        case C.FETCH_ITEMS.failure:
            return state
                .setIn(['loaders', 'itemsTable'], false);
        case C.FETCH_ITEMS.success:
            itemsListComplete = false;
            oldItems = action.payload.refresh ? OrderedMap() : state.get('itemsById');
            newItems = action.payload.response['hydra:member'] ?
                toIdMap(action.payload.response['hydra:member'])
                :
                {};
            newItemsById = oldItems.merge(newItems);
            newItemsCount = action.payload.response['hydra:totalItems'];
            if (newItems.size === 0 || newItemsById.size >= newItemsCount) {
                itemsListComplete = true;
            }

            return state
                .setIn(['itemsParams', 'page'], action.payload.nextPage)
                .set('itemsById', newItemsById)
                .set('itemsCount', newItemsCount)
                .set('itemsListComplete', itemsListComplete)
                .setIn(['loaders', 'itemsTable'], false);

        default:
            return state;
    }
}
