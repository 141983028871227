import { mapToApi, mapFromApi, mapSeparatorToApi } from '../mappers/generators';
import { ContentTypeJsonHeader } from '../../constants';

class SkuGenerator {
    constructor(SkuService) {
        this.skuService = SkuService;
        this.errorHandlers = SkuService.errorHandlers;

        this.create = this.create.bind(this);
        this.getPreviewSkuGenerator = this.getPreviewSkuGenerator.bind(this);
        this.getById = this.getById.bind(this);
    }
    create(generator) {
        const {
            ajax,
            URLS,
        } = this.skuService;
        const onError = this.errorHandlers.post;
        const config = {
            data: mapToApi(generator),
            headers: ContentTypeJsonHeader,
        };
        const url = URLS.CREATE_SKU_GENERATOR;

        return ajax
            .post({ url, config, onError })
            .then((results) => results.data);
    }
    getPreviewSkuGenerator({ generator, productId }) {
        const {
            ajax,
            URLS,
        } = this.skuService;
        const config = {
            params: {
                template: JSON.stringify(generator.template),
                separator: mapSeparatorToApi(generator.separator),
            },
        };

        const url = URLS.PREVIEW_SKU_GENERATOR.replace('{id}', productId);

        return ajax
            .get({ url, config })
            .then((results) => results);
    }
    getById({ id }) {
        const { ajax, URLS } = this.skuService;
        const url = URLS.GET_SKU_GENERATOR.replace('{id}', id);
        const errorConfig = {
            throwError: this.errorHandlers.get,
            addGenericAlert: false,
        };

        return ajax
            .get({ url, errorConfig })
            .then(({ data }) => {
                return mapFromApi(data);
            });
    }
}

export default SkuGenerator;
