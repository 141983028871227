import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Login from 'modules/app/components/LoginContent/loginWithState';
import ChangePassword from 'modules/app/components/ChangePasswordForm/changePasswordWithState';
import ConfirmChangePassword from 'modules/app/components/ConfirmChangePassword/confirmChangePasswordWithState';
import ConfirmRequestSend from 'modules/app/components/ConfirmRequestSend/ConfirmRequestSend';
import {
    ContentCardStyled,
    RootContainerStyled,
    TitleStyled,
} from './entryPage.styles';

class EntryPage extends React.PureComponent {
    render() {
        return (
            <RootContainerStyled>
                <ContentCardStyled>
                    <TitleStyled
                        variant="h4"
                        noWrap
                        gutterBottom
                        align="center"
                    >
                        Arte
                    </TitleStyled>
                    <Switch>
                        <Route
                            path="/change-password"
                            component={ChangePassword}
                        />
                        <Route
                            path="/confirm-password-change"
                            component={ConfirmChangePassword}
                        />
                        <Route
                            path="/confirm-request-send"
                            component={ConfirmRequestSend}
                        />
                        <Route component={Login} />
                    </Switch>
                </ContentCardStyled>
            </RootContainerStyled>
        );
    }
}

const EntryPageWithHOC = withRouter(EntryPage);

export default EntryPageWithHOC;
