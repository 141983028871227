import * as S from 'access/sections';
import * as A from 'access/accesses';
import { ACCEPT } from 'access/voteTypes';

export const ROLE_CONTRACTOR_PRICE_LIST_MANAGE = {
    section: S.ARM_CONTRACTOR,
    type: 'display',
    votes: {
        [A.DROPSHIPPING.MODULE]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.PRICE_LIST.FETCH]: ACCEPT,
    },
};
