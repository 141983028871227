import get from 'lodash/get';

export const mapPropertyFromApi = (property = {}) => ({
    id: get(property, 'id', ''),
    code: get(property, 'code', ''),
    name: get(property, 'name', ''),
    shortName: get(property, 'short_name', ''),
    description: get(property, 'description', ''),
    type: get(property, 'type', ''),
    options: get(property, 'options', {}),
    usageCounter: get(property, 'usage_counter', 0),
});
