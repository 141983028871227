import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { PapersIcon } from 'components/Icons/Icons';

const ProductionOrdersTablePage = lazy(() => import('../pages/ProductionOrdersTablePage'));
const ProductionOrdersTableContainer = lazy(() => import('../containers/ProductionOrdersTableContainer'));
const ProductionOrdersDetailsContainer = lazy(() => import('../containers/ProductionOrdersDetailsContainer'));
const SearchPrinterContainer = lazy(
    () => import('../containers/SearchPrinterContainer'),
);

export const productionRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            name: 'searchEngine',
            path: PATHS.SEARCH,
            component: ProductionOrdersTablePage,
        },
        {
            exact: true,
            name: 'searchProduction',
            path: PATHS.SEARCH_PRODUCTION,
            component: ProductionOrdersTableContainer,
        },
        {
            exact: true,
            name: 'searchPrinter',
            path: PATHS.SEARCH_PRINTER,
            component: SearchPrinterContainer,
        },
        {
            exact: true,
            name: null,
            path: PATHS.DETAILS,
            component: ProductionOrdersDetailsContainer,
        },
    ],
    icon: PapersIcon,
};
