import { Printer } from './actions/Printer';
import { User } from './actions/User';
import { Workplace } from './actions/Workplace';

export class WorkplacesService {
    private constructor({ services, URLS }) {
        this.user = new User(services, { URLS });
        this.workplace = new Workplace(services, { URLS });
        this.printer = new Printer(services, { URLS });
    }

    private static instance: WorkplacesService | undefined;

    public static getInstance({ services, URLS }): WorkplacesService {
        if (!WorkplacesService.instance) {
            WorkplacesService.instance = new WorkplacesService({
                services,
                URLS,
            });
        }

        return WorkplacesService.instance;
    }
}
