import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const RowButtonStyled = styled(IconButton)`
  margin: 0 ${({ theme }) => theme.spacing(1)};
`;
RowButtonStyled.displayName = 'RowButton';

export const MainContainerStyled = styled('div')`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
MainContainerStyled.displayName = 'MainContainer';

export const TopPanelStyled = styled('div')`
  margin-top: 32px;
  width: 100%;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 180px auto;
  justify-content: space-between;
  align-items: center;
`;
TopPanelStyled.displayName = 'TopPanel';

export const RibbonStyled = styled('div')`
  margin: 10px 0;
`;
RibbonStyled.displayName = 'Ribbon';

export const TableWrapperStyled = styled('div')`
  margin: 15px 0;
`;
TableWrapperStyled.displayName = 'TableWrapper';
