import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function FirstPageIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -0.5 9 9"
            { ...props }
        >
            <path
                d="M5.77593 7.43311C5.83899 7.43311 5.89485 7.39295 5.91888 7.33176C5.9423 7.26993 5.92849 7.19981 5.88344 7.15328L2.91684 4.00505L5.88344 0.856823C5.92369 0.816029 5.9399 0.754836 5.92549 0.698106C5.91167 0.640738 5.86963 0.596118 5.81557 0.581457C5.76211 0.566159 5.70445 0.58337 5.66601 0.626077L2.48197 4.00505L5.66601 7.38402C5.69484 7.4159 5.73448 7.43311 5.77593 7.43311ZM3.77757 7.43311C3.84004 7.43311 3.8959 7.39295 3.91992 7.33176C3.94335 7.26993 3.92953 7.19981 3.88449 7.15328L0.917888 4.00505L3.88449 0.856823C3.92473 0.816028 3.94095 0.754836 3.92653 0.698106C3.91272 0.640737 3.87067 0.596118 3.81661 0.581457C3.76316 0.566159 3.70549 0.58337 3.66705 0.626077L0.483018 4.00505L3.66705 7.38402C3.69588 7.41589 3.73553 7.43311 3.77757 7.43311Z"
            />
        </SvgIcon>
    );
}

FirstPageIcon.defaultProps = {
    titleAccess: 'first-page-icon',
};

FirstPageIcon.propTypes = svgIconPropTypes;
