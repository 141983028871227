import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';
export * from './pdpRoles';
export * from './armRoles';
export * from './optimaRoles';
export * from './productionRoles';
export * from './stockRoles';
export * from './salesRoles';
export * from './postSalesRoles';
export * from './salesChannelsRoles';
export * from './skuRoles';
export * from './translationsRoles';
export * from './printSpec';
export * from './discountsRoles';
export * from './productsRoles';
export * from './eanRoles';
export * from './boxesRoles';
export * from './userServiceRoles';
export * from './customerRequestsRoles';
export * from './prodisRoles';
export * from './priceListsRoles';

export const ROLE_ROOT = {
    section: S.ROOT,
    type: 'main',
    votes: {
        ['role_root']: ACCEPT,
    },
};

export const ROLE_STABLO = {
    section: S.STABLO,
    type: 'main',
    votes: {
        [A.STABLO.MODULE]: ACCEPT,
        [A.STABLO.PATHS.PRODUCT_CATALOG]: ACCEPT,
        [A.STABLO.PATHS.PRODUCT_POSSIBILITIES]: ACCEPT,
    },
};

export const ROLE_WORKPLACES_PRINT = {
    section: S.ROOT,
    type: 'main',
    votes: {
        ['role_workplaces_print']: ACCEPT,
    },
};
