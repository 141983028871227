import type { StockService } from '../../StockService';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { AlertService } from '../../../alert/AlertService';
import type { default as i18next } from 'i18next';
import type { uploadServicesFactory } from '../../uploadServices/uploadServicesFactory';
import type { default as RequestCache } from '../../../requestCache/RequestCache';
import type { DeepPartial } from 'utils/types/deepPartial';
import type {
    IPreviewSetType,
    IPreviewSetTypesQuery,
    IProductSetPreviewTypeCreateInput,
    TPreviewSetTypeListResponse,
    TProductSetPreviewTypeUpdateInput,
} from './productSetPreviewTypes.type';
import { ApplicationJsonHeaders, HttpPatchHeaders } from 'services/constants';
import { paramsSerializer } from 'services/utils/utils';
import { AxiosResponse } from 'axios';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';

export class ProductSetPreviewTypesActions {
    private static instance: ProductSetPreviewTypesActions | undefined;

    private readonly asyncRequestPaginator: AsyncRequestPaginator<
        IPreviewSetType,
        IPreviewSetType
    >;

    private constructor(
        private readonly stockService: StockService,
        private readonly services: {
            ajax: AjaxService;
            alert: AlertService;
            translator: typeof i18next;
            upload: ReturnType<typeof uploadServicesFactory>;
            requestCache: RequestCache;
        },
    ) {
        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.getById = this.getById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.getMore = this.getMore.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMore,
            (data) => data,
        );

        this.getFullList = this.getFullList.bind(this);
    }

    static getInstance(
        stockService: StockService,
        services: {
            ajax: AjaxService;
            alert: AlertService;
            translator: typeof i18next;
            upload: ReturnType<typeof uploadServicesFactory>;
            requestCache: RequestCache;
        },
    ): ProductSetPreviewTypesActions {
        if (!ProductSetPreviewTypesActions.instance) {
            ProductSetPreviewTypesActions.instance =
                new ProductSetPreviewTypesActions(stockService, services);
        }

        return ProductSetPreviewTypesActions.instance;
    }

    getMore(
        params: DeepPartial<IPreviewSetTypesQuery> = {},
    ): Promise<AxiosResponse<TPreviewSetTypeListResponse>> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.GET_PRODUCT_SET_PREVIEW_TYPES_LIST;

        const config = {
            headers: ApplicationJsonHeaders,
            params,
            paramsSerializer,
        };

        return ajax.get({
            url,
            config,
            errorConfig: {
                onError: errorHandlers.get,
            },
            onError: errorHandlers.get,
        });
    }

    async getFullList(params: DeepPartial<IPreviewSetTypesQuery>) {
        const { items } =
            await this.asyncRequestPaginator.fetchFullList(params);

        return items;
    }

    async getList(
        params: DeepPartial<IPreviewSetTypesQuery>,
    ): Promise<TPreviewSetTypeListResponse> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.GET_PRODUCT_SET_PREVIEW_TYPES_LIST;

        const config = {
            headers: ApplicationJsonHeaders,
            params,
            paramsSerializer,
        };

        const { data }: { data: TPreviewSetTypeListResponse } = await ajax.get({
            url,
            config,
            errorConfig: {
                onError: errorHandlers.get,
            },
            onError: errorHandlers.get,
        });

        return data;
    }

    delete(id: IPreviewSetType['id']): Promise<void> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.DELETE_PRODUCT_SET_PREVIEW_TYPE.replace('{id}', id);

        return ajax.delete({
            url,
            errorConfig: {
                onError: errorHandlers.delete,
            },
            onError: errorHandlers.delete,
            config: { headers: ApplicationJsonHeaders },
        });
    }

    async getById(id: IPreviewSetType['id']): Promise<IPreviewSetType> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.GET_PRODUCT_SET_PREVIEW_TYPE.replace('{id}', id);

        const { data }: { data: IPreviewSetType } = await ajax.get({
            url,
            errorConfig: {
                onError: errorHandlers.get,
            },
            onError: errorHandlers.get,
            config: { headers: ApplicationJsonHeaders },
        });

        return data;
    }

    async create(
        payload: IProductSetPreviewTypeCreateInput,
    ): Promise<IPreviewSetType> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.CREATE_PRODUCT_SET_PREVIEW_TYPE;

        const { data }: { data: IPreviewSetType } = await ajax.post({
            url,
            data: payload,
            errorConfig: {
                onError: errorHandlers.post,
            },
            onError: errorHandlers.post,
            config: { headers: ApplicationJsonHeaders },
        });

        return data;
    }

    async edit(
        id: IPreviewSetType['id'],
        payload: TProductSetPreviewTypeUpdateInput,
    ): Promise<IPreviewSetType> {
        const { URLS, errorHandlers } = this.stockService;
        const { ajax } = this.services;
        const url = URLS.EDIT_PRODUCT_SET_PREVIEW_TYPE.replace('{id}', id);

        const { data }: { data: IPreviewSetType } = await ajax.patch({
            url,
            data: payload,
            errorConfig: {
                onError: errorHandlers.patch,
            },
            onError: errorHandlers.patch,
            config: { headers: HttpPatchHeaders },
        });

        return data;
    }
}
