import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function SaveIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <path
                d="M1.24003 0.280029C0.713484 0.280029 0.280029 0.713484 0.280029 1.24003V12.76C0.280029 13.2866 0.713484 13.72 1.24003 13.72H12.76C13.2866 13.72 13.72 13.2866 13.72 12.76V3.22378C13.72 3.13892 13.6863 3.05753 13.6263 2.99753L11.0025 0.373779C10.9425 0.313768 10.8611 0.280046 10.7763 0.280029H1.24003ZM1.24003 0.920029H2.84003V4.76003C2.84003 5.28658 3.27348 5.72003 3.80003 5.72003H9.88003C10.4066 5.72003 10.84 5.28658 10.84 4.76003V1.11628L13.08 3.35628V12.76C13.08 12.9375 12.9375 13.08 12.76 13.08H11.16V8.28003C11.16 7.75348 10.7266 7.32003 10.2 7.32003H3.80003C3.27348 7.32003 2.84003 7.75348 2.84003 8.28003V13.08H1.24003C1.06257 13.08 0.920029 12.9375 0.920029 12.76V1.24003C0.920029 1.06257 1.06257 0.920029 1.24003 0.920029ZM3.48003 0.920029H10.2V4.76003C10.2 4.93748 10.0575 5.08003 9.88003 5.08003H3.80003C3.62258 5.08003 3.48003 4.93748 3.48003 4.76003V0.920029ZM8.28003 1.56003C8.19516 1.56004 8.11377 1.59375 8.05377 1.65376C7.99376 1.71377 7.96004 1.79516 7.96003 1.88003V4.12003C7.96004 4.2049 7.99376 4.28628 8.05377 4.34629C8.11377 4.4063 8.19516 4.44002 8.28003 4.44003H9.24003C9.3249 4.44002 9.40628 4.4063 9.46629 4.34629C9.5263 4.28628 9.56002 4.2049 9.56003 4.12003V1.88003C9.56002 1.79516 9.5263 1.71377 9.46629 1.65376C9.40628 1.59375 9.3249 1.56004 9.24003 1.56003H8.28003ZM8.60003 2.20003H8.92003V3.80003H8.60003V2.20003ZM3.80003 7.96003H10.2C10.3775 7.96003 10.52 8.10258 10.52 8.28003V13.08H3.48003V8.28003C3.48003 8.10258 3.62258 7.96003 3.80003 7.96003ZM1.56003 11.8V12.44H2.20003V11.8H1.56003ZM11.8 11.8V12.44H12.44V11.8H11.8Z"
            />
        </SvgIcon>
    );
}

SaveIcon.defaultProps = {
    titleAccess: 'save-icon',
};

SaveIcon.propTypes = svgIconPropTypes;
