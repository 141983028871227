import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

export const ROLE_CAMUNDA_CUSTOMER_REQUESTS_START = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'display',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.CREATE_NEW]: ACCEPT,
    },
};

export const CUSTOMER_REQUESTS_START = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'display',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.CREATE_NEW]: ACCEPT,
    },
};
