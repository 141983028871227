import styled from '@emotion/styled';
import { DownloadIcon } from 'components/Icons/Icons';

export const DownloadCellStyled = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
DownloadCellStyled.displayName = 'DownloadCell';

export const DownloadIconStyled = styled(DownloadIcon)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
DownloadIconStyled.displayName = 'DownloadIcon';
