import { takeLatest, takeEvery, put } from 'redux-saga/effects';
import * as C from './constants';
import * as A from './actions';
import { push } from 'react-router-redux';
import { presets } from 'modules/production/reducers/productionOrders/saga';

export function* presetsSaga() {
    yield takeLatest(C.FETCH_PRESETS.request, fetchPresets);
    yield takeEvery(C.REMOVE_PRESET.request, removePreset);
    yield takeEvery(C.USE_PRESET, usePreset);
    yield takeEvery(C.DUPLICATE_PRESET.request, duplicatePreset);
}

function* fetchPresets() {
    yield presets.get({
        action: A.fetchPresets,
        alerts: {
            failure: true,
        },
    });
}

function* removePreset(action) {
    const { id } = action.payload;

    yield presets.delete({
        action: A.removePreset,
        path: id,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'production:alerts.presetDeleted',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: { id },
            failure: { id },
        },
    });
}

function* usePreset(action) {
    const preset = action.payload;

    yield put(push(
        {
            search: preset.get('query'),
        }
    ));
}

function* duplicatePreset(action) {
    const preset = action.payload;

    yield presets.post({
        action: A.duplicatePreset,
        data: preset,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'production:alerts.presetDuplicated',
                options: {
                    variant: 'success',
                },
            },
        },
    });
}
