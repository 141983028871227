import { armRouting } from 'modules/arm/routing/armRouting';
import { stabloRouting } from 'modules/stablo/routing/stabloRouting';
import { pdpRouting } from 'modules/pdp/routing/routing';
import Demo from 'modules/demo/routing/getRouting';
import { prodisRouting } from 'modules/prodis/routing/routing';
import { dropshippingRouting } from 'modules/dropshipping/routing/dropshippingRouting';
import { productionRouting } from 'modules/production/routing/productionRouting';
import { salesRouting } from 'modules/sales/routing';
import { postSalesRouting } from 'modules/postSales/configs/routing.config';
import { stockRouting } from 'modules/stock/routing/stockRouting';
import { manageOffersRouting } from 'modules/manageOffers/routing/manageOffersRouting';
import { translationsRouting } from 'modules/translations/routing/translationsRouting';
import { boxesRouting } from 'modules/boxes/routing/boxesRouting';
import { userServiceRouting } from 'modules/userService/routing/userServiceRouting';
import { testHelpersRouting } from 'modules/test-helpers/config/routing.config';
import { checkAccess } from 'access/votingMechanism';

export default function getModules() {
    const isTest = window?.env?.APP_ENV_WARNING === 'test';
    const isStage = window.env?.APP_ENV_WARNING === 'stage';

    let modules = [
        stabloRouting,
        prodisRouting,
        dropshippingRouting,
        pdpRouting,
        armRouting,
        boxesRouting,
        productionRouting,
        salesRouting,
        postSalesRouting,
        stockRouting,
        manageOffersRouting,
        translationsRouting,
        userServiceRouting,
    ];

    modules = modules.filter(
        (module) =>
            (checkAccess(module.name) || module['forceAccess']) &&
            (window.env.APP_ENV && window.env.APP_ENV === 'dev'
                ? true
                : !module['testOnly']),
    );

    if (isTest || isStage) {
        modules.push(Demo());
        modules.push(testHelpersRouting);
    }

    return modules;
}
