import {
    mapIntermediateProductsToApi,
    mapOfferEditToApi,
    mapOfferFromApi,
    mapOfferIntermediateProductsFromApi,
    mapOfferToApi,
} from '../mappers/offer';
import { get } from 'lodash';
import { ApplicationJsonHeaders, HttpPatchHeaders } from '../../constants';
import type { SalesChannelsService } from '../SalesChannelsService';

class Offers {
    private readonly params: SalesChannelsService['OFFERS_PARAMS'];
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];
    private readonly alert: SalesChannelsService['alert'];
    private readonly translator: SalesChannelsService['translator'];
    private readonly ALERTS: SalesChannelsService['ALERT_MESSAGES'];

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.params = salesChannelsService.OFFERS_PARAMS;
        this.errorHandlers = salesChannelsService.errorHandlers;
        this.alert = salesChannelsService.alert;
        this.translator = salesChannelsService.translator;
        this.ALERTS = salesChannelsService.ALERT_MESSAGES;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.deleteById = this.deleteById.bind(this);
        this.edit = this.edit.bind(this);
        this.getIntermediateProducts = this.getIntermediateProducts.bind(this);
        this.editIntermediateProducts =
            this.editIntermediateProducts.bind(this);
        this.bulkArchiveProducts = this.bulkArchiveProducts.bind(this);
    }

    async getById(id) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_OFFER.replace('{id}', id);
        const errorConfig = this.configError('get');

        const { data } = await ajax.get({ url, errorConfig });

        return mapOfferFromApi(data);
    }

    async getList(
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            order?: string | undefined;
            filters?: Record<string, unknown> | undefined;
        } = {},
    ) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            getSortParams,
            getFilterParams,
            paramsSerializer,
        } = this.salesChannelsService;
        const { SORTING_KEYS, FILTERS_KEYS } = this.params;
        const {
            page = 1,
            per_page = DEFAULTS.OFFERS_PER_PAGE,
            order: orderFromUrl,
            filters: filtersFromUrl,
        } = params;

        const url = URLS.GET_OFFERS;
        const sort = getSortParams(SORTING_KEYS, orderFromUrl);
        const filters = getFilterParams(FILTERS_KEYS, filtersFromUrl) || {};

        if (filtersFromUrl?.search) filters.name = filtersFromUrl.search;
        const errorConfig = this.configError('get');
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                ...filters,
            },
            headers: ApplicationJsonHeaders,
            paramsSerializer,
        };

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            items: get(data, 'results', []).map((offer) =>
                mapOfferFromApi(offer),
            ),
            totalItems: get(data, 'total', 0),
        };
    }

    async deleteById(id) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.DELETE_OFFER.replace('{id}', id);
        const errorConfig = this.configError('delete');

        await ajax.delete({ url, errorConfig });
        this.alert.addSuccess({
            message: this.translator.t(this.ALERTS.OFFER_DELETED),
        });
    }

    async create(offerData) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.POST_OFFER;
        const errorConfig = this.configError('post');
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const offer = await ajax.post({
            url,
            errorConfig,
            config,
            data: mapOfferToApi(offerData),
        });

        return mapOfferToApi(offer);
    }

    async edit(id, offerData) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.PATCH_OFFER.replace('{id}', id);
        const errorConfig = this.configError('patch');
        const config = {
            headers: HttpPatchHeaders,
        };

        const offer = await ajax.patch({
            url,
            errorConfig,
            config,
            data: mapOfferEditToApi(offerData),
        });

        return mapOfferToApi(offer);
    }

    async getIntermediateProducts(id) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_OFFER_INTERMEDIATE_PRODUCTS.replace('{id}', id);
        const errorConfig = this.configError('get');

        const { data } = await ajax.get({ url, errorConfig });

        return mapOfferIntermediateProductsFromApi(data);
    }

    async editIntermediateProducts(id, intermediateProducts) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.PATCH_OFFER_INTERMEDIATE_PRODUCTS.replace('{id}', id);
        const errorConfig = this.configError('put');
        const config = {
            headers: HttpPatchHeaders,
        };

        const edited = await ajax.patch({
            url,
            errorConfig,
            config,
            data: {
                intermediate_products:
                    mapIntermediateProductsToApi(intermediateProducts),
            },
        });

        this.alert.addSuccess({
            message: this.translator.t(
                this.ALERTS.OFFER_INTERMEDIATE_PRODUCTS_ADDED,
            ),
        });

        return mapOfferIntermediateProductsFromApi(edited);
    }

    deleteIntermediateProduct(id) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.DELETE_OFFER_INTERMEDIATE_PRODUCT.replace('{id}', id);
        const errorConfig = this.configError('delete');

        return ajax.delete({ url, errorConfig });
    }

    async bulkArchiveProducts(
        offerId: string,
        file: File,
        { reason, note = null }: { reason: string; note: string | null },
    ) {
        const { ajax, URLS, mediaFiles } = this.salesChannelsService;
        const { id: uploadedId } = await mediaFiles.uploadCustomFiles(file);
        const url = URLS.POST_BULK_ARCHIVE_OFFER_PRODUCTS.replace(
            '{id}',
            offerId,
        );
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = this.configError('post');

        await ajax.post({
            url,
            config,
            errorConfig,
            data: {
                media_file: {
                    id: `custom-files/${uploadedId}`,
                },
                reason,
                note: note || null,
                separator: ',',
                enclosure: '"',
            },
        });

        this.alert.addSuccess({
            message: this.translator.t(this.ALERTS.FILE_UPLOADED_SUCCESS),
        });
    }

    private configError(
        requestType: 'put' | 'delete' | 'post' | 'get' | 'patch',
        config = {},
    ) {
        return {
            throwError: this.errorHandlers[requestType],
            addGenericAlert: false,
            ...config,
        };
    }
}

export default Offers;
