import { takeEvery, select, put, takeLatest } from 'redux-saga/effects';
import qs from 'qs';
import get from 'lodash/get';
import { push } from 'react-router-redux';
import { selectProperties } from './selectors';
import { getSearch } from 'utils/router/selectors';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import makeUrlParams from 'utils/redux/makeUrlParams';
import { MODULE_NAME } from 'modules/arm/constants';
import * as C from './constants';
import * as A from './actions';

import { slimServicesFactory } from 'services/servicesFactory';
import handleErrorsConfig from 'services/armApi/errorHandlers';

const ARM_URL = get(window, 'env.ARM_API_URL', '');
const properties = makeEndPoint(ARM_URL + '/api/properties');

export default function* propertiesSaga() {
    yield takeEvery(C.CREATE_PROPERTY.request, postProperty);
    yield takeEvery(C.REMOVE_PROPERTY.request, deleteProperty);
    yield takeEvery(C.EDIT_PROPERTY.request, putProperty);
    yield takeLatest(C.FETCH_PROPERTIES.request, getProperties);
    yield takeEvery(C.FETCH_PROPERTY.request, getProperty);
}

function trimProperty(property) {
    const options = property.get('options') || [];

    return property.set('options', options);
}

function* postProperty(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    const newProperty = trimProperty(action.payload.item);

    yield properties.post({
        action: A.createProperties,
        data: newProperty.toJS(),
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'arm:alerts.propertyCreated',
                options: {
                    variant: 'success',
                },
            },
        },
        callbacks: {
            failure: errorHandlers.post,
        },
        sagaCallbacks: {
            success: () => push(`/${MODULE_NAME}/properties`),
        },
    });
}

function* putProperty(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    const property = trimProperty(action.payload.item);
    const propertyId = property.get('id');

    yield properties.put({
        action: A.editProperties,
        path: propertyId,
        data: property.toJS(),
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'arm:alerts.propertyChanged',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: {
                id: propertyId,
            },
            failure: {
                id: propertyId,
            },
        },
        callbacks: {
            failure: errorHandlers.put,
        },
        sagaCallbacks: {
            success: () => push(`/${MODULE_NAME}/properties`),
        },
    });
}

function* deleteProperty(action) {
    const deleteId = action.payload.id;

    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    yield properties.delete({
        action: A.removeProperty,
        path: deleteId,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'arm:alerts.propertyDeleted',
                options: {
                    variant: 'success',
                },
            },
            400: {
                message: 'arm:alerts.propertyIsBeingUsed',
                options: {
                    variant: 'error',
                },
            },
            404: {
                message: 'arm:alerts.propertyDoesNotExist',
                options: {
                    variant: 'error',
                },
            },
        },
        attributes: {
            success: {
                id: deleteId,
            },
            failure: {
                id: deleteId,
            },
        },
        callbacks: {
            failure: errorHandlers.delete,
        },
    });
}

function* getProperties() {
    const urlParams = qs.parse(yield select(getSearch));
    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);
    const params = makeUrlParams({
        urlParams,
        filter: [
            {
                urlName: 'code_match',
                reqName: 'code',
                reqType: 'eq',
            },
            {
                urlName: 'name_match',
                reqName: 'name',
                reqType: 'eq',
            },
            {
                urlName: 'shortName_match',
                reqName: 'shortName',
                reqType: 'eq',
            },
            {
                urlName: 'unit_match',
                reqName: 'unit',
                reqType: 'eq',
            },
        ],
    });

    params.page = (urlParams.pagination && urlParams.pagination.page) || 1;
    params.per_page =
        (urlParams.pagination && urlParams.pagination.per_page) || 20;

    yield properties.get({
        action: A.fetchProperties,
        params: params,
        alerts: {
            failure: true,
        },
        callbacks: {
            failure: errorHandlers.get,
        },
    });
}

function* getProperty(action) {
    const id = action.payload.id;
    const propertiesById = yield select(selectProperties);

    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    if (propertiesById.get(id) && propertiesById.get(id).get('id')) {
        yield put(
            A.fetchProperty.success({
                response: propertiesById.get(id).toJS(),
            }),
        );
    } else {
        yield properties.get({
            action: A.fetchProperty,
            path: id,
            alerts: {
                failure: true,
            },
            callbacks: {
                failure: errorHandlers.get,
            },
        });
    }
}
