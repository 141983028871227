export const mapRoleGroupsFromApi = (data = {}) => {
    const {
        id = '',
        name = '',
        description = '',
    } = data;

    return {
        id,
        name,
        description,
    };
};

export const mapRoleGroupFromApi = (data = {}) => {
    const {
        name = '',
        description = '',
        roles = [],
    } = data;

    return {
        name,
        description,
        roles,
    };
};

export const mapRoleGroupPostToApi = (data = {}) => {
    return {
        name: data.name || '',
        description: data.description || '',
        roles: data.roles?.map((value) => ({ id: value })) || [],
    };
};

export const mapRoleGroupPutToApi = (data = {}) => mapRoleGroupPostToApi(data);

export const mapRoleGroupAutocomplete = ({ items }) => Array.isArray(items) ? items.map(({ id, name }) => ({ value: id, label: name })) : [];
