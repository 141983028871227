import styled from '@emotion/styled';
import { Typography, typographyClasses } from '@mui/material';
import { excludePropForward } from 'utils/styles';
import { css, Theme } from '@emotion/react';

export type TFontWeightOption = 'light' | 'regular' | 'semiBold' | 'bold';
export type TColorOption = 'grayDark' | 'gray' | 'blueDark' | 'grayMidLight';

const getFontWeightOption = (fontWeightOption?: TFontWeightOption) => {
    switch (fontWeightOption) {
        case 'light': {
            return css`
              font-weight: 300;
            `;
        }
        case 'regular': {
            return css`
              font-weight: 400;
            `;
        }
        case 'semiBold': {
            return css`
              font-weight: 600;
            `;
        }
        case 'bold': {
            return css`
              font-weight: 700;
            `;
        }
        default: {
            return css``;
        }
    }
};

const getColorOption = ({ theme, colorOption }: { theme: Theme, colorOption?: TColorOption }) => {
    switch (colorOption) {
        case 'grayDark': {
            return css`
              color: ${theme.palette.text.grayDark ?? '#666666'};
            `;
        }
        case 'gray': {
            return css`
              color: ${theme.palette.text.gray ?? '#7D828D'};
            `;
        }
        case 'blueDark': {
            return css`
              color: ${theme.palette.text.blueDark ?? '#293A65'};
            `;
        }
        case 'grayMidLight': {
            return css`
              color: ${theme.palette.text.grayMidLight ?? '#BDBDBD'};
            `;
        }
        default: {
            return css``;
        }
    }
};

export const TypographyStyled = styled(Typography, excludePropForward([
    'fontWeightOption', 'colorOption'
]))<{ fontWeightOption?: TFontWeightOption, colorOption?: TColorOption }>`

  &.${typographyClasses.h1},
  &.${typographyClasses.h2},
  &.${typographyClasses.h3},
  &.${typographyClasses.h4},
  &.${typographyClasses.h5},
  &.${typographyClasses.h6},
  &.${typographyClasses.body1} {
    ${({ fontWeightOption }) => getFontWeightOption(fontWeightOption)};
    ${({ theme, colorOption }) => getColorOption({ theme, colorOption })};
  }
`;
TypographyStyled.displayName = 'Typography';
