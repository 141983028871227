import { ApplicationJsonHeaders } from '../../../constants';
import type { IPagedResponse } from '../../../../types/services.type';
import type { SalesChannelsService } from '../../SalesChannelsService';
import type { IGetGtinGroupsParams } from './gtin.type';

export class Gtin {
    constructor(private readonly salesChannelsService: SalesChannelsService) {}

    static isCompanyCode(
        code: string,
    ): code is IGetGtinGroupsParams['company'] {
        return ['artgeist', 'decomonkey'].includes(code);
    }

    async getGtinGroups({
        search,
        company,
    }: IGetGtinGroupsParams): Promise<IPagedResponse<{ code: string }>> {
        const {
            URLS: { GET_GTIN_GROUPS },
            ajax,
            paramsSerializer,
        } = this.salesChannelsService;

        const { data } = await ajax.get({
            url: GET_GTIN_GROUPS,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...(company && { company }),
                    ...(search && { search }),
                },
                paramsSerializer,
            },
        });

        return data;
    }
}
