import moment from 'moment';
import get from 'lodash/get';
import { ItemModel } from 'reducerConfigurator/itemModel/itemModel';
import { mapIntermediateProductFromApi } from '../mappers/intermediateProduct';

class Product extends ItemModel {
    constructor(props = {}) {
        super(props);

        this.id = props.id;
        this.createdAt = props.createdAt || props.created_at || null;
        this.name = props.name || '';
        this.pattern = props.pattern || {};
        this.eanCodes = props.eans || [];
        this.skuCodes = props.skus || [];
        this.intermediateProduct = mapIntermediateProductFromApi(
            get(
                props,
                'intermediateProduct',
                get(props, 'intermediate_product', {})
            )
        );
        if (props.patternId) this.patternId = props.patternId;
        if (props.intermediateProductId) this.intermediateProductId = props.intermediateProductId;
    }

    getId = () => this.id && this.id.toString();
    getCreatedAt = () => moment(this.createdAt).format('DD/MM/YYYY');
    getName = () => this.name;
    getEanCodes = () => this.eanCodes;
    getSkuCodes = () => this.skuCodes;
    getPattern = () => this.pattern;
    getPatternId = () => this.pattern.id;
    getPatternThumbnail = () => this.pattern.thumbnail_url;
    getPatternName = () => this.pattern.name;
    getPatternCode = () => this.pattern.code;
    getIntermediateProduct = () => this.intermediateProduct;
    getIntermediateProductHeight = () => this.intermediateProduct.height ? this.intermediateProduct.height : '';
    getIntermediateProductWidth = () => this.intermediateProduct.width ? this.intermediateProduct.width : '';
    getTechnology = () => this.intermediateProduct.technology;
    getTechnologyId = () => this.intermediateProduct.technology.id;
    getTechnologyCode = () => this.intermediateProduct.technology.code;
    getTechnologyName = () =>
        `${this.intermediateProduct.technology.name} [${this.intermediateProduct.technology.code}]`;

    updateProduct = (newData = {}) => {
        const updateField = this.updateFieldFromNewData(newData);

        updateField('name');
        updateField('code');

        if (newData.patternId !== this.pattern.id) {
            this.pattern.id = newData.patternId;
            this.updatedFields.push('pattern');
        }
        if (newData.intermediateProductId !== this.intermediateProduct.id) {
            this.intermediateProduct.id = newData.intermediateProductId;
            this.updatedFields.push('intermediateProduct');
        }

        return this;
    };

    getProductForPOSTRequest = () => {
        const patternObject = {};

        if (this.name) patternObject.name = this.name;
        if (this.pattern.id) patternObject.pattern = { id: this.pattern.id };
        if (this.intermediateProduct.id) patternObject.intermediate_product = { id: this.intermediateProduct.id };

        return patternObject;
    };

    getProductForPATCHRequest = () => {
        const patternObject = {};

        if (this.updatedFields.includes('name')) patternObject.name = this.name;
        if (this.updatedFields.includes('pattern')) patternObject.pattern = { id: this.pattern.id };
        if (this.updatedFields.includes('intermediateProduct')) patternObject.intermediate_product = { id: this.intermediateProduct.id };

        return patternObject;
    };

    getProductPlainObject = () => ({
        id: this.getId(),
        name: this.getName(),
        pattern: this.getPattern(),
        intermediateProduct: this.getIntermediateProduct(),
        createdAt: this.getCreatedAt(),
    });
}

export default Product;
