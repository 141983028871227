import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    selectIsLoggedIn,
    selectShowWorkplaceModal,
} from 'modules/app/reducers/userProfile/selectors';
import {
    setLoggedIn,
    resetToken,
    hideWorkplaceModal,
} from 'modules/app/reducers/userProfile/actions';
import App from 'modules/app/components/App';
import withServices from 'hoc/withServices/withServices';

const mapStateToProps = (state) => {
    return {
        loggedIn: selectIsLoggedIn(state),
        showWorkplaceModal: selectShowWorkplaceModal(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedIn: (data) => dispatch(setLoggedIn(data)),
        hideWorkplaceModal: () => dispatch(hideWorkplaceModal()),
        resetToken: (data) => dispatch(resetToken.request(data)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withServices(App)),
);
