import get from 'lodash/get';
import { mapUserFromApi, mapUserToApi } from '../mappers/users';
import { ApplicationJsonHeaders } from '../../constants';

class Users {
    constructor(pdpService) {
        this.pdpService = pdpService;
        this.params = pdpService.USER_PARAMS || {};

        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.getById = this.getById.bind(this);
        this.getByUserId = this.getByUserId.bind(this);
        this.edit = this.edit.bind(this);
    }
    getList(params = {}) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            errorHandlers,
            paramsSerializer,
            getFilterParams,
        } = this.pdpService;
        const { page = 1, per_page = DEFAULTS.PER_PAGE, filters = {} } = params;

        const { FILTERS_KEYS } = this.params;

        const url = URLS.GET_USERS;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer,
        };

        const filter = getFilterParams(FILTERS_KEYS, filters) || {};

        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        if (filters.search) config.params.search = filters.search;
        if (filter.positionTitle)
            config.params.positionTitle = filter.positionTitle;
        if (filters.groupName) config.params.group_name = filters.groupName;
        if (filters.id) config.params.id = filters.id;

        return ajax.get({ url, config, errorConfig }).then((response) => {
            return {
                items: get(response, 'data.hydra:member', []).map(
                    mapUserFromApi,
                ),
                totalItems: get(response, 'data.hydra:totalItems', 0),
            };
        });
    }
    getById({ id }) {
        const { ajax, URLS } = this.pdpService;
        const url = URLS.EDIT_USER.replace('{id}', id);

        return ajax.get({ url }).then(({ data }) => {
            return mapUserFromApi(data);
        });
    }
    getByUserId({ id: userId }, addGenericAlert = true) {
        const { ajax, URLS } = this.pdpService;
        const url = URLS.GET_USER_V1.replace('{userId}', userId);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert,
        };

        return ajax.get({ url, config, errorConfig }).then(({ data }) => {
            return mapUserFromApi(data);
        });
    }
    delete(id) {
        const { ajax, URLS } = this.pdpService;
        const url = URLS.DELETE_USER.replace('{id}', id);

        return ajax.delete({ url }).then((response) => response);
    }
    create(formData) {
        const { ajax, URLS, errorHandlers } = this.pdpService;

        const formatedData = mapUserToApi(formData);

        const url = URLS.POST_USER;
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
            onError: errorHandlers.post,
        };

        return ajax
            .post({ url, config, data: formatedData, errorConfig })
            .then(({ data }) => {
                return data;
            });
    }
    edit({ formData, id }) {
        const { ajax, URLS, alerts, translator, errorHandlers } =
            this.pdpService;

        const url = URLS.EDIT_USER.replace('{id}', id);

        const formatedData = mapUserToApi(formData);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
            onError: errorHandlers.post,
        };

        return ajax
            .put({ url, data: formatedData, config, errorConfig })
            .then((res) => {
                alerts.addSuccess({
                    message: translator.t('pdp:alerts.userUpdated'),
                });

                return res;
            });
    }
}

export default Users;
