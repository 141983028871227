import moment from 'moment';

const getUser = (created_by) => {
    const { first_name, last_name, username } = created_by;

    if (created_by) {
        return first_name || last_name ? `${first_name} ${last_name}` : username || '';
    }

    return '';
};

export const mapOfferProductFromApiV2 = (data = {}) => {
    const { product, created_at, created_by, id, sku } = data;
    const { intermediate_product, pattern, gtins } = product;
    const user = getUser(created_by);

    const widthFromAPI = Number(intermediate_product?.width);
    const heightFromAPI = Number(intermediate_product?.height);

    const width = isNaN(widthFromAPI) ? 0 : widthFromAPI / 10;
    const height = isNaN(heightFromAPI) ? 0 : heightFromAPI / 10;
    const size = `${width}cm x ${height}cm`;

    return {
        patternThumbnailUrl: pattern?.thumbnail_url || '',
        patternCode: pattern?.code || '',
        intermediateProductName: intermediate_product?.name || '',
        intermediateProductComponentsDescription:
            intermediate_product?.components_description || '',
        intermediateProductId: intermediate_product?.id || '',
        intermediateProductParts: intermediate_product?.parts || '0',
        intermediateProductTechnologyName:
            intermediate_product?.technology?.name || '',
        intermediateProductSize: size,
        createdAt: created_at ? moment(created_at).format('DD/MM/YYYY') : '',
        createdBy: user,
        id: id || '',
        productId: product.id || '',
        sku: sku || '',
        gtins: Array.isArray(gtins) ? gtins : Object.values(gtins ?? {}),
    };
};

export const mapOfferProductsToApi = (data) => {
    return {
        products: data.map((productId) => ({
            id: productId
        }))
    };
};
