import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { excludePropForward } from 'utils/styles';

const getColor = ({ theme, color }) => {
    switch (color) {
        case 'primary':
        case 'blue': {
            return css`
              background-color: ${theme.palette.primary.main};
            `;
        }
        case 'green':
        case 'success': {
            return css`
              background-color: ${theme.palette.success.main};
            `;
        }
        case 'danger':
        case 'red': {
            return css`
              background-color: ${theme.palette.error.main};
            `;
        }
        case 'warning':
        case 'yellow': {
            return css`
              background-color: ${theme.palette.warning.main};
            `;
        }
        case 'black': {
            return css`
              background-color: ${theme.palette.common.black};
            `;
        }
        case 'info': {
            return css`
              background-color: ${theme.palette.common.gray.lighten1};
                  color: ${theme.palette.common.gray.main};
            `;
        }
        case 'default':
        default: {
            return css`
              background-color: ${theme.palette.common.gray.mid};
            `;
        }
    }
};

const getSize = (size) => {
    switch (size) {
        case 'large': {
            return css`
              height: 25px;
              font-size: 20px;
            `;
        }
        case 'small': {
            return css`
              height: 14px;
              font-size: 12px;
            `;
        }
        case 'mini': {
            return css`
              height: 10px;
              font-size: 8px;
            `;
        }
    }
};

const disabledStyles = ({ theme }) => css`
  cursor: not-allowed;
  background-color: ${theme.palette.common.gray.mid};
  
  &:hover {
    box-shadow: none;
  }
  
  &:active {
    box-shadow: none;
  }
`;

const clickableStyles = css`
  transition: 0.1s ease-in-out all;
  cursor: pointer;
  
  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  }
  
  &:active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;

export const RemoveButtonStyled = styled('span')`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: inherit;
  margin-left: -4px;
  
  &:hover {
    color: rgba(0, 0, 0, 0.2);
  }
`;
RemoveButtonStyled.displayName = 'RemoveButton';

export const BadgeStyled = styled('span', excludePropForward(['disabled', 'size', 'clickable']))`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
  text-align: center;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${({ size }) => size && getSize(size)};
  ${({ theme, color }) => color && getColor({ theme, color })};

  ${({ clickable }) => clickable && clickableStyles};
  ${({ disabled }) => disabled && disabledStyles};
`;
BadgeStyled.displayName = 'Badge';
