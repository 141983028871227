import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { ParachuteIcon } from 'components/Icons/Icons';

const Contractors = lazy(() =>
    import('../containers/ContractorsTable/ContractorsTableContainer'),
);
const ContractorDetails = lazy(() =>
    import('../pages/ContractorDetails/ContractorDetailsPage'),
);
const OrderImports = lazy(() =>
    import('../containers/OrderImportsTable/OrderImportsTable'),
);
const OrderImportsDetails = lazy(() =>
    import('../pages/OrderImportsDetails/OrderImportsDetailsPage'),
);
const Patrons = lazy(() =>
    import('../containers/PatronsTable/PatronsTableView'),
);
const Invoices = lazy(() =>
    import('../containers/InvoicesTable/InvoicesTable'),
);

export const dropshippingRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            name: 'contractors',
            exact: true,
            path: PATHS.CONTRACTORS,
            component: Contractors,
        },
        {
            name: null,
            exact: true,
            path: PATHS.CONTRACTORS_DETAILS,
            component: ContractorDetails,
        },
        {
            name: 'patrons',
            exact: true,
            path: PATHS.PATRONS,
            component: Patrons,
        },
        {
            name: 'orderImports',
            exact: true,
            path: PATHS.ORDER_IMPORTS,
            search: PATHS.ORDER_IMPORTS_WITH_SORT.search,
            component: OrderImports,
        },
        {
            name: null,
            exact: true,
            path: PATHS.ORDER_IMPORTS_DETAILS,
            component: OrderImportsDetails,
        },
        {
            name: 'invoices',
            exact: true,
            path: PATHS.INVOICES,
            component: Invoices,
        },
    ],
    icon: ParachuteIcon,
};
