import { ACCEPT } from '../voteTypes';
import * as A from '../accesses';
import * as S from '../sections';

export const ROLE_PRODUCTION_ORDER_PRESET_DELETE = {
    section: S.PRODUCTION,
    type: 'delete',
    votes: {
        [A.PRODUCTION.RESOURCES.PRESET.DELETE]: ACCEPT,
    },
};

export const ROLE_PRODUCTION_ORDER_PRESET_EDIT = {
    section: S.PRODUCTION,
    type: 'edit',
    votes: {
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.RESOURCES.PRESET.EDIT]: ACCEPT,
    },
};

export const ROLE_PRODUCTION_ORDER_PRESET_CREATE = {
    section: S.PRODUCTION,
    type: 'create',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.RESOURCES.PRESET.CREATE]: ACCEPT,
    },
};

export const ROLE_PRODUCTION_ORDER_DISPLAY = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.TEST_HELPERS.MODULE]: ACCEPT,
        [A.TEST_HELPERS.PATHS.WZ_HELPER]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.RESOURCES.ORDER.DISPLAY]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_BALANCE_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.BALANCE]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_NOTES_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.NOTES]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_ADDRESS_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.ADDRESS]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_HEADER_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.HEADER]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_STATES_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.STATES]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_AWE_STATES_SHOW = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.AWE_STATES]: ACCEPT,
    },
};

export const ROLE_UI_PRODUCTION_ORDERS_PRINT_AGAIN = {
    section: S.PRODUCTION,
    type: 'display',
    votes: {
        [A.PRODUCTION.MODULE]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRODUCTION]: ACCEPT,
        [A.PRODUCTION.PATHS.SEARCH_PRINTER]: ACCEPT,
        [A.PRODUCTION.PATHS.DETAILS]: ACCEPT,
        [A.PRODUCTION.UI.PRINT_AGAIN]: ACCEPT,
    },
};
