import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { excludePropForward } from 'utils/styles';

const clickableStyles = css`
  cursor: pointer;
`;

const extendedStyles = css`
  white-space: initial;
`;

const basicStyles = css`
  justify-content: flex-start;
`;

const actionsStyled = ({ theme }) => css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(1)};
`;

const flexStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const customStyles = ({ theme }) => css`
  padding: ${theme.spacing(1)};
`;

const smallCustomStyles = css`
  && {
    padding: 0;
  }
`;

const smallStyles = ({ theme }) => css`
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
`;

export const CellStyled = styled('div', excludePropForward([
    'clickable', 'extended', 'basic', 'actions', 'flex', 'custom', 'smallCustom', 'small', 'smallActions'
]))`
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)};
  grid-row-start: 1;
  grid-row-end: 2;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${({ clickable }) => clickable && clickableStyles};
  ${({ extended }) => extended && extendedStyles};
  ${({ basic }) => basic && basicStyles};
  ${({ actions }) => actions && actionsStyled};
  ${({ flex }) => flex && flexStyles};
  ${({ custom }) => custom && customStyles};
  ${({ smallCustom }) => smallCustom && smallCustomStyles};
  ${({ small }) => small && smallStyles};
  ${({ smallActions }) => smallActions && smallCustomStyles};
`;
CellStyled.displayName = 'Cell';
