import { takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import {
    FETCH_PRODUCT_CATALOGS,
    DOWNLOAD_PRODUCT_CATALOG,
} from './constants';
import {
    fetchProductCatalogs,
    downloadProductCatalog,
} from './actions';

const API = get(window, 'env.STABLO_API_URL', '');
const productCatalogs = makeEndPoint(API + '/catalogs');

export default function* productCatalogsSaga() {
    yield takeEvery(FETCH_PRODUCT_CATALOGS.request, getProductCatalogs);
    yield takeEvery(DOWNLOAD_PRODUCT_CATALOG.request, getDownloadProductCatalog);
}

function* getProductCatalogs() {
    yield productCatalogs.get({
        action: fetchProductCatalogs,
    });
}

function* getDownloadProductCatalog(action) {
    yield productCatalogs.get({
        action: downloadProductCatalog,
        path: action.payload.catalog.get('id') + '/get-summary',
        alerts: {
            failure: true,
        },
        attributes: {
            success: {
                name: action.payload.catalog.get('name'),
            },
        },
    });
}
