import i18next from 'i18next';
import { handleForbiddenError } from 'utils/errors';

const baseError =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (status === 404) {
            alertService.addError({
                message: i18next.t('arm:alerts.productNotFound'),
            });
            throw error;
        }

        if (data.violations) {
            data.violations.forEach((violation = {}) => {
                alertService.addError({
                    message: `${violation.propertyPath}: ${violation.message}`,
                });
                throw error;
            });
        }

        if (data['hydra:description']) {
            alertService.addError({
                message: data['hydra:description'],
            });
            throw error;
        }

        alertService.addError({
            message: i18next.t('common:errors.unknown', { status }),
        });
        throw error;
    };

const errorHandlers = (alertService) => ({
    post: baseError(alertService),
    patch: baseError(alertService),
    get: baseError(alertService),
    delete: baseError(alertService),
});

export default errorHandlers;
