import { fromJS } from 'immutable';
import * as C from './constants';
import moment from 'moment/moment';
import { toIdMap } from 'utils/immutable/list';

const initialState = fromJS({
    productCatalogs: fromJS({}),
    productCatalogFormLoading: false,
});

export default function productCatalogsReducer(state = initialState, action) {
    let url;
    let link;
    let fileName;

    switch (action.type) {
        case C.FETCH_PRODUCT_CATALOGS.request:
            return state.set('productCatalogFormLoading', true);
        case C.FETCH_PRODUCT_CATALOGS.failure:
            return state.set('productCatalogFormLoading', false);
        case C.FETCH_PRODUCT_CATALOGS.success:
            return state
                .set('productCatalogs', toIdMap(action.payload.response['hydra:member']))
                .set('productCatalogFormLoading', false);

        case C.DOWNLOAD_PRODUCT_CATALOG.request:
            return state.set('productCatalogFormLoading', true);
        case C.DOWNLOAD_PRODUCT_CATALOG.failure:
            return state.set('productCatalogFormLoading', false);
        case C.DOWNLOAD_PRODUCT_CATALOG.success:
            url = window.URL.createObjectURL(new Blob([action.payload.response]));
            link = document.createElement('a');
            fileName = action.payload.name + '_' + moment().format('DDMMYYYY_HHmm') + '.csv';

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

            return state.set('productCatalogFormLoading', false);

        default:
            return state;
    }
}
