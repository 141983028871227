import { Autocomplete as AutocompleteMUI } from '@mui/material';
import TextField from '../TextField/TextField';
import { AUTOCOMPLETE_FIELD_CLASS } from './autocompleteClasses';
import { AutocompleteChip } from '../AutocompleteChip/AutocompleteChip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { autocompleteBaseProps, optionProp } from './autocompleteBase.props';
import PropTypes from 'prop-types';
import Loader from '@mui/material/CircularProgress';

export const AutocompleteBase = ({
    id,
    label,
    handleChange,
    handleBlur,
    margin,
    value,
    className,
    error,
    onInputChange,
    size = 'medium',
    options = [],
    fullWidth = false,
    disabled = false,
    readOnly = false,
    multiple = false,
    disableCloseOnSelect = false,
    disableClearable = false,
    loading = false,
    maxLength,
    groupBy,
    isOptionEqualToValue,
    getOptionLabel,
    filterOptions,
    renderTags,
    customRenderOptionLabel = (option) => option.label ?? option,
}) => {
    const { t } = useTranslation();

    if (multiple && value !== undefined && !Array.isArray(value)) {
        throw Error(
            'AutocompleteBase - Passed value for multiple should be array',
        );
    }

    return (
        <AutocompleteMUI
            id={id}
            className={className}
            fullWidth={fullWidth}
            disableClearable={disableClearable}
            options={options}
            onChange={(_e, items, reason) =>
                handleChange(id, items, options, reason)
            }
            onBlur={handleBlur}
            readOnly={readOnly}
            disabled={disabled}
            disableCloseOnSelect={disableCloseOnSelect}
            value={value}
            loadingText={t('common:glossary.loading')}
            groupBy={groupBy}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
                <li
                    {...props}
                    key={option.value}
                >
                    {customRenderOptionLabel(option)}
                </li>
            )}
            noOptionsText={t('common:alerts.noOptions')}
            multiple={multiple}
            onInputChange={onInputChange}
            getOptionLabel={getOptionLabel}
            loading={loading}
            isOptionEqualToValue={
                isOptionEqualToValue
                    ? isOptionEqualToValue
                    : (option, value) => option.value === value.value
            }
            size={size}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={id}
                    className={AUTOCOMPLETE_FIELD_CLASS}
                    margin={margin}
                    error={!!error}
                    helperText={error}
                    InputProps={{
                        ...params.InputProps,
                        inputProps: {
                            ...params.inputProps,
                            maxLength,
                        },
                        endAdornment: (
                            <>
                                {loading ? (
                                    <Loader
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={
                renderTags
                    ? renderTags
                    : (value, getTagProps) => (
                          <AutocompleteChip
                              value={value}
                              getTagProps={getTagProps}
                              getOptionLabel={getOptionLabel}
                          />
                      )
            }
        />
    );
};

AutocompleteBase.propTypes = {
    ...autocompleteBaseProps,
    onInputChange: PropTypes.func,
    value: PropTypes.oneOfType([optionProp, PropTypes.arrayOf(optionProp)]), // TO DO update to string or Array<string>
    loading: PropTypes.bool,
};
