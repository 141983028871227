import React from 'react';
import displayHOCName from 'utils/displayHOCName';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getPath, getSearch, getHash } from 'utils/router/selectors';
import qs from 'qs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => {
    return {
        path: getPath(state),
        search: getSearch(state),
        hash: getHash(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push: (data) => dispatch(push(data)),
    };
};

const withUrlChangeHOC = (WrappedComponent, options = {}) => {
    class WithUrlChange extends React.PureComponent {
        constructor(props) {
            super(props);
            this.defOpt = {
                fieldName: options.fieldName || 'search',
                fieldType: options.fieldType || 'filters',
                urlPushTimeout: options.urlPushTimeout || 1500,
            };
            this.state = {
                value:
                    (qs.parse(props.search)[this.defOpt.fieldType] &&
                        qs.parse(props.search)[this.defOpt.fieldType][
                            this.defOpt.fieldName
                        ]) ||
                    '',
            };
            this.delayedChangeUrl = _.debounce(
                this.changeUrl,
                this.defOpt.urlPushTimeout,
            );
        }

        componentDidUpdate(prevProps, prevState) {
            const { search } = this.props;
            const { value } = this.state;
            const searchFromUrl =
                qs.parse(search)[this.defOpt.fieldType] &&
                qs.parse(search)[this.defOpt.fieldType][this.defOpt.fieldName];

            if (value === prevState.value && searchFromUrl !== value) {
                this.setState({ value: searchFromUrl || '' });
            }
        }

        changeUrl = (value = '') => {
            const { push, hash, search } = this.props;
            const searchObject = qs.parse(search);
            const trimmedValue = value.trim ? value.trim() : value;

            searchObject[this.defOpt.fieldType]
                ? (searchObject[this.defOpt.fieldType][this.defOpt.fieldName] =
                      trimmedValue)
                : (searchObject[this.defOpt.fieldType] = {
                      [this.defOpt.fieldName]: trimmedValue,
                  });

            searchObject.pagination
                ? (searchObject.pagination.page = 1)
                : (searchObject.pagination = { page: 1 });

            push({ hash, search: qs.stringify(searchObject) });
        };

        handleChange = (e) => {
            e.persist();

            const value = e.target ? e.target.value : null;

            this.delayedChangeUrl.cancel();
            this.setState({ value });

            if (value) {
                this.delayedChangeUrl(value);
            } else {
                this.changeUrl(value);
            }
        };

        handleInstantChange = (e) => {
            const value = e.target.value;

            this.delayedChangeUrl.cancel();
            this.setState({ value });
            this.changeUrl(value);
        };

        handleInstantUrlChange = () => {
            const { value } = this.state;

            this.delayedChangeUrl.cancel();
            this.changeUrl(value);
        };

        render() {
            const { value } = this.state;

            return (
                <WrappedComponent
                    value={value}
                    handleChange={this.handleChange}
                    handleInstantChange={this.handleInstantChange}
                    handleInstantUrlChange={this.handleInstantUrlChange}
                    {...this.props}
                />
            );
        }
    }

    WithUrlChange.propTypes = {
        search: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired,
        hash: PropTypes.string.isRequired,
    };

    WithUrlChange.displayName = `WithUrlChange(${displayHOCName(WrappedComponent)})`;

    return connect(mapStateToProps, mapDispatchToProps)(WithUrlChange);
};

export default withUrlChangeHOC;
