class Tos {
    constructor(pdpService, services, URLS, DEFAULTS, ListModel) {
        this.pdpService = pdpService;
        this.services = services;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.ListModel = ListModel;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.accept = this.accept.bind(this);
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.addAttachmentsToTos = this.addAttachmentsToTos.bind(this);
        this.removeAttachmentsFromTos =
            this.removeAttachmentsFromTos.bind(this);
    }

    getById({ id }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.pdpService;

        const url = this.URLS.GET_TOS.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        return ajax.get({ url, errorConfig }).then(({ data }) => {
            return data;
        });
    }

    getList({ page = 1, per_page = this.DEFAULTS.PER_PAGE }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.pdpService;

        const url = this.URLS.GET_TOS_LIST;
        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };
        const config = {
            params: {
                page,
                per_page,
            },
        };

        return ajax.get({ url, config, errorConfig }).then(({ data }) => {
            return new this.ListModel(data);
        });
    }

    create({ tos: data }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.pdpService;

        const url = this.URLS.POST_TOS;
        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
        };

        return ajax.post({ url, data, errorConfig }).then(({ data }) => {
            return data;
        });
    }

    edit({ tos: data, id }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.pdpService;

        const url = this.URLS.PUT_TOS.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.put,
        };

        return ajax.put({ url, data, errorConfig });
    }

    accept({ tosId }) {
        const { ajax } = this.services;
        const url = this.URLS.POST_TOS_CONSENT;
        const data = {
            tosId,
        };

        return ajax.post({ url, data });
    }

    downloadAttachment({ contentId, id, fileName }) {
        const { ajax } = this.services;
        const url = this.URLS.DOWNLOAD_FILE.replace(
            '{id}',
            `${contentId}/attachments/${id}`,
        );

        return ajax.download({ url, fileName });
    }

    addAttachmentsToTos(attachments) {
        return Promise.all(
            attachments.map((attachment) =>
                this._uploadAttachment(attachment.id, attachment.file),
            ),
        ).then((response) => response);
    }

    removeAttachmentsFromTos(attachments) {
        return Promise.all(
            attachments.map((attachment) => this._removeAttachment(attachment)),
        ).then((response) => response);
    }

    _uploadAttachment(id, { file }) {
        const { ajax } = this.services;
        const url = this.URLS.POST_FILE.replace('{id}', id);
        const data = new FormData();

        data.append('file', file);

        return ajax.post({ url, data });
    }

    _removeAttachment({ id, contentId }) {
        const { ajax } = this.services;
        const url = this.URLS.DELETE_FILE.replace(
            '{id}',
            `${contentId}/attachments/${id}`,
        );

        return ajax.delete({ url });
    }
}

export default Tos;
