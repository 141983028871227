import { fromJS } from 'immutable';
import * as C from './constants';

import { slimServicesFactory } from 'services/servicesFactory';

const initialState = fromJS({
    isLoggedIn: false,
    showWorkplaceModal: false,
    activeUser: {},
    loginError: '',
    requestChangePasswordError: '',
    changePasswordErrorCode: null,
    loaders: {
        loginForm: false,
    },
});

export default function userProfile(state = initialState, action) {
    let newLoginError = '';
    let refreshToken = false;

    const slimServices = slimServicesFactory();

    switch (action.type) {
        case C.LOGOUT_USER:
            slimServices.authentication.removeActiveToken();
            slimServices.authentication.removeRefreshToken();
            slimServices.modal.removeModals();
            slimServices.drawer.removeDrawers();
            slimServices.pdp.workplaces.removeLocalWorkplace();

            return state
                .set('isLoggedIn', false)
                .setIn(['loaders', 'loginForm'], false)
                .set('activeUser', fromJS({}))
                .set('showWorkplaceModal', true);
        case C.SET_LOGGED_USER:
            return state
                .set('isLoggedIn', true)
                .set(
                    'showWorkplaceModal',
                    slimServices.pdp.workplaces.doShowWorkplaceModal(),
                );
        case C.LOGIN_USER.request:
            return state
                .set('loginError', '')
                .set('isLoggedIn', false)
                .setIn(['loaders', 'loginForm'], true);
        case C.LOGIN_USER.failure:
            newLoginError = action.payload.response.message || '';

            return state
                .set('loginError', newLoginError)
                .set('isLoggedIn', false)
                .setIn(['loaders', 'loginForm'], false);
        case C.LOGIN_USER.success:
            slimServices.authentication.setActiveToken(
                action.payload.response.token,
            );

            return state
                .set('loginError', '')
                .set('isLoggedIn', true)
                .setIn(['loaders', 'loginForm'], false)
                .set(
                    'showWorkplaceModal',
                    slimServices.pdp.workplaces.doShowWorkplaceModal(),
                );

        case C.RESET_TOKEN.request:
            refreshToken = slimServices.authentication.getRefreshToken();

            return state.setIn(['loaders', 'loginForm'], !!refreshToken);
        case C.RESET_TOKEN.failure:
            return state
                .set('isLoggedIn', false)
                .setIn(['loaders', 'loginForm'], false);
        case C.RESET_TOKEN.success:
            slimServices.authentication.setActiveToken(
                action.payload.response.token,
            );

            return state
                .set('isLoggedIn', true)
                .setIn(['loaders', 'loginForm'], false);

        case C.REQUEST_CHANGE_PASSWORD.request:
            return state.setIn(['loaders', 'loginForm'], true);
        case C.REQUEST_CHANGE_PASSWORD.failure:
            return state
                .set(
                    'requestChangePasswordError',
                    action.payload?.response['hydra:description'] || '',
                )
                .setIn(['loaders', 'loginForm'], false);

        case C.REQUEST_CHANGE_PASSWORD.success:
            return state.setIn(['loaders', 'loginForm'], false);

        case C.CHANGE_PASSWORD.request:
            return state
                .set('changePasswordErrorCode', null)
                .setIn(['loaders', 'loginForm'], true);
        case C.CHANGE_PASSWORD.failure:
            return state
                .set('changePasswordErrorCode', action.payload.response.status)
                .setIn(['loaders', 'loginForm'], false);
        case C.CHANGE_PASSWORD.success:
            return state
                .set('changePasswordErrorCode', null)
                .setIn(['loaders', 'loginForm'], false);
        case C.HIDE_WORKPLACE_MODAL:
            return state
                .set('showWorkplaceModal', false)
                .setIn(['loaders', 'loginForm'], false);
        default:
            return state;
    }
}
