export const ARM_CONTRACTOR = 'contractor';
export const ARM_ORDER_IMPORT = 'orderImport';
export const ARM_INTERMEDIATE_PRODUCT = 'intermediateProduct';
export const ARM_TECHNOLOGY = 'technology';
export const ARM_TECHNOLOGY_GROUP = 'technologyGroup';
export const ARM_PATTERN = 'pattern';
export const ARM_PRODUCT = 'product';
export const ARM_PRINT_SPEC = 'printSpec';
export const ARM_EAN_POOLS = 'eanPools';
export const ARM_PRODUCT_SET_TYPES = 'productSetType';
export const ARM_EXTERNAL_PRODUCERS = 'externalProducers';

export const USER = 'user';
export const TOS = 'tos';
export const POSITIONS = 'positions';

export const STABLO = 'stablo';
export const PRODIS = 'prodis';
export const PRODUCTION = 'production';

export const STOCK_SOURCES = 'sources';
export const STOCK_AUTHORS = 'authors';
export const STOCK_PATTERNS = 'patterns';
export const STOCK_PREVIEW_TYPES = 'previewTypes';
export const STOCK_CATEGORIES = 'categories';
export const STOCK_BASE_PATTERNS = 'basePatterns';

export const SALES = 'sales';
export const SALES_ORDERS = 'orders';

export const POST_SALES_ADMINISTRATION = 'administration';
export const POST_SALES_CUSTOMER_REQUESTS = 'customerRequests';

export const MANAGE_OFFERS = 'manageOffers';

export const MANAGE_TASKS = 'manageTasks';

export const ROOT = 'root';

export const TRANSLATIONS_PATTERNS = 'translationsPatterns';

export const DISCOUNTS = 'discounts';

export const BOXES = 'boxes';

export const USER_SERVICE = 'userService';
