import {
    ApplicationJsonHeaders,
    HeadersAcceptType,
    HttpPatchHeaders,
} from 'services/constants';
import parser from 'qs';
import type { IIGetListData } from './basePatterns.type';
import type { StockService } from '../../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../../stockService.type';

class BasePatterns {
    private readonly URLS: IStockServiceConst['URLS'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.stockService = stockService;
        this.services = services;
        this.URLS = this.stockService.URLS;
    }
    paramsSerializer = (params) => parser.stringify(params);

    manageFiltersReduce = (filters, res, key) => {
        const catValue = filters?.categories || filters[key];

        switch (key) {
            case 'createDateAfter':
                return {
                    ...res,
                    'created_at[after]': filters[key],
                };
            case 'createDateBefore':
                return {
                    ...res,
                    'created_at[before]': filters[key],
                };
            case 'categories':
                return { ...res, ['category.id']: catValue };
            case 'author':
                return { ...res, ['author.id']: filters[key] };
            case 'pagination.page':
                return res;
            case 'pagination.per_page':
                return res;
            default:
                return { ...res, [key]: filters[key] };
        }
    };

    adjustFilters = (filters) => {
        return Object.keys(filters).reduce((res, key) => {
            return this.manageFiltersReduce(filters, res, key);
        }, {});
    };

    getList = async ({
        page = 1,
        per_page = 20,
        filters = {},
    }): Promise<IIGetListData> => {
        const { ajax } = this.services;
        const url = this.URLS.GET_BASE_PATTERNS;
        const correctFilters = this.adjustFilters(filters);
        const config = {
            params: {
                page,
                per_page,
                status: 'active',
                ...correctFilters,
            },
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.get({ url, config });

        return {
            items:
                data?.results.map(({ code, ...item }) => ({
                    ...item,
                    id: code,
                })) ?? [],
            itemsTotal: data?.total ?? 0,
        };
    };

    createBasePattern = async (payload) => {
        const { ajax } = this.services;
        const url = this.URLS.POST_BASE_PATTERN;
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.post({ url, data: payload, config });

        return data;
    };

    getBasePatternPrintResource = async (code) => {
        const { ajax } = this.services;
        const url = this.URLS.GET_BASE_PATTERN_PRINT_RESOURCE.replace(
            '{code}',
            code,
        );
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.get({ url, config });

        return data;
    };

    postBasePatternPrintResource = async (code, body) => {
        const { ajax } = this.services;
        const url = this.URLS.POST_BASE_PATTERN_PRINT_RESOURCE.replace(
            '{code}',
            code,
        );
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.post({ url, config, data: body });

        return data;
    };

    getBasePatternPrintSpec = async (id) => {
        const { ajax } = this.services;
        const url = this.URLS.GET_BASE_PATTERN_PRINT_SPECS.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.get({ url, config });

        return data;
    };

    getBasePatternResource = async (code) => {
        const { ajax } = this.services;
        const url = this.URLS.GET_BASE_PATTERN_RESOURCE.replace('{code}', code);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.get({ url, config });

        return data;
    };

    patchBasePattern = async (code, body) => {
        const { ajax } = this.services;
        const url = this.URLS.PATCH_BASE_PATTERN_RESOURCE.replace(
            '{code}',
            code,
        );
        const config = {
            headers: HttpPatchHeaders,
        };
        const { data } = await ajax.patch({ url, config, data: body });

        return data;
    };

    getCSVFile = async (filters = {}, fileName = 'Base_patterns_list.csv') => {
        const { ajax } = this.services;
        const url = this.URLS.GET_BASE_PATTERNS_IN_CSV;
        const correctFilters = this.adjustFilters(filters);
        const config = {
            params: {
                ...correctFilters,
            },
            headers: {
                Accept: HeadersAcceptType.csv,
            },
        };

        return await ajax.download({ url, config, fileName });
    };

    preparePayload = (formValues) => {
        return Object.keys(formValues).reduce((res, key) => {
            if (Array.isArray(formValues[key])) {
                const mappedArr =
                    key === 'tags'
                        ? formValues[key].map((tag) => ({ code: tag }))
                        : formValues[key].map(({ id }) => ({ id }));

                return { ...res, [key]: mappedArr };
            }
            if (key === 'author') {
                return { ...res, [key]: { id: formValues[key] || '' } };
            }
            if (typeof formValues[key] === 'string') {
                return { ...res, [key]: formValues[key] };
            }
            if (formValues[key]) {
                return { ...res, [key]: { id: formValues[key].id || '' } };
            }

            return null;
        }, {});
    };
}

export default BasePatterns;
