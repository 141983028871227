import parser from 'qs';
import { get } from 'lodash';

import { ApplicationJsonHeaders, TextCsvHeaders } from 'services/constants';

import { getNewOrderImport } from 'modules/dropshipping/utils/orderImportsHelpers';
import {
    mapImportOrderFromApi,
    mapGetListFilters,
} from 'services/armApi/mappers/orderImports';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class OrderImports {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly APP_ALERTS: IArmApiServiceConst['APP_ALERTS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS, APP_ALERTS }: Pick<IArmApiServiceConst, 'URLS' | 'APP_ALERTS'>,
    ) {
        this.APP_ALERTS = APP_ALERTS;
        this.URLS = URLS;

        this.getList = this.getList.bind(this);
        this.download = this.download.bind(this);
        this.create = this.create.bind(this);
        this.getById = this.getById.bind(this);
        this.delete = this.delete.bind(this);
        this.passOrderToProduction = this.passOrderToProduction.bind(this);
    }

    paramsSerializer = (params) => parser.stringify(params);

    async getList({ filters = {}, per_page = 20, page = 1, sort = {} }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_ORDER_IMPORTS;
        const config = {
            params: {
                filter: mapGetListFilters(filters),
                sort,
                page,
                per_page,
            },
            paramsSerializer: this.paramsSerializer,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        try {
            const response = await ajax.get({ url, config, errorConfig });
            const items = get(response, 'data.hydra:member', []);
            const totalItems = get(response, 'data.hydra:totalItems', []);

            return {
                items: items.map(mapImportOrderFromApi),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async getById(id) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_ORDER_IMPORT.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const response = await ajax.get({ url, errorConfig });

        return mapImportOrderFromApi(response.data);
    }

    async download({ fileName, id, fileType }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DOWNLOAD_ORDER_IMPORT.replace('{id}', id).replace(
            '{fileType}',
            fileType,
        );
        const config = { headers: TextCsvHeaders };
        const errorConfig = {
            throwError: errorHandlers.download,
            addGenericAlert: false,
        };

        return await ajax.download({
            url,
            config,
            fileName,
            errorConfig,
        });
    }

    async create(data) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_ORDER_IMPORT;
        const config = { headers: ApplicationJsonHeaders };
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const newOrderImport = getNewOrderImport(data);

        const response = await ajax.post({
            url,
            config,
            errorConfig,
            data: newOrderImport,
        });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.ORDER_IMPORT_CREATED),
        });

        return response.data;
    }

    async delete(id) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DELETE_ORDER_IMPORT.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.ORDER_IMPORT_DELETED),
        });

        return response.data;
    }

    async passOrderToProduction(orderId) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.PASS_IMPORTED_ORDER_FILES.replace(
            '{id}',
            orderId,
        );
        const errorConfig = {
            throwError: errorHandlers.passOrderToProduction,
            addGenericAlert: false,
        };

        const response = await ajax.post({ url, data: {}, errorConfig });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.ORDER_IMPORT_PASSED),
        });

        return response;
    }
}

export default OrderImports;
