import React from 'react';
import CarouselContainer from 'components/Carousel/Carousel';
import type { ModalService } from 'services/modal/ModalService';

type TEventType = 'open' | 'close' | 'patternsImagesChanged';
type TListener<TData> = (data: TData) => void;

class CarouselService<TData extends { id: string } & Record<string, unknown>> {
    private listeners: {
        open?: Set<TListener<TData>>;
        close?: Set<TListener<TData>>;
    } = {};

    private constructor(private readonly modalService: ModalService<TData>) {
        this.open = this.open.bind(this);
        this.listen = this.listen.bind(this);
        this.unlisten = this.unlisten.bind(this);
        this.emit = this.emit.bind(this);
    }

    private static instance: CarouselService<any> | undefined;

    public static getInstance<T extends { id: string }>(
        modalService: ModalService<T>,
    ): CarouselService<T> {
        if (!CarouselService.instance) {
            CarouselService.instance = new CarouselService(modalService);
        }

        return CarouselService.instance;
    }

    listen(eventName: TEventType, listener: TListener<TData>) {
        this.listeners[eventName] = this.listeners[eventName] || new Set();
        this.listeners[eventName].add(listener);
    }

    unlisten(eventName: TEventType, listener: TListener<TData>) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].delete(listener);
        }
    }

    emit(eventName: TEventType, data: TListener<TData>) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].forEach((listener) => listener(data));
        }
    }

    open(items, id: string) {
        this.modalService.open({
            isCarousel: true,
            id: 'gallery',
            content: (
                <CarouselContainer
                    id={id}
                    items={items}
                />
            ),
            dialogProps: (close) => ({
                onClose: close,
                maxWidth: 'md',
                sx: {
                    zIndex: 1320,
                },
            }),
        });
    }
}

export default CarouselService;
