import IntermediateProductsActions from './actions/IntermediateProducts';
import ProductsActions from './actions/Products';
import TechnologiesActions from './actions/Technologies';
import TechnologiesGroupsActions from './actions/TechnologiesGroups';
import PropertiesActions from './actions/Properties';
import ContractorsActions from './actions/Contractors';
import ContractorEmployee from './actions/ContractorEmployee';
import PrintSpecs from './actions/PrintSpecs';
import Invoices from './actions/Invoices';
import OrderImports from './actions/OrderImports';
import BoxGroups from './actions/BoxGroups';
import BoxProducers from './actions/BoxProducers';
import Boxes from './actions/Boxes';
import get from 'lodash/get';
import { BoxRule } from './actions/BoxRule';
import { PrintSpecFile } from './actions/PrintSpecFile';
import { Accessories } from 'services/armApi/actions/Accessories';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { default as Parser } from 'qs';
import type { uploadServicesFactory as UploadServicesFactory } from './uploadServices/uploadServicesFactory';
import type { default as ErrorHandlersConfigFactory } from './errorHandlers';
import { ExternalProducerActions } from './actions/externalProducer/externalProducer.actions';

import type {
    URLS as URLS_CONST,
    DEFAULTS as DEFAULTS_CONST,
    PARAMS as PARAMS_CONST,
    FILES_TYPES as FILES_TYPES_CONST,
} from './constants';
import { IArmApiServiceConstructorParams } from './armApi.type';

export class ArmApiService {
    private readonly ajaxService: AjaxService;
    private readonly parser: typeof Parser;
    private readonly URLS: typeof URLS_CONST;
    private readonly DEFAULTS: typeof DEFAULTS_CONST;
    private readonly FILES_TYPES: typeof FILES_TYPES_CONST;
    private readonly PARAMS: typeof PARAMS_CONST;

    public readonly upload: Pick<
        ReturnType<typeof UploadServicesFactory>,
        'printSpecFiles'
    >;
    public readonly intermediateProducts: IntermediateProductsActions;
    public readonly technologies: TechnologiesActions;
    public readonly technologiesGroups: TechnologiesGroupsActions;
    public readonly products: ProductsActions;
    public readonly properties: PropertiesActions;
    public readonly printSpecs: PrintSpecs;
    public readonly printSpecFile: PrintSpecFile;
    public readonly invoices: Invoices;
    public readonly orderImports: OrderImports;
    public readonly contractors: ContractorsActions;
    public readonly contractorEmployee: ContractorEmployee;
    public readonly boxGroups: BoxGroups;
    public readonly boxProducers: BoxProducers;
    public readonly boxes: Boxes;
    public readonly boxRule: BoxRule;
    public readonly accessories: Accessories;
    public readonly errorHandlers: ReturnType<
        typeof ErrorHandlersConfigFactory
    >;

    private readonly externalProducerActions: ExternalProducerActions;

    private constructor({
        services,
        errorHandlersConfig,
        URLS,
        FILES_TYPES,
        APP_ALERTS,
        DEFAULTS,
        PARAMS,
    }: IArmApiServiceConstructorParams) {
        this.ajaxService = services.ajax;
        this.errorHandlers = errorHandlersConfig(
            services.alert,
            services.translator,
        );
        this.parser = services.parser;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.FILES_TYPES = FILES_TYPES;
        this.PARAMS = PARAMS;

        this.upload = {
            printSpecFiles: services.upload.printSpecFiles,
        };
        this.intermediateProducts = new IntermediateProductsActions(
            this,
            services,
            { URLS },
        );
        this.technologies = new TechnologiesActions(this, services, { URLS });
        this.technologiesGroups = new TechnologiesGroupsActions(services, {
            URLS,
        });
        this.products = new ProductsActions(services, { URLS });
        this.properties = new PropertiesActions(services, { URLS });
        this.printSpecs = new PrintSpecs(this, services, {
            URLS,
            DEFAULTS,
            PARAMS,
        });
        this.printSpecFile = new PrintSpecFile(services, {
            URLS,
        });
        this.invoices = new Invoices(this, services, { URLS });
        this.orderImports = new OrderImports(this, services, {
            URLS,
            APP_ALERTS,
        });

        this.contractors = new ContractorsActions(this, services, {
            APP_ALERTS,
            URLS,
        });
        this.contractorEmployee = new ContractorEmployee(services, {
            URLS,
        });

        this.boxGroups = new BoxGroups(this, services, { URLS, DEFAULTS });
        this.boxProducers = new BoxProducers(this, services, {
            URLS,
            DEFAULTS,
        });
        this.boxes = new Boxes(this, services, { URLS, DEFAULTS });
        this.boxRule = new BoxRule(this, services, { URLS });
        this.accessories = new Accessories(this, services, { URLS });
        this.externalProducerActions = ExternalProducerActions.getInstance(
            this,
            services.ajax,
        );

        this.enhanceUsersListWithManagerData =
            this.enhanceUsersListWithManagerData.bind(this);
        this.uploadIcon = this.uploadIcon.bind(this);
        this.uploadManual = this.uploadManual.bind(this);
        this.uploadProperty = this.uploadProperty.bind(this);
        this.uploadPreview = this.uploadPreview.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    private static instance: ArmApiService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        URLS,
        FILES_TYPES,
        APP_ALERTS,

        DEFAULTS,
        PARAMS,
    }: IArmApiServiceConstructorParams): ArmApiService {
        if (!ArmApiService.instance) {
            ArmApiService.instance = new ArmApiService({
                services,
                errorHandlersConfig,
                URLS,
                FILES_TYPES,
                APP_ALERTS,
                DEFAULTS,
                PARAMS,
            });
        }

        return ArmApiService.instance;
    }

    async enhanceUsersListWithManagerData(usersArray) {
        const userIds = usersArray.map((user) => user.userId);
        const managersInfo = await this.getPatronsInfo(userIds);

        return managersInfo.map(({ user_id, contractors_count }) => {
            const userInfo = usersArray.filter(
                (user) => user.userId === user_id,
            )[0];

            if (userInfo) {
                return {
                    ...userInfo,
                    contractorCount: contractors_count,
                };
            }
        });
    }

    private getPatronsInfo(userIds) {
        const url = this.URLS.GET_MANAGERS;
        const config = {
            params: {
                filter: {
                    user_ids: {
                        in: userIds,
                    },
                },
            },
            paramsSerializer: this.paramsSerializer,
        };

        return this.ajaxService.get({ url, config }).then((response) => {
            return get(response, 'data.hydra:member', []);
        });
    }

    paramsSerializer = (params) => this.parser.stringify(params);

    private uploadFile(file, type) {
        const data = new FormData();

        data.append('type', type);
        data.append('media_file', file);

        return this.ajaxService.post({ url: this.URLS.POST_FILE, data });
    }

    uploadIcon(file) {
        return this.uploadFile(file, this.FILES_TYPES.ICON);
    }

    uploadManual(file) {
        return this.uploadFile(file, this.FILES_TYPES.MANUAL);
    }

    uploadProperty(file) {
        return this.uploadFile(file, this.FILES_TYPES.PROPERTY);
    }

    uploadPreview(file) {
        return this.uploadFile(file, this.FILES_TYPES.PREVIEW);
    }

    downloadFile(fileId, fileName) {
        const url = this.URLS.GET_FILE.replace('{id}', fileId);

        return this.ajaxService.download({ url, fileName });
    }

    public getExternalProducerActions(): ExternalProducerActions {
        return this.externalProducerActions;
    }
}
