export const getListingIdFromOrderLines = (orderLines) => {
    if (!orderLines || !orderLines.length) {
        return null;
    }

    const listingIds = orderLines.reduce((acc, { listingId }) => {
        return acc.includes(listingId) || !listingId
            ? acc
            : [...acc, listingId];
    }, []);

    return listingIds.join(', ');
};

export const splitMultipleValueString = (patternCode) =>
    patternCode?.split(/[ ,]+/);
