import { trimText } from 'utils/formatting';
import { makeNote } from 'utils/note/notes';

export const mapOrderFromApi = (data: any = {}) => {
    const {
        id = '',
        createdAt = '',
        orderNumber = '',
        orderName = '',
        customerName = '',
        channel = '',
        status = '',
        isBillingAddressEditable = false,
        isShippingAddressEditable = false,
        company,
    } = data;

    return {
        id,
        company,
        orderNumber,
        orderName,
        customerName,
        channel,
        status,
        createdAt,
        isBillingAddressEditable,
        isShippingAddressEditable,
    };
};

export const mapOrderBalanceFromApi = (data: any = {}) => {
    const {
        currencyCode = '',
        balanceAmount = 0,
        currencySymbol = '',
        orderDiscountAmount = 0,
        orderLinesDiscountAmount = 0,
        orderLinesTotalAmount = 0,
        orderTotalAmount = 0,
        paidAmount = 0,
        shipmentCostAmount = 0,
        totalDiscountAmount = 0,
    } = data;

    return {
        currencyCode,
        balanceAmount,
        currencySymbol,
        orderDiscountAmount,
        orderLinesDiscountAmount,
        orderLinesTotalAmount,
        orderTotalAmount,
        paidAmount,
        shipmentCostAmount,
        totalDiscountAmount,
    };
};

export const mapEditBillingAddressToApi = ({
    name,
    company,
    streetLine,
    city,
    postalCode,
    countryCode,
    provinceCode,
    taxId,
}: any = {}) => ({
    name: trimText(name),
    company: trimText(company),
    streetLine: trimText(streetLine),
    city: trimText(city),
    postalCode: trimText(postalCode),
    countryCode: trimText(countryCode),
    provinceCode: trimText(provinceCode),
    modifiedTaxId: trimText(taxId),
    phoneNumber: '',
    email: '',
});

export const mapEditShippingAddressToApi = ({
    name,
    company,
    streetLine,
    city,
    postalCode,
    countryCode,
    phoneNumber,
    email,
    provinceCode,
}: any = {}) => ({
    name: trimText(name),
    company: trimText(company),
    streetLine: trimText(streetLine),
    city: trimText(city),
    postalCode: trimText(postalCode),
    countryCode: trimText(countryCode),
    phoneNumber: trimText(phoneNumber),
    email: trimText(email),
    provinceCode: trimText(provinceCode),
});

export const mapOrdersImportToAPI = ({ file, importType, noteChanel, note }) => ({
    mediaFile: {
        id: `custom-files/${file}`,
    },
    channel: importType,
    note: makeNote({
        type: 'importOrder',
        channel: noteChanel || importType,
        note,
    }),
    enclosure: '"',
});
