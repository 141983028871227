import { mapTagsFromAPI } from 'services/salesChannels/mappers/channelTags';
import { ApplicationJsonHeaders } from 'services/constants';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';
import type { SalesChannelsService } from '../SalesChannelsService';

export class ChannelTags {
    private readonly asyncRequestPaginator: AsyncRequestPaginator<unknown>;

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getList = this.getList.bind(this);
        this.getMore = this.getMore.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMore,
            mapTagsFromAPI,
        );
    }

    getMore(params) {
        const { ajax, URLS, DEFAULTS, paramsSerializer, errorHandlers } =
            this.salesChannelsService;
        const {
            page = 1,
            per_page = DEFAULTS.PATTERNS_PER_PAGE,
            filters = {},
        } = params;

        const url = URLS.GET_CHANNEL_TAGS;
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page,
                itemsPerPage: per_page,
            },
            paramsSerializer,
        };

        const channelId = 'channel.id';

        if (params[channelId]) {
            config.params[channelId] = params[channelId];
        }

        if (filters['value']) {
            config.params['value'] = filters['value'];
        }

        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        return ajax.get({ url, config, errorConfig });
    }

    getList(params = {}) {
        return this.asyncRequestPaginator.fetchFullList(params);
    }
}
