export const mapScopeFromApi = (data) => ({
    id: data.id,
    scopeFrom: data.scope_from,
    scopeTo: data.scope_to,
    company: data.company,
    gtinsCounter: {
        freeGtins: data.free_gtins,
        totalGtins: data.total_gtins,
        usedGtins: data.used_gtins
    },
    createdAt: data.created_at
});

export const mapScopeToApi = (data) => ({
    company: {
        id: data.company.id
    },
    scope_from: data.scopeFrom,
    scope_to: data.scopeTo,
});

export const mapScopeGtinFromApi = (data) => ({
    id: data.id,
    name: data.name,
    gpcName: data.gpc.name,
    date: data.date
});
