import i18next from 'i18next';

import { APP_ALERTS, DEFAULTS, URLS } from './constants';
import parser from 'qs';
import errorHandlers from './errorHandlers';
import { ProductionService } from './ProductionService';
import { ApplicationJsonHeaders } from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const productionServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    ProductionService.getInstance({
        CONSTANTS: {
            URLS,
            DEFAULTS,
            APP_ALERTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
            },
        },
        errorHandlersConfig: errorHandlers,
        services: {
            ajax: ajaxService,
            alert: alertService,
            parser,
            translator: i18next,
        },
    });
