import get from 'lodash/get';

const MEDIA_FILES_SERVICE_URL = get(window, 'env.MEDIA_FILES_URL', '');

export const MODULE_NAME = 'boxes';

export const UNITS = {
    ['mm']: 'mm',
    ['cm']: 'cm',
};

const ACCEPTED_MIME_TYPES = [
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'image/tiff',
    'image/bmp',
    'image/png',
];

export const BOX_IMAGES_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES,
    MAX_FILE_SIZE: 30 * 1024 * 1024,
    MAX_FILES_LIMIT: 150,
    UPPY: {
        id: 'box-images-files',
        autoProceed: false,
        debug: true,
    },
    TUS: {
        endpoint: `${MEDIA_FILES_SERVICE_URL}/api/v1/custom-files/tus`,
        chunkSize: 10000000, // 10mb
        retryDelays: [0, 1000, 3000, 5000],
        id: 'box-images-tus-upload',
    },
};
