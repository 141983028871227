import * as S from '../sections';
import * as A from '../accesses';
import { ACCEPT, CONDITIONAL } from '../voteTypes';

export const ROLE_SALES_CHANNELS_CHANNEL_CREATE = {
    section: S.MANAGE_OFFERS,
    type: 'create',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.SALES_CHANNEL_CREATE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_SALES_CHANNELS_COMPANY_READ'],
        }),
        [A.MANAGE_OFFERS.RESOURCES.SALES_CHANNELS.CREATE]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_CHANNEL_READ = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.SALES_CHANNELS]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.SALES_CHANNELS.FETCH]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.OFFERS.FETCH]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.SEE_OFFER]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_CHANNEL_UPDATE = {
    section: S.MANAGE_OFFERS,
    type: 'edit',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.OFFERS_EDIT]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.OFFERS.EDIT]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.SALES_CHANNELS.EDIT]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_COMPANY_READ = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_OFFER_CREATE = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.OFFERS_DETAILS]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.OFFERS.CREATE]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_OFFER_EDIT = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.OFFERS_DETAILS]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.OFFERS.EDIT]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_OFFER_READ = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.OFFERS]: ACCEPT,
        [A.MANAGE_OFFERS.PATHS.OFFERS_DETAILS]: ACCEPT,
        [A.MANAGE_OFFERS.RESOURCES.OFFERS.FETCH]: ACCEPT,
    },
};

export const ROLE_SALES_CHANNELS_OFFER_ITEM_CREATE = {
    section: S.MANAGE_OFFERS,
    type: 'display',
    votes: {
        [A.MANAGE_OFFERS.MODULE]: ACCEPT,
    },
};
