import { get } from 'lodash';

import { configError, paramsSerializer } from '../../utils/utils';
import {
    ApplicationJsonHeaders,
    HeadersAcceptType,
    HttpPatchHeaders,
} from '../../constants';
import {
    mapProductSetFromApi,
    mapProductSetToApi,
} from '../mappers/productSet';

import type { ProductsService } from '../ProductsService';
import type {
    IProductsServiceServices,
    IStockServiceConst,
} from '../productsService.type';

class ProductSet {
    constructor(
        private readonly productsService: ProductsService,
        private readonly services: IProductsServiceServices,
        private readonly URLS: IStockServiceConst['URLS'],
    ) {
        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
    }

    async getList({ filters, per_page = 20, page = 1, sort = {} }) {
        const { ajax } = this.services;
        const { GET_PRODUCT_SET } = this.URLS;

        const defaultSort = !Object.keys(sort).length && {
            createdAt: 'desc',
        };

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                order: defaultSort || sort,
            },
            paramsSerializer,
            headers: ApplicationJsonHeaders,
        };

        if (filters && filters['search']) {
            config.params['search'] = filters['search'];
        }

        try {
            const response = await ajax.get({
                url: GET_PRODUCT_SET,
                config,
            });
            const items = get(response, 'data.results', []);
            const itemsTotal = get(response, 'data.total', []);

            return {
                items: items.map(mapProductSetFromApi),
                itemsTotal,
            };
        } catch {
            return {
                items: [],
                itemsTotal: 0,
            };
        }
    }

    async getById(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;
        const { GET_PRODUCT_SET_ID } = this.URLS;

        const url = GET_PRODUCT_SET_ID.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const config = {
            headers: ApplicationJsonHeaders,
        };

        try {
            const response = await ajax.get({ url, config, errorConfig });

            return mapProductSetFromApi(response.data);
        } catch {
            return {};
        }
    }

    async create(data: object) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.productsService;

        const { POST_PRODUCT_SET } = this.URLS;
        const errorConfig = configError('post', errorHandlers);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        try {
            const productSet = await ajax.post({
                url: POST_PRODUCT_SET,
                data: mapProductSetToApi(data),
                config,
                errorConfig,
            });

            alert.addSuccess({
                message: translator.t(
                    'arm:alerts.productSetCreatedSuccessfully',
                ),
            });

            return mapProductSetFromApi(productSet);
        } catch (error: unknown) {
            if (
                error['response'] &&
                error['response']?.status == 422 &&
                error['response']?.data
            ) {
                const { data } = error['response'];

                if (data?.detail === 'Mixed external producers') {
                    alert.addError({
                        message: translator.t('alerts.mixedExternalProducers', {
                            ns: 'arm',
                        }),
                    });
                }

                if (data?.detail === 'Mixed producer type') {
                    alert.addError({
                        message: translator.t('alerts.mixedProducerType', {
                            ns: 'arm',
                        }),
                    });
                }
            }

            throw error;
        }
    }

    async getCSVFile(filters = {}, fileName) {
        const { ajax } = this.services;
        const { GET_PRODUCT_SET_CSV_FILE } = this.URLS;

        const url = GET_PRODUCT_SET_CSV_FILE;

        const config = {
            params: {
                ...filters,
            },
            headers: {
                Accept: HeadersAcceptType.csv,
            },
            paramsSerializer,
        };

        const errorConfig = {
            addGenericAlert: false,
        };

        return ajax.download({ url, config, fileName, errorConfig });
    }

    async updateById(id: string, data: object) {
        const { ajax } = this.services;
        const { PATCH_PRODUCT_SET_ID } = this.URLS;

        return ajax.patch({
            url: PATCH_PRODUCT_SET_ID.replace('{id}', id),
            data,
            config: {
                headers: HttpPatchHeaders,
            },
        });
    }
}

export default ProductSet;
