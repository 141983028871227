import { getErrorsFromResponse } from '../helpers';
import { fromJS } from 'immutable';

export function createRequest({ options, state }) {
    const { singularName } = options;

    return state
        .set(`${ singularName }Created`, false)
        .setIn(['loaders', `${ singularName }Create`], true)
        .set('postResponseSuccess', null);
}

export function createFailure({ options, state, action }) {
    const { singularName } = options;
    const createdErrorsByField = getErrorsFromResponse(action.payload.response);

    return state
        .set('errorsByField', fromJS(createdErrorsByField))
        .setIn(['loaders', `${ singularName }Create`], false)
        .set('postResponseSuccess', false);
}

export function createSuccess({ options, state, action }) {
    const { pluralName, singularName } = options;
    const successfullyCreatedItem = action.payload.response || { id: null };

    return state
        .set(`${ singularName }Created`, true)
        .set('errorsByField', fromJS({}))
        .set(`${ pluralName }Count`, state.get('contractorsCount') + 1)
        .setIn([`${ pluralName }ById`, successfullyCreatedItem.id.toString()], fromJS(successfullyCreatedItem))
        .setIn(['loaders', `${ singularName }Create`], false)
        .set('postResponseSuccess', true);
}
