import { get } from 'lodash';

const PRODUCTS_URL = get(window, 'env.PRODUCTS_URL', '');

const PRODUCTS_URLS = {
    GET_PRODUCT: `${PRODUCTS_URL}/api/products/{id}`,
    GET_PRODUCTS: `${PRODUCTS_URL}/api/products`,
    POST_PRODUCTS_LIST_CSV_FILE: `${PRODUCTS_URL}/api/products/csv`,
    GET_PRODUCTS_LIST_CSV_FILE: `${PRODUCTS_URL}/api/products/download/{id}`,
} as const;

const PRODUCTS_SET_ID_URL = `${PRODUCTS_URL}/api/product_sets/{id}` as const;
const PRODUCT_SETS_URLS = {
    GET_PRODUCT_SET: `${PRODUCTS_URL}/api/product_sets`,
    POST_PRODUCT_SET: `${PRODUCTS_URL}/api/product_sets`,
    GET_PRODUCT_SET_CSV_FILE: `${PRODUCTS_URL}/api/product_sets/csv`,
    GET_PRODUCT_SET_ID: PRODUCTS_SET_ID_URL,
    PATCH_PRODUCT_SET_ID: PRODUCTS_SET_ID_URL,
} as const;

const PRODUCT_SET_TYPES_URLS = {
    GET_PRODUCT_SET_TYPES: `${PRODUCTS_URL}/api/product_set_types`,
    DELETE_PRODUCT_SET_TYPE: `${PRODUCTS_URL}/api/product_set_types/{id}`,
    POST_PRODUCT_SET_TYPE: `${PRODUCTS_URL}/api/product_set_types`,
    PATCH_PRODUCT_SET_TYPE: `${PRODUCTS_URL}/api/product_set_types/{id}`,
} as const;

export const URLS = {
    ...PRODUCTS_URLS,
    ...PRODUCT_SETS_URLS,
    ...PRODUCT_SET_TYPES_URLS,
} as const;
