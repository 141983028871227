export const reduceToActionMap = (actionsConfigurations) => (actionMapInConfiguration, actionConfig) => {
    const {
        constant,
        constants,
        action,
        actions,
    } = actionConfig;

    if (constant && action) {
        return configureSimpleAction({
            actionMap: actionMapInConfiguration,
            constant,
            action,
            actionsConfigurations,
        });
    }

    if (constants && actions) {
        return configureComplexActions({
            actionMap: actionMapInConfiguration,
            constants,
            actions,
            actionsConfigurations,
        });
    }
};

function configureComplexActions({ actionMap, constants, actions, actionsConfigurations }) {
    Object.keys(constants).forEach((constantKey) => {
        const actionConstant = constants[constantKey];

        actionMap[actionConstant] = actions[constantKey](actionsConfigurations);
    });

    return actionMap;
}

function configureSimpleAction({ actionMap, constant, action, actionsConfigurations }) {
    actionMap[constant] = action(actionsConfigurations);

    return actionMap;
}
