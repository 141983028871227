import get from 'lodash/get';
import { mapUserFromApi } from './user';
import { mapDateFromApi } from './date';

export const mapOfferFromApi = (data = {}) => {
    return {
        id: get(data, 'id', ''),
        name: get(data, 'name', ''),
        skuGenerator: get(data, 'sku_generator', ''),
        productsCount: get(data, 'products_count', 0),
        intermediateProductsCount: get(data, 'intermediate_products_count', 0),
        createdAt: mapDateFromApi(data.created_at),
        createdBy: mapUserFromApi(data.created_by),
        updatedAt: mapDateFromApi(data.updated_at),
        updatedBy: mapUserFromApi(data.updated_by),
    };
};

export const mapOfferToApi = (data) => {
    return {
        name: get(data, 'name', ''),
        sku_generator: get(data, 'sku_generator', ''),
    };
};

export const mapOfferEditToApi = (data) => {
    return {
        name: get(data, 'name', ''),
    };
};

export const mapIntermediateProductFromApi = (data) => {
    return {
        offerItemId: get(data, 'id', ''),
        id: get(data, 'intermediate_product.id', ''),
        name: get(data, 'intermediate_product.name', ''),
        code: get(data, 'intermediate_product.code', ''),
        width: (data?.intermediate_product?.width || 0) / 10,
        height: (data?.intermediate_product?.height || 0) / 10,
        parts: data?.intermediate_product?.parts || '',
        technology: get(data, 'intermediate_product.technology', {}),
        categoryDefinitionId: data?.category_definition?.id || '',
        categoryDefinitionName: data?.category_definition?.name || '',
    };
};

export const mapOfferIntermediateProductsFromApi = (data) => {
    return get(data, 'intermediate_products', []).map(mapIntermediateProductFromApi);
};

const mapIntermediateProductToApi = (data) => {
    return {
        id: get(data, 'id', ''),
    };
};

export const mapIntermediateProductsToApi = (data = []) => {
    return data.map(mapIntermediateProductToApi);
};
