import { fromJS } from 'immutable';
import * as C from './constants';
import { toIdMap } from 'utils/immutable/list';

const initialState = fromJS({
    presetsById: {},
    presetsCount: 0,

    fetchListLoader: false,

    loaders: {
        presetCreate: false,
    },
    errors: '',
});

export function presets(state = initialState, action) {
    let error = '';
    let newSet = {};

    switch (action.type) {
        case C.FETCH_PRESETS.request:
            return state
                .set('fetchListLoader', true);
        case C.FETCH_PRESETS.failure:
            return state
                .set('fetchListLoader', false);
        case C.FETCH_PRESETS.success:
            return state
                .set('presetsCount', action.payload.response.length)
                .set('fetchListLoader', false)
                .set('presetsById', toIdMap(fromJS(action.payload.response)));

        case C.DUPLICATE_PRESET.request:
            return state
                .setIn(['loaders', 'presetCreate'], true)
                .set('errors', '-');
        case C.DUPLICATE_PRESET.failure:
            error = action.payload.error.response.data.name[0];

            return state
                .setIn(['loaders', 'presetCreate'], false)
                .set('errors', error);
        case C.DUPLICATE_PRESET.success:
            newSet = action.payload.response || { id: null };

            return state
                .set('presetsCount', state.get('presetsCount') + 1)
                .setIn(['presetsById', newSet.id.toString()], fromJS(newSet))
                .set('errors', '')
                .setIn(['loaders', 'presetCreate'], false);

        case C.REMOVE_PRESET.request:
            return state;
        case C.REMOVE_PRESET.failure:
            return state;
        case C.REMOVE_PRESET.success:
            return state
                .set('presetsCount', state.get('presetsCount') - 1)
                .deleteIn(['presetsById', action.payload.id]);

        default:
            return state;
    }
}
