import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function LastPageIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -0.5 9 9"
            { ...props }
        >
            <path
                d="M0.224075 0.566895C0.161007 0.566895 0.105146 0.607052 0.0811204 0.668244C0.0576952 0.730075 0.0715101 0.800191 0.116559 0.846723L3.08316 3.99495L0.11656 7.14318C0.0763164 7.18397 0.0600989 7.24516 0.0745145 7.30189C0.0883294 7.35926 0.130375 7.40388 0.184433 7.41854C0.237891 7.43384 0.295553 7.41663 0.333994 7.37392L3.51803 3.99495L0.333993 0.615976C0.305162 0.584105 0.265519 0.566894 0.224075 0.566895ZM2.22243 0.566894C2.15996 0.566894 2.1041 0.607052 2.08008 0.668244C2.05665 0.730074 2.07047 0.80019 2.11551 0.846722L5.08211 3.99495L2.11552 7.14318C2.07527 7.18397 2.05905 7.24516 2.07347 7.30189C2.08728 7.35926 2.12933 7.40388 2.18339 7.41854C2.23685 7.43384 2.29451 7.41663 2.33295 7.37392L5.51698 3.99495L2.33295 0.615976C2.30412 0.584104 2.26447 0.566894 2.22243 0.566894Z"
            />
        </SvgIcon>
    );
}

LastPageIcon.defaultProps = {
    titleAccess: 'last-page-icon',
};

LastPageIcon.propTypes = svgIconPropTypes;
