import { AjaxService } from 'services/ajax/AjaxService';
import DiscountsActions from './actions/Discounts';
import type { default as ErrorHandlersConfigFactory } from './errorHandlers';
import { AlertService } from 'services/alert/AlertService';
import { ProductSetDiscount } from './actions/ProductSetDiscount';
import type { URLS as URLS_CONST } from './constants';

export class DiscountsService {
    public discounts: DiscountsActions;
    public ProductSetDiscount: ProductSetDiscount;

    private readonly ajaxService: AjaxService;
    private readonly errorHandlers: ReturnType<
        typeof ErrorHandlersConfigFactory
    >;
    private readonly URLS: typeof URLS_CONST;

    private constructor({
        services,
        errorHandlersConfig,
        URLS,
    }: {
        services: {
            ajax: AjaxService;
            alert: AlertService;
        };
        errorHandlersConfig: typeof ErrorHandlersConfigFactory;
        URLS: typeof URLS_CONST;
    }) {
        this.ajaxService = services.ajax;
        this.errorHandlers = errorHandlersConfig(services.alert);
        this.URLS = URLS;

        this.discounts = new DiscountsActions(this, services, URLS);
        this.ProductSetDiscount = new ProductSetDiscount(this, services, URLS);
    }

    private static instance: DiscountsService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        URLS,
    }: {
        services: {
            ajax: AjaxService;
            alert: AlertService;
        };
        errorHandlersConfig: typeof ErrorHandlersConfigFactory;
        URLS: typeof URLS_CONST;
    }): DiscountsService {
        if (!DiscountsService.instance) {
            DiscountsService.instance = new DiscountsService({
                services,
                errorHandlersConfig,
                URLS,
            });
        }

        return DiscountsService.instance;
    }
}
