import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function SyncIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1.5 16 16"
            { ...props }
        >
            <path
                d="M7.00002 0C3.13772 0 2.09484e-05 3.1377 2.09484e-05 7C-0.00134615 7.12578 0.0642789 7.24336 0.173654 7.30762C0.283029 7.37051 0.417013 7.37051 0.526388 7.30762C0.635763 7.24336 0.701388 7.12578 0.700021 7C0.700021 3.51641 3.51643 0.7 7.00002 0.7C9.18615 0.7 11.1043 1.81289 12.235 3.5H9.80002C9.67424 3.49863 9.55666 3.56426 9.4924 3.67363C9.42951 3.78301 9.42951 3.91699 9.4924 4.02637C9.55666 4.13574 9.67424 4.20137 9.80002 4.2H13.3V0.7C13.3014 0.605664 13.2645 0.514063 13.1975 0.44707C13.1305 0.380078 13.0389 0.343164 12.9446 0.344531C12.7518 0.348633 12.5973 0.507227 12.6 0.7V2.8041C11.3217 1.10195 9.28869 0 7.00002 0ZM13.6446 6.64453C13.4518 6.64863 13.2973 6.80723 13.3 7C13.3 10.4836 10.4836 13.3 7.00002 13.3C4.81389 13.3 2.89436 12.1871 1.76506 10.5H4.20002C4.3258 10.5027 4.44338 10.4357 4.50764 10.3277C4.57053 10.2184 4.57053 10.083 4.50764 9.97363C4.44338 9.86562 4.3258 9.79863 4.20002 9.8H1.20588C1.16076 9.7918 1.11565 9.7918 1.07053 9.8H0.700021V13.3C0.698654 13.4258 0.764279 13.5434 0.873654 13.6076C0.983029 13.6705 1.11701 13.6705 1.22639 13.6076C1.33576 13.5434 1.40139 13.4258 1.40002 13.3V11.1945C2.67697 12.8967 4.71135 14 7.00002 14C10.8623 14 14 10.8623 14 7C14.0014 6.90566 13.9645 6.81406 13.8975 6.74707C13.8305 6.68008 13.7389 6.64316 13.6446 6.64453Z"
            />
        </SvgIcon>
    );
}

SyncIcon.defaultProps = {
    titleAccess: 'sync-icon',
};

SyncIcon.propTypes = svgIconPropTypes;
