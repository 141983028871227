import React from 'react';
import moment from 'moment';
import i18n from 'i18n';
import { withTranslation } from 'react-i18next';
import { Divider, MenuItem } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Hidden from 'components/Hidden/hidden';
import Typography from 'components/Typography';
import Button from 'components/Button/button';
import {
    GearIcon as SettingsIcon,
    MenuIcon,
    MoreIcon,
    UserIcon as AccountCircle,
} from 'components/Icons/Icons';

import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import {
    AppBarStyled,
    EnvironmentInfoStyled,
    GrowStyled,
    MainInfoStyled,
    MenuButtonStyled,
    ModuleNameStyled,
    SectionDesktopStyled,
    SectionMobileStyled,
    TitleStyled,
    UserInfoActionStyled,
    UserInfoListItemStyled,
} from 'modules/app/components/Header/header.styles';
import { getActiveToken } from 'access/votingMechanism.js';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            menuItems: [],
        };
        this.envMessage = this.getEnvWarningMessage();
    }

    getEnvWarningMessage() {
        switch (window.env.APP_ENV_WARNING) {
            case 'local':
                return 'local build';
            case 'test':
                return `test arte ${window.env.APPLICATION_VERSION}`;
            case 'stage':
                return `stage arte ${window.env.APPLICATION_VERSION}`;
            default:
                return null;
        }
    }

    handleLogout = () => {
        const { logout, push } = this.props;

        logout();
        push({ pathname: '/' });
    };

    handleMenuOpen = (event, menuItems) => {
        this.setState({
            anchorEl: event.currentTarget,
            menuItems,
        });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleOpenSettings = () => {
        const { push } = this.props;

        push('/settings');
    };

    handleOpenWorkplace = () => {
        const { push } = this.props;

        push('/workplace');
    };

    setLanguage = (lng) => {
        i18n.changeLanguage(lng);
        moment.locale(lng);
        this.handleMenuClose();
    };

    render() {
        const {
            t,
            toggleNavBar,
            path,
            toggleMobileNavBar,
            extendedNavBar,
            getCachedWorkplaces,
        } = this.props;
        const { anchorEl, menuItems } = this.state;
        const token = getActiveToken();
        const isMenuOpen = Boolean(anchorEl);

        const workplace = getCachedWorkplaces()?.items?.find(
            ({ id }) => id === token?.workplace,
        );

        const settingsMenuItems = [
            <MenuItem
                key="lang:pl"
                onClick={() => this.setLanguage('pl')}
            >
                {t('actions.languagePl')}
            </MenuItem>,
            <MenuItem
                key="lang:en"
                onClick={() => this.setLanguage('en')}
            >
                {t('actions.languageEn')}
            </MenuItem>,
        ];

        const profileMenuItems = [
            <UserInfoListItemStyled
                id="app-bar-menu-account-info"
                component="div"
                key="userInfo"
            >
                <MainInfoStyled variant="body2">
                    {`${token.first_name} ${token.last_name}`}
                </MainInfoStyled>
                <Typography variant="body2">{token.username}</Typography>
                <Typography variant="body2">{token.email}</Typography>
                {token?.workplace && (
                    <Typography variant="body2">
                        {`${t('actions.workplace')}: ${workplace?.label}`}
                    </Typography>
                )}
            </UserInfoListItemStyled>,
            <Divider key="divider" />,
            <UserInfoActionStyled
                component="div"
                key="settings"
            >
                <Button
                    id="app-bar-menu-open-settings-button"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={this.handleOpenSettings}
                >
                    {t('actions.openSettings')}
                </Button>
            </UserInfoActionStyled>,
            token?.workplace && (
                <UserInfoActionStyled
                    component="div"
                    key="workplace"
                >
                    <Button
                        id="app-bar-menu-open-workplace-button"
                        color="primary"
                        variant="text"
                        fullWidth
                        onClick={this.handleOpenWorkplace}
                    >
                        {t('actions.workplace')}
                    </Button>
                </UserInfoActionStyled>
            ),
            <UserInfoActionStyled
                component="div"
                key="logout"
            >
                <Button
                    id="app-bar-menu-logout-button"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={this.handleLogout}
                >
                    {t('actions.logout')}
                </Button>
            </UserInfoActionStyled>,
        ];

        const mobileMenuItems = [
            <Divider key="languageDivider" />,
            <UserInfoActionStyled
                id="app-bar-menu-languages"
                component="div"
                key="languages"
            >
                <Button
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={() => this.setLanguage('pl')}
                >
                    {t('actions.languagePl')}
                </Button>
                <Button
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={() => this.setLanguage('en')}
                >
                    {t('actions.languageEn')}
                </Button>
            </UserInfoActionStyled>,
        ];

        const allItems = profileMenuItems.concat(mobileMenuItems);

        return (
            <AppBarStyled
                id="app-bar"
                position="absolute"
            >
                <Toolbar>
                    <Hidden smDown>
                        <MenuButtonStyled
                            id="app-bar-hamburger-button"
                            color="inherit"
                            onClick={toggleNavBar}
                            size="large"
                        >
                            <MenuIcon />
                        </MenuButtonStyled>
                    </Hidden>
                    <Hidden smUp>
                        <MenuButtonStyled
                            id="app-bar-hamburger-button"
                            color="inherit"
                            onClick={toggleMobileNavBar}
                            size="large"
                        >
                            <MenuIcon />
                        </MenuButtonStyled>
                    </Hidden>
                    <TitleStyled
                        variant="h2"
                        noWrap
                    >
                        ARTE
                    </TitleStyled>
                    {!extendedNavBar && (
                        <ModuleNameStyled
                            variant="h6"
                            noWrap
                        >
                            {t(`navigation.${path[1]}.${path[2]}`, {
                                defaultValue: '',
                            })}
                        </ModuleNameStyled>
                    )}
                    <GrowStyled />

                    <SectionDesktopStyled>
                        <IconButton
                            id="app-bar-settings-button"
                            color="inherit"
                            onClick={(e) =>
                                this.handleMenuOpen(e, settingsMenuItems)
                            }
                            size="large"
                        >
                            <SettingsIcon />
                        </IconButton>
                        <IconButton
                            id="app-bar-account-button"
                            color="inherit"
                            onClick={(e) =>
                                this.handleMenuOpen(e, profileMenuItems)
                            }
                            size="large"
                        >
                            <AccountCircle />
                        </IconButton>
                    </SectionDesktopStyled>

                    <SectionMobileStyled>
                        <IconButton
                            onClick={(e) => this.handleMenuOpen(e, allItems)}
                            color="inherit"
                            size="large"
                        >
                            <MoreIcon />
                        </IconButton>
                    </SectionMobileStyled>
                </Toolbar>
                <Menu
                    id="app-bar-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}
                >
                    {menuItems}
                </Menu>
                {this.envMessage && (
                    <EnvironmentInfoStyled variant="caption">
                        {this.envMessage}
                    </EnvironmentInfoStyled>
                )}
            </AppBarStyled>
        );
    }
}

Header.defaultProps = {
    t: (key) => key,
};

Header.propTypes = {
    t: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    toggleNavBar: PropTypes.func.isRequired,
    path: PropTypes.array.isRequired,
    toggleMobileNavBar: PropTypes.func.isRequired,
    extendedNavBar: PropTypes.bool.isRequired,
    getCachedWorkplaces: PropTypes.func.isRequired,
};

const HeaderWithHOC = withTranslation('app')(Header);

export default HeaderWithHOC;
