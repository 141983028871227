import { EmailMessageActions } from 'services/notificationCenter/actions/emailMessage/emailMessage.actions';
import type { AjaxService } from '../ajax/AjaxService';
import type { URLS } from './constants';
import type { errorHandlers as errorHandlersHelper } from './errorHandlers';
import type { default as queryParser } from 'qs';

export class NotificationCenterService {
    private readonly emailMessageActions: EmailMessageActions;

    private constructor(ajaxService: AjaxService, errorHandlers: ReturnType<typeof errorHandlersHelper>, urls: typeof URLS, parser: typeof queryParser) {
        this.emailMessageActions = new EmailMessageActions(ajaxService, urls, errorHandlers, parser);

        this.getEmailMessageActions = this.getEmailMessageActions.bind(this);
    }

    private static instance: NotificationCenterService | undefined;

    static getInstance(ajaxService: AjaxService, errorHandlers: ReturnType<typeof errorHandlersHelper>, urls: typeof URLS, parser: typeof queryParser): NotificationCenterService {
        if (!NotificationCenterService.instance) {
            NotificationCenterService.instance = new NotificationCenterService(ajaxService, errorHandlers, urls, parser);
        }

        return NotificationCenterService.instance;
    }

    getEmailMessageActions(): EmailMessageActions {
        return this.emailMessageActions;
    }
}
