import { fromJS, List } from 'immutable';
import moment from 'moment';
import * as C from './constants';

const initialState = fromJS({});

function fetchListRequest(state, action) {
    const {
        listId,
    } = action.payload;
    const options = state.getIn([listId, 'options']) || new List();

    return state
        .setIn([listId, 'options'], options)
        .setIn([listId, 'loading'], true);
}

function fetchListFailure(state, action) {
    const {
        listId,
    } = action.payload;

    return state
        .setIn([listId, 'loading'], false);
}

function fetchListSuccess(state, action) {
    const {
        listId,
        mapResponseToOptions = () => [],
        paramsId,
        response = {},
    } = action.payload;
    let optionsList;
    const options = new List(mapResponseToOptions(response));
    const requestTime = moment().format('X');
    const lastRequest = state.getIn([listId, 'lastRequest']);
    const secondFromLastRequest = requestTime - lastRequest;

    if (secondFromLastRequest > 300) {
        optionsList = options;
    } else {
        optionsList = state.getIn([listId, 'options']).merge(options);
    }

    return state
        .setIn([listId, 'options'], optionsList)
        .setIn([listId, 'lastRequest'], requestTime)
        .setIn([listId, 'requests', paramsId], requestTime)
        .setIn([listId, 'loading'], false);
}

export function autocompleteReducer(state = initialState, action) {
    switch (action.type) {
        case C.FETCH_AUTOCOMPLETE_LIST.request: return fetchListRequest(state, action);
        case C.FETCH_AUTOCOMPLETE_LIST.failure: return fetchListFailure(state, action);
        case C.FETCH_AUTOCOMPLETE_LIST.success: return fetchListSuccess(state, action);

        default:
            return state;
    }
}
