import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import type { PhotoInputItemDto, PhotosGetDto } from './photo.type';

export class PhotoActions {
    private static instance: PhotoActions | undefined;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ) {
        this.getPhotos = this.getPhotos.bind(this);
        this.uploadPhotos = this.uploadPhotos.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ): PhotoActions {
        if (!PhotoActions.instance) {
            PhotoActions.instance = new PhotoActions(
                ajaxService,
                errorHandlers,
            );
        }

        return PhotoActions.instance;
    }

    async getPhotos(id: string): Promise<PhotosGetDto> {
        const response: { data: PhotosGetDto } = await this.ajaxService.get({
            url: URLS.CUSTOMER_REQUEST_PHOTOS.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.get,
            },
            onError: this.errorHandlers.get,
        });

        return response.data;
    }

    async uploadPhotos(
        id: string,
        payload: PhotoInputItemDto[],
    ): Promise<void> {
        await this.ajaxService.post({
            url: URLS.CUSTOMER_REQUEST_PHOTOS.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: { items: payload },
            errorConfig: {
                onError: this.errorHandlers.post,
            },
            onError: this.errorHandlers.post,
        });
    }

    async deletePhoto(id: string): Promise<void> {
        await this.ajaxService.delete({
            url: URLS.PHOTOS.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.delete,
            },
            onError: this.errorHandlers.delete,
        });
    }
}
