import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CheckBoxCheckedIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <rect
                width="14"
                height="14"
                rx="3"
                fill="#3974C0"
            />
            <path
                d="M11.7923 3.39617C11.6884 3.39927 11.5898 3.4427 11.5173 3.51726L5.40013 9.63445L2.88294 7.11726C2.84609 7.07887 2.80194 7.04823 2.75309 7.02711C2.70424 7.006 2.65166 6.99484 2.59845 6.9943C2.54523 6.99376 2.49244 7.00384 2.44317 7.02396C2.3939 7.04407 2.34914 7.07382 2.3115 7.11145C2.27387 7.14908 2.24413 7.19384 2.22401 7.24311C2.2039 7.29239 2.19382 7.34517 2.19436 7.39839C2.1949 7.45161 2.20605 7.50418 2.22717 7.55303C2.24828 7.60188 2.27893 7.64603 2.31732 7.68289L5.11732 10.4829C5.19234 10.5579 5.29406 10.6 5.40013 10.6C5.5062 10.6 5.60793 10.5579 5.68294 10.4829L12.0829 4.08289C12.1407 4.0267 12.1802 3.95437 12.1962 3.87536C12.2122 3.79635 12.2039 3.71436 12.1725 3.64012C12.1411 3.56589 12.088 3.50288 12.0201 3.45935C11.9523 3.41581 11.8729 3.39379 11.7923 3.39617Z"
                fill="white"
            />

        </SvgIcon>
    );
}

CheckBoxCheckedIcon.defaultProps = {
    titleAccess: 'check-box-checked-icon',
};

CheckBoxCheckedIcon.propTypes = svgIconPropTypes;
