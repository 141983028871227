import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CheckBoxUncheckedIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <rect
                x="0.5"
                y="0.5"
                width="13"
                height="13"
                rx="2.5"
                stroke="#BDBDBD"
                fill="white"
            />
        </SvgIcon>
    );
}

CheckBoxUncheckedIcon.defaultProps = {
    titleAccess: 'check-box-unchecked-icon',
};

CheckBoxUncheckedIcon.propTypes = svgIconPropTypes;
