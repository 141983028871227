import type { SalesChannelsService } from '../SalesChannelsService';
import { mapCompanyFromApi } from '../mappers/company';
import get from 'lodash/get';

class Companies {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getList = this.getList.bind(this);
    }
    async getList(
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            search?: string | undefined;
            sort?: unknown | undefined;
        } = {},
    ) {
        const { ajax, URLS, DEFAULTS, paramsSerializer } =
            this.salesChannelsService;
        const {
            page = 1,
            per_page = DEFAULTS.COMPANIES_PER_PAGE,
            search,
            sort,
        } = params;

        const url = URLS.GET_COMPANIES;
        const config = {
            params: {
                page,
                per_page,
                order: sort,
            },
            paramsSerializer,
        };

        if (search) {
            const filtersParams = { match: search };

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        const response = await ajax.get({ url, config });

        return {
            items: get(response, 'data.hydra:member', []).map(
                mapCompanyFromApi,
            ),
            totalItems: get(response, 'data.hydra:totalItems', 0),
        };
    }
}

export default Companies;
