import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CategoryIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            { ...props }
        >
            <path
                d="M3.32001 0.0799561C1.53548 0.0799561 0.0800112 1.53543 0.0800112 3.31996C0.0800112 5.10449 1.53548 6.55996 3.32001 6.55996C5.10454 6.55996 6.56001 5.10449 6.56001 3.31996C6.56001 1.53543 5.10454 0.0799561 3.32001 0.0799561ZM10.16 0.439956C9.96173 0.439956 9.80001 0.601675 9.80001 0.799956V5.83996C9.80001 6.03824 9.96173 6.19996 10.16 6.19996H15.2C15.3983 6.19996 15.56 6.03824 15.56 5.83996V0.799956C15.56 0.601675 15.3983 0.439956 15.2 0.439956H10.16ZM3.32001 0.799956C4.71642 0.799956 5.84001 1.92355 5.84001 3.31996C5.84001 4.71636 4.71642 5.83996 3.32001 5.83996C1.9236 5.83996 0.800011 4.71636 0.800011 3.31996C0.800011 1.92355 1.9236 0.799956 3.32001 0.799956ZM10.52 1.15996H14.84V5.47996H10.52V1.15996ZM10.9011 9.07996C10.7422 9.07996 10.603 9.18261 10.5552 9.33308L9.45689 12.8937C9.41189 13.0371 9.4611 13.1946 9.58064 13.2861L12.4606 15.4868C12.59 15.5853 12.77 15.5853 12.8994 15.4868L15.7794 13.2861C15.8989 13.1946 15.9481 13.0371 15.9031 12.8937L14.8049 9.33308C14.757 9.18261 14.6178 9.07996 14.4589 9.07996H10.9011ZM3.30314 9.43996C3.17939 9.44699 3.06829 9.51449 3.00642 9.62136L0.127824 14.6614C0.0631362 14.7725 0.0645424 14.9103 0.127824 15.0214C0.192511 15.1325 0.312042 15.2 0.440011 15.2H6.20001C6.32798 15.2 6.44751 15.1325 6.5122 15.0214C6.57548 14.9103 6.57689 14.7725 6.5136 14.6614L3.6336 9.62136C3.5661 9.50324 3.43814 9.43433 3.30314 9.43996ZM11.1655 9.79996H14.1945L15.1424 12.8656L12.68 14.7471L10.2177 12.8656L11.1655 9.79996ZM3.32001 10.5256L5.57985 14.48H1.06157L3.32001 10.5256Z"
            />
        </SvgIcon>
    );
}

CategoryIcon.defaultProps = {
    titleAccess: 'category-icon',
};

CategoryIcon.propTypes = svgIconPropTypes;
