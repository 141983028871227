import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import common from './common/reducer';
import * as commonActions from './common/actions';

import userProfile from './userProfile/reducer';
import userProfileSaga from './userProfile/saga';
import * as userProfileActions from './userProfile/actions';

import { presetsSaga } from './presets/saga';
import * as presetsActions from './presets/actions';
import { presets } from './presets/reducer';

import { autocompleteSaga } from './autocomplete/saga';
import * as autocompleteActions from './autocomplete/actions';
import { autocompleteReducer } from './autocomplete/reducer';

export const appReducers = combineReducers({
    common,
    userProfile,
    presets,
    autocompleteReducer,
});

export const appSagas = [
    fork(userProfileSaga),
    fork(presetsSaga),
    fork(autocompleteSaga),
];

export const appActions = [
    commonActions,
    userProfileActions,
    presetsActions,
    autocompleteActions,
];
