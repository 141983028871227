import {
    mapCustomerRequestProcess,
    mapCustomerRequestsVariable,
} from '../mappers/customerRequest';

import { mapCamundaFilter } from '../../../utils/filters';
import { ApplicationJsonHeaders } from '../../constants';

class CustomerRequest {
    constructor(services, URLS, DEFAULTS, errorHandlers) {
        this.services = services;
        this.params = services.OFFERS_PARAMS || {};
        this.errorHandlers = errorHandlers;
        this.alert = services.alert;
        this.translator = services.translator;
        this.ALERTS = services.ALERT_MESSAGES;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;

        this.getList = this.getList.bind(this);
    }
    getCustomerRequestProcessList(params = {}) {
        const {
            ajax, paramsSerializer,
        } = this.services;

        const {
            page = 1,
            perPage = this.DEFAULTS.PER_PAGE,
            filters = {},
        } = params;

        const url = this.URLS.CUSTOMER_REQUESTS;

        const config = {
            params: {
                page,
                itemsPerPage: perPage,
                processDefinitionKey: 'cus_req_process',
                'sorting[startTime]': 'desc',
            },
            headers: ApplicationJsonHeaders,
            paramsSerializer,
        };

        const mapFilters = this.mapFilters();
        const mappedFilters = Object.keys(filters).reduce((prev, curr) => {
            const mappedFilter = mapFilters[curr];

            if (mappedFilter && filters[curr]) {
                const { filterName, filterValue } = mapCamundaFilter({
                    name: mappedFilter.name,
                    type: mappedFilter.type,
                    typeValue: mappedFilter.typeValue,
                    value: mappedFilter.customValue ?? filters[curr],
                });

                return {
                    ...prev,
                    [filterName]: filterValue,
                };
            } else {
                return {
                    ...prev,
                };
            }
        }, {});

        if (Object.keys(mappedFilters).length) config.params = { ...config.params, ...mappedFilters };

        return ajax
            .get({ url, config });
    }
    getCustomerRequestVariableList(customerRequestsIds) {
        const { ajax } = this.services;
        const url = this.URLS.CUSTOMER_REQUESTS_VARIABLE;
        const config = {
            params: {
                itemsPerPage: 1000,
            },
            headers: ApplicationJsonHeaders,
            data: {
                process_instance_id_in: customerRequestsIds,
            },
        };

        return ajax
            .post({ url, config });
    }
    async getList(params = {}) {
        try {
            const customerRequestsProcessData = await this.getCustomerRequestProcessList(params);
            const processIds = customerRequestsProcessData?.data?.results.map(mapCustomerRequestProcess);

            if (!processIds.length) {
                return {
                    items: [],
                    totalItems: 0,
                };
            }
            const customerRequestsVariableData = await this.getCustomerRequestVariableList(processIds);
            const reduceData = mapCustomerRequestsVariable(
                customerRequestsVariableData.data.results,
                customerRequestsProcessData.data.results);

            return {
                items: reduceData,
                totalItems: customerRequestsProcessData.data.total,
            };
        } catch (e) {
            return e;
        }
    }

    mapFilters() {
        return {
            platform: {
                name: 'platform',
                type: 'variable',
                typeValue: 'eq',
                customValue: null,
            },
            marketplaceCountryCode: {
                type: 'variable',
                typeValue: 'eq',
                name: 'marketplaceCountryCode',
                customValue: null,
            },
            pdpUserId: {
                name: 'pdpUserId',
                type: 'variable',
                typeValue: 'eq',
                customValue: null,
            },
            orderName: {
                name: 'orderName',
                type: 'variable',
                typeValue: 'like',
                customValue: null,
            },
            customerNotes: {
                name: 'customerNotes',
                type: 'variable',
                typeValue: 'like',
                customValue: ' ',
            },
            customerRequestFile: {
                name: 'customerRequestFile',
                type: 'variable',
                typeValue: 'like',
                customValue: '/',
            },
            mainReason: {
                name: 'mainReason',
                type: 'variable',
                typeValue: 'eq',
                customValue: null,
            },
            status: {
                name: 'status',
                type: 'boolean',
                typeValue: true,
                customValue: null,
            },
            submissionType: {
                name: 'submissionType',
                type: 'variable',
                typeValue: 'eq',
                customValue: null,
            },
            startedAfter: {
                name: 'startedAfter',
                type: 'normal',
                typeValue: null,
                customValue: null,
            },
            startedBefore: {
                name: 'startedBefore',
                type: 'normal',
                typeValue: null,
                customValue: null,
            },
        };
    }
}

export default CustomerRequest;
