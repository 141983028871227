import Orders from 'services/production/actions/orders/Orders';
import { ProductionOrdersActions } from 'services/production/actions/productionOrders/productionOrders.actions';
import { URLS } from './constants';
import { ShipmentsActions } from 'services/production/actions/shipments/shipments.actions';
import { WorkersActions } from './actions/workers/Workers.actions';

export class ProductionService {
    private static instance: ProductionService | undefined;
    private readonly productionOrdersActions: ProductionOrdersActions;
    private readonly shipmentsActions: ShipmentsActions;
    private readonly workersActions: WorkersActions;

    private constructor({ services, errorHandlersConfig, CONSTANTS }) {
        this.ajaxService = services.ajax;
        this.alert = services.alert;
        this.parser = services.parser;
        this.translator = services.translator;

        const resolvedErrorHandlers = errorHandlersConfig(services.alert);

        this.errorHandlers = resolvedErrorHandlers;
        this.constants = CONSTANTS;

        this.orders = new Orders(this);
        this.productionOrdersActions = ProductionOrdersActions.getInstance(
            services.ajax,
            URLS,
            resolvedErrorHandlers,
        );
        this.shipmentsActions = ShipmentsActions.getInstance(
            services.ajax,
            URLS,
            resolvedErrorHandlers,
        );
        this.workersActions = WorkersActions.getInstance(
            services.ajax,
            URLS,
            resolvedErrorHandlers,
        );

        this.getProductionOrdersActions =
            this.getProductionOrdersActions.bind(this);
        this.getShipmentsActions = this.getShipmentsActions.bind(this);
        this.getWorkersActions = this.getWorkersActions.bind(this);
        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    public static getInstance({
        services,
        errorHandlersConfig,
        CONSTANTS,
    }): ProductionService {
        if (!ProductionService.instance) {
            ProductionService.instance = new ProductionService({
                services,
                errorHandlersConfig,
                CONSTANTS,
            });
        }

        return ProductionService.instance;
    }

    getProductionOrdersActions(): ProductionOrdersActions {
        return this.productionOrdersActions;
    }

    getShipmentsActions(): ShipmentsActions {
        return this.shipmentsActions;
    }

    getWorkersActions(): WorkersActions {
        return this.workersActions;
    }

    paramsSerializer = (params) => this.parser.stringify(params);
}
