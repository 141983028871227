import get from 'lodash/get';
import { mapChannelFromApi } from '../mappers/channel';
import { ApplicationJsonHeaders } from '../../constants';
import {
    type URLS as URLS_CONFIG,
    type DEFAULTS as DEFAULTS_CONFIG,
} from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import { TOrderCompany } from 'types/orderCompany.type';
import { TSortMode } from '../../../types/services.type';

class Channel {
    constructor(
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
        private readonly DEFAULTS: typeof DEFAULTS_CONFIG,
    ) {
        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
    }

    async getById(id, company = 'artgeist', ignoreError = false) {
        const url = (
            company === 'artgeist'
                ? this.URLS.GET_CHANNEL
                : this.URLS.DECO_GET_CHANNEL
        ).replace('{id}', id);

        const errorOptions = ignoreError
            ? {
                  onError: () => {},
              }
            : { addGenericAlert: true };

        const { data } = await this.ajaxService.get({ ...errorOptions, url });

        return mapChannelFromApi(data);
    }

    async getList(
        params: Partial<{
            page: number;
            per_page: number;
            filters: {
                search?: string | undefined;
                name?: string | undefined;
                canImportFromFile?: boolean | undefined;
            };
            sort: {
                label?: TSortMode;
            };
        }> = {},
        company: TOrderCompany | undefined = 'artgeist',
    ) {
        const {
            page = 1,
            per_page = this.DEFAULTS.CHANNEL_PER_PAGE,
            filters = {},
            sort = {},
        } = params;

        const url =
            company === 'artgeist'
                ? this.URLS.GET_CHANNELS
                : this.URLS.DECO_GET_CHANNELS;
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                ...(filters.search && { match: filters.search }),
                ...(filters.name && { name: filters.name }),
                ...(filters.canImportFromFile && {
                    canImportFromFile: filters.canImportFromFile,
                }),
                ...(sort?.label && { 'order[label]': sort.label }),
            },
            headers: ApplicationJsonHeaders,
        };

        const response = await this.ajaxService.get({ url, config });

        return {
            items: get(response, 'data.results', []).map(mapChannelFromApi),
            totalItems: get(response, 'data.total', 0),
        };
    }
}

export default Channel;
