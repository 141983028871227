import type { ComponentProps } from 'react';

import { SvgIcon as MaterialSvgIcon } from '@mui/material';

type SvgIconProps = Omit<ComponentProps<typeof MaterialSvgIcon>, 'titleAccess'>;

export const SvgIcon = (props: SvgIconProps) => (
    <MaterialSvgIcon
        {...props}
        titleAccess=""
    />
);
