import type { AjaxService } from '../ajax/AjaxService';
import type { errorHandlers as errorHandlersHelper } from './errorHandlers';
import { CustomerRequestActions } from './actions/customerRequest/customerRequest.actions';
import { PlaceActions } from './actions/place/place.actions';
import { CustomerRequestComplaintActions } from './actions/customerRequestComplaint/customerRequestComplaint.actions';
import { CustomerRequestReturnActions } from './actions/customerRequestReturn/customerRequestReturn.actions';
import { TransitionHistoryActions } from './actions/transitionHistory/transitionHistory.actions';
import { PhotoActions } from './actions/photo/photo.actions';

export class PostSalesService {
    private static instance: PostSalesService | undefined;

    private readonly customerRequestActions: CustomerRequestActions;
    private readonly placeActions: PlaceActions;
    private readonly customerRequestComplaintActions: CustomerRequestComplaintActions;
    private readonly customerRequestReturnActions: CustomerRequestReturnActions;
    private readonly transitionHistoryActions: TransitionHistoryActions;
    private readonly photoActions: PhotoActions;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ) {
        this.customerRequestActions = CustomerRequestActions.getInstance(
            ajaxService,
            errorHandlers,
        );
        this.placeActions = PlaceActions.getInstance(
            ajaxService,
            errorHandlers,
        );
        this.customerRequestComplaintActions =
            CustomerRequestComplaintActions.getInstance(
                ajaxService,
                errorHandlers,
            );
        this.customerRequestReturnActions =
            CustomerRequestReturnActions.getInstance(
                ajaxService,
                errorHandlers,
            );
        this.transitionHistoryActions = TransitionHistoryActions.getInstance(
            ajaxService,
            errorHandlers,
        );

        this.photoActions = PhotoActions.getInstance(
            ajaxService,
            errorHandlers,
        );

        this.getCustomerRequestActions =
            this.getCustomerRequestActions.bind(this);
        this.getPlaceActions = this.getPlaceActions.bind(this);
        this.getCustomerRequestComplaintActions =
            this.getCustomerRequestComplaintActions.bind(this);
        this.getCustomerRequestReturnActions =
            this.getCustomerRequestReturnActions.bind(this);
        this.getTransitionHistoryActions =
            this.getTransitionHistoryActions.bind(this);
        this.getPhotoActions = this.getPhotoActions.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ): PostSalesService {
        if (!PostSalesService.instance) {
            PostSalesService.instance = new PostSalesService(
                ajaxService,
                errorHandlers,
            );
        }

        return PostSalesService.instance;
    }

    getCustomerRequestActions(): CustomerRequestActions {
        return this.customerRequestActions;
    }

    getPlaceActions(): PlaceActions {
        return this.placeActions;
    }

    getCustomerRequestComplaintActions(): CustomerRequestComplaintActions {
        return this.customerRequestComplaintActions;
    }

    getCustomerRequestReturnActions(): CustomerRequestReturnActions {
        return this.customerRequestReturnActions;
    }

    getTransitionHistoryActions(): TransitionHistoryActions {
        return this.transitionHistoryActions;
    }

    getPhotoActions(): PhotoActions {
        return this.photoActions;
    }
}
