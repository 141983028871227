import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import Immutable from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router/immutable';
import { rootActions, rootReducer } from './roots';
import { PRODUCTION_ENV_NAME } from 'utils/enviromentNames';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => {
    const initialState = Immutable.Map();
    const isProduction =
        window.env && window.env.APP_ENV === PRODUCTION_ENV_NAME;

    // Redux configuration
    const middleware = [];
    const enhancers = [];

    // React-router middleware
    middleware.push(routerMiddleware(history));

    // Add saga middleware
    middleware.push(sagaMiddleware);

    // Redux devtools configuration
    const actionCreators = {
        ...rootActions,
    };

    // Use redux devtools
    const composeEnhancers = !isProduction
        ? composeWithDevTools({
              actionCreators,
          })
        : compose;

    // Apply middleware & compose enhancers
    enhancers.push(applyMiddleware(...middleware));

    const enhancer = composeEnhancers(...enhancers);

    return createStore(rootReducer(history), initialState, enhancer);
};
