import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function NavigationIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 0 16 16"
            { ...props }
        >
            <path
                d="M7.00001 0C6.89064 0 6.7922 0.0667187 6.74626 0.16625L0.612515 13.6062C0.565484 13.7091 0.587359 13.8294 0.665015 13.9125C0.718609 13.9694 0.790796 14 0.866265 14C0.900171 14 0.938453 13.9956 0.971265 13.9825L7.00001 11.6637L13.0288 13.9825C13.136 14.023 13.2574 13.9956 13.335 13.9125C13.4127 13.8294 13.4345 13.7091 13.3875 13.6062L7.25377 0.16625C7.20783 0.0667187 7.10939 0 7.00001 0ZM6.72002 1.56625V11.1737L1.40877 13.2125L6.72002 1.56625Z"
            />
        </SvgIcon>
    );
}

NavigationIcon.defaultProps = {
    titleAccess: 'navigation-icon',
};

NavigationIcon.propTypes = svgIconPropTypes;
