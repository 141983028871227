import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        SALES_CHANNELS: {
            CREATE: `${MODULE_NAME}_resources_sales_channels_create`,
            EDIT: `${MODULE_NAME}_resources_sales_channels_edit`,
            FETCH: `${MODULE_NAME}_resources_sales_channels_fetch`,
        },
        OFFERS: {
            CREATE: `${MODULE_NAME}_resources_offers_create`,
            EDIT: `${MODULE_NAME}_resources_offers_create`,
            FETCH: `${MODULE_NAME}_resources_offers_fetch`,
        },
        COMPANIES: {
            FETCH: `${MODULE_NAME}_resources_companies_fetch`,
        },
    },
};
