/* eslint-disable sonarjs/no-duplicate-string */
import Channel from './actions/Channel';
import Order from './actions/order/order.actions';
import OrderInvoice from './actions/OrderInvoice';
import Package from './actions/Package';
import CustomerRequest from './actions/CustomerRequest';
import Dictionary from './actions/Dictionary';
import { OrderLineActions } from './actions/orderLine/orderLine.actions';
import PaymentMethod from './actions/PaymentMethod';
import {
    type URLS as URLS_CONFIG,
    type DEFAULTS as DEFAULTS_CONFIG,
} from './constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { MediaFilesService } from 'services/mediaFiles/MediaFilesService';
import type { default as errorHandlersFactory } from './errorHandlers';

export class SimpService {
    public readonly orderLineActions: OrderLineActions;
    public readonly channel: Channel;
    public readonly order: Order;
    public readonly orderInvoice: OrderInvoice;
    public readonly package: Package;
    public readonly customerRequest: CustomerRequest;
    public readonly dictionary: Dictionary;
    public readonly paymentMethod: PaymentMethod;

    private channels:
        | {}
        | {
              'hydra:member': {
                  name: string;
              }[];
          };

    private constructor(
        public readonly ajaxService: AjaxService,
        public readonly mediaFiles: MediaFilesService,
        public readonly URLS: typeof URLS_CONFIG,
        DEFAULTS: typeof DEFAULTS_CONFIG,
        public readonly errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ) {
        this.channels = {};

        this.channel = new Channel(ajaxService, URLS, DEFAULTS);
        this.order = new Order(ajaxService, mediaFiles, URLS, DEFAULTS);
        this.orderInvoice = new OrderInvoice(ajaxService, URLS);
        this.package = new Package(this, ajaxService, URLS);
        this.customerRequest = new CustomerRequest(this, ajaxService, URLS);
        this.dictionary = new Dictionary(ajaxService, URLS, errorHandlers);
        this.paymentMethod = new PaymentMethod(ajaxService, URLS, DEFAULTS);

        this.getChannels = this.getChannels.bind(this);

        this.orderLineActions = OrderLineActions.getInstance(
            ajaxService,
            URLS,
            errorHandlers,
        );
    }

    private static instance: SimpService;

    public static getInstance(
        ajaxService: AjaxService,
        mediaFiles: MediaFilesService,
        URLS: typeof URLS_CONFIG,
        DEFAULTS: typeof DEFAULTS_CONFIG,
        errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ): SimpService {
        if (!SimpService.instance) {
            SimpService.instance = new SimpService(
                ajaxService,
                mediaFiles,
                URLS,
                DEFAULTS,
                errorHandlers,
            );
        }

        return SimpService.instance;
    }

    async getChannels(): Promise<{
        'hydra:member': {
            name: string;
        }[];
    }> {
        const channels = this.channels;
        const url = this.URLS.GET_CHANNELS;

        if (Object.keys(channels).length === 0) {
            const response: {
                data: {
                    'hydra:member': {
                        name: string;
                    }[];
                };
            } = await this.ajaxService.get({ url });

            this.channels = response.data;

            return response.data;
        }

        return new Promise(function (resolve) {
            resolve(
                channels as {
                    'hydra:member': {
                        name: string;
                    }[];
                },
            );
        });
    }
}
