import { get } from 'lodash';

import { configError } from 'services/utils/utils';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';

const T_PROCESSING_ERROR = 'userService:alerts.processingError';

class Previews {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly DEFAULTS: IStockServiceConst['DEFAULTS'];
    private readonly HEADERS: IStockServiceConst['HEADERS'];
    private readonly APP_ALERTS: IStockServiceConst['APP_ALERTS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = stockService.URLS;
        this.DEFAULTS = stockService.DEFAULTS;
        this.HEADERS = stockService.HEADERS;
        this.APP_ALERTS = stockService.APP_ALERTS;
        this.errorHandlers = stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.batchDelete = this.batchDelete.bind(this);
        this.download = this.download.bind(this);
        this.batchDownload = this.batchDownload.bind(this);
    }

    async getList({
        sort = {},
        filters = {},
        per_page = this.DEFAULTS.PREVIEWS_LIST_PER_PAGE,
        page = 1,
    }) {
        const { ajax } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_PREVIEWS;
        const params = {
            page,
            per_page,
            sort,
            filters,
        };

        const config = {
            params,
            headers: HEADERS.basic,
        };
        const errorConfig = configError('get', errorHandlers);

        const response = await ajax.get({ url, config, errorConfig });

        return {
            results: get(response, 'data.results', []),
            total: get(response, 'data.total', 0),
        };
    }

    async delete({ previewId, patternId }) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, errorHandlers } = this;

        const url = URLS.DELETE_PREVIEW.replace(
            '{patternId}',
            patternId,
        ).replace('{previewId}', previewId);
        const errorConfig = configError('delete', errorHandlers);

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.DELETE_PREVIEW_SUCCESS),
        });

        return response.data;
    }

    async batchDelete(ids: string[]) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, errorHandlers } = this;

        const url = URLS.DELETE_PREVIEWS;
        const errorConfig = configError('delete', errorHandlers);

        const previewsToDelete = ids.map((id) => {
            return {
                id: id,
            };
        });

        const response = await ajax.post({
            url,
            errorConfig,
            data: { previews: previewsToDelete },
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.DELETE_PREVIEW_SUCCESS),
        });

        return response.data;
    }

    async download({ fileName, fileId }) {
        const { ajax } = this.services;
        const { URLS, errorHandlers } = this;

        const url = URLS.DOWNLOAD_PREVIEW.replace('{id}', fileId);
        const errorConfig = configError('download', errorHandlers);

        return await ajax.download({ url, fileName, errorConfig });
    }

    async batchDownload(ids: string[]) {
        const {
            ajax,
            userServiceApi: { jobs },
            alert: { addSuccess, addError },
            translator: { t },
        } = this.services;

        const { URLS, DEFAULTS, errorHandlers } = this;

        const url = URLS.DOWNLOAD_PREVIEWS;
        const errorConfig = configError('post', errorHandlers);

        const previewsToDownload = ids.map((id) => {
            return {
                id: id,
            };
        });

        const downloadAttachments = async (attachments) => {
            attachments.forEach((attachment) => {
                const fileName = attachment.id;
                const mediaFileId = attachment.media_file.id;
                const isOutputFile = !!attachment.output;

                if (isOutputFile) {
                    void jobs.downloadJobAttachmentById(
                        mediaFileId,
                        `${fileName}.zip`,
                    );
                }
            });
        };

        const showProcessingError = () => {
            addError({
                message: t(T_PROCESSING_ERROR),
            });
        };

        return await ajax
            .post({
                url,
                errorConfig,
                data: { previews: previewsToDownload },
            })
            .then(async (response) => {
                if (response.status === 201) {
                    addSuccess({
                        message: t(
                            'stock:alerts.previewsCreateBatchDownloadSuccess',
                        ),
                    });

                    let interval;

                    try {
                        const jobId = response.data.job_id;
                        const { status, jobAttachments = [] } =
                            await jobs.getById(jobId);

                        const isInProgress =
                            status === 'pending' || status === 'processing';
                        const isFinished = status === 'succeeded';

                        if (isInProgress) {
                            interval = setInterval(async () => {
                                const { status, jobAttachments = [] } =
                                    await jobs.getById(jobId);

                                const isFinished = status === 'succeeded';
                                const isFailed =
                                    status === 'failed' ||
                                    status === 'succeeded_with_warning' ||
                                    status === 'cancelled';

                                if (isFailed) {
                                    clearInterval(interval);
                                    showProcessingError();
                                } else if (isFinished) {
                                    clearInterval(interval);
                                    downloadAttachments(jobAttachments);
                                }
                            }, DEFAULTS.BATCH_DOWNLOAD_INTERVAL);
                        } else if (isFinished) {
                            downloadAttachments(jobAttachments);
                        } else {
                            showProcessingError();
                        }
                    } catch {
                        clearInterval(interval);
                        showProcessingError();
                    }
                } else {
                    showProcessingError();
                }
            });
    }
}

export default Previews;
