import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import {
    appActions,
    appReducers,
    appSagas,
} from 'modules/app/reducers/reducers';
import {
    armActions,
    armReducers,
    armSagas,
} from 'modules/arm/reducers/armReducers';
import {
    stabloActions,
    stabloReducers,
    stabloSagas,
} from 'modules/stablo/reducers/product';
import {
    demoActions,
    demoReducers,
    demoSagas,
} from 'modules/demo/reducers/reducers';
import {
    productionActions,
    productionReducers,
    productionSagas,
} from 'modules/production/reducers/reducers';
import {
    salesActions,
    salesReducers,
    salesSagas,
} from 'modules/sales/reducers';

const reducers = {
    app: appReducers,
    stablo: stabloReducers,
    demo: demoReducers,
    arm: armReducers,
    production: productionReducers,
    sales: salesReducers,
};
const sagas = [
    ...appSagas,
    ...stabloSagas,
    ...demoSagas,
    ...armSagas,
    ...productionSagas,
    ...salesSagas,
];
const actions = [
    appActions,
    stabloActions,
    demoActions,
    armActions,
    productionActions,
    salesActions,
];

export const rootReducer = (history) =>
    combineReducers({ ...reducers, router: connectRouter(history) });

export function* rootSaga() {
    yield sagas;
}

export const rootActions = actions;
