import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CsvDownloadIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 17 19"
            { ...props }
        >
            <path
                d="M0 0V18.4H12.1188L11.4383 17.6H0.8V0.8H8.4V5.99375H13.6V10.8H14.4V5.43984L14.2977 5.3375C14.2832 5.32003 14.2672 5.30383 14.25 5.28906L8.96016 0H0ZM9.2 1.36016L13.0336 5.19375H9.2V1.36016ZM4.58437 8.4C3.74477 8.4 2.78594 9.0505 2.78594 10.8625C2.78594 12.3181 3.40131 13.2 4.47891 13.2C4.78691 13.2 5.03188 13.1512 5.17188 13.0813L5.08125 12.4516C4.96925 12.4932 4.79433 12.5359 4.61953 12.5359C4.05273 12.5359 3.57656 12.0805 3.57656 10.8141C3.57656 9.52646 4.10092 9.05781 4.61172 9.05781C4.83572 9.05781 4.94816 9.09907 5.06016 9.15547L5.2 8.51875C5.1084 8.46235 4.89237 8.4 4.58437 8.4ZM8.58047 8.4L9.6625 13.2H10.4961L11.6 8.4H10.7742L10.3891 10.5367C10.2891 11.0923 10.1827 11.7045 10.1188 12.2953H10.0969C10.0257 11.7117 9.9049 11.078 9.8125 10.5508L9.41406 8.4H8.58047ZM7.35313 8.40078C6.47113 8.40078 5.97422 8.98083 5.97422 9.71562C5.97422 10.2264 6.33848 10.6949 6.86328 11.0305C7.24848 11.2969 7.47891 11.5138 7.47891 11.8914C7.47891 12.2838 7.22684 12.55 6.76484 12.55C6.51964 12.55 6.2123 12.4657 6.0375 12.3609L5.91094 13.0047C6.05814 13.1031 6.41484 13.2008 6.76484 13.2008C7.61204 13.2008 8.21328 12.6616 8.21328 11.8148C8.21328 11.3112 7.97605 10.8564 7.38125 10.4648C6.89805 10.1572 6.70937 9.989 6.70937 9.625C6.70937 9.3374 6.91226 9.05156 7.33906 9.05156C7.59106 9.05156 7.78791 9.12772 7.92031 9.21172L8.05313 8.56875C7.92753 8.49155 7.65353 8.40078 7.35313 8.40078ZM12.4 11.6V15.2H10.4L13.7984 19.2L17.2 15.2H15.2V11.6H12.4ZM13.2 12.4H14.4V16H15.4398L13.7984 18L12.1602 16H13.2V12.4Z"
            />
        </SvgIcon>
    );
}

CsvDownloadIcon.defaultProps = {
    titleAccess: 'csv-download-icon',
};

CsvDownloadIcon.propTypes = svgIconPropTypes;
