import TaskHandler from './actions/TaskHandler';
import FormService from './actions/FormService';
import CustomerRequest from './actions/CustomerRequest';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { StoreService } from 'services/store/StoreService';
import type { AlertService } from 'services/alert/AlertService';

export class CamundaProxyService {
    readonly tasks: TaskHandler;
    readonly formSevice: FormService;
    readonly customerRequest: CustomerRequest;

    private readonly ajax: AjaxService;
    private readonly store: StoreService;
    private readonly alerts: AlertService;

    private readonly URLS: unknown;
    private readonly DEFAULTS: unknown;

    private constructor({
        services,
        URLS,
        DEFAULTS,
        ListModel,
    }: {
        services: {
            ajax: AjaxService;
            store: StoreService;
            alerts: AlertService;
        };
        URLS: unknown;
        DEFAULTS: unknown;
        ListModel: unknown;
    }) {
        this.ajax = services.ajax;
        this.store = services.store;
        this.alerts = services.alerts;

        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;

        this.tasks = new TaskHandler(this, services, URLS, DEFAULTS, ListModel);
        this.formSevice = new FormService(services, URLS, DEFAULTS);
        this.customerRequest = new CustomerRequest(services, URLS, DEFAULTS);
    }

    private static instance: CamundaProxyService | undefined;

    public static getInstance({
        services,
        URLS,
        DEFAULTS,
        ListModel,
    }: {
        services: {
            ajax: AjaxService;
            store: StoreService;
            alerts: AlertService;
        };
        URLS: unknown;
        DEFAULTS: unknown;
        ListModel: unknown;
    }): CamundaProxyService {
        if (!CamundaProxyService.instance) {
            CamundaProxyService.instance = new CamundaProxyService({
                services,
                URLS,
                DEFAULTS,
                ListModel,
            });
        }

        return CamundaProxyService.instance;
    }
}
