import { SalesService } from './SalesService';
import RequestCache from '../requestCache/RequestCache';
import { URLS, ORDER_REIMPORT_STATUS, ALERTS_MESSAGES } from './constants';
import errorHandlers from './errorHandlers';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const salesServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    SalesService.getInstance(
        ajaxService,
        new RequestCache({ cacheTime: 300000 }),
        URLS,
        ORDER_REIMPORT_STATUS,
        ALERTS_MESSAGES,
        errorHandlers(alertService),
    );
