import axios from 'axios';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';

import { SentryReportingHttpStatusesBlacklist } from '../services/constants';
import { getSentryFingerprint } from '../utils/formatting';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (get(window, 'env.APP_ENV_WARNING', '') !== 'local') {
            const hasResponse = typeof error.response !== 'undefined';

            if (
                hasResponse &&
                SentryReportingHttpStatusesBlacklist.includes(
                    error.response.status,
                )
            ) {
                return Promise.reject(error);
            }

            let status,
                url,
                method = null;

            if (hasResponse) {
                url = error.response.config.url;
                method = error.response.config.method.toUpperCase();
                status = error.response.status;
            } else {
                url = error.config.url;
                method = error.config.method.toUpperCase();
            }

            const fingerprint = getSentryFingerprint({ url, method, status });
            const transactionName = fingerprint.join(' ');

            Sentry.captureException(new Error(transactionName), (scope) => {
                scope.setFingerprint(fingerprint);
                scope.setTransactionName(transactionName);

                if (hasResponse) {
                    scope.setTag('status', status);
                    scope.setContext('request', {
                        url,
                        method,
                        status,
                        errorMessage: error.response.data,
                    });
                } else {
                    scope.setContext('request', {
                        url,
                        method,
                        errorMessage: error.message,
                    });
                }

                return scope;
            });
        }

        return Promise.reject(error);
    },
);
