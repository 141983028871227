import get from 'lodash/get';
import moment from 'moment';
import { mapUserFromApi } from './user';

const _mapContractorFromApi = (contractor) => {
    return {
        id: get(contractor, 'id', ''),
    };
};

const _mapTechnologyFromApi = (technology) => {
    return {
        id: get(technology, 'id', ''),
        name: get(technology, 'name', ''),
    };
};

export const _determineStartDateForDiscount = (inputStartDate) => {
    const now = moment();

    return moment(inputStartDate).isSame(now, 'day')
        ? inputStartDate
              .set({
                  hour: now.get('hour'),
                  minute: now.get('minute') + 1,
                  second: now.get('second'),
              })
              .toISOString(true)
        : inputStartDate.startOf('day').toISOString(true);
};

export const _determineEndDateForDiscount = (inputEndDate, periodType) =>
    periodType === 'unlimited'
        ? undefined
        : inputEndDate.endOf('day').toISOString(true);

export const mapDiscountFromApi = (data = {}) => {
    const endDate = moment(get(data, 'end_date', ''));

    return {
        id: get(data, 'id', ''),
        name: get(data, 'name', ''),
        value: get(data, 'value', 0),
        valueType: get(data, 'value_type', 'percentage'),
        periodType: get(data, 'period_type', ''),
        contractor: _mapContractorFromApi(get(data, 'contractor')),
        technologies: get(data, 'technologies', []).map(_mapTechnologyFromApi),
        createdAt: moment(get(data, 'created_at', '')).toISOString(),
        updatedAt: moment(get(data, 'updated_at', '')).toISOString(),
        startDate: moment(get(data, 'start_date', '')).toISOString(),
        endDate: endDate.isValid() ? endDate.toISOString() : '',
        createdBy: mapUserFromApi(get(data, 'created_by', {})),
        targetType: get(data, 'target_type', ''),
        productSets: get(data, 'product_sets', []),
        productSetTypes: get(data, 'product_set_types', []),
    };
};

export const mapCreateDiscountToApi = (data = {}) => ({
    name: get(data, 'name', ''),
    value: get(data, 'value', 0),
    period_type: get(data, 'periodType', ''),
    start_date: get(data, 'startDate', undefined),
    end_date: get(data, 'endDate', undefined),
    technologies: get(data, 'technology', []).map(({ id }) => ({ id })),
    value_type: 'percentage',
    contractor: get(data, 'contractor', {}),
});

export const mapEditDiscountToApi = (formData = {}, discountData = {}) => {
    const discount = {};

    if (formData.name !== discountData.name) discount.name = formData.name;
    if (formData.value !== discountData.value) discount.value = formData.value;
    discount.technologies = get(formData, 'technologies', []).map(({ id }) => ({
        id,
    }));

    return discount;
};
