import type { ArmApiService } from '../../ArmApiService';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { DeepPartial } from '../../../../utils/types/deepPartial';
import type {
    IExternalProducer,
    IExternalProducerRequestParams,
    IExternalProducerResponse,
    IExternalProducerUpdate,
} from './externalProducer.type';
import qs from 'qs';
import { ApplicationJsonHeaders } from '../../../constants';
import { URLS, DEFAULTS } from '../../constants';

export class ExternalProducerActions {
    private static instance: ExternalProducerActions | undefined;

    private constructor(
        private readonly armApiService: ArmApiService,
        private readonly ajaxService: AjaxService,
    ) {
        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    public static getInstance(
        armApiService: ArmApiService,
        ajaxService: AjaxService,
    ) {
        if (!ExternalProducerActions.instance) {
            ExternalProducerActions.instance = new ExternalProducerActions(
                armApiService,
                ajaxService,
            );
        }

        return ExternalProducerActions.instance;
    }

    public async getList({
        page,
        per_page = DEFAULTS.EXTERNAL_PRODUCERS_PER_PAGE,
        name,
    }: DeepPartial<IExternalProducerRequestParams> = {}): Promise<IExternalProducerResponse> {
        const { errorHandlers } = this.armApiService;

        const config = {
            params: { page, per_page, name },
            paramsSerializer: qs.stringify,
            headers: ApplicationJsonHeaders,
        };

        const response: { data: IExternalProducerResponse } =
            await this.ajaxService.get({
                config,
                url: URLS.EXTERNAL_PRODUCERS,
                errorConfig: {
                    throwError: errorHandlers.get,
                },
                onError: errorHandlers.get,
            });

        return response.data;
    }

    public async getById(
        id: IExternalProducer['id'],
    ): Promise<IExternalProducer> {
        const { errorHandlers } = this.armApiService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response: { data: IExternalProducer } =
            await this.ajaxService.get({
                config,
                url: URLS.EXTERNAL_PRODUCERS_BY_ID.replace('{id}', id),
                errorConfig: {
                    throwError: errorHandlers.get,
                },
                onError: errorHandlers.get,
            });

        return response.data;
    }

    public async create(payload: IExternalProducerUpdate) {
        const { errorHandlers } = this.armApiService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response: { data: IExternalProducer } =
            await this.ajaxService.post({
                config,
                url: URLS.EXTERNAL_PRODUCERS,
                errorConfig: {
                    throwError: errorHandlers.post,
                },
                data: payload,
                onError: errorHandlers.post,
            });

        return response.data;
    }

    public async edit(
        id: IExternalProducer['id'],
        payload: IExternalProducerUpdate,
    ) {
        const { errorHandlers } = this.armApiService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response: { data: IExternalProducer } =
            await this.ajaxService.put({
                config,
                url: URLS.EXTERNAL_PRODUCERS_BY_ID.replace('{id}', id),
                errorConfig: {
                    throwError: errorHandlers.put,
                },
                data: payload,
                onError: errorHandlers.put,
            });

        return response.data;
    }
}
