import { forwardRef } from 'react';
import Paper from '@mui/material/Paper';

const PaperComponent = (props) => {
    return <Paper {...props} />;
};

PaperComponent.propTypes = {
    ...Paper.propTypes,
};

const ForwardedPaperComponent = forwardRef(
    ({ additionalClasses, className, ...rest }, ref) => (
        <PaperComponent
            ref={ref}
            className={`${className} ${additionalClasses}`}
            {...rest}
        />
    ),
);

export default ForwardedPaperComponent;
