import {
    mapAweTransitionsFromApi,
    mapProductionOrderFromApi,
    mapProductionOrderGetListWorkers,
    mapProductionOrderGetListWorkersParams,
    mapProductionOrderItemFromApi,
    mapProductionOrderStatusFromApi,
} from 'services/production/mappers/orders';
import { parseTransitionPayload } from 'components/Camunda/utils';
import { TRANSITION_TYPES } from 'services/production/constants';
import { EMPTY_PROMISE_RESULT } from 'services/constants';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';
import qs from 'qs';

export class Orders {
    constructor(productionService) {
        this.productionService = productionService;
        this.constants = productionService.constants;

        this.getById = this.getById.bind(this);
        this.getListWorkers = this.getListWorkers.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getAweTransitions = this.getAweTransitions.bind(this);
        this.getItem = this.getItem.bind(this);
        this.submitTransition = this.submitTransition.bind(this);
        this.addNote = this.addNote.bind(this);
        this.getProperties = this.getProperties.bind(this);
        this.getHistory = this.getHistory.bind(this);
        this.getNotes = this.getNotes.bind(this);
        this.addStabloNote = this.addStabloNote.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
        this.getAllDocuments = this.getAllDocuments.bind(this);
        this.#asyncRequestPaginator = new AsyncRequestPaginator(
            this.getDocuments,
            (data) => data,
        );
    }

    /**
     * @type {import('services/production/ProductionService').ProductionService}
     */
    productionService;

    /**
     * @type {AsyncRequestPaginator}
     */
    #asyncRequestPaginator;

    /**
     * @type {{default: {success: function({omitMessage?: boolean}): void, error: *}, multipackage: {success: function({omitMessage?: boolean}): void, error: function(*): never}}}
     */
    #customTransitionHandlers = {
        multipackage: {
            success: ({ omitMessage }) => {
                const {
                    productionService: { alert, translator },
                    constants: { APP_ALERTS },
                } = this;

                if (!omitMessage) {
                    alert.addSuccess({
                        message: translator.t(
                            APP_ALERTS.MULTIPACKAGE_TRANSITION_SUCCESS,
                        ),
                    });
                }
            },
            error: (error) => {
                const {
                    productionService: { alert, translator },
                    constants: { APP_ALERTS },
                } = this;

                const errorResponse = error?.response?.data?.detail;

                alert.addError({
                    message: `${translator.t(APP_ALERTS.MULTIPACKAGE_TRANSITION_FAILURE)}${errorResponse ? ': ' + errorResponse : ''}`,
                });
                throw error;
            },
        },
        default: {
            success: ({ omitMessage }) => {
                const {
                    productionService: { alert, translator },
                    constants: { APP_ALERTS },
                } = this;

                if (!omitMessage) {
                    alert.addSuccess({
                        message: translator.t(
                            APP_ALERTS.TRANSITION_SUBMIT_SUCCESS,
                        ),
                    });
                }
            },
            error: (error) => this.productionService.errorHandlers.post(error),
        },
    };

    getListWorkers(params = {}) {
        const { ajaxService, errorHandlers, paramsSerializer } =
            this.productionService;
        const { URLS, HEADERS, DEFAULTS } = this.constants;
        const {
            page = 1,
            per_page: perPage = DEFAULTS.ORDERS_PER_PAGE,
            filters: {
                search = '',
                searchOrderNameOrderNumber = '',
                searchLegacyOrderId = '',
                searchLegacyMultipackageId = '',
                searchLegacyProductionOrderId = '',
            } = {},
        } = params;

        const url = URLS.GET_PRODUCTION_ORDERS_WORKERS;
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const config = {
            paramsSerializer,
            headers: HEADERS.basic,
            params: {
                page,
                itemsPerPage: perPage,
                ...mapProductionOrderGetListWorkersParams(params),
            },
        };

        if (
            search ||
            searchOrderNameOrderNumber ||
            searchLegacyOrderId ||
            searchLegacyMultipackageId ||
            searchLegacyProductionOrderId
        ) {
            return ajaxService
                .get({ url, config, errorConfig })
                .then(({ data = {} }) => mapProductionOrderGetListWorkers(data))
                .catch(() => ({
                    items: [],
                    totalItems: 0,
                }));
        }

        return EMPTY_PROMISE_RESULT;
    }

    getById(id) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER.replace('{id}', id);
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        return ajaxService
            .get({ url, config, errorConfig })
            .then(({ data }) => {
                return mapProductionOrderFromApi(data);
            });
    }

    getStatus(id) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER_STATUS.replace('{id}', id);
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        return ajaxService
            .get({ url, config, errorConfig })
            .then(({ data }) => mapProductionOrderStatusFromApi(data));
    }

    getAweTransitions(id) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER_AWE_TRANSITIONS.replace(
            '{id}',
            id,
        );
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        return ajaxService
            .get({ url, config, errorConfig })
            .then(({ data }) => mapAweTransitionsFromApi(data));
    }

    getItem(id) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER_ITEM.replace('{id}', id);
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        return ajaxService
            .get({ url, config, errorConfig })
            .then(({ data }) => mapProductionOrderItemFromApi(data));
    }

    getHistory(id) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER_HISTORY.replace('{id}', id);

        const config = {
            headers: HEADERS.basic,
        };

        const errorConfig = {
            throwError: errorHandlers.get,
        };

        return ajaxService
            .get({ url, config, errorConfig })
            .then((response) => response.data);
    }

    getProperties(id) {
        const { ajaxService } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.GET_PRODUCTION_ORDER_PROPERTIES.replace('{id}', id);

        return ajaxService
            .get({ url, config: { headers: HEADERS.basic } })
            .then((response) => response.data);
    }

    submitTransition({
        productionOrderId,
        actionId,
        payload = {},
        formFields = {},
        isMultipackage = false,
        type = TRANSITION_TYPES.STANDARD,
        omitMessage = false,
    }) {
        const { ajaxService } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url =
            type === TRANSITION_TYPES.AWE
                ? URLS.POST_PRODUCTION_ORDER_AWE_TRANSITION_APPLY.replace(
                      '{id}',
                      productionOrderId,
                  ).replace('{transitionName}', actionId)
                : URLS.POST_PRODUCTION_ORDER_TRANSITION_SUBMIT.replace(
                      '{id}',
                      productionOrderId,
                  ).replace('{actionId}', actionId);
        const config = {
            headers: HEADERS.basic,
        };

        const handler = isMultipackage
            ? this.#customTransitionHandlers.multipackage
            : this.#customTransitionHandlers.default;

        const errorConfig = {
            throwError: handler.error,
            addGenericAlert: false,
        };

        const parsedData = parseTransitionPayload({ payload, formFields });

        return ajaxService
            .post({ url, config, errorConfig, data: parsedData })
            .then((response) => {
                handler.success({ omitMessage });

                return response;
            })
            .catch(() => {});
    }

    /**
     * @param {string} productionOrderId
     * @return {Promise<unknown>}
     */
    async getNotes(productionOrderId) {
        const { ajaxService, errorHandlers } = this.productionService;
        const { URLS, HEADERS } = this.constants;

        const url = URLS.PRODUCTION_ORDERS_NOTES.replace(
            '{id}',
            productionOrderId,
        );
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const response = await ajaxService.get({ url, config, errorConfig });

        return response?.data?.results || [];
    }

    async addNote({ productionOrderId, payload = {} }) {
        const { ajaxService, errorHandlers, translator, alert } =
            this.productionService;
        const { URLS, HEADERS, APP_ALERTS } = this.constants;

        const url = URLS.PRODUCTION_ORDERS_NOTES.replace(
            '{id}',
            productionOrderId,
        );
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const response = await ajaxService.post({
            url,
            config,
            errorConfig,
            data: { body: payload.body, toBroadcast: true },
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.ADD_NOTE_SUCCESS),
        });

        return response;
    }

    async addStabloNote({ productionOrderId, payload = {} }) {
        const { ajaxService, errorHandlers, translator, alert } =
            this.productionService;
        const { URLS, HEADERS, APP_ALERTS } = this.constants;

        const url = URLS.PRODUCTION_ORDERS_STABLO_NOTE.replace(
            '{id}',
            productionOrderId,
        );
        const config = {
            headers: HEADERS.basic,
        };
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const response = await ajaxService.post({
            url,
            config,
            errorConfig,
            data: { body: payload.body },
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.ADD_NOTE_SUCCESS),
        });

        return response;
    }

    async putBlockShipping({ productionOrderId, requestHoldShipping } = {}) {
        const {
            productionService: { ajaxService, errorHandlers },
            constants: {
                URLS: { PUT_BLOCK_SHIPPING },
                HEADERS: { basic },
            },
        } = this;

        const putConfig = {
            url: PUT_BLOCK_SHIPPING.replace('{id}', productionOrderId),
            config: {
                headers: basic,
            },
            errorConfig: {
                throwError: errorHandlers.put,
                addGenericAlert: false,
            },
            data: {
                value: requestHoldShipping,
            },
        };

        try {
            const response = await ajaxService.put(putConfig);

            return response?.status === 204;
        } catch {
            return false;
        }
    }

    /**
     * @param {string} id
     * @return {Promise<import('./orders.type').OrdersDocument[]>}
     */
    async getAllDocuments(id) {
        const data = await this.#asyncRequestPaginator.fetchFullList({ id });

        return data.items;
    }

    /**
     * @param {{ id: string; page: number }} params
     * @return {Promise<import('services/utils/AsyncRequestPaginator').AjaxResponse<import('services/utils/AsyncRequestPaginator').ListResponse<import('./orders.type').OrdersDocument[]>>>}
     */
    getDocuments(params) {
        const {
            productionService: { ajaxService, errorHandlers },
            constants: {
                URLS: { GET_ORDER_DOCUMENTS },
                HEADERS: { basic },
            },
        } = this;

        const url = GET_ORDER_DOCUMENTS.replace('{id}', params.id);

        return ajaxService.get({
            url,
            config: {
                headers: basic,
                params: { page: params.page },
                paramsSerializer: (queryParams) => qs.stringify(queryParams),
            },
            errorConfig: { onError: errorHandlers.get },
        });
    }
}

export default Orders;
