import { AcceptTextCsv, ApplicationJsonHeaders } from '../../constants';
import { configError } from 'services/utils/utils';
import {
    mapBoxRuleGetList,
    mapBoxRuleGetListParams,
    mapPostPatchBoxRule,
    mapBoxRuleRemoveBatch,
} from '../mappers/boxRule';
import parser from 'qs';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

const defaultObj = {
    items: [],
    totalItems: 0,
};

const emptyObj = {};

export class BoxRule {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getList = this.getList.bind(this);
        this.remove = this.remove.bind(this);
        this.removeBatch = this.removeBatch.bind(this);
        this.postBoxRule = this.postBoxRule.bind(this);
        this.getBoxRule = this.getBoxRule.bind(this);
        this.patchBoxRule = this.patchBoxRule.bind(this);
        this.uploadFromCSV = this.uploadFromCSV.bind(this);
        this.getExportBoxRules = this.getExportBoxRules.bind(this);
    }

    private paramsSerializer = (params) => parser.stringify(params);

    async getList(params) {
        const { paramsSerializer } = this;
        const { ajax } = this.services;

        try {
            const { data } = await ajax.get({
                url: this.URLS.GET_BOX_RULES,
                config: {
                    params: mapBoxRuleGetListParams(params),
                    headers: ApplicationJsonHeaders,
                    paramsSerializer,
                },
            });

            if (
                Array.isArray(data?.results) &&
                typeof data?.total === 'number'
            ) {
                return mapBoxRuleGetList({
                    results: data.results,
                    total: data.total,
                });
            }

            return defaultObj;
        } catch {
            return defaultObj;
        }
    }

    async remove(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        await ajax.delete({
            url: this.URLS.DELETE_BOX_RULE.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('delete', errorHandlers),
        });

        return emptyObj;
    }

    async removeBatch(ids: string[]) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        await ajax.put({
            url: this.URLS.DELETE_BOX_RULE_BATCH,
            data: mapBoxRuleRemoveBatch(ids),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('put', errorHandlers),
        });

        return emptyObj;
    }

    async postBoxRule({
        boxGroupId,
        technologyId,
        width,
        height,
        partsCount,
        description,
    }) {
        const { ajax } = this.services;

        return ajax.post({
            url: this.URLS.POST_BOX_RULE,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: { addGenericAlert: false },
            data: mapPostPatchBoxRule({
                boxGroupId,
                technologyId,
                width,
                height,
                partsCount,
                description,
            }),
        });
    }

    async getBoxRule(id: string): Promise<unknown> {
        const { ajax } = this.services;

        try {
            const { data } = await ajax.get({
                url: this.URLS.GET_BOX_RULE.replace('{id}', id),
                config: {
                    headers: ApplicationJsonHeaders,
                },
            });

            return data;
        } catch {
            return emptyObj;
        }
    }

    async patchBoxRule({
        boxRuleId: id,
        boxGroupId,
        technologyId,
        width,
        height,
        partsCount,
        description,
    }) {
        const { ajax } = this.services;

        return ajax.patch({
            url: this.URLS.PATCH_BOX_RULE.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                addGenericAlert: false,
            },
            data: mapPostPatchBoxRule({
                boxGroupId,
                technologyId,
                width,
                height,
                partsCount,
                description,
            }),
        });
    }

    async uploadFromCSV(file: string | Blob): Promise<unknown> {
        const {
            ajax,
            mediaFiles: { uploadCustomFiles },
        } = this.services;
        const { errorHandlers } = this.armApiService;

        const { id: uploadedFileId } = await uploadCustomFiles(file);

        const { data } = await ajax.post({
            url: this.URLS.POST_IMPORT_BOX_RULES_CSV,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('post', errorHandlers),
            data: {
                media_file: {
                    id: `custom-files/${uploadedFileId}`,
                },
                separator: ',',
                enclosure: '"',
            },
        });

        return data;
    }

    /**
     * Downland box rules list to CSV
     */
    async getExportBoxRules({
        page = 1,
        per_page = 15,
        filters = {},
        sort = {},
    }: {
        page?: number;
        per_page?: number;
        filters?: Record<string, unknown>;
        sort?: Record<string, unknown>;
    }) {
        const {
            services: { ajax },
            paramsSerializer,
        } = this;

        await ajax.download({
            url: this.URLS.GET_EXPORT_BOX_RULES_CSV,
            config: {
                headers: AcceptTextCsv,
                params: mapBoxRuleGetListParams({
                    page,
                    per_page,
                    filters,
                    sort,
                }),
                paramsSerializer,
            },
        });
    }
}
