export const mapSeparatorToApi = (separator = '') => {
    if (separator === 'empty') return null;

    return separator;
};

const mapSeparatorFromApi = (separator) => {
    if (separator === null) return 'empty';

    return separator;
};

export const mapToApi = (data) => ({
    template: data.template || [],
    separator: mapSeparatorToApi(data.separator),
    offer_name: data.offerName || '',
});

export const mapFromApi = (data) => ({
    template: data.template || [],
    separator: mapSeparatorFromApi(data.separator),
    offerName: data.offer_name || '',
});
