import { get } from 'lodash';

import { mapGroupFromApi } from '../mappers/groups';

class Groups {
    constructor(pdpService) {
        this.pdpService = pdpService;

        this.getList = this.getList.bind(this);
    }

    getList() {
        const {
            ajax,
            URLS,
            errorHandlers,
        } = this.pdpService;

        const url = URLS.GET_GROUPS;
        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        return ajax
            .get({ url, errorConfig })
            .then((response) => {
                return ({
                    items: get(response, 'data.hydra:member', []).map(mapGroupFromApi),
                    totalItems: get(response, 'data.hydra:totalItems', 0),
                });
            });
    }
}

export default Groups;
