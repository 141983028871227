export function scrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;

    const animateScroll = function(elapsedTime) {
        elapsedTime += increment;
        element.scrollTop = easeInOut(elapsedTime, start, change, duration);
        if (elapsedTime < duration) {
            setTimeout(function() {
                animateScroll(elapsedTime);
            }, increment);
        }
    };

    animateScroll(0);
}

function easeInOut(currentTime, start, change, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
    }
    currentTime -= 1;

    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}
