import get from 'lodash/get';
import { mapWzShipmentsFromApi } from '../mappers/wzShipments';

class WzShipments {
    constructor(searchService) {
        this.searchService = searchService;
        this.constants = searchService.constants;

        this.getList = this.getList.bind(this);
    }

    getList(params = {}) {
        const {
            page = 1,
            per_page = this.constants.DEFAULTS.WZ_SHIPMENTS_PER_PAGE,
            filter = {},
        } = params;
        const { ajaxService, paramsSerializer } = this.searchService;
        const { URLS } = this.constants;

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                ...filter
            },
            paramsSerializer,
            headers: this.constants.HEADERS.basic,
        };

        return ajaxService.get({ url: URLS.GET_WZ_SHIPMENTS, config })
            .then((response) => {
                const items = get(response, 'data.hydra:member', []);
                const itemsTotal = get(response, 'data.hydra:totalItems', 0);

                return {
                    items: items.map(mapWzShipmentsFromApi),
                    itemsTotal,
                };
            })
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    getCSVFile(params = {}, fileName) {
        const {
            page = 1,
            per_page = this.constants.DEFAULTS.WZ_SHIPMENTS_PER_PAGE,
            filter = {},
        } = params;
        const { ajaxService, paramsSerializer } = this.searchService;
        const { URLS } = this.constants;

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                ...filter
            },
            headers: {
                'Accept': this.constants.HEADERS.download.csv,
            },
            paramsSerializer,
        };

        return ajaxService.download({ url: URLS.GET_WZ_SHIPMENTS_EXPORT, config, fileName });
    }
}

export default WzShipments;
