import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TextField from 'components/TextField/TextField';
import Typography from 'components/Typography';
import Button from 'components/Button/button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from 'components/IconButton/iconButton';
import {
    VisibilityOffIcon as VisibilityOff,
    VisibilityOnIcon as Visibility,
} from 'components/Icons/Icons';
import {
    ButtonsWrapperStyled,
    ErrorStyled,
} from 'modules/app/components/LoginForm/loginForm.styles';

class LoginForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
        };
    }

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    handleToggle = (field) => () => {
        this.setState({
            [field]: !this.state[field],
        });
    };

    validateUsername = () => {
        let acceptUsername = true;
        const { username } = this.state;
        const noEmptyMsg = this.props.t('validation.required');

        if (username.trim() === '') {
            acceptUsername = false;
            this.handleChange('usernameError', noEmptyMsg);
        }

        return acceptUsername;
    };

    validatePassword = () => {
        let acceptPassword = true;
        const { password } = this.state;
        const noEmptyMsg = this.props.t('validation.required');

        if (password.trim() === '') {
            acceptPassword = false;
            this.handleChange('passwordError', noEmptyMsg);
        }

        return acceptPassword;
    };

    resetErrors = () => {
        this.setState({
            usernameError: '',
            passwordError: '',
        });
    };

    validateForm = () => {
        this.resetErrors();

        const loginValid = this.validateUsername();
        const passwordValid = this.validatePassword();

        return loginValid && passwordValid;
    };

    handleLogin = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.props.login({
                credentials: {
                    username: this.state.username,
                    password: this.state.password,
                },
            });
        }
    };

    render() {
        const { t, loginError } = this.props;
        const { usernameError, passwordError, showPassword } = this.state;
        const loginFieldName = _.upperFirst(t('common:glossary.login'));
        const passwordFieldName = _.upperFirst(t('common:glossary.password'));
        const signInLabel = _.upperFirst(t('common:actions.signIn'));
        const showLoginError = loginError && !usernameError && !passwordError;

        return (
            <div>
                <ErrorStyled>
                    {showLoginError && (
                        <Typography
                            align="center"
                            color="error"
                        >
                            {t('alerts.loginError')}
                        </Typography>
                    )}
                </ErrorStyled>
                <form onSubmit={this.handleLogin}>
                    <TextField
                        fullWidth
                        id="username-input"
                        name="username"
                        value={this.state.username}
                        onChange={(e) =>
                            this.handleChange('username', e.target.value)
                        }
                        label={loginFieldName}
                        error={!!usernameError}
                        helperText={usernameError ? usernameError : ' '}
                    />
                    <TextField
                        fullWidth
                        id="password-input"
                        label={passwordFieldName}
                        error={!!passwordError}
                        helperText={passwordError ? passwordError : ' '}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        value={this.state.password}
                        onChange={(e) =>
                            this.handleChange('password', e.target.value)
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.handleToggle(
                                            'showPassword',
                                        )}
                                        size="large"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ButtonsWrapperStyled>
                        <Button
                            fullWidth
                            color="primary"
                            type="submit"
                        >
                            {signInLabel}
                        </Button>
                    </ButtonsWrapperStyled>
                </form>
            </div>
        );
    }
}

LoginForm.defaultProps = {
    t: (key) => key,
};

LoginForm.propTypes = {
    t: PropTypes.func,
    login: PropTypes.func.isRequired,
    loginError: PropTypes.string.isRequired,
};

export default LoginForm;
