import { checkAccess } from 'access/votingMechanism';
import { ModulesListStyled } from './navBar.styles';
import type {
    NavBarModule,
    TActiveModule,
    TExpandModuleHandler,
    TExtendedModule,
} from './navBar.type';
import { FixedModule } from './FixedModule';
import { Module } from './Module';

interface INavBarNavigationProps {
    modules: NavBarModule[];
    extended: boolean;
    activeModule: TActiveModule;
    extendedModule: TExtendedModule;
    fixed: boolean;
    expandModuleHandler: TExpandModuleHandler;
}

export const NavBarNavigation = ({
    modules,
    extended,
    activeModule,
    extendedModule,
    fixed,
    expandModuleHandler,
}: INavBarNavigationProps) => {
    const moduleList = modules.filter(
        (module) => !!module.fixedOnNavbar === fixed,
    );

    const availableModules = moduleList.filter(
        (module) => checkAccess(module.name) || module.forceAccess,
    );

    return (
        <ModulesListStyled
            component="nav"
            fixed={fixed}
        >
            {availableModules.map((module) => {
                const isActive = activeModule === module.name;
                const isOpen = extendedModule === module.name;

                return fixed ? (
                    <FixedModule
                        key={module.name}
                        module={module}
                        isActive={isActive}
                        isOpen={isOpen}
                        extended={extended}
                        onClick={expandModuleHandler}
                    />
                ) : (
                    <Module
                        key={module.name}
                        module={module}
                        activeModule={activeModule}
                        extendedModule={extendedModule}
                        extended={extended}
                        onClick={expandModuleHandler}
                    />
                );
            })}
        </ModulesListStyled>
    );
};
