import { object, string } from 'yup';

export const createSchema = (t) => object().shape({
    workplace: string()
        .required(t('app:validation.required'))
});

export const createDefaultValue = (defaultWorkplace) => ({
    workplace: defaultWorkplace ?? '',
});
