const baseError = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;

    if (data.violations) {
        data.violations.forEach((violation = {}) => {
            alertService.addError({
                message: `${ violation.propertyPath }: ${ violation.message }`,
            });
            throw error;
        });
    }

    if (data['hydra:description']) {
        alertService.addError({
            message: data['hydra:description'],
        });
        throw error;
    }

    alertService.addError({
        message: `An unknown error has occurred (${ status })`,
    });
    throw error;
};

const deactivate = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;

    if (data.detail) {
        alertService.addError({
            message: data.detail,
        });
    } else {
        alertService.addError({
            message: `An unknown error has occurred (${ status })`,
        });
    }
    throw error;
};

const errorHandlers = (alertService) => ({
    post: baseError(alertService),
    patch: baseError(alertService),
    get: baseError(alertService),
    delete: baseError(alertService),
    deactivate: deactivate(alertService),
});

export default errorHandlers;
