import type { AlertService } from 'services/alert/AlertService';

const hydraDescription = 'hydra:description';

const get =
    (alertService: AlertService) =>
    (error: any = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        }

        if (status === 500 && data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: 'An unknown error has occurred',
        });
        throw error;
    };

const base =
    (alertService: AlertService) =>
    (error: any = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
        } else if (data.violations && data.violations.length > 0) {
            data.violations.forEach(({ propertyPath, message }) => {
                if (propertyPath) {
                    alertService.addError({
                        message: `${propertyPath}: ${message}`,
                    });
                }
            });
            throw error;
        } else if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        } else {
            alertService.addError({
                message: 'An unknown error has occurred',
            });
        }

        throw error;
    };

const upload =
    (alertService: AlertService) =>
    (error: any = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        if (data.detail) {
            alertService.addError({
                message: data.detail,
                options: { persist: true },
            });

            throw error;
        }
    };

const errorHandlers = (alertService: AlertService) => ({
    get: get(alertService),
    post: base(alertService),
    put: base(alertService),
    patch: base(alertService),
    delete: base(alertService),
    upload: upload(alertService),
});

export default errorHandlers;
