import { fromJS } from 'immutable';
import * as C from './constants';
import _ from 'lodash';
import { toIdMap } from 'utils/immutable/list';

const initialState = fromJS({
    productPossibilities: fromJS({}),
    productPossibilitiesTableLoading: false,
    invalidPatterns: [],
    searchedQuery: '',
});

export default function productPossibilitiesReducer(state = initialState, action) {
    let newProductPossibilities;
    let invalidPatterns;
    let productPossibilities;
    let addProductPossibilities;
    const hydraMember = 'hydra:member';

    switch (action.type) {
        case C.RESET_PRODUCT_POSSIBILITIES:
            return state
                .set('invalidPatterns', [])
                .set('searchedQuery', '')
                .set('productPossibilities', fromJS({}));

        case C.GENERATE_SKU_FOR_PRODUCT_POSSIBILITIES.request:
            return state.set('productPossibilitiesTableLoading', true);
        case C.GENERATE_SKU_FOR_PRODUCT_POSSIBILITIES.failure:
            return state.set('productPossibilitiesTableLoading', false);
        case C.GENERATE_SKU_FOR_PRODUCT_POSSIBILITIES.success:
            productPossibilities = state.get('productPossibilities');
            action.payload.response[hydraMember] && action.payload.response[hydraMember].map((variant = {}) => {
                let possibility = productPossibilities.get(variant.id);

                if (possibility) {
                    possibility = possibility
                        .set('skus', fromJS(variant.skus));
                    productPossibilities = productPossibilities.set(possibility.get('id'), possibility);
                }
            });

            return state
                .set('productPossibilities', fromJS(productPossibilities))
                .set('productPossibilitiesTableLoading', false);

        case C.GENERATE_ADDITIONAL_SKU_FOR_PRODUCT_POSSIBILITIES.request:
            return state.set('productPossibilitiesTableLoading', true);
        case C.GENERATE_ADDITIONAL_SKU_FOR_PRODUCT_POSSIBILITIES.failure:
            return state.set('productPossibilitiesTableLoading', false);
        case C.GENERATE_ADDITIONAL_SKU_FOR_PRODUCT_POSSIBILITIES.success:
            addProductPossibilities = state.get('productPossibilities');
            action.payload.response[hydraMember] && action.payload.response[hydraMember].map((variant = {}) => {
                let possibility = addProductPossibilities.get(variant.id);

                if (possibility) {
                    possibility = possibility
                        .set('skus', fromJS(variant.skus));
                    addProductPossibilities = addProductPossibilities.set(possibility.get('id'), possibility);
                }
            });

            return state
                .set('productPossibilities', fromJS(addProductPossibilities))
                .set('productPossibilitiesTableLoading', false);

        case C.FETCH_PRODUCT_POSSIBILITIES.request:
            return state
                .set('invalidPatterns', [])
                .set('productPossibilitiesTableLoading', true);
        case C.FETCH_PRODUCT_POSSIBILITIES.failure:
            return state
                .set('invalidPatterns', [])
                .set('searchedQuery', '')
                .set('productPossibilities', fromJS({}))
                .set('productPossibilitiesTableLoading', false);
        case C.FETCH_PRODUCT_POSSIBILITIES.success:
            newProductPossibilities = {};
            invalidPatterns = [];

            if (action.payload.response[hydraMember]) {
                newProductPossibilities = toIdMap(action.payload.response[hydraMember]);

                const totalItems = action.payload.response['hydra:totalItems'];

                invalidPatterns = totalItems instanceof Object ?
                    Object.keys(totalItems).filter((key) => totalItems[key] === 0)
                    : _.uniqBy(action.payload.query.split(','));
            } else {
                invalidPatterns =
                    _.uniqBy(invalidPatterns.concat(action.payload.query.split(',')));
            }

            return state
                .set('invalidPatterns', invalidPatterns)
                .set('searchedQuery', action.payload.query)
                .set('productPossibilities', fromJS(newProductPossibilities))
                .set('productPossibilitiesTableLoading', false);

        default:
            return state;
    }
}
