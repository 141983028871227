import React from 'react';
import { svgIconPropTypes } from 'utils/propTypes';
import { SvgIcon } from 'components/Icons/SvgIconBase';

export function ExportFeedIcon(props) {
    return (
        <SvgIcon
            { ...props }
            viewBox="0 0 14 14"
        >
            <path d="M2.16659 0C1.15999 0 0.333252 0.826742 0.333252 1.83333V2.83333C0.333252 3.35667 0.557947 3.83017 0.913981 4.16536C0.557585 4.50002 0.333252 4.97366 0.333252 5.5V6.5C0.333252 7.02567 0.556695 7.49867 0.912028 7.83333C0.556695 8.168 0.333252 8.641 0.333252 9.16667V10.1667C0.333252 11.1777 1.15592 12 2.16659 12H6.0188C5.8208 11.691 5.65984 11.356 5.54484 11H2.16659C1.70692 11 1.33325 10.626 1.33325 10.1667V9.16667C1.33325 8.70733 1.70692 8.33333 2.16659 8.33333H5.54484C5.65984 7.97733 5.82112 7.64233 6.01945 7.33333H2.16659C1.70692 7.33333 1.33325 6.95933 1.33325 6.5V5.5C1.33325 5.04067 1.70692 4.66667 2.16659 4.66667H9.83325C10.2766 4.66667 10.6367 5.01547 10.662 5.45247C11.0157 5.53581 11.3526 5.66087 11.6666 5.82487V5.5C11.6666 4.97366 11.4423 4.50002 11.0859 4.16536C11.4419 3.83017 11.6666 3.35667 11.6666 2.83333V1.83333C11.6666 0.826742 10.8398 0 9.83325 0H2.16659ZM2.16659 1H9.83325C10.2993 1 10.6666 1.36726 10.6666 1.83333V2.83333C10.6666 3.29941 10.2993 3.66667 9.83325 3.66667H2.16659C1.70051 3.66667 1.33325 3.29941 1.33325 2.83333V1.83333C1.33325 1.36726 1.70051 1 2.16659 1ZM9.66659 6C7.64159 6 5.99992 7.64167 5.99992 9.66667C5.99992 11.6917 7.64159 13.3333 9.66659 13.3333C11.6916 13.3333 13.3333 11.6917 13.3333 9.66667C13.3333 7.64167 11.6916 6 9.66659 6ZM9.66659 7.33333C9.75184 7.33333 9.8371 7.36582 9.90226 7.43099L11.5689 9.09766C11.6993 9.22799 11.6993 9.43868 11.5689 9.56901C11.5039 9.63401 11.4186 9.66667 11.3333 9.66667C11.2479 9.66667 11.1626 9.63401 11.0976 9.56901L9.99992 8.47135V12C9.99992 12.184 9.85092 12.3333 9.66659 12.3333C9.48225 12.3333 9.33325 12.184 9.33325 12V8.47135L8.2356 9.56901C8.10526 9.69934 7.89458 9.69934 7.76424 9.56901C7.63391 9.43868 7.63391 9.22799 7.76424 9.09766L9.43091 7.43099C9.49607 7.36582 9.58134 7.33333 9.66659 7.33333Z" />
        </SvgIcon>
    );
}

ExportFeedIcon.defaultProps = {
    titleAccess: 'export-masterfeed-icon',
};

ExportFeedIcon.propTypes = svgIconPropTypes;
