import styled from '@emotion/styled';
import { DialogActions, DialogContent } from '@mui/material';
import Typography from 'components/Typography';
import { excludePropForward } from 'utils/styles';

export const ModalContentStyled = styled(DialogContent)`
  min-width: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
`;
ModalContentStyled.displayName = 'ModalContent';

export const ModalLoaderContainerStyled = styled('div')`
  align-items: center;
  width: 100%;
  height: 100%;
`;
ModalLoaderContainerStyled.displayName = 'ModalLoaderContainer';

export const ErrorModalStyled = styled('div')`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
ErrorModalStyled.displayName = 'ErrorModal';

export const ErrorMessageStyled = styled(Typography, excludePropForward('isEmpty'))`
  ${({ isEmpty }) => isEmpty && `
    min-height: 20px;
  `};
`;
ErrorMessageStyled.displayName = 'ErrorMessage';

export const ModalButtonsStyled = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0;
  padding-left: ${({ theme }) => theme.spacing(3)};
`;
ModalButtonsStyled.displayName = 'ModalButtons';
