import { getUserFullName } from 'services/utils/utils';

import { formatDate } from 'utils/formatting';

export const mapOfferProductSetFromApi = (data = {}) => {
    const {
        created_at,
        sku,
        created_by,
        id,
        product_set: { name, id: product_set_id, products_count, gtins },
    } = data;

    return {
        name,
        id,
        product_set_id,
        sku,
        gtins: Array.isArray(gtins) ? gtins : Object.values(gtins ?? {}),
        productsCount: parseInt(products_count),
        createdAt: formatDate(created_at),
        createdBy: getUserFullName({
            firstName: created_by?.first_name,
            lastName: created_by?.last_name,
        }),
    };
};

export const mapOfferProductSetToApi = (data) => {
    return {
        product_sets: data.map((productSetId) => ({
            id: productSetId,
        })),
    };
};
