import { mapCurrentTask } from '../mappers/tasks';
import { buildMappedFilters } from 'utils/filters';
import { ApplicationJsonHeaders } from '../../constants';
import { Task } from '../mappers/Task';

class TaskHandler {
    constructor(pdpService, services, URLS, DEFAULTS, ListModel) {
        this.pdpService = pdpService;
        this.services = services;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.ListModel = ListModel;
        this.headers = ApplicationJsonHeaders;

        this.getList = this.getList.bind(this);
        this.getCurrentTask = this.getCurrentTask.bind(this);
    }

    getTasksNames = async (storeActions = {}) => {
        const { ajax, store } = this.services;
        const {
            request = () => ({ type: '' }),
            failure = () => ({ type: '' }),
            success = () => ({ type: '' }),
        } = storeActions;

        try {
            const url = this.URLS.GET_ACTIVE_TASKS_COUNTS;
            const config = {
                headers: this.headers,
            };

            await store.dispatch(request());

            const { data } = await ajax.get({ url, config });

            await store.dispatch(success());

            return data;
        } catch (e) {
            return store.dispatch(failure(e));
        }
    };

    getInstancesId = (tasks) => {
        return tasks
            .map((task) => task.process_instance_id);
    };

    getVariableInstancesByName = async (params, name = 'patternCode') => {
        const { ajax } = this.services;
        const url = this.URLS.GET_CUSTOM_FIELDS;
        const config = {
            params,
        };
        const body = { variableName: name };
        const { data } = await ajax.post({ url, config, data: body });

        return data;
    };

    getVariableInstances = async (instancesId) => {
        const { ajax } = this.services;
        const url = this.URLS.GET_CUSTOM_FIELDS;
        const body = { process_instance_id_in: instancesId };
        const config = {
            headers: this.headers,
            params: {
                itemsPerPage: 1000
            }
        };
        const { data } = await ajax.post({ url, data: body, config });

        return data?.results;
    };

    getPatternCode = async (instanceId, storeActions = {}) => {
        const { ajax, store } = this.services;
        const {
            request = () => ({ type: '' }),
            failure = () => ({ type: '' }),
            success = () => ({ type: '' }),
        } = storeActions;

        try {
            const url = this.URLS.GET_CUSTOM_FIELDS;
            const body = { process_instance_id_in: [instanceId] };
            const config = {
                headers: this.headers,
            };

            await store.dispatch(request());

            const { data } = await ajax.post({ url, data: body, config });
            const patternCode = data.find((variable) => variable.name === 'patternCode')?.value;

            await store.dispatch(success());

            return patternCode;
        } catch (e) {
            return store.dispatch(failure(e));
        }
    };

    mergeTasksWithFields = (tasks, variables) => {
        return tasks.map((task) => {
            const { camunda_process_definition } = task;

            if (camunda_process_definition.properties.length > 0) {
                const customFieldsNames = camunda_process_definition.properties
                    .find((prop) => prop.name === 'customFields')
                    .value
                    .split(',');

                if (customFieldsNames) {
                    const taskInstances = customFieldsNames.map((name) => {
                        const value = variables
                            .find((variable) => variable.name === name
                                &&
                                variable.process_instance_id === task.process_instance_id);

                        return value || null;
                    });
                    const filteredInstance = taskInstances.filter((instance) => instance !== null);
                    const payload = filteredInstance.length > 0 ? filteredInstance : null;

                    return { ...task, customFields: payload };
                }

                return { ...task, customFields: null };
            }

            return { ...task, customFields: null };
        });
    };

    prepareTasksList = async (tasks) => {
        const instancesId = this.getInstancesId(tasks);

        if (instancesId.length > 0) {
            const data = await this.getVariableInstances(instancesId);

            return this.mergeTasksWithFields(tasks, data);
        }

        return tasks;
    };

    getList = async ({
        storeActions = {},
        page = 1,
        itemsPerPage = this.DEFAULTS.PER_PAGE,
        filters = { },
        sortBy = '',
        sortOrder = '',
    }, key) => {
        const { ajax, store } = this.services;
        const {
            request = () => ({ type: '' }),
            failure = () => ({ type: '' }),
            success = () => ({ type: '' }),
        } = storeActions;

        try {
            const url = this.URLS.GET_TASK_LIST;
            const config = {
                params: {
                    page,
                    itemsPerPage,
                    processDefinitionKey: key,
                },
                headers: this.headers,
            };

            if (sortBy && sortOrder) {
                await Object.assign(config,
                    { params: { ...config.params, sortBy, sortOrder } }
                );
            }
            if (filters) {
                const mapFilters = Task.mapFilters();
                const mappedFilters = buildMappedFilters(filters, mapFilters);

                await Object.assign(config,
                    { params: { ...config.params, ...mappedFilters } }
                );
            }

            await store.dispatch(request());

            const { data } = await ajax.get({ url, config });
            const tasksList = await this.prepareTasksList([...data.results]);

            await store.dispatch(success({
                responseModel: new this.ListModel({ ...data, results: tasksList }),
            }));
        } catch (e) {
            return store.dispatch(failure(e));
        }
    };

    getById({ id }) {
        const { ajax } = this.services;
        const url = this.URLS.GET_TASK.replace('{id}', id);
        const config = {
            headers: this.headers,
        };

        return ajax
            .get({ url, config })
            .then(({ data }) => {
                return data;
            });
    }
    sendTask({ id, formData }) {
        const { ajax } = this.services;
        const url = this.URLS.SEND_TASK.replace('{id}', id);

        const config = {
            headers: this.headers,
        };

        const formattedData = Object.keys(formData).reduce((prev, curr) => {
            return {
                ...prev,
                [curr]: {
                    value: formData[curr],
                },
            };
        }, {});

        const camundaBodyTemplate = {
            variables: formattedData,
        };

        return ajax
            .post({ url, config, data: camundaBodyTemplate })
            .then(({ data }) => {
                return data;
            });
    }

    getCurrentTask(params = {}, id) {
        const {
            ajax, paramsSerializer,
        } = this.services;
        const {
            page = 1,
            perPage = this.DEFAULTS.PER_PAGE,
        } = params;
        const url = this.URLS.GET_CURRENT_TASKS.replace('{id}', id);

        const config = {
            params: {
                page,
                itemsPerPage: perPage,
                task_definition_key: 'Add_additional_photo_task',
            },
            headers: this.headers,
            paramsSerializer,
        };

        return ajax
            .get({ url, config })
            .then(({ data }) => mapCurrentTask(data));
    }
}

export default TaskHandler;
