import { useContext, useEffect, useState } from 'react';
import ServicesContext from 'contexts/ServicesContext';

export const useWorkplacesState = () => {
    const { workplaces: { user }, authentication } = useContext(ServicesContext);
    const [state, setState] = useState({ defaultWorkplace: '', currentWorkplace: '' });
    const [initState, setInitState] = useState({ defaultWorkplace: '', currentWorkplace: '' });

    useEffect(() => {
        if (!state.defaultWorkplace && !state.currentWorkplace) {
            const tokenData = authentication.getActiveToken();

            (async () => {
                try {
                    const defaultWorkplaceData = await user.getWorkplace();

                    const newState = {
                        defaultWorkplace: defaultWorkplaceData?.id ?? '',
                        currentWorkplace: tokenData?.workplace ?? ''
                    };

                    setState(newState);
                    setInitState(newState);
                } catch (error) {
                    const newState = {
                        defaultWorkplace: '',
                        currentWorkplace: tokenData?.workplace ?? ''
                    };

                    setState(newState);
                    setInitState(newState);
                }
            })();
        }
    }, [authentication, state.currentWorkplace, state.defaultWorkplace, user]);

    const resetState = () => {
        setState(initState);
    };

    return [state, ({ key, value }) => setState((state) => ({ ...state, [key]: value })), resetState];
};
