export class DrawerService {
    private listeners = {
        open: new Set(),
        close: new Set(),
        drawers: new Set(),
    };

    private constructor() {}

    private static instance: DrawerService | undefined;

    public static getInstance(): DrawerService {
        if (!DrawerService.instance) {
            DrawerService.instance = new DrawerService();
        }

        return DrawerService.instance;
    }

    listen(eventName, listener) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].add(listener);
        }
    }
    unlisten(eventName, listener) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].delete(listener);
        }
    }
    emit(eventName, data) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].forEach((listener) => listener(data));
        }
    }
    close(drawerHandler) {
        this.emit('close', drawerHandler);
    }
    open(drawer) {
        this.addData(drawer);
        this.emit('open', drawer);
    }
    addData(data) {
        if (this.listeners.drawers.size === 0) {
            this.listeners.drawers.add(data);

            return;
        }

        this.listeners.drawers.forEach((drawer) => {
            if (drawer.id === data?.id) {
                this.listeners.drawers.delete(drawer);
            }
        });

        this.listeners.drawers.add(data);
    }
    removeDrawers() {
        this.listeners.close.forEach((listener) => {
            this.listeners.drawers.forEach((data) => {
                listener(data);
            });
        });
        this.listeners.drawers.clear();
    }
}
