import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function PencilIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            { ...props }
        >
            <path
                d="M14.5087 0C14.1252 0 13.7418 0.14175 13.452 0.430921L13.1453 0.725761C13.0714 0.687489 12.9876 0.676149 12.9066 0.691741C12.8371 0.707334 12.7746 0.742771 12.7249 0.793801L1.30588 12.1905C1.27037 12.226 1.24339 12.2685 1.22634 12.3153L0.0219547 15.5131C-0.0263343 15.645 0.00633162 15.7938 0.107171 15.893C0.20659 15.9937 0.355718 16.0263 0.487803 15.9781L3.69193 14.776C3.7388 14.759 3.78141 14.7321 3.81692 14.6967L15.2359 3.29995C15.358 3.17379 15.3737 2.97817 15.27 2.83501L15.5654 2.54016C16.1449 1.96182 16.1449 1.00926 15.5654 0.430921C15.2757 0.14175 14.8922 0 14.5087 0ZM14.5087 0.725761C14.7076 0.725761 14.9078 0.795219 15.0541 0.941222C15.3481 1.23464 15.3481 1.73644 15.0541 2.02986L14.77 2.3247L13.6793 1.23606L13.9633 0.941222C14.1096 0.795219 14.3099 0.725761 14.5087 0.725761ZM12.9748 1.55358L14.4746 3.05047L13.4748 4.03705L11.9863 2.5515L12.9748 1.55358ZM11.4637 3.06181L12.9635 4.55869L3.54423 13.9482L3.27153 13.6647V13.0977C3.27153 12.8979 3.1082 12.7348 2.90794 12.7348H2.33984L2.05578 12.4627L11.4637 3.06181ZM1.69219 13.1317L1.91944 13.3585C1.98903 13.4266 2.08419 13.4634 2.18077 13.4606H2.54436V13.8235C2.54152 13.9199 2.57844 14.0148 2.64661 14.0843L2.87386 14.3111L1.48767 14.8327L1.16953 14.5152L1.69219 13.1317Z"
            />
        </SvgIcon>
    );
}

PencilIcon.defaultProps = {
    titleAccess: 'pencil-icon',
};

PencilIcon.propTypes = svgIconPropTypes;
