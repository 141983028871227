import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ArrowDropUpIcon(props) {
    return (
        <SvgIcon
            viewBox="-5 -9 24 24"
            { ...props }
        >
            <path
                d="M0.326165 7.04511C0.409453 7.04261 0.489013 7.00761 0.547439 6.94636L6.68712 0.772595L12.8268 6.94636C12.8865 7.00886 12.9685 7.04261 13.0543 7.04386C13.1848 7.04261 13.3004 6.96386 13.3501 6.84386C13.3986 6.72261 13.37 6.58511 13.2768 6.49386L6.91212 0.0938426C6.78781 -0.0311574 6.58642 -0.0311574 6.46211 0.0938426L0.0974349 6.49386C0.00295921 6.58511 -0.0256319 6.72636 0.0240917 6.84761C0.0750589 6.96886 0.194397 7.04761 0.326165 7.04511Z"
            />
        </SvgIcon>
    );
}

ArrowDropUpIcon.defaultProps = {
    titleAccess: 'arrow-drop-up-icon',
};

ArrowDropUpIcon.propTypes = svgIconPropTypes;
