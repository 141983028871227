import * as S from '../sections';
import * as A from '../accesses';
import { ACCEPT } from '../voteTypes';

export const ROLE_TRANSLATIONS_PATTERN_UPDATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'edit',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.EDIT]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.TAGS.EDIT]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_TRANSLATION_UPDATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'edit',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.TITLES.EDIT]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_TRANSLATION_READ = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'display',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_BETA]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.FETCH]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_TRANSLATION_GROUP_READ = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'display',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.TRANSLATION_GROUP.FETCH]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_TRANSLATION_GROUP_CREATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'create',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.TRANSLATION_GROUP.CREATE]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_CATEGORY_READ = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'display',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.CATEGORIES.FETCH]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_CATEGORY_CREATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'create',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.CATEGORIES.CREATE]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_CATEGORY_UPDATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'edit',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.TRANSLATIONS.RESOURCES.PATTERNS.CATEGORIES.EDIT]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_COLORS_READ = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'display',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_COLORS_CREATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'create',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
    },
};

export const ROLE_TRANSLATIONS_PATTERN_COLORS_UPDATE = {
    section: S.TRANSLATIONS_PATTERNS,
    type: 'edit',
    votes: {
        [A.TRANSLATIONS.MODULE]: ACCEPT,
        [A.TRANSLATIONS.PATHS.PATTERNS_DETAILS]: ACCEPT,
    },
};
