import * as Yup from 'yup';
import { get } from 'lodash';

export const serviceMap = {
    POST_SALES_API_URL: 'postSales',
    PRODUCTION_ORDERS_API_URL: 'productionOrders',
};

export const serviceUrls = {
    PRODUCTION_ORDERS_API_URL: get(window, 'env.PRODUCTION_ORDERS_URL'),
};

export const formSchema = (t = (key) => key, formFields = (key) => key) => {
    const fieldsToBeValidate = {};
    const T_REQUIRED = t('app:validation.required');

    formFields.forEach((formField) => {
        if (formField.type === 'float' || formField.type === 'integer') {
            if (formField.required) {
                fieldsToBeValidate[formField.name] = Yup.number()
                    .required(T_REQUIRED);
            } else {
                fieldsToBeValidate[formField.name] = Yup.number().nullable();
            }
        }
        if (formField.type === 'string') {
            if (formField.fieldType === 'fileupload') {
                if (formField.required) {
                    fieldsToBeValidate[formField.name] = Yup.string()
                        .trim()
                        .required(T_REQUIRED);
                } else {
                    fieldsToBeValidate[formField.name] = Yup.string()
                        .trim();
                }
            } else if (formField.required) {
                fieldsToBeValidate[formField.name] = Yup.string()
                    .required(T_REQUIRED)
                    .trim()
                    .max(255, t('app:validation.maxLength', { max: 255 }));
            } else {
                fieldsToBeValidate[formField.name] = Yup.string()
                    .trim()
                    .max(255, t('app:validation.maxLength', { max: 255 }));
            }
        } else if (formField.type === 'boolean') {
            if (formField.required) {
                fieldsToBeValidate[formField.name] = Yup.bool()
                    .required(T_REQUIRED);
            } else {
                fieldsToBeValidate[formField.name] = Yup.bool();
            }
        } else if (formField.type === 'long') {
            if (formField.required) {
                fieldsToBeValidate[formField.name] = Yup.number()
                    .test('test-decimal', t('common:alerts.decimalType'), (value) => Number.isInteger(parseFloat(value)))
                    .positive(t('app:validation.positivePrice'))
                    .required(T_REQUIRED);
            } else {
                fieldsToBeValidate[formField.name] = Yup.number();
            }
        }
    });

    return Yup.object().shape(fieldsToBeValidate);
};

export const initialFormValues = (formFields) => {
    return formFields.reduce((prev, curr) => {
        if (curr.required) {
            switch (curr.type) {
                case 'number':
                    return {
                        ...prev,
                        [curr.name]: curr?.initialValue ?? 0,
                    };
                case 'boolean':
                    return {
                        ...prev,
                        [curr.name]: curr?.initialValue ?? false,
                    };
                case 'string':
                default:
                    return {
                        ...prev,
                        [curr.name]: curr?.initialValue ?? '',
                    };
            }
        } else {
            return {
                ...prev,
                [curr.name]: curr?.initialValue ?? '',
            };
        }
    },{});
};

export const parseTransitionPayload = ({ payload = {}, formFields }) => {
    if (Object.keys(formFields)?.length === 0) {
        return payload;
    }

    let parsedPayload = { ...payload };

    formFields.forEach(({ id, type }) => {
        if (type === 'boolean') {
            parsedPayload[id] = payload[id] === '1';
        }

        if (type === 'float' && typeof payload[id] === 'string') {
            parsedPayload[id] = parseFloat(payload[id]);
        }
    });

    return parsedPayload;
};

export const getFormFieldsFromTabs = (tabsList) => {
    const tabs = tabsList?.toJS() || {};

    return Object.values(tabs).reduce((tabsArray, tab) => {
        const { formFields } = tab;

        return formFields ? [
            ...tabsArray,
            ...formFields
        ] : tabsArray;
    }, []);
};

export const getErrorMessageLabel = ({ formFields = [], errors = {} }) => {
    return formFields.reduce((message, formField) => {
        const { label, id } = formField;

        const errorMessage = errors?.[id];

        if (errorMessage) {
            message = `${message}${label} ${errorMessage}\n`;
        }

        return message;
    }, '');
};
