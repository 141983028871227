import { makeAction, makeRequestActions } from 'utils/redux/makeActions';
import * as CONSTANTS from './constants';
import * as C from 'modules/arm/reducers/technologies/constants';

export const editProperties = makeRequestActions(CONSTANTS.EDIT_PROPERTY);
export const createProperties = makeRequestActions(CONSTANTS.CREATE_PROPERTY);
export const removeProperty = makeRequestActions(CONSTANTS.REMOVE_PROPERTY);

export const fetchProperties = makeRequestActions(CONSTANTS.FETCH_PROPERTIES);
export const fetchProperty = makeRequestActions(CONSTANTS.FETCH_PROPERTY);

export const clearErrors = makeAction(C.CLEAR_ERRORS);
export const clearPostResponse = makeAction(C.CLEAR_POST_RESPONSE);
