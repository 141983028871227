import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function BookIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 0 18 18"
            { ...props }
        >
            <path
                d="M2.31997 0.720001C1.13139 0.720001 0.159973 1.69142 0.159973 2.88V14.9105C0.159973 16.0531 1.1796 16.92 2.31997 16.92V16.2C1.54298 16.2 0.879973 15.6161 0.879973 14.9105C0.879973 14.2048 1.52802 13.68 2.31997 13.68H13.84V0.720001H2.31997ZM2.31997 1.44H10.24V12.96H2.31997C1.78247 12.96 1.27322 13.1535 0.879973 13.4705V2.88C0.879973 2.08067 1.52064 1.44 2.31997 1.44ZM10.96 1.44H13.12V12.96H10.96V1.44ZM3.03997 14.4V17.64C3.03995 17.7069 3.05855 17.7724 3.09367 17.8293C3.12879 17.8861 3.17906 17.9321 3.23884 17.9621C3.29862 17.992 3.36555 18.0047 3.43213 17.9987C3.49872 17.9927 3.56232 17.9684 3.61583 17.9283L4.83997 17.01L6.06411 17.9283C6.11762 17.9684 6.18123 17.9927 6.24781 17.9987C6.3144 18.0047 6.38133 17.992 6.44111 17.9621C6.50089 17.9321 6.55115 17.8861 6.58628 17.8293C6.6214 17.7724 6.63999 17.7069 6.63997 17.64V14.4H5.91997V16.92L5.05583 16.2717C4.99354 16.2251 4.9178 16.1998 4.83997 16.1998C4.76214 16.1998 4.68641 16.2251 4.62411 16.2717L3.75997 16.92V14.4H3.03997ZM7.35997 16.2V16.92H13.84V16.2H7.35997Z"
            />
        </SvgIcon>
    );
}

BookIcon.defaultProps = {
    titleAccess: 'book-icon',
};

BookIcon.propTypes = svgIconPropTypes;
