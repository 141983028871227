import React from 'react';
import i18n, { getActiveLanguage } from 'i18n';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Select from 'components/Select/select';
import MenuItem from '@mui/material/MenuItem';

class LanguagePicker extends React.PureComponent {
    toggleLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        moment.locale(event.target.value);
    };

    render() {
        return (
            <Select
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                }}
                value={getActiveLanguage()}
                onChange={this.toggleLanguage}
            >
                <MenuItem value={'pl'}>PL</MenuItem>
                <MenuItem value={'en'}>EN</MenuItem>
            </Select>
        );
    }
}

const LanguagePickerWithHOC = withTranslation('common')(LanguagePicker);

export default LanguagePickerWithHOC;
