import {
    mapShipmentDocumentsFromApi,
    mapShipmentsFromApi,
    mapShipmentEditToApi,
} from './../mappers/shipment';
import get from 'lodash/get';

import { configError } from '../../utils/utils';
import { ApplicationJsonHeaders } from 'services/constants';

class Shipment {
    constructor(ProdisService, services, URLS) {
        this.prodisService = ProdisService;
        this.errorHandlers = ProdisService.errorHandlers;
        this.services = services;
        this.constants = ProdisService.constants;
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.edit = this.edit.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.postDPDData = this.postDPDData.bind(this);
    }

    getById(id) {
        const { ajax } = this.services;
        const { GET_SHIPMENT } = this.URLS;

        const url = GET_SHIPMENT.replace('{id}', id);
        const errorConfig = {
            throwError: this.errorHandlers.get,
            addGenericAlert: false,
        };

        return ajax
            .get({ url, errorConfig })
            .then((response) => mapShipmentsFromApi(response.data));
    }

    getList(params = {}) {
        const { DEFAULTS } = this.constants;
        const { paramsSerializer } = this.prodisService;
        const {
            page = 1,
            per_page = DEFAULTS.SHIPMENTS_PER_PAGE,
            filter = {},
            sort = {},
        } = params;
        const { ajax } = this.services;
        const { GET_SHIPMENTS } = this.URLS;
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                ...filter,
            },
            paramsSerializer,
        };

        const errorConfig = {
            throwError: this.errorHandlers.get,
            addGenericAlert: false,
        };

        return ajax
            .get({ url: GET_SHIPMENTS, config, errorConfig })
            .then((response) => {
                const items = get(response, 'data.hydra:member', []);
                const platforms = get(response, 'data.hydra:platforms', []);
                const shipperCompanies = get(
                    response,
                    'data.hydra:shipperCompany',
                    [],
                );
                const statuses = get(response, 'data.hydra:statuses', []);

                const itemsTotal = get(response, 'data.hydra:totalItems', 0);

                return {
                    platforms,
                    shipperCompanies,
                    statuses,
                    itemsTotal,
                    items: items.map(mapShipmentsFromApi),
                };
            })
            .catch(() => ({
                items: [],
                platforms: [],
                shipperCompanies: [],
                statuses: [],
                itemsTotal: 0,
            }));
    }

    edit(data) {
        const id = data['id'];

        const shipment = mapShipmentEditToApi(data);

        const { ajax } = this.services;
        const { PUT_SHIPMENT_RECEIVER_ADDRESS } = this.URLS;
        const url = PUT_SHIPMENT_RECEIVER_ADDRESS.replace('{id}', id);
        const errorConfig = {
            throwError: this.errorHandlers.put,
            addGenericAlert: false,
        };

        return ajax
            .put({ url, data: shipment, errorConfig })
            .then((response) => mapShipmentsFromApi(response.data));
    }

    getShipmentDocuments(shipmentId) {
        const { ajax } = this.services;

        const url = this.URLS.GET_SHIPMENT_DOCUMENTS.replace(
            '{id}',
            shipmentId,
        );

        const errorConfig = {
            throwError: this.errorHandlers.get,
            addGenericAlert: false,
        };

        return ajax
            .get({ url, config: {}, errorConfig })
            .then((response) => {
                const items = get(response, 'data.hydra:member', []);
                const totalItems = get(response, 'data.hydra:totalItems', []);

                return {
                    items: items.map((item) =>
                        mapShipmentDocumentsFromApi(item),
                    ),
                    totalItems,
                };
            })
            .catch(() => ({
                items: [],
                totalItems: 0,
            }));
    }

    async uploadDocument(documentType, file) {
        const { ajax, mediaFiles } = this.services;

        const { id: uploadedFileId } = await mediaFiles.uploadProdisFile(file);

        if (uploadedFileId) {
            const { data } = await ajax.post({
                url: this.URLS.POST_UPLOAD_DOCUMENT,
                data: {
                    mediaFile: {
                        id: `prodis/${uploadedFileId}`,
                    },
                    documentType,
                },
                errorConfig: configError('post', this.errorHandlers),
            });

            return data?.jobId || '';
        }

        return '';
    }

    /**
     * @param {{ portalCookie: string; carrierCode: 'decomonkey_dpd_pl' | 'artgeist_dpd_pl'; dateFrom: string | null; dateTo: string | null; trackingIds: { id: string; }[]; }} data
     */
    async postDPDData(data) {
        const { ajax } = this.services;

        const url = this.URLS.POST_SHIPMENT_DPD_DATA;

        await ajax.post({
            url,
            data,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('post', this.errorHandlers),
        });
    }
}

export default Shipment;
