import { useQuery } from '@tanstack/react-query';
import ServicesContext from 'contexts/ServicesContext';
import { useContext } from 'react';
import {
    WORKPLACES_BASE_CACHE_TIME,
    WORKPLACES_BASE_STALE_TIME,
    workplaceKey,
} from '../config/requests.config';

export const useWorkplaceInfo = (workplace?: string | undefined) => {
    const { workplaces } = useContext(ServicesContext);

    return useQuery({
        queryFn: () => workplaces.workplace.getWorkplaceById(workplace),
        enabled: !!workplace,
        queryKey: workplaceKey(workplace),
        gcTime: WORKPLACES_BASE_CACHE_TIME,
        staleTime: WORKPLACES_BASE_STALE_TIME,
    });
};
