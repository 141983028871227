import { fromJS } from 'immutable';
import { toIdMap } from 'utils/immutable/list';

export function fetchListRequest({ options, state }) {
    const { pluralName } = options;

    return state
        .set(`${pluralName}Count`, null)
        .set(`${pluralName}ById`, fromJS({}))
        .setIn(['loaders', `${pluralName}Table`], true);
}

export function fetchListFailure({ options, state }) {
    const { pluralName } = options;

    return state
        .setIn(['loaders', `${pluralName}Table`], false);
}

export function fetchListSuccess({ options, state, action }) {
    const { pluralName } = options;
    const newList = action.payload.response['hydra:member'] ?
        toIdMap(action.payload.response['hydra:member'])
        :
        fromJS({});
    const newCount = action.payload.response['hydra:totalItems'];

    return state
        .set(`${pluralName}ById`, newList)
        .set(`${pluralName}Count`, newCount)
        .setIn(['loaders', `${pluralName}Table`], false);
}
