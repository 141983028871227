import { ApplicationJsonHeaders } from '../../constants';
import type { SalesChannelsService } from '../SalesChannelsService';

class PriceList {
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.errorHandlers = salesChannelsService.errorHandlers;
        this.getList = this.getList.bind(this);
    }

    async getList(params: {
        'offer.channels.type': string | undefined;
        'offer.channels.type[]': string[] | undefined;
    }): Promise<
        {
            id: string;
            code: string;
            currency: string;
            type: string;
            created_at: string;
            updated_at: string;
            offer: { id: string; name: string };
        }[]
    > {
        const { ajax, URLS, paramsSerializer } = this.salesChannelsService;

        const url = URLS.GET_PRICE_LISTS;
        const errorConfig = this.configError('get');

        const { data } = await ajax.get({
            url,
            config: {
                params,
                paramsSerializer,
                headers: ApplicationJsonHeaders,
            },
            errorConfig,
        });

        return data;
    }

    private configError(requestType: 'get', config = {}) {
        return {
            throwError: this.errorHandlers[requestType],
            addGenericAlert: true,
            ...config,
        };
    }
}

export default PriceList;
