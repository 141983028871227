import { get } from 'lodash';
import parser from 'qs';

import { ApplicationJsonHeaders } from 'services/constants';

import { mapBoxProducersFromApi } from 'services/armApi/mappers/boxProducers';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class BoxProducers {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly DEFAULTS: IArmApiServiceConst['DEFAULTS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS, DEFAULTS }: Pick<IArmApiServiceConst, 'URLS' | 'DEFAULTS'>,
    ) {
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;

        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
    }

    private paramsSerializer = (params) => parser.stringify(params);

    async getList({
        filters = {},
        per_page = this.DEFAULTS.BOX_PRODUCERS_PER_PAGE,
        page = 1,
    }) {
        const { ajax } = this.services;
        const { paramsSerializer } = this;

        const url = this.URLS.GET_BOX_PRODUCERS;
        const parsedParams = {
            per_page,
            page,
            ...filters,
        };

        const config = {
            params: parsedParams,
            headers: ApplicationJsonHeaders,
            paramsSerializer,
        };

        try {
            const response = await ajax.get({ url, config });
            const items = get(response, 'data.results', []);
            const totalItems = get(response, 'data.total', 0);

            return {
                items: items.map(mapBoxProducersFromApi),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async getById(id: string) {
        const { ajax } = this.services;

        const url = this.URLS.GET_BOX_PRODUCERS_BY_ID.replace('{id}', id);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response = await ajax.get({ url, config });

        return response.data;
    }

    async create(data: unknown) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_BOX_PRODUCERS;
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data: data_1 } = await ajax.post({
            url,
            data,
            errorConfig,
            config,
        });

        return mapBoxProducersFromApi(data_1);
    }
}

export default BoxProducers;
