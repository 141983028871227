import { getNamespaces } from '../helpers/getNamespaces';
import { setLoaded, setLoading } from 'utils/immutable/map';

const defaultNamespaces = {
    itemsById: 'itemsById',
    errors: 'errors',
    editLoader: 'editLoader',
};

export function request({ namespaces: configNamespaces, initialState }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const item = action.payload.item;
        const id = item.getId();
        const errors = initialState[namespaces.errors];

        return state
            .setIn([namespaces.itemsById, id], setLoading(item))
            .set(namespaces.errors, errors)
            .set(namespaces.editLoader, true);
    };
}

export function failure({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, responseModel, action }) {
        const id = action.payload.id;
        const item = state.getIn([namespaces.itemsById, id]);
        const errors = responseModel.getErrors(options && options.model);

        return state
            .setIn([namespaces.itemsById, id], setLoaded(item))
            .set(namespaces.errors, errors)
            .set(namespaces.editLoader, false);
    };
}

export function success({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, responseModel }) {
        const editedItem = responseModel.getItemFromResponse(options.model);
        const id = editedItem.getId();

        return state
            .setIn([namespaces.itemsById, id], editedItem)
            .setIn([namespaces.itemsById, id], setLoaded(editedItem))
            .set(namespaces.editLoader, false);
    };
}
