import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ClearIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <path
                d="M10.9198 3.58688C10.8179 3.48809 10.6809 3.43379 10.539 3.43592C10.397 3.43804 10.2617 3.49642 10.1628 3.59822L7.25923 6.50181L4.35562 3.59819C4.30614 3.54729 4.24704 3.50673 4.18175 3.47888C4.11647 3.45102 4.04629 3.43641 3.97531 3.43591C3.90433 3.43541 3.83396 3.44902 3.76829 3.47595C3.70262 3.50288 3.64295 3.5426 3.59276 3.59279C3.54257 3.64298 3.50285 3.70265 3.47592 3.76832C3.44899 3.83399 3.43538 3.90436 3.43588 3.97534C3.43639 4.04632 3.45099 4.11649 3.47885 4.18178C3.50671 4.24707 3.54726 4.30617 3.59816 4.35564L6.50178 7.25926L3.59819 10.1629C3.54729 10.2123 3.50674 10.2714 3.47888 10.3367C3.45102 10.402 3.43642 10.4722 3.43592 10.5432C3.43542 10.6141 3.44903 10.6845 3.47596 10.7502C3.50289 10.8159 3.54261 10.8755 3.5928 10.9257C3.64299 10.9759 3.70266 11.0156 3.76833 11.0425C3.834 11.0695 3.90437 11.0831 3.97535 11.0826C4.04633 11.0821 4.1165 11.0675 4.18179 11.0396C4.24708 11.0118 4.30618 10.9712 4.35565 10.9203L7.25924 8.01673L10.1629 10.9203C10.2123 10.9712 10.2714 11.0118 10.3367 11.0397C10.402 11.0675 10.4722 11.0821 10.5432 11.0826C10.6141 11.0831 10.6845 11.0695 10.7502 11.0426C10.8159 11.0157 10.8755 10.9759 10.9257 10.9257C10.9759 10.8756 11.0156 10.8159 11.0426 10.7502C11.0695 10.6845 11.0831 10.6142 11.0826 10.5432C11.0821 10.4722 11.0675 10.402 11.0396 10.3368C11.0118 10.2715 10.9712 10.2124 10.9203 10.1629L8.0167 7.25927L10.9203 4.35568C10.9717 4.30572 11.0126 4.24594 11.0405 4.17989C11.0684 4.11385 11.0827 4.04287 11.0827 3.97118C11.0826 3.89948 11.0682 3.82853 11.0402 3.76251C11.0122 3.6965 10.9713 3.63678 10.9198 3.58688Z"
            />
        </SvgIcon>
    );
}

ClearIcon.defaultProps = {
    titleAccess: '',
};

ClearIcon.propTypes = svgIconPropTypes;
