import get from 'lodash/get';
import { mapPositionFromApi } from '../mappers/positions';
import { ApplicationJsonHeaders } from '../../constants';

class Position {
    constructor(pdpService, services, URLS, DEFAULTS, ListModel) {
        this.pdpService = pdpService;
        this.services = services;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.ListModel = ListModel;
        this.errorHandlers = this.pdpService.errorHandlers;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }
    getList({
        filters = {},
        sort = {},
        page = 1,
        perPage = this.DEFAULTS.PER_PAGE,
    }) {
        const { ajax } = this.services;
        const { getSortParams, POSITION_PARAMS } = this.pdpService;
        const url = this.URLS.GET_POSITIONS;
        const config = {
            params: {
                ...(filters?.search && { search: filters.search }),
                page: page,
                per_page: perPage,
            },
        };
        const sortFilters = getSortParams(POSITION_PARAMS.SORTING_KEYS, sort);
        const onError = this.errorHandlers.get;

        if (sortFilters) config.params.order = sortFilters;

        return ajax
            .get({ url, config, onError })
            .then((response) => {
                return ({
                    items: get(response, 'data.hydra:member', []).map(mapPositionFromApi),
                    totalItems: get(response, 'data.hydra:totalItems', 0),
                });
            });
    }
    getById({ id }) {
        const { ajax } = this.services;
        const url = this.URLS.GET_POSITION.replace('{id}', id);

        return ajax
            .get({ url })
            .then(({ data }) => {
                return data;
            });
    }
    create({ position: data }) {
        const { ajax } = this.services;

        const url = this.URLS.POST_POSITION;
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const onError = this.errorHandlers.post;

        return ajax
            .post({ url, config, data, onError })
            .then(({ data }) => {
                return data;
            });
    }
    edit({ position: data, id }) {
        const { ajax } = this.services;
        const url = this.URLS.PUT_POSITION.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        return ajax.put({ url, data, config });
    }
}

export default Position;
