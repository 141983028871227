import { omitNegative } from '../../../utils/filters';

export const mapImportOrderFromApi = (importOrder = {}) => {
    const contractor_orders = [];

    importOrder.contractor_orders?.map((contractorOrder = {}) => {
        contractorOrder.order_lines?.map((orderLine = {}) => {
            const tracking_numbers = {};

            orderLine.shipments?.map(({ carrier_code, tracking_number, tracking_page_url }) => {
                if (tracking_numbers[carrier_code]) {
                    if (tracking_numbers[carrier_code].filter((trac) => trac.number === tracking_number).length === 0) {
                        tracking_numbers[carrier_code].push({
                            number: tracking_number,
                            url: tracking_page_url,
                        });
                    }
                } else {
                    tracking_numbers[carrier_code] = [
                        {
                            number: tracking_number,
                            url: tracking_page_url,
                        }
                    ];
                }
            });

            contractor_orders.push({
                id: contractorOrder.id,
                status_details: contractorOrder.status_details,
                order_number: contractorOrder.order_number,
                status: contractorOrder.status,
                quantity: orderLine.ordered_quantity,
                sku: orderLine.sku,
                requestPayments: contractorOrder.request_payments,
                passedToSimp: contractorOrder.passed_to_simp,
                tracking_numbers,
            });
        });
    });

    return {
        ...importOrder,
        contractor_orders,
    };
};

export const mapGetListFilters = ({
    contractorId = '',
    createdAt = [],
    status = '',
    userId = '',
}) => (
    omitNegative({
        status: !!status && {
            eq: status
        },
        contractor_id: !!contractorId && {
            eq: contractorId
        },
        created_by: !!userId && {
            eq: userId
        },
        created_at: createdAt.length && omitNegative({
            gte: createdAt[0] ?? '',
            lte: createdAt[1] ?? ''
        })
    })
);
