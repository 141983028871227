import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CharacterCounterStyled } from './characterCounter.styles';

export const CharacterCounter = ({ currentCharacters, minCharacters = 55, maxCharacters = 70 }) => {
    const { t } = useTranslation();

    return (
        <CharacterCounterStyled>
            {t('common:labels.characters', { count: currentCharacters, countMin: minCharacters, countMax: maxCharacters })}
        </CharacterCounterStyled>
    );
};

CharacterCounter.propTypes = {
    currentCharacters: PropTypes.number.isRequired,
    minCharacters: PropTypes.number,
    maxCharacters: PropTypes.number,
};

export default CharacterCounter;
