import React from 'react';
import MUIHidden from '@mui/material/Hidden';

export default class Hidden extends React.PureComponent {
    render() {
        return (
            <MUIHidden
                initialWidth="xl"
                { ...this.props }
            />
        );
    }
}

Hidden.propTypes = {
    ...MUIHidden.propTypes,
};
