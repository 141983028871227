import { configError } from 'services/utils/utils';
import {
    mapOfferProductSetFromApi,
    mapOfferProductSetToApi,
} from 'services/salesChannels/mappers/offerProductSet';
import {
    ApplicationJsonHeaders,
    HttpPatchHeaders,
    MAXIMUM_ITEMS_COUNT,
} from 'services/constants';
import type { SalesChannelsService } from '../SalesChannelsService';

class OfferProductSets {
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.errorHandlers = salesChannelsService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.edit = this.edit.bind(this);
        this.generateGtin = this.generateGtin.bind(this);
    }

    async getList({ id }) {
        const { ajax, URLS, errorHandlers } = this.salesChannelsService;
        const url = URLS.GET_OFFER_PRODUCT_SETS.replace('{id}', id);
        const errorConfig = configError('get', errorHandlers);
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page: 1,
                itemsPerPage: MAXIMUM_ITEMS_COUNT,
            },
        };

        const { data = {} } = await ajax.get({ url, config, errorConfig });

        return {
            items: data.results?.map(mapOfferProductSetFromApi),
            itemsTotal: data.total,
        };
    }

    async generateGtin(offerId, productIds) {
        const { ajax, URLS, translator, alert } = this.salesChannelsService;
        const url = URLS.PATCH_OFFER_PRODUCT_SETS_GENERATE_GTIN.replace(
            '{id}',
            offerId,
        );
        const errorConfig = configError('patch', this.errorHandlers);
        const config = { headers: HttpPatchHeaders };

        const data = {
            offer_product_sets: productIds.map((productId) => ({
                id: productId,
            })),
        };

        await ajax.patch({ url, data, config, errorConfig });

        alert.addSuccess({
            message: translator.t('alerts.gtinGenerationStarted', {
                ns: 'manageOffers',
            }),
        });
    }

    async edit({ offerId, productSetIds }) {
        const { ajax, URLS, translator, alert, errorHandlers } =
            this.salesChannelsService;

        const url = URLS.PATCH_OFFER_PRODUCT_SETS.replace('{id}', offerId);
        const errorConfig = configError('patch', errorHandlers);
        const config = {
            headers: HttpPatchHeaders,
        };

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: mapOfferProductSetToApi(productSetIds),
        });

        // Handling success with warnings
        if (
            !response?.data?.results ||
            response?.data.results?.some(({ status }) => status !== 'success')
        ) {
            alert.addWarning({
                message: translator.t('alerts.productSetsAddedWithWarnings', {
                    ns: 'arm',
                }),
            });
        } else {
            alert.addSuccess({
                message: translator.t('alerts.productSetsAdded', {
                    ns: 'arm',
                }),
            });
        }

        return response;
    }

    async uploadFromCsv({ file, offerId }) {
        const {
            salesChannelsService: {
                ajax,
                URLS,
                translator,
                alert,
                errorHandlers,
                mediaFiles: { uploadCustomFiles },
            },
        } = this;

        const { id: uploadedFileId } = await uploadCustomFiles(file);

        const { data } = await ajax.post({
            url: URLS.POST_UPLOAD_OFFER_PRODUCT_SETS.replace('{id}', offerId),
            errorConfig: configError('post', errorHandlers),
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: {
                media_file: {
                    id: `custom-files/${uploadedFileId}`,
                },
                separator: ',',
                enclosure: '"',
            },
        });

        alert.addSuccess({
            message: translator.t('alerts.fileUploadedSuccessfully', {
                ns: 'manageOffers',
            }),
        });

        return data;
    }
}

export default OfferProductSets;
