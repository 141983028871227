import { URLS, DEFAULTS } from './constants';
import { ListModel } from './models';
import { CamundaProxyService } from './CamundaProxyService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { StoreService } from 'services/store/StoreService';

export const camundaProxyServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
    storeService: StoreService,
) =>
    CamundaProxyService.getInstance({
        services: {
            ajax: ajaxService,
            alerts: alertService,
            store: storeService,
        },
        URLS,
        DEFAULTS,
        ListModel,
    });
