import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import withUrlChange from 'hoc/withUrlChange/withUrlChange';
import { MenuItem } from '@mui/material';
import Select from 'components/Select/select';
import {
    DescriptionStyled,
    RootStyled,
} from 'components/OldTable/Pagination/ItemsPerPagePicker/itemsPerPagePicker.styles';

class ItemsPerPagePicker extends React.PureComponent {
    render() {
        const {
            t,
            withDescription,
            options,
            defaultValue,
            value,
            handleInstantChange,
            submitPagination,
            perPageItems,
        } = this.props;
        const selectValue = value
            ? Number(value)
            : perPageItems || defaultValue;

        return (
            <RootStyled>
                {withDescription && (
                    <DescriptionStyled>
                        {upperFirst(
                            t('common:actions.show', { defaultValue: 'Show' }),
                        )}
                    </DescriptionStyled>
                )}
                <Select
                    id="items-per-page-picker"
                    name="items per page"
                    margin="dense"
                    variant="standard"
                    value={selectValue}
                    onChange={
                        submitPagination
                            ? (e) =>
                                  submitPagination({ per_page: e.target.value })
                            : handleInstantChange
                    }
                    InputProps={{
                        disableUnderline: true,
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </RootStyled>
        );
    }
}

ItemsPerPagePicker.defaultProps = {
    t: (key) => key,
    withDescription: true,
    defaultOption: 20,
    options: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ],
};

ItemsPerPagePicker.propTypes = {
    t: PropTypes.func,
    withDescription: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        }),
    ),
    itemsCount: PropTypes.number,
    defaultOption: PropTypes.number,
    defaultValue: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleInstantChange: PropTypes.func,
    submitPagination: PropTypes.func,
    perPageItems: PropTypes.number,
};

const ItemsPerPagePickerWithHOC = withUrlChange(ItemsPerPagePicker, {
    fieldType: 'pagination',
    fieldName: 'per_page',
});

export default ItemsPerPagePickerWithHOC;
