import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'react-router-redux';
import { fromJS, Map } from 'immutable';
import qs from 'qs';
import * as C from './constants';
import * as A from './actions';
import { technologies } from '../technologies/saga';
import { MODULE_NAME } from '../../constants';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import { getSearch } from 'utils/router/selectors';

import { slimServicesFactory } from 'services/servicesFactory';
import handleErrorsConfig from 'services/armApi/errorHandlers';
import { mapFiltersToApi } from 'utils/filters';
import {
    mapIntermediateProductFromApi,
    mapTechnologyComponentsFromApi,
} from 'services/armApi/mappers/intermediateProduct';
import { mapTechnologyFromApi } from 'services/armApi/mappers/technology';
import i18next from 'i18next';
import { getString } from 'utils/immutable/map';
import { mapGen } from 'utils/immutable/list';

const ARM_URL = get(window, 'env.ARM_API_URL', '');
const intermediateProductsV1 = makeEndPoint(
    ARM_URL + '/api/intermediate_products',
);
const intermediateProducts = makeEndPoint(
    ARM_URL + '/api/v2/intermediate_products',
);
const technologyComponents = makeEndPoint(
    ARM_URL + '/api/intermediate_product_groups',
);

export default function* propertiesSaga() {
    yield takeEvery(
        C.CREATE_INTERMEDIATE_PRODUCT.request,
        postIntermediateProduct,
    );
    yield takeEvery(
        C.REMOVE_INTERMEDIATE_PRODUCT.request,
        deleteIntermediateProduct,
    );
    yield takeEvery(
        C.EDIT_INTERMEDIATE_PRODUCT.request,
        putIntermediateProduct,
    );
    yield takeLatest(
        C.FETCH_INTERMEDIATE_PRODUCTS.request,
        getIntermediateProducts,
    );
    yield takeEvery(
        C.FETCH_INTERMEDIATE_PRODUCT.request,
        getIntermediateProduct,
    );
    yield takeLatest(
        C.FETCH_TECHNOLOGY_COMPONENTS.request,
        getTechnologyComponents,
    );
}

function* getPropertyValue(type, value) {
    switch (type) {
        case 'file': {
            if (!value || value.length < 1) {
                return null;
            }
            const filesUploaded = [];
            const filesToUpload = [];

            value.forEach((file) => {
                if (file.get('id')) {
                    filesUploaded.push(file.get('id'));
                } else {
                    filesToUpload.push(file);
                }
            });

            const fileUploadResponse = yield uploadFiles(filesToUpload);

            return filesUploaded.concat(
                fileUploadResponse.map((response) => response.data.id),
            );
        }
        case 'bool':
            return Boolean(value);
        case 'number': {
            const newValue = Number(value);

            if (Number.isInteger(newValue)) {
                return newValue;
            }

            return null;
        }
        default:
            return value ? value : '';
    }
}

function uploadFiles(files) {
    const slimServices = slimServicesFactory();

    return Promise.all(
        files.map((file) =>
            slimServices.armApi.uploadProperty(file.get('file')),
        ),
    );
}

function* mapPropertyValues(propertyValue) {
    return fromJS({
        property_id: propertyValue.getIn(['property', 'id']),
        property: propertyValue.get('property'),
        value: yield getPropertyValue(
            propertyValue.getIn(['property', 'type']),
            propertyValue.get('value'),
        ),
    });
}

function reduceComponents(componentsArray, component) {
    if (component.getIn(['selectedIntermediateProduct', 'id'])) {
        componentsArray = componentsArray.push(
            fromJS({
                component_id: component.getIn([
                    'selectedIntermediateProduct',
                    'id',
                ]),
                group_component_id: component.get('id'),
            }),
        );
    }

    return componentsArray;
}

function mapNumericStringToInt(value, mod) {
    if (value === '') {
        return null;
    }

    if (typeof mod === 'number') {
        return parseFloat(value) * mod;
    }

    return parseInt(value);
}

function* trimIntermediateProduct(intermediateProduct) {
    const propertyValues =
        intermediateProduct.get('property_values') || fromJS([]);
    const parsedComponents =
        intermediateProduct.get('components') || fromJS([]);
    const group = intermediateProduct.get('technology') || fromJS({});
    const groups = group.get('code') ? [group.get('code')] : [];
    const components = parsedComponents.reduce(reduceComponents, fromJS([]));
    const parsedPropertyValues = yield mapGen(
        fromJS(propertyValues),
        mapPropertyValues,
    );

    return fromJS({
        name: getString(intermediateProduct, 'name'),
        description: getString(intermediateProduct, 'description'),
        code: getString(intermediateProduct, 'code'),

        groups,
        property_values: parsedPropertyValues,
        components,
        id: getString(intermediateProduct, 'id'),

        width: mapNumericStringToInt(intermediateProduct.get('width'), 10),
        height: mapNumericStringToInt(intermediateProduct.get('height'), 10),
        length: mapNumericStringToInt(intermediateProduct.get('length'), 10),
        weight: mapNumericStringToInt(intermediateProduct.get('weight')),
        price: mapNumericStringToInt((intermediateProduct.get('price') * 100).toFixed(2)),
        accessories: intermediateProduct.get('accessories') || fromJS([]),
    });
}

function* postIntermediateProduct(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);
    const technology =
        action.payload.item.get('groups') &&
        action.payload.item.get('groups').first();
    const newIntermediateProduct = yield trimIntermediateProduct(
        action.payload.item,
    );

    yield intermediateProductsV1.post({
        action: A.createIntermediateProduct,
        mapper: mapIntermediateProductFromApi,
        data: newIntermediateProduct,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'arm:alerts.intermediateProductCreated',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: {
                technology,
            },
        },
        callbacks: {
            failure: errorHandlers.post,
        },
        sagaCallbacks: {
            success: () => push(`/${MODULE_NAME}/intermediate-products`),
        },
    });
}

function* putIntermediateProduct(action) {
    const slimServices = slimServicesFactory();
    const technology =
        action.payload.item.get('groups') &&
        action.payload.item.get('groups').first();
    const intermediateProduct = yield trimIntermediateProduct(
        action.payload.item,
    );
    const intermediateProductId = intermediateProduct.get('id');

    yield intermediateProductsV1.put({
        action: A.editIntermediateProduct,
        mapper: mapIntermediateProductFromApi,
        path: intermediateProductId,
        data: intermediateProduct,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: i18next.t('alerts.intermediateProductChanged', {
                    ns: 'arm',
                }),
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: {
                id: intermediateProductId,
                technology,
            },
            failure: {
                id: intermediateProductId,
            },
        },
        callbacks: {
            failure: (error) => {
                const errorDetail = error?.response?.data?.detail;
                const message = i18next.t(
                    'alerts.intermediateProductChangeError',
                    { ns: 'arm' },
                );

                if (errorDetail) {
                    slimServices.alerts.addError({
                        message: `${message} ${errorDetail}`,
                    });
                } else {
                    slimServices.alerts.addError({ message });
                }
            },
        },
        sagaCallbacks: {
            success: () => push(`/${MODULE_NAME}/intermediate-products`),
        },
    });
}

function* deleteIntermediateProduct(action) {
    const deleteId = action.payload.id;

    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    yield intermediateProductsV1.delete({
        action: A.removeIntermediateProduct,
        path: deleteId,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'arm:alerts.intermediateProductDeleted',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: {
                id: deleteId,
            },
            failure: {
                id: deleteId,
            },
        },
        callbacks: {
            failure: errorHandlers.delete,
        },
    });
}

function* getIntermediateProduct(action) {
    const { id, loaderName } = action.payload;

    try {
        const response = yield intermediateProducts.get({
            path: id,
            alerts: {
                failure: true,
            },
        });
        const intermediateProduct =
            yield injectTechnologyIntoIntermediateProduct(response);

        yield put(
            A.fetchIntermediateProduct.success({
                response: intermediateProduct,
                loaderName,
            }),
        );
    } catch (e) {
        yield put(
            A.fetchIntermediateProduct.failure({
                response: e,
                loaderName,
            }),
        );
    }
}

function* getTechnologyComponents(action) {
    const { technologyId, intermediateProductId } = action.payload;

    try {
        const response = yield technologyComponents.get({
            path: technologyId,
            alerts: {
                failure: true,
            },
        });
        const intermediateProductTechnologyComponents =
            yield mapTechnologyComponentsFromApi(
                response.data.group_components,
            );

        yield put(
            A.fetchTechnologyComponents.success({
                response: {
                    intermediateProductId,
                    technologyComponents:
                        intermediateProductTechnologyComponents,
                },
            }),
        );
    } catch (e) {
        yield put(
            A.fetchTechnologyComponents.failure({
                response: e,
            }),
        );
    }
}

function* injectTechnologyIntoIntermediateProduct(response) {
    const intermediateProduct = mapIntermediateProductFromApi(
        response.data,
        'edit',
    );
    const technologyCode =
        intermediateProduct.technology && intermediateProduct.technology.code;

    if (technologyCode) {
        const technology = yield getTechnologyWithCode(technologyCode);

        if (technology) {
            intermediateProduct.technology = technology;
        } else {
            intermediateProduct.technology = new Map();
        }
    }

    return intermediateProduct;
}

function* getTechnologyWithCode(technologyCode) {
    const hydraMember = 'hydra:member';
    const technologyResponse = yield technologies.get({
        mapper: mapTechnologyFromApi,
        params: {
            filter: {
                code: {
                    eq: technologyCode,
                },
            },
        },
    });

    return (
        technologyResponse.data[hydraMember] &&
        technologyResponse.data[hydraMember][0]
    );
}

function* getIntermediateProducts(action) {
    let params = {};

    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    if (action.payload.requestProperties) {
        params = action.payload.requestProperties;
    } else {
        const urlParams = qs.parse(yield select(getSearch));
        const sortFields = ['code', 'name', 'raw_material', 'products_count'];

        if (urlParams.filters) {
            const filterParams = mapFiltersToApi(urlParams.filters);

            params = { ...params, ...filterParams };
        }

        if (urlParams.order) {
            params.sort = {};
            sortFields.forEach((field) => {
                if (urlParams.order[field])
                    params.sort[field] = urlParams.order[field];
            });
        }

        params.page = (urlParams.pagination && urlParams.pagination.page) || 1;
        params.per_page =
            (urlParams.pagination && urlParams.pagination.per_page) || 20;
    }

    yield intermediateProducts.get({
        action: A.fetchIntermediateProducts,
        mapper: (data) => ({
            ...data,
            'hydra:member': data['hydra:member'].map((listItem) =>
                mapIntermediateProductFromApi(listItem),
            ),
        }),
        params: params,
        alerts: {
            failure: true,
        },
        callbacks: {
            failure: errorHandlers.get,
        },
    });
}
