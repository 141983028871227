import {
    mapCarrierRuleGetList,
    mapCarrierRuleGetListParams,
    mapFormForCarrierRule,
    mapCarrierRuleBatch,
} from '../mappers/carrierRule';
import parser from 'qs';
import { ApplicationJsonHeaders } from '../../constants';
import { configError } from '../../utils/utils';
import type {
    IProdisServiceConst,
    IProdisServiceServices,
} from '../prodisService.type';
import type { ProdisService } from '../prodisService';

const defaultObj = {
    items: [],
    totalItems: 0,
};

class CarrierRule {
    private readonly errorHandlers: ProdisService['errorHandlers'];

    constructor(
        private readonly prodisService: ProdisService,
        private readonly services: IProdisServiceServices,
        private readonly constans: IProdisServiceConst,
    ) {
        this.errorHandlers = this.prodisService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.uploadFromCSV = this.uploadFromCSV.bind(this);
        this.getById = this.getById.bind(this);
        this.createCarrierRule = this.createCarrierRule.bind(this);
        this.updateCarrierRule = this.updateCarrierRule.bind(this);
        this.removeBatch = this.removeBatch.bind(this);
        this.updateBatch = this.updateBatch.bind(this);
        this.getExportCarrierRules = this.getExportCarrierRules.bind(this);
    }

    paramsSerializer = (params) => parser.stringify(params);

    uploadFromCSV(file) {
        const {
            services: {
                ajax,
                mediaFiles: { uploadCustomFiles },
            },
            constans: { URLS },
            errorHandlers,
        } = this;

        const sendUploadedId = ({ id }) =>
            ajax.post({
                url: URLS.POST_IMPORT_CARRIER_RULES,
                config: {
                    headers: ApplicationJsonHeaders,
                },
                errorConfig: configError('post', errorHandlers),
                data: {
                    mediaFile: {
                        id: `custom-files/${id}`,
                    },
                    separator: ',',
                    enclosure: '"',
                },
            });

        return uploadCustomFiles(file)
            .then(sendUploadedId)
            .then((response) => response.data);
    }

    async getList(params) {
        const {
            services: { ajax },
            constans: {
                URLS: { GET_CARRIER_RULES },
            },
            paramsSerializer,
        } = this;

        try {
            const { data } = await ajax.get({
                url: GET_CARRIER_RULES,
                config: {
                    params: mapCarrierRuleGetListParams(params),
                    paramsSerializer,
                },
            });

            const results = data?.['hydra:member'];
            const total = data?.['hydra:totalItems'];

            if (Array.isArray(results) && typeof total === 'number') {
                return mapCarrierRuleGetList({ results, total });
            }

            return defaultObj;
        } catch {
            return defaultObj;
        }
    }

    async removeBatch(ids) {
        const {
            services: { ajax },
            constans: {
                URLS: { PUT_BULK_DELETE_CARRIER_RULES },
            },
        } = this;

        await ajax.put({
            url: PUT_BULK_DELETE_CARRIER_RULES,
            data: mapCarrierRuleBatch(ids),
            config: {
                headers: ApplicationJsonHeaders,
            },
        });
    }

    async updateBatch(carrierType, ids) {
        const {
            services: { ajax },
            constans: {
                URLS: { PUT_BATCH_CARRIER_TYPE_UPDATE },
            },
        } = this;

        await ajax.put({
            url: PUT_BATCH_CARRIER_TYPE_UPDATE.replace('{code}', carrierType),
            data: mapCarrierRuleBatch(ids),
            config: {
                headers: ApplicationJsonHeaders,
            },
        });
    }

    getById(id) {
        const ajax = this.services.ajax;
        const url = this.constans.URLS.GET_CARRIER_RULE.replace('{id}', id);

        return ajax
            .get({
                url,
                config: { headers: ApplicationJsonHeaders },
                errorConfig: { throwError: this.errorHandlers.get },
            })
            .then((response) => response.data);
    }

    createCarrierRule(data) {
        const {
            services: { ajax },
            constans: {
                URLS: { POST_CARRIER_RULE },
            },
        } = this;

        return ajax.post({
            url: POST_CARRIER_RULE,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: { addGenericAlert: false },
            data: mapFormForCarrierRule(data),
        });
    }

    updateCarrierRule({ data, id }) {
        const {
            services: { ajax },
            constans: {
                URLS: { PUT_CARRIER_RULE },
            },
        } = this;

        return ajax.put({
            url: PUT_CARRIER_RULE.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: { addGenericAlert: false },
            data: mapFormForCarrierRule(data),
        });
    }

    /**
     * Downland carrier rules list to CSV
     */
    async getExportCarrierRules({
        page = 1,
        per_page = 15,
        filters = {},
        sort = {},
    }: {
        page?: number;
        per_page?: number;
        filters?: Record<string, unknown>;
        sort?: Record<string, unknown>;
    }) {
        const {
            services: { ajax },
            constans: {
                URLS: { GET_EXPORT_CARRIER_RULES },
                HEADERS: { AcceptTextCsv },
            },
            paramsSerializer,
        } = this;

        await ajax.download({
            url: GET_EXPORT_CARRIER_RULES,
            config: {
                headers: AcceptTextCsv,
                params: mapCarrierRuleGetListParams({
                    page,
                    per_page,
                    filters,
                    sort,
                }),
                paramsSerializer,
            },
        });
    }
}

export default CarrierRule;
