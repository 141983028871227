import styled from '@emotion/styled';
import Typography from 'components/Typography';

export const RootStyled = styled('div')`
  display: flex;
  align-items: center;
`;
RootStyled.displayName = 'Root';

export const DescriptionStyled = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
DescriptionStyled.displayName = 'Description';
