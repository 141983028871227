import { ComponentPropsWithoutRef, ComponentRef, forwardRef } from 'react';
import { ButtonWrapperStyled } from './button.styles';
import { Loader } from 'components/Loader/Loader';

const Button = forwardRef<
    ComponentRef<typeof ButtonWrapperStyled>,
    ComponentPropsWithoutRef<typeof ButtonWrapperStyled>
>(
    (
        {
            loading,
            onClick,
            children,
            variant = 'contained',
            baseWidth,
            ...rest
        },
        ref,
    ) => (
        <ButtonWrapperStyled
            variant={variant}
            loading={loading}
            baseWidth={baseWidth}
            ref={ref}
            onClick={loading ? null : onClick}
            {...rest}
        >
            {loading
                ? [
                      <Loader
                          key="loader"
                          aria-label="buttonLoader"
                          size={24}
                      />,
                      children,
                  ]
                : children}
        </ButtonWrapperStyled>
    ),
);

Button.displayName = 'Button';

export { Button };
export default Button;
