import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Collapse } from '@mui/material';
import { excludePropForward } from 'utils/styles';

const leftPaddingStyles = ({ theme }) => css`
  padding-left: ${theme.spacing(4)};
`;

const smallRowsStyles = css`
  grid-template-rows: max-content auto;
`;

export const RootStyled = styled('div', excludePropForward(['leftPadding', 'smallRows']))`
  position: relative;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[500]};
  grid-template-rows: max-content auto;
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.duration.standard};
  
  ${({ leftPadding }) => leftPadding && leftPaddingStyles};
  ${({ smallRows }) => smallRows && smallRowsStyles};
`;
RootStyled.displayName = 'Root';

export const CollapseStyled = styled(Collapse)`
  grid-row-start: 2;
  grid-column-start: start;
  grid-column-end: end;
  cursor: text;
`;
CollapseStyled.displayName = 'Collapse';
