import arrayToTree from 'utils/arrayToTree';
import { mapChannelCategoriesFromAPI } from 'services/salesChannels/mappers/channelCategories';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';
import type { SalesChannelsService } from '../SalesChannelsService';

export class ChannelCategories {
    private readonly asyncRequestPaginator: AsyncRequestPaginator<unknown>;

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getList = this.getList.bind(this);
        this.getMore = this.getMore.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMore,
            mapChannelCategoriesFromAPI,
        );
    }

    getMore(params) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            errorHandlers,
            HEADERS,
            paramsSerializer,
        } = this.salesChannelsService;
        const url = URLS.GET_CHANNEL_CATEGORIES;

        const { page = 1, per_page = DEFAULTS.PATTERNS_PER_PAGE } = params;

        const config = {
            headers: HEADERS.basic,
            params: { page: page, itemsPerPage: per_page },
            paramsSerializer,
        };
        const channelId = 'channel.id';

        if (params[channelId]) {
            config.params[channelId] = params[channelId];
        }

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        return ajax.get({ url, config, errorConfig });
    }

    async getList(params = {}) {
        const { items } =
            await this.asyncRequestPaginator.fetchFullList(params);

        return arrayToTree(items, { injectDataToParent: true });
    }
}
