import { MODULE_NAME } from '../../constants';
import { makeRequestConstant } from 'utils/redux/makeConstants';
import { Map } from 'immutable';

export const FETCH_DICTIONARIES = makeRequestConstant(
    MODULE_NAME + '_FETCH_FETCH_DICTIONARIES',
);
export const FETCH_PRODUCTION_ORDERS = makeRequestConstant(
    MODULE_NAME + '_FETCH_PRODUCTION_ORDERS',
);
export const PRINT_PRODUCTION_ORDERS = makeRequestConstant(
    MODULE_NAME + '_PRINT_PRODUCTION_ORDERS',
);
export const REPRINT_PRODUCTION_ORDERS = makeRequestConstant(
    MODULE_NAME + '_REPRINT_PRODUCTION_ORDERS',
);
export const DOWNLOAD_PRODUCTION_ORDERS = makeRequestConstant(
    MODULE_NAME + '_DOWNLOAD_PRODUCTION_ORDERS',
);
export const SAVE_AS_PRESET = makeRequestConstant(
    MODULE_NAME + '_SAVE_AS_PRESET',
);

export const FILTER_TO_PARAM_MAP = Map({
    productionAllowedDateFrom: 'productionAllowedDateFrom',
    productionAllowedDateTo: 'productionAllowedDateTo',
    selectedTechnologies: 'technology',
    selectedAccessories: 'accessory',
    selectedExcludedAccessories: 'notAccessory',
    selectedStates: 'state',
    selectedPlatforms: 'platform',
    selectedContractors: 'contractor',
    selectedCountries: 'shipCountry',
    selectedExcludedCountries: 'notShipCountry',
    minWidth: 'partsMinShorterSize',
    maxWidth: 'partsMaxShorterSize',
    minLength: 'partsMinLongerSideSum',
    maxLength: 'partsMaxLongerSideSum',
    selectedPartsCount: 'partsCount',
    isMultipackage: 'multiPackage',
    shippingBox: 'shippingBoxCode',
    carrier: 'carrierTypeCode',
    search: 'productionOrderNumber',
});
