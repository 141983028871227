import React from 'react';
import { Link } from 'react-router-dom';

const LinkWithRef = React.forwardRef((props, ref) => {
    return (
        <Link
            innerRef={ ref }
            { ...props }
        />
    );
});

export default LinkWithRef;
