import { MODULE_NAME } from '../../constants';
import { makeRequestConstant } from 'utils/redux/makeConstants';

export const CREATE_PROPERTY = makeRequestConstant(MODULE_NAME + '_CREATE_PROPERTY');
export const EDIT_PROPERTY = makeRequestConstant(MODULE_NAME + '_EDIT_PROPERTY');
export const REMOVE_PROPERTY = makeRequestConstant(MODULE_NAME + '_REMOVE_PROPERTY');

export const FETCH_PROPERTIES = makeRequestConstant(MODULE_NAME + '_FETCH_PROPERTIES');
export const FETCH_PROPERTY = makeRequestConstant(MODULE_NAME + '_FETCH_PROPERTY');

export const CLEAR_ERRORS = MODULE_NAME + '_CLEAR_ERRORS';
export const CLEAR_POST_RESPONSE = MODULE_NAME + '_CLEAR_POST_RESPONSE';
