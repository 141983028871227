const MEDIA_FILES_URL = window.env && window.env.MEDIA_FILES_URL;

export const MODULE_NAME = 'arm';

export const creatablePropertiesTypes = [
    'bool',
    'number',
    'text',
    'select',
    'file',
];

export const colorProperty = 'color';

export const TECHNOLOGY_THUMBNAIL_UPLOAD_CONFIG = {
    MAX_FILE_SIZE: 20 * 1024 * 1024,
    ACCEPTED_MIME_TYPES: ['image/jpeg', 'image/bmp', 'image/png'],
};

export const TECHNOLOGY_ATTACHMENTS_UPLOAD_CONFIG = {
    MAX_FILE_SIZE: 20 * 1024 * 1024,
};

export const PRINT_SPEC_FILE_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: ['application/pdf'],
    MAX_FILE_SIZE: 1024 * 1024 * 1024, //1GB
};

export const TECHNOLOGY_COMPONENTS_CODES = [
    'akyliteBoard',
    'bar',
    'colorFrame',
    'colorLaminate',
    'cork',
    'doubleSidedMap',
    'fabric',
    'foil',
    'glue',
    'luminescentLayer',
    'box',
    'passePartout',
    'printableAdhesiveFoil',
    'rawMaterial',
    'board',
    'margin',
    'cutLines',
    'cuttingForm',
];

export const CONTRACTOR_TYPE_DROP_FILTER = { type: 'DROP' };

export const BOX_GROUPS_AVAILABLE_FILTERS = [
    { key: 'search', operator: 'match' },
    { key: 'total_box_count', operator: 'eq' },
];

export const PRODUCT_SET_PREVIEWS_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: [
        'image/jpeg',
        'image/gif',
        'image/svg+xml',
        'image/tiff',
        'image/bmp',
        'image/png',
    ],
    MAX_FILE_SIZE: 30 * 1024 * 1024,
    MAX_FILES_LIMIT: 150,
    PREVIEWS_FILE_LIMIT: 150,
    UPPY: {
        id: 'preview-files',
        autoProceed: false,
        debug: true,
    },
    TUS: {
        endpoint: MEDIA_FILES_URL + '/api/v1/preview-files/tus',
        chunkSize: 10000000, // 10mb
        retryDelays: [0, 1000, 3000, 5000],
    },
};
