import { formatDate, getFileNameFromUrl } from 'utils/formatting';
import type {
    CustomShipmentDocument,
    CustomShipmentRead,
} from '../actions/customShipment/customShipment.type';

export const mapCustomShipmentsFromApi = (cs: CustomShipmentRead) => ({
    id: cs?.wzServiceCode,
    receiverName: cs?.receiver?.name,
    stabloOrderIds: cs?.stabloOrderIds,
    createdAt: formatDate(cs?.createdAt),
    pickUpDate: formatDate(cs?.pickUpDate),
    expectedDeliveryDate: formatDate(cs?.expectedDeliveryDate),
    carrierCode: cs?.carrierCode,
    trackingNumber: cs?.trackingNumber,
    status: cs?.status,
    platform: cs?.platform,
    receiver: {
        company: cs?.receiver?.company,
        name: cs?.receiver?.name,
        postalCode: cs?.receiver?.postalCode,
        city: cs?.receiver?.city,
        streetLine: cs?.receiver?.streetLine,
        provinceCode: cs?.receiver?.provinceCode,
        countryCode: cs?.receiver?.countryCode,
        outsideEu: cs?.receiver?.outsideEu,
        email: cs?.receiver?.email,
        phone: cs?.receiver?.phone,
    },
    wzServiceCreateDate: formatDate(cs?.wzServiceCreateDate),
    wzServiceName: cs?.wzServiceName,
    wzServiceCode: cs?.wzServiceCode,
});

export const mapCustomShipmentDocumentsFromApi = (
    doc: CustomShipmentDocument,
) => ({
    id: doc.id,
    path: doc.path,
    fileName: getFileNameFromUrl(doc.path),
    createdAt: doc.createdAt,
    updatedAt: doc.updatedAt,
});
