import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../Cell/cell';
import { ChevronUpIcon as ExpandLessIcon, ChevronDownIcon as ExpandMoreIcon } from 'components/Icons/Icons';
import { IconButtonStyled } from 'components/OldTable/ExtendedCell/extendedCell.styles';

class ExtendedCell extends React.PureComponent {
    render() {
        const { isExtended, small, onClick, disabled, show } = this.props;

        return (
            <Cell
                actions
                small={ small }
                isExtended={ isExtended }
                onClick={ onClick }
            >
                { show && (
                    <IconButtonStyled
                        data-id="expandIcon"
                        disabled={ disabled }
                        size="large"
                    >
                        { isExtended && !disabled ?
                            <ExpandLessIcon />
                            :
                            <ExpandMoreIcon />
                        }
                    </IconButtonStyled>
                ) }
            </Cell>
        );
    }
}

ExtendedCell.propTypes = {
    disabled: PropTypes.bool,
    isExtended: PropTypes.bool,
    show: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ExtendedCell;
