import Orders from './actions/Orders';
import TranslationPatterns from './actions/TranslationPatterns';

export class SearchService {
    private constructor({ services, errorHandlersConfig, CONSTANTS }) {
        this.ajaxService = services.ajax;
        this.alert = services.alert;
        this.parser = services.parser;
        this.userService = services.userService;
        this.translator = services.translator;
        this.errorHandlers = errorHandlersConfig(services.alert);
        this.constants = CONSTANTS;

        this.orders = new Orders(this);
        this.translationPatterns = new TranslationPatterns(this);

        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    private static instance: SearchService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        CONSTANTS,
    }): SearchService {
        if (!SearchService.instance) {
            SearchService.instance = new SearchService({
                services,
                errorHandlersConfig,
                CONSTANTS,
            });
        }

        return SearchService.instance;
    }

    paramsSerializer = (params) => this.parser.stringify(params);
}
