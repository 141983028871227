import get from 'lodash/get';
import parser from 'qs';

import { ApplicationJsonHeaders } from 'services/constants';

import { mapBoxesFromApi, mapBoxToApi } from 'services/armApi/mappers/boxes';
import { configError } from 'services/utils/utils';

import type { ArmApiService } from '../ArmApiService';

import type {
    IArmApiServiceConst,
    IArmApiServiceServices,
} from '../armApi.type';

class Boxes {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly DEFAULTS: IArmApiServiceConst['DEFAULTS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS, DEFAULTS }: Pick<IArmApiServiceConst, 'URLS' | 'DEFAULTS'>,
    ) {
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.downloadBoxImage = this.downloadBoxImage.bind(this);
        this.getBoxImageThumbnail = this.getBoxImageThumbnail.bind(this);
        this.deleteBox = this.deleteBox.bind(this);
    }

    private paramsSerializer = (params: unknown) => parser.stringify(params);

    async getList({
        filters = {},
        per_page = this.DEFAULTS.BOX_GROUPS_PER_PAGE,
        page = 1,
    }): Promise<{ items: unknown | never[]; totalItems: number }> {
        const url = this.URLS.GET_BOXES;
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;
        const parsedParams = {
            per_page,
            page,
            filters,
        };
        const config = {
            params: parsedParams,
            headers: ApplicationJsonHeaders,
            paramsSerializer: this.paramsSerializer,
        };
        const errorConfig = configError('get', errorHandlers);

        try {
            const response = await ajax.get({ url, config, errorConfig });
            const items = get(response, 'data.results', []);
            const totalItems = get(response, 'data.total', 0);

            return {
                items: mapBoxesFromApi(items),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async getById(id: string): Promise<{
        id: unknown;
        barcode: unknown;
        images: unknown;
        producer: unknown;
        description: unknown;
        boxGroup: unknown;
    }> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_BOX.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('get', errorHandlers);

        const { data } = await ajax.get({ url, config, errorConfig });

        return mapBoxesFromApi(data);
    }

    async create(data: unknown): Promise<{
        id: unknown;
        barcode: unknown;
        images: unknown;
        producer: unknown;
        description: unknown;
        boxGroup: unknown;
    }> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_BOX;
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('post', errorHandlers);

        const { data: data_2 } = await ajax.post({
            url,
            data: mapBoxToApi(data),
            errorConfig,
            config,
        });

        return mapBoxesFromApi(data_2);
    }

    async edit(
        id: string,
        data: unknown,
    ): Promise<{
        id: unknown;
        barcode: unknown;
        images: unknown;
        producer: unknown;
        description: unknown;
        boxGroup: unknown;
    }> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.PUT_BOX.replace('{id}', id);
        const errorConfig = configError('put', errorHandlers);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data: data_2 } = await ajax.put({
            url,
            data: mapBoxToApi(data),
            errorConfig,
            config,
        });

        return mapBoxesFromApi(data_2);
    }

    async downloadBoxImage({
        id,
        fileName,
    }: {
        id: string;
        fileName: string;
    }): Promise<unknown> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DOWNLOAD_BOX_IMAGE.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('download', errorHandlers);

        try {
            return await ajax.download({ url, config, errorConfig, fileName });
        } catch (error) {
            return error;
        }
    }

    async getBoxImageThumbnail({ id }: { id: string }): Promise<unknown> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DOWNLOAD_BOX_IMAGE.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
            responseType: 'blob',
        };
        const errorConfig = configError('get', errorHandlers);

        try {
            return await ajax.get({ url, config, errorConfig });
        } catch (error) {
            return error;
        }
    }

    async deleteBox(id: string): Promise<void> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DELETE_BOX.replace('{id}', id);

        const errorConfig = configError('delete', errorHandlers);

        await ajax.delete({ url, errorConfig });
    }
}

export default Boxes;
