import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

/*
    PRODUCTS
 */

export const ROLE_PRODUCTS_PRODUCT_READ = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'display',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS]: ACCEPT,
        [A.POST_SALES.RESOURCES.PRODUCTS.FETCH]: ACCEPT,
    },
};

export const ROLE_PRODUCTS_PRODUCT_EDIT = {
    section: S.ARM_PRODUCT_SET_TYPES,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.PRODUCT_SET_TYPE.DELETE]: ACCEPT,
        [A.ARM.RESOURCES.PRODUCT_SET_TYPE.CREATE]: ACCEPT,
        [A.ARM.RESOURCES.PRODUCT_SET_TYPE.EDIT]: ACCEPT,
    },
};
