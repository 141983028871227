import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import TextField from 'components/TextField/TextField';
import { removeActiveToken, removeRefreshToken } from 'utils/tokenHelpers';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from 'components/IconButton/iconButton';
import EntryPageTitle from 'modules/app/components/EntryPageTitle/EntryPageTitle';
import {
    VisibilityOffIcon as VisibilityOff,
    VisibilityOnIcon as Visibility,
} from 'components/Icons/Icons';
import { passwordFieldRestrictions } from 'utils/regex';
import { ChangePasswordButtonStyled } from 'modules/app/components/ChangePasswordForm/changePasswordForm.styles';

class ChangePasswordForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repeatPassword: '',
            passwordError: '',
        };
    }

    componentDidMount() {
        removeActiveToken();
        removeRefreshToken();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    handleToggleShowPassword = () => {
        const { showPassword } = this.state;

        this.setState({
            showPassword: !showPassword,
        });
    };

    validateForm = () => {
        const { password, repeatPassword } = this.state;

        if (password !== repeatPassword) {
            this.setState({
                passwordError: 'needSamePasswords',
            });
        } else if (password.startsWith(' ') || password.endsWith(' ')) {
            this.setState({
                passwordError: 'noBlankSpace',
            });
        } else if (!passwordFieldRestrictions.test(password)) {
            this.setState({
                passwordError: 'needStrongPasswords',
            });
        } else {
            return true;
        }
    };

    handleChangePassword = (e) => {
        const search = this.props.location.search.slice(1) || '';
        const { email, token } = qs.parse(search);

        e.preventDefault();
        this.setState({
            passwordError: '',
        });

        if (this.validateForm()) {
            this.props.changePassword({
                token: token,
                email: email,
                password: this.state.password,
            });
        }
    };

    renderInput = ({ field, ...other }) => {
        const { t } = this.props;
        const { passwordError, showPassword } = this.state;
        const InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        onClick={this.handleToggleShowPassword}
                        size="large"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            ),
        };

        return (
            <TextField
                fullWidth
                name={field}
                error={!!passwordError}
                helperText={
                    passwordError ? t(`pdp:validation.${passwordError}`) : ' '
                }
                type={showPassword ? 'text' : 'password'}
                value={this.state[field]}
                onChange={this.handleChange}
                InputProps={InputProps}
                {...other}
            />
        );
    };

    render() {
        const { t, error } = this.props;
        const Input = this.renderInput;

        return (
            <div>
                <EntryPageTitle
                    error={Boolean(error)}
                    title={t('titles.passwordChange')}
                    subtitle={
                        error
                            ? t(`validation.changePassword${error}`)
                            : t('contents.enterNewPassword')
                    }
                />
                <form onSubmit={this.handleChangePassword}>
                    <Input
                        field="password"
                        label={t('common:glossary.newPassword')}
                    />
                    <Input
                        name="repeatPassword"
                        label={t('common:glossary.repeatNewPassword')}
                    />
                    <ChangePasswordButtonStyled
                        fullWidth
                        color="primary"
                        type="submit"
                    >
                        {t('actions.confirmNewPassword')}
                    </ChangePasswordButtonStyled>
                </form>
            </div>
        );
    }
}

ChangePasswordForm.defaultProps = {
    t: (key) => key,
};

ChangePasswordForm.propTypes = {
    t: PropTypes.func,
    error: PropTypes.number,
    changePassword: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

export default ChangePasswordForm;
