import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import properties from './properties/reducer';
import propertiesSaga from './properties/saga';
import * as propertiesActions from './properties/actions';

import intermediateProducts from './intermediateProducts/reducer';
import intermediateProductSaga from './intermediateProducts/saga';
import * as intermediateProductsActions from './intermediateProducts/actions';

import products from './products/reducer';
import productsSaga from './products/saga';
import * as productsActions from './products/actions';

export const armReducers = combineReducers({
    properties,
    intermediateProducts,
    products,
});

export const armSagas = [
    fork(propertiesSaga),
    fork(intermediateProductSaga),
    fork(productsSaga),
];

export const armActions = [
    propertiesActions,
    intermediateProductsActions,
    productsActions,
];
