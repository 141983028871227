import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { createGenerateClassName } from '@material-ui/core';

const blueDark = '#293A65';
const grayDark = '#333';
const gray = '#7D828D';
const grayMidLight = '#BDBDBD';

export const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: 'mui-jss',
});

export default createTheme(adaptV4Theme({
    // Remove after theme migrated and no MUI 4 dependencies (including x-grid)
    overrides: {
        MuiTooltip: {
            tooltip: {
                lineHeight: '1.4em',
                fontSize: '0.625rem'
            },
        },
    },
    // End remove
    // Uncomment after theme migrated and no MUI 4 dependencies (including x-grid)
    /*components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    lineHeight: '1.4em',
                    fontSize: '0.625rem'
                }
            }
        }
    },*/
    palette: {
        primary: {
            main: '#3974C0',
            dark: '#2E5D9A',
            light: '#C5D4E8',
            lighter: '#EEF2F8',
            contrastText: '#fff',
        },
        secondary: {
            main: '#3EBE9B',
            dark: '#27AB87',
            light: '#98C2B7',
            contrastText: '#fff',
        },
        error: {
            main: '#DB3C26',
            dark: '#BF3825',
            light: '#E9A79E',
            contrastText: '#fff',
        },
        warning: {
            main: '#FFB800',
            contrastText: '#fff',
        },
        info: {
            main: '#3974C0',
            contrastText: '#fff',
        },
        default: {
            main: '#e0e0e0',
            dark: '#d5d5d5'
        },
        success: {
            main: '#3EBE9B',
            contrastText: '#fff',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
        common: {
            black: '#222',
            gray: {
                dark: '#333333',
                main: gray,
                mid: '#9B9B9B',
                midLight: '#BDBDBD',
                light: '#E5E5E5',
                lighten1: '#F9F9F9'
            },
        },
        text: {
            blueDark,
            grayDark,
            gray,
            grayMidLight,
            primary: grayDark,
            secondary: blueDark,
            warning: '#FF8500',
            disabled: 'rgba(0, 0, 0, 0.26)',
        },
        background: {
            darkMain: '#293A65',
            altMain: '#416A90',
            navBar: '#fff',
            default: '#fafafa',
            entryPage: '#334fa7'
        },
        header: {
            local: 'linear-gradient(to right, #222, #3EBE9B)',
            test: 'linear-gradient(to right, #222, #DB3C26)',
            stage: 'linear-gradient(to right, #222, #3974C0)',
            main: '#222',
        },
        status: {
            canceled: '#979797',
            delivered: '#6bae21',
            error: '#d41a1a',
            open: '#3974c0',
            pending: '#20497c',
            podDownloaded: '#3f7206',
            completed: '#3f7206',
            pickedUp: '#f8e71c',
            exported: '#f89c1c',
            succeeded_with_warning: '#ff6600',
        },
    },
    typography: {
        fontFamily: '"Nunito", sans-serif',
        useNextVariants: true,
        fontWeightBold: 600,
        fontWeightNormal: 'normal',
        h1: {
            fontWeight: 600,
            lineHeight: '2rem',
            fontSize: '1.375rem',
            color: blueDark,
        },
        h2: {
            fontWeight: 600,
            lineHeight: '1.75rem',
            fontSize: '1.125rem',
            color: blueDark,
        },
        h3: {
            fontWeight: 600,
            lineHeight: '1.5rem',
            fontSize: '1rem',
            color: blueDark,
        },
        h4: {
            fontWeight: 600,
            lineHeight: '1.375rem',
            fontSize: '0.875rem',
            color: blueDark,
        },
        h5: {
            fontWeight: 600,
            lineHeight: '1.25rem',
            fontSize: '0.8125rem',
            color: blueDark,
        },
        h6: {
            fontWeight: 600,
            lineHeight: '1.125rem',
            fontSize: '0.75rem',
            color: blueDark,
        },
        body1: {
            fontWeight: 400,
            lineHeight: '1.5rem',
            fontSize: '0.8125rem',
            color: grayDark,
        },
    },
}));
