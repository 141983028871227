import i18next from 'i18next';
import { handleForbiddenError } from 'utils/errors';

const base =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        handleForbiddenError(error, response, alertService);

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });

            throw error;
        }

        alertService.addError({
            message: i18next.t('app:alerts.unknown'),
        });

        throw error;
    };

const errorHandlers = (alertService) => ({
    get: base(alertService),
});

export default errorHandlers;
