import React from 'react';
import PropTypes from 'prop-types';
import {
    ChevronUpIcon,
    ChevronDownIcon,
    SortIcon as SwapVert,
} from 'components/Icons/Icons';
import { WrapperStyled } from 'components/OldTable/SortArrows/sortArrows.styles';

class SortArrows extends React.PureComponent {
    render() {
        const { upActive, downActive } = this.props;

        return (
            <WrapperStyled>
                { upActive && <ChevronUpIcon /> }
                { downActive && <ChevronDownIcon /> }
                { !upActive && !downActive && (
                    <SwapVert
                        color="disabled"
                    />
                ) }
            </WrapperStyled>
        );
    }
}

SortArrows.propTypes = {
    upActive: PropTypes.bool,
    downActive: PropTypes.bool,
};

export default SortArrows;
