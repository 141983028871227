import React from 'react';
import Cell from '../Cell/cell';
import PropTypes from 'prop-types';
import { CheckboxStyled } from 'components/OldTable/CheckBoxCell/checkboxCell.styles';

const CheckBoxCell = ({ checked, indeterminate, onClick, isExtended, small, disabled }) => (
    <Cell
        flex
        small={ small }
        isExtended={ isExtended }
    >
        <CheckboxStyled
            color="primary"
            checked={ checked || false }
            disabled={ disabled }
            indeterminate={ indeterminate }
            onClick={ onClick }
        />
    </Cell>
);

CheckBoxCell.propTypes = {
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isExtended: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default CheckBoxCell;
