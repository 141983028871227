import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function TaskIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 0 16 16"
            { ...props }
        >
            <path
                d="M5.07998 0.319946C4.91287 0.319946 4.74251 0.381172 4.62186 0.501821C4.50121 0.622471 4.43998 0.792835 4.43998 0.959946H1.87998C1.3507 0.959946 0.919983 1.39067 0.919983 1.91995V14.0799C0.919983 14.6092 1.3507 15.0399 1.87998 15.0399H12.12C12.5641 15.0399 12.939 14.7371 13.0481 14.3268C13.0689 14.2481 13.08 14.1651 13.08 14.0799V1.91995C13.08 1.39067 12.6493 0.959946 12.12 0.959946H9.55998C9.55998 0.792835 9.49876 0.622471 9.37811 0.501821C9.25746 0.381172 9.08709 0.319946 8.91998 0.319946H5.07998ZM5.07998 0.959946H8.91998V2.87995H5.07998V0.959946ZM1.87998 1.59995H4.43998V2.23995H2.19998V13.7599H11.8V2.23995H9.55998V1.59995H12.12C12.2963 1.59995 12.44 1.74331 12.44 1.91995V14.0799C12.44 14.2566 12.2963 14.3999 12.12 14.3999H1.87998C1.70366 14.3999 1.55998 14.2566 1.55998 14.0799V1.91995C1.55998 1.74331 1.70366 1.59995 1.87998 1.59995ZM2.83998 2.87995H4.43998C4.43998 3.04706 4.50121 3.21742 4.62186 3.33807C4.74251 3.45872 4.91287 3.51995 5.07998 3.51995H8.91998C9.08709 3.51995 9.25746 3.45872 9.37811 3.33807C9.49876 3.21742 9.55998 3.04706 9.55998 2.87995H11.16V13.1199H2.83998V2.87995ZM8.89373 5.44057C8.80337 5.44341 8.71358 5.45897 8.62686 5.48745C8.49983 5.52891 8.38127 5.60053 8.27748 5.69682L8.27498 5.69432L8.27373 5.69245L4.54436 9.42182C4.50468 9.4615 4.47595 9.51055 4.46123 9.56495L4.13061 10.7968C4.10085 10.9072 4.13277 11.0252 4.21373 11.1062C4.27453 11.167 4.35614 11.1999 4.43998 11.1999C4.46782 11.1999 4.49591 11.1967 4.52311 11.1893L5.75498 10.8587C5.80938 10.844 5.85843 10.8153 5.89811 10.7756L9.62748 7.0462L9.62561 7.04495L9.62373 7.04307C9.73568 6.92234 9.81247 6.78075 9.85061 6.63057C9.89861 6.44113 9.88787 6.2402 9.81811 6.0562C9.77171 5.93364 9.69917 5.8182 9.60061 5.71932C9.47517 5.59388 9.32311 5.51091 9.16311 5.46995C9.07495 5.44739 8.98409 5.43773 8.89373 5.44057ZM8.53811 6.33307L8.98686 6.78182L5.50623 10.2624L4.89248 10.4274L5.05748 9.8137L8.53811 6.33307Z"
            />
        </SvgIcon>
    );
}

TaskIcon.defaultProps = {
    titleAccess: 'task-icon',
};

TaskIcon.propTypes = svgIconPropTypes;
