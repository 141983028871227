import { connect } from 'react-redux';
import {
    fetchProductCatalogs,
} from 'modules/stablo/reducers/productCatalogs/actions';
import { ProductCatalogSelect } from 'modules/stablo/components/ProductCatalogSelect/ProductCatalogSelect';
import {
    selectLoader,
    selectProductCatalogs,
} from 'modules/stablo/reducers/productCatalogs/selectors';

const mapStateToProps = (state) => {
    return {
        productCatalogs: selectProductCatalogs(state),
        loading: selectLoader(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProductCatalogs: (data) => dispatch(fetchProductCatalogs.request(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogSelect);
