export const UPLOAD_FILE_STATUS = {
    UPLOADING: 'uploading',
    PAUSED: 'paused',
    ERROR: 'error',
    SUCCESS: 'success',
};

const generateFileId = (file) => {
    const timestamp = new Date().getTime();
    const fileName = file.name;
    const fileType = file.type;

    function b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }

    return b64EncodeUnicode(`${ fileName }${ fileType }${ timestamp }`);
};

class FileUploadHandler {
    constructor(file, metadata, filesUploadService, customTUSUploadUrl = '') {
        this.fileId = generateFileId(file);
        this.uppyId = '';
        this.uploadId = '';
        this.uploadURL = '';
        this.file = file;
        this.metadata = metadata;
        this.filesUploadService = filesUploadService;
        this.progressListeners = [];
        this.errorListeners = [];
        this.successListeners = [];
        this.state = {
            progress: 0,
            status: UPLOAD_FILE_STATUS.UPLOADING,
        };
        this.customTUSUploadUrl = customTUSUploadUrl;
    }
    getFileId() {
        return this.fileId;
    }
    getUppyId() {
        return this.uppyId;
    }
    getUploadId() {
        return this.uploadId;
    }
    getUploadURL() {
        return this.uploadURL;
    }
    getFileName() {
        return this.file.name;
    }
    getMetadata() {
        return this.metadata;
    }
    getUploadPayload() {
        return {
            name: this.file.name,
            type: this.file.type,
            data: this.file,
            source: 'Local',
            isRemote: false,
        };
    }
    getProgress() {
        return this.state.progress;
    }
    getStatus() {
        return this.state.status;
    }
    onProgressChange(listener) {
        if (this.progressListeners.indexOf(listener) < 0) {
            this.progressListeners.push(listener);
        }
    }
    onError(listener) {
        if (this.errorListeners.indexOf(listener) < 0) {
            this.errorListeners.push(listener);
        }
    }
    onSuccess(listener) {
        if (this.successListeners.indexOf(listener) < 0) {
            this.successListeners.push(listener);
        }
    }
    removeListener(type, listener) {
        if (
            this[`${type}Listeners`] &&
            this[`${type}Listeners`].indexOf(listener) < 0
        ) {
            const index = this[`${type}Listeners`].indexOf(listener);

            this[`${type}Listeners`].slice(index, 1);
        }
    }
    setUppyId(id) {
        this.uppyId = id;
    }
    setProgress(progress) {
        this.state = {
            progress,
            status: UPLOAD_FILE_STATUS.UPLOADING,
        };
        this.progressListeners.forEach((listener) => listener(this.state));
    }
    setError(errors, response) {
        this.state.status = UPLOAD_FILE_STATUS.ERROR;
        this.errorListeners.forEach((listener) => listener(errors, response));
    }
    setSuccess(response) {
        this.state = {
            progress: 100,
            status: UPLOAD_FILE_STATUS.SUCCESS,
        };
        this.uploadURL = response.uploadURL;

        const urlStaged = response.uploadURL.split('/');

        this.uploadId = urlStaged[urlStaged.length - 1];
        this.successListeners.forEach((listener) =>
            listener(this.state, response),
        );
    }
    upload() {
        this.state.status = UPLOAD_FILE_STATUS.UPLOADING;

        return this.filesUploadService.uploadFile(this.fileId);
    }
    pause() {
        this.state.status = UPLOAD_FILE_STATUS.PAUSED;

        return this.filesUploadService.pauseResume(this.fileId);
    }
    retry() {
        this.state.status = UPLOAD_FILE_STATUS.UPLOADING;

        return this.filesUploadService.retryUpload(this.fileId);
    }
}

export default FileUploadHandler;
