import { ApplicationJsonHeaders } from 'services/constants';
import { configError } from 'services/utils/utils';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';

import type { ArmApiService } from '../ArmApiService';

import type {
    IArmApiServiceConst,
    IArmApiServiceServices,
} from '../armApi.type';

export class Accessories {
    private readonly asyncRequestPaginator: AsyncRequestPaginator<
        unknown,
        unknown
    >;
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getMore = this.getMore.bind(this);
        this.getList = this.getList.bind(this);
        this.createAccessory = this.createAccessory.bind(this);
        this.getAccessoryById = this.getAccessoryById.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMore,
            (data) => data,
        );
    }

    async getMore(
        params: { page: number; per_page?: number | undefined } | undefined,
    ) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_ACCESSORIES;

        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page: params?.page,
                per_page: params?.per_page ?? 10,
            },
        };

        const errorConfig = configError('get', errorHandlers);

        return await ajax.get({ url, config, errorConfig });
    }

    async getList(params = {}) {
        const { items } =
            await this.asyncRequestPaginator.fetchFullList(params);

        return items;
    }

    async createAccessory(name: string): Promise<unknown> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.CREATE_ACCESSORY;

        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('post', errorHandlers);

        const { data } = await ajax.post({
            url,
            data: { name },
            config,
            errorConfig,
            onError: undefined,
        });

        return data;
    }

    async getAccessoryById(id: string): Promise<unknown> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_ACCESSORY_BY_ID.replace('{id}', id);

        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('get', errorHandlers);

        const { data } = await ajax.get({
            url,
            config,
            errorConfig,
            onError: undefined,
        });

        return data;
    }
}
