export const mapTranslationPatternFromApi = ({
    channelId = null,
    titlesCount,
    createdAt,
    isCompleteCategories,
    isCompleteColors,
    isCompleteTags,
    isCompleteTitles,
    isCompleteTranslations,
    patternCode = '',
    technologyName,
    technologyGroupName,
    languagesCount
} = {}) => ({
    id: patternCode,
    patternCode,
    channelId,
    technologyName: technologyName,
    technologyGroupName: technologyGroupName,
    isCompleteTranslations,
    isCompleteTitles,
    isCompleteCategories,
    isCompleteTags,
    isCompleteColors,
    completedTitlesCount: titlesCount,
    translationGroupsCount: languagesCount,
    createdAt,
});
