import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function BlockIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <path
                d="M7.00004 0.333252C3.32406 0.333252 0.333374 3.32394 0.333374 6.99992C0.333374 10.6759 3.32406 13.6666 7.00004 13.6666C10.676 13.6666 13.6667 10.6759 13.6667 6.99992C13.6667 3.32394 10.676 0.333252 7.00004 0.333252ZM7.00004 1.33325C8.38669 1.33325 9.63819 1.84774 10.6218 2.67114L2.67126 10.621C1.84806 9.63747 1.33337 8.38639 1.33337 6.99992C1.33337 3.86438 3.8645 1.33325 7.00004 1.33325ZM11.3288 3.37817C12.1522 4.36177 12.6667 5.61327 12.6667 6.99992C12.6667 10.1355 10.1356 12.6666 7.00004 12.6666C5.61357 12.6666 4.36249 12.1519 3.37895 11.3287L11.3288 3.37817Z"
            />
        </SvgIcon>
    );
}

BlockIcon.defaultProps = {
    titleAccess: 'block-icon',
};

BlockIcon.propTypes = svgIconPropTypes;
