import i18next from 'i18next';

export const getErrorMessage = ({ error } = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;

    if (response.status === 403) {
        return i18next.t('alerts.accessDenied', { ns: 'app' });
    }

    if (data['hydra:description']) {
        return data['hydra:description'];
    }

    if (data.detail) {
        return data.detail;
    }

    if (status) {
        return i18next.t('common:errors.unknown', { status });
    }

    return i18next.t('app:alerts.unknown');
};

export const handleForbiddenError = (error, response, alertService) => {
    if (response.status === 403) {
        alertService.addError({
            message: i18next.t('alerts.accessDenied', { ns: 'app' }),
        });
        throw error;
    }
};
