import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ArrowDoubleIcon(props) {
    return (
        <SvgIcon
            viewBox="-3 -1 16 16"
            { ...props }
        >
            <path
                d="M1.27321 0.992188C1.15609 0.992188 1.05234 1.06246 1.00773 1.16955C0.964221 1.27775 0.989877 1.40046 1.07354 1.48189L6.58294 6.99128L1.07354 12.5007C0.998801 12.5721 0.968683 12.6792 0.995455 12.7784C1.02111 12.8788 1.0992 12.9569 1.19959 12.9826C1.29887 13.0093 1.40595 12.9792 1.47735 12.9045L7.39055 6.99128L1.47735 1.07808C1.4238 1.02231 1.35018 0.992188 1.27321 0.992188ZM4.98444 0.992188C4.86843 0.992188 4.76469 1.06246 4.72007 1.16955C4.67657 1.27775 4.70222 1.40046 4.78588 1.48189L10.2953 6.99128L4.78588 12.5007C4.71115 12.5721 4.68103 12.6792 4.7078 12.7784C4.73346 12.8788 4.81154 12.9569 4.91193 12.9826C5.01121 13.0093 5.1183 12.9792 5.18969 12.9045L11.1029 6.99128L5.18969 1.07808C5.13615 1.02231 5.06253 0.992188 4.98444 0.992188Z"
            />
        </SvgIcon>
    );
}

ArrowDoubleIcon.defaultProps = {
    titleAccess: 'arrow-double-icon',
};

ArrowDoubleIcon.propTypes = svgIconPropTypes;
