import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    toggleNavBar,
    toggleMobileNavBar,
} from 'modules/app/reducers/common/actions';
import {
    getIsNavigationBarExtended,
} from 'modules/app/reducers/common/selectors';
import Header from 'modules/app/components/Header/Header';
import { logoutUser } from '../../reducers/userProfile/actions';
import { getPath } from 'utils/router/selectors';

const mapStateToProps = (state) => {
    return {
        extendedNavBar: getIsNavigationBarExtended(state),
        path: getPath(state).split('/'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavBar: () => dispatch(toggleNavBar()),
        toggleMobileNavBar: () => dispatch(toggleMobileNavBar()),
        push: (data) => dispatch(push(data)),
        logout: (data) => dispatch(logoutUser(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
