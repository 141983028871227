export const mapBoxGroupsFromApi = (items = []) => {
    let treeDataItems = [];

    items.forEach(({ boxes = [], code, id: parentId, width, height, depth, internal_box_count: internalBoxCount, shipping_box_count: shippingBoxCount, total_box_count: totalBoxCount }) => {
        treeDataItems.push({
            path: [code],
            id: parentId,
            code,
            width,
            height,
            depth,
            internalBoxCount,
            shippingBoxCount,
            totalBoxCount,
            barcode: null,
            producerName: null,
            isParent: true
        });

        boxes.forEach(({ barcode, id, producer }, boxIndex) => {
            const path = `${code}${[...Array(boxIndex).keys()].reduce((acc) => acc + ' ', '')}`;
            const { name: producerName } = producer;

            treeDataItems.push({
                path: [code, path],
                id,
                code,
                barcode,
                producerName,
                isParent: false,
                parentId: parentId,
            });
        });
    });

    return treeDataItems;
};

export const mapBoxGroupAutocompleteFromApi = (items = []) =>
    items.map(({ id, code }) => ({
        id,
        code,
    }));

export const mapBoxGroupFromApi = (data) => {
    const {
        id,
        boxes = [],
        code = '',
        width = 0,
        height = 0,
        depth = 0,
        shipping_box_count: shippingBoxCount = 0,
        internal_box_count: internalBoxCount = 0,
        total_box_count: totalBoxCount = 0
    } = data;

    return ({
        id,
        boxes,
        code,
        width,
        height,
        depth,
        shippingBoxCount,
        internalBoxCount,
        totalBoxCount
    });
};

export const mapBoxGroupToApi = (data) => data;

export const mapIntermediateProductsBoxesToApi = (intermediateProductIds = []) => ({
    'intermediate_products': intermediateProductIds.map((id) => ({ id })),
});

export const mapBoxRulesBoxesToApi = (boxRulesIds = []) => ({
    'box_rules': boxRulesIds.map((id) => ({ id })),
});
