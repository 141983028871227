export const delay = (ms = Math.random() * 4000 + 1000) => {
    return new Promise((resolve) => setTimeout(() => resolve(true), ms));
};

export const getErrorsFromResponse = (response = {}) => {
    const Errors = {};

    if (response.violations) {
        const createViolations = response.violations;

        createViolations.map((error = {}) => {
            Errors[error.propertyPath] = error.message;
        });
    }

    return Errors;
};

export const getErrorCodeFromPayload = (payload = {}) => {
    if (payload.response && payload.response.status) {
        return payload.response.status;
    } else if (payload.error) {
        return 500;
    }

    return null;
};
