import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/TextField/TextField';

export default class Select extends React.PureComponent {
    render() {
        const { name, inputProps, ...other } = this.props;

        return (
            <TextField
                select
                name={ name }
                inputProps={{
                    id: name,
                    ...inputProps,
                }}
                { ...other }
            />
        );
    }
}
Select.defaultProps = {
    inputProps: {},
    name: '',
};

Select.propTypes = {
    inputProps: PropTypes.object,
    name: PropTypes.string,
    ...TextField.propTypes,
};
