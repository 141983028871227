import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import LinkWithRef from 'components/LinkWithRef/LinkWithRef';
import {
    ClearIcon as Clear,
    ImageIcon as CropOriginal,
    VisibilityOnIcon as RemoveRedEyeOutlined,
    GearIcon as SettingsOutlined,
    HistoryIcon as History,
    PrinterIcon as PrintOutlined,
    EnvelopeIcon as EmailOutlined,
    DownloadIcon as GetAppOutlined,
    CopyIcon as FileCopyOutlined,
    PencilIcon as EditOutlined,
    TrashIcon as DeleteOutline,
    CheckSIcon as Done,
    UploadIcon as BackupOutlined,
} from 'components/Icons/Icons';
import { IconButtonStyled, WrapperStyled } from 'components/OldTable/ActionCell/actionCell.styles';

class ActionCell extends React.PureComponent {
    downloadFile = (e) => {
        const { handleDownload } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        handleDownload();
    };

    printFile = (e) => {
        const { handlePrint } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        handlePrint();
    };

    send = (e) => {
        const { handleSend } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        handleSend();
    };

    removeItem = (e) => {
        const { handleRemove, canRemoveItem } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        if (canRemoveItem) handleRemove();
    };

    showListView = (e) => {
        const { listViewDisabled, item } = this.props;

        e && e.stopPropagation && e.stopPropagation();

        return (item ? !listViewDisabled : listViewDisabled);
    };

    pickEdit = (e) => {
        const { pickEdit } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        pickEdit();
    };

    cancelEdit = (e) => {
        const { cancelEdit } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        cancelEdit();
    };

    submitEdit = (e) => {
        const { submitEdit } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        submitEdit();
    };

    renderListViewButton() {
        const {
            listViewLink,
            showListView,
        } = this.props;
        const buttonSize = 'small';

        return listViewLink ? (
            <IconButtonStyled
                size={ buttonSize }
                name="listView"
                disabled={ this.showListView() }
                component={ LinkWithRef }
                to={ listViewLink }
            >
                <CropOriginal fontSize="inherit" />
            </IconButtonStyled>
        ) : (
            <IconButtonStyled
                size={ buttonSize }
                name="listView"
                disabled={ this.showListView() }
                onClick={ showListView }
            >
                <CropOriginal fontSize="inherit" />
            </IconButtonStyled>
        );
    }

    renderActionButtons() {
        const {
            t,
            detailsLink,
            copyLink,
            handleDownload,
            showListView,
            showDetails,
            showHistory,
            uploadFiles,
            customActionButton,
            secondaryCustomActionButton,
            handlePrint,
            handleSend,
            handleRemove,
            canRemoveItem,
            pickEdit,
            editLink,
        } = this.props;
        const actions = [];
        const buttonSize = 'small';
        const T_ITEM = t('common:glossary.item');

        if (detailsLink) {
            actions.push(
                <Tooltip
                    key="details"
                    title={ t('common:glossary.details') }
                >
                    <IconButtonStyled
                        size={ buttonSize }
                        name="details"
                        component={ LinkWithRef }
                        to={ detailsLink }
                    >
                        <SettingsOutlined fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (showDetails) {
            actions.push(
                <Tooltip
                    key="showDetails"
                    title={ t('common:glossary.showDetails') }
                >
                    <IconButtonStyled
                        size={ buttonSize }
                        name="showDetails"
                        onClick={ showDetails }
                    >
                        <RemoveRedEyeOutlined fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (showHistory) {
            actions.push(
                <Tooltip
                    key="showHistory"
                    title={ t('common:glossary.showHistory') }
                >
                    <IconButtonStyled
                        size={ buttonSize }
                        name="showHistory"
                        onClick={ showHistory }
                    >
                        <History fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (uploadFiles) {
            actions.push(
                <Tooltip
                    key="uploadFiles"
                    title={ t('common:glossary.uploadFiles') }
                >
                    <div>
                        <IconButtonStyled
                            size={ buttonSize }
                            name="uploadFiles"
                            onClick={ uploadFiles.function }
                            disabled={ uploadFiles.disabled }
                        >
                            <BackupOutlined fontSize="inherit" />
                        </IconButtonStyled>
                    </div>
                </Tooltip>
            );
        }

        if (editLink) {
            actions.push(
                <Tooltip
                    key="edit"
                    title={ t('common:actions.edit') }
                >
                    <IconButtonStyled
                        size={ buttonSize }
                        name="edit"
                        component={ LinkWithRef }
                        to={ editLink }
                    >
                        <EditOutlined fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (pickEdit) {
            actions.push(
                <Tooltip
                    key="editRow"
                    title={ t('common:actions.edit') }
                >
                    <IconButtonStyled
                        size={ buttonSize }
                        name="editRow"
                        onClick={ this.pickEdit }
                    >
                        <EditOutlined fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (copyLink) {
            actions.push(
                <Tooltip
                    key="copy"
                    title={ t('common:actions.copy') }
                >
                    <IconButtonStyled
                        name="copy"
                        component={ LinkWithRef }
                        to={ copyLink }
                        size="large"
                    >
                        <FileCopyOutlined fontSize="inherit" />
                    </IconButtonStyled>
                </Tooltip>
            );
        }

        if (handleDownload) {
            actions.push(
                <Tooltip
                    key="download"
                    title={ `${t('common:actions.download')} ${T_ITEM}` }
                >
                    <div>
                        <IconButtonStyled
                            size={ buttonSize }
                            name="download"
                            onClick={ this.downloadFile }
                        >
                            <GetAppOutlined fontSize="inherit" />
                        </IconButtonStyled>
                    </div>
                </Tooltip>
            );
        }

        if (showListView) {
            actions.push(
                <Tooltip
                    key="listView"
                    title={ `${t('common:actions.showListView')}` }
                >
                    <div>
                        {this.renderListViewButton()}
                    </div>
                </Tooltip>
            );
        }

        if (handlePrint) {
            actions.push(
                <Tooltip
                    key="print"
                    title={ `${t('common:actions.print')} ${T_ITEM}` }
                >
                    <div>
                        <IconButtonStyled
                            size={ buttonSize }
                            name="print"
                            onClick={ this.printFile }
                        >
                            <PrintOutlined fontSize="inherit" />
                        </IconButtonStyled>
                    </div>
                </Tooltip>
            );
        }

        if (handleSend) {
            actions.push(
                <Tooltip
                    key="send"
                    title={ `${t('common:actions.sendEmail')}` }
                >
                    <div>
                        <IconButtonStyled
                            size={ buttonSize }
                            name="send"
                            onClick={ this.send }
                        >
                            <EmailOutlined fontSize="inherit" />
                        </IconButtonStyled>
                    </div>
                </Tooltip>
            );
        }

        if (customActionButton) {
            const CustomButtonComponent = customActionButton.component;

            actions.push(
                <Tooltip
                    key={ customActionButton.key }
                    title={ `${t(customActionButton.title)}` }
                >
                    <div>
                        <CustomButtonComponent { ...customActionButton.props } />
                    </div>
                </Tooltip>
            );
        }

        if (secondaryCustomActionButton) {
            const CustomButtonComponent = secondaryCustomActionButton.component;

            actions.push(
                <Tooltip
                    key={ secondaryCustomActionButton.key }
                    title={ `${t(secondaryCustomActionButton.title)}` }
                >
                    <div>
                        <CustomButtonComponent { ...secondaryCustomActionButton.props } />
                    </div>
                </Tooltip>
            );
        }

        if (handleRemove) {
            actions.push(
                <Tooltip
                    key="remove"
                    title={ `${t('common:actions.remove')} ${T_ITEM}` }
                >
                    <div>
                        <IconButtonStyled
                            size={ buttonSize }
                            name="delete"
                            disabled={ !canRemoveItem }
                            onClick={ this.removeItem }
                        >
                            <DeleteOutline fontSize="inherit" />
                        </IconButtonStyled>
                    </div>
                </Tooltip>
            );
        }

        return actions;
    }

    renderEditButtons() {
        const { t } = this.props;

        return [
            <Tooltip
                key="accept"
                title={ t('common:actions.accept') }
            >
                <IconButtonStyled
                    name="editRowAccept"
                    onClick={ this.submitEdit }
                    size="large"
                >
                    <Done fontSize="inherit" />
                </IconButtonStyled>
            </Tooltip>,
            <Tooltip
                key="cancel"
                title={ t('common:actions.cancel') }
            >
                <IconButtonStyled
                    name="editRowCancel"
                    onClick={ this.cancelEdit }
                    size="large"
                >
                    <Clear fontSize="inherit" />
                </IconButtonStyled>
            </Tooltip>
        ];
    }

    render() {
        const { edit, isExtended, small } = this.props;

        return (
            <WrapperStyled
                small={ small }
                actions
                isExtended={ isExtended }
            >
                { edit ?
                    this.renderEditButtons()
                    :
                    this.renderActionButtons()
                }
            </WrapperStyled>
        );
    }
}

ActionCell.defaultProps = {
    t: (key) => key,
};

ActionCell.propTypes = {
    t: PropTypes.func,
    detailsLink: PropTypes.object,
    editLink: PropTypes.object,
    handleCopy: PropTypes.func,
    copyLink: PropTypes.object,
    handleDownload: PropTypes.func,
    handlePrint: PropTypes.func,
    handleSend: PropTypes.func,
    handleRemove: PropTypes.func,
    showListView: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
    canRemoveItem: PropTypes.bool,
    listViewDisabled: PropTypes.bool,
    pickEdit: PropTypes.func,
    edit: PropTypes.bool,
    small: PropTypes.bool,
    showHistory: PropTypes.func,
    uploadFiles: PropTypes.shape({
        function: PropTypes.func,
        disabled: PropTypes.bool,
    }),
    showDetails: PropTypes.func,
    item: PropTypes.object.isRequired,
    cancelEdit: PropTypes.func,
    submitEdit: PropTypes.func,
    customActionButton: PropTypes.object,
    secondaryCustomActionButton: PropTypes.object,
    isExtended: PropTypes.bool,
    listViewLink: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

export default ActionCell;
