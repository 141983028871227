import get from 'lodash/get';
import parser from 'qs';
import { mapPropertyFromApi } from '../mappers/property';

import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class Properties {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.services = services;
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
    }
    private paramsSerializer = (params) => parser.stringify(params);

    async getById(id) {
        const { ajax } = this.services;
        const url = this.URLS.GET_PROPERTY.replace('{id}', id);

        const response = await ajax.get({ url });

        return mapPropertyFromApi(response.data);
    }

    async getList({ filters, per_page = 50, page = 1 }) {
        const { ajax } = this.services;
        const search = get(filters, 'search[$match]', '');
        const url = this.URLS.GET_PROPERTIES;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (search) {
            const filtersParams = { filter: { name: { match: search } } };

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        const response = await ajax.get({ url, config });

        return {
            list: get(response, 'data.hydra:member', []).map((property) =>
                mapPropertyFromApi(property),
            ),
            count: get(response, 'data.hydra:totalItems', 0),
        };
    }
}

export default Properties;
