import type { NavBarModule } from './navBar.type';
import intersection from 'lodash/intersection';

const flattenAvailableModulePaths = (modules: NavBarModule[]) =>
    modules.reduce<string[]>((all, currentModule) => {
        if (currentModule.submodules && currentModule.submodules.length > 0) {
            all = all.concat(
                flattenAvailableModulePaths(currentModule.submodules),
            );
        } else {
            all.push(currentModule.name);
        }

        return all;
    }, []);

export const getTopMatchingPathName = (
    modules: NavBarModule[],
    path: string,
) => {
    /**
     * 1. Recursively get all available module and submodule paths
     * 2. Flatten into a single array of strings
     * 3. Split each path into slugs (e.g. ['arm', 'product-set]) producing array of array of strings
     */
    const modulePaths = flattenAvailableModulePaths(modules).map((modulePath) =>
        modulePath.split('/'),
    );

    /**
     * Produce an array of slugs from current path for comparison
     */
    const activeModuleSlugs = path.split('/');

    return (
        /**
         * 1. For each slug-array of all available paths, get intersections with slug-array of current path
         * 2. Get best-matched slug-array (which is the one with longest intersection) by sorting by length descending and grabbing first item
         * 3. Produce a usable pathname by concatinating slug-array with slashes
         */
        (modulePaths
            .map((modulePathArray) =>
                intersection(activeModuleSlugs, modulePathArray),
            )
            .sort((a, b) => b.length - a.length)
            .at(0)
            ?.join('/') || null)
    );
};
