import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function FolderIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -1 16 16"
            { ...props }
        >
            <path
                d="M0.960046 0.280029C0.433796 0.280029 4.60576e-05 0.713779 4.60576e-05 1.24003V13.05C-0.0199539 13.1238 -0.0124539 13.2013 0.0200461 13.27C0.110046 13.7063 0.500046 14.04 0.960046 14.04H13.44C13.9175 14.04 14.31 13.68 14.38 13.22C14.3825 13.2 14.3888 13.18 14.39 13.16C14.39 13.1538 14.39 13.1463 14.39 13.14L14.4 13.09C14.4 13.0863 14.4 13.0838 14.4 13.08L15.99 4.50003L16 4.47003V4.44003C16 3.91378 15.5663 3.48003 15.04 3.48003V2.52003C15.04 1.99378 14.6063 1.56003 14.08 1.56003H5.77005C5.7713 1.56128 5.76755 1.56003 5.76005 1.56003C5.75005 1.55253 5.7213 1.52878 5.67005 1.47003C5.5913 1.38003 5.50005 1.23003 5.40005 1.07003C5.30005 0.910029 5.19255 0.740029 5.06005 0.590029C4.92755 0.440029 4.74255 0.280029 4.48005 0.280029H0.960046ZM0.960046 0.920029H4.48005C4.46005 0.920029 4.5013 0.920029 4.58005 1.01003C4.6588 1.10003 4.75005 1.25003 4.85005 1.41003C4.95005 1.57003 5.06005 1.74003 5.19005 1.89003C5.32005 2.04003 5.49755 2.20003 5.76005 2.20003H14.08C14.26 2.20003 14.4 2.34003 14.4 2.52003V3.48003H2.56005C2.0563 3.48003 1.65505 3.88503 1.62005 4.38003H1.61005L1.60005 4.44003L0.640046 9.62003V1.24003C0.640046 1.06003 0.780046 0.920029 0.960046 0.920029ZM2.56005 4.12003H15.04C15.22 4.12003 15.36 4.26003 15.36 4.44003L13.79 12.93L13.78 12.95C13.7763 12.9625 13.7725 12.9763 13.77 12.99C13.7663 13.0025 13.7625 13.0163 13.76 13.03C13.76 13.0363 13.76 13.0438 13.76 13.05C13.76 13.0538 13.76 13.0563 13.76 13.06C13.755 13.0763 13.7525 13.0925 13.75 13.11C13.7488 13.12 13.7513 13.13 13.75 13.14C13.7488 13.1538 13.7488 13.1663 13.75 13.18C13.71 13.31 13.5863 13.4 13.44 13.4H0.960046C0.780046 13.4 0.640046 13.26 0.640046 13.08L2.23005 4.50003L2.24005 4.47003V4.44003C2.24005 4.26003 2.38005 4.12003 2.56005 4.12003Z"
            />
        </SvgIcon>
    );
}

FolderIcon.defaultProps = {
    titleAccess: 'folder-icon',
};

FolderIcon.propTypes = svgIconPropTypes;
