import * as Yup from 'yup';
import moment from 'moment';
import { ItemModel } from 'reducerConfigurator/itemModel/itemModel';

const getTosSchema = (t = (key) => key) => {
    const T_REQUIRED = t('app:validation.required');
    const T_MAX_LENGTH = (max) => t('app:validation.maxLength', { max });

    return Yup.object().shape({
        title: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        validFrom: Yup.string()
            .required(T_REQUIRED)
            .ensure(),
        validTo: Yup.string()
            .ensure(),
        titlePl: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        contentPl: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(10000, T_MAX_LENGTH(10000)),
        attachmentsPl: Yup.array()
            .max(10, T_MAX_LENGTH(10)),
        titleEn: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        contentEn: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(10000, T_MAX_LENGTH(10000)),
        attachmentsEn: Yup.array()
            .max(10, T_MAX_LENGTH(10)),
    });
};

const parseContents = (contents = []) => {
    const parsedContents = contents.reduce((parsed, content) => {
        parsed[content.language] = content;

        return parsed;
    }, {});
    const contentPl = parsedContents.pl || {};
    const contentEn = parsedContents.en || {};

    return { contentPl, contentEn };
};

const parseDate = (date) => {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY');
    }

    return '-';
};

class Tos extends ItemModel {
    constructor(props = {}) {
        super(props);

        const { contentPl, contentEn } = parseContents(props.contents);

        this.id = props.id || undefined;
        this.createdAt = props.createdAt || props.created_at || null;
        this.title = props.title || '';
        this.validFrom = props.valid_from || props.validFrom || null;
        this.validTo = props.valid_to || props.validTo || null;
        this.contents = props.contents || [];
        this.contentIdPl = contentPl['@id'] || '';
        this.titlePl = contentPl.title || props.titlePl || '';
        this.contentPl = contentPl.content || props.contentPl || '';
        this.attachmentsPl = contentPl.attachments || props.attachmentsPl || [];
        this.contentIdEn = contentEn['@id'] || '';
        this.titleEn = contentEn.title || props.titleEn || '';
        this.contentEn = contentEn.content || props.contentEn || '';
        this.attachmentsEn = contentEn.attachments || props.attachmentsEn || [];
    }

    static getSchema = getTosSchema;

    getId = () => this.id && this.id.toString();
    getCreatedAt = () => parseDate(this.createdAt);
    getTitle = () => this.title;
    getValidFrom = () => parseDate(this.validFrom);
    getValidTo = () => parseDate(this.validTo);
    getContents = () => this.contents;
    getPlTitle = () => this.titlePl;
    getPlContent = () => this.contentPl;
    getPlAttachments = () => this.attachmentsPl;
    getPlContentId = () => {
        const parsed = this.contentIdPl.split('/');

        return parsed[parsed.length - 1];
    };
    getEnTitle = () => this.titleEn;
    getEnContent = () => this.contentEn;
    getEnAttachments = () => this.attachmentsEn;
    getEnContentId = () => {
        const parsed = this.contentIdEn.split('/');

        return parsed[parsed.length - 1];
    };

    getTranslatedTos(language) {
        if (language === 'pl') {
            return {
                title: this.getPlTitle(),
                content: this.getPlContent(),
                attachments: this.getPlAttachments(),
                contentId: this.getPlContentId(),
            };
        }

        if (language === 'en') {
            return {
                title: this.getEnTitle(),
                content: this.getEnContent(),
                attachments: this.getEnAttachments(),
                contentId: this.getEnContentId(),
            };
        }
    }

    mapContent = (newData, withAttachments = true) => {
        return [
            {
                ...(withAttachments && { attachments: newData.attachmentsPl }),
                language: 'pl',
                '@id': this.contentIdPl || null,
                title: newData.titlePl,
                content: newData.contentPl,
            },
            {
                ...(withAttachments && { attachments: newData.attachmentsEn }),
                language: 'en',
                '@id': this.contentIdEn || null,
                title: newData.titleEn,
                content: newData.contentEn,
            }
        ];
    };

    updateTos = (newData = {}) => {
        const updateField = this.updateFieldFromNewData(newData);

        updateField('title');
        updateField('validFrom');
        updateField('validTo');
        updateField('titlePl');
        updateField('contentPl');
        updateField('attachmentsPl');
        updateField('titleEn');
        updateField('contentEn');
        updateField('attachmentsEn');

        this.updatedFields.push('contents');
        this.contents = this.mapContent(newData);

        return this;
    };

    getTosForPOSTRequest = (withAttachments = true) => {
        const tosObject = {};

        this.contents = this.mapContent(this, withAttachments);

        if (this.title) tosObject.title = this.title;
        if (this.validFrom) tosObject.validFrom = this.validFrom;
        if (this.validTo) tosObject.validTo = this.validTo;
        if (this.contents) tosObject.contents = this.contents;

        return tosObject;
    };

    getTosForPUTRequest = (withAttachments = true) => {
        const tosObject = {};

        this.contents = this.mapContent(this, withAttachments);

        tosObject.title = this.title;
        tosObject.validFrom = this.validFrom;
        tosObject.validTo = this.validTo;
        tosObject.contents = this.contents;

        return tosObject;
    };

    getTosPlainObject = () => ({
        title: this.getTitle(),
        validFrom: this.validFrom,
        validTo: this.validTo,
        titlePl: this.getPlTitle(),
        contentPl: this.getPlContent(),
        attachmentsPl: this.getPlAttachments(),
        titleEn: this.getEnTitle(),
        contentEn: this.getEnContent(),
        attachmentsEn: this.getEnAttachments(),
        contents: this.getContents(),
    });
}

export default Tos;
