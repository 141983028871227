import i18next from 'i18next';

import { URLS } from './constants';
import errorHandlersConfig from './errorHandlers';
import { ProductsService } from './ProductsService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const productsServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    ProductsService.getInstance({
        constants: {
            URLS,
        },
        errorHandlersConfig,
        services: {
            ajax: ajaxService,
            alert: alertService,
            translator: i18next,
        },
    });
