import { TOrderCompany } from 'types/orderCompany.type';
import { type URLS as URLS_CONFIG } from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';

class OrderInvoice {
    constructor(
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
    ) {
        this.downloadInvoice = this.downloadInvoice.bind(this);
    }

    async downloadInvoice(
        invoiceId: string,
        company: TOrderCompany | undefined = 'artgeist',
        hasInstantDownload = true,
    ) {
        const url = (
            company === 'artgeist'
                ? this.URLS.GET_DOWNLOAD_INVOICE
                : this.URLS.DECO_GET_DOWNLOAD_INVOICE
        ).replace('{id}', invoiceId);

        const config = {
            headers: {
                Accept: 'application/pdf',
            },
        };

        if (hasInstantDownload) {
            return this.ajaxService.download({ url, config });
        } else {
            const config = {
                headers: {
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            };

            const response = await this.ajaxService.get({ url, config });

            return response.data;
        }
    }
}

export default OrderInvoice;
