import PropTypes from 'prop-types';
import React from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
    ArrowIcon as ArrowForwardIosOutlinedIcon,
    ArrowLeftIcon as ArrowBackIosIcon,
} from 'components/Icons/Icons';
import { Loader } from '../Loader/Loader';
import {
    ArrowBackwardStyled,
    ArrowForwardStyled,
    CarouselWrapperStyled,
    ImageStyled,
    ImageTitleStyled,
    LoaderWrapperStyled,
    SlideWrapperStyled,
} from 'components/Carousel/carousel.styles';

const imageQuery = '?size=500x500';

export class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
        };
    }

    componentDidMount() {
        this.getSelectedItemTitle();
    }

    getSelectedIndex = () => {
        const { items, id } = this.props;

        return items.findIndex((item) => item.id === id);
    };

    getSelectedItemTitle = (index) => {
        const { items, id } = this.props;
        const initialItem = items.find((item) => item.id === id);

        const title =
            index !== undefined ? items[index].name : initialItem?.name;

        this.setState({
            title,
        });
    };

    render() {
        const { items } = this.props;
        const { title } = this.state;

        if (!items.length)
            return (
                <LoaderWrapperStyled>
                    <Loader aria-label="Loading" />
                </LoaderWrapperStyled>
            );

        return (
            <CarouselWrapperStyled>
                <ImageTitleStyled>{title}</ImageTitleStyled>
                <ReactCarousel
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    dynamicHeight={true}
                    showStatus={false}
                    selectedItem={this.getSelectedIndex()}
                    onChange={this.getSelectedItemTitle}
                    showIndicators={false}
                    renderArrowPrev={(onClickHandler, hasPrev) =>
                        hasPrev && (
                            <ArrowBackwardStyled
                                onClick={onClickHandler}
                                size="large"
                            >
                                <ArrowBackIosIcon />
                            </ArrowBackwardStyled>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext) =>
                        hasNext && (
                            <ArrowForwardStyled
                                onClick={onClickHandler}
                                size="large"
                            >
                                <ArrowForwardIosOutlinedIcon />
                            </ArrowForwardStyled>
                        )
                    }
                >
                    {items.length &&
                        items.map((item) => (
                            <SlideWrapperStyled key={item.id}>
                                <ImageStyled
                                    src={`${item.src}${imageQuery}`}
                                    alt={item.name}
                                />
                            </SlideWrapperStyled>
                        ))}
                </ReactCarousel>
            </CarouselWrapperStyled>
        );
    }
}

Carousel.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            src: PropTypes.string.isRequired,
        }).isRequired,
    ),
};

export default Carousel;
