import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbsStyled, SeparatorStyled, StageNameStyled } from 'components/Breadcrumbs/breadcrumbs.styles';

export class Breadcrumbs extends React.PureComponent {
    moveTo = (index) => {
        const { stagedPath: stagedPathRedux, stagedPathCustom } = this.props;

        const stagedPath = stagedPathCustom || stagedPathRedux;

        const link = '/' + stagedPath.slice(0, index + 1).join('/');

        this.props.moveTo(link);
    };

    render() {
        const {
            stagedPath: stagedPathRedux,
            stagedPathCustom,
            skipPath,
            t,
            moduleName,
            displayIndex,
            overwrite
        } = this.props;
        const stagedPath = stagedPathCustom || stagedPathRedux;

        const lastIndex = stagedPath.length - 1;

        return (
            <BreadcrumbsStyled>
                {
                    stagedPath.map((stage, index) => {
                        if (index >= displayIndex) {
                            const stageProps = {
                                onClick: () => index < lastIndex ? this.moveTo(index) : null,
                                tabIndex: '0',
                            };

                            if (index === lastIndex) {
                                delete stageProps.tabIndex;
                            }
                            if (skipPath.includes(index)) {
                                return;
                            }

                            return (
                                <span key={ index }>
                                    {index > displayIndex && (
                                        <SeparatorStyled
                                            data-test="separator"
                                        >
                                            {' '}
                                            /
                                            {' '}
                                        </SeparatorStyled>
                                    )}
                                    <StageNameStyled
                                        { ...stageProps }
                                        data-test="stage"
                                        aria-disabled={ index === lastIndex }
                                        disabled={ index === lastIndex }
                                    >
                                        {
                                            overwrite[index] ?
                                                overwrite[index]
                                                :
                                                t(`${moduleName}:nav.${stage}`, { defaultValue: stage })
                                        }
                                    </StageNameStyled>
                                </span>
                            );
                        }
                    })
                }
            </BreadcrumbsStyled>
        );
    }
}

Breadcrumbs.defaultProps = {
    displayIndex: 1,
    overwrite: {},
    skipPath: [],
    t: (key) => key,
    moveTo: () => {
    },
};

Breadcrumbs.propTypes = {
    overwrite: PropTypes.object,
    moduleName: PropTypes.string,
    displayIndex: PropTypes.number,
    stagedPath: PropTypes.array,
    stagedPathCustom: PropTypes.array,
    skipPath: PropTypes.array,
    t: PropTypes.func,
    moveTo: PropTypes.func,
};

export default Breadcrumbs;
