import { useState, ReactNode, useEffect } from 'react';

import NavigationPrompt from 'react-router-navigation-prompt';
import { FormikHelpers, useFormik, FormikConfig, FormikErrors } from 'formik';
import { Loader } from '../Loader/Loader';
import { SubmitModal } from './SubmitModal';
import { CancelModal } from './CancelModal';
import { TFunction } from 'i18next';

interface FormProps<
    TFormState extends Record<string, any> = Record<string, any>,
> {
    t?: TFunction | undefined;
    loading?: boolean | undefined;
    forceEditable?: boolean | undefined;
    validationSchema?: FormikConfig<TFormState>['validationSchema'] | undefined;
    initialValues: TFormState;
    onSubmit?:
        | ((params: {
              formData: TFormState;
              formActions: FormikHelpers<TFormState> & {
                  disableEditMode: () => void;
              };
          }) => void)
        | undefined;
    onCancel?: (() => void) | undefined;
    className?: string | undefined;
    id: string;
    children: (props: any) => ReactNode;
    create?: boolean | undefined;
    enableSubmitModal?: boolean | undefined;
    enableNavigationPrompter?: boolean | undefined;
    continuationAvailable?: boolean | undefined;
    /**
     * Enable if you need the form to change initialValues during rendering
     *
     * @default true
     */
    enableReinitialize?: boolean | undefined;
    onError?: ((errors: FormikErrors<TFormState>) => void) | undefined;
}

/**
 * Formik form with predefined behaviors
 *
 * This is the original version of this component migrated to TS and FC.
 */
export const Form = <
    TFormState extends Record<string, any> = Record<string, any>,
>({
    t = ((key) => key) as TFunction, // is simplify version of TFunction
    loading,
    forceEditable = false,
    validationSchema,
    initialValues,
    onSubmit,
    onCancel,
    onError,
    className,
    id,
    children,
    create,
    enableSubmitModal = true,
    enableNavigationPrompter = true,
    continuationAvailable,
    enableReinitialize = true,
}: FormProps<TFormState>) => {
    const [editable, setEditable] = useState(forceEditable);
    const [submitModalActive, setSubmitModalActive] = useState(false);
    const [cancelModalActive, setCancelModalActive] = useState(false);

    const handleOpenSubmitModal = () => setSubmitModalActive(true);
    const handleCloseSubmitModal = () => setSubmitModalActive(false);

    const handleOpenCancelModal = () => setCancelModalActive(true);
    const handleCloseCancelModal = () => setCancelModalActive(false);

    const handleCancel = () => {
        if (editable && !cancelModalActive) {
            return handleOpenCancelModal();
        }
        if (!editable || !cancelModalActive) {
            onCancel && onCancel();
        }
    };

    const handleDisableEditMode = (resetForm: () => void) => {
        setEditable(false);
        setCancelModalActive(false);
        resetForm();
    };

    const handleSubmit = (
        formData: TFormState,
        formActions: FormikHelpers<TFormState>,
    ) => {
        const newFormActions = {
            ...formActions,
            disableEditMode: () => {
                handleDisableEditMode(formActions.resetForm);
            },
        };

        if (!enableSubmitModal || submitModalActive) {
            handleCloseSubmitModal();
            onSubmit && onSubmit({ formData, formActions: newFormActions });
        } else if (continuationAvailable) {
            onSubmit && onSubmit({ formData, formActions: newFormActions });
        } else {
            handleOpenSubmitModal();
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        enableReinitialize,
    });

    const {
        errors,
        resetForm,
        dirty,
        isSubmitting,
        handleSubmit: formikHandleSubmit,
    } = formik;

    const handleToggleEditable = () => {
        setEditable((prevEditable) => !prevEditable);
        resetForm();
    };

    useEffect(() => {
        if (
            onError &&
            isSubmitting &&
            errors &&
            Object.keys(errors).length > 0
        ) {
            onError(errors);
        }
    }, [errors, onError, isSubmitting]);

    return (
        <>
            {loading && <Loader />}
            {enableSubmitModal && (
                <SubmitModal
                    t={t}
                    active={submitModalActive}
                    onClose={handleCloseSubmitModal}
                    formId={id}
                />
            )}

            <CancelModal
                t={t}
                create={create}
                active={cancelModalActive}
                onClose={handleCloseCancelModal}
                onConfirm={
                    forceEditable
                        ? onCancel
                        : () => {
                              handleDisableEditMode(resetForm);
                          }
                }
            />
            {enableNavigationPrompter && !cancelModalActive && (
                <NavigationPrompt when={dirty && !isSubmitting}>
                    {({ onConfirm, onCancel }) => (
                        <CancelModal
                            active
                            t={t}
                            onClose={onCancel}
                            onConfirm={onConfirm}
                        />
                    )}
                </NavigationPrompt>
            )}
            <form
                className={className}
                id={id}
                onSubmit={formikHandleSubmit}
            >
                {children({
                    ...formik,
                    editable,
                    handleToggleEditable: dirty
                        ? handleCancel
                        : handleToggleEditable,
                    handleCancel: dirty ? handleCancel : onCancel,
                })}
            </form>
        </>
    );
};

export default Form;
