import { lazy } from 'utils/codeSplitting';

import { PendingJobIcon } from 'components/Icons/Icons';

import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';

const Jobs = lazy(() => import('modules/userService/containers/JobsContainer'));

export const userServiceRouting = {
    name: MODULE_NAME,
    path: `/${ MODULE_NAME }`,
    routes: [
        {
            exact: true,
            name: 'jobs',
            path: PATHS.JOBS,
            component: Jobs,
        },
    ],
    icon: PendingJobIcon,
    fixedOnNavbar: true,
};
