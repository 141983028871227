/**
 * @typedef {import('./notes.type').NoteOptions} NoteOptions
 */

/**
 * @typedef {import('./notes.type').ImportOrderOptions} ImportOrderOptions
 */

/**
 * @param {ImportOrderOptions & NoteOptions} options
 * @return {string}
 */
const makeOrderImportNote = ({ channel, note = '' }) => `Import zamówień: ${channel}\n${note}`;

export { makeOrderImportNote };
