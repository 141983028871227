import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CheckCircleFilledIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <rect
                width="14"
                height="14"
                rx="7"
                fill="#3974C0"
            />
            <path
                d="M10.8652 4.39614C10.7821 4.39861 10.7033 4.43332 10.6454 4.49292L5.75646 9.38187L3.74468 7.37009C3.71522 7.33941 3.67994 7.31491 3.6409 7.29804C3.60186 7.28117 3.55984 7.27225 3.51731 7.27182C3.47478 7.27138 3.43259 7.27944 3.39321 7.29552C3.35383 7.3116 3.31805 7.33537 3.28798 7.36544C3.2579 7.39552 3.23413 7.43129 3.21805 7.47067C3.20198 7.51005 3.19392 7.55224 3.19435 7.59477C3.19479 7.6373 3.2037 7.67932 3.22057 7.71836C3.23745 7.75741 3.26194 7.79269 3.29263 7.82215L5.53043 10.06C5.59038 10.1199 5.67169 10.1535 5.75646 10.1535C5.84123 10.1535 5.92253 10.1199 5.98249 10.06L11.0975 4.94497C11.1437 4.90007 11.1752 4.84226 11.188 4.77911C11.2008 4.71597 11.1942 4.65044 11.1691 4.59111C11.1439 4.53178 11.1015 4.48142 11.0473 4.44663C10.9931 4.41184 10.9296 4.39424 10.8652 4.39614Z"
                fill="white"
            />
        </SvgIcon>
    );
}

CheckCircleFilledIcon.defaultProps = {
    titleAccess: 'check-circle-filled-icon',
};

CheckCircleFilledIcon.propTypes = svgIconPropTypes;
