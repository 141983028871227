import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function HouseIcon(props) {
    return (
        <SvgIcon
            viewBox="-0.5 0 17 17"
            { ...props }
        >
            <path
                d="M8.62685 0.000281178C8.55129 0.00288699 8.47848 0.029209 8.41872 0.0755155L0.138721 6.53653C0.0633827 6.59527 0.0144652 6.68154 0.00272959 6.77634C-0.00900605 6.87115 0.0174015 6.96674 0.0761428 7.04208C0.134884 7.11742 0.221147 7.16633 0.315956 7.17807C0.410764 7.1898 0.506351 7.1634 0.581689 7.10466L1.08021 6.71583V16.1806C1.08021 16.2761 1.11815 16.3676 1.18566 16.4351C1.25317 16.5027 1.34473 16.5406 1.44021 16.5406H6.41974C6.45861 16.547 6.49828 16.547 6.53716 16.5406H10.7397C10.7786 16.547 10.8183 16.547 10.8572 16.5406H15.8402C15.9357 16.5406 16.0272 16.5027 16.0948 16.4351C16.1623 16.3676 16.2002 16.2761 16.2002 16.1806V6.71583L16.6987 7.10466C16.736 7.13375 16.7787 7.1552 16.8243 7.1678C16.8699 7.1804 16.9175 7.1839 16.9645 7.17809C17.0114 7.17228 17.0568 7.15728 17.0979 7.13394C17.139 7.11061 17.1752 7.0794 17.2043 7.04209C17.2334 7.00479 17.2548 6.96212 17.2674 6.91652C17.28 6.87092 17.2835 6.82329 17.2777 6.77635C17.2719 6.7294 17.2569 6.68406 17.2335 6.64291C17.2102 6.60176 17.179 6.56561 17.1417 6.53653L14.4002 4.39762V1.78059H12.2402V2.71153L8.86169 0.0755155C8.79471 0.0235748 8.71154 -0.00306704 8.62685 0.000281178ZM8.64021 0.816609L15.4802 6.15403V15.8206H11.1602V8.98059H6.12021V15.8206H1.80021V6.15403L8.64021 0.816609ZM12.9602 2.50059H13.6802V3.83583L12.9602 3.27333V2.50059ZM6.84021 9.70059H10.4402V15.8206H6.84021V9.70059Z"
            />
        </SvgIcon>
    );
}

HouseIcon.defaultProps = {
    titleAccess: 'house-icon',
};

HouseIcon.propTypes = svgIconPropTypes;
