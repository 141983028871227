import get from 'lodash/get';

import theme from 'themes/themes';

const USER_SERVICE_URL = get(window, 'env.USER_SERVICE_URL', '');
const MEDIA_FILES_SERVICE_URL = get(window, 'env.MEDIA_FILES_URL', '');
const JOBS = `${USER_SERVICE_URL}/api/user/jobs`;
const FILES = `${MEDIA_FILES_SERVICE_URL}/api/v1`;

export const URLS = {
    GET_JOBS: JOBS,
    GET_JOB_BY_ID: `${JOBS}/{id}`,
    DOWNLOAD_JOB_ATTACHMENT_BY_ID: `${FILES}/{id}/download`,
};

export const DEFAULTS = {
    JOBS_PER_PAGE: 20,
};

export const JOB_STATUSES = {
    cancelled: {
        value: 'cancelled',
        color: theme.palette.common.gray.midLight
    },
    failed: {
        value: 'failed',
        color: theme.palette.error.main
    },
    pending: {
        value: 'pending',
        color: theme.palette.primary.main
    },
    processing: {
        value: 'processing',
        color: theme.palette.warning.main
    },
    succeeded: {
        value: 'succeeded',
        color: theme.palette.secondary.main
    },
    succeeded_with_warning: {
        value: 'succeeded_with_warning',
        color: theme.palette.status.succeeded_with_warning
    }
};
