import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Theme } from '@mui/material';
import { ComponentProps } from 'react';

const baseWidthStyles = css`
    max-width: 200px;
    min-width: 100px;
    margin: 0 5px;
    padding-left: 15px;
    padding-right: 15px;
`;

const loadingStyles = css`
    cursor: default;
    pointer-events: none;
`;

type TColor =
    | 'default'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'info'
    | 'success';

const makeColorClasses = (theme: Theme, color: TColor) => css`
    background-color: ${theme.palette[color].main};
    color: ${theme.palette[color].contrastText};

    ${color === 'default' && 'color: rgba(0,0,0, 0.87)'};

    &:hover {
        background-color: ${theme.palette[color].dark};
    }

    &:disabled {
        background-color: ${theme.palette[color].light};
    }
`;

const getButtonColor = (theme: Theme, color?: TColor | undefined) => {
    switch (color) {
        case 'error':
        case 'warning':
        case 'info':
        case 'success':
        case 'default': {
            return makeColorClasses(theme, color);
        }
        default: {
            return '';
        }
    }
};

export const ButtonWrapperStyled = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'baseWidth',
})<
    ComponentProps<typeof Button> & {
        loading?: boolean | undefined;
        baseWidth?: boolean | undefined;
        color?: TColor | undefined;
    }
>`
    ${({ loading }) => loading && loadingStyles};
    ${({ baseWidth }) => baseWidth && baseWidthStyles};

    ${({ theme, color }) => getButtonColor(theme, color)};
`;
ButtonWrapperStyled.displayName = 'ButtonWrapper';
