import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        INTERMEDIATE_PRODUCT: {
            FETCH: `${MODULE_NAME}_resources_intermediate_product_fetch`,
            EDIT: `${MODULE_NAME}_resources_intermediate_product_edit`,
            DELETE: `${MODULE_NAME}_resources_intermediate_product_delete`,
            CREATE: `${MODULE_NAME}_resources_intermediate_product_create`,
        },
        PATTERN: {
            FETCH: `${MODULE_NAME}_resources_pattern_fetch`,
            CREATE: `${MODULE_NAME}_resources_pattern_create`,
        },
        PRODUCT: {
            FETCH: `${MODULE_NAME}_resources_product_fetch`,
            CREATE: `${MODULE_NAME}_resources_product_create`,
        },
        PROPERTY: {
            FETCH: `${MODULE_NAME}_resources_property_fetch`,
            EDIT: `${MODULE_NAME}_resources_property_edit`,
            DELETE: `${MODULE_NAME}_resources_property_delete`,
            CREATE: `${MODULE_NAME}_resources_property_create`,
        },
        TECHNOLOGY: {
            FETCH: `${MODULE_NAME}_resources_technology_fetch`,
            EDIT: `${MODULE_NAME}_resources_technology_edit`,
            DELETE: `${MODULE_NAME}_resources_technology_delete`,
            CREATE: `${MODULE_NAME}_resources_technology_create`,
        },
        TECHNOLOGY_GROUP: {
            FETCH: `${MODULE_NAME}_resources_technology_group_fetch`,
            EDIT: `${MODULE_NAME}_resources_technology_group_edit`,
            DELETE: `${MODULE_NAME}_resources_technology_group_delete`,
            CREATE: `${MODULE_NAME}_resources_technology_group_create`,
        },
        PRINT_SPEC: {
            FETCH: `${MODULE_NAME}_resources_print_spec_fetch`,
            CREATE: `${MODULE_NAME}_resources_print_spec_create`,
            EDIT: `${MODULE_NAME}_resources_print_spec_edit`,
            DELETE: `${MODULE_NAME}_resources_print_spec_delete`,
        },
        PRINT_SPEC_FILE: {
            DELETE: `${MODULE_NAME}_resources_print_spec_file_delete`,
        },
        PRODUCT_SET: {
            FETCH: `${MODULE_NAME}_resources_product_set_fetch`,
        },
        PRODUCT_SET_TYPE: {
            DELETE: `${MODULE_NAME}_resources_product_set_type_delete`,
            CREATE: `${MODULE_NAME}_resources_product_set_type_create`,
            EDIT: `${MODULE_NAME}_resources_product_set_type_edit`,
        },
        EAN_POOLS: {
            FETCH: `${MODULE_NAME}_resources_ean_pools_fetch`,
            CREATE: `${MODULE_NAME}_resources_ean_pools_create`,
            EDIT: `${MODULE_NAME}_resources_ean_pools_edit`,
            DELETE: `${MODULE_NAME}_resources_ean_pools_delete`,
        },
        EXTERNAL_PRODUCERS: {
            VIEW: `${MODULE_NAME}_resources_external_producers_view`,
            CREATE: `${MODULE_NAME}_resources_external_producers_create`,
            EDIT: `${MODULE_NAME}_resources_external_producers_edit`,
        },
    },
};
