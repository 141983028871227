import Tos from './actions/Tos';
import Position from './actions/Position';
import Users from './actions/Users';
import RoleGroups from './actions/RoleGroups';
import Roles from './actions/Roles';
import Groups from './actions/Groups';
import { Workplaces } from './actions/Workplaces';

export class PdpService {
    private static instance: PdpService | undefined;
    public readonly users: Users;
    public readonly tos: Tos;
    public readonly positions: Position;
    public readonly roleGroups: RoleGroups;
    public readonly roles: Roles;
    public readonly groups: Groups;
    public readonly workplaces: Workplaces;

    private constructor({
        services,
        URLS,
        DEFAULTS,
        ListModel,
        errorHandlers,
        params,
        WORKPLACE_MODAL_ROLE,
        STORAGE_CURRENT_WORKPLACE,
        storage,
    }) {
        this.ajax = services.ajax;
        this.alerts = services.alerts;
        this.parser = services.parser;
        this.translator = services.translator;
        this.positionOptions = {};
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.USER_PARAMS = params.USER_PARAMS;
        this.POSITION_PARAMS = params.POSITION_PARAMS;
        this.errorHandlers = errorHandlers;

        this.tos = new Tos(this, services, URLS, DEFAULTS, ListModel);
        this.positions = new Position(
            this,
            services,
            URLS,
            DEFAULTS,
            ListModel,
        );
        this.users = new Users(this);
        this.roleGroups = new RoleGroups(this);
        this.roles = new Roles(this);
        this.groups = new Groups(this);
        this.workplaces = new Workplaces(services, storage, {
            URLS,
            WORKPLACE_MODAL_ROLE,
            STORAGE_CURRENT_WORKPLACE,
        });

        this.getPositions = this.getPositions.bind(this);
        this.getFilterParams = this.getFilterParams.bind(this);
        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    public static getInstance({
        services,
        URLS,
        DEFAULTS,
        ListModel,
        errorHandlers,
        params,
        WORKPLACE_MODAL_ROLE,
        STORAGE_CURRENT_WORKPLACE,
        storage,
    }) {
        if (!PdpService.instance) {
            PdpService.instance = new PdpService({
                services,
                URLS,
                DEFAULTS,
                ListModel,
                errorHandlers,
                params,
                WORKPLACE_MODAL_ROLE,
                STORAGE_CURRENT_WORKPLACE,
                storage,
            });
        }

        return PdpService.instance;
    }

    getPositions(page = 1, per_page = 20) {
        const positionOptions = this.positionOptions;
        const url = this.URLS.GET_POSITIONS;
        const params = {
            page: page,
            per_page: per_page,
        };
        const config = { params };

        if (positionOptions) {
            return this.ajax.get({ url, config }).then((response) => {
                return response.data['hydra:member'];
            });
        }

        return new Promise(function (resolve) {
            resolve(positionOptions);
        });
    }
    getFilterParams(filterFields = [], filters = {}) {
        return filterFields.reduce((params, filterKey) => {
            if (filters[filterKey]) {
                params[filterKey] = filters[filterKey];
            }

            return params;
        }, {});
    }
    getSortParams(sortingFields = [], order = {}) {
        return sortingFields.reduce((params, orderKey) => {
            if (order[orderKey]) {
                params[orderKey] = order[orderKey];
            }

            return params;
        }, {});
    }
    paramsSerializer = (params) => this.parser.stringify(params);
}

export default PdpService;
