import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function BellIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -1 16 16"
            { ...props }
        >
            <path
                d="M5.7591 0.339205C3.06461 0.466119 0.999983 2.78114 0.999983 5.4603V7.88282L0.11652 9.66017C0.114966 9.66341 0.113447 9.66666 0.111963 9.66993C-0.243456 10.4258 0.331886 11.3333 1.1673 11.3333H3.99998C3.99998 12.432 4.90136 13.3333 5.99998 13.3333C7.09861 13.3333 7.99998 12.432 7.99998 11.3333H10.832C11.6674 11.3333 12.2435 10.426 11.888 9.66993C11.8865 9.66666 11.885 9.66341 11.8834 9.66017L11 7.88282V5.33335C11 2.49795 8.62108 0.204467 5.7591 0.339205ZM5.80597 1.3379C8.11266 1.22931 9.99998 3.05408 9.99998 5.33335V8.00001C9.99991 8.07726 10.0177 8.15347 10.0521 8.22267L10.9831 10.0964C11.0429 10.2247 10.9737 10.3333 10.832 10.3333H1.1673C1.02566 10.3333 0.956951 10.2249 1.01691 10.0964V10.0957L1.9479 8.22267C1.98223 8.15347 2.00005 8.07726 1.99998 8.00001V5.4603C1.99998 3.29346 3.6598 1.43899 5.80597 1.3379ZM4.99998 11.3333H6.99998C6.99998 11.8914 6.55803 12.3333 5.99998 12.3333C5.44194 12.3333 4.99998 11.8914 4.99998 11.3333Z"
            />
        </SvgIcon>
    );
}

BellIcon.defaultProps = {
    titleAccess: 'arrow-icon',
};

BellIcon.propTypes = svgIconPropTypes;
