import type { AlertService } from 'services/alert/AlertService';

const get =
    (alertService: AlertService) =>
    (error: any = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        if (data['hydra:description']) {
            alertService.addError({
                message: data['hydra:description'],
            });
            throw error;
        }

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: 'An unknown error has occurred',
        });
        throw error;
    };

const errorHandlers = (alertService: AlertService) => ({
    get: get(alertService),
    patch: get(alertService),
});

export default errorHandlers;
