import { lazy } from 'react';
import { MODULE_NAME } from './constants.config';
import * as PATHS from './paths.config';

const WZHelperPage = lazy(() =>
    import('../containers/wz-helper-container').then((module) => ({
        default: module.WZHelperContainer,
    })),
);

export const testHelpersRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            name: 'wz-helper',
            path: PATHS.WZ_HELPER,
            component: WZHelperPage,
        },
    ],
};
