import i18next from 'i18next';
import parser from 'qs';

import { ApplicationJsonHeaders } from '../constants';

import { URLS, DEFAULTS } from './constants';
import errorHandlers from './errorHandlers';
import { UserService } from './UserService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const userServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    UserService.getInstance({
        CONSTANTS: {
            URLS,
            DEFAULTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
            },
        },
        errorHandlersConfig: errorHandlers,
        services: {
            ajax: ajaxService,
            alert: alertService,
            parser,
            translator: i18next,
        },
    });
