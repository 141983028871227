import { makeReducerSelector } from 'utils/redux/makeSelectors';
import { MODULE_NAME } from '../../constants';

const selector = makeReducerSelector(MODULE_NAME, 'properties');

export const selectProperties = selector('propertiesById');
export const selectPropertiesCount = selector('propertiesCount');
export const selectPropertyEditErrors = selector('errorsByField');
export const selectFetchPropertyErrorCode = selector('fetchPropertyErrorCode');
export const selectLoader = selector('loaders');
export const selectPostResponseSuccess = selector('postResponseSuccess');
