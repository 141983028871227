import React from 'react';
import * as Yup from 'yup';
import { ItemModel } from 'reducerConfigurator/itemModel/itemModel';
import moment from 'moment';
import Link from '@mui/material/Link';

const getTaskSchema = (t = (key) => key) =>
    Yup.object().shape({
        id: Yup.string().max(255, t('app:validation.maxLength', { max: 255 })),
    });

export class Task extends ItemModel {
    constructor(props = {}) {
        super(props);

        this.id = props.id || undefined;
        this.created = props.created || '';
        this.name = props.name || '';
        this.processDefinitionName = props.process_definition_name || '';
        this.priority = props.priority || 0;
        this.customFields = props.customFields;
    }

    getCreatedAt = () =>
        this.created ? moment(this.created).format('DD/MM/YYYY') : '';
    getName = () => this.name;
    getProcessDefinitionName = () => this.processDefinitionName;
    getPriority = () => this.priority;
    getCustomFieldsTypes = () => {
        if (this.customFields) {
            return this.customFields.map((field) => field.name) || [];
        }
    };
    getCustomFields = (index) => {
        if (this.customFields && this.customFields[index]) {
            return (
                <div>
                    <div>{this.customFields[index].value}</div>
                </div>
            );
        }

        return '';
    };
    getNameWithLink = (goToDetails) => {
        return (
            <Link
                onClick={() => goToDetails(this.id)}
                underline="hover"
            >
                {this.name}
            </Link>
        );
    };

    static getSchema = getTaskSchema;

    static mapFilters() {
        return {
            createdBefore: {
                name: 'createdBefore',
                type: 'normal',
                typeValue: null,
                customValue: null,
            },
            createdAfter: {
                name: 'createdAfter',
                type: 'normal',
                typeValue: null,
                customValue: null,
            },
            orderName: {
                name: 'orderName',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            productList: {
                name: 'productList',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            submissionType: {
                name: 'submissionType',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            pickupDate: {
                name: 'pickupDate',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            stabloOrders: {
                name: 'stabloOrders',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            wzName: {
                name: 'wzName',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            wzNumber: {
                name: 'wzNumber',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
            channelCode: {
                name: 'channelCode',
                type: 'processVariable',
                typeValue: 'like',
                customValue: null,
            },
            languageCode: {
                name: 'languageCode',
                type: 'processVariable',
                typeValue: 'like',
                customValue: null,
            },
            patternCode: {
                name: 'patternCode',
                type: 'processVariable',
                typeValue: 'eq',
                customValue: null,
            },
        };
    }
}
