import { css } from '@emotion/react';

export const modalCarouselPaperStyles = css`
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
`;

export const modalFullHeightPaperStyles = css`
  height: 100%;
`;
