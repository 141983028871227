import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LOCAL_ENV_NAME } from 'utils/enviromentNames';
import { getRawActiveToken, getRefreshToken } from 'utils/tokenHelpers';
import ModuleSwitch from 'components/ModuleSwitch/moduleSwitch';
import ModalContainer from 'containers/Modal/ModalContainer';
import DrawerContainer from 'containers/Drawer/DrawerContainer';
import { Loader } from 'components/Loader/Loader';
import Gatekeeper from '../Gatekeeper/Gatekeeper';
import Header from 'modules/app/components/Header/headerWithState';
import NavBar from 'modules/app/components/navBar/navBarWithState';
import EntryPage from 'modules/app/pages/EntryPage/EntryPage';
import NotFound from 'modules/app/pages/NotFound/NotFound';
import SettingsPage from 'modules/app/pages/SettingsPage/SettingsPage';
import AlertProvider from 'modules/app/components/AlertsBox/AlertProvider';
import AlertsBox from 'modules/app/components/AlertsBox/AlertsBox';
import getModules from '../../routing/routing';
import {
    AppBarSpacerStyled,
    AppContentStyled,
    AppLoaderStyled,
    AppPageStyled,
    AppRootStyled,
    scrollbarStyles,
} from './app.styles';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { WorkplaceModal } from '../workplaceModal/WorkplaceModal';
import { WorkplacePage } from 'modules/app/pages/workplace/WorkplacePage';
import { isTokenExpire } from 'access/votingMechanism.js';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultWorkplace: '',
        };

        const refreshToken = getRefreshToken();
        const tokenIsValid = getRawActiveToken() && !isTokenExpire();

        if (tokenIsValid) {
            props.setLoggedIn();
            props.resetToken();
        } else if (refreshToken) {
            props.resetToken({ noDelay: true });
        }
    }

    componentDidUpdate() {
        const {
            state: { defaultWorkplace },
            props: {
                loggedIn,
                showWorkplaceModal,
                services: {
                    workplaces: { user },
                },
            },
        } = this;

        if (loggedIn && showWorkplaceModal && defaultWorkplace === '') {
            (async () => {
                try {
                    const data = await user.getWorkplace();

                    this.setState((state) => ({
                        ...state,
                        defaultWorkplace: data?.id ?? '',
                    }));
                } catch {
                    this.setState((state) => ({
                        ...state,
                        defaultWorkplace: null,
                    }));
                }
            })();
        }
    }

    handleSaveWorkPlace = async ({ formData: { workplace } }) => {
        const {
            hideWorkplaceModal,
            services: {
                pdp: { workplaces },
            },
        } = this.props;

        await workplaces.patchWorkplace(workplace);

        hideWorkplaceModal();
    };

    render() {
        const {
            loggedIn,
            showWorkplaceModal,
            services: {
                workplaces: { workplace },
            },
        } = this.props;
        const { defaultWorkplace } = this.state;
        const isLocal = window.env.APP_ENV === LOCAL_ENV_NAME;
        const needLogin = !(isLocal || loggedIn);
        const modules = getModules();

        return (
            <AlertProvider>
                <AlertsBox />
                <ModalContainer />
                <DrawerContainer />
                <CssBaseline />
                <GlobalStyles styles={scrollbarStyles} />
                <Gatekeeper />
                <AppRootStyled>
                    {!needLogin && (
                        <Header
                            getCachedWorkplaces={workplace.getCachedWorkplaces}
                        />
                    )}
                    {!needLogin && <NavBar modules={modules} />}
                    <ErrorBoundary>
                        <React.Suspense
                            fallback={
                                <AppLoaderStyled>
                                    <Loader
                                        size={60}
                                        loaderOnly
                                    />
                                </AppLoaderStyled>
                            }
                        >
                            <AppContentStyled>
                                <AppBarSpacerStyled />
                                <AppPageStyled>
                                    <WorkplaceModal
                                        active={
                                            !needLogin && showWorkplaceModal
                                        }
                                        handleSaveWorkplace={
                                            this.handleSaveWorkPlace
                                        }
                                        getWorkplaces={workplace.getWorkplaces}
                                        defaultWorkplace={defaultWorkplace}
                                    />
                                    <Switch>
                                        {needLogin && (
                                            <Route>
                                                <EntryPage />
                                            </Route>
                                        )}
                                        {modules[0] && (
                                            <Redirect
                                                exact
                                                path="/"
                                                to={modules[0].path}
                                            />
                                        )}
                                        {modules.map((module) => {
                                            return (
                                                <Route
                                                    key={module.path}
                                                    path={module.path}
                                                >
                                                    <ModuleSwitch
                                                        module={module}
                                                    />
                                                </Route>
                                            );
                                        })}
                                        <Route
                                            path="/settings"
                                            component={SettingsPage}
                                        />
                                        <Route
                                            path="/workplace"
                                            component={WorkplacePage}
                                        />
                                        <Route component={NotFound} />
                                    </Switch>
                                </AppPageStyled>
                            </AppContentStyled>
                        </React.Suspense>
                    </ErrorBoundary>
                </AppRootStyled>
            </AlertProvider>
        );
    }
}

export default App;

App.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    showWorkplaceModal: PropTypes.bool.isRequired,
    setLoggedIn: PropTypes.func.isRequired,
    hideWorkplaceModal: PropTypes.func.isRequired,
    resetToken: PropTypes.func.isRequired,
    services: PropTypes.shape({
        workplaces: PropTypes.shape({
            user: PropTypes.shape({
                doShowWorkplaceModal: PropTypes.func.isRequired,

                getWorkplace: PropTypes.func.isRequired,
            }).isRequired,
            workplace: PropTypes.shape({
                getWorkplaces: PropTypes.func.isRequired,
                getCachedWorkplaces: PropTypes.func.isRequired,
            }).isRequired,
        }).isRequired,
        modal: PropTypes.shape({
            open: PropTypes.func.isRequired,
        }).isRequired,
        pdp: PropTypes.shape({
            workplaces: PropTypes.shape({
                patchWorkplace: PropTypes.func.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};
