import get from 'lodash/get';

const ARM_API_URL = get(window, 'env.ARM_API_URL', '');
const MEDIA_FILES_SERVICE_URL = get(window, 'env.MEDIA_FILES_URL', '');

const ACCESSORIES_URLS = {
    CREATE_ACCESSORY: `${ARM_API_URL}/api/accessories`,
    GET_ACCESSORIES: `${ARM_API_URL}/api/accessories`,
    GET_ACCESSORY_BY_ID: `${ARM_API_URL}/api/accessories/{id}`,
} as const;

const BOXES_ID_URL = `${ARM_API_URL}/api/boxes/{id}` as const;

const BOXES_URLS = {
    GET_BOX: BOXES_ID_URL,
    GET_BOXES: `${ARM_API_URL}/api/boxes`,
    POST_BOX: `${ARM_API_URL}/api/boxes`,
    PUT_BOX: BOXES_ID_URL,
    DELETE_BOX: BOXES_ID_URL,
} as const;

const BOX_GROUP_ID_URL = `${ARM_API_URL}/api/v2/box_groups/{id}` as const;

const BOX_GROUPS_URLS = {
    GET_BOX_GROUP: BOX_GROUP_ID_URL,
    GET_BOX_GROUPS: `${ARM_API_URL}/api/v2/box_groups`,
    PUT_BOX_RULES: `${ARM_API_URL}/api/v2/box_groups/{id}/box_rules`,
    DELETE_BOX_GROUP: BOX_GROUP_ID_URL,
} as const;

const BOX_RULE_ID_URL = `${ARM_API_URL}/api/box_rules/{id}` as const;
const BOX_RULE_URL = `${ARM_API_URL}/api/box_rules` as const;

const BOX_RULES_URLS = {
    DELETE_BOX_RULE: BOX_RULE_ID_URL,
    DELETE_BOX_RULE_BATCH: `${ARM_API_URL}/api/box_rules/{id}/bulk_delete`,
    GET_BOX_RULE: BOX_RULE_ID_URL,
    GET_BOX_RULES: BOX_RULE_URL,
    PATCH_BOX_RULE: BOX_RULE_ID_URL,
    POST_BOX_RULE: BOX_RULE_URL,
    POST_IMPORT_BOX_RULES_CSV: `${ARM_API_URL}/api/box_rules/import`,
    GET_EXPORT_BOX_RULES_CSV: `${ARM_API_URL}/api/box_rules/export`,
} as const;

const CONTRACTOR_EMPLOYEES_URLS = {
    DELETE_CONTRACTOR_EMPLOYEE: `${ARM_API_URL}/api/contractor_employees/{id}`,
    GET_CONTRACTOR_EMPLOYEE: `${ARM_API_URL}/api/contractor_employees/{id}`,
    GET_CONTRACTOR_EMPLOYEES: `${ARM_API_URL}/api/contractor_employees`,
    POST_CONTRACTOR_EMPLOYEE: `${ARM_API_URL}/api/contractor_employees`,
} as const;

const CONTRACTORS_URLS = {
    ADD_CONTRACTOR_PRICE_LIST: `${ARM_API_URL}/api/contractors/{id}/price_lists`,
    DELETE_CONTRACTOR: `${ARM_API_URL}/api/contractors/{id}`,
    DELETE_CONTRACTOR_PRICE_LIST: `${ARM_API_URL}/api/contractors/{contractorId}/price_lists/{priceListId}`,
    GET_CONTRACTOR: `${ARM_API_URL}/api/contractors/{id}`,
    GET_CONTRACTOR_PRICE_LISTS: `${ARM_API_URL}/api/contractors/{id}/price_lists`,
    GET_CONTRACTORS: `${ARM_API_URL}/api/contractors`,
    POST_CONTRACTOR: `${ARM_API_URL}/api/contractors`,
    PUT_CONTRACTOR: `${ARM_API_URL}/api/contractors/{id}`,
} as const;

const EXTERNAL_PRODUCERS_URLS = {
    EXTERNAL_PRODUCERS: `${ARM_API_URL}/api/external_producers`,
    EXTERNAL_PRODUCERS_BY_ID: `${ARM_API_URL}/api/external_producers/{id}`,
} as const;

const IMPORTED_ORDER_FILES_URLS = {
    DELETE_ORDER_IMPORT: `${ARM_API_URL}/api/imported_order_files/{id}`,
    DOWNLOAD_ORDER_IMPORT: `${ARM_API_URL}/api/imported_order_files/{id}/download?file={fileType}`,
    GET_ORDER_IMPORT: `${ARM_API_URL}/api/imported_order_files/{id}`,
    PASS_IMPORTED_ORDER_FILES: `${ARM_API_URL}/api/imported_order_files/{id}/pass`,
    POST_ORDER_IMPORT: `${ARM_API_URL}/api/imported_order_files`,

    GET_ORDER_IMPORTS: `${ARM_API_URL}/api/v2/imported_order_files`,
} as const;

const INTERMEDIATE_PRODUCT_GROUPS_URLS = {
    POST_TECHNOLOGIES: `${ARM_API_URL}/api/v2/technologies`,
    GET_TECHNOLOGIES: `${ARM_API_URL}/api/intermediate_product_groups`,
    GET_TECHNOLOGY: `${ARM_API_URL}/api/intermediate_product_groups/{id}`,

    GET_TECHNOLOGIES_CSV_FILE: `${ARM_API_URL}/api/v2/intermediate_product_groups/export`,
} as const;

const INTERMEDIATE_PRODUCTS_INTERNAL_BOXES_URLS = {
    PUT_INTERMEDIATE_PRODUCTS_INTERNAL_BOXES: `${ARM_API_URL}/api/v2/intermediate_products_internal_boxes/{id}`,
    PUT_INTERMEDIATE_PRODUCTS_SHIPPING_BOXES: `${ARM_API_URL}/api/v2/intermediate_products_shipping_boxes/{id}`,
} as const;

const INTERMEDIATE_PRODUCTS_URLS = {
    GET_INTERMEDIATE_PRODUCTS_CSV_FILE: `${ARM_API_URL}/api/intermediate_products/export`,

    GET_INTERMEDIATE_PRODUCT: `${ARM_API_URL}/api/v2/intermediate_products/{id}`,
    GET_INTERMEDIATE_PRODUCTS: `${ARM_API_URL}/api/v2/intermediate_products`,
    PATCH_INTERMEDIATE_PRODUCT: `${ARM_API_URL}/api/v2/intermediate_products/{id}`,
} as const;

const MEDIA_FILES_URLS = {
    GET_FILE: `${ARM_API_URL}/api/media_files/{id}/download`,
    POST_FILE: `${ARM_API_URL}/api/media_files`,
} as const;

const PRODUCTS_URLS = {
    GET_PRODUCT: `${ARM_API_URL}/api/products/{id}`,
    GET_PRODUCTS: `${ARM_API_URL}/api/products`,
    GET_PRODUCTS_CSV_FILE: `${ARM_API_URL}/api/v1/products/export`,
} as const;

const PRINT_SPECS_URLS = {
    GET_ARM_PRINT_SPEC_BY_ID_V3: `${ARM_API_URL}/api/v3/print_specs/{id}`,
    GET_ARM_PRINT_SPEC_FILES_V3: `${ARM_API_URL}/api/v3/print_specs/{id}/files`,
    GET_ARM_PRINT_SPECS_V3: `${ARM_API_URL}/api/v3/print_specs`,
    GET_PRINT_SPECS_TECHNOLOGY_QUANTITY_V3: `${ARM_API_URL}/api/v3/print_specs/{id}/technology_quantity`,
    PUT_PRINT_SPECS_V3: `${ARM_API_URL}/api/v3/print_specs/{id}`,
    POST_ARM_PRINT_SPEC_FILES_V3: `${ARM_API_URL}/api/v3/print_specs/{id}/files`,
    POST_ARM_PRINT_SPEC_V3: `${ARM_API_URL}/api/v3/print_specs`,
    DELETE_ARM_PRINT_SPEC_V3: `${ARM_API_URL}/api/v3/print_specs/{id}`,
} as const;

const PROPERTIES_URLS = {
    GET_PROPERTIES: `${ARM_API_URL}/api/properties`,
    GET_PROPERTY: `${ARM_API_URL}/api/properties/{id}`,
} as const;

const TECHNOLOGIES_URLS = {
    GET_TECHNOLOGIES_AUTOCOMPLETE: `${ARM_API_URL}/api/technologies/autocomplete`,
} as const;

const TECHNOLOGY_GROUPS_URLS = {
    GET_TECHNOLOGY_GROUP: `${ARM_API_URL}/api/v2/technology_groups/{id}`,
    GET_TECHNOLOGY_GROUPS: `${ARM_API_URL}/api/v2/technology_groups`,
    POST_TECHNOLOGY_GROUP: `${ARM_API_URL}/api/v2/technology_groups`,
    PUT_TECHNOLOGY_GROUP: `${ARM_API_URL}/api/v2/technology_groups/{id}`,
} as const;

const BOX_PRODUCERS_URL = `${ARM_API_URL}/api/box_producers` as const;

const BOX_PRODUCERS_URLS = {
    POST_BOX_PRODUCERS: BOX_PRODUCERS_URL,
    GET_BOX_PRODUCERS: BOX_PRODUCERS_URL,
    GET_BOX_PRODUCERS_BY_ID: `${ARM_API_URL}/api/box_producers/{id}`,
} as const;

const DOWNLOAD_FILE_RESOURCE =
    `${MEDIA_FILES_SERVICE_URL}/api/v1/{id}/download` as const;

export const URLS = {
    ...ACCESSORIES_URLS,
    ...BOXES_URLS,
    ...BOX_GROUPS_URLS,
    ...BOX_RULES_URLS,
    ...CONTRACTOR_EMPLOYEES_URLS,
    ...CONTRACTORS_URLS,
    ...EXTERNAL_PRODUCERS_URLS,
    ...IMPORTED_ORDER_FILES_URLS,
    ...INTERMEDIATE_PRODUCT_GROUPS_URLS,
    ...INTERMEDIATE_PRODUCTS_INTERNAL_BOXES_URLS,
    ...INTERMEDIATE_PRODUCTS_URLS,
    ...MEDIA_FILES_URLS,
    ...PRINT_SPECS_URLS,
    ...PRODUCTS_URLS,
    ...PROPERTIES_URLS,
    ...TECHNOLOGIES_URLS,
    ...TECHNOLOGY_GROUPS_URLS,
    ...BOX_PRODUCERS_URLS,
    GET_INVOICES: `${ARM_API_URL}/api/invoices`,
    GET_MANAGERS: `${ARM_API_URL}/api/contractor_manager_stats`,
    GET_PRODUCT_SET_TAGS: `${ARM_API_URL}/api/product_set_tags`,
    DOWNLOAD_BOX_IMAGE: DOWNLOAD_FILE_RESOURCE,
    DELETE_ARM_PRINT_SPEC_FILES: `${ARM_API_URL}/api/print_spec_files/{id}`,
    DOWNLOAD_PRINT_SPEC_FILES: DOWNLOAD_FILE_RESOURCE,
    DOWNLOAD_INVOICE: `${ARM_API_URL}{path}`,
} as const;

export const FILES_TYPES = {
    ICON: 'group_icon',
    MANUAL: 'group_manual',
    PROPERTY: 'property_file',
    PREVIEW: 'preview_file',
} as const;

export const API_ERRORS = {
    IMPORT_ORDER_ALREADY_PASSED: 'All orders were already passed to SIMP',
} as const;

export const APP_ALERTS = {
    ORDER_IMPORT_ALREADY_PASSED:
        'dropshipping:alerts.orderAlreadyPassedToProduction',
    ORDER_IMPORT_PASSED: 'dropshipping:alerts.orderPassedToProduction',
    ORDER_IMPORT_DELETED: 'dropshipping:alerts.orderImportDeleted',
    ORDER_IMPORT_CREATED: 'dropshipping:alerts.orderImportCreated',
    CONTRACTOR_CREATED: 'dropshipping:alerts.contractorCreated',
    CONTRACTOR_CHANGED: 'dropshipping:alerts.contractorChanged',
    CONTRACTOR_DELETED: 'dropshipping:alerts.contractorDeleted',
    CONTRACTOR_PRICE_LIST_DELETED:
        'dropshipping:alerts.contractorPriceListDeleted',
} as const;

export const DEFAULTS = {
    BOX_GROUPS_PER_PAGE: 20,
    BOX_PRODUCERS_PER_PAGE: 20,
    PRINT_SPECS_PER_PAGE: 20,
    EXTERNAL_PRODUCERS_PER_PAGE: 20,
} as const;

export const PARAMS = {
    PRINT_SPEC_PARAMS: {
        SORTING_KEYS: ['createdAt', 'name'],
        FILTERS_KEYS: ['status', 'externalProducer'],
    },
} as const;
