import type { AlertService } from '../alert/AlertService';

const hydraDescription = 'hydra:description';

const get =
    (alertService: AlertService) =>
    (error: any = {}): never => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        }

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: `An unknown error has occurred (${status})`,
        });

        throw error;
    };

const post =
    (alertService: AlertService) =>
    (error: any = {}): never => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        if (data.violations) {
            data.violations.forEach((violation: any = {}) => {
                alertService.addError({
                    message: `${violation.propertyPath}: ${violation.message}`,
                });
                throw error;
            });
        }

        if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        }

        alertService.addError({
            message: `An unknown error has occurred (${status})`,
        });

        throw error;
    };

export const errorHandlers = (alertService: AlertService) => ({
    get: get(alertService),
    post: post(alertService),
    put: post(alertService),
});
