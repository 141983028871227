import React from 'react';
import { svgIconPropTypes } from 'utils/propTypes';
import { SvgIcon } from 'components/Icons/SvgIconBase';

export const CrossmarkIcon = ({ fill = '#DB3C26', titleAccess = 'crossmark-icon', ...props }) => (
    <SvgIcon
        viewBox="0 0 12 12"
        titleAccess={ titleAccess }
        { ...props }
    >
        <path
            d="M1.13802 0L0.195312 0.942708L5.05729 5.80469L0.195312 10.6667L1.13802 11.6094L6 6.7474L10.862 11.6094L11.8047 10.6667L6.94271 5.80469L11.8047 0.942708L10.862 0L6 4.86198L1.13802 0Z"
            fill={ fill }
        />
    </SvgIcon>
);

CrossmarkIcon.propTypes = svgIconPropTypes;
