export const mapOrderFromApi = (data = {}) => {
    const {
        purchaseDate,
        id,
        status,
        statusDetails,
        orderNumber,
        orderName,
        customerName,
        orderChannelName,
        customerRequestComplaint,
        customerRequestReturn,
        notesCount,
        company,
    } = data;

    const placeholder = '---';
    const lines = data?.lines?.reduce((prev, curr) => {
        return {
            patternCode: prev?.patternCode
                ? [...prev.patternCode, curr?.product?.patternCode]
                : [curr?.product?.patternCode],
            intermediateProductName: prev?.intermediateProductName
                ? [
                      ...prev.intermediateProductName,
                      curr?.product?.intermediateProduct?.name,
                  ]
                : [curr?.product?.intermediateProduct?.name],
            technologyCode: prev?.technologyCode
                ? [
                      ...prev.technologyCode,
                      curr?.product?.intermediateProduct?.technology?.code,
                  ]
                : [curr?.product?.intermediateProduct?.technology?.code],
        };
    }, {});

    return {
        id,
        status,
        statusDetails: statusDetails || '',
        orderNumber,
        orderName,
        customerName,
        company,
        orderChannelName,
        patternCode: lines?.patternCode
            ? lines.patternCode.join(', ')
            : placeholder,
        intermediateProductName: lines?.intermediateProductName
            ? lines.intermediateProductName.join(', ')
            : placeholder,
        technologyCode: lines?.technologyCode
            ? lines.technologyCode.join(', ')
            : placeholder,
        notesCount,
        purchaseDate,
        customerRequestReturn,
        customerRequestComplaint,
    };
};
