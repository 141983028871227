import * as countries from '../../utils/countries';
export const MODULE_NAME = 'sales';
export const COUNTRY_LIST = countries;

export const ORDER_STATUS = [
    'canceled',
    'error',
    'pending',
    'collecting_information',
    'exported',
    'shipped',
    'validating',
    'manual_validating',
];
