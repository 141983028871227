import styled from '@emotion/styled';
import Typography from 'components/Typography';

export const FieldStyled = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: 0.875rem;
`;
FieldStyled.displayName = 'Field';

export const LabelStyled = styled('span')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
LabelStyled.displayName = 'Label';

export const FieldsContainerStyled = styled('div')`
  margin: ${({ theme }) => theme.spacing(2)};
`;
FieldsContainerStyled.displayName = 'FieldsContainer';

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  background: ${({ theme }) => theme.palette.grey[100]};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-transform: uppercase;
`;
TitleStyled.displayName = 'Title';
