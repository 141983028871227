import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function UploadIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 24 24"
            { ...props }
        >
            <path
                d="M13.92 0C11.4169 0 9.29813 1.49813 8.28 3.615C7.73812 3.24187 7.11 2.97 6.405 2.97C4.61625 2.97 3.18937 4.38562 3.09 6.15C1.30125 6.795 0 8.46375 0 10.47C0 13.0275 2.0775 15.105 4.635 15.105H8.64C8.8125 15.1069 8.97375 15.0169 9.06187 14.8669C9.14812 14.7169 9.14812 14.5331 9.06187 14.3831C8.97375 14.2331 8.8125 14.1431 8.64 14.145H4.635C2.595 14.145 0.96 12.5081 0.96 10.47C0.96 8.77312 2.10375 7.34437 3.66 6.915C3.87187 6.85312 4.01437 6.65437 4.005 6.435C4.00313 6.3675 4.005 6.33375 4.005 6.33C4.005 4.99687 5.07187 3.93 6.405 3.93C7.0725 3.93 7.68 4.19812 8.115 4.635C8.22937 4.74562 8.3925 4.79437 8.54813 4.76062C8.70562 4.72687 8.835 4.61812 8.895 4.47C9.65063 2.43 11.6119 0.96 13.92 0.96C16.8825 0.96 19.275 3.35437 19.275 6.315C19.275 6.53437 19.2581 6.75 19.23 6.975C19.2131 7.11 19.2544 7.245 19.3425 7.34812C19.4306 7.45125 19.56 7.51125 19.695 7.515H19.725C21.5606 7.515 23.04 8.99437 23.04 10.83C23.04 12.6656 21.5606 14.145 19.725 14.145H15.36C15.1875 14.1431 15.0262 14.2331 14.9381 14.3831C14.8519 14.5331 14.8519 14.7169 14.9381 14.8669C15.0262 15.0169 15.1875 15.1069 15.36 15.105H19.725C22.08 15.105 24 13.185 24 10.83C24 8.64562 22.3237 6.90937 20.205 6.66C20.2125 6.54375 20.235 6.435 20.235 6.315C20.235 2.835 17.4 0 13.92 0ZM12 8.19L11.655 8.52L9.255 10.92C9.06375 11.1112 9.06375 11.4187 9.255 11.61C9.44625 11.8012 9.75375 11.8012 9.945 11.61L11.52 10.035V18.465C11.5181 18.6375 11.6081 18.7987 11.7581 18.8869C11.9081 18.9731 12.0919 18.9731 12.2419 18.8869C12.3919 18.7987 12.4819 18.6375 12.48 18.465V10.035L14.055 11.61C14.2463 11.8012 14.5538 11.8012 14.745 11.61C14.9362 11.4187 14.9362 11.1112 14.745 10.92L12.345 8.52L12 8.19Z"
            />
        </SvgIcon>
    );
}

UploadIcon.defaultProps = {
    titleAccess: 'upload-icon',
};

UploadIcon.propTypes = svgIconPropTypes;
