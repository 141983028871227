import { ResponseModel } from 'reducerConfigurator/responseModel/responseModel';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { toIdMap } from 'utils/immutable/list';

export class SaleResponse extends ResponseModel {
    constructor(props = {}) {
        super(props);

        const {
            data = {},
        } = props;

        this.item = data;
        this.results = data['hydra:member'] || [];
        this.total = data['hydra:totalItems'] || 1;
    }

    static getListRequestParams({ defaults: requestDefaults = {}, urlParams = {} }) {
        const {
            pagination = {},
            filters = {},
            order = {},
        } = urlParams;
        const defaults = {
            page: 1,
            per_page: 20,
            order: { ['updatedAt']: 'desc' },
            ...requestDefaults,
        };
        const params = {
            page: pagination.page || defaults.page,
            itemsPerPage: pagination.per_page || defaults.per_page,
            search: defaults.search,
            order: isEmpty(order) ? defaults.order : order
        };

        Object.keys(filters).forEach((key) => {
            switch (key) {
                case 'createdAt':
                case 'purchaseDate':
                case 'updatedAt': {
                    const filter = filters[key];
                    const mappedFilters = {};

                    Object.keys(filter).forEach((variant) => {
                        let value = filter[variant] * 1000;

                        if (variant === 'before') {
                            value = value + 1000;
                        }
                        let date = new Date(parseInt(String(value)));

                        set(mappedFilters, `${variant}`, date.toLocaleString());
                    });
                    set(params, key, mappedFilters);
                    params.order = isEmpty(order) ? { ['updatedAt']: 'desc' } : order;
                    break;
                }
                case 'search': {
                    params.search = filters.search;
                    break;
                }
                case 'channel': {
                    params['channel.name'] = filters[key];
                    break;
                }
                default: {
                    set(params, key, filters[key]);
                    break;
                }
            }
        });

        return params;
    }

    getTotalResults() {
        return this.total;
    }

    getResultsById(model) {
        return toIdMap(this.results, model);
    }

    getItemFromResponse(model) {
        return new model(this.item);
    }
}
