import { isUndefined } from 'lodash';

export const getEnhancedProductSet = async ({ productSet, intermediateProductFetcher }) => {
    let enhancedProducts = [];

    for (const product of productSet.products) {
        const cachedProduct = enhancedProducts.find((enhancedProduct) => {
            return enhancedProduct?.intermediateProductId === product.intermediateProductId;
        });

        let enhancedProduct = null;

        if (cachedProduct) {
            enhancedProduct = Object.assign(product, {
                technology: cachedProduct?.technology,
                parts: cachedProduct?.parts,
                height: cachedProduct?.height,
                width: cachedProduct?.width,
            });
        } else {
            const intermediateProduct =
                await intermediateProductFetcher(product.intermediateProductId);

            enhancedProduct = Object.assign(product, {
                technology: intermediateProduct?.technology?.name,
                parts: intermediateProduct?.parts,
                height: intermediateProduct?.height,
                width: intermediateProduct?.width,
            });
        }

        enhancedProducts.push(enhancedProduct);
    }

    return Object.assign(productSet, {
        products: enhancedProducts
    });
};

export const getBoxGroupFilters = ({ appliedFilters, availableFilters } = {}) => {
    const filterKeys = Object.keys(appliedFilters);

    return {
        filter: availableFilters.reduce((parsedFilters, { key, operator }) => {
            const filterValue = appliedFilters[key];
            const isFilter = filterKeys.includes(key) && !isUndefined(filterValue) && filterValue !== '';

            if (!isFilter) {
                return parsedFilters;
            }

            return {
                ...parsedFilters,
                [key]: {
                    [operator]: filterValue
                }
            };
        }, {})
    };
};

export const getPartsFilterOptions = (t) => [
    {
        label: t('labels.intermediateProductsFilters.none'),
        value: '',
    },
    ...[...Array(20).keys()].map((i) => ({ label: (i + 1).toString(10), value: i + 1 })),
];

export const getRawMaterialFilterOptions = (t, newAutocomplete = false) => {
    const options = [
        {
            label: t('labels.intermediateProductsFilters.isRawMaterial'),
            value: 'true',
        },
        {
            label: t('labels.intermediateProductsFilters.isNotRawMaterial'),
            value: 'false',
        }
    ];

    if (!newAutocomplete) {
        options.unshift({
            label: t('labels.intermediateProductsFilters.none'),
            value: '',
        });
    }

    return options;
};

export const getExternalProducerFilterOptions = (t, newAutocomplete = false) => {
    const options = [
        {
            label: t('labels.technologiesFilters.isExternalProducer'),
            value: 'true',
        },
        {
            label: t('labels.technologiesFilters.isNotExternalProducer'),
            value: 'false',
        }
    ];

    if (!newAutocomplete) {
        options.unshift({
            label: t('labels.technologiesFilters.none'),
            value: '',
        });
    }

    return options;
};
