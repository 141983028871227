import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

/*
    PRINT_SPEC
 */

export const ROLE_PRINT_SPEC_READ = {
    section: S.ARM_PRINT_SPEC,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PRINT_SPEC]: ACCEPT,
        [A.ARM.RESOURCES.PRINT_SPEC.FETCH]: ACCEPT,
    },
};

export const ROLE_PRINT_SPEC_CREATE = {
    section: S.ARM_PRINT_SPEC,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PRINT_SPEC_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.PRINT_SPEC.CREATE]: ACCEPT,
    },
};

export const ROLE_PRINT_SPEC_UPDATE = {
    section: S.ARM_PRINT_SPEC,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PRINT_SPEC_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.PRINT_SPEC.EDIT]: ACCEPT,
        [A.ARM.RESOURCES.PRINT_SPEC.DELETE]: ACCEPT,
    },
};

export const ROLE_PRINT_SPEC_FILE_DELETE = {
    section: S.ARM_PRINT_SPEC,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PRINT_SPEC_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.PRINT_SPEC_FILE.DELETE]: ACCEPT,
    },
};

