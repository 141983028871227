import Typography from 'components/Typography';
import styled from '@emotion/styled';
import Paper from 'components/Paper/paper';

export const RootContainerStyled = styled('div')`
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
  position: fixed;
  inset: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: ${({ theme }) => theme.palette.background.entryPage};
`;
RootContainerStyled.displayName = 'RootContainer';

export const TitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;
TitleStyled.displayName = 'Title';

export const ContentCardStyled = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-top: ${({ theme }) => theme.spacing(8)};
  position: fixed;
  inset: 0;
  
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(8)};
    position: relative;
    width: 456px;
  }
`;
ContentCardStyled.displayName = 'ContentCard';
