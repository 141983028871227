import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import FilesUploadService from './FilesUploadService';
import { DEFAULT_UPLOAD_CONFIG } from './configs';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';

const plugins = {
    Tus,
};

export const filesUploadFactory = (
    config,
    authenticationService: AuthenticationService,
) => {
    const { UPPY: uppyConfig = {}, TUS: tusConfig = {} } = config;

    return new FilesUploadService(
        Uppy,
        {
            uppy: {
                ...DEFAULT_UPLOAD_CONFIG.UPPY,
                ...uppyConfig,
            },
            tus: {
                ...DEFAULT_UPLOAD_CONFIG.TUS,
                ...tusConfig,
            },
        },
        plugins,
        authenticationService,
    );
};
