const get = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;
    const alert = {
        message: 'An unknown error has occurred',
    };

    if (status === 500 && data.detail) {
        alert.message = data.detail;
    }
    alertService.addError(alert);
    throw error;
};

const errorHandlers = (alertService) => ({
    get: get(alertService),
    post: get(alertService),
});

export default errorHandlers;
