import qs from 'qs';

import type {
    IPagedResponse,
    IServiceMethodParams,
} from '../../../../types/services.type';
import type { AjaxService } from '../../../ajax/AjaxService';
import { ApplicationJsonHeaders } from '../../../constants';
import type { URLS } from '../../constants';
import type { default as errorHandlersFactory } from '../../errorHandlers';
import type { IGetProductionOrdersPrintDTO } from './workers.type';
import { mapProductionOrderGetListWorkersOrderParams } from '../../mappers/orders';

export class WorkersActions {
    private static instance: WorkersActions | undefined;

    constructor(
        private readonly ajaxService: AjaxService,
        private readonly urls: typeof URLS,
        private readonly errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ) {
        this.getProductionOrdersPrint =
            this.getProductionOrdersPrint.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        urls: typeof URLS,
        errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ): WorkersActions {
        if (!WorkersActions.instance) {
            this.instance = new WorkersActions(
                ajaxService,
                urls,
                errorHandlers,
            );
        }

        return this.instance;
    }

    async getProductionOrdersPrint({
        page: pageParam,
        per_page: itemsPerPageParam,
        sort,
    }: IServiceMethodParams<never, never>): Promise<
        IPagedResponse<IGetProductionOrdersPrintDTO>
    > {
        const { data } = await this.ajaxService.get({
            url: this.urls.GET_PRODUCTION_ORDERS_PRINT,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...(sort
                        ? {
                              order: mapProductionOrderGetListWorkersOrderParams(
                                  sort,
                              ),
                          }
                        : {}),
                    page: pageParam,
                    itemsPerPage: itemsPerPageParam,
                },
                paramsSerializer: (queryParams) => qs.stringify(queryParams),
            },
            errorConfig: { throwError: this.errorHandlers.get },
            onError: this.errorHandlers.get,
        });

        return data;
    }
}
