import { makeAllRolesVote } from '../../access/votingMechanism';
import * as Sentry from '@sentry/react';

const STORAGE_TOKEN_KEY = 'token';
const STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';

interface IToken {
    id: string;
    roles: string[];
    email: string;
    username: string;
    exp: number;
}

export class AuthenticationService {
    private listeners: Record<
        string,
        (token: string, instance: AuthenticationService) => void
    > = {};

    private constructor(
        private readonly storage: Storage,
        private readonly decodeToken: (token: string) => IToken,
    ) {
        this.subscribeListener = this.subscribeListener.bind(this);
        this.getActiveToken = this.getActiveToken.bind(this);
    }

    private static instance: AuthenticationService | undefined;

    public static getInstance(
        storage: Storage,
        decodeToken: (token: string) => IToken,
    ): AuthenticationService {
        if (!AuthenticationService.instance) {
            AuthenticationService.instance = new AuthenticationService(
                storage,
                decodeToken,
            );
        }

        return AuthenticationService.instance;
    }

    subscribeListener(listenerName, listener) {
        if (!this.listeners[listenerName]) {
            this.listeners[listenerName] = listener;
        }
    }

    setActiveToken(token) {
        const decodedToken = this.decodeToken(token);

        if (decodedToken.roles) {
            makeAllRolesVote(decodedToken.roles);

            Sentry.setUser({
                id: decodedToken.id,
                email: decodedToken.email,
                username: decodedToken.username,
                language: this.storage.getItem('i18nextLng'),
            });
        }

        this.storage.setItem(STORAGE_TOKEN_KEY, token);

        Object.values(this.listeners).forEach((listener) => {
            listener(token, this);
        });
    }

    getActiveToken(): IToken | {} {
        try {
            return this.decodeToken(this.storage.getItem(STORAGE_TOKEN_KEY));
        } catch (e) {
            return {};
        }
    }

    getRawActiveToken() {
        return this.storage.getItem(STORAGE_TOKEN_KEY);
    }

    removeActiveToken() {
        this.storage.removeItem(STORAGE_TOKEN_KEY);
    }

    setRefreshToken(token) {
        this.storage.setItem(STORAGE_REFRESH_TOKEN_KEY, token);
    }

    getRefreshToken() {
        return this.storage.getItem(STORAGE_REFRESH_TOKEN_KEY);
    }

    removeRefreshToken() {
        this.storage.removeItem(STORAGE_REFRESH_TOKEN_KEY);
    }

    isTokenExpire() {
        const token = this.getActiveToken();

        if (token && token.exp > 0) {
            const current_time = new Date().getTime() / 1000;

            return current_time > token.exp;
        } else {
            return true;
        }
    }
}
