import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const simpleStyles = css`
    position: initial;
    width: 100%;
    background: initial;
`;

export const fullScreenStyles = css`
    position: fixed;
`;

export const noMarginsStyles = css`
    position: initial;
    width: 100%;
    background: initial;
`;

export const LoaderContainerStyled = styled('div')<{
    simple?: boolean | undefined;
    noMargins?: boolean | undefined;
    fullScreen?: boolean | undefined;
}>`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: ${({ theme }) => theme.zIndex.modal + 1};

    ${({ simple }) => simple && simpleStyles};
    ${({ noMargins }) => noMargins && noMarginsStyles};
    ${({ fullScreen }) => fullScreen && fullScreenStyles};
`;
LoaderContainerStyled.displayName = 'LoaderContainer';
