import { omitNegative } from '../../../utils/filters';
import { ApplicationJsonHeaders } from '../../constants';
import {
    IGetPrintersData,
    IGetPrintersResponse,
    IPrinterContext,
    IPrinterServices,
} from './printer.type';

export class Printer {
    private services: IPrinterServices;
    private URLS: IPrinterContext['URLS'];

    constructor(services: IPrinterServices, { URLS }: IPrinterContext) {
        this.services = services;
        this.URLS = URLS;

        this.getPrinters = this.getPrinters.bind(this);
    }

    async getPrinters({
        filters = {},
        per_page = 200,
        page = 1,
    }: IGetPrintersData | undefined = {}): Promise<IGetPrintersResponse> {
        const {
            URLS: { GET_PRINTERS },
            services: { ajax },
        } = this;

        const { data } = await ajax.get({
            url: GET_PRINTERS,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...omitNegative({
                        status: filters?.status,
                        type: filters?.type,
                    }),
                    page,
                    itemsPerPage: per_page,
                    code: filters?.search,
                },
            },
        });

        return data;
    }
}
