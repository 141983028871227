import get from 'lodash/get';

const CAMUNDA_URL = get(window, 'env.CAMUNDA_API_URL', '');
const DICTIONARIES_API_URL = get(window, 'env.DICTIONARIES_API_URL', '');

export const URLS = {
    GET_TASK_LIST: `${CAMUNDA_URL}/api/tasks`,
    GET_TASK: `${CAMUNDA_URL}/engine-rest/task/{id}`,
    SEND_TASK: `${CAMUNDA_URL}/api/tasks/{id}/submit_form`,
    GET_CUSTOM_FIELDS: `${CAMUNDA_URL}/api/variable_instances`,
    GET_ACTIVE_TASKS_COUNTS: `${CAMUNDA_URL}/api/active_tasks_counts`,
    GET_DICTIONARY_CONTROLS: `${DICTIONARIES_API_URL}/api/dictionaries/{code}`,

    CUSTOMER_REQUESTS: `${CAMUNDA_URL}/api/history/process_instances`,
    CUSTOMER_REQUESTS_VARIABLE: `${CAMUNDA_URL}/api/history/variable_instances`,

    GET_CURRENT_TASKS: `${CAMUNDA_URL}/api/process_instances/{id}/current_tasks`,
};

export const DEFAULTS = {
    CACHE_TIME: 6000, // 1 min
    PER_PAGE: 20,
};
