import styled from '@emotion/styled';
import Paper from 'components/Paper/paper';
import { css } from '@emotion/react';
import Button from 'components/Button/button';
import { excludePropForward } from 'utils/styles';

const flatStyles = ({ theme }) => css`
  background: ${theme.palette.grey[100]};
`;

const activeStyles = ({ theme }) => css`
  border-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
`;

export const NavButtonStyled = styled('div')`
  display: flex;
  align-items: center;
`;
NavButtonStyled.displayName = 'NavButton';

export const RootStyled = styled(Paper, excludePropForward('flatStyle'))`
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${({ flatStyle }) => flatStyle && flatStyles};
`;
RootStyled.displayName = 'Root';

export const TileStyled = styled(Button, excludePropForward('active'))`
  min-width: 32px;
  color: ${({ theme }) => theme.palette.common.gray.dark};
  
  ${({ active }) => active && activeStyles};
`;
TileStyled.displayName = 'Tile';
