import { useEffect, useRef, useState } from 'react';
import type {
    BaseAutocompleteOption,
    FetchAutocompleteProps,
} from './fetchAutocomplete.type';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';

export const useFetchAutocompleteOptions = <T extends BaseAutocompleteOption>(
    id: FetchAutocompleteProps<T>['id'],
    value: unknown,
    fetchOptions: FetchAutocompleteProps<T>['fetchOptions'],
    gcTime?: FetchAutocompleteProps<T>['gcTime'],
    staleTime?: FetchAutocompleteProps<T>['staleTime'],
    cacheKey?: FetchAutocompleteProps<T>['cacheKey'],
    updateOnValueChange?: FetchAutocompleteProps<T>['updateOnValueChange'],
    keepPreviousOptions?: FetchAutocompleteProps<T>['keepPreviousOptions'],
    multiple?: FetchAutocompleteProps<T>['multiple'],
) => {
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState<T[]>([]);
    const optionsMap = useRef(new Map<string, T>());

    const queryKey = ['fetchAutocomplete', cacheKey ?? id, search];

    const { isFetching, data: localOptions } = useQuery({
        queryKey: updateOnValueChange ? [...queryKey, value] : queryKey,
        queryFn: () => fetchOptions(search),
        initialData: [],
        initialDataUpdatedAt: staleTime,
        placeholderData: keepPreviousOptions ? keepPreviousData : undefined,
        gcTime,
        staleTime,
    });

    useEffect(() => {
        if (keepPreviousOptions) {
            setOptions((previousOptions) =>
                uniqBy([...previousOptions, ...localOptions], 'value'),
            );
        } else {
            setOptions(uniqBy(localOptions, 'value'));
        }
    }, [localOptions, keepPreviousOptions]);

    useEffect(() => {
        if (multiple) {
            options.forEach((option) => {
                optionsMap.current.set(String(option.value), option);
            });
        }
    }, [options, multiple]);

    return { setSearch, options, isFetching, optionsMap };
};
