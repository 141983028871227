import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function LockIcon(props) {
    return (
        <SvgIcon
            viewBox="-5 -3 24 24"
            { ...props }
        >
            <path
                d="M7.39136 0.0800171C4.89826 0.0800171 2.88353 2.0108 2.88353 4.40002V6.20002H1.38092C0.742608 6.20002 0.253967 6.6683 0.253967 7.28002V15.92C0.253967 16.5317 0.742608 17 1.38092 17H13.4018C14.0401 17 14.5287 16.5317 14.5287 15.92V7.28002C14.5287 6.6683 14.0401 6.20002 13.4018 6.20002H11.8992V4.40002C11.8992 2.0108 9.88446 0.0800171 7.39136 0.0800171ZM7.39136 0.800017C9.48239 0.800017 11.1479 2.39611 11.1479 4.40002V6.20002H3.63484V4.40002C3.63484 2.39611 5.30033 0.800017 7.39136 0.800017ZM7.39136 9.80002C8.02967 9.80002 8.51832 10.2683 8.51832 10.88C8.51832 11.2035 8.36864 11.4875 8.14266 11.6675V12.68C8.14266 13.0766 7.80516 13.4 7.39136 13.4C6.97755 13.4 6.64005 13.0766 6.64005 12.68V11.6675C6.41408 11.4875 6.2644 11.2035 6.2644 10.88C6.2644 10.2683 6.75304 9.80002 7.39136 9.80002Z"
            />
        </SvgIcon>
    );
}

LockIcon.defaultProps = {
    titleAccess: 'lock-icon',
};

LockIcon.propTypes = svgIconPropTypes;
