import type { Printer } from '../../services/workplaces/actions/Printer';
import type { Workplace } from '../../services/workplaces/actions/Workplace';
import { IGetWorkplacesData } from '../../services/workplaces/actions/workplace.type';

export const mapGetWorkplacesToAutocomplete = async (
    getWorkplaces: Workplace['getWorkplaces'],
    filters?: IGetWorkplacesData['filters'] | undefined,
) => {
    const data = await getWorkplaces({ filters });

    return data.items.map(({ id, label }) => ({ value: id, label }));
};

export const mapGetActivePrintMachine = async (
    getPrinter: Printer['getPrinters'],
) => {
    const data = await getPrinter({
        filters: {
            type: 'print_machine',
            status: 'active',
        },
    });

    return data.results.map(({ id, label }) => ({ value: id, label }));
};
