const MODULE_NAME = 'router';

export const getPath = (state) => {
    return state
        .getIn([MODULE_NAME, 'location', 'pathname']);
};

export const getSearch = (state) => {
    return state
        .getIn([MODULE_NAME, 'location', 'search']).substr(1);
};

export const getHash = (state) => {
    return state
        .getIn([MODULE_NAME, 'location', 'hash']).substr(1);
};
