import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ImageIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -1 16 16"
            { ...props }
        >
            <path
                d="M0 0.599976V13.4H16V0.599976H0ZM0.64 1.23998H15.36V9.55998H11.9425L9.7025 7.95998H7.8125L6.505 6.65248L4.9475 6.96498L3.57 4.89748L0.64 7.82748V1.23998ZM12 3.15998C11.2087 3.15998 10.56 3.80873 10.56 4.59998C10.56 5.39123 11.2087 6.03998 12 6.03998C12.7912 6.03998 13.44 5.39123 13.44 4.59998C13.44 3.80873 12.7912 3.15998 12 3.15998ZM12 3.79998C12.445 3.79998 12.8 4.15498 12.8 4.59998C12.8 5.04498 12.445 5.39998 12 5.39998C11.555 5.39998 11.2 5.04498 11.2 4.59998C11.2 4.15498 11.555 3.79998 12 3.79998ZM3.47 5.90248L4.6525 7.67498L6.295 7.34748L7.5475 8.59998H9.4975L11.7375 10.2H15.36V12.76H0.64V8.73248L3.47 5.90248Z"
            />
        </SvgIcon>
    );
}

ImageIcon.defaultProps = {
    titleAccess: 'image-icon',
};

ImageIcon.propTypes = svgIconPropTypes;
