import {
    mapCategoryDefinitionFromAPI,
    mapCategoryDefinitionToAPI,
    mapIntermediateProductsToAPICategoryDefinitionUpdate,
} from 'services/salesChannels/mappers/categoryDefinition';
import { HttpPatchHeaders } from 'services/constants';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';
import type { SalesChannelsService } from '../SalesChannelsService';

export class CategoryDefinition {
    private readonly asyncRequestPaginator: AsyncRequestPaginator<{
        id: string;
        name: string;
    }>;

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.updateIntermediateProducts =
            this.updateIntermediateProducts.bind(this);
        this.getMore = this.getMore.bind(this);
        this.getAll = this.getAll.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator<{
            id: string;
            name: string;
        }>(this.getMore, mapCategoryDefinitionFromAPI);
    }

    private static buildURL(url: string, channelId = '', id = '') {
        return url.replace('{channelId}', channelId).replace('{id}', id);
    }

    async getById(
        channelId: string,
        id: string,
    ): Promise<{ id: string; name: string }> {
        const { ajax, errorHandlers, HEADERS, URLS } =
            this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.GET_CHANNEL_CATEGORY_DEFINITION,
            channelId,
            id,
        );

        const config = {
            headers: HEADERS.basic,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        const { data } = await ajax.get({ url, config, errorConfig });

        return mapCategoryDefinitionFromAPI(data);
    }

    /**
     * @internal
     */
    async getMore(params) {
        const {
            ajax,
            errorHandlers,
            HEADERS,
            DEFAULTS,
            paramsSerializer,
            URLS,
        } = this.salesChannelsService;
        const channelId = params['channelId'];
        const url = CategoryDefinition.buildURL(
            URLS.GET_CHANNEL_CATEGORY_DEFINITIONS,
            channelId,
        );
        const {
            page = 1,
            per_page = DEFAULTS.CATEGORY_DEFINITIONS_PER_PAGE,
            order,
            sort,
        } = params;

        const config = {
            headers: HEADERS.basic,
            params: { page, itemsPerPage: per_page, sort: order || sort || {} },
            paramsSerializer,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        return ajax.get({ url, config, errorConfig });
    }

    getAll(
        channelId: string,
        params = {},
    ): Promise<{ totalItems: number; items: { id: string; name: string }[] }> {
        return this.asyncRequestPaginator.fetchFullList({
            ...params,
            channelId,
        });
    }

    async getList(
        channelId: string,
        params: {
            page?: undefined | number;
            per_page?: undefined | number;
            order?: undefined | Record<string, unknown>;
            sort?: undefined | Record<string, unknown>;
        },
    ): Promise<{ totalItems: number; items: { id: string; name: string }[] }> {
        const {
            ajax,
            errorHandlers,
            HEADERS,
            DEFAULTS,
            paramsSerializer,
            URLS,
        } = this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.GET_CHANNEL_CATEGORY_DEFINITIONS,
            channelId,
        );
        const {
            page = 1,
            per_page = DEFAULTS.CATEGORY_DEFINITIONS_PER_PAGE,
            order,
            sort,
        } = params;

        const config = {
            headers: HEADERS.basic,
            params: { page, itemsPerPage: per_page, sort: order || sort || {} },
            paramsSerializer,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.get,
        };

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            totalItems: data?.total || 0,
            items: (data?.results || []).map(mapCategoryDefinitionFromAPI),
        };
    }

    async edit(
        channelId: string,
        id: string,
        patchData: { name: string },
    ): Promise<{ name: string; id: string }> {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.PATCH_CHANNEL_CATEGORY_DEFINITION,
            channelId,
            id,
        );

        const config = {
            headers: HttpPatchHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.patch,
        };

        const { data } = await ajax.patch({
            url,
            config,
            data: mapCategoryDefinitionToAPI(patchData),
            errorConfig,
        });

        return mapCategoryDefinitionFromAPI(data);
    }

    async create(
        channelId: string,
        createData: { name: string },
    ): Promise<{ name: string; id: string }> {
        const { ajax, errorHandlers, HEADERS, URLS } =
            this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.POST_CHANNEL_CATEGORY_DEFINITION,
            channelId,
        );

        const config = {
            headers: HEADERS.basic,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
        };

        const { data } = await ajax.post({
            url,
            config,
            data: mapCategoryDefinitionToAPI(createData),
            errorConfig,
        });

        return mapCategoryDefinitionFromAPI(data);
    }

    async delete(channelId: string, id: string) {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.DELETE_CHANNEL_CATEGORY_DEFINITION,
            channelId,
            id,
        );

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.delete,
        };

        await ajax.delete({ url, errorConfig });
    }

    async updateIntermediateProducts(
        id: string,
        products: Record<string, unknown>[],
    ) {
        const { ajax, errorHandlers, HEADERS, URLS } =
            this.salesChannelsService;
        const url = CategoryDefinition.buildURL(
            URLS.PUT_CATEGORY_DEFINITION_OFFER_INTERMEDIATE_PRODUCTS,
            '',
            id,
        );

        const data = {
            offer_intermediate_products: products.map(
                mapIntermediateProductsToAPICategoryDefinitionUpdate,
            ),
        };

        const config = {
            headers: HEADERS.basic,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.put,
        };

        await ajax.put({ url, config, data, errorConfig });
    }
}
