class ProductionOrders {
    constructor(stabloService, CONSTANTS) {
        this.ajax = stabloService.ajaxService;
        this.errorHandlers = stabloService.errorHandlers;
        this.URLS = CONSTANTS.URLS;
        this.HEADERS = CONSTANTS.HEADERS;

        this.fetchCarrier = this.fetchCarrier.bind(this);
        this.getProductionOrderStatus =
            this.getProductionOrderStatus.bind(this);
    }

    fetchCarrier(id) {
        const ajax = this.ajax;
        const url = this.URLS.FETCH_PRODUCTION_ORDER_CARRIER.replace(
            '{id}',
            id,
        );

        return ajax
            .get({
                url,
                config: { headers: this.HEADERS.basic },
                errorConfig: { throwError: this.errorHandlers.get },
            })
            .then((response) => response.data);
    }

    getProductionOrderStatus(id) {
        const ajax = this.ajax;
        const url = this.URLS.GET_PRODUCTION_ORDER_STATUS.replace('{id}', id);

        return ajax
            .get({
                url,
                config: { headers: this.HEADERS.basic },
                errorConfig: { throwError: this.errorHandlers.get },
            })
            .then((response) => response.data);
    }
}

export default ProductionOrders;
