import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { GlobeIcon } from 'components/Icons/Icons';

const PatternsTableViewContainer = lazy(() => import('modules/translations/containers/PatternsTableViewContainer'));
const PatternsDetailsPage = lazy(() => import('../pages/PatternsDetailsPage'));

export const translationsRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            name: 'patterns',
            path: PATHS.PATTERNS,
            component: PatternsTableViewContainer,
        },
        {
            exact: true,
            name: null,
            path: PATHS.PATTERNS_DETAILS,
            component: PatternsDetailsPage,
        },
    ],
    icon: GlobeIcon
};
