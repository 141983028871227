import { MODULE_NAME } from '../constants';

export const SOURCES = `/${ MODULE_NAME }/sources`;
export const SOURCES_DETAILS = `/${ MODULE_NAME }/sources/:id`;

export const AUTHORS = `/${ MODULE_NAME }/authors`;
export const AUTHORS_DETAILS = `/${ MODULE_NAME }/authors/:id`;

export const PATTERNS = `/${ MODULE_NAME }/patterns`;
export const PATTERNS_DETAILS = `/${ MODULE_NAME }/patterns/:id`;

export const PREVIEWS = `/${ MODULE_NAME }/previews`;

export const PREVIEW_TYPES = `/${ MODULE_NAME }/previewTypes`;
export const PREVIEW_TYPES_DETAILS = `/${ MODULE_NAME }/previewTypes/:id`;

export const CATEGORIES = `/${ MODULE_NAME }/categories`;
export const CATEGORIES_DETAILS = `/${ MODULE_NAME }/categories/:id`;

export const BASE_PATTERNS = `/${ MODULE_NAME }/basePatterns`;
export const BASE_PATTERNS_DETAILS = `/${ MODULE_NAME }/basePatterns/:code`;
