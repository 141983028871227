import get from 'lodash/get';

const SKU_APT_URL = get(window, 'env.SKU_API_URL', '');
const SKU_GENERATOR_BASE_URL = `${ SKU_APT_URL }/api/v1/sku-generator`;

export const URLS = {
    PREVIEW_SKU_GENERATOR: `${SKU_GENERATOR_BASE_URL}/product-previews/{id}`,
    CREATE_SKU_GENERATOR: `${SKU_GENERATOR_BASE_URL}/generators`,
    GET_SKU_GENERATOR: `${SKU_GENERATOR_BASE_URL}/generators/{id}`,
};

export const DEFAULTS = {};
