import { NotificationCenterService } from 'services/notificationCenter/notificationCenterService';
import { errorHandlers } from './errorHandlers';
import { URLS } from 'services/notificationCenter/constants';
import parser from 'qs';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const notificationCenterServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    NotificationCenterService.getInstance(
        ajaxService,
        errorHandlers(alertService),
        URLS,
        parser,
    );
