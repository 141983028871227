import PropTypes from 'prop-types';

export const optionProp = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const autocompleteBaseProps = {
    id: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    handleBlur: PropTypes.func,
    options: PropTypes.arrayOf(optionProp),
    value: PropTypes.oneOfType([
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
    ]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    disableClearable: PropTypes.bool,
    multiple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    margin: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
    maxLength: PropTypes.number,
    groupBy: PropTypes.func,
    isOptionEqualToValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    filterOptions: PropTypes.func,
    renderTags: PropTypes.func,
    customRenderOptionLabel: PropTypes.func,
};
