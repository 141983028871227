import { get } from 'lodash';

import {
    mapOfferProductFromApiV2,
    mapOfferProductsToApi,
} from '../mappers/offerProduct';
import {
    OFFER_PRODUCT_STATUSES,
    OFFER_PRODUCTS_FILTER_MAP,
} from '../constants';
import { ApplicationJsonHeaders, HttpPatchHeaders } from '../../constants';
import { configError } from 'services/utils/utils';
import type { SalesChannelsService } from '../SalesChannelsService';

class OfferProducts {
    private readonly params: SalesChannelsService['OFFER_PRODUCTS_PARAMS'];
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.params = salesChannelsService.OFFER_PRODUCTS_PARAMS;
        this.errorHandlers = salesChannelsService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.archive = this.archive.bind(this);
        this.edit = this.edit.bind(this);
        this.uploadFromCsv = this.uploadFromCsv.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.generateGtin = this.generateGtin.bind(this);
    }

    private static mapFilters(filters = {}) {
        return Object.entries(filters).reduce((parsedFilters, [key, value]) => {
            if (['width', 'height'].includes(key)) {
                value = Number(value) * 10;
            }

            return {
                ...parsedFilters,
                [OFFER_PRODUCTS_FILTER_MAP[key]]: value,
            };
        }, {});
    }

    async getList({
        id,
        params = {},
    }: {
        id: string;
        params?:
            | {
                  page?: number | undefined;
                  pageSize?: number | undefined;
                  order?: string | undefined;
                  filters?: Record<string, unknown> | undefined;
              }
            | undefined;
    }) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            getSortParams,
            getFilterParams,
            paramsSerializer,
        } = this.salesChannelsService;
        const { SORTING_KEYS, FILTERS_KEYS } = this.params;

        const {
            page = 1,
            pageSize = DEFAULTS.OFFERS_PER_PAGE,
            order: orderFromUrl,
            filters: filtersFromUrl,
        } = params;

        const url = URLS.GET_OFFER_PRODUCTS_V2.replace('{id}', id);
        const errorConfig = configError('get', this.errorHandlers);
        const filters = getFilterParams(FILTERS_KEYS, filtersFromUrl);
        const sort = getSortParams(SORTING_KEYS, orderFromUrl, true);
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page,
                itemsPerPage: pageSize,
                ...(sort && { order: sort }),
            },
            paramsSerializer,
        };

        if (filters && filters.product_pattern_code)
            config.params['product.pattern.code'] =
                filters.product_pattern_code;
        if (filters && filters.intermediate_product_id)
            config.params['product.intermediate_product.id'] =
                filters.intermediate_product_id;
        if (filters && filters.technology_id)
            config.params['product.intermediate_product.technology.id'] =
                filters.technology_id;
        if (filters && filters.width)
            config.params['product.intermediate_product.width'] =
                Number(filters.width) * 10;
        if (filters && filters.height)
            config.params['product.intermediate_product.height'] =
                Number(filters.height) * 10;
        if (filters && filters.sku) config.params['sku'] = filters.sku;
        if (filters && filters.ean && !filters.noEan)
            config.params['product.gtin.id'] = filters.ean;
        if (filters && filters.noEan) config.params['product.gtin.id'] = '';
        config.params['status'] = OFFER_PRODUCT_STATUSES.ACTIVE;
        if (filters && filters.productId)
            config.params['product.id'] = filters.productId;

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            items: get(data, 'results', []).map((offerItem) =>
                mapOfferProductFromApiV2(offerItem),
            ),
            totalItems: get(data, 'total', 0),
        };
    }

    async archive(id: string, payload: { reason: string; note?: string }) {
        const { ajax, URLS, translator, alert } = this.salesChannelsService;

        const url = URLS.PATCH_OFFER_PRODUCT.replace('{id}', id);
        const errorConfig = configError('patch', this.errorHandlers);
        const config = {
            headers: HttpPatchHeaders,
        };

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: payload,
        });

        alert.addSuccess({
            message: translator.t('arm:alerts.productArchived'),
        });

        return response;
    }

    async edit({ offerId, productIds }) {
        const { ajax, URLS, translator, alert } = this.salesChannelsService;

        const url = URLS.GET_OFFER_PRODUCTS.replace('{id}', offerId);
        const errorConfig = configError('patch', this.errorHandlers);
        const config = {
            headers: HttpPatchHeaders,
        };

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: mapOfferProductsToApi(productIds),
        });

        alert.addSuccess({
            message: translator.t('arm:alerts.productsAdded'),
        });

        return response;
    }

    private getUploadFromCsvURL(type: 'offerProduct' | 'verifyUsingSku') {
        const {
            salesChannelsService: { URLS },
        } = this;

        switch (type) {
            case 'offerProduct':
                return URLS.POST_UPLOAD_OFFER_PRODUCTS;
            case 'verifyUsingSku':
                return URLS.POST_VERIFY_OFFER_USING_SKU;

            default:
                return '';
        }
    }

    async uploadFromCsv(
        { file, offerId }: { file: Blob; offerId: string },
        type: 'offerProduct' | 'verifyUsingSku' = 'offerProduct',
    ) {
        const {
            salesChannelsService: {
                ajax,
                translator,
                alert,
                mediaFiles: { uploadCustomFiles },
            },
        } = this;

        const { id: uploadedFileId } = await uploadCustomFiles(file);

        const { data } = await ajax.post({
            url: this.getUploadFromCsvURL(type).replace('{id}', offerId),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('post', this.errorHandlers),
            data: {
                media_file: {
                    id: `custom-files/${uploadedFileId}`,
                },
                separator: ',',
                enclosure: '"',
            },
        });

        alert.addSuccess({
            message: translator.t(
                'manageOffers:alerts.fileUploadedSuccessfully',
            ),
        });

        return data;
    }

    async exportToCsv({ offerId, params }) {
        const { ajax, URLS, translator, alert } = this.salesChannelsService;
        const { filters } = params;

        const url = URLS.POST_EXPORT_OFFER_PRODUCTS.replace('{id}', offerId);
        const errorConfig = configError('post', this.errorHandlers);
        const config = { headers: ApplicationJsonHeaders };

        const mappedFilters = OfferProducts.mapFilters(filters);

        const data = {
            filters: mappedFilters,
            separator: ';',
            enclosure: '"',
        };

        const response = await ajax.post({ url, data, config, errorConfig });

        alert.addSuccess({
            message: translator.t(
                'manageOffers:alerts.productsExportedSuccessfully',
            ),
        });

        return response;
    }

    async generateGtin(offerId: string, productIds: string[]) {
        const { ajax, URLS, translator, alert } = this.salesChannelsService;
        const url = URLS.PATCH_OFFER_PRODUCTS_GENERATE_GTIN.replace(
            '{id}',
            offerId,
        );
        const errorConfig = configError('patch', this.errorHandlers);
        const config = { headers: HttpPatchHeaders };

        const data = {
            offer_products: productIds.map((productId) => ({
                id: productId,
            })),
        };

        await ajax.patch({ url, data, config, errorConfig });

        alert.addSuccess({
            message: translator.t('manageOffers:alerts.gtinGenerationStarted'),
        });
    }
}

export default OfferProducts;
