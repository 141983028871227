import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

export const ROLE_POST_SALES_ADMINISTRATION = {
    section: S.POST_SALES_ADMINISTRATION,
    type: 'main',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.COMPLAINT_REASONS.CREATE]: ACCEPT,
        [A.POST_SALES.RESOURCES.COMPLAINT_REASONS.FETCH]: ACCEPT,
        [A.POST_SALES.RESOURCES.COMPLAINT_REASONS.EDIT]: ACCEPT,
        [A.POST_SALES.RESOURCES.COMPLAINT_REASONS.DELETE]: ACCEPT,
        [A.POST_SALES.RESOURCES.RETURN_REASONS.CREATE]: ACCEPT,
        [A.POST_SALES.RESOURCES.RETURN_REASONS.FETCH]: ACCEPT,
        [A.POST_SALES.RESOURCES.RETURN_REASONS.EDIT]: ACCEPT,
        [A.POST_SALES.RESOURCES.RETURN_REASONS.DELETE]: ACCEPT,
    },
};

export const ROLE_POST_SALES_READ = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'display',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS.FETCH]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.FETCH]: ACCEPT,
        [A.POST_SALES.RESOURCES.PHOTOS.FETCH]: ACCEPT,
    },
};

export const ROLE_POST_SALES_CREATE = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'create',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_CREATE]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.CREATE]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.CREATE_NEW]: ACCEPT,
        [A.POST_SALES.RESOURCES.PHOTOS.CREATE]: ACCEPT,
    },
};

export const ROLE_POST_SALES_UPDATE = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'edit',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.EDIT]: ACCEPT,
    },
};

export const ROLE_POST_SALES_DELETE = {
    section: S.POST_SALES_CUSTOMER_REQUESTS,
    type: 'delete',
    votes: {
        [A.POST_SALES.MODULE]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS]: ACCEPT,
        [A.POST_SALES.PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS_DETAILS.DELETE]: ACCEPT,
        [A.POST_SALES.RESOURCES.PHOTOS.DELETE]: ACCEPT,
        [A.POST_SALES.RESOURCES.CUSTOMER_REQUESTS.DELETE]: ACCEPT,
    },
};
