import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, DialogTitle } from '@mui/material';
import { DownloadIcon as GetAppOutlined } from 'components/Icons/Icons';
import IconButton from 'components/IconButton/iconButton';
import Typography from 'components/Typography';
import { Loader } from 'components/Loader/Loader';
import Button from 'components/Button/button';
import {
    ErrorMessageStyled,
    ErrorModalStyled,
    ModalButtonsStyled,
    ModalContentStyled,
    ModalLoaderContainerStyled,
} from 'modules/pdp/components/TosModal/tosModal.styles';

class TosModal extends React.PureComponent {
    render() {
        const {
            t,
            title,
            content,
            attachments = [],
            contentId,
            loading,
            retryFetch,
            downloadAttachment,
            buttons,
            fetchError,
        } = this.props;

        return (
            <>
                { title && (
                    <DialogTitle>
                        { title }
                    </DialogTitle>
                ) }
                <ModalContentStyled>
                    { loading && (
                        <ModalLoaderContainerStyled>
                            <Loader />
                            <Typography>
                                { t('labels.loadingTos') }
                            </Typography>
                        </ModalLoaderContainerStyled>
                    ) }
                    { fetchError && (
                        <ErrorModalStyled>
                            <Typography>
                                { t(('labels.contactIt')) }
                            </Typography>
                            <Button
                                color="primary"
                                onClick={ retryFetch }
                            >
                                { t('common:actions.tryAgain') }
                            </Button>
                        </ErrorModalStyled>
                    ) }
                    { !fetchError && content && content.split('\n').map((paragraph, index) => {
                        return (
                            <ErrorMessageStyled
                                isEmpty={ paragraph.length === 0 }
                                key={ index }
                                component="p"
                            >
                                { paragraph }
                            </ErrorMessageStyled>
                        );
                    }) }
                    { !fetchError && attachments.length > 0 && (
                        <List>
                            {
                                attachments.map((attachment) => {
                                    const { id, name } = attachment;

                                    return (
                                        <ListItem
                                            disableGutters
                                            key={ id }
                                        >
                                            { name }
                                            <IconButton
                                                disabled={ !downloadAttachment }
                                                onClick={ () => downloadAttachment(contentId, attachment) }
                                                size="large"
                                            >
                                                <GetAppOutlined />
                                            </IconButton>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    ) }
                </ModalContentStyled>
                { content && buttons && (
                    <ModalButtonsStyled>
                        { buttons }
                    </ModalButtonsStyled>
                ) }
            </>
        );
    }
}

TosModal.defaultProps = {
    t: (key) => key,
};

TosModal.propTypes = {
    t: PropTypes.func,
    content: PropTypes.node,
    attachments: PropTypes.array,
    contentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
    retryFetch: PropTypes.func,
    downloadAttachment: PropTypes.func,
    buttons: PropTypes.node,
    fetchError: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default TosModal;
