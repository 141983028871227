import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

/*
    DISCOUNTS
 */

export const ROLE_DISCOUNTS_DISCOUNT_READ = {
    section: S.DISCOUNTS,
    type: 'display',
    votes: {
        [A.DROPSHIPPING.MODULE]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.DISCOUNT.FETCH]: ACCEPT,
    },
};

export const ROLE_DISCOUNTS_DISCOUNT_EDIT = {
    section: S.DISCOUNTS,
    type: 'manage',
    votes: {
        [A.DROPSHIPPING.MODULE]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.DISCOUNT.EDIT]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.DISCOUNT.CREATE]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.DISCOUNT.DELETE]: ACCEPT,
    },
};
