const baseError = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;

    if (data.violations && data.violations.length > 0) {
        data.violations.forEach((violation = {}) => {
            alertService.addError({
                message: `${ violation.propertyPath }: ${ violation.message }`,
            });
            throw error;
        });
    }

    alertService.addError({
        message: `An unknown error has occurred (${ status })`,
    });
    throw error;
};

const download = (alertService) => (error = {}) => {
    const alert = {
        message: 'An unknown error has occurred',
    };

    alertService.addError(alert);
    throw error;
};

const errorHandlers = (alertService) => ({
    download: download(alertService),
    post: baseError(alertService),
});

export default errorHandlers;
