import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { TruckIcon } from 'components/Icons/Icons';

const Shipments = lazy(() =>
    import('../pages/ShipmentsInProgressPage').then((module) => ({
        default: module.EnhancedShipmentsInProgressPage,
    })),
);
const DeliveredShipmentsPage = lazy(() =>
    import('../pages/DeliveredShipmentsPage').then((module) => ({
        default: module.EnhancedDeliveredShipmentsPage,
    })),
);
const CustomShipmentsPage = lazy(() => import('../pages/CustomShipmentsPage'));
const CustomShipmentsDetailsContainer = lazy(() =>
    import('../containers/CustomShipmentDetailsContainer').then((module) => ({
        default: module.CustomShipmentDetailsContainer,
    })),
);
const ShipmentVerificationTable = lazy(() =>
    import('../pages/ShipmentVerificationTablePage').then((module) => ({
        default: module.EnhancedShipmentVerificationTablePage,
    })),
);
const DeliveryNotesPage = lazy(() =>
    import('../pages/DeliveryNotesPage').then((module) => ({
        default: module.EnhancedDeliveryNotesPage,
    })),
);
const AddressEdit = lazy(() =>
    import('../pages/AddressEditPage').then((module) => ({
        default: module.EnhancedAddressEditPage,
    })),
);
const CarrierRuleManagement = lazy(() =>
    import('../containers/CarrierRuleManagementContainer'),
);
const CarrierRuleDetailsContainer = lazy(() =>
    import('../containers/CarrierRuleDetailsContainer'),
);

export const prodisRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            name: 'shipments',
            exact: true,
            path: PATHS.SHIPMENTS,
            component: Shipments,
        },
        {
            name: 'deliveredShipments',
            exact: true,
            path: PATHS.DELIVERED_SHIPMENTS,
            component: DeliveredShipmentsPage,
        },
        {
            name: 'customShipments',
            exact: true,
            path: PATHS.CUSTOM_SHIPMENTS,
            component: CustomShipmentsPage,
        },
        {
            name: 'shipmentVerification',
            exact: true,
            path: PATHS.SHIPMENT_VERIFICATION,
            component: ShipmentVerificationTable,
        },
        {
            name: 'deliveryNotes',
            exact: true,
            path: PATHS.DELIVERY_NOTES,
            component: DeliveryNotesPage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.SHIPMENT_VERIFICATION_DETAILS,
            component: AddressEdit,
        },
        {
            name: 'carrierRuleManagement',
            exact: true,
            path: PATHS.CARRIER_RULE_MANAGEMENT,
            search: PATHS.CARRIER_RULE_MANAGEMENT_WITH_SORT.search,
            component: CarrierRuleManagement,
        },
        {
            name: null,
            path: PATHS.CARRIER_RULE_DETAILS,
            component: CarrierRuleDetailsContainer,
            exact: true,
        },
        {
            name: null,
            path: PATHS.CUSTOM_SHIPMENTS_DETAILS,
            component: CustomShipmentsDetailsContainer,
            exact: true,
        },
    ],
    icon: TruckIcon,
};
