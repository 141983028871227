import { MODULE_NAME } from '../constants';
import { PATHS } from './paths.config';
import { lazy } from 'react';
import { ChatIcon } from 'components/Icons/Icons';

const CustomerRequestTableViewPage = lazy(() =>
    import('../pages/CustomerRequestTableViewPage').then((module) => ({
        default: module.CustomerRequestTableViewPage,
    })),
);
const CustomerRequestComplaintPage = lazy(() =>
    import('../pages/CustomerRequestComplaintPage').then((module) => ({
        default: module.CustomerRequestComplaintPage,
    })),
);
const CustomerRequestReturnPage = lazy(() =>
    import('../pages/CustomerRequestReturnPage').then((module) => ({
        default: module.CustomerRequestReturnPage,
    })),
);
const CustomerRequestCreatePage = lazy(() =>
    import('../pages/CustomerRequestCreatePage').then((module) => ({
        default: module.CustomerRequestCreatePage,
    })),
);

export const postSalesRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            name: 'customerRequests',
            path: PATHS.CUSTOMER_REQUESTS,
            component: CustomerRequestTableViewPage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.CUSTOMER_REQUESTS_COMPLAINT_DETAILS,
            component: CustomerRequestComplaintPage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.CUSTOMER_REQUESTS_RETURN_DETAILS,
            component: CustomerRequestReturnPage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.CUSTOMER_REQUESTS_CREATE,
            component: CustomerRequestCreatePage,
        },
    ],
    icon: ChatIcon,
};
