import { MODULE_NAME } from '../constants';
const REDUCER = 'common';

export const getIsNavigationBarExtended = (state) => {
    return state
        .getIn([MODULE_NAME, REDUCER, 'navBarExtended']);
};

export const getIsMobileNavigationBarExtended = (state) => {
    return state
        .getIn([MODULE_NAME, REDUCER, 'mobileNavBarExtended']);
};

export const getIsLoggedIn = (state) => {
    return state
        .getIn([MODULE_NAME, REDUCER, 'isLoggedIn']);
};

export const getAlertsById = (state) => {
    return state
        .getIn([MODULE_NAME, REDUCER, 'alertsById']);
};
