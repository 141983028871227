import React from 'react';
import qs from 'qs';
import debounce from 'lodash/debounce';
import * as PropTypes from 'prop-types';
import withUrlPush from 'hoc/withUrlPush/withUrlPush';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/button';
import {
    SearchButtonsStyled,
    SearchCardStyled,
} from 'modules/stablo/components/SearchPanel/searchPanel.styles';

export class SearchPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery:
                qs.parse(props.search)[props.searchedField] ||
                props.initialValue,
        };
        this.delayedChangeUrl = debounce(this.changeUrl, props.urlPushTimeout);
    }

    componentDidMount() {
        const { initialValue } = this.props;

        if (initialValue && initialValue.length > 0) {
            this.changeUrl(initialValue);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { search, searchedField } = this.props;
        const { searchQuery } = this.state;
        const searchFromUrl = qs.parse(search)[searchedField];

        if (
            searchQuery === prevState.searchQuery &&
            searchFromUrl !== searchQuery
        ) {
            this.setState({ searchQuery: searchFromUrl || '' });
        }
    }

    changeQuery = (e) => {
        const value = e.target.value;

        this.setState({ searchQuery: value });
        if (value) {
            this.delayedChangeUrl(value);
        } else {
            this.changeUrl(value);
        }
    };

    changeUrl = (value) => {
        const { push, hash, search, searchedField } = this.props;
        const searchObject = qs.parse(search);

        searchObject[searchedField] = value;
        push({ hash, search: qs.stringify(searchObject) });
    };

    clearQuery = () => {
        const { onClear } = this.props;

        this.delayedChangeUrl.cancel();
        this.changeQuery({ target: { value: '' } });
        onClear && onClear();
    };

    handlePick = () => {
        const { onPick } = this.props;
        const { searchQuery } = this.state;

        this.delayedChangeUrl.cancel();
        this.changeUrl(searchQuery);
        onPick && onPick();
    };

    handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.handlePick();
        }
    };

    render() {
        const { t, title, placeholderLabel, error } = this.props;
        const { searchQuery } = this.state;

        return (
            <SearchCardStyled>
                <TextField
                    fullWidth
                    multiline
                    onChange={this.changeQuery}
                    value={searchQuery}
                    label={title}
                    placeholder={placeholderLabel}
                    error={!!error}
                    helperText={error ? error : ' '}
                    InputProps={{
                        inputProps: {
                            onKeyDown: this.handleKeyDown,
                        },
                    }}
                />
                <SearchButtonsStyled>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={this.clearQuery}
                    >
                        {t('common:actions.clear')}
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handlePick}
                    >
                        {t('common:actions.pick')}
                    </Button>
                </SearchButtonsStyled>
            </SearchCardStyled>
        );
    }
}

SearchPanel.defaultProps = {
    t: (key) => key,
    searchedField: 'search',
    push: () => {},
    urlPushTimeout: 1500,
};

SearchPanel.propTypes = {
    t: PropTypes.func.isRequired,
    onPick: PropTypes.func,
    title: PropTypes.string,
    searchedField: PropTypes.string,
    urlPushTimeout: PropTypes.number,
    placeholderLabel: PropTypes.string,
    search: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    hash: PropTypes.string.isRequired,
    onClear: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
};

const SearchPanelWithHOC = withUrlPush(SearchPanel);

export default SearchPanelWithHOC;
