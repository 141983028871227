import { mapUserFromApi } from './user';
import { mapDateFromApi } from './date';

export const mapPlatformFromApi = (data = {}) => ({
    id: data.id || '',
    code: data.code || '',
    iri: data['@id'] || '',
    createdBy: mapUserFromApi(data.created_by_user),
    createdAt: mapDateFromApi(data.created_at),
});
