import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ToggleOffIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -3.5 14 14"
            { ...props }
        >
            <path
                d="M10.3334 0.666626H3.66671C1.82671 0.666626 0.333374 2.15996 0.333374 3.99996C0.333374 5.83996 1.82671 7.33329 3.66671 7.33329H10.3334C12.1734 7.33329 13.6667 5.83996 13.6667 3.99996C13.6667 2.15996 12.1734 0.666626 10.3334 0.666626ZM3.66671 5.99996C2.56004 5.99996 1.66671 5.10663 1.66671 3.99996C1.66671 2.89329 2.56004 1.99996 3.66671 1.99996C4.77337 1.99996 5.66671 2.89329 5.66671 3.99996C5.66671 5.10663 4.77337 5.99996 3.66671 5.99996Z"
            />
        </SvgIcon>
    );
}

ToggleOffIcon.defaultProps = {
    titleAccess: 'toggle-off-icon',
};

ToggleOffIcon.propTypes = svgIconPropTypes;
