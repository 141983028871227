import { takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import * as C from './constants';
import * as A from './actions';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import { goBack } from 'react-router-redux';

import { slimServicesFactory } from 'services/servicesFactory';
import handleErrorsConfig from 'services/armApi/errorHandlers';

const ARM_URL = get(window, 'env.ARM_API_URL', '');
const products = makeEndPoint(ARM_URL + '/api/products');

export default function* productsSaga() {
    yield takeEvery(C.CREATE_PRODUCT.request, postProducts);
}

function* postProducts(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = handleErrorsConfig(slimServices.alerts);

    const newProduct = action.payload.item;
    const id = newProduct.toJS().intermediate_product.id;

    yield products.post({
        action: A.createProduct,
        data: newProduct.set('intermediate_product', id),
        alerts: {
            success: true,
            failure: true,
        },
        callbacks: {
            failure: errorHandlers.post,
        },
        sagaCallbacks: {
            success: goBack,
        },
    });
}
