import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ArrowIcon(props) {
    return (
        <SvgIcon
            viewBox="-5 -1 16 16"
            { ...props }
        >
            <path
                d="M7.00009 6.98141C6.99898 6.90668 6.96886 6.83642 6.91644 6.784L1.206 1.07356C1.13462 0.99883 1.02755 0.968716 0.928288 0.995484C0.827909 1.02114 0.749837 1.09921 0.724185 1.19959C0.697417 1.29885 0.72753 1.40592 0.802257 1.4773L6.31082 6.98587L0.802257 12.4944C0.72753 12.5658 0.697416 12.6729 0.724184 12.7722C0.749836 12.8725 0.827908 12.9506 0.928287 12.9763C1.02755 13.003 1.13462 12.9729 1.206 12.8982L6.91644 7.18774C6.97109 7.13309 7.00121 7.05837 7.00009 6.98141Z"
            />
        </SvgIcon>
    );
}

ArrowIcon.defaultProps = {
    titleAccess: 'arrow-icon',
};

ArrowIcon.propTypes = svgIconPropTypes;
