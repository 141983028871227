import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function VisibilityOffIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -1 14 14"
            { ...props }
        >
            <path
                d="M2.16248 0.337646L1.33761 1.16252L2.95658 2.78149C2.6421 3.03786 2.3617 3.31092 2.11121 3.58472C1.04371 4.77472 0.583374 6.00008 0.583374 6.00008C0.583374 6.00008 2.33337 10.6667 7.00004 10.6667C7.70004 10.6667 8.3356 10.5616 8.90727 10.3808C9.32675 10.2526 9.71175 10.0843 10.0614 9.88631L11.8376 11.6625L12.6625 10.8376L2.16248 0.337646ZM7.00004 1.33341C6.30004 1.33341 5.66448 1.43855 5.09281 1.61938L6.06124 2.58781C6.35291 2.52948 6.66754 2.50008 7.00004 2.50008C10.08 2.50008 11.632 4.99091 12.1395 6.00008C11.947 6.38508 11.5914 6.99216 11.0606 7.58716L11.8889 8.41545C12.9564 7.22545 13.4167 6.00008 13.4167 6.00008C13.4167 6.00008 11.6667 1.33341 7.00004 1.33341ZM3.78487 3.60978L4.99255 4.81746C4.78305 5.16716 4.66671 5.56896 4.66671 6.00008C4.66671 6.04675 4.66657 6.09925 4.6724 6.14592C4.7424 7.31842 5.68171 8.25772 6.85421 8.32772C6.90087 8.33355 6.95337 8.33342 7.00004 8.33342C7.43116 8.33342 7.83297 8.21707 8.18266 8.00757L9.1921 9.01701C8.81327 9.19754 8.39332 9.33661 7.93884 9.41235C7.64134 9.47069 7.33254 9.50008 7.00004 9.50008C3.92004 9.50008 2.36805 7.00925 1.86055 6.00008C2.05305 5.61508 2.40283 5.00801 2.93949 4.413C3.17834 4.1392 3.46456 3.86615 3.78487 3.60978ZM7.14587 3.67244L9.32768 5.85425C9.25768 4.68175 8.31837 3.74244 7.14587 3.67244Z"
            />
        </SvgIcon>
    );
}

VisibilityOffIcon.defaultProps = {
    titleAccess: 'visibility-off-icon',
};

VisibilityOffIcon.propTypes = svgIconPropTypes;
