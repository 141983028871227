import get from 'lodash/get';
import parser from 'qs';

import { ApplicationJsonHeaders } from 'services/constants';
import { parseGroupedSortParams } from 'utils/tableHelpers';

import {
    mapBoxGroupsFromApi,
    mapBoxGroupToApi,
    mapBoxGroupFromApi,
    mapIntermediateProductsBoxesToApi,
    mapBoxGroupAutocompleteFromApi,
    mapBoxRulesBoxesToApi,
} from 'services/armApi/mappers/boxGroups';
import { getBoxGroupFilters } from 'modules/arm/helpers';
import { BOX_GROUPS_AVAILABLE_FILTERS } from 'modules/arm/constants';
import { configError } from 'services/utils/utils';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class BoxGroups {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly DEFAULTS: IArmApiServiceConst['DEFAULTS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS, DEFAULTS }: Pick<IArmApiServiceConst, 'URLS' | 'DEFAULTS'>,
    ) {
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;

        this.getList = this.getList.bind(this);
        this.getListAutocomplete = this.getListAutocomplete.bind(this);
        this.getById = this.getById.bind(this);
        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.setIntermediateProductsShippingBoxes =
            this.setIntermediateProductsShippingBoxes.bind(this);
        this.setIntermediateProductsInternalBoxes =
            this.setIntermediateProductsInternalBoxes.bind(this);
        this.editBoxRules = this.editBoxRules.bind(this);
        this.deleteBoxGroup = this.deleteBoxGroup.bind(this);
    }

    private paramsSerializer = (params: unknown) => parser.stringify(params);

    private async _getList({
        filters = {},
        per_page = this.DEFAULTS.BOX_GROUPS_PER_PAGE,
        page = 1,
        sort = {},
    }): Promise<{ items: unknown[]; totalItems: unknown }> {
        const { ajax } = this.services;

        const parsedFilters = getBoxGroupFilters({
            appliedFilters: filters,
            availableFilters: BOX_GROUPS_AVAILABLE_FILTERS,
        });

        const url = this.URLS.GET_BOX_GROUPS;
        const parsedParams = {
            per_page,
            page,
            order: parseGroupedSortParams(sort, 'code'),
            ...parsedFilters,
        };

        const config = {
            params: parsedParams,
            headers: ApplicationJsonHeaders,
            paramsSerializer: this.paramsSerializer,
        };

        try {
            const response = await ajax.get({ url, config });
            const items = get(response, 'data.results', []);
            const totalItems = get(response, 'data.total', 0);

            return {
                items,
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async getListAutocomplete({ search = '' }): Promise<{
        items: { id: string; code: unknown }[];
        totalItems: unknown;
    }> {
        const { ajax } = this.services;
        const { paramsSerializer } = this;

        const url = this.URLS.GET_BOX_GROUPS;

        const searchQuery = search
            ? {
                  search: {
                      match: search,
                  },
              }
            : {};

        const config = {
            params: {
                filter: searchQuery,
            },
            headers: ApplicationJsonHeaders,
            paramsSerializer,
        };

        try {
            const response = await ajax.get({ url, config });
            const items = get(response, 'data.results', []);
            const totalItems = get(response, 'data.total', 0);

            return {
                items: mapBoxGroupAutocompleteFromApi(items),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async getList(
        { filters, per_page, page, sort },
        type = 'mapped',
    ): Promise<{ items: unknown[]; totalItems: unknown }> {
        // type mapped or raw
        if (type === 'mapped') {
            const { items, totalItems } = await this._getList({
                filters,
                per_page,
                page,
                sort,
            });

            return {
                items: mapBoxGroupsFromApi(items),
                totalItems,
            };
        }

        return await this._getList({ filters, per_page, page, sort });
    }

    async getById(id: string): Promise<{
        id: unknown;
        boxes: unknown;
        code: unknown;
        width: unknown;
        height: unknown;
        depth: unknown;
        shippingBoxCount: unknown;
        internalBoxCount: unknown;
        totalBoxCount: unknown;
    }> {
        const { ajax } = this.services;
        const url = this.URLS.GET_BOX_GROUP.replace('{id}', id);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response = await ajax.get({ url, config });

        return mapBoxGroupFromApi(response.data);
    }

    async create(data: unknown) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_BOX_GROUPS;
        const errorConfig = configError('post', errorHandlers);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data: data_2 } = await ajax.post({
            url,
            data: mapBoxGroupToApi(data),
            errorConfig,
            config,
        });

        return mapBoxGroupFromApi(data_2);
    }

    async edit(id: string, data: unknown) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_BOX_GROUP.replace('{id}', id);
        const errorConfig = configError('put', errorHandlers);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data: data_2 } = await ajax.put({
            url,
            data: mapBoxGroupToApi(data),
            errorConfig,
            config,
        });

        return mapBoxGroupFromApi(data_2);
    }

    async setIntermediateProductsInternalBoxes(
        boxGroupId: string,
        intermediateProductIds: unknown[],
    ) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.PUT_INTERMEDIATE_PRODUCTS_INTERNAL_BOXES.replace(
            '{id}',
            boxGroupId,
        );
        const errorConfig = configError('put', errorHandlers);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajax.put({
            url,
            data: mapIntermediateProductsBoxesToApi(intermediateProductIds),
            errorConfig,
            config,
        });

        return mapBoxGroupFromApi(data);
    }

    async setIntermediateProductsShippingBoxes(
        boxGroupId: string,
        intermediateProductIds: unknown[],
    ) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.PUT_INTERMEDIATE_PRODUCTS_SHIPPING_BOXES.replace(
            '{id}',
            boxGroupId,
        );
        const errorConfig = configError('put', errorHandlers);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajax.put({
            url,
            data: mapIntermediateProductsBoxesToApi(intermediateProductIds),
            errorConfig,
            config,
        });

        return mapBoxGroupFromApi(data);
    }

    async editBoxRules(boxGroupId: string, boxRulesIds: unknown[]) {
        const { ajax } = this.services;

        const url = this.URLS.PUT_BOX_RULES.replace('{id}', boxGroupId);
        const errorConfig = {
            addGenericAlert: false,
        };
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajax.put({
            url,
            config,
            data: mapBoxRulesBoxesToApi(boxRulesIds),
            errorConfig,
        });

        return mapBoxGroupFromApi(data);
    }

    async deleteBoxGroup(id: string): Promise<void> {
        const { ajax } = this.services;

        const url = this.URLS.DELETE_BOX_GROUP.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
        };

        await ajax.delete({
            url,
            errorConfig,
        });
    }
}

export default BoxGroups;
