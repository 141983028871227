import { formatAuthorName } from '../../utils/formatting';

const STOCK_MEDIA_FILES_URL = window.env && window.env.STOCK_MEDIA_FILES_URL;

export const MODULE_NAME = 'stock';

export const AM_TECHNICS = [
    'alubond',
    'cork',
    'electronic',
    'glass',
    'ikea',
    'metal',
    'multistick',
    'painted',
    'plexi',
    'plywood',
    'print',
    'print&hand',
] as const;

const acceptedMimeTypes = [
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'image/tiff',
    'image/bmp',
    'image/png',
] as const;

export const SOURCE_UPLOAD_CONFIG = {
    UPPY: {
        autoProceed: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/source-files/',
        chunkSize: 50000000, // 50mb
        retryDelays: [0, 1000, 3000, 5000],
    },
    ACCEPTED_MIME_TYPES: undefined,
} as const;

export const THUMBNAIL_UPLOAD_CONFIG = {
    UPPY: {
        autoProceed: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/thumbnail-files/',
        chunkSize: 2000000, // 20mb
        retryDelays: [0, 1000, 3000, 5000],
    },
    ACCEPTED_MIME_TYPES: acceptedMimeTypes,
} as const;

export const PATTERN_UPLOAD_CONFIG = {
    UPPY: {
        autoProceed: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/pattern-files/',
        chunkSize: 50000000, // 50mb
        retryDelays: [0, 1000, 3000, 5000],
    },
    ACCEPTED_MIME_TYPES: undefined,
    MAX_FILE_SIZE: 1024 * 1024 * 1024 * 10,
} as const;

export const PREVIEW_TYPE_UPLOAD_CONFIG = {
    UPPY: {
        autoProceed: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/preview-type-files/',
        chunkSize: 10000000, // 10mb
        retryDelays: [0, 1000, 3000, 5000],
    },
    ACCEPTED_MIME_TYPES: acceptedMimeTypes,
} as const;

export const PAINT_COLOR_PALETTES_UPLOAD_CONFIG = {
    UPPY: {
        autoProceed: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/palette-files/',
        chunkSize: 25000000, // 25mb
        retryDelays: [0, 1000, 3000, 5000],
    },
    MAX_FILE_SIZE: 100 * 1024 * 1024,
    ACCEPTED_EXTENSIONS: ['ai'],
} as const;

export const DRAFTS_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: null,
    MAX_FILE_SIZE: 1024 * 1024 * 1024,
    MAX_FILES_LIMIT: 100,
    UPPY: {
        id: 'stock-draft-files',
        autoProceed: false,
        debug: true,
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/draft-files',
        chunkSize: 10000000, // 10mb
        retryDelays: [0, 1000, 3000, 5000],
    },
} as const;

export const ORIGIN_OPTIONS = [
    'AdobeStock',
    'Artgeist',
    'Mix',
    'Shutterstock',
    'YoungArt',
];

export const URL_PARAMS = {
    create: 'create',
    createExternal: 'create-external',
};

export const GENERAL_FIELDS_TO_COPY = ['author', 'printSpec'];
export const GENERAL_FIELDS_COPY_MAPPINGS = {
    author: formatAuthorName,
    printSpec: (value) => value.name,
};
export const DETAILS_FIELDS_TO_COPY = ['name', 'nameEn'];
export const FIELDS_WITH_SEO_WARNING = ['name', 'nameEn'];
