import Jobs from './actions/Jobs';

export class UserService {
    private constructor({ services, errorHandlersConfig, CONSTANTS }) {
        this.ajaxService = services.ajax;
        this.alert = services.alert;
        this.parser = services.parser;
        this.translator = services.translator;
        this.errorHandlers = errorHandlersConfig(services.alert);
        this.constants = CONSTANTS;

        this.jobs = new Jobs(this);

        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    private static instance: UserService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        CONSTANTS,
    }): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService({
                services,
                errorHandlersConfig,
                CONSTANTS,
            });
        }

        return UserService.instance;
    }

    paramsSerializer = (params) => this.parser.stringify(params);
}
