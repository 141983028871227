import i18next from 'i18next';
import { API_ERRORS, APP_ALERTS } from 'services/armApi/constants';
import { handleForbiddenError } from 'utils/errors';

const hydraDescription = 'hydra:description';

const baseError =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        if (data.violations) {
            data.violations.forEach((violation = {}) => {
                alertService.addError({
                    message: `${violation.propertyPath}: ${violation.message}`,
                });
                throw error;
            });
        }

        if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        }

        alertService.addError({
            message: `An unknown error has occurred (${status})`,
        });
        throw error;
    };

const intermediateProductNotFound =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { status } = response;

        handleForbiddenError(error, response, alertService);

        if (status === 404) {
            alertService.addError({
                message: 'Intermediate product data is not available',
            });
        } else {
            alertService.addError({
                message: `An unknown error has occurred (${status})`,
            });
        }
        throw error;
    };

const download =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
        } else {
            alertService.addError({
                message: i18next.t('common:errors.unknown', { status }),
            });
        }
        throw error;
    };

const createContractor =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data[hydraDescription]) {
            alertService.addError({
                message: data[hydraDescription],
            });
            throw error;
        }

        alertService.addError({
            message: `An unknown error has occurred (${status})`,
        });
    };

const putTechnologies =
    (alertService, translator) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data[hydraDescription]) {
            alertService.addError({
                message: `${translator.t('alerts.noSavedChanges', {
                    ns: 'common',
                })} ${data[hydraDescription]}`,
            });
            throw error;
        }

        alertService.addError({
            message: `An unknown error has occurred (${status})`,
        });
    };

const passOrderToProduction =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;

        handleForbiddenError(error, response, alertService);

        if (response.data && response.status === 400) {
            const message = response.data[hydraDescription];

            if (message === API_ERRORS.IMPORT_ORDER_ALREADY_PASSED) {
                alertService.addWarning({
                    message: i18next.t(APP_ALERTS.ORDER_IMPORT_ALREADY_PASSED),
                });
            } else {
                alertService.addError({ message });
            }
        } else {
            alertService.addError({ message: i18next.t('app:alerts.unknown') });
        }
        throw error;
    };

const errorHandlers = (alertService, translator) => ({
    post: baseError(alertService),
    patch: baseError(alertService),
    put: baseError(alertService),
    get: baseError(alertService),
    delete: baseError(alertService),
    download: download(alertService),
    intermediateProductNotFound: intermediateProductNotFound(alertService),
    createContractor: createContractor(alertService),
    passOrderToProduction: passOrderToProduction(alertService),
    putTechnologies: putTechnologies(alertService, translator),
});

export default errorHandlers;
