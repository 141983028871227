import { FILTER_DEFAULT_VALUES, FILTER_FIELD_TYPES, FILTER_MATCHER_TYPES } from 'services/constants';
import { CheckCircleOutlinedIcon, ImageIcon, StorageIcon } from 'components/Icons/Icons';
import React from 'react';

export const defaultFilters = {
    channelId: {
        name: 'channelId',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
    },
    patternCode: {
        name: 'patternCode',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'translations:labels.patternsFilters.patternCode',
        labelAlt: 'translations:labels.patternsFilters.patternCodeAlt',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        matcher: FILTER_MATCHER_TYPES.PARTIAL,
        icon: <ImageIcon size="small" />
    },
    incompleteCategories: {
        name: 'incompleteCategories',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'translations:labels.patternsFilters.incompleteCategories',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.SWITCH_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <CheckCircleOutlinedIcon size="small" />,
        invertedSwitchKey: 'isCompleteCategories',
    },
    incompleteColors: {
        name: 'incompleteColors',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'translations:labels.patternsFilters.incompleteColors',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.SWITCH_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <CheckCircleOutlinedIcon size="small" />,
        invertedSwitchKey: 'isCompleteColors',
    },
    incompleteTags: {
        name: 'incompleteTags',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'translations:labels.patternsFilters.incompleteTags',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.SWITCH_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <CheckCircleOutlinedIcon size="small" />,
        invertedSwitchKey: 'isCompleteTags',
    },
    incompleteTitles: {
        name: 'incompleteTitles',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'translations:labels.patternsFilters.incompleteTitles',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.SWITCH_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <CheckCircleOutlinedIcon size="small" />,
        invertedSwitchKey: 'isCompleteTitles',
    },
    isCompleteTranslations: {
        name: 'isCompleteTranslations',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'translations:labels.patternsFilters.completeTranslations',
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.SWITCH_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <CheckCircleOutlinedIcon size="small" />,
    },
    offerId: {
        name: 'offerId',
        label: 'translations:labels.patternsFilters.offerId',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        isModalFilter: false,
        fieldType: FILTER_FIELD_TYPES.AUTOCOMPLETE_FIELD,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        icon: <StorageIcon size="small" />,
    },
};

