import styled from '@emotion/styled';
import IconButton from 'components/IconButton/iconButton';
import Cell from '../Cell/cell';

export const IconButtonStyled = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)};
`;
IconButtonStyled.displayName = 'IconButton';

export const WrapperStyled = styled(Cell)`
  flex-wrap: wrap;
`;
WrapperStyled.displayName = 'Wrapper';
