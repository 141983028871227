import { mapDictionariesFromApi } from './mappers/dictionaries';
import { ApplicationJsonHeaders } from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import { AVAILABLE_DICTIONARY_CODES } from './constants';
import { IDictionaryValueResponseData } from './dictionariesService.type';

export class DictionariesService {
    private dictionaries = {};
    private readonly headers = ApplicationJsonHeaders;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly URLS: unknown,
        private readonly DEFAULTS: unknown,
    ) {
        this.getOptions = this.getOptions.bind(this);
        this.getAllOptions = this.getAllOptions.bind(this);
        this.getList = this.getList.bind(this);
        this.getDictionaryCodeValues = this.getDictionaryCodeValues.bind(this);
        this.getDictionaryFormValues = this.getDictionaryFormValues.bind(this);
    }

    private static instance: DictionariesService | undefined;

    public static getInstance(
        ajaxService: AjaxService,
        URLS: unknown,
        DEFAULTS: unknown,
    ): DictionariesService {
        if (!DictionariesService.instance) {
            DictionariesService.instance = new DictionariesService(
                ajaxService,
                URLS,
                DEFAULTS,
            );
        }

        return DictionariesService.instance;
    }

    parseResponse(response) {
        return {
            items: (response?.data?.results ?? []).map(mapDictionariesFromApi),
            totalItems: response?.data?.total ?? 0,
        };
    }

    getList(params = {}) {
        const ajax = this.ajaxService;
        const { page = 1, per_page = this.DEFAULTS.PER_PAGE } = params;

        const url = this.URLS.DICTIONARIES;
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
            },
            headers: this.headers,
        };

        return ajax
            .get({ url, config })
            .then(this.parseResponse)
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    getDictionaryCodeValues(params = {}, code) {
        const ajax = this.ajaxService;
        const { page = 1, per_page = this.DEFAULTS.PER_PAGE } = params;

        const url = this.URLS.DICTIONARY_CODE_VALUES.replace('{code}', code);
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
            },
            headers: this.headers,
        };

        return ajax
            .get({ url, config })
            .then(this.parseResponse)
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    async getDictionaryFormValues(
        formUrl = '',
    ): Promise<IDictionaryValueResponseData['results'] | {}> {
        const config = {
            params: {
                page: 1,
                itemsPerPage: 100,
            },
            headers: ApplicationJsonHeaders,
        };

        const code =
            AVAILABLE_DICTIONARY_CODES.find((dictionaryCode) =>
                formUrl.includes(dictionaryCode),
            ) || '';

        if (!code) {
            return {};
        }
        const url = this.URLS.DICTIONARY_CODE_VALUES.replace('{code}', code);

        const response: { data: IDictionaryValueResponseData } =
            await this.ajaxService.get({
                url,
                config,
            });

        return response.data.results;
    }

    getDictionary(dictionaryName = '') {
        const dictionary = this.dictionaries[dictionaryName];
        const url = this.URLS.GET_DICTIONARIES;

        if (!dictionary) {
            return this.ajaxService
                .get({ url, config: { headers: this.headers } })
                .then((response) => {
                    if (!response.data || typeof response.data !== 'object') {
                        return {};
                    }
                    this.dictionaries[dictionaryName] =
                        response.data[dictionaryName];

                    return response.data[dictionaryName];
                })
                .catch(() => ({}));
        }

        return new Promise(function (resolve) {
            resolve(dictionary);
        });
    }

    getAllOptions(search = '') {
        const url = this.URLS.GET_DICTIONARIES;
        const dictionaries = {};

        return this.ajaxService
            .get({ url, config: { headers: this.headers } })
            .then(({ data: dictionaryData }) => {
                if (!dictionaryData || typeof dictionaryData !== 'object') {
                    this.dictionaries = {};

                    return dictionaries;
                }
                const dictionaryTypeKeys = Object.keys(dictionaryData);

                this.dictionaries = dictionaryData;

                if (dictionaryTypeKeys.length > 0) {
                    for (let i = 0; i < dictionaryTypeKeys.length; i++) {
                        const type = dictionaryTypeKeys[i];
                        const options = [];
                        const optionsKeys = Object.keys(dictionaryData[type]);

                        if (optionsKeys.length > 0) {
                            for (let i = 0; i < optionsKeys.length; i++) {
                                const itemId = optionsKeys[i];

                                if (
                                    dictionaryData[type][itemId]
                                        .toUpperCase()
                                        .includes(search.toUpperCase())
                                ) {
                                    options.push({
                                        key: itemId,
                                        value: dictionaryData[type][itemId],
                                    });
                                }
                            }

                            dictionaries[type] = options;
                        }
                    }
                }

                return dictionaries;
            })
            .catch(() => ({}));
    }

    getOptions(dictionaryName = '', search = '') {
        return this.getDictionary(dictionaryName).then((dictionary) => {
            const options = [];
            const dictionaryKeys = Object.keys(dictionary);

            if (dictionaryKeys.length > 0) {
                for (let i = 0; i < dictionaryKeys.length; i++) {
                    const itemId = dictionaryKeys[i];

                    if (
                        dictionary[itemId]
                            .toUpperCase()
                            .includes(search.toUpperCase())
                    ) {
                        options.push({
                            label: itemId,
                            value: dictionary[itemId],
                        });
                    }
                }
            }

            return options;
        });
    }
}
