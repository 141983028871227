import { ApplicationJsonHeaders } from 'services/constants';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { URLS } from '../../constants';
import type { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { EmailMessageOutputDto, EmailMessagesParameters } from './emailMessage.type';
import type { default as queryParser } from 'qs';

export class EmailMessageActions {
    private ajaxService: AjaxService;

    private urls: typeof URLS;

    private errorHandlers: ReturnType<typeof errorHandlersHelper>;

    private parser: typeof queryParser;

    constructor(ajaxService: AjaxService, urls: typeof URLS, errorHandlers: ReturnType<typeof errorHandlersHelper>, parser: typeof queryParser) {
        this.ajaxService = ajaxService;
        this.urls = urls;
        this.errorHandlers = errorHandlers;
        this.parser = parser;

        this.getEmailMessages = this.getEmailMessages.bind(this);
    }

    getEmailMessages(params: Partial<EmailMessagesParameters>): Promise<{ results: EmailMessageOutputDto[], total: number }> {
        const url = this.urls.GET_EMAIL_MESSAGES;

        return this.ajaxService.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                params,
                paramsSerializer: (parameters) => this.parser.stringify(parameters)
            },
            errorConfig: { onError: this.errorHandlers.post },
            onError: this.errorHandlers.post,
        }).then(({ data }) => data);
    }
}
