import { Tooltip } from '@mui/material';
import type { ReactNode } from 'react';

export const NavTooltip = ({
    isTooltip,
    tooltipTitle,
    children,
}: {
    isTooltip: boolean;
    tooltipTitle: string;
    children: ReactNode;
}) =>
    isTooltip ? (
        <Tooltip
            placement="right"
            title={tooltipTitle}
        >
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
