import type { StockService } from '../../StockService';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { DeepPartial } from '../../../../utils/types/deepPartial';
import type {
    IProductSetPreviewOutputDto,
    IProductSetPreviewParameters,
    IProductSetPreviewsListResponse,
} from './productSetPreviews.type';
import { URLS } from '../../constants';
import { paramsSerializer } from '../../../utils/utils';
import { ApplicationJsonHeaders } from '../../../constants';

export class ProductSetPreviewsActions {
    private static instance: ProductSetPreviewsActions | undefined;

    private constructor(
        private readonly stockService: StockService,
        private readonly ajaxService: AjaxService,
    ) {
        this.getList = this.getList.bind(this);
        this.deleteById = this.deleteById.bind(this);
        this.download = this.download.bind(this);
        this.addPreview = this.addPreview.bind(this);
    }

    public static getInstance(
        stockService: StockService,
        ajaxService: AjaxService,
    ): ProductSetPreviewsActions {
        if (!ProductSetPreviewsActions.instance) {
            ProductSetPreviewsActions.instance = new ProductSetPreviewsActions(
                stockService,
                ajaxService,
            );
        }

        return ProductSetPreviewsActions.instance;
    }

    public async getList(
        parameters: DeepPartial<IProductSetPreviewParameters>,
    ): Promise<IProductSetPreviewsListResponse> {
        const { errorHandlers } = this.stockService;

        const response: { data: IProductSetPreviewsListResponse } =
            await this.ajaxService.get({
                url: URLS.GET_PRODUCT_SET_PREVIEWS_LIST,
                config: {
                    headers: ApplicationJsonHeaders,
                    params: parameters,
                    paramsSerializer,
                },
                errorConfig: {
                    onError: errorHandlers.get,
                },
                onError: errorHandlers.get,
            });

        return response.data;
    }

    public async deleteById(
        id: IProductSetPreviewOutputDto['id'],
    ): Promise<void> {
        const { errorHandlers } = this.stockService;

        await this.ajaxService.delete({
            url: URLS.DELETE_PRODUCT_SET_PREVIEW.replace('{id}', id),
            errorConfig: {
                onError: errorHandlers.delete,
            },
            onError: errorHandlers.delete,
            config: {},
        });
    }

    public async download(fileId: string, fileName: string): Promise<void> {
        const { errorHandlers } = this.stockService;

        const url = URLS.DOWNLOAD_PRODUCT_SET_PREVIEW.replace(
            '{id}',
            fileId,
        ).replace('previews', 'preview-files');

        await this.ajaxService
            .download({
                url,
                fileName,
                config: {
                    headers: ApplicationJsonHeaders,
                },
                errorConfig: { onError: errorHandlers.download },
                onError: errorHandlers.download,
            })
            .then((response) => response);
    }

    public async addPreview(
        fileId: string,
        productSetId: string,
        previewTypeId: string,
    ): Promise<void> {
        const { errorHandlers } = this.stockService;

        await this.ajaxService.post({
            url: URLS.CREATE_PRODUCT_SET_PREVIEW,
            errorConfig: {
                onError: errorHandlers.post,
            },
            onError: errorHandlers.post,
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: {
                preview_file: {
                    id: `preview-files/${fileId}`,
                },
                product_set: {
                    id: productSetId,
                },
                product_set_preview_type: {
                    id: previewTypeId,
                },
            },
        });
    }
}
