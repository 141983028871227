import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function EnvelopeIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 16 16"
            { ...props }
        >
            <path
                d="M0 0V0.32V11.52H16V0H0ZM0.64 0.64H15.36V1.44188C15.2242 1.56021 9.54042 6.51391 9.0675 6.92563C8.65241 7.28699 8.22954 7.36063 8 7.36063C7.77069 7.36063 7.34765 7.28704 6.9325 6.92563C6.45958 6.51391 0.775772 1.56021 0.64 1.44188V0.64ZM0.64 2.28813C1.42015 2.96818 6.06965 7.02142 6.51312 7.4075C7.05734 7.88128 7.63347 7.99938 8 7.99938C8.3663 7.99938 8.9426 7.88133 9.48687 7.4075C9.93035 7.02142 14.5798 2.96875 15.36 2.28875V10.88H0.64V2.28813Z"
            />
        </SvgIcon>
    );
}

EnvelopeIcon.defaultProps = {
    titleAccess: 'envelope-icon',
};

EnvelopeIcon.propTypes = svgIconPropTypes;
