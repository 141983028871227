type TEventType = 'open' | 'close';
type TListener<TData> = (data: TData) => void;

export class ModalService<TData extends { id: string }> {
    private listeners = {
        open: new Set<TListener<TData>>(),
        close: new Set<TListener<TData>>(),
    };

    private modals = new Set<TData>();

    private static instance: ModalService<{ id: string } & any> | undefined;

    private constructor() {}

    public static getInstance() {
        if (!ModalService.instance) {
            ModalService.instance = new ModalService();
        }

        return ModalService.instance;
    }

    public listen(eventName: TEventType, listener: TListener<TData>) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].add(listener);
        }
    }

    public unlisten(eventName: TEventType, listener: TListener<TData>) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].delete(listener);
        }
    }

    private emit(eventName: TEventType, data: TData) {
        if (this.listeners[eventName]) {
            this.listeners[eventName].forEach((listener) => listener(data));
        }
    }

    public close(modalHandler: TData) {
        this.emit('close', modalHandler);
    }

    public open(modal: TData) {
        this.addData(modal);
        this.emit('open', modal);
    }

    private addData(data: TData) {
        if (this.modals.size === 0) {
            this.modals.add(data);

            return;
        }

        this.modals.forEach((modal) => {
            if (modal.id === data.id) {
                this.modals.delete(modal);
            }
        });

        this.modals.add(data);
    }

    public removeModals() {
        this.listeners.close.forEach((listener) => {
            this.modals.forEach((data) => {
                listener(data);
            });
        });
        this.modals.clear();
    }
}

export default ModalService;
