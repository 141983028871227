/**
 * Maps CategoryDefinition entity from API
 * @param {object} data - Single entity API response
 * @return {{name: string, id: string}} Mapped CategoryDefinition object
 */
const mapCategoryDefinitionFromAPI = (data = {}) => ({
    id: data?.id || '',
    name: data?.name || '',
});

/**
 * Maps CategoryDefinition DTO to API
 * @param {{ name: string }} data - DTO to map
 * @return {{ name: string }} Mapped DTO
 */
const mapCategoryDefinitionToAPI = (data) => ({
    name: data.name.toLowerCase(),
});

/**
 * Maps intermediate product to CategoryDefinition update DTO
 * @param data - Intermediate product object
 * @return {{id: string}} Mapped DTO
 */
const mapIntermediateProductsToAPICategoryDefinitionUpdate = (data = {}) => ({
    id: data?.id || '',
});

export { mapCategoryDefinitionFromAPI, mapCategoryDefinitionToAPI, mapIntermediateProductsToAPICategoryDefinitionUpdate };
