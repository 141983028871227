import {Grid, Paper} from '@mui/material';
import {FetchAutocomplete} from 'components/AutocompleteNew/FetchAutocomplete';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ServicesContext from 'contexts/ServicesContext';
import {mapGetWorkplacesToAutocomplete} from '../../../../utils/workplaceMappers/workplaceMappers.util';
import {useWorkplacesState} from './hooks/useDefaultWorkplacesState';
import ItemDetails from 'components/ItemDetails/itemDetails';
import Button from 'components/Button/button';
import PrimaryFormButton from 'components/PrimaryFormButton/primaryFormButton';
import {handleUpdateWorkplaces} from './handlers/handleUpdateWorkplaces';
import {useWorkplaceInfo} from './hooks/useWorkplaceInfo';
import {access} from 'access/votingMechanism';
import {usePrintout} from './hooks/usePrintout';
import {PrinterList} from './components/PrinterList';

export const WorkplacePage = () => {
    // preview | edit
    const [formState, setFormState] = useState('preview');
    const [state, setState, resetState] = useWorkplacesState();
    const {t} = useTranslation(['workplaces', 'common']);
    const {
        workplaces: {user, workplace},
        pdp: {workplaces: currentWorkplaces},
        router,
        alerts,
    } = useContext(ServicesContext);

    const {data: workplaceData} = useWorkplaceInfo(state.currentWorkplace);
    const {mutate: printout} = usePrintout();

    const canPrint = access['role_workplaces_print'];

    const handleUpdate = () => {
        (async () => {
            await handleUpdateWorkplaces(state, {
                t,
                alertService: alerts,
                patchCurrentWorkplace: currentWorkplaces.patchWorkplace,
                patchDefaultWorkplace: user.patchWorkplace,
                resetState,
            });

            setFormState('preview');
        })();
    };

    return (
        <ItemDetails
            t={t}
            moduleName="boxes"
            pathTitle="asd"
            title={t('titles.workplacePage', {ns: 'workplaces'})}
            showBreadCrumbs={false}
            cancelButton={
                <Button
                    baseWidth
                    onClick={() => {
                        if (formState === 'edit') {
                            setFormState('preview');
                            resetState();
                        } else {
                            router.push('/');
                        }
                    }}
                    color="primary"
                >
                    {t('actions.cancel', {ns: 'common'})}
                </Button>
            }
            submitButton={
                <PrimaryFormButton
                    t={t}
                    create={false}
                    editable={formState === 'edit'}
                    handleEdit={() => setFormState('edit')}
                    handleSubmit={() => handleUpdate()}
                />
            }
        >
            <Paper
                sx={{marginTop: '20px', padding: '10px 24px', width: '100%'}}
            >
                <Grid
                    container
                    xs={6}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <FetchAutocomplete
                            cacheKey="workplaces"
                            id="defaultWorkplace"
                            label={t('labels.defaultWorkplace', {
                                ns: 'workplaces',
                            })}
                            fetchOptions={async () =>
                                mapGetWorkplacesToAutocomplete(
                                    workplace.getWorkplaces,
                                )
                            }
                            value={state.defaultWorkplace}
                            handleChange={(key, value) => {
                                setState({key, value});
                            }}
                            disabled={formState !== 'edit'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FetchAutocomplete
                            cacheKey="workplaces"
                            id="currentWorkplace"
                            label={t('labels.currentWorkplace', {
                                ns: 'workplaces',
                            })}
                            fetchOptions={async () =>
                                mapGetWorkplacesToAutocomplete(
                                    workplace.getWorkplaces,
                                )
                            }
                            value={state.currentWorkplace}
                            handleChange={(key, value) => {
                                setState({key, value});
                            }}
                            disabled={formState !== 'edit'}
                        />
                    </Grid>
                </Grid>
                <PrinterList
                    printerData={workplaceData}
                    canPrint={canPrint}
                    handlePrintout={(code) => printout({printerCode: code})}
                />
            </Paper>
        </ItemDetails>
    );
};
