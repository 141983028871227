export const downloadInBackground = (url, fileName, popupCloseTimeout = 60000) => {
    if (!url) {
        return;
    }

    const rootElement = document.getElementById('root');
    const frameElement = document.createElement('iframe');

    frameElement.name = fileName;
    frameElement.style = 'display: none;';
    rootElement.appendChild(frameElement);

    const popup = window.open(url, frameElement.name,'width=,height=,resizable=no,toolbar=0,location=0,menubar=0');

    setTimeout(() => {
        popup.close();
        rootElement.removeChild(frameElement);
    }, popupCloseTimeout);
};

export const downloadFromURL = (url, fileName) => () => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
};
