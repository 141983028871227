import React from 'react';
import { svgIconPropTypes } from 'utils/propTypes';
import { SvgIcon } from 'components/Icons/SvgIconBase';

export function PendingJobIcon(props) {
    return (
        <SvgIcon
            { ...props }
            viewBox="0 0 14 16"
        >
            <path
                d="M0.23999 0.640015V15.36H9.51999V14.72H0.87999V1.28001H6.95999V5.43501H11.12V8.70376L11.76 9.49751V4.99251L11.6825 4.91501C11.6677 4.89655 11.651 4.87978 11.6325 4.86501C11.631 4.86375 11.6296 4.8625 11.6281 4.86126L7.40749 0.640015H0.23999ZM7.59999 1.72751L10.6675 4.79501H7.59999V1.72751ZM2.15999 7.04001V7.68001H8.55999V7.04001H2.15999ZM2.15999 8.96001V9.60001H7.27999V8.96001H2.15999ZM11.2787 9.92001L8.55999 13.12H10.16V16H12.4V13.12H14L11.2787 9.92001ZM2.15999 10.88V11.52H8.55999V10.88H2.15999ZM11.2787 10.88L12.5925 12.48H11.76V15.36H10.8V12.48H9.96749L11.2787 10.88Z"
            />
        </SvgIcon>
    );
}

PendingJobIcon.defaultProps = {
    titleAccess: 'pending-job-icon',
};

PendingJobIcon.propTypes = svgIconPropTypes;
