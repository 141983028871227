import { useMutation } from '@tanstack/react-query';
import ServicesContext from 'contexts/ServicesContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const usePrintout = () => {
    const { authentication, workplaces, alerts } = useContext(ServicesContext);
    const { t } = useTranslation(['workplaces']);

    return useMutation({
        mutationFn: ({ printerCode }: { printerCode: string }) =>
            workplaces.workplace.printout(printerCode, {
                path: window?.env?.TEST_PAGE_FILE_ID || '',
                requested_by_user: {
                    id: authentication.getActiveToken()?.user_id,
                },
                print_name: null,
            }),
        onSuccess: () => {
            alerts.addSuccess({
                message: t('alerts.successPrintTestPage', { ns: 'workplaces' }),
            });
        },
        onError: (error: Error) => {
            alerts.addError({
                message: `${t('alerts.failurePrintTestPage', {
                    ns: 'workplaces',
                })} - ${error.message}`,
            });
        },
    });
};
