import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { configureStore, history, sagaMiddleware } from 'store/configureStore';
import { rootSaga } from 'store/roots';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderOLD } from '@mui/styles';
import theme, { generateClassName } from './themes/themes';
import { StylesProvider } from '@material-ui/core/styles';
import i18n, { FALLBACK_LANGUAGE } from 'i18n';
import { access, makeAllRolesVote } from 'access/votingMechanism';
import ServicesContext from './contexts/ServicesContext';
import { servicesFactory } from './services/servicesFactory';
import App from 'modules/app/components/App/appWithState';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import './configs/axiosInterceptors';
import './configs/sentry';

import './configs/muiLicense';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const store = configureStore();
sagaMiddleware.run(rootSaga);
makeAllRolesVote();

const deferRender = async () => {
    if (import.meta.env.PROD || !import.meta.env.VITE_MOCKING_ENABLED) {
        return;
    }

    const { worker } = await import('./_mocks_/browser');

    return worker.start();
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const resolvedServices = servicesFactory(store);

const container = document.getElementById('root');
const root = createRoot(container);

deferRender().then(() => {
    root.render(
        <React.StrictMode>
            <StylesProvider generateClassName={generateClassName}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        {/* remove it after migration to emotion */}
                        <ThemeProviderOLD theme={theme}>
                            <ErrorBoundary>
                                <QueryClientProvider client={queryClient}>
                                    <Provider store={store}>
                                        <ServicesContext.Provider
                                            value={resolvedServices}
                                        >
                                            <ConnectedRouter history={history}>
                                                <LocalizationProvider
                                                    adapterLocale={
                                                        i18n.language ||
                                                        FALLBACK_LANGUAGE
                                                    }
                                                    dateAdapter={AdapterMoment}
                                                >
                                                    <App access={access} />
                                                </LocalizationProvider>
                                            </ConnectedRouter>
                                        </ServicesContext.Provider>
                                    </Provider>
                                    {import.meta.env.DEV && (
                                        <ReactQueryDevtools
                                            initialIsOpen={false}
                                        />
                                    )}
                                </QueryClientProvider>
                            </ErrorBoundary>
                        </ThemeProviderOLD>
                    </ThemeProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </React.StrictMode>,
    );
});

if (import.meta.hot) {
    import.meta.hot.on('locales-update', () => {
        i18n.reloadResources().then(() => {
            void i18n.changeLanguage(i18n.language);
        });
    });
}
