import get from 'lodash/get';
import { mapRoleFromApi } from '../mappers/roles';

class Roles {
    constructor(pdpService) {
        this.pdpService = pdpService;
        this.params = pdpService.USER_PARAMS || {};

        this.getList = this.getList.bind(this);
    }
    getList() {
        const {
            ajax,
            URLS,
            errorHandlers,
        } = this.pdpService;

        const url = URLS.GET_ROLES;

        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        return ajax
            .get({ url, errorConfig })
            .then((response) => {
                return ({
                    items: get(response, 'data.hydra:member', []).map(mapRoleFromApi),
                    totalItems: get(response, 'data.hydra:totalItems', 0),
                });
            });
    }
}

export default Roles;
