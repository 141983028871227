const MEDIA_FILES_URL = window.env && window.env.MEDIA_FILES_URL;

export const PRINT_SPEC_FILES_UPLOAD_CONFIG = {
    UPPY: {
        id: 'stock-print-spec-files',
    },
    TUS: {
        endpoint: MEDIA_FILES_URL + '/api/v1/custom-files/tus',
    },
};
