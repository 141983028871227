import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import { TransitionHistoryGetCollectionDto } from './transitionHistory.type';

export class TransitionHistoryActions {
    private static instance: TransitionHistoryActions | undefined;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ) {
        this.getHistory = this.getHistory.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ): TransitionHistoryActions {
        if (!TransitionHistoryActions.instance) {
            TransitionHistoryActions.instance = new TransitionHistoryActions(
                ajaxService,
                errorHandlers,
            );
        }

        return TransitionHistoryActions.instance;
    }

    async getHistory(id: string): Promise<TransitionHistoryGetCollectionDto> {
        const response: { data: TransitionHistoryGetCollectionDto } =
            await this.ajaxService.get({
                url: URLS.TRANSITION_HISTORY.replace('{id}', id),
                config: {
                    headers: ApplicationJsonHeaders,
                },
                errorConfig: {
                    onError: this.errorHandlers.get,
                },
                onError: this.errorHandlers.get,
            });

        return response.data;
    }
}
