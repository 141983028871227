import parser from 'qs';

export const getSortParams = (sortingFields, order = {}) => {
    return sortingFields.reduce((params, orderKey) => {
        if (order[orderKey]) {
            params[orderKey] = order[orderKey];
        }

        return params;
    }, {});
};

export const getFilterParams = (filterFields = [], filters = {}) => {
    return filterFields.reduce((params, filterKey) => {
        if (filters[filterKey]) {
            params[filterKey] = filters[filterKey];
        }

        return params;
    }, {});
};

export const paramsSerializer = (params) => parser.stringify(params);

export const getRequestParams = ({ order = {}, pagination: { page, per_page } = {}, filters = {} }) => {
    const params = {};

    if (page) params.page = +page;
    if (per_page) params.per_page = +per_page;
    if (Object.keys(filters).length > 0) params.filters = filters;
    if (Object.keys(order).length > 0) params.order = order;

    return params;
};

export const parseSortParams = (sort) =>
    sort.reduce((sortObject, field) => {
        sortObject[field.field] = field.sort;

        return sortObject;
    }, {});

export const getUserFullName = ({ firstName, lastName }) =>
    (!firstName && !lastName) ? '---' : `${firstName || ''} ${lastName || ''}`;

export function configError(requestType, errorHandlers, config) {
    return {
        throwError: errorHandlers[requestType],
        addGenericAlert: false,
        ...config,
    };
}

export const getErrorsByField = (errorList) => {
    if (Array.isArray(errorList)) {
        return errorList.reduce((prev, curr) => {
            return {
                ...prev,
                [curr.propertyPath]: curr.message,
            };
        }, {});
    }

    return {};
};
