import {
    forwardRef,
    type ElementRef,
    type ComponentPropsWithoutRef,
    useRef,
    useEffect,
    KeyboardEventHandler,
    Fragment,
} from 'react';
import { TextField as MUITextField, useTheme } from '@mui/material';
import { cx } from '@emotion/css';
import {
    readOnlyNotchedOutlineColorStyles,
    readOnlyNotchedOutlineStyles,
    readOnlyLabelFocusedStyles,
    WrapperWithCharacterCounterStyled,
    inputLabelRootStyles,
} from './textField.styles';
import CharacterCounter from 'components/CharacterCounter/CharacterCounter';

interface ITextFieldProps
    extends Omit<ComponentPropsWithoutRef<typeof MUITextField>, 'inputRef'> {
    delayedAutoFocus?: boolean | undefined;
    readOnly?: boolean | undefined;
    showCharacterCounter?: boolean | undefined;
}

const TextField = forwardRef<ElementRef<typeof MUITextField>, ITextFieldProps>(
    (
        {
            delayedAutoFocus,
            type,
            InputLabelProps = {},
            readOnly,
            error,
            InputProps = {},
            inputProps = {},
            value,
            defaultValue,
            showCharacterCounter,
            margin = 'normal',
            size = 'medium',
            variant = 'outlined',
            ...rest
        },
        ref,
    ) => {
        const inputRef: ComponentPropsWithoutRef<
            typeof MUITextField
        >['inputRef'] = useRef(null);
        const theme = useTheme();

        useEffect(() => {
            if (delayedAutoFocus) {
                setTimeout(() => {
                    inputRef?.current?.focus?.();
                }, 200);
            }
        }, [delayedAutoFocus]);

        const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
            if (type === 'number' && event.code === 'KeyE') {
                event.preventDefault();
            }
        };

        const newInputProps: ComponentPropsWithoutRef<
            typeof MUITextField
        >['InputProps'] = {};
        let newInputLabelProps = InputLabelProps;

        if (readOnly) {
            const notchedOutline = cx(readOnlyNotchedOutlineStyles, {
                [readOnlyNotchedOutlineColorStyles(theme)]: !error,
            });

            newInputProps.readOnly = true;
            newInputProps.classes = {
                notchedOutline,
            };
            newInputLabelProps = {
                shrink: Boolean(
                    value ||
                        defaultValue ||
                        inputProps.value ||
                        inputProps.defaultValue,
                ),
                ...InputLabelProps,
                classes: {
                    focused: readOnlyLabelFocusedStyles(theme),
                },
            };
        }

        const WrapperComponent = showCharacterCounter
            ? WrapperWithCharacterCounterStyled
            : Fragment;

        return (
            <WrapperComponent>
                {showCharacterCounter && typeof value === 'string' && (
                    <CharacterCounter currentCharacters={value.length} />
                )}
                <MUITextField
                    ref={ref}
                    margin={margin}
                    size={size}
                    variant={variant}
                    error={error}
                    inputRef={inputRef}
                    value={value}
                    defaultValue={defaultValue}
                    onKeyDown={handleKeyDown}
                    type={type}
                    InputProps={{
                        ...InputProps,
                        ...newInputProps,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: inputLabelRootStyles,
                            formControl: inputLabelRootStyles,
                        },
                        ...newInputLabelProps,
                    }}
                    inputProps={{ ...inputProps, role: 'textbox' }}
                    {...rest}
                />
            </WrapperComponent>
        );
    },
);

TextField.displayName = 'TextField';

export { TextField };
export default TextField;
