import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import Typography from 'components/Typography';

export const ContainerStyled = styled(Stack)`
  padding: 40px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
ContainerStyled.displayName = 'Container';

export const TitleStyled = styled(Typography)`
  font-size: 2rem;
  line-height: 2rem;
`;
TitleStyled.displayName = 'Title';

export const MessageStyled = styled(Typography)`
  font-family: monospace;
  text-align: left;
`;
MessageStyled.displayName = 'Message';
