import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function MoreIcon(props) {
    return (
        <SvgIcon
            viewBox="-6 -3 16 16"
            { ...props }
        >
            <path
                d="M3.16663 1.49998C3.16663 1.19056 3.04371 0.893814 2.82492 0.675022C2.60612 0.456229 2.30938 0.333313 1.99996 0.333313C1.69054 0.333313 1.39379 0.456229 1.175 0.675022C0.956209 0.893814 0.833293 1.19056 0.833293 1.49998C0.833293 1.8094 0.956209 2.10615 1.175 2.32494C1.39379 2.54373 1.69054 2.66665 1.99996 2.66665C2.30938 2.66665 2.60612 2.54373 2.82492 2.32494C3.04371 2.10615 3.16663 1.8094 3.16663 1.49998ZM3.16663 4.99998C3.16663 4.69056 3.04371 4.39381 2.82492 4.17502C2.60612 3.95623 2.30938 3.83331 1.99996 3.83331C1.69054 3.83331 1.39379 3.95623 1.175 4.17502C0.956209 4.39381 0.833293 4.69056 0.833293 4.99998C0.833293 5.3094 0.956209 5.60615 1.175 5.82494C1.39379 6.04373 1.69054 6.16665 1.99996 6.16665C2.30938 6.16665 2.60612 6.04373 2.82492 5.82494C3.04371 5.60615 3.16663 5.3094 3.16663 4.99998ZM3.16663 8.49998C3.16663 8.19056 3.04371 7.89382 2.82492 7.67502C2.60612 7.45623 2.30938 7.33331 1.99996 7.33331C1.69054 7.33331 1.39379 7.45623 1.175 7.67502C0.956209 7.89381 0.833293 8.19056 0.833293 8.49998C0.833293 8.8094 0.956209 9.10615 1.175 9.32494C1.39379 9.54373 1.69054 9.66665 1.99996 9.66665C2.30938 9.66665 2.60612 9.54373 2.82492 9.32494C3.04371 9.10615 3.16663 8.8094 3.16663 8.49998Z"
            />
        </SvgIcon>
    );
}

MoreIcon.defaultProps = {
    titleAccess: 'more-icon',
};

MoreIcon.propTypes = svgIconPropTypes;
