import type { AjaxService } from '../../../ajax/AjaxService';
import type { URLS } from '../../constants';
import type {
    OrderLinePatchOutputDTO,
    OrderLineRequestHoldShippingPayloadDTO,
} from './orderLine.type';
import type { default as errorHandlersFactory } from '../../errorHandlers';
import { ApplicationJsonHeaders } from '../../../constants';
import { TOrderCompany } from 'types/orderCompany.type';

export class OrderLineActions {
    private static instance: OrderLineActions | undefined;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly urls: typeof URLS,
        private readonly errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ) {
        this.requestHoldShipping = this.requestHoldShipping.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        urls: typeof URLS,
        errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ): OrderLineActions {
        if (!OrderLineActions.instance) {
            this.instance = new OrderLineActions(
                ajaxService,
                urls,
                errorHandlers,
            );
        }

        return this.instance;
    }

    async requestHoldShipping(
        orderLineId: string,
        payload: OrderLineRequestHoldShippingPayloadDTO,
        company: TOrderCompany = 'artgeist',
    ): Promise<OrderLinePatchOutputDTO> {
        const url = (
            company === 'artgeist'
                ? this.urls.PATCH_ORDER_LINE_REQUEST_HOLD_SHIPPING
                : this.urls.DECO_PATCH_ORDER_LINE_REQUEST_HOLD_SHIPPING
        ).replace('{id}', orderLineId);
        const config = { headers: ApplicationJsonHeaders };

        const response: { data: OrderLinePatchOutputDTO } =
            await this.ajaxService.patch({
                url,
                config,
                data: payload,
                onError: this.errorHandlers.patch,
            });

        return response.data;
    }
}
