import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        BOX: {
            DELETE: `${MODULE_NAME}_resources_box_delete`,
        },
    },
} as const;
