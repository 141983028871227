import findLast from 'lodash/findLast';
import findIndex from 'lodash/findIndex';
import FileUploadHandler from './FileUploadHandler';

export class FilesUploadService {
    constructor(Uppy, configs, plugins, authenticationService) {
        this.uppyService = new Uppy(configs.uppy);
        this.authenticationService = authenticationService;
        this.files = [];
        this.uploading = false;

        this.onUpload = this.onUpload.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.addFile = this.addFile.bind(this);
        this.addFiles = this.addFiles.bind(this);
        this.resetFiles = this.resetFiles.bind(this);
        this.uppyService
            .use(plugins.Tus, configs.tus)
            .on('upload', this.onUpload)
            .on('upload-progress', this.onUploadProgress)
            .on('upload-error', this.onUploadError)
            .on('upload-success', this.onUploadSuccess);
    }

    onUpload() {
        const token = this.authenticationService.getRawActiveToken();

        this.uppyService.getPlugin('Tus').setOptions({
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
    onUploadProgress(file, progress) {
        const { bytesTotal, bytesUploaded } = progress;
        const percentComplete = Math.floor((bytesUploaded / bytesTotal) * 100);
        const fileHandler = this.getFile(file.id);

        fileHandler.setProgress(percentComplete);
    }
    onUploadError(file, error, response) {
        const fileHandler = this.getFile(file.id);

        fileHandler.setError(error, response);
    }
    onUploadSuccess(file, response) {
        const fileHandler = this.getFile(file.id);

        fileHandler.setSuccess(response);
    }
    uploadFile(fileId) {
        const file = this.getFile(fileId);

        if (this.uploading) {
            return this.uploading.then(() => {
                this.uppyService.reset();
                this._addFile(file);

                return this._upload();
            });
        }
        this.uppyService.reset();
        this._addFile(file);

        return this._upload();
    }
    uploadFiles() {
        const files = this.getFiles();

        if (this.uploading) {
            return this.uploading.then(() => {
                this.uppyService.reset();
                files.forEach(this._addFile);

                return this._upload();
            });
        }
        this.uppyService.reset();
        files.forEach(this._addFile);

        return this._upload();
    }
    addFile({ file, metadata, customTUSUploadUrl = '' }) {
        const fileIndex = findIndex(
            this.files,
            (fileItem) => fileItem.getFileName() === file.name,
        );

        if (fileIndex > -1) {
            return this.files[fileIndex];
        }
        const fileHandler = new FileUploadHandler(
            file,
            metadata,
            this,
            customTUSUploadUrl,
        );

        this.files.push(fileHandler);

        return fileHandler;
    }
    addFiles(filesObjects) {
        return filesObjects.map(this.addFile);
    }
    getFile(fileId) {
        return findLast(
            this.files,
            (fileHandlerItem) =>
                fileHandlerItem.getFileId() === fileId ||
                fileHandlerItem.getUppyId() === fileId,
        );
    }
    getFiles() {
        return this.files;
    }
    removeFile(fileId) {
        const fileIndex = findIndex(
            this.files,
            (fileHandlerItem) =>
                fileHandlerItem.getFileId() === fileId ||
                fileHandlerItem.getUppyId() === fileId,
        );

        this.files.splice(fileIndex, 1);
    }
    _upload() {
        this.uploading = this.uppyService.upload().then((response) => {
            this.uploading = false;

            return response;
        });

        return this.uploading;
    }
    _addFile = (file) => {
        const filePayload = file.getUploadPayload();
        const fileId = this.uppyService.addFile(filePayload);

        file.setUppyId(fileId);

        if (file.customTUSUploadUrl !== '') {
            this.uppyService.setFileState(fileId, {
                tus: {
                    endpoint: file.customTUSUploadUrl,
                },
            });
        }
    };
    pauseUploadFile(fileId) {
        const fileHandler = this.getFile(fileId);
        const uppyId = fileHandler.getUppyId();

        if (uppyId) {
            this.uppyService.pauseResume(uppyId);
        }
    }
    retryUploadFile(fileId) {
        const fileHandler = this.getFile(fileId);
        const uppyId = fileHandler.getUppyId();

        if (uppyId) {
            this.uppyService.retryUpload(uppyId);
        }
    }
    pauseAll() {
        this.uppyService.pauseAll();
    }
    resumeAll() {
        this.uppyService.resumeAll();
    }
    retryUploadFiles() {
        this.uppyService.retryAll();
    }
    cancelAll() {
        this.uppyService.cancelAll();
    }
    reset() {
        this.uppyService.reset();
    }
    resetFiles() {
        this.files = [];
    }
}

export default FilesUploadService;
