import { mapDateFromApi } from 'services/salesChannels/mappers/date';

export const mapChannelPatternFromAPI = (data = {}) => ({
    id: data?.pattern?.code || '',
    code: data?.pattern?.code || '',
    thumbnail: {
        httpUrl: data?.pattern?.thumbnail_url || '',
    },
    createdAt: mapDateFromApi(data?.pattern?.created_at || ''),
    additionalColors: data?.additional_colors ?? [],
    mainColor: data?.main_color ?? null,
});

export const mapChannelPatternListFromAPI = (data = []) =>
    data.map((item) => ({
        id: item?.pattern?.code || '',
        thumbnailUrl: item?.pattern?.thumbnail_url || '',
        patternCode: item?.pattern?.code || '',
        technologyName: item?.pattern?.technology?.name || '',
        technologyGroupName:
            item?.pattern?.technology?.technology_group?.name || '',
        createdAt: item?.pattern?.created_at || '',
        completeTitles: item?.['is_complete_titles'] || false,
        completeCategories: item?.['is_complete_categories'] || false,
        completeColors: item?.['is_complete_colors'] || false,
        completeTags: item?.['is_complete_tags'] || false,
        languagesCount: item?.['languages_count'] || 0,
        titlesCount: item?.['titles_count'] || 0,
        categoriesCount: item?.['categories_count'] || 0,
        tagsCount: item?.['tags_count'] || 0,
        colorsCount: item?.['colors_count'] || 0,
    }));

export const mapPatternUpdateToAPI = (data = {}) => ({
    ...(data?.categories
        ? { categories: data.categories.map(({ id }) => ({ id })) }
        : {}),
    ...(data?.tags ? { tags: data.tags.map(({ id }) => ({ id })) } : {}),
});
