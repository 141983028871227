import React from 'react';
import * as PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import Modal from 'components/Modal/modal';

export class SubmitModal extends React.PureComponent {
    render() {
        const {
            t,
            onClose,
            onSubmit,
            active,
            formId,
        } = this.props;

        return (
            <Modal
                danger
                active={ active }
                closeModal={ onClose }
                onSubmit={ onSubmit }
                leftButton={{
                    children: upperFirst(t('common:actions.cancel')),
                    onClick: onClose,
                }}
                rightButton={{
                    type: 'submit',
                    form: formId,
                    children: upperFirst(t('common:actions.ok')),
                }}
                label={ t('common:labels.confirmModal') }
            >
                { t('common:contents.confirmModal') }
            </Modal>
        );
    }
}

SubmitModal.defaultProps = {
    t: (key) => key,
};

SubmitModal.propTypes = {
    t: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    active: PropTypes.bool,
    formId: PropTypes.string,
};
