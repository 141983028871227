import { connect } from 'react-redux';
import { getSearch } from 'utils/router/selectors';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import {
    resetProductPossibilities,
    fetchProductPossibilities,
    generateSkuForProductPossibilities,
    generateAdditionalSkuForProductPossibilities,
} from 'modules/stablo/reducers/productPossibilities/actions';
import ProductPossibilitiesTable from 'modules/stablo/components/ProductPossibilitiesTable/ProductPossibilitiesTable';
import {
    selectSearchedQuery,
    selectLoader,
    selectProductPossibilities,
    selectInvalidPatterns,
} from 'modules/stablo/reducers/productPossibilities/selectors';

const mapStateToProps = (state) => {
    const search = qs.parse(getSearch(state));

    return {
        patternQuery: search.patterns || '',
        activePage: Number(search.pagination && search.pagination.page) || 1,
        per_page: Number(search.pagination && search.pagination.per_page) || 20,
        defaultQuery: selectSearchedQuery(state),
        productPossibilities: selectProductPossibilities(state),
        loading: selectLoader(state),
        invalidPatterns: selectInvalidPatterns(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetProductPossibilities: (data) => dispatch(resetProductPossibilities(data)),
        fetchProductPossibilities: (data) => dispatch(fetchProductPossibilities.request(data)),
        generateSkuForProductPossibilities: (data) => dispatch(generateSkuForProductPossibilities.request(data)),
        generateAdditionalSkuForProductPossibilities: (data) =>
            dispatch(generateAdditionalSkuForProductPossibilities.request(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPossibilitiesTable));
