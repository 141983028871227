/* istanbul ignore file */
import i18next from 'i18next';
import moment from 'moment';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const FALLBACK_LANGUAGE = 'pl';

export const getActiveLanguage = () => {
    const code = i18next.language;

    if (!code) {
        return FALLBACK_LANGUAGE;
    }

    if (code.indexOf('-') < 0) {
        return code;
    } else {
        return code.split('-')[0]; // return the first part of language code e.g 'en-US' -> 'en'
    }
};

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            ns: [
                'app',
                'arm',
                'common',
                'libraries',
                'demo',
                'pdp',
                'stablo',
                'prodis',
                'dropshipping',
                'production',
                'sales',
                'stock',
                'manageOffers',
                'manageTasks',
                'translations',
                'postSales',
                'boxes',
                'userService',
                'workplaces',
                'test-helpers',
            ],
            defaultNS: 'common',
            supportedLngs: ['pl', 'en'],
            fallbackLng: FALLBACK_LANGUAGE,
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: false,
            },
            load: 'languageOnly',
        },
        () => {
            const lng = localStorage.getItem('i18nextLng');

            if (lng === 'en') moment.locale('en');
            else moment.locale(FALLBACK_LANGUAGE);
        },
    );

export default i18next;
