import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { fromJS } from 'immutable';
import upperFirst from 'lodash/upperFirst';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Loader } from 'components/Loader/Loader';
import ExitEditModal from 'components/ExitEditModal/ExitEditModal';
import Modal from 'components/Modal/modal';
import Body from './Body/body';
import Head from './Head/head';
import Pagination from './Pagination/pagination';
import { EmptyTableStyled, PaperStyled, RootStyled } from 'components/OldTable/oldTable.styles';
import { sortByKey } from 'utils/immutable/list';

class Table extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editedItem: null,
            referencedEditedItem: null,
            pickingId: null,
            submitModalActive: false,
            cancelEditModalActive: false,
            url: {},
        };
    }

    componentDidUpdate(prevProps) {
        const { search, fetchItems, withPagination, perPageItems } = this.props;

        if (withPagination && prevProps.search !== search) {
            this.setState({ url: qs.parse(search) });
            fetchItems && fetchItems();
        }

        if (perPageItems !== prevProps.perPageItems) {
            this.setState({
                url: {
                    pagination: {
                        page: 1,
                    },
                },
            });
        }
    }

    componentDidMount() {
        const { search, fetchItems } = this.props;

        this.setState({ url: qs.parse(search) });
        fetchItems && fetchItems({ refresh: true });
    }

    changePage = (page) => {
        const { push, hash, submitPagination } = this.props;
        const { url } = this.state;

        url.pagination ?
            url.pagination.page = page
            :
            url.pagination = { page: page };
        if (push) push({ hash, search: qs.stringify(this.state['url']) });
        if (submitPagination) submitPagination({ page });
    };

    pickItemPreprocessor(id) {
        const { itemsById, pickItemPreprocessor } = this.props;
        const { pickingId } = this.state;

        if (itemsById.get(pickingId)) {
            return pickItemPreprocessor(itemsById.get(pickingId));
        }

        if (itemsById.get(id)) {
            return pickItemPreprocessor(itemsById.get(id));
        }

        const foundItem = itemsById.find(function (o) {
            return o.get('id') === id;
        });

        if (foundItem) {
            return pickItemPreprocessor(foundItem);
        }

        return null;
    }

    pickEditedItem = (id) => {
        const { editedItem, cancelEditModalActive, submitModalActive } = this.state;

        if (editedItem && !cancelEditModalActive && !submitModalActive && this.editedItemChange()) {
            this.setState({
                pickingId: id,
                cancelEditModalActive: true,
            });
        } else {
            this.setState({
                pickingId: null,
                cancelEditModalActive: false,
                submitModalActive: false,
                editedItem: this.pickItemPreprocessor(id),
                referencedEditedItem: this.pickItemPreprocessor(id),
            });
        }
    };

    changeEditedItem = (item) => {
        this.setState({ editedItem: item });
    };

    submitEdit = () => {
        const { submitEditRow } = this.props;
        const { editedItem, submitModalActive } = this.state;
        const itemDidChange = this.editedItemChange();

        if (editedItem && !submitModalActive && itemDidChange) {
            this.setState({
                submitModalActive: true,
            });
        } else {
            if (itemDidChange) submitEditRow(editedItem);
            this.setState({
                submitModalActive: false,
                editedItem: null,
                referencedEditedItem: null,
            });
        }
    };

    editedItemChange = () => {
        const { itemsById } = this.props;
        const { editedItem, referencedEditedItem } = this.state;

        if (!itemsById || !editedItem) return false;

        return !referencedEditedItem.equals(editedItem);
    };

    getSearchOrder(search) {
        const order = search.order;

        if (!order) return {
            searchLabel: null,
            isDesc: null,
        };

        const label = Object.keys(order)[0];
        const isDesc = order[label] === 'desc';

        return {
            searchLabel: label,
            isDesc: isDesc,
        };
    }

    getPaginationProps() {
        const { t, itemsTotal, defaultItemsPerPage, minWidth, perPageItems } = this.props;
        const activePage = Number(this.state.url.pagination && this.state.url.pagination.page) || 1;
        const per_page = this.state.url.pagination && this.state.url.pagination.per_page
            ? Number(this.state.url.pagination.per_page)
            : perPageItems || defaultItemsPerPage;

        return {
            t,
            activePage,
            minWidth,
            changePage: this.changePage,
            pageCount: Math.ceil(itemsTotal / per_page) || 1,
            defaultItemsPerPage: defaultItemsPerPage,
        };
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    getActionCell() {
        const {
            detailsLink,
            editLink,
            handleCopy,
            handleDownload,
            customActionButton,
            secondaryCustomActionButton,
            handleSend,
            handlePrint,
            handleRemove,
            showListView,
            submitEditRow,
            showDetails,
            showHistory,
            uploadFiles,
        } = this.props;

        let width = 0;
        const iconWidth = 34;

        if (detailsLink) width += iconWidth;
        if (editLink) width += iconWidth;
        if (handleCopy) width += iconWidth;
        if (handleDownload) width += iconWidth;
        if (customActionButton) width += iconWidth;
        if (secondaryCustomActionButton) width += iconWidth;
        if (handleSend) width += iconWidth;
        if (handlePrint) width += iconWidth;
        if (handleRemove) width += iconWidth;
        if (showListView) width += iconWidth;
        if (submitEditRow) width += iconWidth;
        if (showDetails) width += iconWidth;
        if (showHistory) width += iconWidth;
        if (uploadFiles) width += iconWidth;

        if (width > 0 && width < iconWidth * 2) width = iconWidth * 2;

        return width ? ` ${width + 16}px` : '';
    }

    getActions() {
        const {
            path,
            detailsLink,
            handleCopy,
            handleDownload,
            customActionButton,
            secondaryCustomActionButton,
            handlePrint,
            handleSend,
            handleRemove,
            showListView,
            showDetails,
            showHistory,
            uploadFiles,
            canRemoveItem,
            listViewDisabled,
            submitEditRow,
            editLink,
            getListViewLink,
        } = this.props;

        const actions = {};

        if (detailsLink) actions.detailsLink = path;
        if (submitEditRow) actions.submitEditRow = submitEditRow;
        if (editLink) actions.editLink = path;
        if (handleCopy) {
            actions.handleCopy = handleCopy;
            actions.copyLink = `${path}/create`;
        }
        if (handleDownload) actions.handleDownload = handleDownload;
        if (customActionButton) actions.customActionButton = customActionButton;
        if (secondaryCustomActionButton) actions.secondaryCustomActionButton = secondaryCustomActionButton;
        if (handlePrint) actions.handlePrint = handlePrint;
        if (handleSend) actions.handleSend = handleSend;
        if (showDetails) actions.showDetails = showDetails;
        if (showHistory) actions.showHistory = showHistory;
        if (uploadFiles) actions.uploadFiles = uploadFiles;
        if (handleRemove) {
            actions.handleRemove = handleRemove;
            actions.canRemoveItem = canRemoveItem;
        }
        if (showListView) {
            actions.showListView = showListView;
            actions.listViewDisabled = listViewDisabled;
            actions.getListViewLink = getListViewLink;
        }

        return actions;
    }

    getItemsArray() {
        const { itemsById, selfSortable } = this.props;
        const { searchLabel, isDesc } = this.getSearchOrder(this.state.url);

        if (selfSortable && searchLabel) {
            return sortByKey(itemsById, searchLabel, isDesc)
                .toArray();
        } else {
            return itemsById.toArray();
        }
    }

    handleSort = (key) => {
        if (key) {
            const newSearch = this.state.url;
            const { push, hash, sortCallback } = this.props;
            const order = newSearch.order;

            if (typeof key === 'string') {
                if (order && order[key]) {
                    order[key] === 'desc' ?
                        newSearch.order = { [key]: 'asc' }
                        :
                        newSearch.order = { [key]: 'desc' };
                } else {
                    newSearch.order = { [key]: 'desc' };
                }
            } else if (Array.isArray(key)) {
                newSearch.order = {};
                if (order && order[key[0]]) {
                    order[key[0]] === 'desc' ?
                        key.map((singleKey) => {
                            newSearch.order[singleKey] = 'asc';
                        })
                        :
                        key.map((singleKey) => {
                            newSearch.order[singleKey] = 'desc';
                        });
                } else {
                    key.map((singleKey) => {
                        newSearch.order[singleKey] = 'desc';
                    });
                }
            }
            push && push({
                search: qs.stringify(newSearch),
                hash,
            });
            sortCallback && sortCallback(newSearch);
        }
    };

    renderEditModals() {
        const {
            t,
            confirmEditModal = {},
        } = this.props;
        const {
            cancelEditModalActive,
            pickingId,
            submitModalActive,
        } = this.state;

        return [
            <ExitEditModal
                t={ t }
                key="exitModal"
                active={ cancelEditModalActive }
                closeModal={ () => this.setState({
                    pickingId: null,
                    cancelEditModalActive: false,
                }) }
                onSubmit={ () => this.pickEditedItem(pickingId) }
            />,
            <Modal
                danger
                id="edit-confirmation-modal"
                key="editModal"
                active={ submitModalActive }
                closeModal={ () => this.setState({ submitModalActive: false }) }
                onSubmit={ this.submitEdit }
                leftButton={{
                    children: upperFirst(t('common:actions.cancel')),
                    onClick: this.pickEditedItem,
                }}
                rightButton={ upperFirst(t('common:actions.ok')) }
                label={ confirmEditModal.label }
            >
                {confirmEditModal.content}
            </Modal>,
            <NavigationPrompt
                key="navPrompt"
                when={ this.editedItemChange() }
            >
                {({ onConfirm, onCancel }) => (
                    <ExitEditModal
                        t={ t }
                        active
                        closeModal={ onCancel }
                        onSubmit={ onConfirm }
                    />
                )}
            </NavigationPrompt>
        ];
    }

    renderBody = ({ hasItems, bodyStyle, actions, template }) => {
        const {
            t,
            id,
            isCollectionComplete,
            onSelect,
            keyName,
            columns,
            extendedRowComponent,
            customExtendedRowComponent,
            showExtendedIcon,
            onRowClick,
            extendedAll,
            extendedItems,
            compressedItems,
            smallRows,
            isSelectedAll,
            selectedItems,
            forceSelected,
            submitEditRow,
            withPagination,
            loading,
            fetchItems,
        } = this.props;
        const { editedItem } = this.state;
        const columnStyles = { gridTemplateColumns: template };
        const itemsArray = this.getItemsArray();

        return hasItems ? (
            <Body
                t={ t }
                id={ `${id}-body` }
                bodyStyle={ bodyStyle }
                isCollectionComplete={ isCollectionComplete }
                fetchItems={ fetchItems }
                columns={ columns || [] }
                columnStyles={ columnStyles }
                isSelectedAll={ isSelectedAll }
                edit={ submitEditRow &&
                    {
                        item: editedItem,
                        pick: this.pickEditedItem,
                        changeItem: this.changeEditedItem,
                        submit: this.submitEdit,
                    }
                }
                onSelect={ onSelect }
                selectedItems={ selectedItems }
                forceSelected={ forceSelected }
                itemsArray={ itemsArray }
                keyName={ keyName }
                actions={ actions }
                toggleExtend={ onRowClick }
                smallRows={ smallRows }
                extendedAll={ extendedAll }
                extendedRowComponent={ extendedRowComponent }
                customExtendedRowComponent={ customExtendedRowComponent }
                showExtendedIcon={ showExtendedIcon }
                extendedItems={ extendedItems }
                compressedItems={ compressedItems }
                withPagination={ withPagination }
                loading={ loading }
            />
        ) : (
            <EmptyTableStyled>
                {!loading && t('common:labels.countMessages.empty')}
            </EmptyTableStyled>
        );
    };

    renderHead = ({ hasItems, hasActions, headerColumnStyles }) => {
        const {
            t,
            id,
            flatStyle,
            onSelectAll,
            columns,
            extendedRowComponent,
            customExtendedRowComponent,
            smallRows,
            isSelectedAll,
            isSelectedAllFetched,
            isAnySelected,
        } = this.props;
        const { url } = this.state;

        return (
            <Head
                t={ t }
                id={ `${id}-header` }
                flatStyle={ flatStyle }
                smallRows={ smallRows }
                columns={ columns || [] }
                columnStyles={ headerColumnStyles }
                onSelectAll={ onSelectAll }
                extendable={ Boolean(extendedRowComponent) || Boolean(customExtendedRowComponent) }
                onSortAll={ this.handleSort }
                isSelectedAll={ hasItems && (isSelectedAllFetched || isSelectedAll) }
                isAnySelected={ hasItems && isAnySelected }
                withActions={ hasActions }
                order={ url.order || url.sortOrder && { sortOrder: url.sortOrder } || {} }
            />
        );
    };

    renderPagination = ({ footerStyles }) => {
        const paginationProps = this.getPaginationProps();

        const {
            id,
            flatStyle,
            perPageOptions,
            submitPagination,
            perPageItems,
        } = this.props;

        return (
            <Pagination
                id={ `${id}-footer` }
                flatStyle={ flatStyle }
                footerStyles={ footerStyles }
                perPageOptions={ perPageOptions }
                submitPagination={ submitPagination }
                perPageItems={ perPageItems }
                { ...paginationProps }
            />
        );
    };

    render() {
        const {
            id,
            flatStyle,
            onSelect,
            columnTemplate,
            minWidth,
            extendedRowComponent,
            customExtendedRowComponent,
            itemsById,
            submitEditRow,
            withPagination,
            loading,
        } = this.props;
        const template = `
                ${onSelect ? '48px ' : ''}
                ${extendedRowComponent || customExtendedRowComponent ? '48px [start] ' : '[start] '}
                ${columnTemplate}
                ${this.getActionCell()}
                [end]
            `;

        const headerColumnStyles = { gridTemplateColumns: `${template} 10px` };
        const bodyStyle = {};
        const footerStyles = {};

        if (minWidth) {
            headerColumnStyles.minWidth = minWidth;
            bodyStyle.minWidth = minWidth;
            footerStyles.minWidth = minWidth;
        }

        const actions = this.getActions();

        const hasItems = itemsById.size > 0;
        const hasActions = fromJS(actions).size > 0;

        return (
            <RootStyled flatStyle={ flatStyle }>
                <PaperStyled id={ id }>
                    {submitEditRow && this.renderEditModals()}
                    {this.renderHead({ hasItems, hasActions, headerColumnStyles })}
                    {loading && <Loader />}
                    {this.renderBody({ hasItems, bodyStyle, actions, template })}
                    {withPagination && this.renderPagination({ footerStyles })}
                </PaperStyled>
            </RootStyled>
        );
    }
}

Table.defaultProps = {
    t: (key) => key,
    itemsById: fromJS({}),
    keyName: 'id',
    isCollectionComplete: true,
    pickItemPreprocessor: (item) => item,
    defaultItemsPerPage: 20,
    itemsTotal: 1,
};

Table.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    flatStyle: PropTypes.bool,
    fetchItems: PropTypes.func,
    isCollectionComplete: PropTypes.bool,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    loading: PropTypes.bool,
    keyName: PropTypes.string,
    columns: PropTypes.array,
    itemsById: PropTypes.object,
    selfSortable: PropTypes.bool,

    columnTemplate: PropTypes.string,
    minWidth: PropTypes.string,

    extendedRowComponent: PropTypes.array,
    customExtendedRowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    showExtendedIcon: PropTypes.func,
    onRowClick: PropTypes.func,
    extendedAll: PropTypes.bool,
    smallRows: PropTypes.bool,
    extendedItems: PropTypes.object,
    compressedItems: PropTypes.object,
    path: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    push: PropTypes.func,
    sortCallback: PropTypes.func,
    detailsLink: PropTypes.bool,
    handleCopy: PropTypes.func,
    handleDownload: PropTypes.func,
    customActionButton: PropTypes.shape({}),
    secondaryCustomActionButton: PropTypes.shape({}),
    handleRemove: PropTypes.func,
    showListView: PropTypes.func,
    showDetails: PropTypes.func,
    showHistory: PropTypes.func,
    uploadFiles: PropTypes.func,
    canRemoveItem: PropTypes.func,
    listViewDisabled: PropTypes.func,
    getListViewLink: PropTypes.func,
    isSelectedAll: PropTypes.bool,
    isAnySelected: PropTypes.bool,
    submitEditRow: PropTypes.func,
    pickItemPreprocessor: PropTypes.func,
    confirmEditModal: PropTypes.shape({
        label: PropTypes.node,
        content: PropTypes.node,
    }),
    withPagination: PropTypes.bool,
    itemsTotal: PropTypes.number,
    editLink: PropTypes.bool,
    defaultItemsPerPage: PropTypes.number,
    perPageOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    })),
    isSelectedAllFetched: PropTypes.bool,
    selectedItems: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    handlePrint: PropTypes.func,
    handleSend: PropTypes.func,
    submitPagination: PropTypes.func,
    perPageItems: PropTypes.number,
    forceSelected: PropTypes.object,
};

export default Table;
