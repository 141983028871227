import type { SalesChannelsService } from '../SalesChannelsService';
import {
    mapCreateChannelPreviewToApi,
    mapGetPreviewTypesList,
} from '../mappers/channelPreviews';
import { configError } from 'services/utils/utils';

class ChannelPreviews {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.create = this.create.bind(this);
        this.getPreviewTypesList = this.getPreviewTypesList.bind(this);
    }

    async create(channelId, data) {
        const { ajax, URLS, HEADERS, errorHandlers, alert, translator } =
            this.salesChannelsService;

        const url = URLS.POST_CHANNEL_PREVIEW_TYPE.replace('{id}', channelId);
        const errorConfig = configError('post', errorHandlers);
        const config = {
            headers: HEADERS.basic,
        };

        const response = await ajax.post({
            url,
            errorConfig,
            config,
            data: mapCreateChannelPreviewToApi(data),
        });

        alert.addSuccess({
            message: translator.t('manageOffers:alerts.channelPreviewCreated'),
        });

        return response;
    }

    async getPreviewTypesList({
        itemId,
        params: { page, per_page: itemsPerPage } = {},
    }: {
        itemId: string;
        params?:
            | {
                  page?: number | undefined;
                  per_page?: number | undefined;
              }
            | undefined;
    }) {
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;

        const url = URLS.GET_CHANNEL_PREVIEW_TYPES.replace('{id}', itemId);
        const config = {
            headers: HEADERS.basic,
            params: {
                page,
                itemsPerPage,
            },
        };
        const errorConfig = configError('get', errorHandlers);

        const emptyResponse = {
            items: 0,
            totalItems: [],
        };

        try {
            const response = await ajax.get({ url, errorConfig, config });

            if (response?.data) {
                const { total, results } = response.data;

                return mapGetPreviewTypesList({ results, total });
            }

            return emptyResponse;
        } catch {
            return emptyResponse;
        }
    }
}

export default ChannelPreviews;
