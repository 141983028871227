import styled from '@emotion/styled';
import Paper from 'components/Paper/paper';

export const SearchCardStyled = styled(Paper)`
  padding: 12px 24px 24px;
  margin: 0
`;
SearchCardStyled.displayName = 'SearchCard';

export const SearchButtonsStyled = styled('div')`
  display: grid;
  grid-column-gap: 4px;
  justify-content: flex-end;
  grid-template-columns: 124px auto;
`;
SearchButtonsStyled.displayName = 'SearchButtons';
