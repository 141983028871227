import { MODULE_NAME } from '../../constants';
import { makeRequestConstant } from 'utils/redux/makeConstants';

export const CREATE_INTERMEDIATE_PRODUCT = makeRequestConstant(MODULE_NAME + '_CREATE_INTERMEDIATE_PRODUCT');
export const REMOVE_INTERMEDIATE_PRODUCT = makeRequestConstant(MODULE_NAME + '_REMOVE_INTERMEDIATE_PRODUCT');
export const EDIT_INTERMEDIATE_PRODUCT = makeRequestConstant(MODULE_NAME + '_EDIT_INTERMEDIATE_PRODUCT');

export const FETCH_INTERMEDIATE_PRODUCTS = makeRequestConstant(MODULE_NAME + '_FETCH_INTERMEDIATE_PRODUCTS');
export const FETCH_INTERMEDIATE_PRODUCT = makeRequestConstant(MODULE_NAME + '_FETCH_INTERMEDIATE_PRODUCT');
export const FETCH_TECHNOLOGY_COMPONENTS = makeRequestConstant(MODULE_NAME + '_FETCH_TECHNOLOGY_COMPONENTS');

export const CLEAR_ERRORS = MODULE_NAME + '_CLEAR_ERRORS';
export const CLEAR_POST_RESPONSE = MODULE_NAME + '_CLEAR_POST_RESPONSE';
