import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CsvUploadIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 18 20"
            { ...props }
        >
            <path
                d="M0 0V19.1667H12.0833V18.3333H0.833333V0.833333H8.75V6.24349H14.1667V10.4997L15 11.5332V5.6665L14.8934 5.5599C14.8783 5.5417 14.8617 5.52482 14.8438 5.50944L9.3335 0H0ZM9.58333 1.41683L13.5767 5.41016H9.58333V1.41683ZM4.77539 8.75C3.90081 8.75 2.90202 9.4276 2.90202 11.3151C2.90202 12.8314 3.54303 13.75 4.66553 13.75C4.98636 13.75 5.24154 13.6992 5.38737 13.6263L5.29297 12.9704C5.1763 13.0137 4.99409 13.0583 4.81201 13.0583C4.22159 13.0583 3.72559 12.5838 3.72559 11.2646C3.72559 9.9234 4.27179 9.43522 4.80387 9.43522C5.03721 9.43522 5.15433 9.4782 5.271 9.53695L5.41667 8.8737C5.32125 8.81495 5.09622 8.75 4.77539 8.75ZM8.93799 8.75L10.0651 13.75H10.9334L12.0833 8.75H11.2231L10.8219 10.9757C10.7178 11.5545 10.607 12.1922 10.5404 12.8076H10.5176C10.4434 12.1997 10.3176 11.5396 10.2214 10.9904L9.80631 8.75H8.93799ZM7.6595 8.75081C6.74076 8.75081 6.22314 9.35503 6.22314 10.1204C6.22314 10.6525 6.60258 11.1405 7.14925 11.4901C7.5505 11.7676 7.79053 11.9935 7.79053 12.3869C7.79053 12.7956 7.52796 13.0729 7.04671 13.0729C6.7913 13.0729 6.47115 12.9851 6.28906 12.876L6.15723 13.5465C6.31056 13.649 6.68213 13.7508 7.04671 13.7508C7.92921 13.7508 8.5555 13.1892 8.5555 12.3071C8.5555 11.7825 8.30838 11.3088 7.6888 10.9009C7.18547 10.5805 6.98893 10.4052 6.98893 10.026C6.98893 9.72646 7.20027 9.42871 7.64486 9.42871C7.90736 9.42871 8.11241 9.50804 8.25033 9.59554L8.38867 8.92578C8.25784 8.84536 7.97242 8.75081 7.6595 8.75081ZM14.3734 12.0833L10.8333 16.25H12.9167V20H15.8333V16.25H17.9167L14.3734 12.0833ZM14.3734 13.3333L16.0832 15.4167H15V19.1667H13.75V15.4167H12.6668L14.3734 13.3333Z"
            />
        </SvgIcon>
    );
}

CsvUploadIcon.defaultProps = {
    titleAccess: 'csv-upload-icon',
};

CsvUploadIcon.propTypes = svgIconPropTypes;
