import { NavListItemButton } from './NavListItemButton';
import { ExpandedModuleList } from './ExpandedModuleList';
import { useTranslation } from 'react-i18next';
import type {
    NavBarModule,
    TActiveModule,
    TExpandModuleHandler,
    TExtendedModule,
} from './navBar.type';
import { NavTooltip } from './NavTooltip';

interface IModuleProps {
    module: NavBarModule;
    onClick: TExpandModuleHandler;
    extended: boolean;
    activeModule: TActiveModule;
    extendedModule: TExtendedModule;
    isSubmodule?: boolean | undefined;
}

export const Module = ({
    module,
    activeModule,
    extendedModule,
    onClick,
    extended,
    isSubmodule = false,
}: IModuleProps) => {
    const { t } = useTranslation(['app']);

    const moduleName = t(`navigation.${module.name}.main`, {
        defaultValue: module.name,
        ns: 'app',
    });

    const isActive = isSubmodule
        ? activeModule === module.name
        : activeModule?.includes(module.name);
    const isOpen = isSubmodule
        ? extendedModule === module.name
        : extendedModule?.includes(module.name);

    return (
        <NavTooltip
            tooltipTitle={moduleName}
            isTooltip={!extended && !!module.icon}
        >
            <NavListItemButton
                moduleName={moduleName}
                onClick={onClick}
                isOpen={isOpen}
                isActive={isActive}
                fixed={false}
                sections={module.routes}
                module={module}
                isSubmodule={isSubmodule}
            />
            <ExpandedModuleList
                isOpen={isOpen}
                module={module}
                sections={module.routes}
                additionalItems={
                    Array.isArray(module.submodules) &&
                    module.submodules.map((submodule) => (
                        <Module
                            key={submodule.name}
                            module={submodule}
                            activeModule={activeModule}
                            extendedModule={extendedModule}
                            onClick={onClick}
                            extended={extended}
                            isSubmodule
                        />
                    ))
                }
            />
        </NavTooltip>
    );
};
