import { omitNegative } from '../../../utils/filters';
import { ApplicationJsonHeaders } from '../../constants';
import type {
    IGetWorkplacesData,
    IGetWorkplacesReturnData,
    IWorkplace,
    IWorkplaceContext,
    CreatePrintoutDto,
    IWorkplaceData,
    IWorkplaceServices,
} from './workplace.type';

export class Workplace {
    static getWorkplacesCacheKey = 'cachedWorkplaceGetWorkplaces';
    private services: IWorkplaceServices;
    private URLS: IWorkplaceContext['URLS'];

    constructor(services: IWorkplaceServices, { URLS }: IWorkplaceContext) {
        this.services = services;
        this.URLS = URLS;

        this.getWorkplaces = this.getWorkplaces.bind(this);
        this.getCachedWorkplaces = this.getCachedWorkplaces.bind(this);
        this.getWorkplaceById = this.getWorkplaceById.bind(this);
        this.printout = this.printout.bind(this);
        this.postWorkplaces = this.postWorkplaces.bind(this);
        this.putWorkplaces = this.putWorkplaces.bind(this);
    }

    async getWorkplaces({
        filters = {},
        per_page = 200,
        page = 1,
    }: IGetWorkplacesData = {}): Promise<IGetWorkplacesReturnData> {
        const {
            URLS: { GET_WORKPLACES },
            services: { ajax },
        } = this;

        const { data } = await ajax.get({
            url: GET_WORKPLACES,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...omitNegative({
                        printer_status: filters?.status,
                        printer_type: filters?.type,
                        code: filters?.search,
                    }),
                    page,
                    itemsPerPage: per_page,
                },
            },
        });

        const mappedData = {
            items: data?.results ?? [],
            itemsTotal: data?.total ?? 0,
        };

        localStorage.setItem(
            Workplace.getWorkplacesCacheKey,
            JSON.stringify(mappedData),
        );

        return mappedData;
    }

    getCachedWorkplaces(): IGetWorkplacesReturnData {
        const cached = localStorage.getItem(Workplace.getWorkplacesCacheKey);

        return JSON.parse(cached);
    }

    async getWorkplaceById(id: string): Promise<IWorkplace> {
        const {
            URLS: { GET_WORKPLACE },
            services: { ajax },
        } = this;

        const response: { data: IWorkplace } = await ajax.get({
            url: GET_WORKPLACE.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
        });

        return response.data;
    }

    async printout(
        printerCode: string,
        payload: CreatePrintoutDto,
    ): Promise<void> {
        const {
            URLS: { PRINTOUT },
            services: { ajax },
        } = this;

        await ajax.post({
            url: PRINTOUT.replace('{printerCode}', printerCode),
            data: payload,
            config: {
                headers: ApplicationJsonHeaders,
            },
        });
    }

    async postWorkplaces(data: IWorkplaceData): Promise<void> {
        const {
            URLS: { POST_WORKPLACES },
            services: { ajax, alertService },
        } = this;

        await ajax.post({
            url: POST_WORKPLACES,
            config: {
                headers: ApplicationJsonHeaders,
            },
            data,
            errorConfig: undefined,
            onError: (error = {}) => {
                const { response = {} }: any = error;
                const { data = {} } = response;

                if (data.detail) {
                    alertService.addError({
                        message: data.detail,
                        options: { persist: true },
                    });

                    throw error;
                }
            },
        });
    }

    async putWorkplaces(id: string, data: IWorkplaceData): Promise<void> {
        const {
            URLS: { PUT_WORKPLACES },
            services: { ajax, alertService },
        } = this;

        await ajax.put({
            url: PUT_WORKPLACES.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            data,
            errorConfig: undefined,
            onError: (error = {}) => {
                const { response = {} }: any = error;
                const { data = {} } = response;

                if (data.detail) {
                    alertService.addError({
                        message: data.detail,
                        options: { persist: true },
                    });

                    throw error;
                }
            },
        });
    }
}
