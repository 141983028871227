import React from 'react';
import PropTypes from 'prop-types';
import {
    FieldsContainerStyled,
    FieldStyled,
    LabelStyled,
    TitleStyled,
} from 'components/OldTable/ExtendedRow/extendedRow.styles';

class ExtendedRow extends React.PureComponent {
    renderFieldsCells(section) {
        const { t, item } = this.props;
        const cells = [];

        section.fields(item).map((field, index) => {
            cells.push(
                <FieldStyled
                    key={ `${ index } - label` }
                >
                    <LabelStyled>
                        { t(field.label) }
                        :
                    </LabelStyled>
                    { field.value }
                </FieldStyled>
            );
        });

        return (
            <FieldsContainerStyled>
                { cells }
            </FieldsContainerStyled>
        );
    }

    render() {
        const {
            t,
            sections,
        } = this.props;

        return (
            <div>
                {
                    sections.map((section, index) => {
                        const { label } = section;

                        return (
                            <div key={ label || index }>
                                { label && (
                                    <TitleStyled
                                        component="div"
                                    >
                                        { t(label) }
                                    </TitleStyled>
                                ) }
                                { this.renderFieldsCells(section) }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

ExtendedRow.defaultProps = {
    t: (key) => key,
};

ExtendedRow.propTypes = {
    t: PropTypes.func,
    item: PropTypes.object.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
};

export default ExtendedRow;
