import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import withUrlChange from 'hoc/withUrlChange/withUrlChange';
import {
    ArrowIcon as KeyboardArrowRight,
    ArrowLeftIcon as KeyboardArrowLeft,
} from 'components/Icons/Icons';
import Tile from './Tile';
import ItemsPerPagePicker from './ItemsPerPagePicker/itemsPerPagePicker';
import {
    NavButtonStyled,
    RootStyled,
} from 'components/OldTable/Pagination/pagination.styles';

class Pagination extends React.PureComponent {
    renderTile = (number) => {
        const { activePage, changePage } = this.props;
        const onClick = () => changePage(number);
        const active = number === activePage;

        return (
            <Tile
                key={number}
                onClick={onClick}
                active={active}
            >
                {number}
            </Tile>
        );
    };

    defaultPagination = () => {
        let pages = [];
        const { pageCount } = this.props;

        for (let i = 1; i <= pageCount; i += 1) {
            pages.push(this.renderTile(i));
        }

        return pages;
    };

    beginningPagination = () => {
        const { pageCount, changePage, activePage } = this.props;
        const onNext = () => changePage(activePage + 3);
        let pages = [];

        for (let i = 1; i <= 5; i += 1) {
            pages.push(this.renderTile(i));
        }

        pages.push(
            <Tile
                key="ellipsis1"
                onClick={onNext}
            >
                ...
            </Tile>,
        );
        pages.push(this.renderTile(pageCount));

        return pages;
    };

    endingPagination = () => {
        const { pageCount, changePage, activePage } = this.props;
        const onPrev = () => changePage(activePage - 3);
        let pages = [];

        pages.push(this.renderTile(1));
        pages.push(
            <Tile
                key="ellipsis2"
                onClick={onPrev}
            >
                ...
            </Tile>,
        );

        for (let i = pageCount - 4; i <= pageCount; i += 1) {
            pages.push(this.renderTile(i));
        }

        return pages;
    };

    middlePagination = () => {
        const { pageCount, activePage, changePage } = this.props;
        const onNext = () => changePage(activePage + 3);
        const onPrev = () => changePage(activePage - 3);
        let pages = [];

        pages.push(this.renderTile(1));
        pages.push(
            <Tile
                key="ellipsis2"
                onClick={onPrev}
            >
                ...
            </Tile>,
        );

        for (let i = activePage - 1; i <= activePage + 1; i += 1) {
            pages.push(this.renderTile(i));
        }

        pages.push(
            <Tile
                key="ellipsis1"
                onClick={onNext}
            >
                ...
            </Tile>,
        );
        pages.push(this.renderTile(pageCount));

        return pages;
    };

    addNavButtons = (pages) => {
        const { t, pageCount, activePage, changePage } = this.props;
        const onNext = () => changePage(activePage + 1);
        const onPrev = () => changePage(activePage - 1);

        const nextLabel = t('common:actions.next', { defaultValue: 'Next' });
        const prevLabel = t('common:actions.prev', { defaultValue: 'Prev' });

        pages.push(
            <Tile
                key="next"
                disabled={activePage === pageCount}
                onClick={onNext}
            >
                <Tooltip title={nextLabel}>
                    <NavButtonStyled>
                        {<KeyboardArrowRight /> || nextLabel}
                    </NavButtonStyled>
                </Tooltip>
            </Tile>,
        );
        pages.unshift(
            <Tile
                key="prev"
                disabled={activePage === 1}
                onClick={onPrev}
            >
                <Tooltip title={prevLabel}>
                    <NavButtonStyled>
                        {<KeyboardArrowLeft /> || prevLabel}
                    </NavButtonStyled>
                </Tooltip>
            </Tile>,
        );

        return pages;
    };

    getPages = () => {
        const { pageCount, activePage } = this.props;
        let pages = [];

        if (pageCount <= 7) {
            pages = this.defaultPagination();
        } else if (activePage <= 3) {
            pages = this.beginningPagination();
        } else if (activePage >= pageCount - 2) {
            pages = this.endingPagination();
        } else {
            pages = this.middlePagination();
        }

        return pages;
    };

    render() {
        const {
            id,
            pageCount,
            t,
            defaultItemsPerPage,
            footerStyles,
            flatStyle,
            perPageOptions,
            submitPagination,
            perPageItems,
        } = this.props;
        const pages =
            pageCount <= 7 && pageCount !== 1
                ? this.getPages()
                : this.addNavButtons(this.getPages());

        return (
            <RootStyled
                id={id}
                square={flatStyle}
                elevation={flatStyle ? 0 : 2}
                style={footerStyles}
                flatStyle={flatStyle}
            >
                <div>{pages}</div>
                <ItemsPerPagePicker
                    t={t}
                    options={perPageOptions}
                    defaultValue={defaultItemsPerPage}
                    submitPagination={submitPagination}
                    perPageItems={perPageItems}
                />
            </RootStyled>
        );
    }
}

Pagination.defaultProps = {
    t: (key) => key,
    changePage: () => {},
    activePage: 1,
    pageCount: 1,
};

Pagination.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    flatStyle: PropTypes.bool,
    activePage: PropTypes.number,
    changePage: PropTypes.func,
    pageCount: PropTypes.number,
    footerStyles: PropTypes.object,
    defaultItemsPerPage: PropTypes.number.isRequired,
    perPageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        }),
    ),
    submitPagination: PropTypes.func,
    perPageItems: PropTypes.number,
};

const PaginationWithHOC = withUrlChange(Pagination, {
    fieldType: 'pagination',
    fieldName: 'page',
});

export default PaginationWithHOC;
