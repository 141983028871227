import get from 'lodash/get';

const PDP_URL = get(window, 'env.PDP_API_URL', '');

export const URLS = {
    GET_USER_ME: `${PDP_URL}/api/users/me`,
};

export const DEFAULTS = {
    CACHE_TIME: 6000, // 1 min
};
