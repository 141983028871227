import { IconButton, Tooltip } from '@mui/material';
import { PrinterIcon } from 'components/Icons/Icons';
import { useTranslation } from 'react-i18next';

export interface PrinterItemProps {
    name: string;
    code: string;
    handlePrintout: (code: string) => void;
    canPrint: boolean;
    active: boolean;
}

export const PrinterItem = ({
    name,
    code,
    handlePrintout,
    canPrint,
    active,
}: PrinterItemProps) => {
    const { t } = useTranslation(['workplaces']);

    return (
        <div>
            {name}
            <Tooltip title={t('actions.printTestPage', { ns: 'workplaces' })}>
                <IconButton
                    onClick={() => handlePrintout(code)}
                    disabled={!canPrint || !active}
                >
                    <PrinterIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};
