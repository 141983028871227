import moment from 'moment';
import { getUserFullName } from '../../utils/utils';

export const mapProductSetFromApi = (data = {}) => {
    const {
        id = '',
        name = '',
        products = [],
        createdBy = {},
        createdAt = '',
    } = data;

    const formattedProducts = products.map((product) => {
        return {
            id: product?.id,
            patternCode: product?.pattern?.code,
            intermediateProductName: product?.intermediateProduct?.code,
            intermediateProductId: product?.intermediateProduct?.id,
        };
    });

    return {
        id,
        name,
        createdAt: moment(createdAt).format('DD/MM/YYYY'),
        products: formattedProducts,
        productsCount: products.length,
        author: getUserFullName(createdBy),
        productSetType: data?.productSetType?.name,
        productSetTypeId: data?.productSetType?.id,
    };
};

export const mapProductSetToApi = ({
    name = '',
    productSetType = '',
    products = [],
} = {}) => ({
    name,
    productSetTypeId: productSetType,
    products: products.map((id) => ({ id })),
});
