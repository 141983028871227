import styled from '@emotion/styled';
import { Chip, Stack } from '@mui/material';

export const StackStyled = styled(Stack)`
    display: block;
`;
StackStyled.displayName = 'Stack';

export const ChipStyled = styled(Chip)`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;
ChipStyled.displayName = 'Chip';
