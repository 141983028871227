import { ResponseModel } from 'reducerConfigurator/responseModel/responseModel';
import { toIdMap } from 'utils/immutable/list';

export class ListModel extends ResponseModel {
    constructor(data) {
        super(data);
        this.results = data['hydra:member'];
        this.total = data['hydra:totalItems'] || 0;
    }

    getTotalResults = () => {
        return this.total;
    };
    getResultsById = (model) => {
        return toIdMap(this.results, model);
    };
}
