export const ALERTS_DEFAULT_TIMEOUT = 5000;
export const ALERTS_ERROR_TIMEOUT = 15000;

export const ALERTS_VARIANTS = {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
} as const;

export const BASE_ALERT_HANDLERS = {
    200: {
        message: 'app:alerts.actionConfirmed',
        options: {
            variant: ALERTS_VARIANTS.SUCCESS,
        },
    },
    400: {
        message: 'app:alerts.badRequest',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
    403: {
        message: 'app:alerts.accessDenied',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
    418: {
        message: 'app:alerts.teapot',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
    500: {
        message: 'app:alerts.networkError',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
    UNKNOWN: {
        message: 'app:alerts.unknown',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
    NETWORK_ERROR: {
        message: 'app:alerts.networkError',
        options: {
            variant: ALERTS_VARIANTS.ERROR,
        },
    },
} as const;
