import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function PriceTagIcon(props) {
    return (
        <SvgIcon
            viewBox="-0.5 -1 16 16"
            { ...props }
        >
            <path
                d="M6.83995 0.63996C6.9487 0.64121 7.0587 0.64621 7.16995 0.65996C7.3912 0.68871 7.6337 0.76371 7.81995 0.94996L15.0899 8.21996C15.4487 8.57871 15.4487 9.17121 15.0899 9.52996L9.52995 15.09C9.1712 15.4487 8.5787 15.4487 8.21995 15.09L0.949945 7.81996C0.764945 7.63496 0.688695 7.38996 0.659945 7.16996C0.631195 6.94996 0.639945 6.73246 0.639945 6.51996V1.75996C0.639945 1.14496 1.14495 0.63996 1.75994 0.63996H6.51995C6.6237 0.63996 6.7312 0.63871 6.83995 0.63996ZM6.82995 1.27996C6.7362 1.27746 6.6287 1.27996 6.51995 1.27996H1.75994C1.4912 1.27996 1.27995 1.49121 1.27995 1.75996V6.51996C1.27995 6.74246 1.2812 6.94371 1.29994 7.08996C1.3187 7.23621 1.3487 7.30871 1.39995 7.35996L8.66995 14.64C8.7837 14.7537 8.9662 14.7537 9.07995 14.64L9.11995 14.59L14.6399 9.07996C14.7537 8.96621 14.7537 8.78371 14.6399 8.66996L7.35995 1.39996C7.30995 1.34996 7.22745 1.31871 7.07995 1.29996C7.0062 1.28996 6.9237 1.28246 6.82995 1.27996ZM3.51995 2.23996C4.2237 2.23996 4.79995 2.81621 4.79995 3.51996C4.79995 4.22371 4.2237 4.79996 3.51995 4.79996C2.8162 4.79996 2.23995 4.22371 2.23995 3.51996C2.23995 2.81621 2.8162 2.23996 3.51995 2.23996ZM3.51995 2.87996C3.16245 2.87996 2.87995 3.16246 2.87995 3.51996C2.87995 3.87746 3.16245 4.15996 3.51995 4.15996C3.87745 4.15996 4.15995 3.87746 4.15995 3.51996C4.15995 3.16246 3.87745 2.87996 3.51995 2.87996Z"
            />
        </SvgIcon>
    );
}

PriceTagIcon.defaultProps = {
    titleAccess: 'price-tag-icon',
};

PriceTagIcon.propTypes = svgIconPropTypes;
