import { Map } from 'immutable';
import { getNamespaces } from '../helpers/getNamespaces';

const defaultResetTime = 3; // seconds;
const defaultNamespaces = {
    itemsForAutocomplete: 'itemsForAutocomplete',
    itemsForAutocompleteCount: 'itemsForAutocompleteCount',
    autocompleteLoader: 'fetchAutocompleteList',
    autocompleteFetchTimestamp: 'autocompleteFetchTimestamp',
};

export function request({ namespaces: configNamespaces, options = {} }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        const withTimestampReset = typeof options.withTimestampReset === 'undefined' ? true : options.withTimestampReset;
        const lastReqTimestamp = state.get(namespaces.autocompleteFetchTimestamp);
        const shouldResetItems = withTimestampReset ?
            (new Date().getTime() - lastReqTimestamp) > defaultResetTime * 10000
            :
            false;
        const oldItems = shouldResetItems ? new Map() : state.get(namespaces.itemsForAutocomplete);

        return state
            .set(namespaces.itemsForAutocomplete, oldItems)
            .set(namespaces.autocompleteLoader, true);
    };
}

export function failure({ options = {}, namespaces: configNamespaces, initialState }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        const withTimestampReset = typeof options.withTimestampReset === 'undefined' ? true : options.withTimestampReset;
        const timeStamp = withTimestampReset ? new Date().getTime() : 0;
        const resetedItems = initialState.get(namespaces.itemsForAutocomplete);

        return state
            .set(namespaces.autocompleteFetchTimestamp, timeStamp)
            .set(namespaces.itemsForAutocomplete, resetedItems)
            .set(namespaces.autocompleteLoader, false);
    };
}

export function success({ options = {}, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state, responseModel }) {
        const withTimestampReset = typeof options.withTimestampReset === 'undefined' ? true : options.withTimestampReset;
        const timeStamp = withTimestampReset ? new Date().getTime() : 0;
        const oldItems = state.get(namespaces.itemsForAutocomplete);
        const newItems = responseModel.getResultsById(options && options.model);
        const results = newItems.merge(oldItems);
        const resultsTotal = responseModel && responseModel.getTotalResults();

        return state
            .set(namespaces.autocompleteFetchTimestamp, timeStamp)
            .set(namespaces.itemsForAutocomplete, results)
            .set(namespaces.itemsForAutocompleteCount, resultsTotal)
            .set(namespaces.autocompleteLoader, false);
    };
}
