import React from 'react';
import { fromJS } from 'immutable';
import displayHOCName from 'utils/displayHOCName';
import PropTypes from 'prop-types';
import { getString } from 'utils/immutable/map';

const withSelectableListHOC = (WrappedComponent, options = {}) => {
    class WithSelectableList extends React.PureComponent {
        constructor(props) {
            super(props);

            const selectableListOptions = props.selectableListOptions
                ? props.selectableListOptions
                : {};

            this.options = {
                listName:
                    selectableListOptions.listName ||
                    options.listName ||
                    'itemsById',
                keyName: selectableListOptions.idKey || options.id || 'id',
                list: selectableListOptions.list || options.list || null,
                autoCheckForSelected:
                    selectableListOptions.autoCheckForSelected ||
                    options.autoCheckForSelected ||
                    true,
            };
            this.isSelectedAll = false;
            this.isSelectedAllFetched = false;
            this.isAnySelected = false;
            this.state = {
                selectedItems: props.defaultSelectedItems || fromJS({}),
            };
        }

        handleResetState = () => {
            const { forceSelected } = this.props;

            this.isSelectedAll = false;
            this.isSelectedAllFetched = false;
            this.isAnySelected = false;
            this.setState({
                selectedItems: forceSelected || fromJS({}),
            });
        };

        changeAllSelectedItems = (value) => {
            const { forceSelected } = this.props;
            let selectedItems = this.state.selectedItems.toJS();
            const listToSelect =
                this.options.list ||
                this.props[this.options.listName] ||
                fromJS({});

            listToSelect.forEach((item) => {
                const idKey = getString(item, this.options.keyName);

                if (forceSelected?.get(idKey)) {
                    selectedItems[idKey] = true;
                } else {
                    selectedItems[idKey] = value;
                }
            });
            this.setState({ selectedItems: fromJS(selectedItems) });
        };

        handleSelectItem = (idKey) => {
            const { selectedItems } = this.state;

            this.isSelectedAll = false;
            this.setState({
                selectedItems: selectedItems.set(
                    idKey.toString(),
                    !selectedItems.get(idKey),
                ),
            });
        };

        handleSelectAllFetched = () => {
            if (!this.isSelectedAll) {
                this.changeAllSelectedItems(!this.isSelectedAllFetched);
            } else {
                this.changeAllSelectedItems(false);
            }

            this.isSelectedAll = false;
        };

        handleSelectAll = () => {
            this.isSelectedAll = true;
            this.changeAllSelectedItems(false);
        };

        checkForSelected = () => {
            const { selectedItems } = this.state;
            const listToCheck = this.options.list
                ? this.options.list
                : this.props[this.options.listName];

            this.isSelectedAllFetched = true;
            this.isAnySelected = false;

            listToCheck &&
                listToCheck.forEach((item) => {
                    if (
                        selectedItems.get(getString(item, this.options.keyName))
                    ) {
                        this.isAnySelected = true;
                    } else {
                        this.isSelectedAllFetched = false;
                    }
                });
        };

        render() {
            const { selectedItems } = this.state;

            this.options.autoCheckForSelected && this.checkForSelected();

            return (
                <WrappedComponent
                    selectedItems={selectedItems}
                    handleSelectItem={this.handleSelectItem}
                    handleSelectAll={this.handleSelectAll}
                    handleSelectAllFetched={this.handleSelectAllFetched}
                    handleResetSelected={this.handleResetState}
                    isSelectedAll={this.isSelectedAll}
                    isAnySelected={this.isAnySelected}
                    isSelectedAllFetched={this.isSelectedAllFetched}
                    {...this.props}
                />
            );
        }
    }

    WithSelectableList.propTypes = {
        defaultSelectedItems: PropTypes.object,
        selectableListOptions: PropTypes.object,
        forceSelected: PropTypes.object,
    };

    WithSelectableList.displayName = `WithSelectableList(${displayHOCName(
        WrappedComponent,
    )})`;

    return WithSelectableList;
};

export default withSelectableListHOC;
