import parser from 'qs';
import get from 'lodash/get';
import set from 'lodash/set';
import { mapContractorFromApi } from '../mappers/contractor';
import { ApplicationJsonHeaders } from '../../constants';
import errorHandlers from '../errorHandlers';

import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class ContractorEmployee {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
    }
    private paramsSerializer = (params: unknown) => parser.stringify(params);

    async getById(id: string) {
        const { ajax } = this.services;
        const onError = errorHandlers(this.services.alert).get;
        const errorConfig = { addGenericAlert: false };

        const response = await ajax.get({
            errorConfig,
            onError,
            url: this.URLS.GET_CONTRACTOR_EMPLOYEE.replace('{id}', id),
        });

        return mapContractorFromApi(response.data);
    }

    async getList({
        filters = {
            search: '',
            userId: '',
            contractorId: '',
        },
    }) {
        const { ajax } = this.services;
        const { search, userId, contractorId } = filters;
        const config = {
            params: {},
            paramsSerializer: this.paramsSerializer,
        };

        const filtersParams = {};

        if (search) set(filtersParams, 'filter.name.match', search);
        if (userId) set(filtersParams, 'filter.user_id.in[""]', userId);
        if (contractorId)
            set(filtersParams, 'filter.contractor_id.eq', contractorId);

        config.params = {
            ...config.params,
            ...filtersParams,
        };

        try {
            const response = await ajax.get({
                url: this.URLS.GET_CONTRACTOR_EMPLOYEES,
                config,
            });
            const items = get(response, 'data.hydra:member', []);
            const totalItems = get(response, 'data.hydra:totalItems', []);

            return {
                items: items.map((contractor: unknown) =>
                    mapContractorFromApi(contractor),
                ),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async create(data: unknown) {
        const { ajax, alert, translator } = this.services;

        const url = this.URLS.POST_CONTRACTOR_EMPLOYEE;
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { post: onError } = errorHandlers(alert, translator);

        const { data: responseData } = await ajax.post({
            url,
            config,
            data,
            onError,
        });

        return responseData;
    }
    async delete(id: string) {
        const { ajax } = this.services;

        const url = this.URLS.DELETE_CONTRACTOR_EMPLOYEE.replace('{id}', id);

        return await ajax.delete({ url });
    }
}

export default ContractorEmployee;
