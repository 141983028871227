import get from 'lodash/get';

const SEARCH_URL = get(window, 'env.SEARCH_API_URL', '');
const TRANSLATION_PATTERNS_URL = `${SEARCH_URL}/api/translation_patterns`;

export const URLS = {
    GET_ORDERS: `${SEARCH_URL}/api/v2/orders`,
    REFRESH_ORDERS: `${SEARCH_URL}/api/orders/refresh`,

    GET_TRANSLATION_PATTERNS: `${SEARCH_URL}/api/v2/translation_patterns`,
    POST_TRANSLATION_PATTERNS_EXPORT: `${TRANSLATION_PATTERNS_URL}/export`,
};

export const DEFAULTS = {
    ORDERS_PER_PAGE: 20,
    PRODUCTION_ORDERS_PER_PAGE: 20,
    TRANSLATION_PATTERNS_PER_PAGE: 20,
    EXPORT_TRANSLATION_POLLING_INTERVAL: 2000,
};
