import { makeRequestActions } from 'utils/redux/makeActions';
import * as C from './constants';

export const fetchDictionaries = makeRequestActions(C.FETCH_DICTIONARIES);
export const fetchProductionOrders = makeRequestActions(C.FETCH_PRODUCTION_ORDERS);
export const printProductionOrders = makeRequestActions(C.PRINT_PRODUCTION_ORDERS);
export const reprintProductionOrders = makeRequestActions(C.REPRINT_PRODUCTION_ORDERS);
export const downloadProductionOrders = makeRequestActions(
    C.DOWNLOAD_PRODUCTION_ORDERS,
);
export const saveAsPreset = makeRequestActions(C.SAVE_AS_PRESET);
