import { formatDate } from '../../../utils/formatting';
import { keysCamelToSnake } from '../../../utils/mappers';
import { mapPriceRangeValueToParams, omitNegative } from '../../../utils/filters';

export const mapSourcesGetList = ({ results, total }) => {
    const items = results.map(({
        id,
        name,
        own_name,
        media_file: {
            width,
            height,
            file_name,
        },
        thumbnail: {
            id: imgId,
            http_url,
        },
        author: {
            first_name,
            last_name,
        },
        orientation,
        price,
        created_at
    }) => ({
        id,
        imgId,
        imgUrl: http_url,
        name,
        imgName: file_name,
        ownName: own_name,
        author: [first_name, last_name].join(' '),
        height,
        width,
        orientation,
        price,
        createdAt: formatDate(created_at),
    }));

    return ({
        items,
        itemsTotal: total,
    });
};

const mapPrice = (priceArr) => {
    if (priceArr?.length) {
        const mapped = mapPriceRangeValueToParams({
            from: priceArr[0],
            to: priceArr[1],
        });

        return omitNegative({
            gte: mapped[0],
            lte: mapped[1],
        });
    }
};

export const mapGetListFilters = ({ author, priceFrom, priceTo, imageHeightFrom, imageHeightTo, imageWidthFrom, imageWidthTo, createdAtFrom, createdAtTo, ...filters }) => {
    const mappedFilters = {
        ...keysCamelToSnake(filters),
        'author.id': author,
        price: mapPrice(priceFrom || priceTo ? [priceFrom, priceTo] : []),
        'media_file.height': (imageHeightFrom || imageHeightTo) && omitNegative({
            gte: imageHeightFrom,
            lte: imageHeightTo,
        }),
        'media_file.width': (imageWidthFrom || imageHeightTo) && omitNegative({
            gte: imageWidthFrom,
            lte: imageWidthTo,
        }),
        created_at: (createdAtFrom || createdAtTo) && {
            after: createdAtFrom,
            before: createdAtTo,
        }
    };

    return omitNegative(mappedFilters);
};
