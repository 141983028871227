import React from 'react';
import { svgIconPropTypes } from 'utils/propTypes';
import { SvgIcon } from 'components/Icons/SvgIconBase';

export const CheckmarkIcon = ({ fill = '#3EBE9B', titleAccess = 'checkmark-icon', ...props }) => (
    <SvgIcon
        viewBox="0 0 18 14"
        titleAccess={ titleAccess }
        { ...props }
    >
        <path
            d="M15.995 0a1 1 0 0 0-.688.303L5.014 10.596 1.722 7.303A1 1 0 1 0 .307 8.717l4 4a1 1 0 0 0 1.415 0l11-11A1 1 0 0 0 15.994 0Z"
            fill={ fill }
        />
    </SvgIcon>
);

CheckmarkIcon.propTypes = svgIconPropTypes;
