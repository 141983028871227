import { fromJS } from 'immutable';

import { access } from 'access/votingMechanism';
import { MODULE_ACCESSES } from 'modules/dropshipping/accesses';
import { getString } from 'utils/immutable/map';

export const normalizeContractorEmployees = (items = {}) => {
    return Object.values(items).reduce(
        (employees, employee) => ({
            ...employees,
            [employee.id]: employee,
        }),
        {},
    );
};

export const parseContractorData = (contractor) => {
    let trimmedContractor = contractor;
    const addresses = [];
    const canAssignPatrons =
        access[MODULE_ACCESSES.RESOURCES.CONTRACTOR.ASSIGN_MANAGER];
    const tradeTerms = trimmedContractor.getIn(['setting', 'trade_terms']);

    if (!canAssignPatrons) {
        trimmedContractor = trimmedContractor.delete('manager');
    } else {
        const manager = trimmedContractor.get('manager');

        trimmedContractor = trimmedContractor.set(
            'manager',
            manager
                ? fromJS({
                      user_id: manager,
                  })
                : null,
        );
    }

    if (tradeTerms) {
        const deferredPayment =
            trimmedContractor.getIn(['setting', 'deferred_payment_days']) ||
            '0';

        if (tradeTerms.split('_')[0] !== 'prepaid') {
            trimmedContractor = trimmedContractor.setIn(
                ['setting', 'deferred_payment_days'],
                Number(deferredPayment),
            );
        } else {
            trimmedContractor = trimmedContractor.setIn(
                ['setting', 'deferred_payment_days'],
                null,
            );
        }
    }

    if (trimmedContractor.get('addresses')) {
        trimmedContractor.get('addresses').forEach((address) => {
            if (!address.get('isRemoved')) {
                addresses.push(
                    address
                        .set('house_number', getString(address, 'house_number'))
                        .set('flat_number', getString(address, 'flat_number')),
                );
            }
        });
    }

    if (!trimmedContractor.get('user_id')) {
        trimmedContractor = trimmedContractor.set('user_id', null);
    }

    return trimmedContractor
        .set(
            'tax_number',
            getString(contractor, 'tax_number').replace(/\s/g, ''),
        )
        .set('addresses', addresses)
        .toJS();
};
