import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import NotFound from 'modules/app/pages/NotFound/NotFound';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbsWithState';
import Hidden from 'components/Hidden/hidden';
import Button from 'components/Button/button';
import { Loader } from '../Loader/Loader';
import {
    ActionsStyled,
    BodyStyled,
    InsideStyled,
    ItemDetailsStyled,
    SubtitleStyled,
    TitleStyled,
    TypographyStyled,
} from './itemDetails.styles';

class ItemDetails extends React.PureComponent {
    render() {
        const {
            t,
            showBreadCrumbs,
            moduleName,
            pathTitle,
            title,
            subtitle,
            skipPath,
            children,
            editPath,
            loading,
            showErrorPage,
            submitButton,
            cancelButton,
            otherButtons,
            additionalNode,
            additionalNodeInside,
            actionsClassName,
        } = this.props;

        // eslint-disable-next-line react/display-name
        const editLink = React.forwardRef((props, ref) => (
            <Link
                innerRef={ref}
                to={editPath}
                {...props}
            />
        ));
        const editButton = editPath ? (
            <Button
                baseWidth
                component={editLink}
                color="primary"
            >
                {_.upperFirst(t('common:actions.edit'))}
            </Button>
        ) : null;

        return showErrorPage ? (
            <NotFound />
        ) : (
            <ItemDetailsStyled>
                {loading && <Loader />}
                <div>
                    {showBreadCrumbs && (
                        <Hidden smDown>
                            <Breadcrumbs
                                t={t}
                                moduleName={moduleName}
                                overwrite={{
                                    2: pathTitle,
                                }}
                                skipPath={skipPath}
                            />
                        </Hidden>
                    )}
                    <ActionsStyled className={actionsClassName}>
                        {otherButtons}
                        {submitButton || editButton}
                        {cancelButton || null}
                        {additionalNode || null}
                    </ActionsStyled>
                    <TitleStyled>
                        <TypographyStyled
                            data-name="itemDetailsTitle"
                            variant="h2"
                        >
                            {title}
                            {subtitle && (
                                <SubtitleStyled>{subtitle}</SubtitleStyled>
                            )}
                        </TypographyStyled>
                    </TitleStyled>
                    {additionalNodeInside || null}
                </div>
                <BodyStyled>
                    <InsideStyled>{children}</InsideStyled>
                </BodyStyled>
            </ItemDetailsStyled>
        );
    }
}

ItemDetails.defaultProps = {
    moduleName: '',
    pathTitle: '',
    skipPath: [],
    showBreadCrumbs: true,
    t: (key) => key,
};

ItemDetails.propTypes = {
    showBreadCrumbs: PropTypes.bool,
    loading: PropTypes.bool,
    additionalNode: PropTypes.node,
    additionalNodeInside: PropTypes.node,
    otherButtons: PropTypes.node,
    showErrorPage: PropTypes.bool,
    children: PropTypes.node.isRequired,
    editPath: PropTypes.string,
    skipPath: PropTypes.array,
    moduleName: PropTypes.string,
    pathTitle: PropTypes.string,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    t: PropTypes.func,
    submitButton: PropTypes.object,
    cancelButton: PropTypes.object,
    actionsClassName: PropTypes.string,
};

export default ItemDetails;
