/* istanbul ignore file */
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    getPath,
    getSearch,
    getHash,
} from 'utils/router/selectors';
import Table from 'components/OldTable/OldTable';

const mapStateToProps = (state) => {
    return {
        path: getPath(state),
        search: getSearch(state),
        hash: getHash(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push: (data) => dispatch(push(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
