import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ProductPossibilitiesTable from 'modules/stablo/containers/ProductPossibilitiesTable/mapProps';

class ProductPossibilities extends React.PureComponent {
    render() {
        const { t } = this.props;

        return <ProductPossibilitiesTable t={t} />;
    }
}

ProductPossibilities.propTypes = {
    t: PropTypes.func,
};

const ProductPossibilitiesWithHOC =
    withTranslation('stablo')(ProductPossibilities);

export default ProductPossibilitiesWithHOC;
