import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function MoreHorizontalIcon(props) {
    return (
        <SvgIcon
            viewBox="-3 -6 16 16"
            { ...props }
        >
            <path
                d="M8.50008 3.16663C8.8095 3.16663 9.10625 3.04371 9.32504 2.82492C9.54383 2.60612 9.66675 2.30938 9.66675 1.99996C9.66675 1.69054 9.54383 1.39379 9.32504 1.175C9.10625 0.956209 8.8095 0.833293 8.50008 0.833293C8.19066 0.833293 7.89392 0.956209 7.67512 1.175C7.45633 1.39379 7.33341 1.69054 7.33341 1.99996C7.33341 2.30938 7.45633 2.60612 7.67512 2.82492C7.89392 3.04371 8.19066 3.16663 8.50008 3.16663ZM5.00008 3.16663C5.3095 3.16663 5.60625 3.04371 5.82504 2.82492C6.04383 2.60612 6.16675 2.30938 6.16675 1.99996C6.16675 1.69054 6.04383 1.39379 5.82504 1.175C5.60625 0.956209 5.3095 0.833293 5.00008 0.833293C4.69066 0.833293 4.39392 0.956209 4.17512 1.175C3.95633 1.39379 3.83341 1.69054 3.83341 1.99996C3.83341 2.30938 3.95633 2.60612 4.17512 2.82492C4.39392 3.04371 4.69066 3.16663 5.00008 3.16663ZM1.50008 3.16663C1.8095 3.16663 2.10625 3.04371 2.32504 2.82492C2.54383 2.60612 2.66675 2.30938 2.66675 1.99996C2.66675 1.69054 2.54383 1.39379 2.32504 1.175C2.10625 0.956209 1.8095 0.833292 1.50008 0.833292C1.19066 0.833292 0.893915 0.956209 0.675123 1.175C0.45633 1.39379 0.333414 1.69054 0.333414 1.99996C0.333414 2.30938 0.45633 2.60612 0.675123 2.82492C0.893915 3.04371 1.19066 3.16663 1.50008 3.16663Z"
            />
        </SvgIcon>
    );
}

MoreHorizontalIcon.defaultProps = {
    titleAccess: 'more-horizontal-icon',
};

MoreHorizontalIcon.propTypes = svgIconPropTypes;
