import get from 'lodash/get';
import {
    mapDiscountFromApi,
    mapCreateDiscountToApi,
} from '../mappers/discount';
import parser from 'qs';
import { ApplicationJsonHeaders } from '../../constants';

class Discounts {
    constructor(discountService, services, URLS) {
        this.discountService = discountService;
        this.services = services;
        this.URLS = URLS;
        this.headers = ApplicationJsonHeaders;

        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
        this.deactivate = this.deactivate.bind(this);
    }
    _paramsSerializer = (params) => parser.stringify(params);
    getList({ filters = {}, sort, per_page = 20, page = 1 }) {
        const { ajax } = this.services;
        const url = this.URLS.GET_DISCOUNTS;
        const errorConfig = {
            throwError: this.discountService.errorHandlers.get,
            addGenericAlert: false,
        };
        const config = {
            headers: this.headers,
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                'contractor.id': filters.contractorId,
                status: filters.status,
            },
            paramsSerializer: this._paramsSerializer,
        };

        return ajax.get({ url, config, errorConfig }).then((response) => {
            return {
                items: get(response, 'data.results', []).map(
                    mapDiscountFromApi,
                ),
                itemsTotal: get(response, 'data.total', 0),
            };
        });
    }
    deactivate(id) {
        const { ajax } = this.services;
        const url = this.URLS.DEACTIVATE_DISCOUNT.replace('{id}', id);
        const errorConfig = {
            throwError: this.discountService.errorHandlers.deactivate,
            addGenericAlert: false,
        };
        const config = {
            headers: this.headers,
        };

        return ajax.patch({ url, config, errorConfig }).then(() => null);
    }
    create(formData) {
        const { ajax } = this.services;
        const url = this.URLS.POST_DISCOUNT;
        const errorConfig = {
            throwError: this.discountService.errorHandlers.post,
            addGenericAlert: false,
        };
        const config = {
            headers: this.headers,
            data: mapCreateDiscountToApi(formData),
        };

        return ajax
            .post({ url, config, errorConfig })
            .then((response) => mapDiscountFromApi(response.data));
    }
}

export default Discounts;
