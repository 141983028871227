import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function AssignmentIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -2 16 16"
            { ...props }
        >
            <path
                d="M1.83333 0C0.826742 0 0 0.826742 0 1.83333V10.1667C0 11.1733 0.826742 12 1.83333 12H10.1667C11.1733 12 12 11.1733 12 10.1667V1.83333C12 0.826742 11.1733 0 10.1667 0H1.83333ZM1.83333 1H10.1667C10.6327 1 11 1.36726 11 1.83333V10.1667C11 10.6327 10.6327 11 10.1667 11H9.33333V8.5C9.33333 7.86168 8.80499 7.33333 8.16667 7.33333H3.83333C3.19502 7.33333 2.66667 7.86168 2.66667 8.5V11H1.83333C1.36726 11 1 10.6327 1 10.1667V1.83333C1 1.36726 1.36726 1 1.83333 1ZM6 2.66667C5.375 2.66667 4.84294 2.91904 4.5013 3.30339C4.15967 3.68773 4 4.18056 4 4.66667C4 5.15278 4.15967 5.64561 4.5013 6.02995C4.84294 6.41429 5.375 6.66667 6 6.66667C6.625 6.66667 7.15706 6.41429 7.4987 6.02995C7.84033 5.64561 8 5.15278 8 4.66667C8 4.18056 7.84033 3.68773 7.4987 3.30339C7.15706 2.91904 6.625 2.66667 6 2.66667ZM6 3.66667C6.375 3.66667 6.59294 3.78929 6.7513 3.96745C6.90967 4.14561 7 4.40278 7 4.66667C7 4.93056 6.90967 5.18773 6.7513 5.36589C6.59294 5.54404 6.375 5.66667 6 5.66667C5.625 5.66667 5.40706 5.54404 5.2487 5.36589C5.09033 5.18773 5 4.93056 5 4.66667C5 4.40278 5.09033 4.14561 5.2487 3.96745C5.40706 3.78929 5.625 3.66667 6 3.66667ZM3.83333 8.33333H8.16667C8.26502 8.33333 8.33333 8.40165 8.33333 8.5V11H3.66667V8.5C3.66667 8.40165 3.73499 8.33333 3.83333 8.33333Z"
            />
        </SvgIcon>
    );
}

AssignmentIcon.defaultProps = {
    titleAccess: 'assignment-icon',
};

AssignmentIcon.propTypes = svgIconPropTypes;
