import { takeEvery } from 'redux-saga/effects';
import qs from 'qs';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import * as C from './constants';
import * as A from './actions';

export function* autocompleteSaga() {
    yield takeEvery(C.FETCH_AUTOCOMPLETE_LIST.request, fetchList);
}

function* fetchList(action) {
    const {
        endpoint,
        listId,
        mapResponseToOptions,
        params,
    } = action.payload;
    const paramsId = qs.stringify(params);

    const api = makeEndPoint(endpoint);

    yield api.get({
        action: A.fetchAutocompleteList,
        params: params,
        attributes: {
            success: {
                listId,
                paramsId,
                mapResponseToOptions,
            },
            failure: {
                listId,
                paramsId,
                mapResponseToOptions,
            },
        },
        alerts: {
            failure: true,
        },
    });
}
