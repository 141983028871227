import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import productCatalogs from './productCatalogs/reducer';
import productCatalogsSaga from './productCatalogs/saga';
import * as productCatalogsActions from './productCatalogs/actions';

import productPossibilities from './productPossibilities/reducer';
import productPossibilitiesSaga from './productPossibilities/saga';
import * as productPossibilitiesActions from './productPossibilities/actions';

export const stabloReducers = combineReducers({
    productPossibilities,
    productCatalogs,
});

export const stabloSagas = [
    fork(productPossibilitiesSaga),
    fork(productCatalogsSaga),
];

export const stabloActions = [
    productPossibilitiesActions,
    productCatalogsActions,
];
