import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CalendarIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <path
                d="M2 0V1H1.5C0.675781 1 0 1.67578 0 2.5V11.5C0 12.3242 0.675781 13 1.5 13H11.5C12.3242 13 13 12.3242 13 11.5V2.5C13 1.67578 12.3242 1 11.5 1H11V0H10V1H3V0H2ZM1.5 2H2V3H3V2H10V3H11V2H11.5C11.7812 2 12 2.21875 12 2.5V4H1V2.5C1 2.21875 1.21875 2 1.5 2ZM1 5H12V11.5C12 11.7812 11.7812 12 11.5 12H1.5C1.21875 12 1 11.7812 1 11.5V5ZM4 6V7H5V6H4ZM6 6V7H7V6H6ZM8 6V7H9V6H8ZM10 6V7H11V6H10ZM2 8V9H3V8H2ZM4 8V9H5V8H4ZM6 8V9H7V8H6ZM8 8V9H9V8H8ZM10 8V9H11V8H10ZM2 10V11H3V10H2ZM4 10V11H5V10H4ZM6 10V11H7V10H6Z"
            />
        </SvgIcon>
    );
}

CalendarIcon.defaultProps = {
    titleAccess: 'calendar-icon',
};

CalendarIcon.propTypes = svgIconPropTypes;
