export const mapCustomFieldDefinitionFromApi = (data = {}) => {
    const { key, id } = data;

    return {
        id,
        key
    };
};

export const mapCustomFieldDefinitionToApi = (data = {}) => {
    const { key, salesChannelId } = data;

    return {
        key,
        channel: {
            id: salesChannelId
        }
    };
};

export const mapCustomFieldDefinitionEditToApi = (data = {}) => {
    const { key } = data;

    return {
        key,
    };
};

