class RequestCache {
    constructor(settings) {
        this.settings = {
            cacheTime: settings.cacheTime,
        };
        this.cache = {};
    }
    _getCacheTime() {
        return this.settings.cacheTime;
    }
    _getRequestId(url, params) {
        const data = { url, params };

        return btoa(JSON.stringify(data).replace(/[^a-zA-Z0-9]/g, ''));
    }
    setRequest(url, params, request) {
        const requestId = this._getRequestId(url, params);

        this.cache[requestId] = {
            expirationDate: new Date().getTime() + this._getCacheTime(),
            request,
        };
    }
    getRequest(url, params) {
        const requestId = this._getRequestId(url, params);
        const cachedRequest = this.cache[requestId];

        if (cachedRequest) {
            if (cachedRequest.expirationDate > new Date().getTime()) {
                return cachedRequest.request;
            }
            this.clearRequest(url, params);
        }
    }
    clearRequest(url, params) {
        const requestId = this._getRequestId(url, params);

        delete this.cache[requestId];
    }
}

export default RequestCache;
