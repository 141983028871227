import type {
    FetchAutocompleteProps,
    BaseAutocompleteOption,
} from './fetchAutocomplete.type';
import type { SyntheticEvent, FocusEvent } from 'react';
import type {
    AutocompleteChangeReason,
    FilterOptionsState,
} from '@mui/material';

export const defaultValue = <T extends BaseAutocompleteOption>(
    multiple: FetchAutocompleteProps<T>['multiple'],
) => (multiple ? [] : null);

const normalizeOptionLabel = (optionLabel: string) =>
    optionLabel?.toLowerCase()?.trim();

const resolveMultipleValue = (value: any) =>
    value.map((item) => (typeof item !== 'object' ? item : item?.value));

const resolveValue = <T extends BaseAutocompleteOption>(
    multiple: FetchAutocompleteProps<T>['multiple'],
    value: any,
) => (multiple ? resolveMultipleValue(value) : value?.value);

export const resolveNewValue =
    <T extends BaseAutocompleteOption>(
        multiple: FetchAutocompleteProps<T>['multiple'],
    ) =>
    (value: any) =>
        resolveValue(multiple, value) || defaultValue(multiple);

export const changeHandler =
    <T extends BaseAutocompleteOption>(
        id: FetchAutocompleteProps<T>['id'],
        handleChange: FetchAutocompleteProps<T>['handleChange'],
        searchSetter: (search: string) => void,
        multiple: FetchAutocompleteProps<T>['multiple'],
    ) =>
    (
        _event: SyntheticEvent,
        value: any,
        options: T[],
        reason: AutocompleteChangeReason,
    ) => {
        searchSetter('');

        if (reason === 'clear') {
            handleChange(id, defaultValue(multiple), options);
        } else {
            handleChange(id, resolveNewValue(multiple)(value), options);
        }
    };

export const blurHandler =
    <T extends BaseAutocompleteOption>(
        searchSetter: (search: string) => void,
        handleBlur: FetchAutocompleteProps<T>['handleBlur'],
    ) =>
    (event: FocusEvent<HTMLDivElement>) => {
        if (handleBlur) {
            handleBlur(event);
        }

        searchSetter('');
    };

export const inputChangeHandler =
    <T extends BaseAutocompleteOption>(
        localFilter: FetchAutocompleteProps<T>['localFilter'],
        searchSetter: (search: string) => void,
    ) =>
    (_, inputValue: string, reason: string) => {
        if (!localFilter && reason === 'input') {
            searchSetter(inputValue);
        }
    };

export const isOptionEqualToValue = <T extends BaseAutocompleteOption>(
    option: T,
    value: unknown,
) => option.value === value;

export const getOptionLabel =
    <T extends BaseAutocompleteOption>(options: T[]) =>
    (optionValue: unknown) =>
        options.find((option) => option.value === optionValue)?.label || '';

export const filterOptions =
    <T extends BaseAutocompleteOption>(options: T[]) =>
    (_, state: FilterOptionsState<unknown>) =>
        options.filter(
            (option) =>
                !!option.label &&
                normalizeOptionLabel(option.label).includes(
                    normalizeOptionLabel(state.inputValue),
                ),
        );
