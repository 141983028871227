import styled from '@emotion/styled';
import IconButton from 'components/IconButton/iconButton';
import { css } from '@emotion/react';

const arrowStyles = ({ theme }) => css`
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  cursor: pointer;
  color: white;
  background-color: ${theme.palette?.common?.gray?.mid};
  opacity: 0.6;
  
  &:hover {
    background-color: ${theme.palette?.common?.gray?.midLight};
  }
`;

export const ArrowForwardStyled = styled(IconButton)`
  ${arrowStyles};
  
  right: 0;
`;
ArrowForwardStyled.displayName = 'ArrowForward';

export const ArrowBackwardStyled = styled(IconButton)`
  ${arrowStyles};
  
  left: 0;
  
  & span {
    position: relative;
    left: 3px;
  }
`;
ArrowBackwardStyled.displayName = 'ArrowBackward';

export const LoaderWrapperStyled = styled('div')`
  height: 200px;
  width: 200px;
`;
LoaderWrapperStyled.displayName = 'LoaderWrapper';

export const CarouselWrapperStyled = styled('div')`
  padding: 0 20px;

  & .carousel .slide {
    background: none;
    
    & img {
      width: auto;
      max-width: 100%;
    }
  }

  & .control-dots .dot {
    outline: none;
  }

  & .carousel .thumbs-wrapper {
    margin-top: 5px;
  }
  
  & .carousel .slider-wrapper {
    min-height: 500px;
    display: flex;
    align-items: center;
  }
`;
CarouselWrapperStyled.displayName = 'CarouselWrapper';

export const ImageTitleStyled = styled('p')`
  color: white;
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
`;
ImageTitleStyled.displayName = 'ImageTitle';

export const SlideWrapperStyled = styled('div')`
  padding: 0 60px;
`;
SlideWrapperStyled.displayName = 'SlideWrapper';

export const ImageStyled = styled('img')`
  max-height: 500px;
`;
ImageStyled.displayName = 'Image';
