import { get } from 'lodash';
import {
    mapCustomFieldDefinitionEditToApi,
    mapCustomFieldDefinitionFromApi,
    mapCustomFieldDefinitionToApi,
} from '../mappers/customFieldDefinition';
import { configError } from '../../utils/utils';
import type { SalesChannelsService } from '../SalesChannelsService';

class CustomFieldDefinition {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getList = this.getList.bind(this);
        this.deleteById = this.deleteById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    async getList(
        id,
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            filters?: Record<string, unknown> | undefined;
        } = {},
    ) {
        const { ajax, URLS, DEFAULTS, paramsSerializer, HEADERS } =
            this.salesChannelsService;

        const { page = 1, per_page = DEFAULTS.PER_PAGE, filters } = params;

        const url = URLS.GET_CHANNEL_CUSTOM_FIELD_DEFINITIONS.replace(
            '{id}',
            id,
        );

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                ...filters,
            },
            headers: HEADERS['basic'],
            paramsSerializer,
        };

        const { data = {} } = await ajax.get({ url, config });

        return {
            items: get(data, 'results', []).map((data_1) =>
                mapCustomFieldDefinitionFromApi(data_1),
            ),
            totalItems: get(data, 'total', 0),
        };
    }

    async deleteById(id) {
        const {
            ajax,
            URLS,
            translator,
            ALERT_MESSAGES,
            errorHandlers,
            alert: alertService,
        } = this.salesChannelsService;
        const url = URLS.DELETE_CUSTOM_FIELD_DEFINITION.replace('{id}', id);
        const errorConfig = configError('delete', errorHandlers);

        await ajax.delete({ url, errorConfig });
        alertService.addSuccess({
            message: translator.t(
                ALERT_MESSAGES.CUSTOM_FIELD_DEFINITION_DELETED,
            ),
        });
    }

    async create(data) {
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;
        const url = URLS.POST_CUSTOM_FIELD_DEFINITION;
        const errorConfig = configError('post', errorHandlers);
        const config = {
            headers: HEADERS['basic'],
        };

        const offer = await ajax.post({
            url,
            errorConfig,
            config,
            data: mapCustomFieldDefinitionToApi(data),
        });

        return mapCustomFieldDefinitionFromApi(offer);
    }

    async edit(id, data) {
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;

        const url = URLS.PATCH_CUSTOM_FIELD_DEFINITION.replace('{id}', id);
        const errorConfig = configError('patch', errorHandlers);
        const config = {
            headers: HEADERS['patch'],
        };

        const offer = await ajax.patch({
            url,
            errorConfig,
            config,
            data: mapCustomFieldDefinitionEditToApi(data),
        });

        return mapCustomFieldDefinitionFromApi(offer);
    }
}

export default CustomFieldDefinition;
