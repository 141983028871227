export const MODULE_NAME = 'postSales';

export const FILES_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: [
        'image/jpeg',
        'image/gif',
        'image/svg+xml',
        'image/tiff',
        'image/bmp',
        'image/png',
        'application/pdf',
    ],
    MAX_FILE_SIZE: 1024 * 1024 * 30,
} as const;

export const CAMUNDA_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ';
