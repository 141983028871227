import { grey } from '@mui/material/colors';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material';

export const readOnlyNotchedOutlineStyles = css`
    border-width: 1px !important;
`;

export const readOnlyNotchedOutlineColorStyles = (theme: Theme) => css`
    border-color: ${theme.palette.action.disabled} !important;
`;

export const readOnlyLabelFocusedStyles = (theme: Theme) => css`
    color: ${theme.palette.action.active} !important;
`;

export const inputLabelRootStyles = css`
    color: ${grey[600]};
`;

export const WrapperWithCharacterCounterStyled = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
WrapperWithCharacterCounterStyled.displayName = 'WrapperWithCharacterCounter';
