import moment from 'moment';

export const mapCustomerRequestProcess = (data = {}) => {
    return data.id;
};

export const mapCustomerRequestsVariable = (customerRequestsVariableData, customerRequestsProcessData) => {
    return customerRequestsProcessData.map(({ id, start_time, state }) => {
        const data = customerRequestsVariableData.filter((item) => id === item.process_instance_id);

        return data.reduce((prev, curr) => {
            return {
                id,
                state,
                createdAt: moment(start_time).format('DD/MM/YYYY'),
                [curr.name]: curr.value,
                ...prev,
            };
        }, {});
    });
};
