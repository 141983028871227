export const mapContractorFromApi = (contractor) => contractor;

/**
 * @param {string} id
 * @returns {{id: string}}
 */
const mapContractorIdToDTO = (id) => ({ id });

/**
 * @param {(string | string[])} priceListId
 * @returns {{ price_lists: {id: string}[] }}
 */
export const createContractorAddPriceListPayload = (priceListId) => ({
    price_lists: Array.isArray(priceListId) ? priceListId.map(mapContractorIdToDTO) : [{ id: priceListId }]
});

export const mapContractorPriceListsFromApi = (results = []) => ({
    items: results.map(({ id, code, offer } = {}) => ({
        id,
        code,
        offer: offer.name,
    })),
    itemsTotal: results.length,
});
