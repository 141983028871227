import get from 'lodash/get';

import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';
import type { StockService } from '../StockService';

class Tags {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = this.stockService.URLS;
        this.errorHandlers = this.stockService.errorHandlers;

        this.getList = this.getList.bind(this);
    }

    async getList(search = '', groupName = 'tag', limit = 100) {
        const { ajax } = this.services;
        const url = this.URLS.GET_PATTERN_TAGS;
        const onError = this.errorHandlers.get;
        const config = {
            params: {
                limit,
                search,
                group_name: groupName,
            },
        };

        const response = await ajax.get({ url, config, onError });

        return get(response, 'data.results', []);
    }
}

export default Tags;
