import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function HistoryIcon(props) {
    return (
        <SvgIcon
            viewBox="-0.5 -0.5 17 17"
            { ...props }
        >
            <path
                d="M2.05435 0.00157123C2.0394 0.00428879 2.02446 0.00836462 2.01087 0.0124408C1.84918 0.0491257 1.73505 0.194506 1.73913 0.360267V3.83853H5.21739C5.34239 3.83989 5.45924 3.77467 5.5231 3.66597C5.5856 3.55728 5.5856 3.42413 5.5231 3.31543C5.45924 3.20673 5.34239 3.14152 5.21739 3.14288H2.90217C4.2106 1.85755 6.00679 1.05592 8 1.05592C12.0571 1.05592 15.3043 4.3032 15.3043 8.36027C15.3043 12.4173 12.0571 15.6646 8 15.6646C3.94294 15.6646 0.695652 12.4173 0.695652 8.36027C0.695652 7.16597 0.990489 5.99749 1.48913 5.00157L0.869565 4.68635C0.324728 5.77739 0 7.05048 0 8.36027C0 12.7896 3.57065 16.3603 8 16.3603C12.4293 16.3603 16 12.7896 16 8.36027C16 3.93092 12.4293 0.360267 8 0.360267C5.8356 0.360267 3.86685 1.22847 2.43478 2.62114V0.360267C2.43886 0.259724 2.3981 0.163256 2.32609 0.0953211C2.25272 0.0260279 2.15353 -0.00793973 2.05435 0.00157123ZM7.65217 2.79505V7.76244C7.44429 7.88336 7.30435 8.10347 7.30435 8.36027C7.30435 8.42005 7.31114 8.47847 7.32609 8.53418L4.96739 10.8929L5.46739 11.3929L7.82609 9.03418C7.88179 9.04912 7.94022 9.05592 8 9.05592C8.38451 9.05592 8.69565 8.74478 8.69565 8.36027C8.69565 8.10347 8.55571 7.88336 8.34783 7.76244V2.79505H7.65217Z"
            />
        </SvgIcon>
    );
}

HistoryIcon.defaultProps = {
    titleAccess: 'history-icon',
};

HistoryIcon.propTypes = svgIconPropTypes;
