import parser from 'qs';
import get from 'lodash/get';
import set from 'lodash/set';
import { fromJS } from 'immutable';

import {
    createContractorAddPriceListPayload,
    mapContractorFromApi,
    mapContractorPriceListsFromApi,
} from 'services/armApi/mappers/contractor';
import { parseContractorData } from 'modules/dropshipping/utils/contractorHelpers';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceConst,
    IArmApiServiceServices,
} from '../armApi.type';
import type { AxiosResponse } from 'axios';

class Contractors {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly APP_ALERTS: IArmApiServiceConst['APP_ALERTS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS, APP_ALERTS }: Pick<IArmApiServiceConst, 'URLS' | 'APP_ALERTS'>,
    ) {
        this.URLS = URLS;
        this.APP_ALERTS = APP_ALERTS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.getPriceLists = this.getPriceLists.bind(this);
        this.addPriceLists = this.addPriceLists.bind(this);
        this.deletePriceList = this.deletePriceList.bind(this);
    }

    private paramsSerializer = (params: unknown) => parser.stringify(params);

    async create(data: unknown) {
        const { errorHandlers } = this.armApiService;
        const { ajax, alert, translator } = this.services;

        const url = this.URLS.POST_CONTRACTOR;
        const errorConfig = {
            throwError: errorHandlers.createContractor,
            addGenericAlert: false,
        };

        const newContractor = parseContractorData(fromJS(data));

        const response = await ajax.post({
            url,
            errorConfig,
            data: newContractor,
        });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.CONTRACTOR_CREATED),
        });

        return response.data;
    }

    async edit(data: Record<string, unknown> = {}) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const { id } = data;
        const url = this.URLS.PUT_CONTRACTOR.replace('{id}', id as string);
        const errorConfig = {
            throwError: errorHandlers.put,
            addGenericAlert: false,
        };

        const contractor = parseContractorData(fromJS(data));

        const response = await ajax.put({ url, data: contractor, errorConfig });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.CONTRACTOR_CHANGED),
        });

        return response.data;
    }

    async getById(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_CONTRACTOR.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const response = await ajax.get({ errorConfig, url });

        return mapContractorFromApi(response.data);
    }

    async getPriceLists(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_CONTRACTOR_PRICE_LISTS.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const response = await ajax.get({ errorConfig, url });

        return mapContractorPriceListsFromApi(response.data?.price_lists || []);
    }

    addPriceLists(
        contractorId: string,
        priceListId: string | string[],
    ): Promise<AxiosResponse<unknown>> {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_CONTRACTOR_PRICE_LISTS.replace(
            '{id}',
            contractorId,
        );
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        return ajax.post({
            errorConfig,
            url,
            data: createContractorAddPriceListPayload(priceListId),
        });
    }

    async deletePriceList({ contractorId, priceListId }) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DELETE_CONTRACTOR_PRICE_LIST.replace(
            '{contractorId}',
            contractorId,
        ).replace('{priceListId}', priceListId);
        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(
                this.APP_ALERTS.CONTRACTOR_PRICE_LIST_DELETED,
            ),
        });

        return response.data;
    }

    async getList({
        filters = {},
        per_page = 20,
        page = 1,
        sort = {},
    }: {
        filters?: Record<string, unknown>;
        sort?: Record<string, unknown>;
        per_page?: number;
        page?: number;
    }) {
        const { ajax } = this.services;

        const { search } = filters;

        const url = this.URLS.GET_CONTRACTORS;
        const config = {
            params: {
                per_page,
                page,
                sort,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (sort?.patron) {
            sort.manager = sort.patron;
        }
        const filtersParams = {
            sort,
            filter: {
                phonenumber: { match: filters?.phoneNumber },
                nip_number: { match: filters?.nipNumber },
                manager_id: { eq: filters?.patronID },
                type: { eq: filters?.type },
                blocked: filters?.blocked,
                do_not_combine_in_multipackage:
                    filters?.do_not_combine_in_multipackage,
            },
        };

        if (search) {
            set(filtersParams, 'filter.search.match', search);
        }

        config.params = {
            ...config.params,
            ...filtersParams,
        };

        try {
            const response = await ajax.get({ url, config });
            const items = get(response, 'data.hydra:member', []);
            const totalItems = get(response, 'data.hydra:totalItems', []);

            return {
                items: items.map((contractor: unknown) =>
                    mapContractorFromApi(contractor),
                ),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }

    async delete(id: string) {
        const { ajax, alert, translator } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.DELETE_CONTRACTOR.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(this.APP_ALERTS.CONTRACTOR_DELETED),
        });

        return response.data;
    }
}

export default Contractors;
