import { AjaxService } from './AjaxService';
import axios from 'axios';
import i18next from 'i18n';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';
import type { AlertService } from 'services/alert/AlertService';

export const ajaxServiceFactory = (
    authenticationService: AuthenticationService,
    alertService: AlertService,
) =>
    AjaxService.getInstance({
        axios,
        authenticationService,
        alertService,
        translator: i18next,
    });
