import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function RemoveIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -2 16 16"
            { ...props }
        >
            <path
                d="M12.783 6.64575C12.6967 6.56443 12.5821 6.51991 12.4636 6.52162L1.49456 6.52158C1.43387 6.52034 1.37354 6.53123 1.31711 6.5536C1.26068 6.57597 1.20928 6.60938 1.16592 6.65186C1.12257 6.69434 1.08812 6.74505 1.06461 6.80102C1.04109 6.85698 1.02898 6.91707 1.02898 6.97778C1.02898 7.03848 1.04109 7.09857 1.06461 7.15454C1.08812 7.2105 1.12257 7.26121 1.16593 7.30369C1.20928 7.34618 1.26068 7.37958 1.31711 7.40196C1.37354 7.42433 1.43387 7.43522 1.49456 7.43398L12.4636 7.43402C12.5555 7.43533 12.6457 7.40883 12.7223 7.358C12.7989 7.30716 12.8584 7.23437 12.8929 7.14916C12.9275 7.06395 12.9355 6.97029 12.9158 6.88046C12.8962 6.79063 12.8499 6.70882 12.783 6.64575Z"
            />
        </SvgIcon>
    );
}

RemoveIcon.defaultProps = {
    titleAccess: 'remove-icon',
};

RemoveIcon.propTypes = svgIconPropTypes;
