import {
    setInitialState,
    createReduxMethods,
    clearErrors,
} from 'utils/redux/handleTableView/reduxMethods';
import * as C from './constants';

const options = {
    singularName: 'product',
    pluralName: 'products',
};

export const initialState = setInitialState(options);

export default function productsReducer(state = initialState, action = {}) {
    const actionProps = { options, state, action };

    /* istanbul ignore next */
    switch (action.type) {
        case C.CLEAR_ERRORS: return clearErrors(actionProps);

        case C.CREATE_PRODUCT.request: return createReduxMethods.request(actionProps);
        case C.CREATE_PRODUCT.failure: return createReduxMethods.failure(actionProps);
        case C.CREATE_PRODUCT.success: return createReduxMethods.success(actionProps);

        default: return state;
    }
}
