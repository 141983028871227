import { makeAction, makeRequestActions } from 'utils/redux/makeActions';
import * as C from './constants';

export const createIntermediateProduct = makeRequestActions(C.CREATE_INTERMEDIATE_PRODUCT);
export const removeIntermediateProduct = makeRequestActions(C.REMOVE_INTERMEDIATE_PRODUCT);
export const editIntermediateProduct = makeRequestActions(C.EDIT_INTERMEDIATE_PRODUCT);
export const fetchIntermediateProducts = makeRequestActions(C.FETCH_INTERMEDIATE_PRODUCTS);
export const fetchIntermediateProduct = makeRequestActions(C.FETCH_INTERMEDIATE_PRODUCT);
export const fetchTechnologyComponents = makeRequestActions(C.FETCH_TECHNOLOGY_COMPONENTS);

export const clearErrors = makeAction(C.CLEAR_ERRORS);
export const clearPostResponse = makeAction(C.CLEAR_POST_RESPONSE);
