const base = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {} } = response;

    if (data.violations && data.violations.length > 0) {
        data.violations.forEach(({ propertyPath, message }) => {
            if (propertyPath) {
                alertService.addError({
                    message: `${propertyPath}: ${message}`,
                });
            }
        });
        throw error;
    } else if (data['hydra:description']) {
        alertService.addError({
            message: data['hydra:description'],
        });
        throw error;
    } else {
        alertService.addError({
            message: 'An unknown error has occurred',
        });
    }
    throw error;
};
const errorHandlers = (alertService) => ({
    patch: base(alertService),
});

export default errorHandlers;
