import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CommentIcon(props) {
    return (
        <SvgIcon
            viewBox="1 0 17 17"
            { ...props }
        >
            <path
                d="M2.73913 2C1.78397 2 1 2.7513 1 3.66667V11.6667C1 12.582 1.78397 13.3333 2.73913 13.3333H4.16304C4.2106 13.7344 4.20516 14.1055 4.09783 14.4167C3.96603 14.7995 3.72011 15.1211 3.26087 15.3854C3.13179 15.4635 3.07201 15.6133 3.11141 15.7539C3.15082 15.8945 3.28261 15.9948 3.43478 16C4.91033 16 6.6019 15.2617 7.45652 13.3333H15.2609C16.216 13.3333 17 12.582 17 11.6667V3.66667C17 2.7513 16.216 2 15.2609 2H2.73913ZM2.73913 2.66667H15.2609C15.837 2.66667 16.3043 3.11458 16.3043 3.66667V11.6667C16.3043 12.2188 15.837 12.6667 15.2609 12.6667H7.26087C7.11549 12.6667 6.98505 12.7539 6.93478 12.8854C6.39402 14.2995 5.42799 14.9727 4.42391 15.2188C4.55978 15.0326 4.68886 14.8359 4.76087 14.625C4.94158 14.099 4.92663 13.5234 4.81522 12.9375C4.78397 12.7826 4.64402 12.6693 4.47826 12.6667H2.73913C2.16304 12.6667 1.69565 12.2188 1.69565 11.6667V3.66667C1.69565 3.11458 2.16304 2.66667 2.73913 2.66667Z"
            />
            <line
                x1="4.58154"
                y1="5.4375"
                x2="11.7989"
                y2="5.4375"
                stroke="#9B9B9B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="4.58154"
                y1="7.40625"
                x2="11.7989"
                y2="7.40625"
                stroke="#9B9B9B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="4.58154"
                y1="9.375"
                x2="11.7989"
                y2="9.375"
                stroke="#9B9B9B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

        </SvgIcon>
    );
}

CommentIcon.defaultProps = {
    titleAccess: 'comment-icon',
};

CommentIcon.propTypes = svgIconPropTypes;
