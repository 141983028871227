import parser from 'qs';
import i18next from 'i18n';
import {
    URLS,
    DEFAULTS,
    USER_PARAMS,
    POSITION_PARAMS,
    WORKPLACE_MODAL_ROLE,
    STORAGE_CURRENT_WORKPLACE,
} from './constants';
import { ListModel } from './models';
import PdpService from './PdpService';
import errorHandlers from './errorHandlers';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';

export const pdpServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
    authenticationService: AuthenticationService,
) =>
    PdpService.getInstance({
        services: {
            parser,
            ajax: ajaxService,
            alerts: alertService,
            translator: i18next,
            authentication: authenticationService,
        },
        storage: localStorage,
        URLS,
        DEFAULTS,
        ListModel,
        errorHandlers: errorHandlers(alertService),
        params: {
            USER_PARAMS,
            POSITION_PARAMS,
        },
        WORKPLACE_MODAL_ROLE,
        STORAGE_CURRENT_WORKPLACE,
    });
