export const mapChannelPatternTitlesFromAPI = (data = {}) => ({
    title: data?.title || '',
    id: data?.id || '',
    language: data?.language || '',
});

export const mapChannelPatternTitleToAPI = (data = {}) => ({
    language: data?.language || '',
    title: data?.title || '',
});

export const mapChannelPatternTitleEditToAPI = (data = {}) => ({
    title: data?.title || '',
});
