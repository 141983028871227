import i18next from 'i18n';
import { StockService } from './StockService';
import RequestCache from '../requestCache/RequestCache';
import errorHandlers from './errorHandlers';
import { URLS, APP_ALERTS, DEFAULTS } from './constants';
import { uploadServicesFactory } from './uploadServices/uploadServicesFactory';
import { ApplicationJsonHeaders, HttpPatchHeaders } from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';
import type { UserService } from 'services/userService/UserService';
import { filesUploadFactory } from 'services/filesUpload/filesUploadFactory';

export const stockServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
    authenticationService: AuthenticationService,
    userService: UserService
) =>
    StockService.getInstance({
        services: {
            ajax: ajaxService,
            alert: alertService,
            userServiceApi: userService,
            translator: i18next,
            upload: uploadServicesFactory((config) =>
                filesUploadFactory(config, authenticationService),
            ),
            requestCache: new RequestCache({ cacheTime: 300000 }),
        },
        errorHandlers: errorHandlers(alertService),
        constants: {
            URLS,
            APP_ALERTS,
            DEFAULTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
                patch: HttpPatchHeaders,
            },
        },
    });
