import React from 'react';
import * as PropTypes from 'prop-types';
import withServices from 'hoc/withServices/withServices';
import TosModal from './TosModal';
import { resetToken, logoutUser } from '../../reducers/userProfile/actions';

class Gatekeeper extends React.Component {
    constructor(props) {
        super(props);
        this.tosModalActive = false;

        this.authorizeUser = this.authorizeUser.bind(this);
        this.checkForTos = this.checkForTos.bind(this);
    }

    componentDidMount() {
        const {
            services: { authentication },
        } = this.props;
        const token = authentication.getActiveToken();
        const tokenIsFresh = !authentication.isTokenExpire();

        authentication.subscribeListener('authorizeUser', this.authorizeUser);

        if (token && tokenIsFresh) {
            this.authorizeUser();
            this.getUserData();
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    getUserData() {
        this.props.services.profile.getMe();
    }

    authorizeUser = () => {
        const {
            services: { authentication },
        } = this.props;
        const token = authentication.getActiveToken();

        this.checkForTos(token);
    };

    checkForTos(token) {
        const missingTos = token['missing_tos_consents_ids'] || [];

        if (!this.tosModalActive && missingTos.length > 0) {
            const id = missingTos[0];

            this.renderAcceptTosModal(id);
        }
    }

    closeAcceptTosModal(close, logout = false) {
        const {
            services: { store },
        } = this.props;

        if (logout) {
            store.dispatch(logoutUser());
        }
        if (this.tosModalActive) {
            store.dispatch(resetToken.request({ noDelay: true }));
        }
        this.tosModalActive = false;
        close();
    }

    renderAcceptTosModal(id) {
        const {
            services: { modal },
        } = this.props;

        this.tosModalActive = true;
        modal.open({
            id: 'acceptTos',
            children: (close) => (
                <TosModal
                    id={id}
                    close={({ logout }) =>
                        this.closeAcceptTosModal(close, logout)
                    }
                />
            ),
        });
    }

    render() {
        return null;
    }
}

Gatekeeper.propTypes = {
    services: PropTypes.shape({
        modal: PropTypes.shape({
            open: PropTypes.func.isRequired,
        }).isRequired,
        authentication: PropTypes.shape({
            getActiveToken: PropTypes.func.isRequired,
            subscribeListener: PropTypes.func.isRequired,
            isTokenExpire: PropTypes.func.isRequired,
        }).isRequired,
        store: PropTypes.shape({
            dispatch: PropTypes.func.isRequired,
        }).isRequired,
        profile: PropTypes.shape({
            getMe: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
};

const GatekeeperWithHOC = withServices(Gatekeeper);

export default GatekeeperWithHOC;
