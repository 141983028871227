import { ApplicationJsonHeaders } from 'services/constants';
import type { AjaxService } from '../ajax/AjaxService';
import {
    DynamicFormDictionaryRequestParams,
    DynamicFormDictionaryResponse,
} from './dynamicFormDictionaries.type';
import type { ErrorHandler } from './errorHandler';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';

export class DynamicFormDictionariesService {
    private static instance: DynamicFormDictionariesService | undefined;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly errorHandler: ErrorHandler,
    ) {
        this._getDictionaryPage = this._getDictionaryPage.bind(this);
        this.getDictionary = this.getDictionary.bind(this);
    }

    static getInstance(ajaxService: AjaxService, errorHandler: ErrorHandler) {
        if (!DynamicFormDictionariesService.instance) {
            DynamicFormDictionariesService.instance =
                new DynamicFormDictionariesService(ajaxService, errorHandler);
        }

        return DynamicFormDictionariesService.instance;
    }

    _getDictionaryPage<T>(
        url: string,
        params: DynamicFormDictionaryRequestParams,
    ): Promise<{ data: DynamicFormDictionaryResponse<T> }> {
        return this.ajaxService.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                params,
            },
            errorConfig: {
                onError: this.errorHandler.handleGet,
            },
            onError: this.errorHandler.handleGet,
        });
    }

    async getDictionary<T>(url: string): Promise<T[]> {
        const asyncRequestPaginator = new AsyncRequestPaginator<T, T>(
            (params) => this._getDictionaryPage<T>(url, params),
            (data) => data,
        );

        const { items } = await asyncRequestPaginator.fetchFullList({
            page: 1,
        });

        return items;
    }
}
