import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    ImageContainerStyled,
    NotFoundPageStyled,
} from 'modules/app/pages/NotFound/notFound.styles';

class Home extends React.PureComponent {
    render() {
        const { t } = this.props;

        return (
            <NotFoundPageStyled>
                <ImageContainerStyled>
                    <img
                        src="/images/404.png"
                        alt="404"
                    />
                </ImageContainerStyled>
                <div>{t('alerts.pageNotFound')}</div>
            </NotFoundPageStyled>
        );
    }
}

Home.defaultProps = {
    t: (key) => key,
};

Home.propTypes = {
    t: PropTypes.func,
};

const HomeWithHOC = withTranslation('common')(Home);

export default HomeWithHOC;
