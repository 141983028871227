import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ServicesContext from 'contexts/ServicesContext';
import { List, ListItem, Grid } from '@mui/material';
import Typography from 'components/Typography';

export const SettingsPage = (props) => {
    const { errorHistory } = useContext(ServicesContext);
    const rows = errorHistory?.getErrors() || [];

    return (
        <List>
            <ListItem>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography variant="h3">
                            {props.t('common:labels.message')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                    >
                        <Typography variant="h3">
                            {props.t('common:glossary.date')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Typography variant="h3">
                            {props.t('common:labels.path')}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
            {rows.map((row) => {
                return (
                    <ListItem key={row.id}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                {row?.data?.message}
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >
                                {moment(row.context.timestamp).fromNow()}
                                <br />
                                {moment(row.context.timestamp).format(
                                    'DD-MM HH:mm:ss',
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                            >
                                {row?.context?.location.pathname}
                            </Grid>
                        </Grid>
                    </ListItem>
                );
            })}
        </List>
    );
};

SettingsPage.defaultProps = {
    t: (key) => key,
};

SettingsPage.propTypes = {
    t: PropTypes.func,
};

const SettingsPageWithHOC = withTranslation()(SettingsPage);

export default SettingsPageWithHOC;
