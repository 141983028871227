import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        CONTRACTOR: {
            FETCH: `${MODULE_NAME}_resources_contractor_fetch`,
            EDIT: `${MODULE_NAME}_resources_contractor_edit`,
            CREATE: `${MODULE_NAME}_resources_contractor_create`,
            DELETE: `${MODULE_NAME}_resources_contractor_delete`,
            SEARCH: `${MODULE_NAME}_resources_contractor_search`,
            ASSIGN_MANAGER: `${MODULE_NAME}_resources_contractor_assign_manager`,
            VERIFY: `${MODULE_NAME}_resources_contractor_verify`,
            SEE_OFFER: `${MODULE_NAME}_resources_contractor_see_offer`,
        },
        INVOICE: {
            FETCH: `${MODULE_NAME}_resources_invoice_fetch`,
        },
        ORDER_IMPORT: {
            FETCH: `${MODULE_NAME}_resources_order_import_fetch`,
            EDIT: `${MODULE_NAME}_resources_order_import_edit`,
            CREATE: `${MODULE_NAME}_resources_order_import_create`,
            DELETE: `${MODULE_NAME}_resources_order_import_delete`,
            PASS_TO_PRODUCTION: `${MODULE_NAME}_resources_order_pass_to_production`,
        },
        DISCOUNT: {
            FETCH: `${MODULE_NAME}_resources_discount_fetch`,
            EDIT: `${MODULE_NAME}_resources_discount_edit`,
            CREATE: `${MODULE_NAME}_resources_discount_create`,
            DELETE: `${MODULE_NAME}_resources_discount_delete`,
        },
        PRICE_LIST: {
            FETCH: `${MODULE_NAME}_resources_price_list_fetch`,
        },
    },
};
