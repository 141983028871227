import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function VisibilityOnIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -3 14 14"
            { ...props }
        >
            <path
                d="M7.00004 0.333252C2.33337 0.333252 0.583374 4.99992 0.583374 4.99992C0.583374 4.99992 2.33337 9.66658 7.00004 9.66658C11.6667 9.66658 13.4167 4.99992 13.4167 4.99992C13.4167 4.99992 11.6667 0.333252 7.00004 0.333252ZM7.00004 1.49992C10.0777 1.49992 11.6344 3.98906 12.1384 4.99764C11.6338 5.99922 10.0655 8.49992 7.00004 8.49992C3.92237 8.49992 2.36569 6.01078 1.86169 5.0022C2.36686 4.00061 3.93462 1.49992 7.00004 1.49992ZM7.00004 2.66658C5.71146 2.66658 4.66671 3.71133 4.66671 4.99992C4.66671 6.2885 5.71146 7.33325 7.00004 7.33325C8.28862 7.33325 9.33337 6.2885 9.33337 4.99992C9.33337 3.71133 8.28862 2.66658 7.00004 2.66658ZM7.00004 3.83325C7.64462 3.83325 8.16671 4.35533 8.16671 4.99992C8.16671 5.6445 7.64462 6.16658 7.00004 6.16658C6.35546 6.16658 5.83337 5.6445 5.83337 4.99992C5.83337 4.35533 6.35546 3.83325 7.00004 3.83325Z"
            />
        </SvgIcon>
    );
}

VisibilityOnIcon.defaultProps = {
    titleAccess: 'visibility-on-icon',
};

VisibilityOnIcon.propTypes = svgIconPropTypes;
