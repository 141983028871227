import React from 'react';
import * as PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import Modal from 'components/Modal/modal';

export class CancelModal extends React.PureComponent {
    render() {
        const {
            t,
            create,
            active,
            onClose,
            onConfirm,
        } = this.props;

        return (
            <Modal
                active={ active }
                leftButton={ upperFirst(t('common:actions.cancel')) }
                rightButton={ upperFirst(t('common:actions.ok')) }
                label={
                    create ?
                        t('common:labels.createModal')
                        :
                        t('common:labels.editModal')
                }
                closeModal={ onClose }
                onSubmit={ onConfirm }
            >
                {
                    create ?
                        t('common:contents.createModal')
                        :
                        t('common:contents.editModal')
                }
            </Modal>
        );
    }
}

CancelModal.defaultProps = {
    t: (key) => key,
};

CancelModal.propTypes = {
    t: PropTypes.func,
    create: PropTypes.bool,
    active: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
