import i18next from 'i18next';

const base = (alertService) => (error = {}) => {
    const { response = {} } = error;
    const { data = {}, status } = response;

    if (status === 403) {
        alertService.addError({
            message: i18next.t('production:errors.forbidden')
        });

        throw error;
    }

    if (status === 404) {
        alertService.addError({
            message: i18next.t('production:errors.noProductionOrderAvailable')
        });

        throw error;
    }

    if (data.detail) {
        alertService.addError({
            message: data.detail
        });

        throw error;
    }

    alertService.addError({
        message: i18next.t('app:alerts.unknown'),
    });

    throw error;
};

const errorHandlers = (alertService) => ({
    get: base(alertService),
    post: base(alertService),
    put: base(alertService),
});

export default errorHandlers;
