import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProviderStyled, ErrorIconStyled, InfoIconStyled, SuccessIconStyled, WarningIconStyled } from './alertProvider.styles';

function AlertProvider({ children }) {
    return (
        <SnackbarProviderStyled
            iconVariant={{
                success: <SuccessIconStyled />,
                error: <ErrorIconStyled />,
                warning: <WarningIconStyled />,
                info: <InfoIconStyled />,
            }}
        >
            { children }
        </SnackbarProviderStyled>
    );
}

AlertProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AlertProvider;
