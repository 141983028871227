import get from 'lodash/get';

export const mapUserFromApi = (data = {}) => {
    const username = get(data, 'username', '');
    const firstName = get(data, 'first_name', '');
    const lastName = get(data, 'last_name', '');
    const fullName = (firstName && lastName) ?
        `${firstName} ${lastName}`
        :
        firstName ? firstName : lastName ? lastName : '';

    return {
        id: get(data, 'id', ''),
        username,
        firstName,
        lastName,
        fullName,
    };
};
