import { fromJS } from 'immutable';
import { getString } from 'utils/immutable/map';
import { getErrorCodeFromPayload } from '../helpers';
import upperFirst from 'lodash/upperFirst';

export function fetchRequest({ options, state, action }) {
    const { singularName } = options;
    const { payload = {} } = action;
    const loaderName = payload.loaderName || singularName;

    return state
        .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
        .setIn(['loaders', `${loaderName}Details`], true)
        .setIn(['loaders', `${loaderName}Create`], true);
}

export function fetchFailure({ options, state, action }) {
    const { singularName } = options;
    const { payload = {} } = action;
    const loaderName = payload.loaderName || singularName;
    const errorCode = getErrorCodeFromPayload(action.payload);

    return state
        .set(`fetch${upperFirst(singularName)}ErrorCode`, errorCode)
        .setIn(['loaders', `${loaderName}Details`], false)
        .setIn(['loaders', `${loaderName}Create`], false);
}

export function fetchSuccess({ options, state, action }) {
    const { pluralName, singularName, model } = options;
    const { payload = {} } = action;
    const loaderName = payload.loaderName || singularName;
    const item = model ? new model(action.payload.response) : fromJS(action.payload.response);

    return state
        .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
        .setIn([`${pluralName}ById`, getString(item, 'id')], item)
        .setIn(['loaders', `${loaderName}Details`], false)
        .setIn(['loaders', `${loaderName}Create`], false);
}
