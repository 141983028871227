import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function StorageIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0.5 16 16"
            { ...props }
        >
            <path
                d="M8.00775 0.0800471C7.96646 0.0791181 7.92531 0.0853021 7.88611 0.0983284L0.326111 2.61833C0.254414 2.64225 0.192064 2.68812 0.147894 2.74946C0.103725 2.81079 0.0799776 2.88447 0.0800171 2.96005V15.56C0.0800267 15.6555 0.117958 15.7471 0.185469 15.8146C0.25298 15.8821 0.344542 15.92 0.440017 15.92H2.53955C2.57843 15.9265 2.61809 15.9265 2.65697 15.92H13.3395C13.3784 15.9265 13.4181 15.9265 13.457 15.92H13.4626H15.56C15.6555 15.92 15.7471 15.8821 15.8146 15.8146C15.8821 15.7471 15.92 15.6555 15.92 15.56V2.96005C15.9201 2.88447 15.8963 2.81079 15.8521 2.74946C15.808 2.68812 15.7456 2.64225 15.6739 2.61833L8.11392 0.0983284C8.07965 0.0869442 8.04386 0.0807814 8.00775 0.0800471ZM8.00002 0.819735L15.2 3.2195V15.2H13.76V5.12005C13.76 5.02457 13.7221 4.93301 13.6546 4.8655C13.5871 4.79799 13.4955 4.76006 13.4 4.76005H2.60002C2.50454 4.76006 2.41298 4.79799 2.34547 4.8655C2.27796 4.93301 2.24003 5.02457 2.24002 5.12005V15.2H0.800017V3.2195L8.00002 0.819735ZM2.96002 5.48005H13.04V15.2H11.96V11.96C11.96 11.8646 11.9221 11.773 11.8546 11.7055C11.7871 11.638 11.6955 11.6001 11.6 11.6H10.16V8.36005C10.16 8.26457 10.1221 8.17301 10.0546 8.1055C9.98705 8.03799 9.89549 8.00006 9.80002 8.00005H6.20002C6.10454 8.00006 6.01298 8.03799 5.94547 8.1055C5.87796 8.17301 5.84003 8.26457 5.84002 8.36005V11.6H4.40002C4.30454 11.6001 4.21298 11.638 4.14547 11.7055C4.07796 11.773 4.04003 11.8646 4.04002 11.96V15.2H2.96002V5.48005ZM6.56002 8.72005H9.44002V11.6H8.00002H6.56002V8.72005ZM4.76002 12.32H6.20002H7.64002V15.2H4.76002V12.32ZM8.36002 12.32H9.80002H11.24V15.2H8.36002V12.32Z"
            />
        </SvgIcon>
    );
}

StorageIcon.defaultProps = {
    titleAccess: 'storage-icon',
};

StorageIcon.propTypes = svgIconPropTypes;
