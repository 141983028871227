export default function ({ urlParams = {}, filters = [], sort = [] }) {
    const params = {
        filter: {},
        sort: {},
    };

    filters.forEach((field) => {
        let preProcessor = (value) => value;
        let urlName = '';
        let reqName = '';
        let reqType = '';
        let urlValue = '';

        if (typeof field === 'string') {
            urlName = field;

            const options = field.split('_');

            reqName = options[0];
            reqType = options[1];
        } else {
            if (field.preProcessor) preProcessor = field.preProcessor;
            urlName = field.urlName;
            reqName = field.reqName;
            reqType = field.reqType;
        }

        if (urlParams.filters && urlParams.filters[urlName]) {
            urlValue = preProcessor(urlParams.filters[urlName]);
        }

        if (urlValue !== '' && urlValue !== undefined) {
            if (reqType.length > 0) {
                if (params.filter[reqName]) {
                    params.filter[reqName][reqType] = urlValue;
                } else {
                    params.filter[reqName] = {
                        [reqType]: urlValue,
                    };
                }
            } else {
                params.filter[reqName] = urlValue;
            }
        }
    });

    if (urlParams.order) {
        sort.forEach((field) => {
            if (urlParams.order[field]) params.sort[field] = urlParams.order[field];
        });
    }

    return params;
}
