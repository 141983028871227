import get from 'lodash/get';
import { ApplicationJsonHeaders } from '../../constants';
import {
    type URLS as URLS_CONFIG,
    type DEFAULTS as DEFAULTS_CONFIG,
} from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';

class PaymentMethod {
    constructor(
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
        private readonly DEFAULTS: typeof DEFAULTS_CONFIG,
    ) {
        this.getList = this.getList.bind(this);
    }

    async getList(
        params: Partial<{
            page: number;
            per_page: number;
        }> = {},
    ) {
        const ajax = this.ajaxService;
        const { page = 1, per_page = this.DEFAULTS.PAYMENT_METHODS_PER_PAGE } =
            params;

        const url = this.URLS.GET_PAYMENT_METHODS;
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
            },
            headers: ApplicationJsonHeaders,
        };

        const response = await ajax.get({ url, config });

        return get(response, 'data.results', []).reduce(
            (paymentMethodsObject, paymentMethod) => ({
                ...paymentMethodsObject,
                [paymentMethod.code]: paymentMethod.label,
            }),
            {},
        );
    }
}

export default PaymentMethod;
