import { getNamespaces } from '../helpers/getNamespaces';
import { setLoaded, setLoading } from 'utils/immutable/map';

const defaultNamespaces = {
    itemsById: 'itemsById',
    itemsCount: 'itemsCount',
};

export function request({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const id = action.payload.id;
        const item = state.getIn([namespaces.itemsById, id]);

        return state
            .setIn([namespaces.itemsById, id], setLoading(item));
    };
}

export function failure({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const id = action.payload.id;
        const item = state.getIn([namespaces.itemsById, id]);

        return state
            .setIn([namespaces.itemsById, id], setLoaded(item));
    };
}

export function success({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const id = action.payload.id;
        const itemsCount = state.get(namespaces.itemsCount) - 1;

        return state
            .deleteIn([namespaces.itemsById, id])
            .set(namespaces.itemsCount, itemsCount);
    };
}
