import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function DehazeIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -3 16 16"
            { ...props }
        >
            <path
                d="M0.166748 4.41675H11.8334V5.58342H0.166748V4.41675ZM0.166748 0.916748H11.8334V2.08341H0.166748V0.916748ZM0.166748 7.91675H11.8334V9.08342H0.166748V7.91675Z"
            />
        </SvgIcon>
    );
}

DehazeIcon.defaultProps = {
    titleAccess: 'dehaze-icon',
};

DehazeIcon.propTypes = svgIconPropTypes;
