import moment from 'moment';
import { get } from 'lodash';
import i18next from 'i18next';

import makeUrlParams from 'utils/redux/makeUrlParams';

export const isOrderFileExtensionValid = (orderFile) => {
    if (orderFile && orderFile.name) {
        const name = orderFile.name;
        const extension = name.substr(name.lastIndexOf('.') + 1);

        if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') return true;
    }

    return false;
};

export const checkForPrepaidNeeded = (orderImport) => Boolean(
    orderImport.getIn(['contractor', 'setting', 'trade_terms']) === 'prepaid_pdf' ||
    orderImport.getIn(['contractor', 'setting', 'trade_terms']) === 'prepaid_paypal'
);

export const getNewOrderImport = (newOrderImport) => {
    const data = new FormData();

    data.append('contractor_id', newOrderImport.getIn(['contractor', 'id']));
    data.append('orders_file', newOrderImport.get('orders_file'));

    if (checkForPrepaidNeeded(newOrderImport)) {
        data.append('payment_confirmation_required', 'true');
        data.append('payment_confirmation', newOrderImport.get('payment_confirmation'));
    }

    return data;
};

export const validateNewOrderImport = (orderImport) => {
    let error = '';

    if (!orderImport.getIn(['contractor', 'id'])) {
        error = 'contractorRequired';
    } else if (!orderImport.get('orders_file')) {
        error = 'orderFileNeeded';
    } else if (!isOrderFileExtensionValid(orderImport.get('orders_file'))) {
        error = 'orderFileMustBeXLSXorCSV';
    } else if (checkForPrepaidNeeded(orderImport) && !orderImport.get('payment_confirmation')) {
        error = 'PaymentConfirmationNeeded';
    }

    if (error) {
        return {
            message: error,
            propertyPath: 'contractor',
        };
    }

    return false;
};

export const createRequestFromParams = (urlParams) => {
    return makeUrlParams({
        urlParams,
        filters: [
            'status_eq',
            {
                urlName: 'contractor_id_eq',
                reqName: 'contractor_id',
                reqType: 'eq',
            },
            {
                urlName: 'created_by_eq',
                reqName: 'created_by',
                reqType: 'eq',
            },
            {
                urlName: 'created_at_gte',
                reqName: 'created_at',
                reqType: 'gte',
                preProcessor: (date) => moment(date).startOf('day').format(),
            },
            {
                urlName: 'created_at_lte',
                reqName: 'created_at',
                reqType: 'lte',
                preProcessor: (date) => moment(date).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                }).format(),
            },
        ],
        sort: [
            'created_at',
            'status',
        ],
    });
};

export const formatCreateOrderImportError = (responseData = {}, alertService) => {
    const errors = get(responseData, 'violations', []);
    const apiError = get(responseData, 'hydra:description', '');

    if (errors.length > 0) {
        errors.forEach((violation) => {
            const translationPath = `dropshipping:validation.${violation.message}`;
            const translation = i18next.t(translationPath, { defaultValue: violation.message });
            const message = `${violation.propertyPath}: ${translation}`;

            alertService.addError({ message });
        });
    } else if (apiError) {
        alertService.addError({ message: apiError });
    }
};
