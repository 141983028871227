import { makeRequestWithAlerts } from './makeRequest';

const getUrl = (url, path) => {
    if (path) {
        const trimmedUrl = url[url.length] === '/' ? url.substring(0, url.length - 1) : url;
        const trimmedPath = path[0] === '/' ? path.substring(1) : path;

        return trimmedUrl + '/' + trimmedPath;
    }

    return url;
};

export const makeDownloadRequest = (baseUrl, model) => (function*(
    { action, path, params, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'get',
            params: { ...params },
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        true,
        paramsSerializerOptions,
        model,
        mapper,
    );
});

export const makeGetRequest = (baseUrl, model) => (function*(
    { action, path, params, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, disableParamsSerializer, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'get',
            params: { ...params },
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        false,
        paramsSerializerOptions,
        model,
        mapper,
        disableParamsSerializer,
    );
});

export const makeDeleteRequest = (baseUrl, model) => (function*(
    { action, path, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    if (path) {
        const fullUrl = getUrl(baseUrl, path);

        return yield makeRequestWithAlerts(
            action,
            fullUrl,
            { method: 'delete' },
            alerts,
            attributes,
            callbacks,
            sagaCallbacks,
            false,
            paramsSerializerOptions,
            model,
            mapper,
        );
    } else {
        throw new Error('No id in DELETE request!');
    }
});

export const makeDeleteAllRequest = (baseUrl, model) => (function*(
    { action, path, params, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'delete',
            params: { ...params },
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        false,
        paramsSerializerOptions,
        model,
        mapper,
    );
});

export const makePutRequest = (baseUrl, model) => (function*(
    { action, path, data, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'put',
            data,
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        false,
        paramsSerializerOptions,
        model,
        mapper,
    );
});

export const makePatchRequest = (baseUrl, model) => (function*(
    { action, path, data, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'patch',
            data,
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        false,
        paramsSerializerOptions,
        model,
        mapper,
    );
});

export const makePostRequest = (baseUrl, model) => (function*(
    { action, path, params, data, alerts = {}, attributes = {},
        callbacks = {}, sagaCallbacks = {}, paramsSerializerOptions, mapper }
) {
    const fullUrl = getUrl(baseUrl, path);

    return yield makeRequestWithAlerts(
        action,
        fullUrl,
        {
            method: 'post',
            data,
            params: { ...params },
        },
        alerts,
        attributes,
        callbacks,
        sagaCallbacks,
        false,
        paramsSerializerOptions,
        model,
        mapper,
    );
});

export const makeEndPoint = (url, model) => {
    return {
        get: makeGetRequest(url, model),
        download: makeDownloadRequest(url, model),
        put: makePutRequest(url, model),
        patch: makePatchRequest(url, model),
        post: makePostRequest(url, model),
        delete: makeDeleteRequest(url, model),
        deleteAll: makeDeleteAllRequest(url, model),
    };
};
