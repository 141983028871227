import { NavLink } from 'react-router-dom';
import {
    ModuleListItemButtonStyled,
    ModuleListItemIconStyled,
    ModuleListItemTextStyled,
} from './navBar.styles';
import {
    ArrowDropDownIcon as ArrowDropDown,
    ArrowDropUpIcon as ArrowDropUp,
} from 'components/Icons/Icons';
import type {
    NavBarSection,
    NavBarModule,
    TExpandModuleHandler,
} from './navBar.type';

interface INavListItemButton {
    moduleName: string;
    isOpen: boolean;
    onClick: TExpandModuleHandler;
    sections: NavBarSection[];
    module: NavBarModule;
    isActive: boolean;
    fixed: boolean;
    isSubmodule?: boolean | undefined;
}

export const NavListItemButton = ({
    moduleName,
    isOpen,
    onClick,
    sections,
    module,
    isActive,
    fixed,
    isSubmodule = false,
}: INavListItemButton) => {
    const Icon = module.icon;

    const mainSection = sections.at(0);

    return (
        <ModuleListItemButtonStyled
            selected={isOpen || isActive}
            {...(fixed && {
                component: NavLink,
                to: mainSection.path,
            })}
            onClick={() => onClick(module.name, !isOpen)}
        >
            {Icon && (
                <ModuleListItemIconStyled>
                    <Icon />
                </ModuleListItemIconStyled>
            )}
            <ModuleListItemTextStyled
                primary={moduleName}
                style={isSubmodule ? { paddingLeft: '3.5rem' } : {}}
            />
            {!fixed &&
                (isOpen ? (
                    <ArrowDropUp className="arrow" />
                ) : (
                    <ArrowDropDown className="arrow" />
                ))}
        </ModuleListItemButtonStyled>
    );
};
