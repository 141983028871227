import React from 'react';
import PropTypes from 'prop-types';
import { ClearIcon } from 'components/Icons/ClearIcon';
import { getContrastTextColor } from 'utils/colors';
import { BadgeStyled, RemoveButtonStyled } from 'components/Badge/badge.styles';

export class Badge extends React.PureComponent {
    static TYPE = {
        PRIMARY: 'primary',
        SUCCESS: 'success',
        INFO: 'info',
        WARNING: 'warning',
        DANGER: 'danger',
        DEFAULT: 'default',
    };

    static COLORS = {
        BLACK: 'black',
        BLUE: 'blue',
        GREEN: 'green',
        YELLOW: 'yellow',
        RED: 'red',
    };

    static SIZES = {
        LARGE: 'large',
        SMALL: 'small',
        MINI: 'mini',
    };

    handleOnClick = () => {
        const { disabled, onClick } = this.props;

        if (!disabled && onClick) {
            onClick();
        }
    };

    handleOnRemove = () => {
        const { disabled, onRemove } = this.props;

        if (!disabled && onRemove) {
            onRemove();
        }
    };

    renderRemoveButton = () => {
        const { size } = this.props;

        let iconSize;

        switch (size) {
            case 'mini':
                iconSize = 8;
                break;
            case 'small':
                iconSize = 12;
                break;
            case 'large':
                iconSize = 20;
                break;

            default:
                iconSize = 16;
                break;
        }

        return (
            <RemoveButtonStyled>
                <ClearIcon
                    onClick={ this.handleOnRemove }
                    name="exitCross"
                    size={ iconSize }
                    aria-label='Remove Badge'
                />
            </RemoveButtonStyled>
        );
    };

    render() {
        const { children, type, color, size, onClick, onRemove, disabled, customColor } = this.props;

        const customColorStyles = customColor && { backgroundColor: customColor, color: getContrastTextColor(customColor) };

        return (
            <BadgeStyled
                onClick={ this.handleOnClick }
                style={ customColorStyles }
                disabled={ disabled }
                clickable={ onClick }
                size={ size }
                color={ color || type }
            >
                { onRemove && this.renderRemoveButton() }
                { children }
            </BadgeStyled>
        );
    }
}

Badge.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    customColor: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Badge;
