import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { StorageIcon } from 'components/Icons/Icons';

const SalesChannelsTableViewPage = lazy(
    () => import('../pages/SalesChannelsTableViewPage'),
);
const SalesChannelDetailsPage = lazy(
    () => import('../pages/SalesChannelDetailsPage'),
);
const OffersTableViewPage = lazy(() => import('../pages/OffersTableViewPage'));
const OfferDetailsPage = lazy(() => import('../pages/OfferDetailsPage'));

export const manageOffersRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            forceAccess: true,
            exact: true,
            name: 'salesChannelsTable',
            path: PATHS.SALES_CHANNELS,
            component: SalesChannelsTableViewPage,
        },
        {
            forceAccess: true,
            exact: true,
            name: null,
            path: PATHS.SALES_CHANNEL_DETAILS,
            component: SalesChannelDetailsPage,
        },
        {
            exact: true,
            name: 'offersTable',
            path: PATHS.OFFERS,
            component: OffersTableViewPage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.OFFERS_DETAILS,
            component: OfferDetailsPage,
        },
    ],
    icon: StorageIcon,
};
