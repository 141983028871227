import { Collection, fromJS, Iterable, List, Map as ImmutableJSMap, OrderedMap } from 'immutable';
import { getString } from './map';

interface IConstructable<TValue, TClass> {
    new(value: TValue): TClass;
}

const isImmutableJSList = <T>(value: unknown): value is List<T> => List.isList(value);

export function toIdMap<TKeyType, T extends ImmutableJSMap<TKeyType, unknown>>(list: List<T>, idKey?: string): OrderedMap<unknown, unknown>;
export function toIdMap<TArrayType, TValueType, TClassType>(array: TArrayType[], Model?: IConstructable<TValueType, TClassType>): OrderedMap<unknown, unknown>;
export function toIdMap<TListOrArrayType, TValueType, TClassType>(listOrArray: List<TListOrArrayType> | TListOrArrayType[], idKeyOrModel?: string | IConstructable<TValueType, TClassType>): OrderedMap<unknown, unknown> {
    let withIds: Iterable<number, [string, unknown]> | unknown[];

    if (isImmutableJSList(listOrArray)) {
        withIds = listOrArray.map((value) => (([getString(value as ImmutableJSMap<unknown, unknown>, idKeyOrModel ?? 'id'), fromJS(value)])));
    } else {
        withIds = listOrArray.map((value: any = {}) => {
            if (!value?.id || !value?.id?.toString) {
                return value;
            }

            return (([value.id.toString(), idKeyOrModel ? new (idKeyOrModel as IConstructable<TValueType, TClassType>)(value) : fromJS(value)]));
        });
    }

    return OrderedMap(withIds);
}

export const sortByKey = <T extends Collection<string, unknown>>(list: List<T>, key: string, isDesc: boolean) => {
    const keyArray = key.split('.');

    return list.sortBy(
        (item) => {
            return item?.getIn(keyArray);
        },
        (curr, prev) => {
            if (curr < prev) {
                return isDesc ? 1 : -1;
            }
            if (curr > prev) {
                return isDesc ? -1 : 1;
            }

            return 0;
        });
};

export function* mapGen<T, V>(list: List<T>, callback: (arg: T) => V) {
    const mappedArray = [];

    for (let i = 0; i < list.size; i++) {
        mappedArray[i] = yield callback(list.get(i));
    }

    return List(mappedArray);
}
