import * as S from 'access/sections';
import * as A from 'access/accesses';
import { ACCEPT } from 'access/voteTypes';

export const ROLE_PRODIS = {
    section: S.PRODIS,
    type: 'main',
    votes: {
        [A.PRODIS.MODULE]: ACCEPT,
        [A.PRODIS.PATHS.SHIPMENT_VERIFICATION]: ACCEPT,
        [A.PRODIS.PATHS.DELIVERED_SHIPMENTS]: ACCEPT,
        [A.PRODIS.PATHS.CUSTOM_SHIPMENTS]: ACCEPT,
        [A.PRODIS.PATHS.CUSTOM_SHIPMENTS_DETAILS]: ACCEPT,
        [A.PRODIS.PATHS.SHIPMENT_VERIFICATION_DETAILS]: ACCEPT,
        [A.PRODIS.PATHS.SHIPMENTS]: ACCEPT,
        [A.PRODIS.PATHS.DELIVERY_NOTES]: ACCEPT,
    },
};

export const ROLE_PRODIS_CARRIER_RULE_MANAGE = {
    section: S.PRODIS,
    type: 'main',
    votes: {
        [A.PRODIS.PATHS.CARRIER_RULE_MANAGEMENT]: ACCEPT,
        [A.PRODIS.PATHS.CARRIER_RULE_DETAILS]: ACCEPT,
    },
};
