export const mapInvoicesFromApi = (data = {}) => {
    const {
        invoice_number = '',
        contractor,
        order_price,
        invoice_date = '',
        payment_date = '',
        payment_status = '',
        id = '',
        download_pdf_link = '',
    } = data;

    return {
        id,
        invoice_number,
        invoice_date,
        payment_date,
        payment_status,
        download_pdf_link,
        contractor: {
            name: contractor && contractor['name'] || '',
        },
        order_price: {
            price_net: order_price && order_price['price_net'] || 0,
            price_currency: order_price && order_price['price_currency'] || '',
            price_gross: order_price && order_price['price_gross'] || 0,
        },
    };
};
