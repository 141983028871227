import React from 'react';
import { useWidth } from 'hooks/utils/useWidth';

export const withWidth = (options) => (WrappedComponent) => (props) => {
    const width = useWidth();

    return (
        <WrappedComponent
            { ...props }
            width={ options?.initialWidth || width }
        />
    );
};
