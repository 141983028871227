import upperFirst from 'lodash/upperFirst';
import type { ErrorHistory } from 'services/ErrorHistory/ErrorHistory';
import { ALERTS_VARIANTS, BASE_ALERT_HANDLERS } from './constants';

export class AlertService {
    private listeners: Record<string, (alert: unknown) => void> = {};

    private constructor(private readonly history: ErrorHistory) {
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.generateAlert = this.generateAlert.bind(this);

        this.addAlert = this.addAlert.bind(this);
        this.addError = this.addError.bind(this);
        this.addSuccess = this.addSuccess.bind(this);
        this.addInfo = this.addInfo.bind(this);
        this.addWarning = this.addWarning.bind(this);
        this.addGenericAlert = this.addGenericAlert.bind(this);
    }

    private static instance: AlertService | undefined;

    public static getInstance(history: ErrorHistory): AlertService {
        if (!AlertService.instance) {
            AlertService.instance = new AlertService(history);
        }

        return AlertService.instance;
    }

    private fireSubscribers(alert) {
        Object.values(this.listeners).forEach((listener) => {
            listener(alert);
        });
    }

    subscribe(name, listener) {
        if (typeof listener !== 'function') {
            // eslint-disable-next-line no-console
            console.error('Alert listeners must be functions!');

            return;
        }
        if (this.listeners[name]) {
            // eslint-disable-next-line no-console
            console.error(
                `Listener ${name} in alerts service is already subscribed to.`,
            );

            return;
        }
        this.listeners[name] = listener;
    }

    generateAlert(status, customAlerts = {}, variant = 'warning') {
        const alertsTable = {
            ...BASE_ALERT_HANDLERS,
            ...customAlerts,
        };

        if (alertsTable[status]) {
            return alertsTable[status];
        }

        const genericStatus = Math.floor(status / 100) * 100;

        if (status && alertsTable[genericStatus]) {
            return alertsTable[genericStatus];
        }

        return {
            message: `app:alerts.unexpected${upperFirst(variant)}`,
            options: {
                variant,
            },
        };
    }

    unsubscribe(name) {
        this.listeners.delete(name);
    }

    addAlert(alert = {}) {
        this.fireSubscribers(alert);
    }

    addError(data = {}) {
        const options = data.options || {};

        this.history.addError(data);
        this.addAlert({
            ...data,
            options: { ...options, variant: ALERTS_VARIANTS.ERROR },
        });
    }

    addSuccess(data) {
        const options = data.options || {};

        this.addAlert({
            ...data,
            options: { ...options, variant: ALERTS_VARIANTS.SUCCESS },
        });
    }

    addInfo(data) {
        const options = data.options || {};

        this.addAlert({
            ...data,
            options: { ...options, variant: ALERTS_VARIANTS.INFO },
        });
    }

    addWarning(data) {
        const options = data.options || {};

        this.addAlert({
            ...data,
            options: { ...options, variant: ALERTS_VARIANTS.WARNING },
        });
    }

    addGenericAlert(response = {}) {
        let status = response.status || 'UNKNOWN';

        if (response?.message === 'Network Error') {
            status = 'NETWORK_ERROR';
        }

        const alert = this.generateAlert(status);

        this.addAlert(alert);
    }
}
