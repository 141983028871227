import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function SortIcon(props) {
    return (
        <SvgIcon
            viewBox="-4 -3 16 16"
            { ...props }
        >
            <path
                d="M3.99999 0.199951C3.89769 0.199951 3.79538 0.238939 3.71718 0.317139L0.517179 3.51714C0.402779 3.63154 0.36846 3.80348 0.43046 3.95308C0.49206 4.10268 0.638391 4.19995 0.799991 4.19995H7.19999C7.36159 4.19995 7.50792 4.10268 7.56952 3.95308C7.63152 3.80348 7.5972 3.63154 7.4828 3.51714L4.2828 0.317139C4.2046 0.238939 4.10229 0.199951 3.99999 0.199951ZM0.799991 5.79995C0.638391 5.79995 0.49206 5.89723 0.43046 6.04683C0.36846 6.19643 0.402779 6.36836 0.517179 6.48276L3.71718 9.68276C3.79518 9.76076 3.89759 9.79995 3.99999 9.79995C4.10239 9.79995 4.2048 9.76076 4.2828 9.68276L7.4828 6.48276C7.5972 6.36836 7.63152 6.19643 7.56952 6.04683C7.50792 5.89723 7.36159 5.79995 7.19999 5.79995H0.799991Z"
            />
        </SvgIcon>
    );
}

SortIcon.defaultProps = {
    titleAccess: 'sort-icon',
};

SortIcon.propTypes = svgIconPropTypes;
