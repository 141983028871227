import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function AppsIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -2 16 16"
            { ...props }
        >
            <path
                d="M6 4.66667C5.26333 4.66667 4.66667 5.26333 4.66667 6C4.66667 6.73667 5.26333 7.33333 6 7.33333C6.73667 7.33333 7.33333 6.73667 7.33333 6C7.33333 5.26333 6.73667 4.66667 6 4.66667ZM6 0C5.26333 0 4.66667 0.596667 4.66667 1.33333C4.66667 2.07 5.26333 2.66667 6 2.66667C6.73667 2.66667 7.33333 2.07 7.33333 1.33333C7.33333 0.596667 6.73667 0 6 0ZM6 9.33333C5.26333 9.33333 4.66667 9.93 4.66667 10.6667C4.66667 11.4033 5.26333 12 6 12C6.73667 12 7.33333 11.4033 7.33333 10.6667C7.33333 9.93 6.73667 9.33333 6 9.33333ZM10.6667 4.66667C9.93 4.66667 9.33333 5.26333 9.33333 6C9.33333 6.73667 9.93 7.33333 10.6667 7.33333C11.4033 7.33333 12 6.73667 12 6C12 5.26333 11.4033 4.66667 10.6667 4.66667ZM10.6667 0C9.93 0 9.33333 0.596667 9.33333 1.33333C9.33333 2.07 9.93 2.66667 10.6667 2.66667C11.4033 2.66667 12 2.07 12 1.33333C12 0.596667 11.4033 0 10.6667 0ZM10.6667 9.33333C9.93 9.33333 9.33333 9.93 9.33333 10.6667C9.33333 11.4033 9.93 12 10.6667 12C11.4033 12 12 11.4033 12 10.6667C12 9.93 11.4033 9.33333 10.6667 9.33333ZM1.33333 4.66667C0.596667 4.66667 0 5.26333 0 6C0 6.73667 0.596667 7.33333 1.33333 7.33333C2.07 7.33333 2.66667 6.73667 2.66667 6C2.66667 5.26333 2.07 4.66667 1.33333 4.66667ZM1.33333 0C0.596667 0 0 0.596667 0 1.33333C0 2.07 0.596667 2.66667 1.33333 2.66667C2.07 2.66667 2.66667 2.07 2.66667 1.33333C2.66667 0.596667 2.07 0 1.33333 0ZM1.33333 9.33333C0.596667 9.33333 0 9.93 0 10.6667C0 11.4033 0.596667 12 1.33333 12C2.07 12 2.66667 11.4033 2.66667 10.6667C2.66667 9.93 2.07 9.33333 1.33333 9.33333Z"
            />
        </SvgIcon>
    );
}

AppsIcon.defaultProps = {
    titleAccess: 'apps-icon',
};

AppsIcon.propTypes = svgIconPropTypes;
