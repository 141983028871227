import { forwardRef, type ComponentPropsWithoutRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { Loader } from '../Loader/Loader';

interface IIconButtonProps extends ComponentPropsWithoutRef<typeof IconButton> {
    loading?: boolean | undefined;
}

const IconButtonComponent = forwardRef<HTMLButtonElement, IIconButtonProps>(
    (props, ref) => {
        const { loading, children, onClick, ...other } = props;

        return (
            <IconButton
                onClick={loading ? null : onClick}
                ref={ref}
                {...other}
            >
                {loading
                    ? [
                          <Loader
                              simple
                              aria-label="buttonLoader"
                              key="loader"
                              size={24}
                          />,
                          children,
                      ]
                    : children}
            </IconButton>
        );
    },
);

export default IconButtonComponent;
