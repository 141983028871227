import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CheckSIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -3 16 16"
            { ...props }
        >
            <path
                d="M13.3896 0.194789C13.251 0.198918 13.1195 0.256827 13.0229 0.356247L4.86668 8.5125L1.51043 5.15625C1.46128 5.10506 1.40242 5.0642 1.33729 5.03604C1.27215 5.00789 1.20205 4.99302 1.1311 4.9923C1.06014 4.99158 0.989758 5.00502 0.924063 5.03184C0.858368 5.05866 0.798686 5.09832 0.748511 5.1485C0.698335 5.19867 0.658676 5.25835 0.631855 5.32405C0.605034 5.38974 0.591591 5.46013 0.592313 5.53108C0.593035 5.60204 0.607907 5.67214 0.636059 5.73727C0.664211 5.80241 0.705077 5.86127 0.756262 5.91041L4.4896 9.64375C4.58962 9.74373 4.72525 9.79989 4.86668 9.79989C5.0081 9.79989 5.14374 9.74373 5.24376 9.64375L13.7771 1.11041C13.8542 1.0355 13.9068 0.939052 13.9281 0.833708C13.9494 0.728364 13.9384 0.61904 13.8965 0.520061C13.8546 0.421082 13.7838 0.337066 13.6934 0.279024C13.6029 0.220982 13.497 0.191621 13.3896 0.194789Z"
            />
        </SvgIcon>
    );
}

CheckSIcon.defaultProps = {
    titleAccess: 'check-s-icon',
};

CheckSIcon.propTypes = svgIconPropTypes;
