import qs from 'qs';
import { MODULE_NAME } from '../constants';

export const CONTRACTORS = `/${MODULE_NAME}/contractors`;
export const CONTRACTORS_DETAILS = `/${MODULE_NAME}/contractors/:id`;
export const ORDER_IMPORTS = `/${MODULE_NAME}/order-imports`;
export const ORDER_IMPORTS_DETAILS = `/${MODULE_NAME}/order-imports/:id`;
export const INVOICES = `/${MODULE_NAME}/invoices`;
export const PATRONS = `/${MODULE_NAME}/patrons`;

export const ORDER_IMPORTS_WITH_SORT = {
    pathname: ORDER_IMPORTS,
    search: qs.stringify({
        sort: [{ field: 'created_at', sort: 'desc' }],
        page: 1
    }),
};
