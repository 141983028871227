import i18next from 'i18next';
import { handleForbiddenError } from 'utils/errors';

const baseError =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { status } = response;

        handleForbiddenError(error, response, alertService);

        alertService.addError({
            message: i18next.t('common:errors.unknown', { status }),
        });
        throw error;
    };

const errorHandlers = (alertService) => ({
    post: baseError(alertService),
    patch: baseError(alertService),
    put: baseError(alertService),
    get: baseError(alertService),
    delete: baseError(alertService),
});

export default errorHandlers;
