import type { AjaxService } from 'services/ajax/AjaxService';
import type { ProdisService } from 'services/prodis/prodisService';
import {
    ICustomShipmentCarrier,
    ICustomShipmentCarrierParameters,
    ICustomShipmentCarrierResponse,
} from './customShipmentCarrier.type';
import { URLS } from 'services/prodis/constants';
import { ApplicationJsonHeaders } from 'services/constants';
import { paramsSerializer } from 'services/utils/utils';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';

export class CustomShipmentCarrierActions {
    private static instance: CustomShipmentCarrierActions | undefined;

    private readonly asyncRequestPaginator: AsyncRequestPaginator<
        ICustomShipmentCarrier,
        ICustomShipmentCarrier
    >;

    private constructor(
        private readonly prodisService: ProdisService,
        private readonly ajaxService: AjaxService,
    ) {
        this._getCustomShipmentCarrierPage =
            this._getCustomShipmentCarrierPage.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            (params) => this._getCustomShipmentCarrierPage(params),
            (data) => data,
        );

        this.getFullList = this.getFullList.bind(this);
    }

    public static getInstance(
        prodisService: ProdisService,
        ajaxService: AjaxService,
    ): CustomShipmentCarrierActions {
        if (!CustomShipmentCarrierActions.instance) {
            CustomShipmentCarrierActions.instance =
                new CustomShipmentCarrierActions(prodisService, ajaxService);
        }

        return CustomShipmentCarrierActions.instance;
    }

    _getCustomShipmentCarrierPage(
        params: Partial<ICustomShipmentCarrierParameters>,
    ): Promise<{ data: ICustomShipmentCarrierResponse }> {
        const { errorHandlers } = this.prodisService;

        return this.ajaxService.get({
            url: URLS.GET_CUSTOM_SHIPMENT_CARRIERS,
            config: {
                headers: ApplicationJsonHeaders,
                params,
                paramsSerializer,
            },
            errorConfig: { onError: errorHandlers.get },
            onError: errorHandlers.get,
        });
    }

    public async getFullList() {
        const { items } = await this.asyncRequestPaginator.fetchFullList({});

        return items;
    }
}
