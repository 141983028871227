import { ContentTypeJsonHeader } from '../constants';

export class SalesService {
    private constructor(
        ajaxService,
        requestCache,
        URLS,
        ORDER_REIMPORT_STATUS,
        ALERTS_MESSAGES,
        errorHandlers,
    ) {
        this.ajaxService = ajaxService;
        this.order = {};
        this.orderLines = {};
        this.packages = {};
        this.URLS = URLS;
        this.errorHandlers = errorHandlers;
        this.ORDER_REIMPORT_STATUS = ORDER_REIMPORT_STATUS;
        this.ALERTS_MESSAGES = ALERTS_MESSAGES;

        this.getOrder = this.getOrder.bind(this);
        this.getOrderLines = this.getOrderLines.bind(this);
        this.getPackages = this.getPackages.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
    }

    private static instance: SalesService | undefined;

    public static getInstance(
        ajaxService,
        requestCache,
        URLS,
        ORDER_REIMPORT_STATUS,
        ALERTS_MESSAGES,
        errorHandlers,
    ): SalesService {
        if (!SalesService.instance) {
            SalesService.instance = new SalesService(
                ajaxService,
                requestCache,
                URLS,
                ORDER_REIMPORT_STATUS,
                ALERTS_MESSAGES,
                errorHandlers,
            );
        }

        return SalesService.instance;
    }

    getOrder(orderId = '') {
        const order = this.order[orderId];
        const url = this.URLS.GET_ORDER.replace('{id}', orderId);

        if (!order) {
            return this.ajaxService.get({ url }).then((response) => {
                this.order[orderId] = response.data;

                return response.data;
            });
        }

        return new Promise(function (resolve) {
            resolve(order);
        });
    }

    getOrderLines(orderId = '') {
        const orderLines = this.orderLines[orderId];
        const url = this.URLS.GET_ORDER_LINES.replace('{id}', orderId);

        if (!orderLines) {
            return this.ajaxService.get({ url }).then((response) => {
                this.orderLines[orderId] = response.data['hydra:member'];

                return response.data['hydra:member'];
            });
        }

        return new Promise(function (resolve) {
            resolve(orderLines);
        });
    }

    getPackages(packageId = '') {
        const packages = this.packages[packageId];
        const url = this.URLS.GET_PACKAGES.replace('{id}', packageId);

        if (!packages) {
            return this.ajaxService.get({ url }).then((response) => {
                this.packages[packageId] = response.data;

                return response.data;
            });
        }

        return new Promise(function (resolve) {
            resolve(packages);
        });
    }

    reimport(orderId = '', company = 'artgeist') {
        const url =
            company.toLowerCase() === 'decomonkey'
                ? this.URLS.GET_ORDER_FOR_REIMPORT_DECOMONKEY.replace(
                      '{id}',
                      orderId,
                  )
                : this.URLS.GET_ORDER_FOR_REIMPORT.replace('{id}', orderId);

        const data = this.ORDER_REIMPORT_STATUS;
        const config = {
            headers: ContentTypeJsonHeader,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.patch,
        };

        return this.ajaxService
            .patch({ url, config, data, errorConfig })
            .then((response) => {
                const status = response.status;

                this.ajaxService.alertService.addSuccess({
                    message: this.ajaxService.translator.t(
                        this.ALERTS_MESSAGES.REIMPORT_PASSED,
                    ),
                });

                return { status };
            })
            .catch(() => {
                return {
                    status: '',
                };
            });
    }

    downloadInvoice(invoiceId) {
        const url = this.URLS.DOWNLOAD_INVOICE.replace('{id}', invoiceId);
        const config = {
            headers: {
                Accept: 'application/pdf',
            },
        };

        return this.ajaxService.download({ url, config });
    }
}
