import useMediaQuery from '@mui/material/useMediaQuery';

const BREAKPOINTS = {
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
} as const;

const createMediaQuery = (pxCount: number) =>
    `@media screen and (min-width: ${pxCount}px)`;

export const useWidth = () => {
    const smMatches = useMediaQuery(createMediaQuery(BREAKPOINTS.sm));
    const mdMatches = useMediaQuery(createMediaQuery(BREAKPOINTS.md));
    const lgMatches = useMediaQuery(createMediaQuery(BREAKPOINTS.lg));
    const xlMatches = useMediaQuery(createMediaQuery(BREAKPOINTS.xl));

    if (xlMatches) return 'xl';
    if (lgMatches) return 'lg';
    if (mdMatches) return 'md';
    if (smMatches) return 'sm';

    return 'xs';
};
