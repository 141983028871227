import { darken } from '@mui/material/styles';
import styled from '@emotion/styled';

export const AppRootStyled = styled('div')`
  display: flex;
  position: fixed;
  inset: 0;
`;
AppRootStyled.displayName = 'AppRoot';

export const AppLoaderStyled = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
AppLoaderStyled.displayName = 'AppLoader';

export const AppContentStyled = styled('main')`
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;
AppContentStyled.displayName = 'AppContent';

export const AppBarSpacerStyled = styled('div')`
  ${({ theme }) => theme.mixins.toolbar};
`;
AppBarSpacerStyled.displayName = 'AppBarSpacer';

export const AppPageStyled = styled('div')`
  overflow: auto;
  background: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(6)}`};
  flex-grow: 1;
  position: relative;
`;
AppPageStyled.displayName = 'Page';

export const scrollbarStyles = (theme) => ({
    '::-webkit-scrollbar': {
        width: '10px'
    },

    '::-webkit-scrollbar-track': {
        background: theme.palette.common.gray.midLight
    },

    '::-webkit-scrollbar-thumb': {
        background: theme.palette.common.gray.mid
    },

    '::-webkit-scrollbar-thumb:hover': {
        background: darken(theme.palette.common.gray.mid, 0.2)
    }
});
