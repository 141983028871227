export const mapGroupFromApi = (data = {}) => {
    return ({
        '@id': data['@id'],
        id: data.id,
        department: data.department,
        name: data.name || '',
        position: data.position || '',
        groupRoles: data.groupRoles || []
    });
};
