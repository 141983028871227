import styled from '@emotion/styled';
import Typography from '../Typography';

export const ItemDetailsStyled = styled('div')`
  display: flex;
  flex-direction: column;
`;
ItemDetailsStyled.displayName = 'ItemDetailsStyled';

export const ActionsStyled = styled('div')`
  display: flex;
  flex-direction: row-reverse;
`;
ActionsStyled.displayName = 'Actions';

export const TitleStyled = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
TitleStyled.displayName = 'Title';

export const TypographyStyled = styled(Typography)`
  display: flex;
  align-items: center;
`;
TypographyStyled.displayName = 'Typography';

export const SubtitleStyled = styled('span')`
  margin-left: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.grey[400]};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
SubtitleStyled.displayName = 'Subtitle';

export const BodyStyled = styled('div')`
  display: flex;
  min-height: 0;
  flex: 1;
`;
BodyStyled.displayName = 'Body';

export const InsideStyled = styled('div')`
  flex: 1;
  display: flex;
  width: 100%;
`;
InsideStyled.displayName = 'Inside';

