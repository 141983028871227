import get from 'lodash/get';

export const mapUserToApi = (data = {}) => {
    const {
        roles,
        email,
        enabled,
        firstName,
        lastName,
        plainPassword,
        groups,
        username,
        positions,
    } = data;

    const formattedGroups = groups && groups.map((groups) => `/api/groups/${groups.id}`);
    const formattedRoles = roles && roles.map((role) => {
        return {
            isRevoked: role.isRevoked,
            role: role.role['@id'],
        };
    });
    const formattedPositions =
        positions &&
        positions.map(
            (position) => `/api/positions/${position?.id ?? position}`,
        );

    return {
        firstName: firstName || '',
        lastName: lastName || '',
        username: username || '',
        email: email || '',
        plainPassword: plainPassword || '',
        enabled: enabled || false,
        groups: formattedGroups || [],
        userRoles: formattedRoles || [],
        positions: formattedPositions || [],
    };
};

const mapRoleFromApi = (role) => ({
    isRevoked: role.isRevoked,
    role: {
        '@id': `/api/roles/${role.role?.id}`,
        id: role.role?.id,
        name: role.role?.name,
    },
});

export const mapUserFromApi = (data = {}) => {
    const positionTitles = data.positionsTitles && data.positionsTitles.length > 1
        ? data.positionsTitles.join(', ')
        : data.positionsTitles[0];

    const firstName = get(data, 'firstName', '');
    const lastName = get(data, 'lastName', '');
    const fullName = (firstName && lastName) ?
        `${firstName} ${lastName}`
        :
        firstName ? firstName : lastName ? lastName : '';

    return ({
        id: get(data, 'id', ''),
        userId: get(data, 'userId', ''),
        firstName,
        lastName,
        fullName,
        email: get(data, 'email', ''),
        username: get(data, 'username', ''),
        enabled: get(data, 'enabled', ''),
        department: get(data, 'groups[0].department', ''),
        positionTitles: positionTitles || '',
        positions: get(data, 'positions', []),
        roles: get(data, 'individualUserRoles', []).map(mapRoleFromApi),
        inheritedRoles: get(data, 'inheritedUserRoles', []).map(mapRoleFromApi),
        groups: get(data, 'groups', []),
    });
};
