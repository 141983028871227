import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Button from 'components/Button/button';
import React from 'react';
import { FetchAutocomplete } from 'components/AutocompleteNew/FetchAutocomplete';
import Modal from 'components/Modal/modal';
import Form from 'components/Form/Form';
import { useTranslation } from 'react-i18next';
import { createSchema, createDefaultValue } from './workplaceModalForm.config';
import { mapGetWorkplacesToAutocomplete } from '../../../../utils/workplaceMappers/workplaceMappers.util';

export const WorkplaceModal = ({
    active,
    handleSaveWorkplace,
    getWorkplaces,
    defaultWorkplace,
}) => {
    const { t } = useTranslation(['workplaces', 'common']);

    return (
        <Modal active={active}>
            <Box minWidth="360px">
                <Box mb={1}>
                    <Typography
                        variant="h3"
                        component="h6"
                    >
                        {t('titles.selectWorkplace', { ns: 'workplaces' })}
                    </Typography>
                </Box>
                <Form
                    t={t}
                    id="WorkplaceModalForm"
                    validationSchema={createSchema(t)}
                    initialValues={createDefaultValue(defaultWorkplace)}
                    enableSubmitModal={false}
                    onSubmit={handleSaveWorkplace}
                >
                    {({
                        errors,
                        handleBlur,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <>
                            <Box mb={2}>
                                <FetchAutocomplete
                                    cacheKey="workplaces"
                                    id="workplace"
                                    handleBlur={handleBlur}
                                    error={
                                        touched?.workplace && errors?.workplace
                                            ? errors.workplace
                                            : ''
                                    }
                                    value={values.workplace}
                                    handleChange={(key, value) => {
                                        setFieldValue(key, value);
                                    }}
                                    fetchOptions={async () =>
                                        mapGetWorkplacesToAutocomplete(
                                            getWorkplaces,
                                        )
                                    }
                                    maxLength={100}
                                />
                            </Box>
                            <Box
                                mb={2}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Button
                                    color="secondary"
                                    type="submit"
                                >
                                    {t('actions.save', { ns: 'common' })}
                                </Button>
                            </Box>
                        </>
                    )}
                </Form>
            </Box>
        </Modal>
    );
};

WorkplaceModal.propTypes = {
    active: PropTypes.bool.isRequired,
    handleSaveWorkplace: PropTypes.func.isRequired,
    getWorkplaces: PropTypes.func.isRequired,
    defaultWorkplace: PropTypes.string,
};
