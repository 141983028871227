import get from 'lodash/get';

const PRODUCTION_ORDERS_URL = get(window, 'env.PRODUCTION_ORDERS_URL', '');

const PRODUCTION_ORDERS_API_V1 = `${PRODUCTION_ORDERS_URL}/api/v1`;
const PRODUCTION_ORDERS_API_V3 = `${PRODUCTION_ORDERS_URL}/api/v3`;
const PRODUCTION_ORDERS_API_WORKERS = `${PRODUCTION_ORDERS_URL}/api/workers/v1`;

export const URLS = {
    GET_PRODUCTION_ORDERS: `${PRODUCTION_ORDERS_API_V1}/production_orders`,
    GET_PRODUCTION_ORDERS_WORKERS: `${PRODUCTION_ORDERS_API_WORKERS}/production_orders`,
    GET_PRODUCTION_ORDER: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}`,
    GET_V3_PRODUCTION_ORDERS: `${PRODUCTION_ORDERS_API_V3}/production_orders`,
    GET_V3_PRODUCTION_ORDER: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}`,
    GET_PRODUCTION_ORDER_STATUS: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/status`,
    GET_PRODUCTION_ORDER_AWE_TRANSITIONS: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}/transitions`,
    GET_PRODUCTION_ORDER_HISTORY: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/history`,
    GET_PRODUCTION_ORDER_ITEM: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/item`,
    GET_PRODUCTION_ORDER_PROPERTIES: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}/properties`,
    POST_PRODUCTION_ORDER_TRANSITION_SUBMIT: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/transitions/{actionId}/submit`,
    POST_PRODUCTION_ORDER_AWE_TRANSITION_APPLY: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}/transition_apply/{transitionName}`,
    PRODUCTION_ORDERS_NOTES: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}/notes`,
    PRODUCTION_ORDERS_STABLO_NOTE: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/notes`,
    PUT_BLOCK_SHIPPING: `${PRODUCTION_ORDERS_API_V1}/production_orders/{id}/flags/block_shipping`,
    GET_PRODUCTION_ORDER_DOCUMENTS: `${PRODUCTION_ORDERS_API_V3}/production_orders/{id}/documents`,
    GET_ORDER_DOCUMENTS: `${PRODUCTION_ORDERS_API_V3}/orders/{id}/documents`,
    GET_SHIPMENT_DOCUMENTS: `${PRODUCTION_ORDERS_API_V3}/shipments/{id}/documents`,
    GET_PRODUCTION_ORDERS_PRINT: `${PRODUCTION_ORDERS_API_WORKERS}/production_orders_print`,
};

export const DEFAULTS = {
    ORDERS_PER_PAGE: 20,
};

export const APP_ALERTS = {
    TRANSITION_SUBMIT_SUCCESS: 'production:alerts.transitionSubmitSuccess',
    MULTIPACKAGE_TRANSITION_SUCCESS: 'production:alerts.multipackageComplete',
    MULTIPACKAGE_TRANSITION_FAILURE: 'production:alerts.multipackageFailed',
    ADD_NOTE_SUCCESS: 'production:alerts.addNoteSuccess',
    TRANSITION_HOLD_SHIPPING_SUCCESS:
        'production:alerts.transitionHoldShippingSuccess',
    TRANSITION_HOLD_SHIPPING_ERROR: 'app:alerts.unknown',
};

export const TRANSITION_TYPES = {
    STANDARD: 'standard',
    AWE: 'awe',
};

export const TRANSITION_COLORS = [
    {
        code: "cancel-product",
        color: "#DC828F"
    },
    {
        code: "remake-product",
        color: "#E5DB9C"
    },
    {
        code: "back-to-print",
        color: "#BEB4C5"
    },
    {
        code: "internal-complaint",
        color: "#BEB4C5"
    },
    {
        code: "inner-complaint",
        color: "#BEB4C5"
    },
    {
        code: "walk-to-received-from-production",
        color: "#A1CDCE"
    },
    {
        code: "pack-accessories",
        color: "#E6A57E"
    },
    {
        code: "add-accessories",
        color: "#E6A57E"
    },
    {
        code: "acc-pack",
        color: "#E6A57E"
    }
]