import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function DownloadIcon(props) {
    return (
        <SvgIcon
            viewBox="-4 -2 16 16"
            { ...props }
        >
            <path
                d="M4 0V8.28125L1.35938 5.64062L0.640625 6.35938L4.14062 9.85938L4.5 10.2031L4.85938 9.85938L8.35938 6.35938L7.64062 5.64062L5 8.28125V0H4ZM0 11V12H9V11H0Z"
            />
        </SvgIcon>
    );
}

DownloadIcon.defaultProps = {
    titleAccess: 'download-icon',
};

DownloadIcon.propTypes = svgIconPropTypes;
