import type { IPagedResponse } from '../../../types/services.type';
import type { AjaxService } from '../../ajax/AjaxService';
import type { AlertService } from '../../alert/AlertService';
import { ApplicationJsonHeaders } from '../../constants';
import type { DiscountsService } from '../DiscountsService';
import type { URLS as URLS_CONST } from '../constants';
import { mapDiscountFromApi } from '../mappers/discount';
import type {
    IPostCreateProductSetDiscountsParams,
    IProductSetDiscountGetListParams,
    IProductSetDiscountGetListResults,
} from './productSetDiscount.type';
import parser from 'qs';

export class ProductSetDiscount {
    constructor(
        private readonly salesChannelsService: DiscountsService,
        private readonly services: {
            ajax: AjaxService;
            alert: AlertService;
        },
        private readonly URLS: typeof URLS_CONST,
    ) {
        this.getList = this.getList.bind(this);
        this.postCreateProductSetDiscounts =
            this.postCreateProductSetDiscounts.bind(this);
        this.patchProductSetDiscountsDeactivate =
            this.patchProductSetDiscountsDeactivate.bind(this);
    }

    private paramsSerializer = (params) => parser.stringify(params);

    async getList({
        filters = {},
        sort,
        per_page = 20,
        page = 1,
    }: IProductSetDiscountGetListParams): Promise<
        IPagedResponse<IProductSetDiscountGetListResults>
    > {
        const {
            services: { ajax },
            URLS,
            paramsSerializer,
        } = this;
        const errorConfig = {
            addGenericAlert: false,
        };
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                'contractor.id': filters?.contractorId,
                status: filters?.status,
            },
            paramsSerializer,
        };

        const {
            data: { results, total, ...data },
        } = await ajax.get({
            url: URLS.getProductSetDiscounts,
            config,
            errorConfig,
        });

        return {
            ...data,
            items: results.map(mapDiscountFromApi),
            itemsTotal: total,
        };
    }

    async postCreateProductSetDiscounts(
        params: IPostCreateProductSetDiscountsParams,
    ): Promise<void> {
        const {
            services: { ajax },
            URLS,
        } = this;

        await ajax.post({
            url: URLS.postCreateProductSetDiscounts,
            data: params,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                addGenericAlert: false,
            },
        });
    }

    async patchProductSetDiscountsDeactivate(id: string): Promise<void> {
        const {
            services: { ajax },
            URLS,
        } = this;

        await ajax.patch({
            url: URLS.patchProductSetDiscountsDeactivate.replace('{id}', id),
            data: undefined,
            config: {
                headers: ApplicationJsonHeaders,
            },
        });
    }
}
