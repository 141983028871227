import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { withTranslation } from 'react-i18next';
import { removeActiveToken, removeRefreshToken } from 'utils/tokenHelpers';
import EntryPageTitle from 'modules/app/components/EntryPageTitle/EntryPageTitle';

export class ConfirmRequestSend extends React.PureComponent {
    componentDidMount() {
        removeActiveToken();
        removeRefreshToken();
    }

    render() {
        const { t, location } = this.props;
        const { login } = qs.parse(location.search.slice(1));

        return (
            <EntryPageTitle
                title={t('titles.emailSend')}
                subtitle={t('contents.emailSend', { login })}
            />
        );
    }
}

ConfirmRequestSend.defaultProps = {
    t: (key) => key,
};

ConfirmRequestSend.propTypes = {
    location: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const ConfirmRequestSendWithHOC = withTranslation('app')(ConfirmRequestSend);

export default ConfirmRequestSendWithHOC;
