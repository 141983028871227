import { get } from 'lodash';
import {
    mapCustomFieldValueFromApi,
    mapCustomFieldValueToApi,
    mapCustomFieldValueEditToApi,
} from '../mappers/customFieldValue';
import { configError } from '../../utils/utils';
import type { SalesChannelsService } from '../SalesChannelsService';

class CustomFieldValue {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.getList = this.getList.bind(this);
        this.deleteById = this.deleteById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }
    async getList(
        id,
        params: {
            page?: number | undefined;
            per_page?: number | undefined;
            filters?: Record<string, unknown> | undefined;
        } = {},
    ) {
        const { ajax, URLS, DEFAULTS, paramsSerializer, HEADERS } =
            this.salesChannelsService;

        const { page = 1, per_page = DEFAULTS.PER_PAGE, filters } = params;

        const url = URLS.GET_CHANNEL_CUSTOM_FIELD_VALUES.replace('{id}', id);

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
            },
            headers: HEADERS['basic'],
            paramsSerializer,
        };

        if (filters['type']) config.params['type'] = filters['type'];
        if (filters['customFieldDefinition'])
            config.params['custom_field_definition_id'] =
                filters['customFieldDefinition'];
        if (filters['language'])
            config.params['language'] = filters['language'];

        const { data } = await ajax.get({ url, config });

        return {
            items: get(data, 'results', []).map(mapCustomFieldValueFromApi),
            totalItems: get(data, 'total', 0),
        };
    }
    async deleteById(id) {
        const {
            ajax,
            URLS,
            translator,
            ALERT_MESSAGES,
            errorHandlers,
            alert: alertService,
        } = this.salesChannelsService;
        const url = URLS.DELETE_CUSTOM_FIELD_VALUE.replace('{id}', id);
        const errorConfig = configError('delete', errorHandlers);

        await ajax.delete({ url, errorConfig });
        alertService.addSuccess({
            message: translator.t(ALERT_MESSAGES.CUSTOM_FIELD_VALUE_DELETED),
        });
    }
    async create(data) {
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;
        const url = URLS.POST_CUSTOM_FIELD_VALUE;
        const errorConfig = configError('post', errorHandlers);
        const config = {
            headers: HEADERS['basic'],
        };

        const { data: created } = await ajax.post({
            url,
            errorConfig,
            config,
            data: mapCustomFieldValueToApi(data),
        });

        return mapCustomFieldValueFromApi(created);
    }
    async edit(id, data) {
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;

        const url = URLS.PUT_CUSTOM_FIELD_VALUE.replace('{id}', id);
        const errorConfig = configError('put', errorHandlers);
        const config = {
            headers: HEADERS['basic'],
        };

        const { data: edited } = await ajax.put({
            url,
            errorConfig,
            config,
            data: mapCustomFieldValueEditToApi(data),
        });

        return mapCustomFieldValueFromApi(edited);
    }
}

export default CustomFieldValue;
