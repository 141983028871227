import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

export const ROLE_SIMP = {
    section: S.SALES,
    type: 'main',
    votes: {
        [A.SALES.MODULE]: ACCEPT,
    },
};

export const ROLE_SIMP_ORDERS_READ = {
    section: S.SALES_ORDERS,
    type: 'display',
    votes: {
        [A.SALES.PATHS.ORDERS]: ACCEPT,
    },
};

export const ROLE_SIMP_ORDERS_CREATE = {
    section: S.SALES_ORDERS,
    type: 'create',
    votes: {
        [A.SALES.PATHS.ORDERS]: ACCEPT,
        [A.SALES.PATHS.ORDER_DETAILS]: ACCEPT,
        [A.SALES.RESOURCES.ORDER.CREATE]: ACCEPT,
    },
};

export const ROLE_SIMP_ORDERS_UPDATE = {
    section: S.SALES_ORDERS,
    type: 'edit',
    votes: {
        [A.SALES.PATHS.ORDERS]: ACCEPT,
        [A.SALES.PATHS.ORDER_DETAILS]: ACCEPT,
        [A.SALES.RESOURCES.ORDER.EDIT]: ACCEPT,
    },
};

export const ROLE_SIMP_ORDERS_DELETE = {
    section: S.SALES_ORDERS,
    type: 'delete',
    votes: {
        [A.SALES.PATHS.ORDERS]: ACCEPT,
    },
};

export const ROLE_SIMP_ORDER_NOTE_UPDATE = {
    section: S.SALES_ORDERS,
    type: 'edit',
    votes: {
        [A.SALES.RESOURCES.ORDER_NOTES.EDIT]: ACCEPT,
    },
};

export const ROLE_NOTIFICATION_CENTER_READ_MESSAGE = {
    section: S.SALES_ORDERS,
    type: 'display',
    votes: {
        [A.SALES.RESOURCES.MESSAGE.DISPLAY]: ACCEPT,
    }
};

export const ROLE_SIMP_ORDERS_USE_ARTE_OFFER_MANAGE = {
    section: S.SALES_ORDERS,
    type: 'edit',
    votes: {
        [A.SALES.RESOURCES.ORDER.SWITCH_ORDER]: ACCEPT,
    }
};
