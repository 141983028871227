export const mapChannelFromApi = (data: any = {}) => {
    const { name = '', id = '', source = '', label } = data;

    return {
        id,
        name,
        source,
        label,
    };
};
