import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function FileIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            { ...props }
        >
            <path
                d="M8.87517 1.34636C9.21892 1.69011 9.43357 2.16294 9.43357 2.6853L9.42756 10.3167C9.43056 10.3407 9.43056 10.3647 9.42756 10.3887C9.42831 10.397 9.42831 10.4045 9.42756 10.4127C9.42906 10.4292 9.42906 10.4442 9.42756 10.4608C9.42531 10.463 9.42381 10.4645 9.42156 10.4668C9.38028 11.176 8.78961 11.7457 8.07061 11.7457C7.32459 11.7457 6.71366 11.1347 6.71366 10.3887L6.71966 4.58263C6.71741 4.47081 6.78496 4.36874 6.88853 4.32671C6.99135 4.28393 7.11069 4.31019 7.18799 4.3905C7.23602 4.44304 7.26229 4.51134 7.26004 4.58264L7.26004 10.3827C7.26004 10.8345 7.6248 11.1993 8.07661 11.1993C8.51342 11.1993 8.85866 10.8615 8.88118 10.4307C8.88193 10.4165 8.88418 10.4022 8.88718 10.3887C8.88643 10.3805 8.88643 10.3729 8.88718 10.3647L8.88118 10.3347C8.88343 10.3324 8.88493 10.3309 8.88718 10.3287L8.88118 2.6853C8.88118 1.93478 8.28226 1.33436 7.53023 1.33436C6.7797 1.33436 6.17928 1.93478 6.17928 2.6853L6.17928 12.1479C6.17928 13.1987 7.02588 14.0453 8.07661 14.0453C9.12735 14.0453 9.97395 13.1987 9.97394 12.1479L9.97394 4.58264C9.96344 4.47906 10.0115 4.37849 10.1 4.32295C10.1878 4.26816 10.3004 4.26816 10.3882 4.32295C10.4768 4.37849 10.5248 4.47906 10.5143 4.58264L10.5143 12.1479C10.5143 13.4914 9.4148 14.5924 8.07061 14.5917C6.72642 14.5909 5.6329 13.4974 5.6329 12.1539L5.6269 2.6853C5.6269 1.64057 6.4855 0.781969 7.53023 0.781969C8.0526 0.781969 8.53143 1.00262 8.87517 1.34636Z"
            />
        </SvgIcon>
    );
}

FileIcon.defaultProps = {
    titleAccess: 'file-icon',
};

FileIcon.propTypes = svgIconPropTypes;
