import parser from 'qs';
import { mapCarrierType } from '../mappers/carrierType';
import { ApplicationJsonHeaders } from '../../constants';

const defaultObj = {
    items: [],
    totalItems: 0,
};

class CarrierType {
    constructor(prodisService, services, URLS) {
        this.prodisService = prodisService;
        this.services = services;
        this.URLS = URLS;

        this.getList = this.getList.bind(this);
    }

    paramsSerializer = (params) => parser.stringify(params);

    async getList(params) {
        const {
            services: {
                ajax,
            },
            URLS: {
                GET_CARRIER_TYPES,
            },
            paramsSerializer,
        } = this;

        try {
            const data = await ajax.get({
                url: GET_CARRIER_TYPES,
                config: {
                    params,
                    paramsSerializer,
                    headers: ApplicationJsonHeaders,
                }
            }).then((response) => response.data);

            if (Array.isArray(data)) {
                return data.map(mapCarrierType);
            }

            return defaultObj;
        } catch {
            return defaultObj;
        }
    }
}

export default CarrierType;
