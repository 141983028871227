import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CardsIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            { ...props }
        >
            <path
                d="M4.59549 0.0884396C3.89659 0.150315 3.26659 0.621408 3.03878 1.32875L0.167213 10.2373C-0.136537 11.1795 0.386588 12.1977 1.32878 12.5014L1.32315 12.5L2.60003 12.9373V14.12C2.60003 15.11 3.41003 15.92 4.40003 15.92H14.12C15.11 15.92 15.92 15.11 15.92 14.12V4.76C15.92 3.86422 15.2563 3.11891 14.3971 2.98391L5.30424 0.16719C5.06799 0.0912519 4.82753 0.0673458 4.59549 0.0884396ZM4.65456 0.802815C4.79518 0.791565 4.94003 0.807033 5.08206 0.85344C5.08346 0.85344 5.08487 0.85344 5.08628 0.85344L11.8869 2.96H4.40003C3.8769 2.96 3.40862 3.18922 3.07815 3.54922L3.72362 1.54813C3.86143 1.12063 4.23549 0.839377 4.65456 0.802815ZM4.40003 3.68H14.12C14.165 3.68 14.2086 3.68844 14.2536 3.69266L14.3563 3.725C14.3914 3.73625 14.428 3.74188 14.466 3.74188C14.8935 3.88532 15.2 4.28188 15.2 4.76V14.12C15.2 14.7205 14.7205 15.2 14.12 15.2H4.40003C3.79956 15.2 3.32003 14.7205 3.32003 14.12V12.7798C3.3369 12.7166 3.3369 12.6519 3.32003 12.59V4.76C3.32003 4.15953 3.79956 3.68 4.40003 3.68ZM13.76 4.4C13.3621 4.4 13.04 4.72203 13.04 5.12C13.04 5.51797 13.3621 5.84 13.76 5.84C14.158 5.84 14.48 5.51797 14.48 5.12C14.48 4.72203 14.158 4.4 13.76 4.4ZM2.60003 5.03563V12.1766L1.55518 11.8194C1.55378 11.818 1.55096 11.818 1.54815 11.818C0.977213 11.6323 0.667838 11.0305 0.853463 10.4581L2.60003 5.03563Z"
            />
        </SvgIcon>
    );
}

CardsIcon.defaultProps = {
    titleAccess: 'cards-icon',
};

CardsIcon.propTypes = svgIconPropTypes;
