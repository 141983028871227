import i18next from 'i18next';
import { handleForbiddenError } from 'utils/errors';

const T_UNKNOWN = 'app:alerts.unknown';

const get =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        handleForbiddenError(error, response, alertService);

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: i18next.t(T_UNKNOWN),
        });
        throw error;
    };

const download =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (status === 404) {
            alertService.addError({
                message: i18next.t('common:errors:fileNotFound'),
            });
            throw error;
        }

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: i18next.t(T_UNKNOWN),
        });
        throw error;
    };

const post =
    (alertService) =>
    (error = {}) => {
        const { response = {} } = error;
        const { data = {} } = response;

        handleForbiddenError(error, response, alertService);

        if (data.violations && data.violations.length > 0) {
            data.violations.forEach(({ propertyPath, message, title }) => {
                const errorMessage = message || title;

                alertService.addError({
                    message: propertyPath
                        ? `${propertyPath}: ${errorMessage}`
                        : errorMessage,
                });
            });
            throw error;
        }

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
            throw error;
        }

        alertService.addError({
            message: i18next.t(T_UNKNOWN),
        });
        throw error;
    };

const errorHandlers = (alertService) => ({
    get: get(alertService),
    delete: get(alertService),
    download: download(alertService),
    post: post(alertService),
    patch: post(alertService),
    put: post(alertService),
});

export default errorHandlers;
