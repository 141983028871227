import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    getIsNavigationBarExtended,
    getIsMobileNavigationBarExtended,
} from 'modules/app/reducers/common/selectors';
import {
    toggleNavBar,
    toggleMobileNavBar,
} from 'modules/app/reducers/common/actions';
import { NavBar } from 'modules/app/components/navBar/NavBar';

const mapStateToProps = (state) => {
    return {
        extended: getIsNavigationBarExtended(state),
        mobileExtended: getIsMobileNavigationBarExtended(state),
        path: state.getIn(['router', 'location', 'pathname']),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavBar: () => dispatch(toggleNavBar()),
        toggleMobileNavBar: (data) => dispatch(toggleMobileNavBar(data)),
        moveTo: (data) => dispatch(push(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
