import { takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import {
    FETCH_PRODUCT_POSSIBILITIES,
    GENERATE_SKU_FOR_PRODUCT_POSSIBILITIES,
    GENERATE_ADDITIONAL_SKU_FOR_PRODUCT_POSSIBILITIES,
} from './constants';
import {
    fetchProductPossibilities,
    generateSkuForProductPossibilities,
    generateAdditionalSkuForProductPossibilities,
} from './actions';

const API = get(window, 'env.STABLO_API_URL', '');
const productPossibilitiesCollection = makeEndPoint(API + '/product_possibility_collections');
const productPossibilities = makeEndPoint(API + '/product_possibilities');

export default function* productPossibilitiesSaga() {
    yield takeEvery(FETCH_PRODUCT_POSSIBILITIES.request, getProductPossibilities);
    yield takeEvery(GENERATE_SKU_FOR_PRODUCT_POSSIBILITIES.request, postProductPossibilities);
    yield takeEvery(GENERATE_ADDITIONAL_SKU_FOR_PRODUCT_POSSIBILITIES.request, postAdditionalProductPossibilities);
}

function* getProductPossibilities(action) {
    yield productPossibilities.get({
        action: fetchProductPossibilities,
        params: action.payload.params,
        alerts: {
            failure: true,
        },
        attributes: {
            success: {
                query: action.payload.query,
            },
        },
    });
}

function* postProductPossibilities(action) {
    yield productPossibilitiesCollection.post({
        action: generateSkuForProductPossibilities,
        alerts: {
            failure: true,
        },
        data: {
            items: action.payload.items,
        },
    });
}

function* postAdditionalProductPossibilities(action) {
    yield productPossibilitiesCollection.post({
        action: generateAdditionalSkuForProductPossibilities,
        alerts: {
            failure: true,
        },
        data: {
            items: action.payload.items,
        },
    });
}
