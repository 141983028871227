import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ViewAgendaIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 16 16"
            { ...props }
        >
            <path
                d="M0.125 0.375V0.9375V11.625H15.875V0.375H0.125ZM1.25 1.5H14.75V5.4375H1.25V1.5ZM1.25 6.5625H14.75V10.5H1.25V6.5625Z"
            />
        </SvgIcon>
    );
}

ViewAgendaIcon.defaultProps = {
    titleAccess: 'view-agenda-icon',
};

ViewAgendaIcon.propTypes = svgIconPropTypes;
