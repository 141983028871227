import parser from 'qs';
import i18next from 'i18n';
import {
    ALERT_MESSAGES,
    COMPANIES_PARAMS,
    DEFAULTS,
    OFFER_ITEMS_PARAMS,
    OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS,
    OFFER_PRODUCTS_PARAMS,
    OFFERS_PARAMS,
    SALES_CHANNELS_PARAMS,
    URLS,
} from './constants';
import { SalesChannelsService } from './SalesChannelsService';
import errorHandlers from './errorHandlers';
import { ApplicationJsonHeaders, HttpPatchHeaders } from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const salesChannelsServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    SalesChannelsService.getInstance({
        services: {
            ajax: ajaxService,
            parser,
            alerts: alertService,
            translator: i18next,
        },
        constants: {
            URLS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
                patch: HttpPatchHeaders,
            },
            DEFAULTS,
            SALES_CHANNELS_PARAMS,
            COMPANIES_PARAMS,
            OFFERS_PARAMS,
            OFFER_ITEMS_PARAMS,
            OFFER_PRODUCTS_PARAMS,
            ALERT_MESSAGES,
            OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS,
        },
        errorHandlers: errorHandlers(alertService),
    });
