import get from 'lodash/get';
import { mapTechnologyGroupFromApi } from './technologyGroup';

const mapGroupComponentsFromApi = (components) => {
    return Object.values(components).map((component) => ({
        id: get(component, 'id'),
        personalName: get(component, 'name', ''),
        personalCode: get(component, 'code', ''),
        required: get(component, 'required'),
        technologyId: get(component, 'component.id'),
        technologyName: get(component, 'component.name'),
        technologyCode: get(component, 'component.code'),
        technologyDescription: get(component, 'component.description'),
    }));
};

const mapRequiredPropertiesFromApi = (requiredProperties) => {
    const properties = {};

    requiredProperties.map((property) => {
        const id = get(property, 'id');
        const name = get(property, 'name');
        const type = get(property, 'type');
        const code = get(property, 'code', '');
        const description = get(property, 'description');
        const propertyValue = {
            id,
            name,
            type,
            code,
            description,
        };

        if (type === 'number') {
            propertyValue.unit = get(property, 'options.unit', '');
        }
        properties[code] = propertyValue;
    });

    return properties;
};

const mapTechnologyGroup = (technology) => {
    const technologyGroup = get(technology, 'technology_group');

    if (technologyGroup) {
        return mapTechnologyGroupFromApi(technologyGroup);
    }
};

const mapAccessories = (accessories) =>
    accessories.map(({ accessory: { id } }) => id);

export const mapTechnologyFromApi = (technology) => ({
    id: get(technology, 'id'),
    iconFile: get(technology, 'icon_file'),
    name: get(technology, 'name'),
    description: get(technology, 'description'),
    code: get(technology, 'code'),
    stabloPrefix: get(technology, 'stablo_prefix'),
    createdAt: get(technology, 'created_at'),
    intermediateProductCount: get(technology, 'intermediate_products_count', 0),
    components: mapGroupComponentsFromApi(
        get(technology, 'group_components', []),
    ),
    attachments: get(technology, 'attachments', []),
    requiredProperties: mapRequiredPropertiesFromApi(
        get(technology, 'required_properties', []),
    ),
    patternRequirePaintPalette: get(technology, 'flags', []).includes(
        'pattern-require-paint-palette',
    ),
    technologyGroup: mapTechnologyGroup(technology),
    flags: get(technology, 'flags', []),
    isRawMaterial: get(technology, 'is_raw_material', false),
    externalProducer: get(technology, 'external_producer', false),
    accessories: mapAccessories(get(technology, 'accessories', [])),
});

const mapGroupComponentToApi = (component) => ({
    component_id: component?.technologyId,
    name: component?.personalName,
    code: component?.personalCode,
    required: component?.required,
    id: component?.id,
});

const mapRequiredPropertyToApi = (property) => property.code;

export const mapTechnologyToApi = (data = {}) => {
    const {
        technologyGroup,
        stabloPrefix,
        isRawMaterial,
        components = [],
        requiredProperties = [],
        newAttachments,
        patternRequirePaintPalette,
        createdAt,
        intermediateProductCount,
        icon,
        iconFile,
        icon_file,
        externalProducer,
        accessories = [],
        ...restForm
    } = data;

    const requiredPropertiesCode = Object.values(requiredProperties).map(
        mapRequiredPropertyToApi,
    );
    const requiredPropertiesObject =
        Object.values(requiredProperties).length > 0
            ? { required_properties_code: requiredPropertiesCode }
            : {};

    const groupComponents = components
        .filter((groupComponent) => !groupComponent?.isRemoved)
        .map(mapGroupComponentToApi);

    return {
        is_raw_material: isRawMaterial,
        icon_file: icon ? icon : iconFile,
        technology_group: technologyGroup,
        group_components: groupComponents,
        stablo_prefix: stabloPrefix,
        external_producer: externalProducer,
        ...requiredPropertiesObject,
        ...restForm,
        accessories: accessories
            .filter((item) => !!item)
            .map((item) => ({ id: item })),
    };
};

export const mapTechnologyV2ToApi = ({
    code,
    name,
    isRawMaterial = false,
    externalProducer = false,
    stabloPrefix = '',
    description = '',
    technologyGroup = { id: '' },
    requiredProperties = {},
    flags = [],
    components = [],
    icon = { id: '' },
    attachments = [],
}) => ({
    code,
    name,
    description,
    stablo_prefix: stabloPrefix,
    // WIRE FOR BE
    icon_file: icon.id ? `api/media_files/${icon.id}` : '',
    flags,
    attachments: attachments.map(({ id }) => ({ id })),
    required_properties: Object.values(requiredProperties).map(
        ({ code, id }) => ({ code, id }),
    ),
    group_components: components
        .filter((groupComponent) => !groupComponent?.isRemoved)
        .map((comp) => ({
            id: comp.technologyId,
            name: comp.personalName,
            code: comp.personalCode,
            component_id: comp.technologyId,
            required: comp.required,
        })),
    // WIRE FOR BE
    technology_group: technologyGroup.id
        ? `api/v2/technology_groups/${technologyGroup.id}`
        : '',
    is_raw_material: isRawMaterial,
    external_producer: externalProducer,
});

export const mapTechnologyAutocomplete = (data = {}) => ({
    name: data.name,
    code: data.code,
    id: data.id,
});
