import range from 'lodash/range';

type Params = Record<string, unknown>;

export interface AjaxResponse<T> {
    data?: T;
}

export interface ListResponse<T> {
    totalPages?: number;
    total_pages?: number;
    total?: number;
    results?: T[];
}

export class AsyncRequestPaginator<TResponseData, TMappedData = TResponseData> {
    constructor(
        private readonly makeListRequest: (
            params: Params,
        ) => Promise<AjaxResponse<ListResponse<TResponseData>>>,
        private readonly mapResponseData: (data: TResponseData) => TMappedData,
    ) {}

    private requestPromise: Promise<{
        items: TMappedData[];
        totalItems: number;
    }>;

    private fullList: TMappedData[] = [];

    private requestPending = false;

    async fetchFullList(params: Params) {
        if (this.requestPending) {
            return this.requestPromise;
        }

        this.requestPending = true;
        this.requestPromise = this.makeListRequest(params).then(
            (initialResponse) => {
                const items = (initialResponse?.data?.results || []).map(
                    this.mapResponseData,
                );
                const totalPages =
                    initialResponse?.data?.totalPages ||
                    initialResponse?.data?.total_pages ||
                    0;

                if (totalPages <= 1) {
                    this.fullList = items;
                    this.requestPending = false;

                    return {
                        items: this.fullList,
                        totalItems: initialResponse?.data?.total || 0,
                    };
                }

                const requests = range(2, totalPages + 1).map((pageNumber) =>
                    this.makeListRequest({
                        ...params,
                        page: pageNumber,
                    }),
                );

                return Promise.all(requests).then((responses) => {
                    this.fullList = [
                        ...items,
                        ...responses
                            .map((response) =>
                                (response?.data?.results || []).map(
                                    this.mapResponseData,
                                ),
                            )
                            .flat(),
                    ];
                    this.requestPending = false;

                    return {
                        items: this.fullList,
                        totalItems: initialResponse?.data?.total || 0,
                    };
                });
            },
        );

        return this.requestPromise;
    }
}
