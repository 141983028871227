export const mapCurrentTask = (data = []) => {
    if (data.length) {
        const { id } = data[0];

        return {
            id: id || '',
        };
    } else {
        return {
            id: '',
        };
    }
};
