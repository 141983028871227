import i18next from 'i18next';

import { URLS, DEFAULTS } from './constants';
import parser from 'qs';
import { OdaService } from './OdaService';
import {
    ApplicationJsonHeaders,
    HttpPatchHeaders,
    HeadersAcceptType,
} from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const odaServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    OdaService.getInstance({
        CONSTANTS: {
            URLS,
            DEFAULTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
                patch: HttpPatchHeaders,
                download: HeadersAcceptType,
            },
        },
        services: {
            ajax: ajaxService,
            alert: alertService,
            parser,
            translator: i18next,
        },
    });
