export const mapBoxesFromApi = (data = {}) => {
    const {
        producer = {},
        images = [],
        barcode = '',
        description = '',
        box_group = {},
        id
    } = data;

    return {
        id,
        barcode,
        images,
        producer,
        description,
        boxGroup: box_group,
    };
};

export const mapBoxToApi = (data) => {
    const { images, boxGroup, barcode, description, producer } = data;

    return {
        images,
        barcode,
        description,
        producer: {
            id: producer.id
        },
        box_group: boxGroup,
    };
};

export const mapBoxGroupToApi = (data) => {
    return data;
};
