import get from 'lodash/get';

const WORKPLACES_API_URL = get(window, 'env.WORKPLACES_URL', '');

const USER_ID_WORKPLACE_URL = `${WORKPLACES_API_URL}/api/users/{id}/workplace`;

const WORKPLACES_URL = `${WORKPLACES_API_URL}/api/workplaces`;
const WORKPLACES_ID_URL = `${WORKPLACES_API_URL}/api/workplaces/{id}`;

export const URLS = {
    GET_WORKPLACE: WORKPLACES_ID_URL,
    GET_WORKPLACES: WORKPLACES_URL,
    POST_WORKPLACES: WORKPLACES_URL,
    PUT_WORKPLACES: WORKPLACES_ID_URL,
    PRINTOUT: `${WORKPLACES_API_URL}/api/printers/{printerCode}/printout`,
    GET_USER_WORKPLACE: USER_ID_WORKPLACE_URL,
    PATCH_USER_WORKPLACE: USER_ID_WORKPLACE_URL,
    GET_PRINTERS: `${WORKPLACES_API_URL}/api/printers`,
};


export const BASE_PRINTERS_STALE_TIME =  5 * (1000 * 60); // 5 minutes
export const BASE_PRINTERS_CACHE_TIME =  10 * (1000 * 60); // 10 minutes