const placeholder = '';

const getReceiver = (receiver) => ({
    name: receiver?.name || placeholder,
    phone: receiver?.phone || placeholder,
    streetLine: receiver?.streetLine || placeholder,
    postalCode: receiver?.postalCode || placeholder,
    city: receiver?.city || placeholder,
    countryCode: receiver?.countryCode || placeholder,
    email: receiver?.email || placeholder,
    company: receiver?.company || placeholder,
    provinceCode: receiver?.provinceCode || placeholder,
    outsideEu: receiver?.outsideEu || false,
});

const getShipper = (shipper) => ({
    phone: shipper?.phone || placeholder,
    name: shipper?.name || placeholder,
    streetLine: shipper?.streetLine || placeholder,
    postalCode: shipper?.postalCode || placeholder,
    city: shipper?.city || placeholder,
    countryCode: shipper?.countryCode || placeholder,
    email: shipper?.email || placeholder,
});

const getCarrier = (carrier) => ({
    type: carrier?.type || placeholder,
});

export const mapShipmentsFromApi = (data) => {
    const {
        id = placeholder,
        trackingId = placeholder,
        trackingLink = placeholder,
        createdAt = placeholder,
        pickUpDate = placeholder,
        deliveryDate = placeholder,
        shipDate = placeholder,
        status = placeholder,
        platform = placeholder,
        errorReason = placeholder,
        receiver = {},
        shipper = {},
        carrier = {},
        documents = [],
    } = data;

    const products = data?.products?.map?.((product) => ({
        stabloId: product?.stabloId,
        orderName: product?.orderName,
    }));

    return {
        id,
        products,
        trackingId,
        trackingLink,
        createdAt,
        pickUpDate,
        deliveryDate,
        shipDate,
        status,
        platform,
        documents,
        errorReason,
        receiver: getReceiver(receiver),
        shipper: getShipper(shipper),
        carrier: getCarrier(carrier)
    };
};

export const mapShipmentDocumentsFromApi = (item) => ({
    id: item?.id,
    status: item?.status,
    type: item?.type
});

export const mapShipmentEditToApi = (data) => {
    const { shipDate, receiver } = data;

    return {
        shipDate,
        receiver
    };
};
