import { profileServiceFactory } from './profile/profileServiceFactory';
import { stockServiceFactory } from './stock/stockServiceFactory';
import { armApiServiceFactory } from './armApi/armApiServiceFactory';
import { pdpServiceFactory } from './pdp/pdpServiceFactory';
import { dictionariesServiceFactory } from './dictionaries/dictionariesServiceFactory';
import { salesServiceFactory } from './sales/salesServiceFactory';
import { simpServiceFactory } from './simp/simpServiceFactory';
import { salesChannelsServiceFactory } from './salesChannels/salesChannelsServiceFactory';
import { skuServiceFactory } from './sku/skuServiceFactory';
import { carouselServiceFactory } from './carousel/carouselServiceFactory';
import { camundaProxyServiceFactory } from './camundaProxy/camundaProxyServiceFactory';
import { discountsServiceFactory } from './discounts/discountsServiceFactory';
import { searchServiceFactory } from './search/searchServiceFactory';
import { odaServiceFactory } from './oda/odaServiceFactory';
import { productionServiceFactory } from './production/productionServiceFactory';
import { stabloServiceFactory } from './stablo/stabloServiceFactory';
import { mediaFilesServiceFactory } from './mediaFiles/mediaFilesServiceFactory';
import { workplacesServiceFactory } from './workplaces/workplacesServiceFactory';
import { notificationCenterServiceFactory } from 'services/notificationCenter/notificationCenterServiceFactory';
import { postSalesServiceFactory } from 'services/postSales/postSalesServiceFactory';
import { prodisServiceFactory } from './prodis/prodisServiceFactory';
import { dynamicFormDictionariesServiceFactory } from './dynamicFormDictionaries/dynamicFormDictionariesServiceFactory';
import { productsServiceFactory } from './products/productsServiceFactory';
import { userServiceFactory } from './userService/userServiceFactory';
import ModalService from './modal/ModalService';
import { ajaxServiceFactory } from './ajax/ajaxServiceFactory';
import type { Store } from 'redux';
import { routerServiceFactory } from './router/routerServiceFactory';
import { errorHistoryFactory } from './ErrorHistory/errorHistoryFactory';
import { alertServiceFactory } from './alert/alertServiceFactory';
import { authenticationServiceFactory } from './authentication/authenticationServiceFactory';
import { StoreService } from './store/StoreService';
import { DrawerService } from './drawer/DrawerService';

// TODO: Remove this once we stop using services in redux hooks
export const slimServicesFactory = () => {
    const authenticationService = authenticationServiceFactory();
    const errorHistory = errorHistoryFactory();
    const alertService = alertServiceFactory(errorHistory);
    const ajaxService = ajaxServiceFactory(authenticationService, alertService);
    const modalService = ModalService.getInstance();
    const carouselService = carouselServiceFactory(modalService);
    const dictionariesService = dictionariesServiceFactory(ajaxService);
    const discountsService = discountsServiceFactory(ajaxService, alertService);
    const drawerService = DrawerService.getInstance();
    const dynamicFormDictionariesService =
        dynamicFormDictionariesServiceFactory(ajaxService, alertService);
    const mediaFilesService = mediaFilesServiceFactory(
        ajaxService,
        alertService,
    );
    const notificationCenterService = notificationCenterServiceFactory(
        ajaxService,
        alertService,
    );

    const profileService = profileServiceFactory(ajaxService);

    const userService = userServiceFactory(ajaxService, alertService);

    const stockService = stockServiceFactory(
        ajaxService,
        alertService,
        authenticationService,
        userService
    );

    const armApiService = armApiServiceFactory(
        mediaFilesService,
        ajaxService,
        alertService,
        authenticationService,
    );

    const pdpService = pdpServiceFactory(
        ajaxService,
        alertService,
        authenticationService,
    );

    const salesService = salesServiceFactory(ajaxService, alertService);

    const simpService = simpServiceFactory(
        mediaFilesService,
        ajaxService,
        alertService,
    );

    const postSalesService = postSalesServiceFactory(ajaxService, alertService);

    const salesChannelsService = salesChannelsServiceFactory(
        ajaxService,
        alertService,
    );

    const skuService = skuServiceFactory(ajaxService, alertService);

    const productsService = productsServiceFactory(ajaxService, alertService);

    const prodisService = prodisServiceFactory(
        mediaFilesService,
        ajaxService,
        alertService,
    );

    const searchService = searchServiceFactory(
        userService,
        ajaxService,
        alertService,
    );

    const odaService = odaServiceFactory(ajaxService, alertService);

    const productionService = productionServiceFactory(
        ajaxService,
        alertService,
    );

    const stabloService = stabloServiceFactory(ajaxService, alertService);

    const workplacesService = workplacesServiceFactory(
        ajaxService,
        alertService,
        authenticationService,
    );

    return {
        authentication: authenticationService,
        errorHistory,
        alerts: alertService,
        ajax: ajaxService,
        modal: modalService,
        carousel: carouselService,
        dictionaries: dictionariesService,
        discounts: discountsService,
        drawer: drawerService,
        dynamicFormDictionaries: dynamicFormDictionariesService,
        mediaFiles: mediaFilesService,
        notificationCenter: notificationCenterService,
        profile: profileService,
        stock: stockService,
        armApi: armApiService,
        pdp: pdpService,
        sales: salesService,
        simp: simpService,
        postSales: postSalesService,
        salesChannels: salesChannelsService,
        sku: skuService,
        products: productsService,
        prodis: prodisService,
        userServiceApi: userService,
        searchApi: searchService,
        odaApi: odaService,
        productionApi: productionService,
        stablo: stabloService,
        workplaces: workplacesService,
    };
};

export const servicesFactory = (store: Store) => {
    const router = routerServiceFactory(store);
    const authenticationService = authenticationServiceFactory();
    const errorHistory = errorHistoryFactory();
    const alertService = alertServiceFactory(errorHistory);
    const ajaxService = ajaxServiceFactory(authenticationService, alertService);
    const storeService = StoreService.getInstance(store);
    const camundaProxyService = camundaProxyServiceFactory(
        ajaxService,
        alertService,
        storeService,
    );

    return {
        ...slimServicesFactory(),
        router,
        store: storeService,
        camundaProxy: camundaProxyService,
    };
};

export type TServices = ReturnType<typeof servicesFactory>;
