import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export const ClipboardIcon = (props) => (
    <SvgIcon
        { ...props }
        viewBox="0 0 24 24"
    >
        <path
            d="M20.2422 3.42859H18.7541C18.7231 3.42859 18.6955 3.43924 18.6667 3.44621V1.88355C18.6667 0.844875 17.8025 0 16.74 0H3.8315C2.76901 0 1.90479 0.844875 1.90479 1.88355V19.4498C1.90479 20.4885 2.76901 21.3333 3.8315 21.3333H6.09526V22.1838C6.09526 23.1853 6.92637 24 7.94832 24H20.2422C21.2642 24 22.0953 23.1853 22.0953 22.1838V5.24479C22.0953 4.24331 21.2642 3.42859 20.2422 3.42859ZM2.66667 19.4498V1.88355C2.66667 1.26525 3.18938 0.761925 3.8315 0.761925H16.74C17.3821 0.761925 17.9048 1.26525 17.9048 1.88355V19.4498C17.9048 20.0681 17.3821 20.5714 16.74 20.5714H3.8315C3.18938 20.5715 2.66667 20.0681 2.66667 19.4498ZM21.3333 22.1838C21.3333 22.7653 20.8438 23.2381 20.2422 23.2381H7.94832C7.34675 23.2381 6.85715 22.7653 6.85715 22.1838V21.3333H16.74C17.8025 21.3333 18.6667 20.4885 18.6667 19.4498V4.17285C18.6955 4.17983 18.7231 4.19047 18.7541 4.19047H20.2422C20.8438 4.19047 21.3333 4.66331 21.3333 5.24479V22.1838Z"
            fill="#666666"
        />
        <path
            d="M14.4762 4.95239H6.09526C5.88469 4.95239 5.71429 5.12276 5.71429 5.33332C5.71429 5.54392 5.88469 5.71428 6.09526 5.71428H14.4762C14.6868 5.71428 14.8572 5.54392 14.8572 5.33332C14.8572 5.12276 14.6868 4.95239 14.4762 4.95239Z"
            fill="#666666"
        />
        <path
            d="M14.4762 8.00003H6.09526C5.88469 8.00003 5.71429 8.17039 5.71429 8.38095C5.71429 8.59152 5.88469 8.76192 6.09526 8.76192H14.4762C14.6868 8.76192 14.8572 8.59152 14.8572 8.38095C14.8572 8.17039 14.6868 8.00003 14.4762 8.00003Z"
            fill="#666666"
        />
        <path
            d="M14.4762 11.0476H6.09526C5.88469 11.0476 5.71429 11.218 5.71429 11.4286C5.71429 11.6391 5.88469 11.8095 6.09526 11.8095H14.4762C14.6868 11.8095 14.8572 11.6391 14.8572 11.4286C14.8572 11.218 14.6868 11.0476 14.4762 11.0476Z"
            fill="#666666"
        />
        <path
            d="M10.6667 14.0952H6.09526C5.88469 14.0952 5.71429 14.2656 5.71429 14.4762C5.71429 14.6868 5.88469 14.8572 6.09526 14.8572H10.6667C10.8773 14.8572 11.0476 14.6868 11.0476 14.4762C11.0476 14.2656 10.8773 14.0952 10.6667 14.0952Z"
            fill="#666666"
        />
    </SvgIcon>
);

ClipboardIcon.defaultProps = {
    titleAccess: 'clipboard-icon',
};

ClipboardIcon.propTypes = svgIconPropTypes;
