export const MODULE_NAME = 'dropshipping';

export const PAYMENT_CONFIRMATION_FILES_CONFIG = {
    ACCEPTED_FILE_TYPES: [
        'image/jpeg',
        'image/gif',
        'image/png',
        'text/plain',
        'application/pdf',
    ],
};

export const LANGUAGE_OPTIONS = [
    'pl',
    'en',
];
export const TRADE_TERMS_OPTIONS = [
    'prepaid_pdf',
    'prepaid_paypal',
    'separate_order_payment',
    'all_orders_payment',
];
export const SHIPPING_METHOD_OPTIONS = [
    'courier',
    'by_hand',
];

export const DISCOUNTS_PERIOD_TYPES = [
    'limited',
    'unlimited',
];

export const PREDEFINED_INVOICES_PARAMS = {
    ORDER: {
        ['invoice_date']: 'desc',
    },
};

export const PREDEFINED_ORDER_IMPORTS_PARAMS = {
    ORDER: {
        ['created_at']: 'desc',
    },
};
