export const mapCustomFieldValueFromApi = (data = {}) => {
    const { custom_field_definition, id, type, value, intermediate_products, category_definition, language = '' } = data;
    const technologies = data.technologies ? data.technologies.map(({ id, name }) => ({ id, name })) : [];
    const intermediateProducts = intermediate_products && intermediate_products.map(({ id }) => id);

    return {
        id,
        type,
        value,
        technologies,
        intermediateProducts,
        customFieldDefinition: custom_field_definition,
        categoryDefinition: category_definition,
        categoryDefinitionDescription: category_definition?.name || '',
        technologiesDescription: technologies.map((item) => item.name).join(', '),
        intermediateProductsDescription: (intermediate_products || []).map((item) => item.name).join(', '),
        language,
    };
};

export const mapCustomFieldValueToApi = (data = {}) => {
    const {
        customFieldDefinition,
        type,
        value,
        technologies,
        intermediateProducts,
        language,
        categoryDefinitionId
    } = data;

    return {
        value,
        type,
        technologies: technologies && technologies.map((item) => ({ id: item.id })),
        intermediate_products: intermediateProducts && intermediateProducts.map((item) => ({ id: item })),
        custom_field_definition: {
            id: customFieldDefinition.id
        },
        category_definition: categoryDefinitionId ? {
            id: categoryDefinitionId,
        } : null,
        language: language || null
    };
};

export const mapCustomFieldValueEditToApi = (data = {}) => mapCustomFieldValueToApi(data);
