import type AjaxService from '../../../ajax/AjaxService';
import type { URLS } from '../../constants';
import type { default as errorHandlersFactory } from '../../errorHandlers';
import { ApplicationJsonHeaders } from '../../../constants';
import type { ShipmentsDocument } from './shipments.type';
import { AjaxResponse, AsyncRequestPaginator, ListResponse } from '../../../utils/AsyncRequestPaginator';
import qs from 'qs';

export class ShipmentsActions {
    private static instance: ShipmentsActions | undefined;

    private readonly asyncRequestPaginator: AsyncRequestPaginator<ShipmentsDocument, ShipmentsDocument>;

    private constructor(private readonly ajaxService: AjaxService, private readonly urls: typeof URLS, private readonly errorHandlers: ReturnType<typeof errorHandlersFactory>) {
        this.getDocuments = this.getDocuments.bind(this);
        this.getAllDocuments = this.getAllDocuments.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator<ShipmentsDocument, ShipmentsDocument>(this.getDocuments, (data) => data);
    }

    static getInstance(ajaxService: AjaxService, urls: typeof URLS, errorHandlers: ReturnType<typeof errorHandlersFactory>): ShipmentsActions {
        if (!ShipmentsActions.instance) {
            this.instance = new ShipmentsActions(ajaxService, urls, errorHandlers);
        }

        return this.instance;
    }

    getDocuments(params: { id: string; page: number }): Promise<AjaxResponse<ListResponse<ShipmentsDocument>>> {
        const url = this.urls.GET_SHIPMENT_DOCUMENTS.replace('{id}', params.id);

        return this.ajaxService.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                params: { page: params.page },
                paramsSerializer: (queryParams) => qs.stringify(queryParams)
            },
            errorConfig: { onError: this.errorHandlers.get },
            onError: this.errorHandlers.get,
        });
    }

    async getAllDocuments(id: string): Promise<ShipmentsDocument[]> {
        const data = await this.asyncRequestPaginator.fetchFullList({ id });

        return data.items;
    }
}
