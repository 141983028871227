import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { putAlert } from './handleAlerts';
import { getRawActiveToken } from 'utils/tokenHelpers';

export function* makeRequestWithAlerts(
    action,
    url,
    request,
    alerts,
    attributes,
    callbacks,
    sagaCallbacks,
    download,
    paramsSerializerOptions,
    model,
    mapper,
    disableParamsSerializer,
    errorHandlers,
) {
    try {
        const token = getRawActiveToken();

        request.headers = {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
        };

        if (download) {
            request.responseType = 'blob';
        }

        if (token) {
            request.headers.Authorization = 'Bearer ' + token;
        }

        if (attributes.headers) {
            request.headers = _.merge(request.headers, attributes.headers);
        }

        if (attributes.responseType) {
            request.responseType = attributes.responseType;
        }

        if (!disableParamsSerializer) {
            request.paramsSerializer = (params) => {
                return qs.stringify(params, paramsSerializerOptions);
            };
        }

        const response = yield call(axios, url, request);

        if (action && action.success) {
            const responseData = model ?
                new model(response)
                :
                mapper ?
                    mapper(response.data)
                    :
                    response.data;

            yield put(action.success({
                response: responseData || {},
                ...attributes.success,
            }));
        }

        if (callbacks.success) {
            callbacks.success(response);
        }
        if (sagaCallbacks.success) {
            yield put(sagaCallbacks.success(response));
        }
        if (alerts.success) {
            yield putAlert(alerts, response, 'success');
        }

        if (errorHandlers && errorHandlers.success) {
            errorHandlers(response);
        }

        return response;
    } catch (e) {
        if (action && action.failure) {
            const responseData = model ?
                new model(e.response)
                :
                e.response && e.response.data;

            yield put(action.failure({
                response: responseData || {},
                error: e,
                ...attributes.failure,
            }));
        } else {
            return e.response;
        }
        if (callbacks.failure) {
            try {
                callbacks.failure(e);
            } catch (e) {
                return e;
            }
        }
        if (sagaCallbacks.failure) {
            yield put(sagaCallbacks.failure(e));
        }
        if (alerts.failure) {
            yield putAlert(alerts, e.response, 'error');
        }

        return e;
    }
}
