import { NavListItemButton } from './NavListItemButton';
import { useTranslation } from 'react-i18next';
import type { NavBarModule, TExpandModuleHandler } from './navBar.type';
import { NavTooltip } from './NavTooltip';

interface IFixedModuleProps {
    module: NavBarModule;
    isOpen: boolean;
    isActive: boolean;
    extended: boolean;
    onClick: TExpandModuleHandler;
}

export const FixedModule = ({
    module,
    isOpen,
    isActive,
    onClick,
    extended,
}: IFixedModuleProps) => {
    const { t } = useTranslation(['app']);

    const moduleName = t(`navigation.${module.name}.main`, {
        ns: 'app',
        defaultValue: module.name,
    });

    return (
        <NavTooltip
            isTooltip={!extended && !!module.icon}
            tooltipTitle={moduleName}
        >
            <NavListItemButton
                moduleName={moduleName}
                module={module}
                sections={module.routes}
                isActive={isActive}
                isOpen={isOpen}
                onClick={onClick}
                fixed
            />
        </NavTooltip>
    );
};
