import get from 'lodash/get';

const SALES_URL = get(window, 'env.SIMP_API_URL', '');
const SIMP_DECO_API_URL = get(window, 'env.SIMP_DECO_API_URL', '');
const ORDER_LINES_BASE_URL = `${SALES_URL}/api/orders`;
const PACKAGES_BASE_URL = `${SALES_URL}/api/packages`;
const INVOICES_BASE_URL = `${SALES_URL}/api/order_invoices`;

export const URLS = {
    GET_ORDER: `${ORDER_LINES_BASE_URL}/{id}`,
    GET_ORDER_LINES: `${ORDER_LINES_BASE_URL}/{id}/order_lines`,
    GET_PACKAGES: `${PACKAGES_BASE_URL}/{id}`,
    GET_ORDER_FOR_REIMPORT: `${ORDER_LINES_BASE_URL}/{id}/update_status`,
    GET_ORDER_FOR_REIMPORT_DECOMONKEY: `${SIMP_DECO_API_URL}/api/orders/{id}/update_status`,
    DOWNLOAD_INVOICE: `${INVOICES_BASE_URL}/{id}/download`,
};

export const ORDER_REIMPORT_STATUS = {
    status: 'collecting_information',
    statusDetails: '',
};

export const ALERTS_MESSAGES = {
    REIMPORT_PASSED: 'sales:alerts.reimportPassed',
};
