import React from 'react';
import * as PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import Button from 'components/Button/button';
import { withWidth } from 'hoc/withWidth/withWidth';

class PrimaryFormButton extends React.PureComponent {
    render() {
        const {
            t,
            create,
            width,
            editable,
            primaryButtonVariant,
            handleSubmit,
            handleEdit,
            label,
            disabled,
        } = this.props;

        return editable || create ? (
            <Button
                variant={primaryButtonVariant}
                baseWidth={width !== 'xs'}
                fullWidth={width === 'xs'}
                onClick={handleSubmit}
                color="secondary"
                disabled={disabled}
            >
                {label ? label : upperFirst(t('common:actions.accept'))}
            </Button>
        ) : (
            <Button
                variant={primaryButtonVariant}
                baseWidth={width !== 'xs'}
                fullWidth={width === 'xs'}
                onClick={handleEdit}
                color="primary"
                disabled={disabled}
            >
                {upperFirst(t('common:actions.edit'))}
            </Button>
        );
    }
}

PrimaryFormButton.defaultProps = {
    t: (key) => key,
    primaryButtonVariant: 'contained',
};

PrimaryFormButton.propTypes = {
    t: PropTypes.func,
    create: PropTypes.bool,
    editable: PropTypes.bool,
    width: PropTypes.string,
    primaryButtonVariant: PropTypes.string,
    handleEdit: PropTypes.func,
    handleSubmit: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

const PrimaryFormButtonWithHOC = withWidth()(PrimaryFormButton);

export default PrimaryFormButtonWithHOC;
