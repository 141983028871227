import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function SearchIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -2 16 16"
            { ...props }
        >
            <path
                d="M5.33333 0C2.39413 0 0 2.39456 0 5.33428C0 8.274 2.39413 10.6686 5.33333 10.6686C6.61147 10.6686 7.78527 10.2146 8.70521 9.46105L11.8896 12.646C11.9387 12.6972 11.9976 12.7381 12.0627 12.7662C12.1279 12.7944 12.198 12.8093 12.2689 12.81C12.3399 12.8107 12.4103 12.7972 12.476 12.7704C12.5416 12.7436 12.6013 12.7039 12.6515 12.6537C12.7017 12.6036 12.7413 12.5439 12.7682 12.4782C12.795 12.4125 12.8084 12.3421 12.8077 12.2711C12.807 12.2001 12.7921 12.13 12.764 12.0649C12.7358 11.9997 12.6949 11.9408 12.6437 11.8917L9.45937 8.70675C10.2128 7.78665 10.6667 6.61264 10.6667 5.33428C10.6667 2.39456 8.27253 0 5.33333 0ZM5.33333 1.06686C7.69606 1.06686 9.6 2.97113 9.6 5.33428C9.6 7.69743 7.69606 9.6017 5.33333 9.6017C2.9706 9.6017 1.06667 7.69743 1.06667 5.33428C1.06667 2.97113 2.9706 1.06686 5.33333 1.06686Z"
            />
        </SvgIcon>
    );
}

SearchIcon.defaultProps = {
    titleAccess: 'search-icon',
};

SearchIcon.propTypes = svgIconPropTypes;
