export const MODULE_NAME = 'manage-offers';

export const SALES_CHANNELS_TYPES = [
    'auctions',
    'shops',
    'drop',
];

export const SEPARATOR_OPTIONS = [
    '/',
    '-',
    '_',
    ':',
    'empty',
];

export const LANGUAGES = ['PL', 'EN', 'FR', 'DE', 'ES', 'IT', 'PT', 'SE', 'NL'];

export const customFieldValueTypeAvValues = (t) => ([
    { value: 'channel', label: t('manageOffers:labels.salesChannel') },
    { value: 'technology', label: t('manageOffers:labels.technology') },
    { value: 'intermediate_product', label: t('manageOffers:labels.intermediateProduct') },
    { value: 'category', label: t('labels.category', { ns: 'manageOffers' }) }
]);
