import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ChevronDownIcon(props) {
    return (
        <SvgIcon
            viewBox="-3.5 -6 16 16"
            { ...props }
        >
            <path d="M0.488688 0L0 0.499548L4.50136 4.90588L9 0.499548L8.51674 0L4.50136 3.93394L0.488688 0Z" />
        </SvgIcon>
    );
}

ChevronDownIcon.defaultProps = {
    titleAccess: 'chevron-down-icon',
};

ChevronDownIcon.propTypes = svgIconPropTypes;
