/**
 * @typedef {import('./notes.type').NoteOptions} NoteOptions
 */

/**
 * @typedef {import('./notes.type').FeedOptions} FeedOptions
 */

/**
 * @param {FeedOptions & NoteOptions} options
 * @return {string}
 */
const makeFeedNote = ({ language, priceList = '', includePrice, alwaysIncludePrice = false, note = '' }) => {
    let newNote = note;

    if ((includePrice && priceList && priceList !== '') || alwaysIncludePrice) {
        newNote = `Cennik: ${priceList}\n${newNote}`;
    }

    return `Język: ${language}\n${newNote}`;
};

export { makeFeedNote };
