import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ToggleOnIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -3.5 14 14"
            { ...props }
        >
            <path
                d="M10.3334 0.666626H3.66671C1.82671 0.666626 0.333374 2.15996 0.333374 3.99996C0.333374 5.83996 1.82671 7.33329 3.66671 7.33329H10.3334C12.1734 7.33329 13.6667 5.83996 13.6667 3.99996C13.6667 2.15996 12.1734 0.666626 10.3334 0.666626ZM10.3334 5.99996C9.22671 5.99996 8.33337 5.10663 8.33337 3.99996C8.33337 2.89329 9.22671 1.99996 10.3334 1.99996C11.44 1.99996 12.3334 2.89329 12.3334 3.99996C12.3334 5.10663 11.44 5.99996 10.3334 5.99996Z"
            />
        </SvgIcon>
    );
}

ToggleOnIcon.defaultProps = {
    titleAccess: 'toggle-on-icon',
};

ToggleOnIcon.propTypes = svgIconPropTypes;
