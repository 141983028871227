/* eslint-disable no-dupe-class-members */
import type { AjaxService } from 'services/ajax/AjaxService';
import { ApplicationJsonHeaders } from 'services/constants';
import { configError } from 'services/utils/utils';
import type { default as ErrorHandlersFactory } from './errorHandlers';
import type { AlertService } from 'services/alert/AlertService';

export class MediaFilesService {
    private readonly URLS: unknown;
    private readonly ajax: AjaxService;
    private readonly errorHandlers: ReturnType<typeof ErrorHandlersFactory>;

    private constructor({
        services,
        errorHandlers,
        URLS,
    }: {
        services: {
            ajax: AjaxService;
            alert: AlertService;
        };
        errorHandlers: ReturnType<typeof ErrorHandlersFactory>;
        URLS: unknown;
    }) {
        this.URLS = URLS;
        this.errorHandlers = errorHandlers;
        this.ajax = services.ajax;

        this.uploadCustomFiles = this.uploadCustomFiles.bind(this);
        this.uploadProdisFile = this.uploadProdisFile.bind(this);
        this.uploadCustomerRequestsFile =
            this.uploadCustomerRequestsFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadBlob = this.downloadBlob.bind(this);
    }

    private static instance: MediaFilesService | undefined;

    public static getInstance({
        services,
        errorHandlers,
        URLS,
    }: {
        services: {
            ajax: AjaxService;
            alert: AlertService;
        };
        errorHandlers: ReturnType<typeof ErrorHandlersFactory>;
        URLS: unknown;
    }): MediaFilesService {
        if (!MediaFilesService.instance) {
            MediaFilesService.instance = new MediaFilesService({
                services,
                errorHandlers,
                URLS,
            });
        }

        return MediaFilesService.instance;
    }

    private async uploadFiles(
        file: string | Blob,
        url: string,
    ): Promise<{ id: string }>;
    private async uploadFiles(
        file: Blob,
        url: string,
        fileName: string,
    ): Promise<{ id: string }>;
    private async uploadFiles(
        file: string | Blob,
        url: string,
        fileName?: string | undefined,
    ): Promise<{ id: string }> {
        const { ajax, errorHandlers } = this;

        const formData = new FormData();

        if (fileName) {
            formData.append('file', file as Blob, fileName);
        } else {
            formData.append('file', file);
        }

        const { data } = await ajax.post({
            url,
            data: formData,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('post', errorHandlers),
        });

        return data;
    }

    uploadCustomFiles(file: string | Blob) {
        const { URLS } = this;

        return this.uploadFiles(file, URLS.UPLOAD_CUSTOM_FILES);
    }

    /**
     * @param {string | Blob} file
     */
    uploadProdisFile(file) {
        const { URLS } = this;

        return this.uploadFiles(file, URLS.PRODIS_MEDIA_FILES);
    }

    /**
     * @param {Blob} file
     * @param {string} fileName
     */
    uploadCustomerRequestsFile(file, fileName) {
        const { URLS } = this;

        return this.uploadFiles(
            file,
            URLS.CUSTOMER_REQUESTS_MEDIA_FILES,
            fileName,
        );
    }

    /**
     * @param {string} path
     * @param {(error: unknown) => void} errorHandler
     * @returns {Promise<string>}
     */
    async downloadFile(
        path,
        errorHandler = configError('get', this.errorHandlers).throwError,
    ) {
        const response = await this.downloadBlob(path, errorHandler);

        const blob = new Blob([response.data], { type: 'application/pdf' });

        return window.URL.createObjectURL(blob);
    }

    /**
     * @param {string} path
     * @param {(error: unknown) => void} errorHandler
     * @returns {Promise<import('axios').AxiosResponse<BlobPart>>}
     */
    async downloadBlob(
        path,
        errorHandler = configError('get', this.errorHandlers).throwError,
    ) {
        const { ajax, URLS } = this;

        return ajax.get({
            url: `${URLS.MEDIA_FILES_API_V1}/${path}/download`,
            config: {
                headers: {
                    'Content-Type': 'application/offset+octet-stream',
                    Accept: 'application/offset+octet-stream',
                },
                responseType: 'blob',
            },
            errorConfig: { throwError: errorHandler },
        });
    }
}
