import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClassNames } from '@emotion/react';
import { modalCarouselPaperStyles, modalFullHeightPaperStyles } from 'containers/Modal/modal.styles';

class Modal extends Component {
    getDialogTitle() {
        const {
            title,
            close,
        } = this.props;

        if (typeof title === 'function') {
            return title(close);
        }

        return title;
    }

    getDialogContent() {
        const {
            content,
            close,
        } = this.props;

        if (typeof content === 'function') {
            return content(close);
        }

        return content;
    }

    getDialogChildren() {
        const {
            children,
            close,
        } = this.props;

        if (typeof children === 'function') {
            return children(close);
        }

        return children;
    }

    getDialogActions() {
        const {
            actions,
            close,
        } = this.props;

        if (typeof actions === 'function') {
            return actions(close);
        }

        return actions;
    }

    getDialogProps() {
        const {
            dialogProps,
            close,
        } = this.props;

        if (typeof dialogProps === 'function') {
            return dialogProps(close);
        }

        return dialogProps;
    }

    render() {
        const {
            id,
            title,
            content,
            actions,
            children,
            dialogTitleProps,
            dialogContentProps,
            dialogActionsProps,
            className,
            isCarousel,
            isFullHeight,
        } = this.props;

        return (
            <ClassNames>
                {({ cx }) => (
                    <Dialog
                        open
                        className={ className }
                        aria-labelledby={ id }
                        { ...this.getDialogProps() }
                        PaperProps={{
                            classes: {
                                root: cx(
                                    { [modalCarouselPaperStyles]: isCarousel },
                                    { [modalFullHeightPaperStyles]: isFullHeight },
                                ),
                            },
                        }}
                    >
                        {title && (
                            <DialogTitle
                                id={ id }
                                { ...dialogTitleProps }
                            >
                                {this.getDialogTitle()}
                            </DialogTitle>
                        )}
                        {content && (
                            <DialogContent { ...dialogContentProps }>
                                {this.getDialogContent()}
                            </DialogContent>
                        )}
                        {actions && (
                            <DialogActions { ...dialogActionsProps }>
                                {this.getDialogActions()}
                            </DialogActions>
                        )}
                        {children && this.getDialogChildren()}
                    </Dialog>
                )}
            </ClassNames>
        );
    }
}

Modal.defaultProps = {
    dialogProps: {},
    dialogTitleProps: {},
    dialogContentProps: {},
    dialogActionsProps: {},
};

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    actions: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    dialogProps: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    dialogTitleProps: PropTypes.shape({}),
    dialogContentProps: PropTypes.shape({}),
    dialogActionsProps: PropTypes.shape({}),
    isCarousel: PropTypes.bool,
    isFullHeight: PropTypes.bool,
    className: PropTypes.object,
};

export default Modal;
