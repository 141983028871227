import { fromJS } from 'immutable';
import * as C from './constants';
import countries from 'utils/countries';
import { setLoadedCollection, setLoadingCollection } from 'utils/immutable/map';
import { toIdMap } from 'utils/immutable/list';

const initialState = fromJS({
    technologies: {},
    states: {},
    platforms: {},
    contractors: {},
    accessories: {},
    countries: {},
    productionOrdersById: {},
    totalProductionOrdersCount: 0,
    loaders: {},
    partsCounts: {},
    errors: '',
});

const getSendIds = (collection) =>
    collection.map((isTrue, productionOrderId) => isTrue && productionOrderId);

export default function productionOrdersReducer(state = initialState, action) {
    switch (action.type) {
        case C.FETCH_DICTIONARIES.request: {
            return state.setIn(['loaders', 'dictionaries'], true);
        }
        case C.FETCH_DICTIONARIES.failure: {
            return state.setIn(['loaders', 'dictionaries'], false);
        }
        case C.FETCH_DICTIONARIES.success: {
            const partsCounts = {};

            for (let i = 0; i <= 25; i++) {
                partsCounts[i] = `${i}`;
            }

            return state
                .set(
                    'technologies',
                    fromJS(action.payload.response.technology).mapEntries(
                        ([k, v], index) => {
                            return [index, { key: k, value: v }];
                        },
                    ),
                )
                .set(
                    'states',
                    fromJS(action.payload.response.state).mapEntries(
                        ([k, v], index) => {
                            return [index, { key: k, value: v }];
                        },
                    ),
                )
                .set(
                    'platforms',
                    fromJS(action.payload.response.platform).mapEntries(
                        ([k, v], index) => {
                            return [index, { key: k, value: v }];
                        },
                    ),
                )
                .set(
                    'contractors',
                    fromJS(action.payload.response.contractor).mapEntries(
                        ([k, v], index) => {
                            return [index, { key: k, value: v }];
                        },
                    ),
                )
                .set(
                    'accessories',
                    fromJS(action.payload.response.accessory).mapEntries(
                        ([k, v], index) => {
                            return [index, { key: k, value: v }];
                        },
                    ),
                )
                .set(
                    'countries',
                    fromJS(countries).map((country) => {
                        return {
                            key: country,
                            value: 'libraries:countries.' + country,
                        };
                    }),
                )
                .set(
                    'partsCounts',
                    fromJS(partsCounts).map((option) => {
                        return { key: option, value: option };
                    }),
                )
                .setIn(['loaders', 'dictionaries'], false);
        }

        case C.FETCH_PRODUCTION_ORDERS.request: {
            return state.setIn(['loaders', 'productionOrdersTable'], true);
        }
        case C.FETCH_PRODUCTION_ORDERS.failure: {
            return state
                .set('productionOrdersById', fromJS({}))
                .setIn(['loaders', 'productionOrdersTable'], false);
        }
        case C.FETCH_PRODUCTION_ORDERS.success: {
            const orders = fromJS(action.payload.response.items) || fromJS([]);

            return state
                .set('productionOrdersById', toIdMap(orders, 'productId'))
                .set(
                    'totalProductionOrdersCount',
                    action.payload.response['totalItemsCount'],
                )
                .setIn(['loaders', 'productionOrdersTable'], false);
        }
        case C.PRINT_PRODUCTION_ORDERS.request: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');
            let productionOrdersPrintStatus = action.payload.response
                ? action.payload.response['fail']
                : [];

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else {
                const sendIds = getSendIds(action.payload.collection);

                productionOrdersById = setLoadingCollection(
                    productionOrdersById,
                    sendIds,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll)
                .set(
                    'productionOrdersPrintStatus',
                    productionOrdersPrintStatus,
                );
        }
        case C.PRINT_PRODUCTION_ORDERS.failure:
        case C.PRINT_PRODUCTION_ORDERS.success: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');
            let productionOrdersPrintStatus = action.payload.response
                ? action.payload.response['fail']
                : 'success';

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else {
                const sendIds = getSendIds(action.payload.collection);

                productionOrdersById = setLoadedCollection(
                    productionOrdersById,
                    sendIds,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll)
                .set(
                    'productionOrdersPrintStatus',
                    productionOrdersPrintStatus,
                );
        }

        case C.REPRINT_PRODUCTION_ORDERS.request: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else if (action.payload.collection) {
                productionOrdersById = setLoadingCollection(
                    productionOrdersById,
                    action.payload.collection,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll);
        }
        case C.REPRINT_PRODUCTION_ORDERS.failure:
        case C.REPRINT_PRODUCTION_ORDERS.success: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else if (action.payload.collection) {
                productionOrdersById = setLoadedCollection(
                    productionOrdersById,
                    action.payload.collection,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll);
        }

        case C.DOWNLOAD_PRODUCTION_ORDERS.request: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else {
                const sendIds = getSendIds(action.payload.collection);

                productionOrdersById = setLoadingCollection(
                    productionOrdersById,
                    sendIds,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll);
        }
        case C.DOWNLOAD_PRODUCTION_ORDERS.failure:
        case C.DOWNLOAD_PRODUCTION_ORDERS.success: {
            let loadingAll = false;
            let productionOrdersById = state.get('productionOrdersById');

            if (action.payload.collection === 'all') {
                loadingAll = true;
            } else {
                const sendIds = getSendIds(action.payload.collection);

                productionOrdersById = setLoadedCollection(
                    productionOrdersById,
                    sendIds,
                );
            }

            return state
                .set('productionOrdersById', productionOrdersById)
                .setIn(['loaders', 'productionOrdersTable'], loadingAll);
        }

        case C.SAVE_AS_PRESET.request: {
            return state.set('errors', '-').setIn(['loaders', 'presets'], true);
        }
        case C.SAVE_AS_PRESET.success: {
            return state.set('errors', '').setIn(['loaders', 'presets'], false);
        }
        case C.SAVE_AS_PRESET.failure: {
            const error = action.payload.error.response.data.name[0];

            return state
                .set('errors', error)
                .setIn(['loaders', 'presets'], false);
        }

        default:
            return state;
    }
}
