import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Loader } from 'components/Loader/Loader';
import Typography from 'components/Typography';
import ActionCells from '../ActionCell/actionCell';
import ExtendedRow from '../ExtendedRow/extendedRow';
import CheckBoxCell from '../CheckBoxCell/checkBoxCell';
import ExtendedCell from '../ExtendedCell/extendedCell';
import Cell from '../Cell/cell';
import { CollapseStyled, RootStyled } from 'components/OldTable/Row/row.styles';

const defaultCellPlaceholder = '---';

class Row extends React.PureComponent {
    handleSelect = (event) => {
        const { onSelect, idKey } = this.props;

        event && event.stopPropagation && event.stopPropagation();
        onSelect(idKey);
    };

    getExtendedRowComponent() {
        const {
            item,
            extendedRowComponent,
        } = this.props;

        if (typeof extendedRowComponent === 'function') {
            return extendedRowComponent(item);
        }

        return extendedRowComponent;
    }

    renderActionCell() {
        const {
            t,
            item,
            actions,
            idKey,
            edit,
            smallRows,
        } = this.props;

        if (edit && edit.isEditing) {
            return (
                <ActionCells
                    edit
                    small={ smallRows }
                    t={ t }
                    item={ item }
                    pickEdit={ edit.pick }
                    cancelEdit={ edit.cancel }
                    submitEdit={ edit.submit }
                />
            );
        }

        const {
            getListViewLink,
            detailsLink,
            handleDownload,
            showListView,
            showDetails,
            showHistory,
            uploadFiles,
            listViewDisabled,
            customActionButton,
            secondaryCustomActionButton,
            handlePrint,
            handleSend,
            handleRemove,
            canRemoveItem,
            handleCopy,
            copyLink,
            editLink,
        } = actions;
        const props = {
            t: t,
            item: item,
            small: smallRows,
        };

        if (Object.keys(actions).length > 0) {
            if (detailsLink) props.detailsLink = { pathname: `${ detailsLink.replace(/\/$/, '') }/${ idKey }` };
            if (getListViewLink) props.listViewLink = getListViewLink(item.get('id'));
            if (editLink) props.editLink = { pathname: `${ editLink.replace(/\/$/, '') }/${ idKey }/edit` };
            if (edit) props.pickEdit = edit.pick;
            if (handleDownload) props.handleDownload = () => handleDownload(idKey);
            if (showListView) props.showListView = () => showListView(item.get('id'));
            if (showDetails) props.showDetails = () => showDetails(item);
            if (showHistory) props.showHistory = () => showHistory(item);
            if (uploadFiles) props.uploadFiles = {
                function: () => uploadFiles(item),
                disabled: item.props['camundaAdditionalTasks'] ? item.props['camundaAdditionalTasks'].length === 0 : false,
            };
            if (listViewDisabled) props.listViewDisabled = listViewDisabled(item);
            if (customActionButton) props.customActionButton = {
                ...customActionButton,
                props: { item },
            };
            if (secondaryCustomActionButton) props.secondaryCustomActionButton = {
                ...secondaryCustomActionButton,
                props: { item },
            };
            if (handlePrint) props.handlePrint = () => handlePrint(idKey);
            if (handleSend) props.handleSend = () => handleSend(idKey);
            if (handleRemove) props.handleRemove = () => handleRemove(idKey);
            if (canRemoveItem) props.canRemoveItem = canRemoveItem(item);
            if (handleCopy) props.copyLink = {
                pathname: copyLink,
                state: {
                    item: handleCopy(item),
                },
            };

            return <ActionCells { ...props } />;
        }
    }

    renderCell(cell, index) {
        const {
            item,
            isExtended,
            smallRows,
            edit,
        } = this.props;

        const TypographyProps = {
            basic: true,
            small: smallRows,
            customCell: cell.customCell,
            isExtended: isExtended,
            variant: smallRows ? 'body2' : 'body1',
            align: cell.align || 'left',
            component: Cell,
        };

        const key = cell.label || index;

        if (edit && edit.isEditing && cell.editCell) {
            return (
                <Typography
                    key={ key }
                    { ...TypographyProps }
                >
                    { cell.editCell(edit.item, edit.changeItem) }
                </Typography>
            );
        }

        const renderedCell = cell.cell(item) || defaultCellPlaceholder;

        if (cell.withTitle || cell.title) {
            const title = cell.title || renderedCell;
            const titleText = typeof title === 'function' ? title(item) : title;

            return (
                <Typography
                    key={ key }
                    { ...TypographyProps }
                >
                    <Tooltip
                        title={ titleText && titleText !== defaultCellPlaceholder ? titleText : '' }
                        arrow
                    >
                        <div>
                            { renderedCell }
                        </div>
                    </Tooltip>
                </Typography>
            );
        }

        return (
            <Typography
                key={ key }
                { ...TypographyProps }
            >
                { renderedCell }
            </Typography>
        );
    }

    render() {
        const {
            t,
            id,
            dataId,
            item,
            cells,
            rowStyle,
            onClick,
            onSelect,
            isExtended,
            extendedRowComponent,
            customExtendedRowComponent,
            showExtendedIcon = () => true,
            smallRows,
            selected,
            disableSelected,
            edit,
        } = this.props;
        const extendedRow = this.getExtendedRowComponent();

        return (
            <RootStyled
                id={ id }
                data-id={ dataId }
                style={ rowStyle }
                leftPadding={ !onSelect && !(extendedRowComponent || customExtendedRowComponent) }
                smallRows={ smallRows }
            >
                { item.get('isLoading') && <Loader /> }
                { onSelect && (
                    <CheckBoxCell
                        small={ smallRows }
                        checked={ selected }
                        disabled={ disableSelected }
                        onClick={ this.handleSelect }
                    />
                ) }
                { (extendedRowComponent || customExtendedRowComponent) && (
                    <ExtendedCell
                        disabled={ !extendedRow && !customExtendedRowComponent }
                        show={ showExtendedIcon(item) }
                        small={ smallRows }

                        isExtended={ isExtended }
                        onClick={ onClick }
                    />
                ) }
                { cells.map((cell, index) => this.renderCell(cell, index)) }
                { this.renderActionCell() }
                { extendedRow && (
                    <CollapseStyled
                        in={ isExtended }
                    >
                        <ExtendedRow
                            t={ t }
                            item={ item }
                            sections={ extendedRow }
                        />
                    </CollapseStyled>
                ) }
                { customExtendedRowComponent && (
                    <CollapseStyled
                        in={ isExtended || edit && edit.isEditing }
                    >
                        { customExtendedRowComponent.content(
                            item,
                            edit && edit.isEditing,
                            Boolean(isExtended || edit && edit.isEditing),
                        ) }
                    </CollapseStyled>
                ) }
            </RootStyled>
        );
    }
}

Row.defaultProps = {
    t: (key) => key,
    actions: {},
};

Row.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    item: PropTypes.object.isRequired,
    actions: PropTypes.object,
    cells: PropTypes.array.isRequired,
    idKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowStyles: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    isExtended: PropTypes.bool,
    extendedRowComponent: PropTypes.array,
    customExtendedRowComponent: PropTypes.shape({
        content: PropTypes.func.isRequired,
    }),
    showExtendedIcon: PropTypes.func,
    smallRows: PropTypes.bool,
    edit: PropTypes.object,
    dataId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rowStyle: PropTypes.object.isRequired,
    selected: PropTypes.object,
    secondaryCustomActionButton: PropTypes.object,
    customActionButton: PropTypes.object,
    disableSelected: PropTypes.bool,
};

export default Row;
