import parser from 'qs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Product from '../models/Product';
import errorHandlers from '../errorHandlers';
import { mapFiltersToApi } from 'utils/filters';
import { HeadersAcceptType } from 'services/constants';

import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class Products {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
    }
    private paramsSerializer = (params) => parser.stringify(params);

    async getList({ filters = {}, per_page = 20, page = 1, sort = {} }) {
        const { ajax } = this.services;

        const { search, ...restFilters } = filters;
        const config = {
            params: {
                per_page,
                page,
                sort,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (!isEmpty(restFilters)) {
            const filtersParams = mapFiltersToApi(restFilters);

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }
        if (isEmpty(restFilters) && search) {
            const filtersParams = { filter: { name: { match: search } } };

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        try {
            const response = await ajax.get({
                url: this.URLS.GET_PRODUCTS,
                config,
            });
            const items = get(response, 'data.hydra:member', []);
            const totalItems = get(response, 'data.hydra:totalItems', []);

            return {
                items: items.map((product) => new Product(product)),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }
    async getById(id) {
        const { ajax, alert } = this.services;

        const onError = errorHandlers(alert).get;
        const errorConfig = { addGenericAlert: false };

        const response = await ajax.get({
            errorConfig,
            onError,
            url: this.URLS.GET_PRODUCT.replace('{id}', id),
        });

        return new Product(response.data);
    }
    async getCsvFile(
        { filters = {}, per_page = 100, page = 1, sort = {} },
        fileName,
    ) {
        const { ajax } = this.services;

        const config = {
            params: {
                per_page,
                page,
                sort,
            },
            headers: {
                Accept: HeadersAcceptType.csv,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (!isEmpty(filters)) {
            const filtersParams = mapFiltersToApi(filters);

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        return await ajax.download({
            url: this.URLS.GET_PRODUCTS_CSV_FILE,
            config,
            fileName,
        });
    }
}

export default Products;
