import SalesChannels from './actions/sales-channels/sales-channels.service';
import Companies from './actions/Companies';
import Offers from './actions/Offers';
import OfferProducts from './actions/OfferProducts';
import Platforms from './actions/Platforms';
import { OfferPriceLists } from './actions/offer-price-lists/offer-price-lists.actions';
import OfferProductSets from './actions/OfferProductSets';
import CustomFieldDefinition from './actions/CustomFieldDefinition';
import CustomFieldValue from './actions/CustomFieldValue';
import ChannelPreviews from 'services/salesChannels/actions/ChannelPreviews';
import { MediaFiles } from './actions/MediaFiles';
import PriceList from './actions/PriceList';
import { ChannelCategories } from './actions/ChannelCategories';
import { ChannelPatterns } from 'services/salesChannels/actions/ChannelPatterns';
import { ChannelTags } from 'services/salesChannels/actions/ChannelTags';
import { Colors } from 'services/salesChannels/actions/Colors';
import { CategoryDefinition } from 'services/salesChannels/actions/CategoryDefinition';
import Scope from './actions/Scope';
import type { AjaxService } from 'services/ajax/AjaxService';
import ParserService from 'qs';
import type { AlertService } from 'services/alert/AlertService';
import type { i18n } from 'i18next';
import type { default as errorHandlersFactory } from './errorHandlers';
import * as ServiceConstants from './constants';
import type {
    ApplicationJsonHeaders,
    HttpPatchHeaders,
} from 'services/constants';
import { Gtin } from './actions/gtin/Gtin';
import { ChannelIntermediateProductPreviews } from './actions/channelIntermediateProductPreviews/ChannelIntermediateProductPreviews';

export class SalesChannelsService {
    public readonly offers: Offers;
    public readonly platforms: Platforms;
    public readonly customFieldDefinition: CustomFieldDefinition;
    public readonly companies: Companies;
    public readonly salesChannels: SalesChannels;
    public readonly offerProducts: OfferProducts;
    public readonly offerProductSets: OfferProductSets;
    public readonly mediaFiles: MediaFiles;
    public readonly offerPriceLists: OfferPriceLists;
    public readonly customFieldValue: CustomFieldValue;
    public readonly channelPreviews: ChannelPreviews;
    public readonly priceList: PriceList;
    public readonly channelCategories: ChannelCategories;
    public readonly channelPatterns: ChannelPatterns;
    public readonly channelTags: ChannelTags;
    public readonly colors: Colors;
    public readonly categoryDefinition: CategoryDefinition;
    public readonly scope: Scope;
    public readonly gtin: Gtin;
    public readonly intermediateProductPreviews: ChannelIntermediateProductPreviews;

    public readonly ajax: AjaxService;
    public readonly parser: typeof ParserService;
    public readonly alert: AlertService;
    public readonly translator: i18n;
    public readonly errorHandlers: ReturnType<typeof errorHandlersFactory>;

    public readonly URLS: (typeof ServiceConstants)['URLS'];
    public readonly HEADERS: {
        basic: typeof ApplicationJsonHeaders;
        patch: typeof HttpPatchHeaders;
    };
    public readonly ALERT_MESSAGES: (typeof ServiceConstants)['ALERT_MESSAGES'];
    public readonly DEFAULTS: (typeof ServiceConstants)['DEFAULTS'];
    public readonly SALES_CHANNELS_PARAMS: (typeof ServiceConstants)['SALES_CHANNELS_PARAMS'];
    public readonly COMPANIES_PARAMS: (typeof ServiceConstants)['COMPANIES_PARAMS'];
    public readonly OFFERS_PARAMS: (typeof ServiceConstants)['OFFERS_PARAMS'];
    public readonly OFFER_ITEMS_PARAMS: (typeof ServiceConstants)['OFFER_ITEMS_PARAMS'];
    public readonly OFFER_PRODUCTS_PARAMS: (typeof ServiceConstants)['OFFER_PRODUCTS_PARAMS'];
    public readonly OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS: (typeof ServiceConstants)['OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS'];

    private constructor({
        services,
        constants,
        errorHandlers,
    }: {
        services: {
            ajax: AjaxService;
            parser: typeof ParserService;
            alerts: AlertService;
            translator: i18n;
        };
        constants: Omit<
            typeof ServiceConstants,
            'OFFER_PRODUCT_STATUSES' | 'OFFER_PRODUCTS_FILTER_MAP'
        > & {
            HEADERS: {
                basic: typeof ApplicationJsonHeaders;
                patch: typeof HttpPatchHeaders;
            };
        };
        errorHandlers: ReturnType<typeof errorHandlersFactory>;
    }) {
        this.ajax = services.ajax;
        this.parser = services.parser;
        this.alert = services.alerts;
        this.translator = services.translator;
        this.errorHandlers = errorHandlers;

        this.URLS = constants.URLS;
        this.HEADERS = constants.HEADERS;
        this.ALERT_MESSAGES = constants.ALERT_MESSAGES;
        this.DEFAULTS = constants.DEFAULTS;
        this.SALES_CHANNELS_PARAMS = constants.SALES_CHANNELS_PARAMS;
        this.COMPANIES_PARAMS = constants.COMPANIES_PARAMS;
        this.OFFERS_PARAMS = constants.OFFERS_PARAMS;
        this.OFFER_ITEMS_PARAMS = constants.OFFER_ITEMS_PARAMS;
        this.OFFER_PRODUCTS_PARAMS = constants.OFFER_PRODUCTS_PARAMS;
        this.OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS =
            constants.OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS;

        this.salesChannels = new SalesChannels(this);
        this.companies = new Companies(this);
        this.platforms = new Platforms(this);
        this.offers = new Offers(this);
        this.offerProducts = new OfferProducts(this);
        this.offerProductSets = new OfferProductSets(this);
        this.mediaFiles = new MediaFiles(this);
        this.offerPriceLists = OfferPriceLists.getInstance(this);
        this.customFieldDefinition = new CustomFieldDefinition(this);
        this.customFieldValue = new CustomFieldValue(this);
        this.channelPreviews = new ChannelPreviews(this);
        this.priceList = new PriceList(this);
        this.channelCategories = new ChannelCategories(this);
        this.channelPatterns = new ChannelPatterns(this);
        this.channelTags = new ChannelTags(this);
        this.colors = new Colors(this);
        this.categoryDefinition = new CategoryDefinition(this);
        this.scope = new Scope(this);
        this.gtin = new Gtin(this);
        this.intermediateProductPreviews =
            new ChannelIntermediateProductPreviews(this);

        this.getSortParams = this.getSortParams.bind(this);
        this.getFilterParams = this.getFilterParams.bind(this);
        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    private static instance: SalesChannelsService | undefined;

    public static getInstance({
        services,
        constants,
        errorHandlers,
    }: {
        services: {
            ajax: AjaxService;
            parser: typeof ParserService;
            alerts: AlertService;
            translator: i18n;
        };
        constants: Omit<
            typeof ServiceConstants,
            'OFFER_PRODUCT_STATUSES' | 'OFFER_PRODUCTS_FILTER_MAP'
        > & {
            HEADERS: {
                basic: typeof ApplicationJsonHeaders;
                patch: typeof HttpPatchHeaders;
            };
        };
        errorHandlers: ReturnType<typeof errorHandlersFactory>;
    }): SalesChannelsService {
        if (!SalesChannelsService.instance) {
            SalesChannelsService.instance = new SalesChannelsService({
                services,
                constants,
                errorHandlers,
            });
        }

        return SalesChannelsService.instance;
    }

    getSortParams(sortingFields = [], order = {}, newTable = false) {
        let orders = order;

        if (Array.isArray(orders) && newTable) {
            orders = order.reduce((prev, curr) => {
                const values = Object.values(curr);

                return {
                    ...prev,
                    [values[0]]: values[1],
                };
            }, {});
        }

        return sortingFields.reduce((params, orderKey) => {
            if (orders[orderKey]) {
                params[orderKey] = orders[orderKey];
            }

            return params;
        }, {});
    }

    getFilterParams(filterFields = [], filters = {}) {
        return filterFields.reduce((params, filterKey) => {
            if (filters[filterKey]) {
                params[filterKey] = filters[filterKey];
            }

            return params;
        }, {});
    }

    paramsSerializer = (params: any) => this.parser.stringify(params);
}
