import { getNamespaces } from '../helpers/getNamespaces';
import { setLoaded, setLoading } from 'utils/immutable/map';

const defaultNamespaces = {
    downloadFileLoader: 'downloadFileLoader',
};

export function request({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const { id } = action.payload;
        const item = state.getIn([namespaces.itemsById, id]);

        return state
            .set(namespaces.downloadFileLoader, true)
            .setIn([namespaces.itemsById, id], setLoading(item));
    };
}

export function failure({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action }) {
        const { id } = action.payload;
        const item = state.getIn([namespaces.itemsById, id]);

        return state
            .set(namespaces.downloadFileLoader, false)
            .setIn([namespaces.itemsById, id], setLoaded(item));
    };
}

export function success({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function ({ state, action, responseModel }) {
        const { id } = action.payload;
        const item = state.getIn([namespaces.itemsById, id]);

        responseModel.downloadFile(action);

        return state
            .set(namespaces.downloadFileLoader, false)
            .setIn([namespaces.itemsById, id], setLoaded(item));
    };
}
