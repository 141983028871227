import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import withServices from 'hoc/withServices/withServices';

class DrawerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawer: null,
        };
    }
    openDrawer = (drawer) => {
        this.setState({ drawer });
    };
    closeDrawer = () => {
        this.setState({ drawer: null });
    };
    componentDidMount() {
        this.props.services.drawer.listen('open', this.openDrawer);
        this.props.services.drawer.listen('close', this.closeDrawer);
    }
    componentWillUnmount() {
        this.props.services.drawer.unlisten('open', this.openDrawer);
        this.props.services.drawer.unlisten('close', this.closeDrawer);
    }
    render() {
        const { drawer } = this.state;

        if (!drawer) return null;

        return (
            <Drawer
                {...drawer}
                close={this.closeDrawer}
            />
        );
    }
}

DrawerContainer.propTypes = {
    services: PropTypes.shape({
        drawer: PropTypes.shape({
            listen: PropTypes.func.isRequired,
            unlisten: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
};

const DrawerContainerWithHOC = withServices(DrawerContainer);

export default DrawerContainerWithHOC;
