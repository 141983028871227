import { MODULE_NAME } from '../constants';
import { lazy } from 'utils/codeSplitting';

const TusTestPage = lazy(() => import('../pages/TusTestPage/TusTestPage'));
const FileUploaderPage = lazy(() =>
    import('../pages/FileUploaderPage/FileUploaderPage'),
);
const ModalExample = lazy(() => import('../components/ModalExample/modalExample'));
const MultiFileDownloaderExample = lazy(() =>
    import('../components/MultiFileDownloaderExample/multiFileDownloaderExample'),
);
const LoaderExample = lazy(() => import('../components/LoaderExample/loaderExample'));
const AlertExample = lazy(() => import('../containers/AlertExample/alertExampleContainer'));
const TextFieldsExample = lazy(() => import('../components/TextFieldsExample/textFieldsExample'));
const PaperExample = lazy(() => import('../components/PaperExample/paperExample'));
const ButtonsExample = lazy(() => import('../components/ButtonsExample/buttonsExample'));
const BreadcrumbsExample = lazy(() =>
    import('../components/BreadcrumbsExample/BreadcrumbsExample'),
);
const BadgesExample = lazy(() =>
    import('../components/BadgesExample/BadgesExample'),
);
const TypographyExample = lazy(() => import('../components/TypographyExample/typographyExample'));
const TabsExample = lazy(() => import('../components/TabsExample/tabsExample'));
const TablePage = lazy(() => import('../pages/TablePage/TablePage'));
const FetchTablePage = lazy(() => import('../pages/FetchTablePage/FetchTablePage'));
const TableViewPage = lazy(() => import('../pages/TableViewPage/TableViewPage'));
const PatternPreviewPage = lazy(() => import('../pages/PatternPreviewPage/patternPreviewPage'));
const VerticalTabsPage = lazy(() => import('../pages/VerticalTabsPage/VerticalTabsPage'));
const TypographyWithTranslationPage = lazy(() =>
    import('../pages/TypographyWithTranslationPage/TypographyWithTranslationPage'),
);
const IconListPage = lazy(() => import('../pages/IconListPage/IconListPage'));
const InvokeErrorContainer = lazy(() =>
    import('../containers/InvokeErrorContainer/InvokeErrorContainer'),
);
const DatePickerExampleContainer = lazy(() =>
    import(
        '../containers/DatePickerExampleContainer/DatePickerExampleContainer'
    ),
);
const DateRangePickerExampleContainer = lazy(() =>
    import(
        '../containers/DateRangePickerExampleContainer/DateRangePickerExampleContainer'
    ),
);
const ErrorBoundaryExample = lazy(() =>
    import('../components/ErrorBoundaryExample/ErrorBoundaryExample'),
);
const DataGridProExample = lazy(() =>
    import('../containers/DataGridProExample/DataGridProExample'),
);
const CamundaFormFieldsExample = lazy(() =>
    import('../containers/CamundaFormFieldsExample/CamundaFormFieldsExample'),
);
const NotesModalExample = lazy(() =>
    import('../components/notesModalExample/NotesModalExample').then((m) => ({
        default: m.NotesModalExample,
    })),
);
const DynamicLoadErrorExample = lazy(() =>
    import('../components/DynamicLoadError').then((module) => ({
        default: module.DynamicLoadError,
    })),
);
const DynamicFormExample = lazy(() =>
    import('../components/dynamicFormExample/DynamicFormExample').then(
        (module) => ({ default: module.DynamicFormExample }),
    ),
);

export default function getRouting() {
    const styleGuidePath = `/${MODULE_NAME}/style-guide`;

    return {
        name: MODULE_NAME,
        forceAccess: true,
        path: `/${MODULE_NAME}`,
        routes: [
            {
                name: 'FileUploader Test',
                path: `/${MODULE_NAME}/fileuploader-test`,
                exact: true,
                component: FileUploaderPage,
            },
            {
                name: 'TUS Test',
                path: `/${MODULE_NAME}/tus-test`,
                exact: true,
                component: TusTestPage,
            },
            {
                name: 'Breadcrumbs',
                path: `${styleGuidePath}/breadcrumbs`,
                exact: true,
                component: BreadcrumbsExample,
            },
            {
                name: 'Alerts',
                path: `${styleGuidePath}/alerts`,
                exact: true,
                component: AlertExample,
            },
            {
                name: 'TextFields',
                path: `${styleGuidePath}/text-fields`,
                exact: true,
                component: TextFieldsExample,
            },
            {
                name: 'Buttons',
                path: `${styleGuidePath}/buttons`,
                exact: true,
                component: ButtonsExample,
            },
            {
                name: 'Badges',
                path: `${styleGuidePath}/badges`,
                exact: true,
                component: BadgesExample,
            },
            {
                name: 'Icons',
                path: `${styleGuidePath}/icons`,
                exact: true,
                component: IconListPage,
            },
            {
                name: 'Paper',
                path: `${styleGuidePath}/paper`,
                exact: true,
                component: PaperExample,
            },
            {
                name: 'Modal',
                path: `${styleGuidePath}/modal`,
                component: ModalExample,
            },
            {
                name: 'Multi File Downloader',
                path: `${styleGuidePath}/multi-file-downloader`,
                component: MultiFileDownloaderExample,
            },
            {
                name: 'Loader',
                path: `${styleGuidePath}/loader`,
                component: LoaderExample,
            },
            {
                name: 'Typography',
                path: `${styleGuidePath}/typography`,
                component: TypographyExample,
            },
            {
                name: 'Tabs',
                path: `${styleGuidePath}/tabs`,
                component: TabsExample,
            },
            {
                name: 'Table',
                path: `${styleGuidePath}/table`,
                component: TablePage,
            },
            {
                name: 'Fetch Table',
                path: `${styleGuidePath}/fetch-table`,
                component: FetchTablePage,
            },
            {
                name: 'Table View',
                path: `${styleGuidePath}/table-view`,
                component: TableViewPage,
            },
            {
                name: 'Pattern Preview',
                path: `${styleGuidePath}/pattern-preview`,
                exact: true,
                component: PatternPreviewPage,
            },
            {
                name: 'VerticalTabs',
                path: `${styleGuidePath}/vertical-tabs`,
                exact: true,
                component: VerticalTabsPage,
            },
            {
                name: 'TWTranslation',
                path: `${styleGuidePath}/typogprahy-with-translation`,
                exact: true,
                component: TypographyWithTranslationPage,
            },
            {
                name: 'Invoke Error',
                path: `${styleGuidePath}/invoke-error`,
                exact: true,
                component: InvokeErrorContainer,
            },
            {
                name: 'Date Picker',
                path: `${styleGuidePath}/date-picker`,
                exact: true,
                component: DatePickerExampleContainer,
            },
            {
                name: 'Date Range Picker',
                path: `${styleGuidePath}/date-range-picker`,
                exact: true,
                component: DateRangePickerExampleContainer,
            },
            {
                name: 'Error Boundary',
                path: `${styleGuidePath}/error-boundary`,
                exact: true,
                component: ErrorBoundaryExample,
            },
            {
                name: 'Data Grid Pro',
                path: `${styleGuidePath}/data-grid-pro`,
                exact: true,
                component: DataGridProExample,
            },
            {
                name: 'Camunda Form Fields',
                path: `${styleGuidePath}/camunda-form-fields`,
                exact: true,
                component: CamundaFormFieldsExample,
            },
            {
                name: 'Notes Modal',
                path: `${styleGuidePath}/notes-modal`,
                exact: true,
                component: NotesModalExample,
            },
            {
                name: 'DynamicLoadError',
                path: `${styleGuidePath}/dynamic-load-error`,
                exact: true,
                component: DynamicLoadErrorExample,
            },
            {
                name: 'DynamicForm',
                path: `${styleGuidePath}/dynamic-form`,
                exact: true,
                component: DynamicFormExample,
            },
        ],
    };
}
