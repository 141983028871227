import { goBack, push, replace } from 'react-router-redux';
import type { default as qs } from 'qs';
import type { Store } from 'redux';

export class RouterService {
    private readonly store: Store;
    private readonly parser: typeof qs;

    private constructor({
        parser,
        store,
    }: {
        parser: typeof qs;
        store: Store;
    }) {
        this.parser = parser;
        this.store = store;
        this.getSearch = this.getSearch.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.goBack = this.goBack.bind(this);
        this.push = this.push.bind(this);
        this.replace = this.replace.bind(this);
        this.getReferrerPath = this.getReferrerPath.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    private static instance: RouterService | undefined;

    public static getInstance({
        parser,
        store,
    }: {
        parser: typeof qs;
        store: Store;
    }): RouterService {
        if (!RouterService.instance) {
            RouterService.instance = new RouterService({ parser, store });
        }

        return RouterService.instance;
    }

    getSearch(parsed = true) {
        const searchString =
            this.store.getState().getIn(['router', 'location', 'search']) || '';

        return parsed
            ? this.parser.parse(searchString.substr(1, searchString.length)) ||
                  {}
            : searchString.substr(1, searchString.length) || '';
    }

    getLocation() {
        return this.store.getState().getIn(['router', 'location']).toJS() || {};
    }

    goBack() {
        return this.store.dispatch(goBack());
    }

    push(data: unknown, state?: unknown) {
        return this.store.dispatch(push(data, state));
    }

    replace(data, state) {
        return this.store.dispatch(replace(data, state));
    }

    clearSearch() {
        return this.store.dispatch(push({}));
    }

    getReferrerPath({ defaultPath }) {
        return (
            this.store
                .getState()
                .getIn(['router', 'location', 'state', 'referrer']) ||
            defaultPath
        );
    }
}
