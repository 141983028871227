import { MODULE_NAME } from '../constants';

export const PROPERTIES = `/${MODULE_NAME}/properties`;
export const PROPERTIES_DETAILS = `/${MODULE_NAME}/properties/:id`;

export const INTERMEDIATE_PRODUCTS = `/${MODULE_NAME}/intermediate-products`;
export const INTERMEDIATE_PRODUCTS_DETAILS = `/${MODULE_NAME}/intermediate-products/:id`;

export const PRODUCTS = `/${MODULE_NAME}/products`;

export const TECHNOLOGIES = `/${MODULE_NAME}/technologies`;
export const TECHNOLOGIES_DETAILS = `/${MODULE_NAME}/technologies/:id`;

export const TECHNOLOGIES_GROUPS = `/${MODULE_NAME}/technologies-groups`;
export const TECHNOLOGIES_GROUPS_DETAILS = `/${MODULE_NAME}/technologies-groups/:id`;

export const PRINT_SPEC = `/${MODULE_NAME}/print-spec`;
export const PRINT_SPEC_DETAILS = `/${MODULE_NAME}/print-spec/:id`;

export const PRODUCT_SET = `/${MODULE_NAME}/product-set`;
export const PRODUCT_SET_DETAILS = `/${MODULE_NAME}/product-set/:id`;
export const PRODUCT_SET_TYPES = `/${MODULE_NAME}/product-set-types`;
export const PRODUCT_SET_PREVIEW_TYPES = `/${MODULE_NAME}/product-set-preview-types`;
export const PRODUCT_SET_PREVIEW_TYPES_DETAILS = `/${MODULE_NAME}/product-set-preview-types/:id`;
export const PRODUCT_SET_PREVIEWS = `/${MODULE_NAME}/product-set-previews`;

export const EAN_POOLS = `/${MODULE_NAME}/ean-pools`;
export const EAN_POOL_DETAILS = `/${MODULE_NAME}/ean-pools/:id`;
export const EAN_POOL_GTINS = `/${MODULE_NAME}/ean-pools/:id/gtins`;

export const EXTERNAL_PRODUCERS = `/${MODULE_NAME}/external-producers`;
export const EXTERNAL_PRODUCER_DETAILS = `/${MODULE_NAME}/external-producers/:id`;
