import React from 'react';
import * as PropTypes from 'prop-types';
import withServices from 'hoc/withServices/withServices';
import { getActiveLanguage } from 'i18n';
import { withTranslation } from 'react-i18next';
import Tos from 'modules/pdp/models/Tos';
import { Loader } from 'components/Loader/Loader';
import Button from 'components/Button/button';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import ModalComponent from 'modules/pdp/components/TosModal/TosModal';
import { LogoutButtonStyled } from 'modules/app/components/Gatekeeper/tosModal.styles';

class TosModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tos: new Tos(),
            fetchError: false,
            userId: null,
        };
    }

    componentDidMount() {
        this.fetchTos();
        this.getUserData();
    }

    setLoader(state = false) {
        this.setState({ loading: state });
    }

    getUserData() {
        const {
            services: { profile },
        } = this.props;

        this.setState({ fetchError: false });
        profile
            .getMe()
            .then((data) => {
                this.setState({ userId: data.id });
            })
            .catch(() => {
                this.setState({ fetchError: true });
            });
    }

    fetchTos() {
        const {
            id,
            services: { pdp },
        } = this.props;

        this.setLoader(true);
        this.setState({ fetchError: false });
        pdp.tos
            .getById({ id })
            .then((result) => {
                this.setLoader();
                this.setState({ tos: new Tos(result) });
            })
            .catch(() => {
                this.setLoader();
                this.setState({ fetchError: true });
            });
    }

    retryFetch = () => {
        this.fetchTos();
        this.getUserData();
    };

    downloadAttachment = (contentId, attachment) => {
        const {
            services: { pdp },
        } = this.props;
        const { id, name: fileName } = attachment;

        this.setLoader(true);
        pdp.tos
            .downloadAttachment({ contentId, id, fileName })
            .then(() => {
                this.setLoader();
            })
            .catch(() => {
                this.setLoader();
            });
    };

    acceptTos = () => {
        const {
            close,
            services: { pdp },
        } = this.props;
        const { tos } = this.state;
        const tosId = tos.getId();

        pdp.tos.accept({ tosId }).then(() => {
            close({});
        });
    };

    logout = () => {
        const { close } = this.props;

        close({ logout: true });
    };

    render() {
        const { t } = this.props;
        const { tos, loading, userId, fetchError } = this.state;
        const {
            title,
            content,
            attachments = [],
            contentId,
        } = tos.getTranslatedTos(getActiveLanguage()) || {};

        return (
            <ModalComponent
                t={t}
                title={title}
                content={content}
                attachments={attachments}
                contentId={contentId}
                loading={loading}
                fetchError={fetchError}
                retryFetch={this.retryFetch}
                downloadAttachment={this.downloadAttachment}
                buttons={
                    <React.Fragment>
                        <LanguagePicker />
                        <div>
                            <LogoutButtonStyled
                                variant="text"
                                color="primary"
                                onClick={this.logout}
                            >
                                {t('common:actions.logout')}
                            </LogoutButtonStyled>
                            <Button
                                disabled={!userId}
                                color="secondary"
                                onClick={this.acceptTos}
                            >
                                {t('common:actions.accept')}
                                {!userId && <Loader />}
                            </Button>
                        </div>
                    </React.Fragment>
                }
            />
        );
    }
}

TosModal.defaultProps = {
    t: (key) => key,
};

TosModal.propTypes = {
    t: PropTypes.func,
    services: PropTypes.shape({
        pdp: PropTypes.shape({
            tos: PropTypes.shape({
                accept: PropTypes.func.isRequired,
                downloadAttachment: PropTypes.func.isRequired,
                getById: PropTypes.func.isRequired,
            }).isRequired,
        }).isRequired,
        profile: PropTypes.shape({
            getMe: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
};

const TosModalWithHOC = withTranslation('app')(withServices(TosModal));

export default TosModalWithHOC;
