import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function PauseIcon(props) {
    return (
        <SvgIcon
            viewBox="-5 -5 16 16"
            { ...props }
        >
            <path
                d="M1 0C0.4475 0 0 0.4475 0 1V6C0 6.5525 0.4475 7 1 7C1.5525 7 2 6.5525 2 6V1C2 0.4475 1.5525 0 1 0ZM5 0C4.4475 0 4 0.4475 4 1V6C4 6.5525 4.4475 7 5 7C5.5525 7 6 6.5525 6 6V1C6 0.4475 5.5525 0 5 0Z"
            />
        </SvgIcon>
    );
}

PauseIcon.defaultProps = {
    titleAccess: 'pause-icon',
};

PauseIcon.propTypes = svgIconPropTypes;
