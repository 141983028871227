import React from 'react';
import PropTypes from 'prop-types';
import { ChipStyled, StackStyled } from './AutocompleteChip.styles';

export const AutocompleteChip = ({
    value,
    getTagProps,
    getOptionLabel = (option) => option.label ?? option,
}) => (
    <StackStyled direction="row">
        {value.map((option, index) => {
            return (
                // Key provided by getTagProps
                // eslint-disable-next-line react/jsx-key
                <ChipStyled
                    {...getTagProps({ index })}
                    size="small"
                    label={getOptionLabel(option)}
                />
            );
        })}
    </StackStyled>
);

AutocompleteChip.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ).isRequired,
    getTagProps: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func,
};
