import { get } from 'lodash';

import { configError, paramsSerializer } from 'services/utils/utils';
import { mapGetListFilters, mapSourcesGetList } from '../mappers/sources';
import { keysCamelToSnake } from '../../../utils/mappers';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';

class Sources {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly DEFAULTS: IStockServiceConst['DEFAULTS'];
    private readonly HEADERS: IStockServiceConst['HEADERS'];
    private readonly APP_ALERTS: IStockServiceConst['APP_ALERTS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = stockService.URLS;
        this.DEFAULTS = stockService.DEFAULTS;
        this.HEADERS = stockService.HEADERS;
        this.APP_ALERTS = stockService.APP_ALERTS;
        this.errorHandlers = stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.getListAutocomplete = this.getListAutocomplete.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.download = this.download.bind(this);
    }

    async create(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const url = URLS.POST_SOURCE;
        const config = { headers: HEADERS.basic };
        const errorConfig = configError('post', errorHandlers);

        const response = await ajax.post({ url, config, errorConfig, data });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.CREATE_SOURCE_SUCCESS),
        });

        return response.data;
    }

    async edit(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const { id, ...formData } = data;

        const url = URLS.PATCH_SOURCE.replace('{id}', id);
        const config = { headers: HEADERS.patch };
        const errorConfig = configError('patch', errorHandlers);

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: formData,
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.EDIT_SOURCE_SUCCESS),
        });

        return response.data;
    }

    async getList({
        sort = {},
        filters = {},
        per_page = this.DEFAULTS.SOURCES_LIST_PER_PAGE,
        page = 1,
    }) {
        const { ajax } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_SOURCES;
        const params = {
            ...mapGetListFilters(filters),
            order: keysCamelToSnake(sort),
            page,
            per_page,
        };
        const config = {
            params,
            headers: HEADERS.basic,
            paramsSerializer,
        };
        const errorConfig = configError('get', errorHandlers);

        const { data } = await ajax.get({ url, config, errorConfig });

        return mapSourcesGetList(data);
    }

    getListAutocomplete({
        filters,
        per_page = this.DEFAULTS.SOURCES_AUTOCOMPLETE_PER_PAGE,
        page = 1,
        caches = true,
    }) {
        const { ajax, requestCache } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_SOURCES;
        const params = {
            ...filters,
            page,
            per_page,
        };
        const config = { params, headers: HEADERS.basic };
        const request = requestCache.getRequest(url, params);
        const onError = errorHandlers.get;

        if (!request || !caches) {
            const newRequest = ajax
                .get({ url, config, onError })
                .then((response) => {
                    return get(response, 'data.results', []);
                });

            if (caches) {
                requestCache.setRequest(url, params, newRequest);
            }

            return newRequest;
        }

        return request;
    }

    async getById(id) {
        const { ajax } = this.services;
        const { URLS, errorHandlers } = this;

        const url = URLS.GET_SOURCE.replace('{id}', id);
        const errorConfig = configError('get', errorHandlers);

        const response = await ajax.get({ url, errorConfig });

        return response.data;
    }

    async delete(id) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, errorHandlers } = this;

        const url = URLS.DELETE_SOURCE.replace('{id}', id);
        const errorConfig = configError('delete', errorHandlers);

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.DELETE_SOURCE_SUCCESS),
        });

        return response.data;
    }

    async download({ fileName, fileId }) {
        const { ajax } = this.services;
        const { URLS, errorHandlers } = this;

        const url = URLS.DOWNLOAD_SOURCE.replace('{id}', fileId);
        const errorConfig = configError('download', errorHandlers);

        return await ajax.download({ url, fileName, errorConfig });
    }
}

export default Sources;
