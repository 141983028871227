import qs from 'qs';
import { MODULE_NAME } from '../constants';

export const SHIPMENTS = `/${MODULE_NAME}/shipments`;
export const DELIVERED_SHIPMENTS = `/${MODULE_NAME}/delivered-shipments`;
export const CUSTOM_SHIPMENTS = `/${MODULE_NAME}/custom-shipments`;
export const CUSTOM_SHIPMENTS_DETAILS = `${CUSTOM_SHIPMENTS}/:wzServiceCode`;
export const DELIVERY_NOTES = `/${MODULE_NAME}/delivery-notes`;
export const SHIPMENT_VERIFICATION = `/${MODULE_NAME}/shipment-verification`;
export const SHIPMENT_VERIFICATION_DETAILS = `/${MODULE_NAME}/shipment-verification/:id/edit`;
export const CARRIER_RULE_MANAGEMENT = `/${MODULE_NAME}/rule-management`;
export const CARRIER_RULE_DETAILS = `${CARRIER_RULE_MANAGEMENT}/:id`;

export const CARRIER_RULE_MANAGEMENT_WITH_SORT = {
    pathname: CARRIER_RULE_MANAGEMENT,
    search: qs.stringify({
        sort: [{ field: 'updatedAt', sort: 'desc' }],
        page: 1,
    }),
};
