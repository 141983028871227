import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    loginUser,
    requestChangePassword,
} from 'modules/app/reducers/userProfile/actions';
import {
    selectLoginError, selectRequestChangePasswordError,
    selectUserProfileLoaders,
} from 'modules/app/reducers/userProfile/selectors';
import LoginContent from 'modules/app/components/LoginContent/LoginContent';

const mapStateToProps = (state) => {
    return {
        loading: selectUserProfileLoaders(state, 'loginForm'),
        loginError: selectLoginError(state),
        requestChangePasswordError: selectRequestChangePasswordError(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(loginUser.request(data)),
        requestChangePassword: (data) => dispatch(requestChangePassword.request(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('app')(LoginContent));
