/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { checkAccess, checkAccessArray } from 'access/votingMechanism';
import PropTypes from 'prop-types';
import NotFound from 'modules/app/pages/NotFound/NotFound';
import { DEV_ENV_NAME } from 'utils/enviromentNames';

export class ModuleSwitch extends React.PureComponent {
    checkRouteAccess = (path) => {
        if (Array.isArray(path)) {
            return checkAccessArray(path);
        }

        return checkAccess(path);
    };

    renderRoute(index, route) {
        const { module } = this.props;

        return route.routes ? (
            <Route
                key={index}
                path={route.path}
            >
                <ModuleSwitchWithRouter
                    module={{ ...route, forceAccess: module.forceAccess }}
                />
            </Route>
        ) : (
            <Route
                {...route}
                key={index}
            />
        );
    }

    render() {
        const { module, isSubmodule } = this.props;

        return (
            <Switch>
                {!isSubmodule && (
                    <Redirect
                        exact
                        path={module.path}
                        to={module.routes[0].path}
                    />
                )}
                {module.routes.map((route, index) => {
                    const isDevEnv = window?.env?.APP_ENV === DEV_ENV_NAME;
                    const showOnlyOnTestEnv = !(
                        module.testOnly || route.testOnly
                    );

                    if (isDevEnv ? true : showOnlyOnTestEnv) {
                        const forceAccess =
                            module.forceAccess || route.forceAccess;

                        if (forceAccess || this.checkRouteAccess(route.path)) {
                            return this.renderRoute(index, route);
                        }
                    }
                })}
                {Array.isArray(module.submodules) &&
                    module.submodules.map((submodule) => (
                        <ModuleSwitch
                            key={submodule.name}
                            module={submodule}
                            isSubmodule
                        />
                    ))}
                <Route component={NotFound} />
            </Switch>
        );
    }
}

const ModuleSwitchWithRouter = withRouter(ModuleSwitch);

export default ModuleSwitchWithRouter;

const rotesPropTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    forceAccess: PropTypes.bool,
    testOnly: PropTypes.bool,
};

ModuleSwitch.propTypes = {
    module: PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        routes: PropTypes.arrayOf(
            PropTypes.shape({
                ...rotesPropTypes,
                routes: PropTypes.arrayOf(PropTypes.shape(rotesPropTypes)),
            }),
        ).isRequired,
        icon: PropTypes.node,
        testOnly: PropTypes.bool,
        forceAccess: PropTypes.bool,
        submodules: PropTypes.arrayOf(PropTypes.object),
    }),
    isSubmodule: PropTypes.bool,
};
