import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        GROUP: {
            FETCH: `${MODULE_NAME}_resources_group_fetch`,
        },
        ROLE: {
            FETCH: `${MODULE_NAME}_resources_role_fetch`,
        },
        USER: {
            FETCH: `${MODULE_NAME}_resources_user_fetch`,
            CREATE: `${MODULE_NAME}_resources_user_create`,
            DELETE: `${MODULE_NAME}_resources_user_delete`,
            EDIT: `${MODULE_NAME}_resources_user_edit`,
        },
        TOS: {
            FETCH: `${MODULE_NAME}_resources_tos_fetch`,
            CREATE: `${MODULE_NAME}_resources_tos_create`,
            DELETE: `${MODULE_NAME}_resources_tos_delete`,
            EDIT: `${MODULE_NAME}_resources_tos_edit`,
        },
        POSITION: {
            FETCH: `${MODULE_NAME}_resources_position_fetch`,
            CREATE: `${MODULE_NAME}_resources_position_create`,
            DELETE: `${MODULE_NAME}_resources_position_delete`,
            EDIT: `${MODULE_NAME}_resources_position_edit`,
        },
        ROLE_GROUPS: {
            FETCH: `${MODULE_NAME}_resources_role_groups_fetch`,
            CREATE: `${MODULE_NAME}_resources_role_groups_create`,
            DELETE: `${MODULE_NAME}_resources_role_groups_delete`,
            EDIT: `${MODULE_NAME}_resources_role_groups_edit`,
        },
    },
};
