import parser from 'qs';
import { ApplicationJsonHeaders } from '../constants';
import errorHandlersConfig from './errorHandlers';
import { URLS } from './constants';
import { StabloService } from './StabloService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const stabloServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    StabloService.getInstance({
        services: { ajax: ajaxService, alert: alertService, parser },
        URLS,
        errorHandlersConfig,
        CONSTANTS: {
            URLS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
            },
        },
    });
