import { useTranslation } from 'react-i18next';
import { IWorkplace } from 'services/workplaces/actions/workplace.type';
import { PrinterItem, type PrinterItemProps } from './PrinterItem';

interface PrinterListProps {
    printerData:
        | Partial<
              Pick<
                  IWorkplace,
                  | 'contractor_documents_a4'
                  | 'production_orders_a4'
                  | 'ean_brother'
                  | 'termical'
              >
          >
        | undefined;
    canPrint: PrinterItemProps['canPrint'];
    handlePrintout: PrinterItemProps['handlePrintout'];
}

export const PrinterList = ({
    printerData,
    canPrint,
    handlePrintout,
}: PrinterListProps) => {
    const { t } = useTranslation(['workplaces']);

    return (
        <>
            {printerData?.production_orders_a4 && (
                <PrinterItem
                    name={t('labels.printers.laser', { ns: 'workplaces' })}
                    code={printerData.production_orders_a4.code}
                    canPrint={canPrint}
                    handlePrintout={handlePrintout}
                    active={
                        printerData.production_orders_a4.status === 'active'
                    }
                />
            )}

            {printerData?.termical && (
                <PrinterItem
                    name={t('labels.printers.thermal', { ns: 'workplaces' })}
                    code={printerData.termical.code}
                    canPrint={canPrint}
                    handlePrintout={handlePrintout}
                    active={printerData.termical.status === 'active'}
                />
            )}
            {printerData?.ean_brother && (
                <PrinterItem
                    name={t('labels.printers.ean', { ns: 'workplaces' })}
                    code={printerData.ean_brother.code}
                    canPrint={canPrint}
                    handlePrintout={handlePrintout}
                    active={printerData.ean_brother.status === 'active'}
                />
            )}
            {printerData?.contractor_documents_a4 && (
                <PrinterItem
                    name={t('labels.printers.documents', { ns: 'workplaces' })}
                    code={printerData.contractor_documents_a4.code}
                    canPrint={canPrint}
                    handlePrintout={handlePrintout}
                    active={
                        printerData.contractor_documents_a4.status === 'active'
                    }
                />
            )}
        </>
    );
};
