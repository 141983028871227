import { type ComponentType, useContext } from 'react';
import ServicesContext from 'contexts/ServicesContext';
import type { TServices } from 'services/servicesFactory';
import type { DeepPartial } from 'utils/types/deepPartial';

export interface WithServicesProps {
    services: TServices;
}

const withServices =
    <TProps extends WithServicesProps = WithServicesProps>(
        BaseComponent: ComponentType<TProps>,
    ) =>
    (props: Omit<TProps, keyof WithServicesProps>) => {
        const context = useContext(ServicesContext);

        return (
            <BaseComponent
                services={context}
                {...(props as TProps)}
            />
        );
    };

export const withServicesConfigurator =
    (availableServices: (keyof TServices)[] = []) =>
    <
        TProps extends
            DeepPartial<WithServicesProps> = DeepPartial<WithServicesProps>,
    >(
        BaseComponent: ComponentType<TProps>,
    ) =>
    (props: Omit<TProps, keyof DeepPartial<WithServicesProps>>) => {
        const context = useContext(ServicesContext);
        const services = availableServices.reduce<Partial<TServices>>(
            (services, serviceKey) => ({
                ...services,
                [serviceKey]: context[serviceKey],
            }),
            {},
        );

        return (
            <BaseComponent
                services={services}
                {...(props as TProps)}
            />
        );
    };

export default withServices;
