import { ApplicationJsonHeaders } from '../../constants';
import { configError } from '../../utils/utils';
import type { SalesChannelsService } from '../SalesChannelsService';

export class MediaFiles {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.uploadCustomFiles = this.uploadCustomFiles.bind(this);
    }

    async uploadCustomFiles(file) {
        const {
            salesChannelsService: { ajax, URLS, errorHandlers },
        } = this;

        const formData = new FormData();

        formData.append('file', file);

        const { data } = await ajax.post({
            url: URLS.UPLOAD_CUSTOM_FILES,
            data: formData,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: configError('post', errorHandlers),
        });

        return data;
    }
}
