import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { scrollTo } from 'utils/nodes/scrollHelpers';
import { Loader } from 'components/Loader/Loader';
import Row from '../Row/row';
import { RootStyled } from 'components/OldTable/Body/body.styles';
import { getString } from 'utils/immutable/map';

class Body extends React.PureComponent {
    constructor(props) {
        super(props);
        this.tableBodyRef = React.createRef();
        this.clickedRowRef = React.createRef();
        this.state = {
            clickedRow: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.loading && this.props.loading && this.tableBodyRef.current) {
            this.tableBodyRef.current.scrollTop = 0;
        }
        if (prevState.clickedRow !== this.state['clickedRow']) {
            const bodyNode = this.tableBodyRef?.current || {};
            const rowNode = this.clickedRowRef?.current || {};
            const scrollTime = 300;

            if (rowNode.clientHeight + rowNode.offsetTop > bodyNode.clientHeight + bodyNode.scrollTop) {
                scrollTo(bodyNode, bodyNode.scrollTop + rowNode.clientHeight, scrollTime);
            }
        }
    }

    handleClick = (e, idKey) => {
        const { toggleExtend } = this.props;

        e && e.stopPropagation && e.stopPropagation();
        toggleExtend && toggleExtend(idKey);
        this.setState({ clickedRow: idKey });
    };

    getActive(idKey) {
        const { extendedAll, extendedItems, compressedItems } = this.props;

        if (!extendedItems && !compressedItems) return false;
        if (extendedAll) return !compressedItems.get(idKey);

        return extendedItems.get(idKey);
    }

    getEditProps(idKey, keyName) {
        const { edit } = this.props;

        if (!edit) return null;

        return {
            isEditing: edit.item && getString(edit.item, keyName) === idKey,
            item: edit.item,
            pick: () => edit.pick(idKey),
            cancel: () => edit.pick(),
            changeItem: edit.changeItem,
            submit: () => edit.submit(idKey),
        };
    }

    getRows() {
        const {
            t,
            id,
            columns,
            columnStyles,
            onSelect,
            selectedItems,
            forceSelected,
            itemsArray,
            keyName,
            toggleExtend,
            extendedRowComponent,
            customExtendedRowComponent,
            showExtendedIcon,
            actions,
            smallRows,
            isSelectedAll,
        } = this.props;

        return itemsArray.map((item, index) => {
            const idKey = keyName === 'index' ?
                index
                :
                getString(item, keyName);
            const active = this.getActive(idKey);

            return (
                <Row
                    ref={ this.state['clickedRow'] === idKey ? this.clickedRowRef : null }
                    key={ idKey }
                    t={ t }
                    id={ `${id}-row-${index}` }
                    dataId={ idKey }
                    item={ item }
                    actions={ actions }
                    cells={ columns }
                    idKey={ idKey }
                    rowStyle={ columnStyles }
                    onClick={ toggleExtend ? (e) => this.handleClick(e, idKey) : null }
                    onSelect={ onSelect }
                    selected={ isSelectedAll || selectedItems && selectedItems.get(idKey) }
                    disableSelected={ forceSelected && forceSelected.get(idKey) }
                    smallRows={ smallRows }
                    isExtended={ active }
                    edit={ this.getEditProps(idKey, keyName) }
                    extendedRowComponent={ extendedRowComponent }
                    customExtendedRowComponent={ customExtendedRowComponent }
                    showExtendedIcon={ showExtendedIcon }
                />
            );
        });
    }

    render() {
        const {
            id,
            isCollectionComplete,
            fetchItems,
            withPagination,
            bodyStyle,
        } = this.props;

        return (
            <RootStyled
                id={ id }
                style={ bodyStyle }
                ref={ this.tableBodyRef }
                onClick={ this.handleClick }
            >
                {fetchItems && !withPagination ? (
                    <InfiniteScroll
                        loadMore={ fetchItems }
                        hasMore={ !isCollectionComplete }
                        useWindow={ false }
                        initialLoad={ false }
                        loader={ (
                            <Loader
                                simple
                                key="loader"
                            />
                        ) }
                    >
                        {this.getRows()}
                    </InfiniteScroll>
                ) : this.getRows()}
            </RootStyled>
        );
    }
}

Body.defaultProps = {
    keyName: 'id',
    bodyStyle: {},
};

Body.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    isCollectionComplete: PropTypes.bool,
    fetchItems: PropTypes.func,
    columns: PropTypes.array.isRequired,
    columnStyles: PropTypes.object,
    onSelect: PropTypes.func,
    itemsArray: PropTypes.array.isRequired,
    keyName: PropTypes.string,
    toggleExtend: PropTypes.func,
    isSelectedAll: PropTypes.bool,
    extendedAll: PropTypes.bool,
    extendedRowComponent: PropTypes.array,
    customExtendedRowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    showExtendedIcon: PropTypes.func,
    extendedItems: PropTypes.object,
    actions: PropTypes.object,
    edit: PropTypes.object,
    withPagination: PropTypes.bool,
    loading: PropTypes.bool,
    bodyStyle: PropTypes.object,
    compressedItems: PropTypes.object,
    selectedItems: PropTypes.object,
    smallRows: PropTypes.bool,
    forceSelected: PropTypes.object,
};

export default Body;
