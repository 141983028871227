import { get } from 'lodash';

import { mapOrderFromApi } from '../mappers/orders';

import orderFiltersConfig from 'modules/sales/components/OrdersTableView/filtersConfig';

import { parsePostFilters } from 'utils/filters';

class Orders {
    constructor(searchService) {
        this.searchService = searchService;
        this.constants = searchService.constants;

        this.getList = this.getList.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    getList({
        page = 1,
        per_page = this.constants.DEFAULTS.ORDERS_PER_PAGE,
        filters = {},
        sort = {},
    }) {
        const { ajaxService } = this.searchService;
        const { URLS } = this.constants;

        const postFilters = parsePostFilters({
            filters,
            sort,
            config: orderFiltersConfig,
        });
        const config = {
            data: postFilters,
            params: {
                page,
                itemsPerPage: per_page,
            },
            headers: this.constants.HEADERS.basic,
        };

        return ajaxService
            .post({ url: URLS.GET_ORDERS, config })
            .then((response) => {
                const items = get(response, 'data.results', []);
                const itemsTotal = get(response, 'data.total', 0);

                return {
                    items: items.map((item) => mapOrderFromApi(item)),
                    itemsTotal,
                };
            })
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    refresh(id) {
        const { ajaxService } = this.searchService;
        const { URLS } = this.constants;

        const data = {
            orders: [{ id }],
        };

        const config = {
            data,
            headers: this.constants.HEADERS.basic,
        };

        return ajaxService
            .post({ url: URLS.REFRESH_ORDERS, config })
            .then(({ status }) => ({ status }))
            .catch(() => ({ status: '' }));
    }
}

export default Orders;
