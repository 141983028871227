import { PREVIEWS_UPLOAD_CONFIG } from '../constants';
import {
    PREVIEW_TYPES_UPLOAD_CONFIG,
    PATTERNS_UPLOAD_CONFIG,
    PAINT_COLOR_PALETTES_UPLOAD_CONFIG,
    DRAFTS_UPLOAD_CONFIG,
    BASE_PATTERNS_FILES_UPLOAD_CONFIG,
} from './configs';

import type { default as FilesUploadService } from 'services/filesUpload/FilesUploadService';

export const uploadServicesFactory = (
    filesUploadServiceFactory: (config: unknown) => FilesUploadService,
) => ({
    patterns: filesUploadServiceFactory(PATTERNS_UPLOAD_CONFIG),
    previewTypes: filesUploadServiceFactory(PREVIEW_TYPES_UPLOAD_CONFIG),
    previews: filesUploadServiceFactory(PREVIEWS_UPLOAD_CONFIG),
    paintColorPalettes: filesUploadServiceFactory(
        PAINT_COLOR_PALETTES_UPLOAD_CONFIG,
    ),
    drafts: filesUploadServiceFactory(DRAFTS_UPLOAD_CONFIG),
    basePatternsFiles: filesUploadServiceFactory(
        BASE_PATTERNS_FILES_UPLOAD_CONFIG,
    ),
});
