import get from 'lodash/get';
import { downloadInBackground } from 'utils/download';

import {
    getDiffData,
    getDiffKeys,
    mapExternalPatternData,
    mapPatternData,
    mapPatternsGetList,
    mapPatternsToApiForBatchEdit,
} from '../mappers/pattern';
import { configError } from 'services/utils/utils';
import { ApplicationJsonHeaders } from 'services/constants';
import { parsePatternFiltersToApi } from 'modules/stock/components/patternsTableView/filters/filtersHelpers';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';
import type { IServiceMethodParams } from '../../../types/services.type';

const defaultObj = {
    items: [],
    totalItems: 0,
};

class Patterns {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly HEADERS: IStockServiceConst['HEADERS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = this.stockService.URLS;
        this.HEADERS = this.stockService.HEADERS;
        this.errorHandlers = this.stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.edit = this.edit.bind(this);
        this.getImageData = this.getImageData.bind(this);
        this.download = this.download.bind(this);
        this.editList = this.editList.bind(this);
        this.postPattern = this.postPattern.bind(this);
        this.patchPattern = this.patchPattern.bind(this);
        this.remove = this.remove.bind(this);
    }

    async getList(params: IServiceMethodParams | undefined = {}): Promise<{
        items: any;
        totalItems: any;
    }> {
        const {
            errorHandlers,
            URLS: { GET_PATTERNS },
            services: { ajax },
        } = this;

        const errorConfig = configError('get', errorHandlers);
        const config = {
            params: {
                ...params,
                filters: parsePatternFiltersToApi(params.filters),
            },
            headers: ApplicationJsonHeaders,
        };

        try {
            const { data } = await ajax.get({
                url: GET_PATTERNS,
                config,
                errorConfig,
            });

            if (
                Array.isArray(data?.results) &&
                typeof data?.total === 'number'
            ) {
                return mapPatternsGetList({
                    results: data.results,
                    total: data.total,
                });
            }

            return defaultObj;
        } catch {
            return defaultObj;
        }
    }

    async getById(patternId: string, ignoreErrors = false): Promise<any> {
        const { ajax } = this.services;

        const url = this.URLS.GET_PATTERN.replace('{id}', patternId);

        const errorConfig = ignoreErrors
            ? {
                  logError: false,
                  addGenericAlert: false,
                  errorHandler: () => {},
              }
            : {};

        const { data } = await ajax.get({ url, errorConfig });

        return data;
    }

    async edit(
        id: string,
        data: Record<string, unknown>,
        type = 'pattern',
    ): Promise<any> {
        const {
            services: { ajax },
            URLS: { PATCH_PATTERN, PATCH_EXTERNAL_PATTERN },
        } = this;

        const url = (
            type === 'externalPattern' ? PATCH_EXTERNAL_PATTERN : PATCH_PATTERN
        ).replace('{id}', id);
        const config = {
            headers: this.HEADERS['patch'],
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.patch,
        };

        return await ajax.patch({
            url,
            data:
                type === 'externalPattern'
                    ? data
                    : { ...data, externalProducer: null },
            errorConfig,
            config,
        });
    }

    getByCode = async (patternCode: string): Promise<any> => {
        const { ajax } = this.services;
        const url = this.URLS.GET_PATTERN_BY_CODE.replace('{id}', patternCode);
        const { data } = await ajax.get({ url });

        return data;
    };

    async getImageData(
        fileId: string,
        type = 'pattern',
    ): Promise<{ url: string; fileName: string }> {
        const { ajax } = this.services;
        const url = (
            type === 'pattern'
                ? this.URLS.DOWNLOAD_PATTERN_FILE
                : this.URLS.DOWNLOAD_PRODUCT_SET_PATTERN_FILE
        ).replace('{id}', fileId);

        const response = await ajax.get({ url });
        const fileName = get(response, 'data.filename', '');

        return { url, fileName };
    }

    async download(fileId: string): Promise<void> {
        const { ajax } = this.services;
        const url = this.URLS.DOWNLOAD_PATTERN_FILE.replace('{id}', fileId);

        const response = await ajax.get({ url });
        const fileName = get(response, 'data.filename', '');
        const url_1 = get(response, 'data.url', '');

        downloadInBackground(url_1, fileName);
    }

    async editList({ printSpec, patternsById }): Promise<void> {
        const { ajax } = this.services;
        const url = this.URLS.EDIT_PATTERNS_PRINT_SPEC.replace(
            '{id}',
            printSpec.id,
        );
        const patterns = Object.values(patternsById);
        const errorConfig = {
            addGenericAlert: false,
            throwError: this._makeEditListError(patternsById),
        };
        const data = {
            pattern_ids: mapPatternsToApiForBatchEdit(patterns),
        };
        const config = {
            headers: ApplicationJsonHeaders,
        };

        await ajax.post({ url, data, config, errorConfig });
        this.services.alert.addSuccess({
            message: this.services.translator.t(
                'stock:alerts.patternsPrintSpecEdited',
                {
                    printSpec: printSpec.name,
                    count: patterns.length,
                },
            ),
            options: {
                persist: true,
            },
        });
    }

    _makeEditListError(patternsById) {
        return (error = {}) => {
            const { response = {} } = error;
            const { data = {} } = response;
            const ids = data.detail.split(', ').map((msg) => msg.split('"')[1]);
            const codes = ids.map((id) => patternsById[id]?.code);
            const message =
                codes.length && codes.length[0]
                    ? this.services.translator.t(
                          'stock:alerts.patternsPrintSpecNotEdited',
                          {
                              patterns: codes.join(', '),
                          },
                      )
                    : data.detail;

            this.services.alert.addError({
                message,
                options: {
                    persist: true,
                },
            });
            throw error;
        };
    }

    async _postPattern(patternData, type = 'pattern') {
        const {
            services: { ajax },
            URLS: { POST_PATTERN, POST_EXTERNAL_PATTERN },
        } = this;

        const response = await ajax.post({
            url:
                type === 'externalPattern'
                    ? POST_EXTERNAL_PATTERN
                    : POST_PATTERN,
            data:
                type === 'externalPattern'
                    ? patternData
                    : { ...patternData, externalProducer: null },
            errorConfig: {
                addGenericAlert: false,
            },
        });

        return response?.data ?? {};
    }

    postPattern = async (
        patternData: Record<string, unknown>,
        type: string = 'pattern',
    ): Promise<any> => {
        const mappedPatternData =
            type === 'pattern'
                ? mapPatternData(patternData)
                : mapExternalPatternData(patternData);

        return await this._postPattern(mappedPatternData, type);
    };

    patchPattern = async (
        {
            patternId,
            prevPatternData,
            newPatternData,
        }: {
            patternId: string;
            prevPatternData: object;
            newPatternData: object;
        },
        type: string = 'pattern',
    ): Promise<any | {}> => {
        const diffKeys = getDiffKeys(prevPatternData, newPatternData);

        if (diffKeys.length) {
            const diffData = getDiffData(diffKeys, newPatternData);

            const mappedPatternData =
                type === 'pattern'
                    ? mapPatternData(diffData)
                    : mapExternalPatternData(diffData, 'edit');

            const patchedPattern = await this.edit(
                patternId,
                mappedPatternData,
                type,
            );

            return patchedPattern?.data ?? {};
        }

        return prevPatternData;
    };

    remove = async (id: string): Promise<void> => {
        const {
            services: { ajax },
            URLS: { DELETE_PATTERN },
        } = this;

        await ajax.delete({
            url: DELETE_PATTERN.replace('{id}', id),
        });
    };
}

export default Patterns;
