import { fromJS, Map as ImmutableJSMap } from 'immutable';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

export const deleteByKeys = <K, V>(map: ImmutableJSMap<K, V>, keys: K[]) => {
    let newMap = ImmutableJSMap(map);

    keys.forEach((key) => {
        if (newMap.get(key)) {
            newMap = newMap.delete(key);
        }
    });

    return newMap;
};

export const getKeys = <K, V>(map: ImmutableJSMap<K, V>) => map.keySeq().toArray();

interface IStringGetter<T> {
    getString(key: T): string;
}

const isImmutableJSMap = <K, V>(map: unknown): map is ImmutableJSMap<K, V> => ImmutableJSMap.isMap(map);

export function getString<TKeyType>(object: IStringGetter<TKeyType>, key: TKeyType): string;
export function getString<TKeyType, TValueType>(map: ImmutableJSMap<TKeyType, TValueType>, key: TKeyType): string;
export function getString<TKeyType, TValueType = unknown>(mapOrObject: IStringGetter<TKeyType> | ImmutableJSMap<TKeyType, TValueType>, key: TKeyType): string {
    if (isImmutableJSMap(mapOrObject)) {
        return mapOrObject.get(key)?.toString?.() || '';
    }

    return mapOrObject.getString(key);
}

export const getSelected = <K, NestedK extends string, NestedV, V extends ImmutableJSMap<NestedK, NestedV>>(map: ImmutableJSMap<K, V>, filterMap: ImmutableJSMap<K, unknown>, keyName: NestedK = 'id' as NestedK) => {
    const selectedIdMap = {};

    map.forEach((item, key) => {
        if (item && key && filterMap.get(key)) {
            selectedIdMap[getString(item, keyName)] = item;
        }
    });

    return fromJS(selectedIdMap);
};

interface ILoadingSetter<TReturnType> {
    setLoading(): TReturnType;
}

interface ILoadedSetter<TReturnType> {
    setLoaded(): TReturnType;
}

export function setLoading<TReturnType>(object: ILoadingSetter<TReturnType>): TReturnType;
export function setLoading<TKeyType, TValueType>(map: ImmutableJSMap<TKeyType, TValueType>, id: TKeyType): ImmutableJSMap<TKeyType, TValueType>;
export function setLoading<TReturnType, TKeyType, TValueType>(objectOrMap: ILoadingSetter<TReturnType> | ImmutableJSMap<TKeyType, TValueType>, id?: TKeyType) {
    if (isImmutableJSMap(objectOrMap)) {
        if (!objectOrMap.get(id)) {
            return objectOrMap;
        }

        return objectOrMap.setIn([id, 'isLoading'], true);
    }

    return objectOrMap.setLoading();
}

export function setLoaded<TReturnType>(object: ILoadedSetter<TReturnType>): TReturnType;
export function setLoaded<TKeyType, TValueType>(map: ImmutableJSMap<TKeyType, TValueType>, id: TKeyType): ImmutableJSMap<TKeyType, TValueType>;
export function setLoaded<TReturnType, TKeyType, TValueType>(objectOrMap: ILoadedSetter<TReturnType> | ImmutableJSMap<TKeyType, TValueType>, id?: TKeyType) {
    if (isImmutableJSMap(objectOrMap)) {
        if (!objectOrMap.get(id)) {
            return objectOrMap;
        }

        return objectOrMap.setIn([id, 'isLoading'], false);
    }

    return objectOrMap.setLoaded();
}

export const setLoadingCollection = <K, V>(map: ImmutableJSMap<K, V>, ids: K[]) => {
    let newMap = ImmutableJSMap(map);

    ids.forEach((id) => {
        newMap = newMap.get(id) ? newMap.setIn([id, 'isLoading'], true) : newMap;
    });

    return newMap;
};

export const setLoadedCollection = <K, V>(map: ImmutableJSMap<K, V>, ids: K[]) => {
    let newMap = ImmutableJSMap(map);

    ids.forEach((id) => {
        newMap = newMap.get(id) ? newMap.setIn([id, 'isLoading'], false) : newMap;
    });

    return newMap;
};

export const sortByKey = <K extends string, V extends ImmutableJSMap<unknown, unknown> | Record<string, unknown>>(map: ImmutableJSMap<K, V>, key: K, isDesc: boolean) => {
    const keyArray = key && key.split('.') || [];

    return map.sortBy((item) => {
        return toLower(ImmutableJSMap.isMap(item) ? (item as ImmutableJSMap<unknown, unknown>).getIn(keyArray) : get(item, keyArray));
    }, (curr, prev) => {
        if (curr < prev) {
            return isDesc ? 1 : -1;
        }
        if (curr > prev) {
            return isDesc ? -1 : 1;
        }

        return 0;
    });
};
