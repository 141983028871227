import get from 'lodash/get';
import { mapSourceCategoryFromApi } from '../../mappers/sourceCategory';
import { ApplicationJsonHeaders } from '../../../constants';

import type { ISourceCategories } from './sourceCategories.type';
import type { StockService } from '../../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../../stockService.type';

class SourceCategories {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly errorHandlers: StockService['errorHandlers'];
    private perPage: number = 50;
    private getListPromise: unknown;
    private requestPending: boolean = false;
    private fullList: unknown;

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.stockService = stockService;
        this.services = services;
        this.URLS = this.stockService.URLS;
        this.errorHandlers = this.stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
    }
    async _getMore(page) {
        const { ajax } = this.services;
        const url = this.URLS.GET_SOURCE_CATEGORIES;
        const onError = this.errorHandlers.get;

        const config = {
            params: { page: page, per_page: this.perPage },
        };

        const response = await ajax.get({ url, config, onError });

        return get(response, 'data.results', []).map(mapSourceCategoryFromApi);
    }
    _getFullList() {
        return this.fullList;
    }
    getList(params) {
        const { ajax } = this.services;
        const url = this.URLS.GET_SOURCE_CATEGORIES;
        const onError = this.errorHandlers.get;
        const config = {
            params: {
                ...(params?.filters ?? {}),
                page: 1,
                per_page: this.perPage,
            },
        };

        if (this.requestPending) {
            return this.getListPromise;
        }

        this.requestPending = true;
        this.getListPromise = ajax
            .get({ url, config, onError })
            .then((response) => {
                const categories = get(response, 'data.hydra:member', []).map(
                    mapSourceCategoryFromApi,
                );
                const totalPages =
                    get(response, 'data.hydra:totalItems', 0) / this.perPage;

                if (totalPages > 1) {
                    const requests = [];

                    for (let i = 2; i <= totalPages; i++) {
                        requests.push(this._getMore(i));
                    }

                    return Promise.all(requests).then((responses) => {
                        this.fullList = [...categories, ...responses.flat()];
                        this.requestPending = false;

                        return this._getFullList();
                    });
                } else {
                    this.fullList = categories;
                    this.requestPending = false;

                    return this._getFullList();
                }
            });

        return this.getListPromise;
    }

    async getById(id: string): Promise<ISourceCategories> {
        const { ajax } = this.services;
        const { URLS } = this.stockService;
        const url = URLS.GET_SOURCE_CATEGORIES_BY_ID.replace('{id}', id);

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const { data } = await ajax.get({ url, config });

        return data;
    }
}

export default SourceCategories;
