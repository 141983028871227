import styled from '@emotion/styled';

export const ErrorStyled = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  height: ${({ theme }) => theme.spacing(3)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
ErrorStyled.displayName = 'Error';

export const ButtonsWrapperStyled = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  display: grid;
  grid-row-gap: 8px;
`;
ButtonsWrapperStyled.displayName = 'ButtonsWrapper';
