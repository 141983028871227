import { makeReducerSelector } from 'utils/redux/makeSelectors';
import { MODULE_NAME } from '../../constants';

const selector = makeReducerSelector(MODULE_NAME, 'productPossibilities');

export const selectSearchedQuery = selector('searchedQuery');

export const selectLoader = selector('productPossibilitiesTableLoading');

export const selectProductPossibilities = selector('productPossibilities');

export const selectInvalidPatterns = selector('invalidPatterns');
