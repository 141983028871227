import { select, takeEvery, takeLatest } from 'redux-saga/effects';
import qs from 'qs';
import get from 'lodash/get';
import * as C from './constants';
import * as A from './actions';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import { getSearch } from 'utils/router/selectors';
import moment from 'moment';

const STABLO_API_URL = get(window, 'env.STABLO_API_URL', '');
const USERSERVICE_API_URL = get(window, 'env.USERSERVICE_API_URL', '');
const productionOrders = makeEndPoint(STABLO_API_URL + '/production-orders');
const dictionaries = makeEndPoint(
    STABLO_API_URL + '/production-orders-dictionaries',
);

export const presets = makeEndPoint(
    USERSERVICE_API_URL + '/api/filter-presets',
);

export default function* productionOrdersSaga() {
    yield takeLatest(C.FETCH_PRODUCTION_ORDERS.request, getProductionOrders);
    yield takeLatest(C.FETCH_DICTIONARIES.request, getDictionaries);
    yield takeEvery(C.PRINT_PRODUCTION_ORDERS.request, printProductionOrders);
    yield takeEvery(
        C.REPRINT_PRODUCTION_ORDERS.request,
        reprintProductionOrders,
    );
    yield takeEvery(
        C.DOWNLOAD_PRODUCTION_ORDERS.request,
        downloadProductionOrders,
    );
    yield takeEvery(C.SAVE_AS_PRESET.request, saveAsPreset);
}

function* getDictionaries() {
    yield dictionaries.get({
        action: A.fetchDictionaries,
        alerts: {
            failure: true,
        },
    });
}

const generateParamsFromConfigArray = (urlParams) => {
    const params = {};

    if (urlParams.filters) {
        C.FILTER_TO_PARAM_MAP.forEach((paramName, filterName) => {
            if (urlParams.filters[filterName]) {
                params[paramName] = urlParams.filters[filterName];
            }
        });
    }

    return params;
};

function* getProductionOrders() {
    const urlParams = qs.parse(yield select(getSearch));

    const params = {
        ...generateParamsFromConfigArray(urlParams),
        ...(urlParams?.filters?.partWidth &&
            urlParams.filters.partWidth[0] && {
                partsMinShorterSize: urlParams.filters.partWidth[0],
            }),
        ...(urlParams?.filters?.partWidth &&
            urlParams.filters.partWidth[1] && {
                partsMaxShorterSize: urlParams.filters.partWidth[1],
            }),
        ...(urlParams?.filters?.partLength &&
            urlParams.filters.partLength[0] && {
                partsMinLongerSideSum: urlParams.filters.partLength[0],
            }),
        ...(urlParams?.filters?.partLength &&
            urlParams.filters.partLength[1] && {
                partsMaxLongerSideSum: urlParams.filters.partLength[1],
            }),
        ...(urlParams?.filters?.workplace &&
            urlParams.filters.workplace[0] && {
                workplaceId: urlParams.filters.workplace[0],
            }),
        page: (urlParams.pagination && urlParams.pagination.page) || 1,
        limit: (urlParams.pagination && urlParams.pagination.per_page) || 20,
    };

    if (urlParams.order) {
        params.order = urlParams.order;
    }

    if (params.productionAllowedDateFrom) {
        params.productionAllowedDateFrom = moment(
            `${params.productionAllowedDateFrom} 00:00:00`,
        )
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss');
    }

    if (params.productionAllowedDateTo) {
        params.productionAllowedDateTo = moment(
            `${params.productionAllowedDateTo} 23:59:59`,
        )
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss');
    }

    yield productionOrders.get({
        action: A.fetchProductionOrders,
        params,
        alerts: {
            failure: true,
        },
    });
}

function* printProductionOrders(action) {
    const { collection } = action.payload;
    const productIds = collection
        .filter((item) => item)
        .keySeq()
        .toArray()
        .join(',');

    yield productionOrders.get({
        action: A.printProductionOrders,
        path: `/${productIds}/print`,
        alerts: {
            failure: true,
        },
        attributes: {
            success: { collection },
            failure: { collection },
        },
    });
}

function* reprintProductionOrders(action) {
    const { collection } = action.payload;
    const productIds = collection.join(',');

    yield productionOrders.get({
        action: A.reprintProductionOrders,
        path: `/${productIds}/reprint`,
        alerts: {
            failure: true,
        },
        attributes: {
            success: { collection },
            failure: { collection },
        },
    });
}

function* saveAsPreset(action) {
    const preset = action.payload;
    const filters = {};

    C.FILTER_TO_PARAM_MAP.forEach((paramName, filterName) => {
        if (preset[filterName]) {
            filters[paramName] = preset[filterName];
        }
    });

    const urlParams = yield select(getSearch);

    preset.query = urlParams;
    preset.name = preset.name.trim().toUpperCase();

    yield presets.post({
        action: A.saveAsPreset,
        data: preset,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'production:alerts.presetCreated',
                options: {
                    variant: 'success',
                },
            },
        },
    });
}

function* downloadProductionOrders(action) {
    const { collection } = action.payload;
    const productIds = collection
        .filter((item) => item)
        .keySeq()
        .toArray()
        .join(',');

    const response = yield productionOrders.download({
        action: A.downloadProductionOrders,
        path: `/${productIds}/pdf`,
        alerts: {
            failure: true,
        },
        attributes: {
            success: { collection },
            failure: { collection },
        },
    });

    if (response.status >= 200 && response.status < 300) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const fileName =
            collection.toArray().length === 1
                ? `${productIds}.pdf`
                : 'orders.pdf';

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
