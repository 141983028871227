export class ItemModel {
    constructor(props = {}) {
        this.isLoading = false;
        this.id = props.id;
        this.updatedFields = [];
    }

    get = (fieldName) => this[fieldName];

    getString = (fieldName) => this[fieldName] || '';

    set = (fieldName, value) => this[fieldName] = value;

    getId = () => this.id && this.id.toString();

    setLoading = () => {
        this.isLoading = true;

        return { ...this };
    };

    setLoaded = () => {
        this.isLoading = false;

        return { ...this };
    };

    updateFieldFromNewData = (newData = {}) => (fieldName) => {
        if (newData[fieldName] !== this[fieldName]) {
            this[fieldName] = newData[fieldName];
            this.updatedFields.push(fieldName);
        }
    };

    // For backwards compatibility
    isEmpty = () => false;
}
