import { ACCEPT, CONDITIONAL, DENY } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

const propertiesAccessesPackage = {
    [A.ARM.PATHS.PROPERTIES]: ACCEPT,
    [A.ARM.PATHS.PROPERTIES_DETAILS]: ACCEPT,

    [A.ARM.RESOURCES.PROPERTY.FETCH]: ACCEPT,
    [A.ARM.RESOURCES.PROPERTY.CREATE]: ACCEPT,
    [A.ARM.RESOURCES.PROPERTY.DELETE]: ACCEPT,
    [A.ARM.RESOURCES.PROPERTY.EDIT]: ACCEPT,
};

/*
    CONTRACTOR
 */

export const ROLE_ARM_CONTRACTOR_DISPLAY = {
    section: S.ARM_CONTRACTOR,
    type: 'display',
    votes: {
        [A.DROPSHIPPING.MODULE]: ACCEPT,
        [A.DROPSHIPPING.PATHS.CONTRACTORS]: ACCEPT,
        [A.DROPSHIPPING.PATHS.CONTRACTORS_DETAILS]: ACCEPT,
        [A.DROPSHIPPING.PATHS.INVOICES]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.FETCH]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.SEARCH]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.SEND_EMAIL]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.INVOICE.FETCH]: ACCEPT,
    },
};

export const ROLE_ARM_CONTRACTOR_CREATE = {
    section: S.ARM_CONTRACTOR,
    type: 'create',
    votes: {
        [A.DROPSHIPPING.PATHS.CONTRACTORS_DETAILS]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.CREATE]: ACCEPT,
    },
};

export const ROLE_ARM_CONTRACTOR_EDIT = {
    section: S.ARM_CONTRACTOR,
    type: 'edit',
    votes: {
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.EDIT]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.VERIFY]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.ASSIGN_MANAGER]: CONDITIONAL(
            ACCEPT,
            {
                reqRoles: ['ROLE_ROOT'],
            },
        ),
    },
};

export const ROLE_ARM_CONTRACTOR_DELETE = {
    section: S.ARM_CONTRACTOR,
    type: 'delete',
    votes: {
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.DELETE]: ACCEPT,
    },
};

/*
    CONTRACTOR ORDER
 */

export const ROLE_ARM_CONTRACTOR_ORDER_DISPLAY = {
    section: S.ARM_ORDER_IMPORT,
    type: 'display',
    votes: {
        [A.DROPSHIPPING.MODULE]: ACCEPT,
        [A.DROPSHIPPING.PATHS.ORDER_IMPORTS]: ACCEPT,
        [A.DROPSHIPPING.PATHS.ORDER_IMPORTS_DETAILS]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.ORDER_IMPORT.FETCH]: ACCEPT,
    },
};

export const ROLE_ARM_CONTRACTOR_ORDER_ADD = {
    section: S.ARM_ORDER_IMPORT,
    type: 'create',
    votes: {
        [A.DROPSHIPPING.PATHS.ORDER_IMPORTS_DETAILS]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.ORDER_IMPORT.CREATE]: ACCEPT,
    },
};

export const ROLE_ARM_CONTRACTOR_ORDER_EDIT = {
    section: S.ARM_ORDER_IMPORT,
    type: 'edit',
    votes: {
        [A.DROPSHIPPING.RESOURCES.ORDER_IMPORT.EDIT]: ACCEPT,
        [A.DROPSHIPPING.RESOURCES.ORDER_IMPORT.PASS_TO_PRODUCTION]: ACCEPT,
    },
};

export const ROLE_ARM_CONTRACTOR_ORDER_DELETE = {
    section: S.ARM_ORDER_IMPORT,
    type: 'delete',
    votes: {
        [A.DROPSHIPPING.RESOURCES.ORDER_IMPORT.DELETE]: ACCEPT,
    },
};

/*
    CONTRACTOR USER
 */

export const ROLE_ARM_IS_CONTRACTOR_USER = {
    votes: {
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.SEARCH]: DENY,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.SEND_EMAIL]: DENY,
        [A.DROPSHIPPING.RESOURCES.CONTRACTOR.VERIFY]: DENY,
    },
};

/*
    INTERMEDIATE PRODUCT
 */

export const ROLE_ARM_INTERMEDIATE_PRODUCT_DISPLAY = {
    section: S.ARM_INTERMEDIATE_PRODUCT,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.INTERMEDIATE_PRODUCTS]: ACCEPT,
        [A.ARM.PATHS.INTERMEDIATE_PRODUCTS_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.INTERMEDIATE_PRODUCT.FETCH]: ACCEPT,
        ...propertiesAccessesPackage,
    },
};

export const ROLE_ARM_INTERMEDIATE_PRODUCT_ADD = {
    section: S.ARM_INTERMEDIATE_PRODUCT,
    type: 'create',
    votes: {
        [A.ARM.PATHS.INTERMEDIATE_PRODUCTS_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.INTERMEDIATE_PRODUCT.CREATE]: ACCEPT,
        ...propertiesAccessesPackage,
    },
};

export const ROLE_ARM_INTERMEDIATE_PRODUCT_EDIT = {
    section: S.ARM_INTERMEDIATE_PRODUCT,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.INTERMEDIATE_PRODUCT.EDIT]: ACCEPT,
        ...propertiesAccessesPackage,
    },
};

export const ROLE_ARM_INTERMEDIATE_PRODUCT_DELETE = {
    section: S.ARM_INTERMEDIATE_PRODUCT,
    type: 'delete',
    votes: {
        [A.ARM.RESOURCES.INTERMEDIATE_PRODUCT.DELETE]: ACCEPT,
    },
};

/*
    INTERMEDIATE PRODUCT GROUP (TECHNOLOGY)
 */

export const ROLE_ARM_INTERMEDIATE_PRODUCT_GROUP_DISPLAY = {
    section: S.ARM_TECHNOLOGY,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.TECHNOLOGIES]: ACCEPT,
        [A.ARM.PATHS.TECHNOLOGIES_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.TECHNOLOGY.FETCH]: ACCEPT,
        ...propertiesAccessesPackage,
    },
};

export const ROLE_ARM_INTERMEDIATE_PRODUCT_GROUP_EDIT = {
    section: S.ARM_TECHNOLOGY,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.TECHNOLOGY.EDIT]: ACCEPT,
        [A.ARM.RESOURCES.TECHNOLOGY.CREATE]: ACCEPT,
        ...propertiesAccessesPackage,
    },
};

export const ROLE_ARM_INTERMEDIATE_PRODUCT_GROUP_DELETE = {
    section: S.ARM_TECHNOLOGY,
    type: 'delete',
    votes: {
        [A.ARM.RESOURCES.TECHNOLOGY.DELETE]: ACCEPT,
    },
};

/*
    TECHNOLOGY GROUP
 */

export const ROLE_ARM_TECHNOLOGY_GROUP_READ = {
    section: S.ARM_TECHNOLOGY_GROUP,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.TECHNOLOGIES_GROUPS]: ACCEPT,
        [A.ARM.PATHS.TECHNOLOGIES_GROUPS_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.TECHNOLOGY_GROUP.FETCH]: ACCEPT,
    },
};

export const ROLE_ARM_TECHNOLOGY_GROUP_UPDATE = {
    section: S.ARM_TECHNOLOGY_GROUP,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.TECHNOLOGY_GROUP.EDIT]: ACCEPT,
    },
};

export const ROLE_ARM_TECHNOLOGY_GROUP_CREATE = {
    section: S.ARM_TECHNOLOGY_GROUP,
    type: 'create',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.TECHNOLOGIES_GROUPS_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.TECHNOLOGY_GROUP.CREATE]: ACCEPT,
    },
};

export const ROLE_ARM_TECHNOLOGY_GROUP_DELETE = {
    section: S.ARM_TECHNOLOGY_GROUP,
    type: 'delete',
    votes: {
        [A.ARM.RESOURCES.TECHNOLOGY_GROUP.DELETE]: ACCEPT,
    },
};

/*
    PATTERN
 */

export const ROLE_ARM_PATTERN_DISPLAY = {
    section: S.ARM_PATTERN,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PATTERNS]: ACCEPT,
        [A.ARM.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.PATTERN.FETCH]: ACCEPT,
    },
};

export const ROLE_ARM_PATTERN_EDIT = {
    section: S.ARM_PATTERN,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.PATTERN.CREATE]: ACCEPT,
    },
};

/*
    PRODUCT
 */

export const ROLE_ARM_PRODUCT_DISPLAY = {
    section: S.ARM_PRODUCT,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.PRODUCTS]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_DETAILS]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_TYPES]: ACCEPT,
        [A.ARM.RESOURCES.PRODUCT.FETCH]: ACCEPT,
        [A.ARM.RESOURCES.PRODUCT_SET.FETCH]: ACCEPT,
    },
};

export const ROLE_ARM_PRODUCT_EDIT = {
    section: S.ARM_PRODUCT,
    type: 'edit',
    votes: {
        [A.ARM.RESOURCES.PRODUCT.CREATE]: ACCEPT,
    },
};

/**
 * ExternalProducer
 */

export const ROLE_EXTERNAL_PRODUCER_VIEW = {
    section: S.ARM_EXTERNAL_PRODUCERS,
    type: 'display',
    votes: {
        [A.ARM.PATHS.EXTERNAL_PRODUCERS]: ACCEPT,
        [A.ARM.RESOURCES.EXTERNAL_PRODUCERS.VIEW]: ACCEPT,
    },
};

export const ROLE_EXTERNAL_PRODUCER_EDIT = {
    section: S.ARM_EXTERNAL_PRODUCERS,
    type: 'edit',
    votes: {
        [A.ARM.PATHS.EXTERNAL_PRODUCER_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.EXTERNAL_PRODUCERS.CREATE]: ACCEPT,
        [A.ARM.RESOURCES.EXTERNAL_PRODUCERS.EDIT]: ACCEPT,
    },
};
