import moment from 'moment';
import {
    PriceListIntermediateProductOutputDto,
    PriceListItemOutputDto,
    PriceListProductSetOutputDto,
    UpdatePriceListIntermediateProductsDto,
    UpdatePriceListProductSetsDto,
} from '../actions/offer-price-lists/offer-price-lists.type';
import type { DeepPartial } from 'utils/types/deepPartial';

export const mapOfferPriceListsFromApi = (data: PriceListItemOutputDto) => {
    const { id, created_at, type, currency, code } = data;

    return {
        id,
        type,
        currency,
        code,
        createdAt: created_at ? moment(created_at).format('DD/MM/YYYY') : '',
    };
};

export const mapPriceListIntermediateProductFromApi = (
    data: DeepPartial<PriceListIntermediateProductOutputDto> = {},
) => ({
    id: data?.offer_intermediate_product?.id ?? null,
    price: data?.price?.amount ?? null,
    currency: data?.price?.currency ?? null,
    name: data?.offer_intermediate_product?.intermediate_product?.name ?? '',
    code: data?.offer_intermediate_product?.intermediate_product?.code ?? '',
});

export const mapPriceListProductSetFromApi = (
    data: DeepPartial<PriceListProductSetOutputDto> = {},
) => ({
    id: data?.offer_product_set?.id ?? null,
    price: data?.price?.amount ?? null,
    currency: data?.price?.currency ?? null,
    name: data?.offer_product_set?.product_set?.name ?? '',
    code: data?.offer_product_set?.product_set?.product_set_type?.code ?? '',
});

export const mapPriceIntermediateProductToApi = (data: {
    id: string;
    price: string | number;
}): UpdatePriceListIntermediateProductsDto['price_list_intermediate_products'][number] => ({
    id: data.id,
    amount: Number(data.price),
});

export const mapPriceProductSetToApi = (data: {
    id: string;
    price: string | number;
}): UpdatePriceListProductSetsDto['price_list_product_sets'][number] => ({
    id: data.id,
    amount: Number(data.price),
});
