export const MODULE_NAME = 'prodis';

export const DELIVERED_STATUSES = [
    'delivered',
    'pod_downloaded',
    'completed',
];

export const FAILED_STATUSES = [
    'error',
];

export const DELIVERY_NOTES_FILTER_DATES = [
    { paramName: 'podCreatedAt', labelKey: 'labels.podCreatedAt' },
    { paramName: 'ie599CreatedAt', labelKey: 'labels.ie599CreatedAt' },
    { paramName: 'pickUpDate', labelKey: 'labels.pickupDate' },
    { paramName: 'deliveryDate', labelKey: 'labels.deliveryDate' },
    { paramName: 'invoiceSaleDate', labelKey: 'labels.optimaInvoiceDate' },
    { paramName: 'issueDate', labelKey: 'labels.optimaDeliveryNoteDate' },
    { paramName: 'externalUpdatedAt', labelKey: 'labels.externalUpdatedAt' },
];

export const DELIVERY_NOTES_COUNTRY_TYPES = [
    'PL',
    'UE',
    'nonUE',
];

export const DELIVERY_NOTES_COMPANIES = [
    { value: '0', label: 'Artgeist' },
    { value: '1', label: 'Decomonkey' },
];

export const PREDEFINED_SHIPMENTS_PARAMS = {
    DELIVERED_SHIPMENTS: {
        ORDER: {
            createdAt: 'desc',
        },
        FILTERS: {
            status: ['delivered','pod_downloaded','completed'],
        }
    },
    SHIPMENTS_IN_PROGRESS: {
        ORDER: {
            createdAt: 'desc',
        },
        FILTERS: {
            status: ['open','pending','picked_up','complated','canceled','error'],
        }
    },
    SHIPMENTS_VERIFICATION: {
        ORDER: {
            createdAt: 'desc',
        },
        FILTERS: {
            status: ['error'],
        }
    },
};

export const FILES_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: {
        POD: [
            'application/zip',
            'application/x-zip-compressed',
            'application/pdf',
        ],
        IE599: [
            'application/zip',
            'application/x-zip-compressed',
            'application/xml',
            'text/xml'
        ]
    },
    MAX_FILE_SIZE: 1024 * 1024 * 30,
};
