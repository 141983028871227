import { makeAction, makeRequestActions } from 'utils/redux/makeActions';
import * as C from './constants';

export const logoutUser = makeAction(C.LOGOUT_USER);
export const setLoggedIn = makeAction(C.SET_LOGGED_USER);
export const hideWorkplaceModal = makeAction(C.HIDE_WORKPLACE_MODAL);
export const loginUser = makeRequestActions(C.LOGIN_USER);
export const resetToken = makeRequestActions(C.RESET_TOKEN);
export const requestChangePassword = makeRequestActions(C.REQUEST_CHANGE_PASSWORD);
export const changePassword = makeRequestActions(C.CHANGE_PASSWORD);
