import parser from 'qs';
import get from 'lodash/get';
import { mapInvoicesFromApi } from '../mappers/invoices';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class Invoices {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.armApiService = armApiService;
        this.services = services;
        this.URLS = URLS;

        this.getList = this.getList.bind(this);
        this.download = this.download.bind(this);
    }
    paramsSerializer = (params) => parser.stringify(params);
    async getList({ filter = {}, per_page = 20, page = 1, sort = {} }) {
        const { ajax } = this.services;

        const config = {
            params: {
                filter,
                sort,
                page,
                per_page,
            },
            paramsSerializer: this.paramsSerializer,
        };
        const errorConfig = {
            addGenericAlert: false,
            throwError: this.armApiService.errorHandlers.get,
        };

        try {
            const response = await ajax.get({
                url: this.URLS.GET_INVOICES,
                config,
                errorConfig,
            });
            const items = get(response, 'data.hydra:member', []);
            const totalItems = get(response, 'data.hydra:totalItems', []);

            return {
                items: items.map(mapInvoicesFromApi),
                totalItems,
            };
        } catch {
            return {
                items: [],
                totalItems: 0,
            };
        }
    }
    async download({ fileName, path }) {
        const { ajax } = this.services;

        const url = this.URLS.DOWNLOAD_INVOICE.replace('{path}', path);
        const config = {
            headers: {
                'Content-Type': 'text/csv',
                Accept: 'text/csv',
            },
        };
        const errorConfig = {
            throwError: this.armApiService.errorHandlers.download,
            addGenericAlert: false,
        };

        return await ajax.download({
            url,
            config,
            fileName,
            errorConfig,
        });
    }
}

export default Invoices;
