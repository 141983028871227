import * as S from '../sections';
import * as A from '../accesses';
import { ACCEPT } from '../voteTypes';

/*
    EAN_SCOPE
 */

export const ROLE_EAN_SCOPE_READ = {
    section: S.ARM_EAN_POOLS,
    type: 'display',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.EAN_POOLS]: ACCEPT,
        [A.ARM.PATHS.EAN_POOL_GTINS]: ACCEPT,
        [A.ARM.RESOURCES.EAN_POOLS.FETCH]: ACCEPT,
    },
};

export const ROLE_EAN_SCOPE_EDIT = {
    section: S.ARM_EAN_POOLS,
    type: 'manage',
    votes: {
        [A.ARM.MODULE]: ACCEPT,
        [A.ARM.PATHS.EAN_POOL_DETAILS]: ACCEPT,
        [A.ARM.RESOURCES.EAN_POOLS.CREATE]: ACCEPT,
        [A.ARM.RESOURCES.EAN_POOLS.EDIT]: ACCEPT,
        [A.ARM.RESOURCES.EAN_POOLS.DELETE]: ACCEPT,
    },
};
