import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function UserIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -1 16 16"
            { ...props }
        >
            <path
                d="M6 0.8125C3.8313 0.8125 2.0625 2.5813 2.0625 4.75C2.0625 6.10571 2.75464 7.30981 3.80273 8.01953C1.79663 8.88086 0.375 10.8716 0.375 13.1875H1.5C1.5 11.5615 2.35913 10.1465 3.64453 9.35547C4.02246 10.2827 4.94312 10.9375 6 10.9375C7.05688 10.9375 7.97754 10.2827 8.35547 9.35547C9.64087 10.1465 10.5 11.5615 10.5 13.1875H11.625C11.625 10.8716 10.2034 8.88086 8.19727 8.01953C9.24536 7.30981 9.9375 6.10571 9.9375 4.75C9.9375 2.5813 8.1687 0.8125 6 0.8125ZM6 1.9375C7.56006 1.9375 8.8125 3.18994 8.8125 4.75C8.8125 6.31006 7.56006 7.5625 6 7.5625C4.43994 7.5625 3.1875 6.31006 3.1875 4.75C3.1875 3.18994 4.43994 1.9375 6 1.9375ZM6 8.6875C6.46143 8.6875 6.90088 8.75342 7.31836 8.88086C7.1228 9.42358 6.61304 9.8125 6 9.8125C5.38696 9.8125 4.8772 9.42358 4.68164 8.88086C5.09912 8.75342 5.53857 8.6875 6 8.6875Z"
            />
        </SvgIcon>
    );
}

UserIcon.defaultProps = {
    titleAccess: 'user-icon',
};

UserIcon.propTypes = svgIconPropTypes;
