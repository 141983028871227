import { URLS, DEFAULTS } from './constants';
import { SkuService } from './skuService';
import errorHandlers from './errorHandlers';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const skuServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    SkuService.getInstance({
        services: {
            ajax: ajaxService,
        },
        constants: {
            URLS,
            DEFAULTS,
        },
        errorHandlers: errorHandlers(alertService),
    });
