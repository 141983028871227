const PRODIS_URL = window?.env?.PRODIS_API_URL || '';

const CARRIER_RULE = {
    GET_CARRIER_RULES: `${PRODIS_URL}/api/carrier_rules`,
    POST_CARRIER_RULE: `${PRODIS_URL}/api/carrier_rules`,
    PUT_BULK_DELETE_CARRIER_RULES: `${PRODIS_URL}/api/carrier_rules/bulk_delete`,
    POST_IMPORT_CARRIER_RULES: `${PRODIS_URL}/api/carrier_rules/import`,
    GET_EXPORT_CARRIER_RULES: `${PRODIS_URL}/api/carrier_rules/export`,
    GET_CARRIER_RULE: `${PRODIS_URL}/api/carrier_rules/{id}`,
    PUT_CARRIER_RULE: `${PRODIS_URL}/api/carrier_rules/{id}`,
} as const;

const CARRIER_TYPE = {
    GET_CARRIER_TYPES: `${PRODIS_URL}/api/carrier_types`,
    PUT_BATCH_CARRIER_TYPE_UPDATE: `${PRODIS_URL}/api/carrier_types/{code}/batch_update`,
} as const;

const CARRIER = {
    GET_CARRIERS: `${PRODIS_URL}/api/carriers`,
} as const;

const CUSTOM_SHIPMENT_CARRIER = {
    GET_CUSTOM_SHIPMENT_CARRIERS: `${PRODIS_URL}/api/custom_shipment_carriers`,
} as const;

const CUSTOM_SHIPMENT_DOCUMENT = {
    GET_DOWNLOAD_CUSTOM_SHIPMENT_DOCUMENT: `${PRODIS_URL}/api/custom_shipment_documents/{id}/download`,
} as const;

const CUSTOM_SHIPMENT = {
    PATCH_CUSTOM_SHIPMENT: `${PRODIS_URL}/api/custom_shipments/{wzServiceCode}`,
    POST_CUSTOM_SHIPMENT_CUSTOM_SHIPMENT_DOCUMENT: `${PRODIS_URL}/api/custom_shipments/{wzServiceCode}/custom_shipment_document`,
    POST_GENERATE_CUSTOM_SHIPMENT: `${PRODIS_URL}/api/custom_shipments_generate`,
    GET_CUSTOM_SHIPMENTS: `${PRODIS_URL}/api/v2/custom_shipments`,
    GET_CUSTOM_SHIPMENT: `${PRODIS_URL}/api/v2/custom_shipments/{wzServiceCode}`,
    DELETE_CUSTOM_SHIPMENT: `${PRODIS_URL}/api/v2/custom_shipments/{wzServiceCode}`,
    GET_EXPORT_CUSTOM_SHIPMENT: `${PRODIS_URL}/api/v2/custom_shipments/{wzServiceCode}/export`,
} as const;

const DOCUMENT = {
    POST_UPLOAD_DOCUMENT: `${PRODIS_URL}/api/documents/upload`,
    GET_DOWNLOAD_DOCUMENT: `${PRODIS_URL}/api/documents/{id}`,
    POST_SHIPMENT_DPD_DATA: `${PRODIS_URL}/api/shipments/dpdpl/pod`,
} as const;

const SHIPMENT = {
    GET_SHIPMENTS: `${PRODIS_URL}/api/shipments`,
    GET_SHIPMENT: `${PRODIS_URL}/api/shipments/{id}`,
    PUT_SHIPMENT_RECEIVER_ADDRESS: `${PRODIS_URL}/api/shipments/{id}/receiver_address`,
} as const;

const SHIPMENT_DOCUMENT = {
    GET_SHIPMENT_DOCUMENTS: `${PRODIS_URL}/api/shipments/{id}/documents`,
} as const;

export const URLS = {
    ...CARRIER_RULE,
    ...CARRIER_TYPE,
    ...CARRIER,
    ...CUSTOM_SHIPMENT_CARRIER,
    ...CUSTOM_SHIPMENT_DOCUMENT,
    ...CUSTOM_SHIPMENT,
    ...DOCUMENT,
    ...SHIPMENT,
    ...SHIPMENT_DOCUMENT,
} as const;

export const DEFAULTS = {
    SHIPMENTS_PER_PAGE: 20,
    CARRIERS_PER_PAGE: 20,
};
