import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function LineWeightIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -2 16 16"
            { ...props }
        >
            <path
                d="M13.84 11.4L13.84 10.68L0.159965 10.68L0.159966 11.4L13.84 11.4ZM13.84 8.88002L13.84 7.80002L0.159965 7.80003L0.159965 8.88003L13.84 8.88002ZM13.84 6.00002L13.84 4.56002L0.159965 4.56003L0.159965 6.00003L13.84 6.00002ZM13.84 2.76002L13.84 0.600024L0.159965 0.600025L0.159965 2.76003L13.84 2.76002Z"
            />
        </SvgIcon>
    );
}

LineWeightIcon.defaultProps = {
    titleAccess: 'line-weight-icon',
};

LineWeightIcon.propTypes = svgIconPropTypes;
