import get from 'lodash/get';

const placeholder = '';

export const mapProductionOrderItemFromApi = (data = {}) => {
    const {
        patternCode = placeholder,
        width = 0,
        height = 0,
        parts = [],
        quantity = 0,
        technology = {},
        intermediateProductId = placeholder,
        productId = placeholder,
        printerCode = placeholder,
        components = [],
        prodisShipmentId,
        qrCodedDyeLink = '',
        trackingNumber,
        placeInStack,
        selectedBoxCode,
        selectedCarrierTypeCode,
    } = data;

    return {
        patternCode,
        selectedBoxCode,
        selectedCarrierTypeCode,
        width,
        height,
        parts: parts.map((part) => ({
            width: get(part, 'width', 0),
            height: get(part, 'height', 0),
        })),
        quantity,
        technology: {
            label: get(technology, 'label', placeholder),
            legacyCode: get(technology, 'legacyCode', placeholder),
            legacyId: get(technology, 'legacyId', 0),
            armCode: get(technology, 'armCode', placeholder),
            armId: get(technology, 'armId', placeholder),
        },
        trackingNumber,
        placeInStack,
        intermediateProductId,
        prodisShipmentId,
        productId,
        printerCode,
        qrCodedDyeLink,
        components: components.map((component) => ({
            type: {
                legacyCode: get(component, 'type.legacyCode', placeholder),
                legacyLabel: get(component, 'type.legacyLabel', placeholder),
                armCode: get(component, 'type.armCode', placeholder),
            },
            value: {
                legacyCode: get(component, 'value.legacyCode', placeholder),
                legacyValue: get(component, 'value.legacyValue', placeholder),
                armCode: get(component, 'value.armCode', placeholder),
            },
            quantity: get(component, 'quantity', 0),
        })),
    };
};

export const mapProductionOrderFromApi = (data = {}) => {
    const {
        id,
        orderNumber = placeholder,
        productionOrderNumber = placeholder,
        legacyState = placeholder,
        currentState = {},
        productionArea = placeholder,
        source = placeholder,
        productId = placeholder,
        sku = placeholder,
        simpOrderId = placeholder,
        legacyProductionOrderId = placeholder,
        legacyOrderId = placeholder,
        approvedAt = placeholder,
        paidAt = placeholder,
        notes = [],
        legacyStoreName,
        simpCompany,
        requestHoldShipping = false,
    } = data;

    return {
        id,
        orderNumber,
        productionOrderNumber,
        legacyState,
        currentState,
        productionArea,
        source,
        productId,
        sku,
        simpOrderId,
        legacyProductionOrderId,
        legacyOrderId,
        approvedAt,
        paidAt,
        notes,
        legacyStoreName,
        simpCompany,
        requestHoldShipping,
    };
};

const getTransitions = (transitions) =>
    transitions.map((transition) => ({
        id: get(transition, 'id'),
        allowMultiAction: get(transition, 'allowMultiAction'),
        label: get(transition, 'label', placeholder),
        code: get(transition, 'code', placeholder),
        color: get(transition, 'color', ''),
        formFields: get(transition, 'formFields', []).map((field) => ({
            id: get(field, 'id'),
            label: get(field, 'label', placeholder),
            type: get(field, 'type', placeholder),
            constraints: get(field, 'constraints', []).map((constraint) => ({
                name: get(constraint, 'name'),
                value: get(constraint, 'value'),
            })),
            description: get(field, 'description'),
            properties: get(field, 'properties', []).map((property) => ({
                name: get(property, 'name'),
                value: get(property, 'value'),
            })),
            initialValue: get(field, 'initialValue'),
        })),
    }));

export const mapProductionOrderStatusFromApi = (data = {}) => {
    const { id, transitions = [], currentState = {} } = data;

    return {
        id,
        transitions: getTransitions(transitions),
        currentState: {
            label: get(currentState, 'label', placeholder),
            code: get(currentState, 'code', placeholder),
            user: {
                pdpId: get(currentState, 'user.pdpId', placeholder),
                name: get(currentState, 'user.name', placeholder),
            },
            statusAt: get(currentState, 'statusAt', placeholder),
        },
    };
};

export const mapAweTransitionsFromApi = ({ transitions = [] } = {}) => ({
    transitions: getTransitions(transitions),
});

export const mapProductionOrderGetListWorkers = ({ results, total } = {}) => {
    return {
        items:
            results.map(
                ({
                    id,
                    approvedAt,
                    orderNumber,
                    legacyOrderId,
                    patternCode,
                    shippingCountryCode,
                    statusColor,
                    statusName,
                    storeName,
                    technologyLabel,
                    productionOrderNumber,
                }) => ({
                    id,
                    approvedAt,
                    orderNumber,
                    legacyOrderId,
                    patternCode,
                    shippingCountryCode,
                    statusColor,
                    statusName,
                    storeName,
                    technologyLabel,
                    productionOrderNumber,
                }),
            ) || [],
        totalItems: total || 0,
    };
};

const mapProductionOrderGetListWorkersFiltersParams = ({
    search,
    searchOrderNameOrderNumber,
    searchLegacyOrderId,
    searchLegacyMultipackageId,
    searchLegacyProductionOrderId,
}) => ({
    search,
    searchOrderNameOrderNumber,
    searchLegacyOrderId,
    searchLegacyMultipackageId,
    searchLegacyProductionOrderId,
});

export const mapProductionOrderGetListWorkersOrderParams = ({
    approvedAt = null,
    productionOrderNumber = null,
    technologyLabel = null,
    storeName = null,
    statusName = null,
    shippingCountryCode = null,
    legacyOrderId = null,
}) => ({
    ...(approvedAt && { approvedAt }),
    ...(productionOrderNumber && { productionOrderNumber }),
    ...(technologyLabel && { 'properties.technology.label': technologyLabel }),
    ...(storeName && { 'order.channel.storeLabel': storeName }),
    ...(statusName && { currentPlace: statusName }),
    ...(shippingCountryCode && {
        'order.properties.shippingCountryCode': shippingCountryCode,
    }),
    ...(legacyOrderId && { 'order.legacyOrderId': legacyOrderId }),
});

export const mapProductionOrderGetListWorkersParams = ({
    sort = {},
    filters,
}) => ({
    ...mapProductionOrderGetListWorkersFiltersParams(filters),
    order: mapProductionOrderGetListWorkersOrderParams(sort),
});
