import React from 'react';
import PropTypes from 'prop-types';
import { SubtitleStyled, TitleStyled } from './entryPageTitle.styles';

class EntryPageTitle extends React.PureComponent {
    render() {
        const { title, subtitle, error } = this.props;

        return (
            <div>
                {title && (
                    <TitleStyled
                        variant="h6"
                        align="center"
                    >
                        {title}
                    </TitleStyled>
                )}
                {subtitle && (
                    <SubtitleStyled
                        color={error ? 'error' : 'initial'}
                        align="center"
                    >
                        {subtitle}
                    </SubtitleStyled>
                )}
            </div>
        );
    }
}

EntryPageTitle.propTypes = {
    title: PropTypes.string,
    error: PropTypes.bool,
    subtitle: PropTypes.string,
};

export default EntryPageTitle;
