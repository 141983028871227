import { configError } from 'services/utils/utils';
import { ApplicationJsonHeaders } from 'services/constants';
import type { SimpService } from '../SimpService';
import type { AjaxService } from 'services/ajax/AjaxService';
import { type URLS as URLS_CONFIG } from '../constants';

class CustomerRequest {
    private readonly errorHandlers: SimpService['errorHandlers'];

    constructor(
        simpService: SimpService,
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
    ) {
        this.errorHandlers = simpService.errorHandlers;

        this.getSummary = this.getSummary.bind(this);
    }

    async getSummary(processInstanceId) {
        const url = this.URLS.GET_CUSTOMER_REQUEST_SUMMARY.replace(
            '{processInstanceId}',
            processInstanceId,
        );

        const config = { headers: ApplicationJsonHeaders };
        const errorConfig = configError('get', this.errorHandlers);

        const response = await this.ajaxService.get({
            url,
            config,
            errorConfig,
        });

        return response.data;
    }
}

export default CustomerRequest;
