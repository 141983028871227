import { Map } from 'immutable';

export class ResponseModel {
    constructor(props = {}) {
        this.status = null;
        this.item = props.item || {};
    }

    translateErrorsByFields(errorsByFields, fields) {
        return errorsByFields.reduce(
            (translatedErrors, error, errorField) => {
                let fieldName;

                Object.keys(fields).forEach((fieldKey) => {
                    const field = fields[fieldKey];

                    if (field.apiName === errorField) {
                        fieldName = fieldKey;

                        return false;
                    }
                });

                if (fieldName) {
                    return translatedErrors.set(fieldName, error);
                }

                return translatedErrors;
            }, new Map({})
        );
    }

    getErrors() {
        return new Map({});
    }

    getTotalResults() {

    }

    getResultsById() {

    }

    getItemFromResponse(itemModel) {
        if (itemModel) return new itemModel(this.item);

        return this.item;
    }
}
