import get from 'lodash/get';
import moment from 'moment';
import { mapIntermediateProductFromApi } from './intermediateProduct';

export const mapPrintSpecFromApi = (data = {}) => {
    return {
        id: get(data, 'id', ''),
        name: get(data, 'name', ''),
        comment: get(data, 'comment', ''),
        createdAt: moment(get(data, 'created_at', '')).format('DD/MM/YYYY'),
        lastName: get(data, 'created_by.last_name', ''),
        firstName: get(data, 'created_by.first_name', ''),
        username: get(data, 'created_by.username', ''),
        externalProducer: get(data, 'external_producer', null),
    };
};

export const mapCreatePrintSpecToApi = (data = {}) => {
    return {
        name: get(data, 'name', ''),
        comment: get(data, 'comment', ''),
        external_producer: get(data, 'externalProducer', null),
    };
};

export const mapEditPrintSpecToApi = (data = {}) => {
    return {
        name: get(data, 'name', ''),
        comment: get(data, 'comment', ''),
    };
};

export const mapPrintSpecsIntermediateProductToApi = (data = {}) => {
    return {
        id: get(data, 'id', ''),
    };
};

export const mapPrintSpecsIntermediateProductsFromApi = (data) => {
    return get(data, 'intermediate_products', []).map(
        mapIntermediateProductFromApi,
    );
};

export const mapPrintSpecFilesFromApi = (data = {}) => {
    let createdBy = '';

    if (data.created_by) {
        if (data.created_by.first_name || data.created_by.last_name) {
            createdBy = `${data.created_by.first_name} ${data.created_by.last_name}`;
        } else if (data.created_by.username) {
            createdBy = data.created_by.username;
        }
    }

    return {
        fileName: data.file ? data.file.file_name : '',
        filePath: data.file ? data.file.file_path : '',
        id: data.id || '',
        fileId: data.file ? data.file.id : '',
        createdBy: createdBy,
        createdAt: data.created_at
            ? moment(data.created_at).format('DD/MM/YYYY')
            : '',
        comment: data.comment || '',
    };
};

export const mapPrintSpecFilesCreateToApi = (data) => {
    return {
        file: {
            id: data?.fileId ? `custom-files/${data.fileId}` : '',
        },
        comment: data.printSpecFileComment || '',
    };
};
