import Products from './actions/Products';
import ProductSet from './actions/ProductSet';
import { ProductSetTypesActions } from './actions/productSetTypes/productSetTypes.actions';

import type { IProductsServiceConstructorParams } from './productsService.type';

export class ProductsService {
    private readonly ajaxService: IProductsServiceConstructorParams['services']['ajax'];
    private readonly alert: IProductsServiceConstructorParams['services']['alert'];
    private readonly translator: IProductsServiceConstructorParams['services']['translator'];
    readonly errorHandlers: ReturnType<
        IProductsServiceConstructorParams['errorHandlersConfig']
    >;
    private readonly URLS: IProductsServiceConstructorParams['constants']['URLS'];

    private readonly productSetTypesActions: ProductSetTypesActions;

    public readonly productSet: ProductSet;
    public readonly products: Products;

    private constructor({
        services,
        errorHandlersConfig,
        constants: { URLS },
    }: IProductsServiceConstructorParams) {
        this.ajaxService = services.ajax;
        this.alert = services.alert;
        this.translator = services.translator;
        this.errorHandlers = errorHandlersConfig(services.alert);
        this.URLS = URLS;

        this.products = new Products(this, services, URLS);
        this.productSet = new ProductSet(this, services, URLS);
        this.productSetTypesActions = ProductSetTypesActions.getInstance(
            this,
            services,
            URLS,
        );
    }

    private static instance: ProductsService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        constants,
    }: IProductsServiceConstructorParams): ProductsService {
        if (!ProductsService.instance) {
            ProductsService.instance = new ProductsService({
                services,
                errorHandlersConfig,
                constants,
            });
        }

        return ProductsService.instance;
    }

    getProductSetTypesActions(): ProductSetTypesActions {
        return this.productSetTypesActions;
    }
}
