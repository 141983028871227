/* istanbul ignore file */
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import { getActiveToken } from 'access/votingMechanism.js';

if (get(window, 'env.APP_ENV_WARNING', '') !== 'local') {
    Sentry.init({
        dsn: get(window, 'env.SENTRY_DSN', ''),
        environment: get(window, 'env.APP_ENV_WARNING', ''),
        release: get(window, 'env.APPLICATION_VERSION', ''),
        autoSessionTracking: false,
        attachStacktrace: true,
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category.startsWith('ui')) {
                const target = hint.event.target;

                if (target && target.outerText) {
                    breadcrumb.message = target.outerText;
                }
            }

            const isWarning = breadcrumb.level === 'warning';
            const isInfo = breadcrumb.level === 'info';
            const isConsoleEntry = breadcrumb.category === 'console';

            if (isConsoleEntry && (isWarning || isInfo)) {
                return null;
            }

            return breadcrumb;
        },
        initialScope: (scope) => {
            const decodedToken = getActiveToken();
            const userLanguage = localStorage.getItem('i18nextLng');

            if (decodedToken && Object.keys(decodedToken).length !== 0) {
                scope.setUser({
                    id: decodedToken.id,
                    email: decodedToken.email,
                    username: decodedToken.username,
                    language: userLanguage
                });
            }

            return scope;
        },
    });
}

