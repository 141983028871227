import {
    Drawer,
    drawerClasses,
    List,
    ListItemButton,
    listItemButtonClasses,
    ListItemIcon,
    ListItemText,
    listItemTextClasses,
} from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconButton from 'components/IconButton/iconButton';
import Typography from 'components/Typography';
import type { ComponentProps } from 'react';

const drawerWidth = 300,
    minimizedDrawerWidth = 68;

const listStyles = css`
    height: 100%;
    font-size: 1rem;
    line-height: normal;
    overflow: auto;
`;

const fixedListStyles = ({ theme }) => css`
    position: relative;
    height: auto;
    width: inherit;
    padding: 0;
    background-color: ${theme.palette.common.white};
    margin-left: -1px;
`;

export const ModulesListStyled = styled(List, {
    shouldForwardProp: (prop) => prop !== 'fixed',
})<{ fixed: boolean } & ComponentProps<typeof List>>`
    ${({ fixed }) => (fixed ? fixedListStyles : listStyles)};
`;
ModulesListStyled.displayName = 'ModulesList';

export const DrawerStyled = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'minimized',
})<{ minimized: boolean } & ComponentProps<typeof Drawer>>`
    height: 100%;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }

    ${`& .${drawerClasses.paper}`} {
        height: 100%;
        width: ${drawerWidth}px;
        position: relative;
        background-image: ${({ theme }) => theme.palette.background.navBar};
        transition: ${({ theme }) =>
            theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })};
    }

    ${({ minimized }) =>
        minimized &&
        css`
            width: ${minimizedDrawerWidth}px !important;

            ${`& .${drawerClasses.paper}`} {
                width: ${minimizedDrawerWidth}px;
            }

            & .arrow {
                display: none;
            }
        `};
`;
DrawerStyled.displayName = 'Drawer';

export const MenuButtonStyled = styled(IconButton)`
    margin-left: -15px;
    margin-right: 20px;
`;
MenuButtonStyled.displayName = 'MenuButton';

export const AppTitleStyled = styled(Typography)`
    letter-spacing: 9px;
    font-size: 1.25rem;
`;
AppTitleStyled.displayName = 'AppTitle';

export const ModuleListItemButtonStyled = styled(ListItemButton)`
    min-height: 48px;
    overflow: hidden;
    white-space: normal;

    &:hover {
        background: ${({ theme }) => theme.palette.primary.lighter};
    }

    &:before {
        content: '';
        margin-left: -4px;
        width: 4px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: ${({ theme }) => theme.palette.background.darkMain};
        border-radius: 0 2px 2px 0;
        transition: margin 200ms;
    }

    ${`& .${listItemButtonClasses.selected}`} {
        position: relative;
        background-color: ${({ theme }) =>
            theme.palette.primary.lighter} !important;

        &:before {
            margin-left: 0;
        }
    }
`;
ModuleListItemButtonStyled.displayName = 'ModuleListItemButton';

export const ModuleListItemIconStyled = styled(ListItemIcon)`
    margin-left: 5px;
    min-width: 51px;
`;
ModuleListItemIconStyled.displayName = 'ModuleListItemIcon';

export const ModuleListItemTextStyled = styled(ListItemText)`
    overflow: hidden;
    white-space: nowrap;
`;
ModuleListItemTextStyled.displayName = 'ModuleListItemText';

export const SectionStyled = styled(List)`
    background-color: ${({ theme }) => theme.palette.common.gray.lighten1};
`;
SectionStyled.displayName = 'Section';

export const SectionItemStyled = styled(ListItemButton)`
    padding-left: ${({ theme }) => theme.spacing(9)};

    &:hover {
        background: ${({ theme }) => theme.palette.primary.lighter};
    }

    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 100px;
        position: absolute;
        top: 50%;
        left: 45px;
        transform: translateY(-3px);
        background-color: ${({ theme }) => theme.palette.background.darkMain};
        opacity: 0;
        transition: all 400ms;
    }

    &.active {
        &:before {
            opacity: 1;
            left: 50px;
        }
    }

    & .${listItemTextClasses.root} {
        white-space: nowrap;
    }

    & .${listItemTextClasses.primary} {
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
`;
SectionItemStyled.displayName = 'SectionItem';
