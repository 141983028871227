import { URLS } from './constants';
import { WorkplacesService } from './WorkplacesService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';

export const workplacesServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
    authenticationService: AuthenticationService,
) =>
    WorkplacesService.getInstance({
        URLS,
        services: {
            ajax: ajaxService,
            authentication: authenticationService,
            alertService,
        },
    });
