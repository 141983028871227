import React, { useEffect, useState } from 'react';
import { Toolbar } from '@mui/material';
import { ClearIcon, MenuIcon } from 'components/Icons/Icons';
import Hidden from 'components/Hidden/hidden';
import {
    AppTitleStyled,
    DrawerStyled,
    MenuButtonStyled,
} from './navBar.styles';
import { NavBarNavigation } from './NavBarNavigation';
import type { NavBarModule } from './navBar.type';
import { getTopMatchingPathName } from './navBar.utils';

interface INavBarProps {
    path: string;
    extended: boolean;
    toggleNavBar: () => void;
    toggleMobileNavBar: () => void;
    mobileExtended: boolean;
    modules: NavBarModule[];
}

export const NavBar = ({
    path,
    extended,
    toggleNavBar,
    toggleMobileNavBar,
    mobileExtended,
    modules,
}: INavBarProps) => {
    const [activeModule, setActiveModule] = useState<string | null>(null);
    const [extendedModule, setExtendedModule] = useState<string | null>(null);

    useEffect(() => {
        const topMatchedModulePath = getTopMatchingPathName(modules, path);

        if (activeModule !== topMatchedModulePath) {
            setActiveModule(topMatchedModulePath);
        }

        if (!extended && extendedModule !== null) {
            setExtendedModule(null);
        }
    }, [path, activeModule, extended, extendedModule, modules]);

    const expandModuleHandler = (moduleName: string, isOpen: boolean) => {
        const pathSlug = moduleName.split('/');
        const slugLength = pathSlug.length;
        const isNestedPath = slugLength > 1;

        const newClosedModuleName = isNestedPath ? pathSlug.at(0) : null;

        setExtendedModule(isOpen ? moduleName : newClosedModuleName);

        if (!extended) {
            toggleNavBar();
        }
    };

    return (
        <div>
            <Hidden smUp>
                <DrawerStyled
                    variant="temporary"
                    anchor="left"
                    open={mobileExtended}
                    onClose={toggleMobileNavBar}
                >
                    <NavBarNavigation
                        modules={modules}
                        extended={extended}
                        fixed={false}
                        activeModule={activeModule}
                        extendedModule={extendedModule}
                        expandModuleHandler={expandModuleHandler}
                    />
                    <NavBarNavigation
                        modules={modules}
                        extended={extended}
                        fixed
                        activeModule={activeModule}
                        extendedModule={extendedModule}
                        expandModuleHandler={expandModuleHandler}
                    />
                </DrawerStyled>
            </Hidden>
            <Hidden smDown>
                <DrawerStyled
                    variant="permanent"
                    anchor="left"
                    open={extended}
                    onClose={toggleNavBar}
                    minimized={!extended}
                >
                    <Toolbar>
                        <MenuButtonStyled
                            color="inherit"
                            onClick={toggleNavBar}
                            size="large"
                        >
                            {extended ? <ClearIcon /> : <MenuIcon />}
                        </MenuButtonStyled>
                        <AppTitleStyled
                            variant="h6"
                            noWrap
                        >
                            ARTE
                        </AppTitleStyled>
                    </Toolbar>
                    <NavBarNavigation
                        modules={modules}
                        extended={extended}
                        fixed={false}
                        activeModule={activeModule}
                        extendedModule={extendedModule}
                        expandModuleHandler={expandModuleHandler}
                    />
                    <NavBarNavigation
                        modules={modules}
                        extended={extended}
                        fixed
                        activeModule={activeModule}
                        extendedModule={extendedModule}
                        expandModuleHandler={expandModuleHandler}
                    />
                </DrawerStyled>
            </Hidden>
        </div>
    );
};
