import { serviceMap, serviceUrls } from 'components/Camunda/utils';
import { ApplicationJsonHeaders } from '../../constants';
import { toIdMap } from 'utils/immutable/list';

class FormService {
    acceptedTypes = ['radiobutton', 'multiselect', 'autocomplete'];

    constructor(services, URLS, DEFAULTS) {
        this.services = services;
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.ajax = services.ajax;
    }

    serviceType = (properties) => {
        const serviceProperty = properties.find((property) => property.get('name') === 'formDictionary');

        if (serviceProperty) {
            const url = serviceProperty.get('value') ? serviceProperty.get('value') : null;
            const type = url ? url.substring(0, serviceProperty.get('value').indexOf('/')) : null;
            const name = serviceMap[type] ? serviceMap[type] : null;

            return url && name && {
                name: serviceMap[type],
                url,
            };
        }
    };

    getControlsWithGroups = (controls, name = 'group_name') => {
        return controls ? controls?.filter((control) => control[name])?.reduce((prev, curr) => {
            return prev[curr[name]]
                ? ({ ...prev, [curr[name]]: [...prev[curr[name]], curr] })
                : ({ ...prev, [curr[name]]: [curr] });
        }, {}) : {};
    };

    getFormValues = async (code) => {
        const address = `${code}/values`;
        const url = this.URLS.GET_DICTIONARY_CONTROLS.replace('{code}', address);
        const config = {
            params: {
                page: 1,
                itemsPerPage: 100,
            },
            headers: ApplicationJsonHeaders,
        };
        const { data: { results } } = await this.ajax.get({ url, config });

        return results.map((result) => ({ ...result, id: result.code }));
    };

    getFormValuesCustomUrl = async (url) => {
        const config = {
            params: {
                page: 1,
                itemsPerPage: 100,
            },
            headers: ApplicationJsonHeaders,
        };
        const { data: { results } } = await this.ajax.get({ url, config });

        return results.map((result) => ({ ...result, id: result.code }));
    };

    getFormFieldValue = async (service, getService, dicCode, storeActions = {}) => {
        const { store } = this.services;
        const {
            request = () => ({ type: '' }),
            failure = () => ({ type: '' }),
            success = () => ({ type: '' }),
        } = storeActions;

        try {
            let fieldValue;

            const segments = service.url.split('/');
            const [type] = segments;
            const baseUrl = serviceUrls?.[type];

            if (baseUrl) {
                const parsedUrl = segments.join('/').replace(type, baseUrl);

                fieldValue = await this.getFormValuesCustomUrl(parsedUrl);
            } else if (dicCode && !baseUrl) {
                fieldValue = await this.getFormValues(dicCode);
            } else {
                const chosenService = getService(service.name);

                fieldValue = await chosenService.getFormValues(service.url);
            }
            await store.dispatch(request());
            await store.dispatch(success());

            return fieldValue;
        } catch (e) {
            return store.dispatch(failure(e));
        }
    };

    markUploadForm = (fieldType, setUploadForm) => {
        const isUpload = fieldType === 'fileupload';

        return setUploadForm(isUpload);
    };

    generateForm = async (formFields, setUploadForm, getService) => {
        const mappedFields = formFields.toArray().map(async (formField) => {
            const properties = formField.get('properties');
            const constrains = formField.get('constraints');
            const control = properties.find((property) => property.get('name') === 'controlType');
            const label = formField.get('label');
            const type = formField.get('type');
            const name = formField.get('id');
            const fieldType = control.get('value');
            const description = formField.get('description');
            const initialValue = formField.get('initialValue');
            const readOnly = properties
                .find((property) => property.get('name') === 'readOnly')?.get('value') || false;

            if (setUploadForm) {
                this.markUploadForm(fieldType, setUploadForm);
            }

            const required = constrains.size > 0 && toIdMap(constrains, 'name').get('required').get('value');

            if (control && this.acceptedTypes.includes(fieldType)) {
                const dicCode = properties
                    .find((property) => property.get('name') === 'dictionaryCode')?.get('value') || null;
                const isGrouped = properties
                    .find((property) => property.get('name') === 'isGrouped')?.get('value') || null;
                const service = this.serviceType(properties);

                const controls = await this.getFormFieldValue(service, getService, dicCode);
                const groupMark = dicCode ? 'group_name' : 'reasonGroup';
                const controlsWithGroups = this.getControlsWithGroups(controls, groupMark);
                const contrWithGroups = dicCode && isGrouped ? true : Object.keys(controlsWithGroups).length > 0;
                const activeControls = contrWithGroups ? controlsWithGroups : controls;

                return {
                    field: formField,
                    description,
                    label,
                    type,
                    data: activeControls,
                    name,
                    fieldType,
                    constrains,
                    required,
                    readOnly,
                    contrWithGroups,
                    initialValue,
                };
            }

            return {
                field: formField,
                description,
                label,
                type,
                data: type,
                name,
                fieldType,
                constrains,
                required,
                readOnly,
                initialValue,
                contrWithGroups: false,
            };
        });

        return Promise.all(mappedFields);
    };
}

export default FormService;
