import { get } from 'lodash';

import { paramsSerializer } from '../../utils/utils';

import {
    mapScopeFromApi,
    mapScopeToApi,
    mapScopeGtinFromApi,
} from '../mappers/scope';
import { ApplicationJsonHeaders, HttpPatchHeaders } from 'services/constants';
import type { SalesChannelsService } from '../SalesChannelsService';

class Scope {
    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.salesChannelsService = salesChannelsService;

        this.getList = this.getList.bind(this);
        this.getScope = this.getScope.bind(this);
        this.createScope = this.createScope.bind(this);
        this.updateScope = this.updateScope.bind(this);
        this.deleteScope = this.deleteScope.bind(this);
        this.getScopeGtins = this.getScopeGtins.bind(this);
    }

    async getList() {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;

        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page: 1,
                itemsPerPage: 1000,
            },
        };

        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const url = URLS.GET_SCOPES;

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            items: get(data, 'results', []).map(mapScopeFromApi),
            totalItems: get(data, 'total', 0),
        };
    }

    async getScope(id) {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const url = URLS.GET_SCOPE.replace('{id}', id);

        const { data } = await ajax.get({ url, config, errorConfig });

        return mapScopeFromApi(data);
    }

    async createScope(data) {
        const { ajax, alert, translator, URLS, errorHandlers } =
            this.salesChannelsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const url = URLS.POST_SCOPE;

        const response = await ajax.post({
            url,
            config,
            errorConfig,
            data: mapScopeToApi(data),
        });

        alert.addSuccess({
            message: translator.t('arm:alerts.eanPoolCreated'),
        });

        return mapScopeFromApi(response.data);
    }

    async updateScope({ id, data }) {
        const { ajax, alert, translator, errorHandlers, URLS } =
            this.salesChannelsService;

        const config = {
            headers: HttpPatchHeaders,
        };

        const errorConfig = {
            throwError: errorHandlers.patch,
            addGenericAlert: false,
        };

        const url = URLS.PATCH_SCOPE.replace('{id}', id);

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: mapScopeToApi(data),
        });

        alert.addSuccess({
            message: translator.t('arm:alerts.eanPoolUpdated'),
        });

        return mapScopeFromApi(response.data);
    }

    async deleteScope(id) {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        const url = URLS.DELETE_SCOPE.replace('{id}', id);

        return await ajax.delete({ url, config, errorConfig });
    }

    async getScopeGtins({
        id,
        params = {},
    }: {
        id: string;
        params?:
            | {
                  page?: number | undefined;
                  per_page?: number | undefined;
                  filters?: any;
                  sort?: string | undefined;
              }
            | undefined;
    }) {
        const { ajax, errorHandlers, URLS } = this.salesChannelsService;

        const { page, per_page, filters, sort } = params;

        const config = {
            paramsSerializer,
            headers: ApplicationJsonHeaders,
            params: {
                ...filters,
                page,
                itemsPerPage: per_page,
                order: sort,
            },
        };

        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const url = URLS.GET_SCOPE_GTINS.replace('{id}', id);

        const { data } = await ajax.get({ url, config, errorConfig });

        return {
            items: get(data, 'results', []).map((data_1) =>
                mapScopeGtinFromApi(data_1),
            ),
            totalItems: get(data, 'total', 0),
        };
    }
}

export default Scope;
