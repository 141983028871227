import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        ORDER: {
            DISPLAY: `${MODULE_NAME}_resources_order_display`
        },
        PRESET: {
            CREATE: `${MODULE_NAME}_resources_preset_create`,
            EDIT: `${MODULE_NAME}_resources_preset_edit`,
            DELETE: `${MODULE_NAME}_resources_preset_delete`,
        },
    },
    UI: {
        BALANCE: `${MODULE_NAME}_ui_balance`,
        HEADER: `${MODULE_NAME}_ui_header`,
        ADDRESS: `${MODULE_NAME}_ui_address`,
        NOTES: `${MODULE_NAME}_ui_notes`,
        STATES: `${MODULE_NAME}_ui_states`,
        AWE_STATES: `${MODULE_NAME}_ui_awe_states`,
        PRINT_AGAIN: `${MODULE_NAME}_ui_print_again`,
    }
};
