import { URLS } from './constants';
import errorHandlersConfig from './errorHandlers';
import { DiscountsService } from './DiscountsService';
import { AjaxService } from 'services/ajax/AjaxService';
import { AlertService } from 'services/alert/AlertService';

export const discountsServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    DiscountsService.getInstance({
        URLS,
        errorHandlersConfig,
        services: {
            ajax: ajaxService,
            alert: alertService,
        },
    });
