import { put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'react-router-redux';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import * as C from './constants';
import * as A from './actions';
import i18n, { FALLBACK_LANGUAGE } from 'i18n';
import { getRefreshToken, removeActiveToken, removeRefreshToken, setRefreshToken } from 'utils/tokenHelpers';
import { delay } from 'utils/redux/helpers';
import { getActiveToken } from 'access/votingMechanism.js';

const API = window.env && window.env.PDP_API_URL;
const login = makeEndPoint(API + '/api');
const changePasswordRequest = makeEndPoint(API + '/api/change_password_requests');
const changePassword = makeEndPoint(API + '/api/change_passwords');

export default function* usersSaga() {
    yield takeLatest(C.LOGIN_USER.request, postUser);
    yield takeLatest(C.RESET_TOKEN.request, prepareToResetActiveToken);
    yield takeLatest(C.REQUEST_CHANGE_PASSWORD.request, postChangePasswordRequest);
    yield takeLatest(C.CHANGE_PASSWORD.request, postChangePassword);
}

function* prepareToResetActiveToken(data) {
    const token = getActiveToken();
    const expTime = moment.unix(token.exp).diff(moment(), 'milliseconds');
    const delayTime = expTime - 90000;

    if (!(data && data.payload && data.payload.noDelay)) {
        yield delay(delayTime);
    }
    const refreshToken = getRefreshToken();

    if (refreshToken) {
        const response = yield login.post({
            action: A.resetToken,
            path: 'token/refresh',
            data: JSON.stringify({ refresh_token: refreshToken }),
        });

        if (response && response.status >= 200 && response.status < 300) {
            setRefreshToken(response.data.refresh_token);
            yield prepareToResetActiveToken();
        } else {
            yield put(A.logoutUser());
        }
    }
}

function* postUser({ payload }) {
    removeActiveToken();
    removeRefreshToken();

    const username = payload.credentials.username && payload.credentials.username.trim();
    const password = payload.credentials.password;

    const response = yield login.post({
        action: A.loginUser,
        path: 'login',
        data: JSON.stringify({ username, password }),
    });

    if (response && response.data && response.data.refresh_token) {
        setRefreshToken(response.data.refresh_token);
        yield prepareToResetActiveToken();
    }
}

function* postChangePasswordRequest({ payload }) {
    const login = payload.login;
    const lang = i18n.language || FALLBACK_LANGUAGE;

    yield changePasswordRequest.post({
        action: A.requestChangePassword,
        data: JSON.stringify({ login, lang }),
        alerts: {
            failure: true,
        },
        sagaCallbacks: {
            success: () => push({
                pathname: '/confirm-request-send',
                search: `?login=${login}`,
            }),
        },
    });
}

function* postChangePassword({ payload }) {
    const { password, email, token } = payload;

    yield changePassword.post({
        action: A.changePassword,
        data: JSON.stringify({ email, password, token }),
        alerts: {
            failure: true,
        },
        sagaCallbacks: {
            success: () => push({
                pathname: '/confirm-password-change',
            }),
        },
    });
}
