import { fromJS, Map } from 'immutable';

export const HeadersAcceptType = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as const;

export const AcceptTextCsv = {
    Accept: HeadersAcceptType.csv,
} as const;

export const SentryReportingHttpStatusesBlacklist = [403, 401] as const;

export const applicationJson = 'application/json';

export const HttpPatchHeaders = {
    'Content-Type': 'application/merge-patch+json',
    Accept: applicationJson,
} as const;

export const ContentTypeJsonHeader = {
    'Content-Type': applicationJson,
} as const;

export const ApplicationJsonHeaders = {
    'Content-Type': applicationJson,
    Accept: applicationJson,
} as const;

export const TextCsvHeaders = {
    'Content-Type': 'text/csv',
    Accept: 'text/csv',
} as const;

export const ApplicationJsonLdHeaders = {
    'Content-Type': 'application/ld+json',
    Accept: 'application/ld+json',
} as const;

export const DEFAULT_DEBOUNCE_DELAY = 1500;

export const MAXIMUM_ITEMS_COUNT = 1000;

export const FILTER_MATCHER_TYPES = {
    INSENSITIVE_EXACT: 'iexact',
    EXACT: 'exact',
    INSENSITIVE_LIKE: 'ilike',
    PARTIAL: 'part',
    INSENSITIVE_PARTIAL: 'ipart',
    IN_VALUE_ARRAY: 'in',
    DATE_RANGE: 'dateTimeRange',
} as const;

export const FILTER_FIELD_TYPES = {
    TEXT_FIELD: 'textField',
    SWITCH_FIELD: 'switchField',
    AUTOCOMPLETE_FIELD: 'autoCompleteField',
    MULTI_SELECT_FIELD: 'multiSelectField',
    DATE_RANGE_PICKER_FIELD: 'dateRangePickerField',
} as const;

export const FILTER_DEFAULT_VALUES = {
    TEXT_FIELD: '',
    SWITCH_FIELD: false,
    AUTOCOMPLETE_FIELD: '',
    MULTI_SELECT_FIELD: [],
    DATE_RANGE_PICKER_FIELD: null,
    DATE_PICKER_FIELD: null,
} as const;

export const ADVANCED_FILTER_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
export const FORMAT_ISO8601 = 'YYYY-MM-DD[T]HH:mm:ssZ';
export const FORMAT_DD_MM_YYYY_HH_mm = 'DD_MM_YYYY_HH_mm';
export const FORMAT_UNIX_TIMESTAMP = 'X';
export const FORMAT_DD_MM_YYYY_HH_mm_WITH_SLASH = 'DD/MM/YYYY HH:mm';
export const FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY';
export const FORMAT_DDMMYYYY_HHmm = 'DDMMYYYY_HHmm';
export const FORMAT_DD_MM_YYYY_HH_mm_ss = 'DD/MM/YYYY HH:mm:ss';
export const FORMAT_YYYY_MM_DD_HH_mm_ssZ = 'YYYY-MM-DD HH:mm:ssZ';
export const FORMAT_YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss';

export const EMPTY_IMMUTABLE_OBJECT = fromJS({});

export const EMPTY_IMMUTABLE_RESPONSE = {
    items: Map({}),
    itemsTotal: 0,
} as const;

export const EMPTY_PROMISE_RESULT = Promise.resolve({
    items: [],
    totalItems: 0,
});
