import type { MediaFilesService } from '../mediaFiles/MediaFilesService';
import { SimpService } from './SimpService';
import errorHandlers from './errorHandlers';
import { DEFAULTS, URLS } from './constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const simpServiceFactory = (
    mediaFilesService: MediaFilesService,
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    SimpService.getInstance(
        ajaxService,
        mediaFilesService,
        URLS,
        DEFAULTS,
        errorHandlers(alertService),
    );
