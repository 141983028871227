import get from 'lodash/get';
import parser from 'qs';
import { HeadersAcceptType } from 'services/constants';
import isEmpty from 'lodash/isEmpty';
import { mapFiltersToApi } from 'utils/filters';
import {
    mapTechnologyFromApi,
    mapTechnologyToApi,
    mapTechnologyAutocomplete,
} from '../mappers/technology';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

import { ICreateDTO } from './technologies.type';

const hydraMember = 'hydra:member';

class Technologies {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.autocomplete = this.autocomplete.bind(this);
        this.autocompleteBasic = this.autocompleteBasic.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    private paramsSerializer = (params) => parser.stringify(params);

    async getById(id: string) {
        const { ajax } = this.services;
        const url = this.URLS.GET_TECHNOLOGY.replace('{id}', id);

        const response = await ajax.get({ url });

        return mapTechnologyFromApi(response.data);
    }

    async autocomplete({ filters, per_page = 50, page = 1 }) {
        const { ajax } = this.services;
        const search = get(filters, 'search[$match]', '');
        const url = this.URLS.GET_TECHNOLOGIES;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (search) {
            const filtersParams = { filter: { search: { match: search } } };

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        if (typeof filters?.external_producer === 'boolean') {
            const filtersParams = {
                filter: { external_producer: filters?.external_producer },
            };

            config.params = {
                ...config.params,
                filter: {
                    ...config.params?.filter,
                    ...filtersParams?.filter,
                },
            };
        }

        try {
            const response = await ajax.get({ url, config });

            return (response?.data?.[hydraMember] ?? []).map(
                mapTechnologyFromApi,
            );
        } catch {
            return [];
        }
    }

    async autocompleteBasic({ filters, per_page = 50, page = 1 }) {
        const { ajax } = this.services;
        const search = get(filters, 'search[$match]', '');
        const url = this.URLS.GET_TECHNOLOGIES_AUTOCOMPLETE;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer: this.paramsSerializer,
        };

        config.params = {
            ...config.params,
            filter: {
                ...(search && { search: { match: search } }),
                ...(typeof filters?.external_producer === 'boolean' && {
                    external_producer: filters?.external_producer,
                }),
                ...(filters?.technologyId && {
                    id: {
                        in: Array.isArray(filters.technologyId)
                            ? filters.technologyId
                            : [filters.technologyId],
                    },
                }),
            },
        };

        const response = await ajax.get({ url, config });

        return (response?.data?.[hydraMember] ?? []).map(
            mapTechnologyAutocomplete,
        );
    }

    async getCsvFile(
        { filters = {}, per_page = 1000, page = 1, sort = {} },
        fileName,
    ) {
        const { ajax } = this.services;

        const config = {
            params: {
                per_page,
                page,
                sort,
            },
            headers: {
                Accept: HeadersAcceptType.csv,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (!isEmpty(filters)) {
            const filtersParams = mapFiltersToApi(filters);

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        return await ajax.download({
            url: this.URLS.GET_TECHNOLOGIES_CSV_FILE,
            config,
            fileName,
        });
    }

    async getList(params = {}) {
        const { ajax } = this.services;
        const { filters = {}, per_page = 20, page = 1, sort } = params;
        const url = this.URLS.GET_TECHNOLOGIES;

        const filtersParams = mapFiltersToApi(filters);

        const config = {
            params: {
                page,
                per_page,
                sort,
                ...filtersParams,
            },
            paramsSerializer: this.paramsSerializer,
        };

        try {
            const response = await ajax.get({ url, config });
            const items = (response?.data?.[hydraMember] ?? []).map(
                mapTechnologyFromApi,
            );
            const itemsTotal = response?.data?.['hydra:totalItems'] ?? 0;

            return {
                items: items,
                itemsTotal,
            };
        } catch {
            return {
                items: [],
                itemsTotal: 0,
            };
        }
    }

    async delete({ id }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;
        const url = this.URLS.GET_TECHNOLOGY.replace('{id}', id);

        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        return await ajax.delete({ url, errorConfig });
    }

    async create(data: ICreateDTO) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_TECHNOLOGIES;
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        try {
            const response = await ajax.post({
                url,
                errorConfig,
                data,
            });

            return response.data;
        } catch {
            return undefined;
        }
    }

    async edit(id: string, data) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_TECHNOLOGY.replace('{id}', id);
        const errorConfig = {
            throwError: errorHandlers.putTechnologies,
            addGenericAlert: false,
        };

        const { data: data_2 } = await ajax.put({
            url,
            data: mapTechnologyToApi(data),
            errorConfig,
        });

        return mapTechnologyFromApi(data_2);
    }
}

export default Technologies;
