import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import orders from './orders/reducer';
import ordersSaga from './orders/saga';
import * as ordersActions from './orders/actions';

export const salesReducers = combineReducers({
    orders,
});

export const salesSagas = [
    fork(ordersSaga),
];

export const salesActions = [
    ordersActions,
];
