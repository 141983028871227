import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CopyIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            { ...props }
        >
            <path
                d="M6.08 0V1.92L6.72 2.56V0.64H11.52V4.48H15.36V12.8H10.56V13.44H16V4.03L11.97 0H6.08ZM12.16 1.09L14.91 3.84H12.16V1.09ZM0 2.56V16H9.92V6.59L9.83 6.49L5.99 2.65L5.89 2.56H0ZM0.64 3.2H5.44V7.04H9.28V15.36H0.64V3.2ZM6.08 3.66L8.82 6.4H6.08V3.66Z"
            />
        </SvgIcon>
    );
}

CopyIcon.defaultProps = {
    titleAccess: 'copy-icon',
};

CopyIcon.propTypes = svgIconPropTypes;
