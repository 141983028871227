import React from 'react';
import * as PropTypes from 'prop-types';
import Select from 'components/Select/select';
import { Loader } from 'components/Loader/Loader';
import { MenuItem } from '@mui/material';
import { getString } from 'utils/immutable/map';

export class ProductCatalogSelect extends React.PureComponent {
    componentDidMount() {
        const { fetchProductCatalogs } = this.props;

        fetchProductCatalogs();
    }

    renderOptions() {
        const { productCatalogs } = this.props;

        if (productCatalogs.size > 0) {
            const result = [];

            productCatalogs.forEach((productCatalog) => {
                const id = getString(productCatalog, 'id');

                result.push(
                    <MenuItem
                        key={ id }
                        value={ id }
                        component="li"
                        button={ true }
                    >
                        {productCatalog.get('name')}
                    </MenuItem>
                );
            });

            return result;
        } else {
            return (
                <MenuItem
                    component="li"
                    button={ false }
                    value=""
                >
                    <Loader />
                </MenuItem>
            );
        }
    }

    render() {
        const { t, value, onChange, onBlur, error, helperText } = this.props;

        return (
            <Select
                fullWidth
                value={ value }
                onChange={ onChange }
                onBlur={ onBlur }
                label={ t('labels.productCatalog') }
                error={ error }
                helperText={ helperText }
            >
                {this.renderOptions()}
            </Select>
        );
    }
}

ProductCatalogSelect.defaultProps = {
    t: (key) => key,
};

ProductCatalogSelect.propTypes = {
    t: PropTypes.func,
    fetchProductCatalogs: PropTypes.func.isRequired,
    productCatalogs: PropTypes.object,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string.isRequired,
};
