import WzShipments from './actions/WzShipments';

export class OdaService {
    private constructor({ services, CONSTANTS }) {
        this.ajaxService = services.ajax;
        this.alert = services.alert;
        this.parser = services.parser;
        this.translator = services.translator;
        this.constants = CONSTANTS;

        this.wzShipments = new WzShipments(this);

        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    private static instance: OdaService | undefined;

    public static getInstance({ services, CONSTANTS }): OdaService {
        if (!OdaService.instance) {
            OdaService.instance = new OdaService({ services, CONSTANTS });
        }

        return OdaService.instance;
    }

    paramsSerializer = (params) => this.parser.stringify(params);
}
