import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function AlertIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 14 14"
            { ...props }
        >
            <path
                d="M7 0C3.13761 0 0 3.13761 0 7C0 10.8624 3.13761 14 7 14C10.8624 14 14 10.8624 14 7C14 3.13761 10.8624 0 7 0ZM7 0.608696C10.5334 0.608696 13.3913 3.46657 13.3913 7C13.3913 10.5334 10.5334 13.3913 7 13.3913C3.46657 13.3913 0.608696 10.5334 0.608696 7C0.608696 3.46657 3.46657 0.608696 7 0.608696ZM6.63502 3.74372C6.54372 3.74372 6.51316 3.80411 6.51316 3.86498V7.97367C6.51316 8.06498 6.57415 8.09553 6.63502 8.09553H7.33466C7.42597 8.09553 7.45652 8.03454 7.45652 7.97367V3.86498C7.45652 3.77367 7.39553 3.74372 7.33466 3.74372H6.63502ZM6.63502 9.22198C6.54372 9.22198 6.51316 9.28237 6.51316 9.34324V10.135C6.51316 10.2263 6.57415 10.2563 6.63502 10.2563H7.36498C7.45628 10.2563 7.48684 10.1959 7.48684 10.135V9.34324C7.48684 9.25194 7.42585 9.22198 7.36498 9.22198H6.63502Z"
            />
        </SvgIcon>
    );
}

AlertIcon.defaultProps = {
    titleAccess: 'alert-icon',
};

AlertIcon.propTypes = svgIconPropTypes;
