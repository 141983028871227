import get from 'lodash/get';
import {
    mapRoleGroupsFromApi,
    mapRoleGroupFromApi,
    mapRoleGroupPutToApi,
    mapRoleGroupPostToApi,
} from '../mappers/roleGroups';

class RoleGroups {
    constructor(pdpService) {
        this.pdpService = pdpService;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
    }

    getList(params = {}) {
        const {
            ajax,
            URLS,
            DEFAULTS,
            errorHandlers,
            paramsSerializer,
        } = this.pdpService;
        const {
            page = 1,
            per_page = DEFAULTS.PER_PAGE,
            filters = {},
        } = params;

        const url = URLS.ROLE_GROUPS;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer,
        };

        const errorConfig = {
            addGenericAlert: false,
            onError: errorHandlers.get,
        };

        if (filters.search) config.params['name'] = filters.search;

        return ajax
            .get({ url, config, errorConfig })
            .then((response) => {
                return ({
                    items: get(response, 'data.hydra:member', []).map(mapRoleGroupsFromApi),
                    totalItems: get(response, 'data.hydra:totalItems', 0),
                });
            });
    }

    getById({ id }) {
        const {
            ajax,
            URLS,
        } = this.pdpService;
        const url = URLS.ROLE_GROUP_ID.replace('{id}', id);

        return ajax
            .get({ url })
            .then(({ data }) => {
                return mapRoleGroupFromApi(data);
            });
    }

    edit({ roleGroup, id }) {
        const {
            ajax,
            URLS,
        } = this.pdpService;
        const url = URLS.ROLE_GROUP_ID.replace('{id}', id);
        const data = mapRoleGroupPutToApi(roleGroup);

        return ajax
            .put({ url, data })
            .then(({ data }) => {
                return mapRoleGroupFromApi(data);
            });
    }

    create({ roleGroup }) {
        const {
            ajax,
            URLS,
        } = this.pdpService;
        const url = URLS.ROLE_GROUPS;
        const data = mapRoleGroupPostToApi(roleGroup);

        return ajax
            .post({ url, data })
            .then(({ data }) => {
                return mapRoleGroupFromApi(data);
            });
    }

    delete(id) {
        const {
            ajax,
            URLS,
        } = this.pdpService;
        const url = URLS.ROLE_GROUP_ID.replace('{id}', id);

        return ajax.delete({ url });
    }
}

export default RoleGroups;
