import LZString from 'lz-string';

const STORAGE_ERROR_HISTORY_KEY = 'errorHistory';
const MAX_ERRORS = 10;

interface IStoredError {
    id: string;
    data: Error;
    context: {
        location: { pathname: string };
        timestamp: number;
    };
}

export class ErrorHistory {
    private constructor(private readonly storage: Storage) {
        this.addError = this.addError.bind(this);
        this.getErrors = this.getErrors.bind(this);
    }

    private static instance: ErrorHistory | undefined;

    public static getInstance(storage: Storage): ErrorHistory {
        if (!ErrorHistory.instance) {
            ErrorHistory.instance = new ErrorHistory(storage);
        }

        return ErrorHistory.instance;
    }

    addError(error: Error) {
        const errors = this.getErrors() || [];
        const timestamp = new Date().getTime();
        const id = btoa(
            `${JSON.stringify(error.message).replace(
                /[^a-zA-Z0-9]/g,
                '',
            )}${timestamp}`,
        );

        errors.unshift({
            id,
            data: error,
            context: {
                location: { pathname: window?.location?.pathname },
                timestamp,
            },
        });

        if (errors.length > MAX_ERRORS) errors.pop();

        try {
            this.storage.setItem(
                STORAGE_ERROR_HISTORY_KEY,
                ErrorHistory.encodeStorageList(errors),
            );
        } catch (error) {
            if (
                error instanceof DOMException &&
                error.name.includes('QuotaExceededError')
            ) {
                errors.pop();
                this.storage.setItem(
                    STORAGE_ERROR_HISTORY_KEY,
                    ErrorHistory.encodeStorageList(errors),
                );
            }
        }
    }

    getErrors(): IStoredError[] {
        const storageString = this.storage.getItem(STORAGE_ERROR_HISTORY_KEY);

        return ErrorHistory.decodeStorageList(storageString) || [];
    }

    private static decodeStorageList(stringList: string): IStoredError[] {
        let storageList: IStoredError[] = [];

        try {
            storageList = JSON.parse(LZString.decompressFromUTF16(stringList));
        } catch {
            storageList = JSON.parse(stringList);
        }

        return storageList;
    }

    private static encodeStorageList(arrayList: IStoredError[]): string {
        return LZString.compressToUTF16(JSON.stringify(arrayList));
    }
}
