import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import items from './items/reducer';
import itemsSaga from './items/saga';
import * as itemsActions from './items/actions';

export const demoReducers = combineReducers({
    items,
});

export const demoSagas = [
    fork(itemsSaga),
];

export const demoActions = [
    itemsActions,
];
