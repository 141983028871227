import { Map } from 'immutable';
import { ResponseModel } from 'reducerConfigurator/responseModel/responseModel';
import { toIdMap } from 'utils/immutable/list';
import { mapFiltersParamsTypes } from 'utils/filters';

function getViolations(responseData = {}) {
    if (responseData.violations) {
        return responseData.violations.reduce(reduceViolations, Map());
    }

    return Map();
}

function reduceViolations(violationsMap, violation) {
    const { propertyPath, title } = violation;

    return violationsMap.set(propertyPath, title);
}

export class StockResponse extends ResponseModel {
    constructor(props = {}) {
        super(props);

        const {
            data = {},
        } = props;

        this.item = data;
        this.results = data.results || [];
        this.page = data.page || 1;
        this.total = data.total || 1;
        this.violations = getViolations(data);

        this.status = props.status || 500;
        this.statusText = props.statusText || 'Unexpected error';
    }

    static getListRequestParams({ defaults: requestDefaults = {}, urlParams = {} }) {
        const params = {};
        const {
            pagination = {},
            filters = {},
            order = {},
        } = urlParams;
        const defaults = {
            page: 1,
            per_page: 20,
            ...requestDefaults,
        };

        let filtersWithSearch = filters.search ?
            { ...filters, search: { '$match': filters.search } }
            : defaults.search ? { ...filters, search: { '$match': defaults.search } } : filters;

        if (defaults['name']) filtersWithSearch = { ...filtersWithSearch, name: { '$match': defaults['name'] } };

        params.page = pagination.page || defaults.page;
        params.per_page = pagination.per_page || defaults.per_page;
        params.filters = mapFiltersParamsTypes(filtersWithSearch);
        params.sort = order;

        const isImportedIndex = params.filters['$and'] ? params.filters['$and'].findIndex((e) => e.is_imported) : -1;

        if (isImportedIndex !== -1) {
            params.filters['$and'][isImportedIndex].is_imported['$eq'] = false;
        }

        if (params.filters.dedicated_channel_id && params.filters.dedicated_channel_id['$in']) {
            params.filters.dedicated_channel_id['$in'] = Array(params.filters.dedicated_channel_id['$in']);
        }

        return params;
    }

    static getAuthorsRequestParams({ defaults: requestDefaults = {}, urlParams = {} }) {
        const params = {};
        const {
            pagination = {},
            filters = {},
            order = {},
        } = urlParams;
        const defaults = {
            page: 1,
            per_page: 20,
            ...requestDefaults,
        };

        params.page = pagination.page || defaults.page;
        params.per_page = pagination.per_page || defaults.per_page;
        Object.entries(filters).forEach(([key, value]) => {
            params[key] = key === 'search' ? value : value[0];
        });
        Object.entries(order).forEach(([key, value]) => {
            params[`order[${key}]`] = value;
        });

        return params;
    }

    getErrors(model) {
        if (model && model.fields) {
            return this.translateErrorsByFields(this.violations, model.fields);
        }

        return this.violations;
    }

    getTotalResults() {
        return this.total;
    }

    getResultsById(model) {
        return toIdMap(this.results, model);
    }

    getItemFromResponse(model) {
        return new model(this.item);
    }

    downloadFile(action) {
        const url = window.URL.createObjectURL(new Blob([action.payload.response.item]));
        const link = document.createElement('a');
        const fileName = action.payload.fileName;

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
