import React from 'react';
import upperFirst from 'lodash/upperFirst';
import Modal from 'components/Modal/modal';
import PropTypes from 'prop-types';

const ExitEditModal = ({ t, ...props }) => (
    <Modal
        { ...props }
        leftButton={ upperFirst(t('common:actions.cancel')) }
        rightButton={ upperFirst(t('common:actions.ok')) }
        label={ t('common:labels.editModal') }
    >
        { t('common:contents.editModal') }
    </Modal>
);

ExitEditModal.defaultProps = {
    t: (key) => key,
};

ExitEditModal.propTypes = {
    t: PropTypes.func,
};

export default ExitEditModal;
