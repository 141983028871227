import type { ComponentProps } from 'react';
import { CircularProgress, type SxProps } from '@mui/material';
import { LoaderContainerStyled } from './loader.styles';

interface LoaderProperties {
    className?: string | undefined;
    noMargins?: boolean | undefined;
    simple?: boolean | undefined;
    fullScreen?: boolean | undefined;
    loaderOnly?: boolean | undefined;
    size?: number | string | undefined;
    color?: ComponentProps<typeof CircularProgress>['color'] | undefined;
    'aria-label'?: string | undefined;
    sx?: SxProps | undefined;
}

export const Loader = ({
    className,
    loaderOnly,
    fullScreen,
    size,
    color,
    noMargins,
    simple,
    sx,
    'aria-label': ariaLabel,
}: LoaderProperties) => {
    if (loaderOnly) {
        return (
            <CircularProgress
                aria-label={ariaLabel}
                className={className}
                size={size}
                color={color}
            />
        );
    }

    return (
        <LoaderContainerStyled
            aria-label={ariaLabel}
            simple={simple}
            fullScreen={fullScreen}
            noMargins={noMargins}
        >
            <CircularProgress
                className={className}
                size={size}
                color={color}
                sx={sx}
            />
        </LoaderContainerStyled>
    );
};
