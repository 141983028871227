import { AppBar, IconButton, ListItem } from '@mui/material';
import styled from '@emotion/styled';
import Typography from 'components/Typography';

const getHeaderColor = (theme) => {
    switch (window.env.APP_ENV_WARNING) {
        case 'local':
            return theme.palette.header.local;
        case 'test':
            return theme.palette.header.test;
        case 'stage':
            return theme.palette.header.stage;
        default:
            return theme.palette.header.main;
    }
};

export const UserInfoListItemStyled = styled(ListItem)`
  display: block;
  min-width: 288px;
`;
UserInfoListItemStyled.displayName = 'UserInfoListItem';

export const MainInfoStyled = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
MainInfoStyled.displayName = 'MainInfo';

export const UserInfoActionStyled = styled(ListItem)`
  margin: 0;
  padding: 0;
`;
UserInfoActionStyled.displayName = 'UserInfoAction';

export const AppBarStyled = styled(AppBar)`
  background: ${({ theme }) => getHeaderColor(theme)};
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
`;
AppBarStyled.displayName = 'AppBar';

export const MenuButtonStyled = styled(IconButton)`
  margin-left: -12px;
  margin-right: 20px;
`;
MenuButtonStyled.displayName = 'MenuButton';

export const TitleStyled = styled(Typography)`
  letter-spacing: 9px;
  color: ${({ theme }) => theme.palette.common.white};
  overflow: visible;
`;
TitleStyled.displayName = 'Title';

export const ModuleNameStyled = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.grey[500]};
`;
ModuleNameStyled.displayName = 'ModuleName';

export const GrowStyled = styled('div')`
  flex-grow: 1;
`;
GrowStyled.displayName = 'Grow';

export const SectionDesktopStyled = styled('div')`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
  }
`;
SectionDesktopStyled.displayName = 'SectionDesktop';

export const SectionMobileStyled = styled('div')`
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;
SectionMobileStyled.displayName = 'SectionMobile';

export const EnvironmentInfoStyled = styled(Typography)`
  position: absolute;
  opacity: 0.5;
  right: ${({ theme }) => theme.spacing(3)};
  bottom: 0;
`;
EnvironmentInfoStyled.displayName = 'EnvironmentInfo';
