export const mapWzShipmentsFromApi = (data = {}) => {
    const placeholder = '';
    const {
        id,
        docNumber = placeholder,
        issueDate = placeholder,
        stabloOrderId = placeholder,
        companyName = placeholder,
        invoiceDocNumber = placeholder,
        invoiceSaleDate = placeholder,
        contractorCode = placeholder,
        sellingChannel = placeholder,
        courierName = placeholder,
        shippingTrackingNumber = placeholder,
        receiverCountryCode = placeholder,
        countryType = placeholder,
        pickUpDate = placeholder,
        deliveryDate = placeholder,
        externalUpdatedAt = placeholder,
        externalStatus = placeholder,
        docStatus = placeholder,
        ie599CreatedAt = placeholder,
        ie599Url = placeholder,
        ie599Filename = placeholder,
        ie599ContentType = placeholder,
        podCreatedAt = placeholder,
        podUrl = placeholder,
        podFilename = placeholder,
        podContentType = placeholder,
        trackingUrl = placeholder,
        trackingContentType = placeholder,
        trackingFilename = placeholder,
        ie599IsRequire = false,
    } = data;

    return {
        ie599IsRequire,
        id,
        docNumber,
        issueDate,
        stabloOrderId,
        companyName,
        invoiceDocNumber,
        invoiceSaleDate,
        contractorCode,
        sellingChannel,
        courierName,
        shippingTrackingNumber,
        receiverCountryCode,
        countryType,
        pickUpDate,
        deliveryDate,
        externalUpdatedAt,
        externalStatus,
        docStatus,
        ie599CreatedAt,
        ie599Url,
        ie599Filename,
        ie599ContentType,
        podCreatedAt,
        podUrl,
        podFilename,
        podContentType,
        trackingUrl,
        trackingContentType,
        trackingFilename,
    };
};
