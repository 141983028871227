export const handleUpdateWorkplaces = async (
    { defaultWorkplace, currentWorkplace },
    {
        alertService,
        patchCurrentWorkplace,
        patchDefaultWorkplace,
        t,
        resetState,
    },
) => {
    try {
        if (defaultWorkplace) {
            await patchDefaultWorkplace(defaultWorkplace);
        }
        await patchCurrentWorkplace(currentWorkplace);

        alertService.addSuccess({
            message: t('alerts.successChangeWorkplaces'),
        });
    } catch (error) {
        alertService.addError({ message: t('alerts.failureChangeWorkplaces') });
        resetState();
    }
};
