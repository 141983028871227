import { getNamespaces } from '../helpers/getNamespaces';

const defaultNamespaces = {
    itemsById: 'itemsById',
    itemsCount: 'itemsCount',
    fetchListLoader: 'fetchListLoader',
};

export function request({ namespaces: configNamespaces, initialState }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        const resultsById = initialState[namespaces.itemsById];

        return state
            .set(namespaces.itemsById, resultsById)
            .set(namespaces.fetchListLoader, true);
    };
}

export function failure({ namespaces: configNamespaces, initialState }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        const resultsById = initialState[namespaces.itemsById];
        const resultsTotal = initialState[namespaces.resultsTotal];

        return state
            .set(namespaces.itemsById, resultsById)
            .set(namespaces.itemsCount, resultsTotal)
            .set(namespaces.fetchListLoader, false);
    };
}

export function success({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state, action, responseModel }) {
        const model = responseModel || action.payload.responseModel;
        const resultsById = model && model.getResultsById(options.model);
        const resultsTotal = model && model.getTotalResults();

        return state
            .set(namespaces.itemsById, resultsById)
            .set(namespaces.itemsCount, resultsTotal)
            .set(namespaces.fetchListLoader, false);
    };
}
