import { ApplicationJsonHeaders } from 'services/constants';
import isEmpty from 'lodash/isEmpty';
import { configError } from 'services/utils/utils';
import type { SimpService } from '../SimpService';
import type { AjaxService } from 'services/ajax/AjaxService';
import { type URLS as URLS_CONFIG } from '../constants';
import { TOrderCompany } from 'types/orderCompany.type';

class Dictionary {
    static AVAILABLE_DICTIONARIES = [
        'marketplaceCountryCode-artgeist',
        'marketplaceCountryCode-decomonkey',
    ];

    private readonly dictionaries: Record<string, unknown> = {};

    constructor(
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
        private readonly errorHandlers: SimpService['errorHandlers'],
    ) {
        this.errorHandlers = errorHandlers;

        this.getByCode = this.getByCode.bind(this);
    }

    async getByCode(
        code = '',
        company: TOrderCompany | undefined = 'artgeist',
    ) {
        const { URLS, ajaxService, dictionaries, errorHandlers } = this;

        const baseURL =
            company === 'artgeist'
                ? URLS.GET_DICTIONARY
                : URLS.DECO_GET_DICTIONARY;

        const url = baseURL.replace('{code}', code);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('get', errorHandlers);

        const dictionaryKey = `${code}-${company}`;

        if (!Dictionary.AVAILABLE_DICTIONARIES.includes(dictionaryKey)) {
            return Promise.resolve([]);
        }

        if (isEmpty(dictionaries[dictionaryKey])) {
            const {
                data: { results = [] },
            } = await ajaxService.get({ url, config, errorConfig });

            dictionaries[dictionaryKey] = results;

            return results;
        }

        return Promise.resolve(dictionaries[dictionaryKey] ?? []);
    }
}

export default Dictionary;
