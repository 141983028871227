import { getNamespaces } from '../helpers/getNamespaces';

const defaultNamespaces = {
    itemsById: 'itemsById',
    fetchLoader: 'fetchLoader',
};

export function request({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        return state
            .set(namespaces.fetchLoader, true);
    };
}

export function failure({ namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        return state
            .set(namespaces.fetchLoader, false);
    };
}

export function success({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state, responseModel }) {
        const item = responseModel.getItemFromResponse(options.model);
        const id = item.getId();

        return state
            .setIn([namespaces.itemsById, id], item)
            .set(namespaces.fetchLoader, false);
    };
}
