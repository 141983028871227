import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../Loader/Loader';
import Button from 'components/Button/button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class Modal extends React.PureComponent {
    handleCloseModal = (e) => {
        const { closeModal } = this.props;

        e && e.preventDefault && e.preventDefault();
        closeModal && closeModal();
    };

    getButtonProps(button) {
        if (typeof button === 'string') {
            return {
                children: button,
            };
        }

        return button;
    }

    getContent() {
        const { children } = this.props;

        if (typeof children === 'string') {
            return (
                <DialogContentText id="alert-dialog-description">
                    { children }
                </DialogContentText>
            );
        }

        return children;
    }

    render() {
        const { leftButton, rightButton, loading, label, active, onSubmit, className, maxWidth } = this.props;

        return (
            <Dialog
                open={ active }
                onClose={ this.handleCloseModal }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ root: className }}
                maxWidth={ maxWidth }
            >
                { loading && active && <Loader /> }
                { label && (
                    <DialogTitle id="alert-dialog-title">
                        { label }
                    </DialogTitle>
                ) }
                <DialogContent>
                    { this.getContent() }
                </DialogContent>
                { !!(leftButton || rightButton) && (
                    <DialogActions>
                        { leftButton && (
                            <Button
                                onClick={ this.handleCloseModal }
                                variant="outlined"
                                aria-label="secondary button"
                                { ...this.getButtonProps(leftButton) }
                            />
                        ) }
                        { rightButton && (
                            <Button
                                onClick={ onSubmit }
                                color="primary"
                                aria-label="primary button"
                                autoFocus
                                { ...this.getButtonProps(rightButton) }
                            />
                        ) }
                    </DialogActions>
                ) }
            </Dialog>
        );
    }
}

Modal.defaultProps = {
    onSubmit: () => {},
    maxWidth: 'sm',
};

Modal.propTypes = {
    leftButton: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    rightButton: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    loading: PropTypes.bool,
    label: PropTypes.node,
    active: PropTypes.bool.isRequired,
    children: PropTypes.node,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default Modal;
