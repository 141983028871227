import { MODULE_NAME } from '../constants';

export const USERS = `/${MODULE_NAME}/users`;
export const USERS_DETAILS = `/${MODULE_NAME}/users/:id`;

export const TOS = `/${MODULE_NAME}/tos`;
export const TOS_DETAILS = `/${MODULE_NAME}/tos/:id`;

export const POSITIONS = `/${MODULE_NAME}/positions`;
export const POSITION_DETAILS = `/${MODULE_NAME}/positions/:id`;

export const ROLE_GROUPS = `/${MODULE_NAME}/role-groups`;
export const ROLE_GROUP_DETAILS = `/${MODULE_NAME}/role-groups/:id`;

export const WORKPLACES = `/${MODULE_NAME}/workplaces`;
export const WORKPLACES_DETAILS = `/${MODULE_NAME}/workplaces/:id`;
