import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ArrowDropDownIcon(props) {
    return (
        <SvgIcon
            viewBox="-5 -9 24 24"
            { ...props }
        >
            <path
                d="M13.0472 5.78271e-05C12.964 0.00255799 12.8844 0.037558 12.826 0.0988081L6.6863 6.27257L0.54662 0.0988081C0.486951 0.0363077 0.404907 0.00255807 0.319133 0.00130783C0.188607 0.00255807 0.0729984 0.081308 0.0232743 0.201308C-0.0252067 0.322559 0.0033848 0.460059 0.0966175 0.551309L6.4613 6.95132C6.58561 7.07632 6.78699 7.07632 6.9113 6.95132L13.276 0.551309C13.3705 0.460059 13.399 0.318809 13.3493 0.197559C13.2984 0.0763081 13.179 -0.00244202 13.0472 5.78271e-05Z"
            />
        </SvgIcon>
    );
}

ArrowDropDownIcon.defaultProps = {
    titleAccess: 'arrow-drop-down-icon',
};

ArrowDropDownIcon.propTypes = svgIconPropTypes;
