import { handleForbiddenError } from 'utils/errors';
import type { AlertService } from '../alert/AlertService';
import i18next from 'i18next';

type TAjaxServiceError = any;

const baseError =
    (alertService: AlertService) =>
    (error: TAjaxServiceError = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data.violations) {
            data.violations.forEach((violation: any = {}) => {
                alertService.addError({
                    message: `${violation.propertyPath}: ${violation.message}`,
                });
                throw error;
            });
        }

        const singleDataError = data['hydra:description'] || data?.detail;

        if (singleDataError) {
            alertService.addError({
                message: singleDataError,
            });
            throw error;
        }

        alertService.addError({
            message: i18next.t('common:errors.unknown', { status }),
        });
        throw error;
    };

const download =
    (alertService: AlertService) =>
    (error: TAjaxServiceError = {}) => {
        const { response = {} } = error;
        const { data = {}, status } = response;

        handleForbiddenError(error, response, alertService);

        if (data.detail) {
            alertService.addError({
                message: data.detail,
            });
        } else {
            alertService.addError({
                message: i18next.t('common:errors.unknown', { status }),
            });
        }
        throw error;
    };

const errorHandlers = (alertService: AlertService) => ({
    post: baseError(alertService),
    patch: baseError(alertService),
    put: baseError(alertService),
    get: baseError(alertService),
    delete: baseError(alertService),
    download: download(alertService),
});

export default errorHandlers;
