import { reduceToActionMap } from './reduceToActionMap';

export function configureActionMap(actionMapConfigurations) {
    const {
        options,
        namespaces,
        initialState,
        actionsConfiguration,
    } = actionMapConfigurations;
    const actionsConfigurations = { options, namespaces, initialState };

    return actionsConfiguration.reduce(reduceToActionMap(actionsConfigurations), {});
}
