import get from 'lodash/get';

const PDP_URL = get(window, 'env.PDP_API_URL', '');

const USERS_BASE_URL = `${ PDP_URL }/api/users`;
const USER_URL = `${ PDP_URL }/api/users/{id}`;
const USER_V1_URL = `${PDP_URL}/api/v1/users/{userId}`;

export const URLS = {
    GET_USERS: USERS_BASE_URL,
    POST_USER: USERS_BASE_URL,
    GET_USER_V1: USER_V1_URL,
    EDIT_USER: USER_URL,
    DELETE_USER: USER_URL,
    GET_TOS_LIST: `${PDP_URL}/api/tos`,
    GET_TOS: `${PDP_URL}/api/tos/{id}`,
    POST_TOS: `${PDP_URL}/api/tos`,
    PUT_TOS: `${PDP_URL}/api/tos/{id}`,
    POST_TOS_CONSENT: `${PDP_URL}/api/tos_user_consents`,
    POST_FILE: `${PDP_URL}/api/tos_content/{id}/attachments`,
    DOWNLOAD_FILE: `${PDP_URL}/api/tos_content/{id}`,
    DELETE_FILE: `${PDP_URL}/api/tos_content/{id}`,
    GET_POSITIONS: `${PDP_URL}/api/positions`,
    GET_POSITION: `${PDP_URL}/api/positions/{id}`,
    POST_POSITION: `${PDP_URL}/api/positions`,
    PUT_POSITION: `${PDP_URL}/api/positions/{id}`,
    ROLE_GROUPS: `${PDP_URL}/api/role_groups`,
    ROLE_GROUP_ID: `${PDP_URL}/api/role_groups/{id}`,
    GET_ROLES: `${PDP_URL}/api/roles`,
    GET_GROUPS: `${PDP_URL}/api/groups`,
    PATCH_USER_WORKPLACE: `${PDP_URL}/api/workplaces/current`,
};

export const DEFAULTS = {
    CACHE_TIME: 6000, // 1 min
    PER_PAGE: 20,
};

export const USER_PARAMS = {
    FILTERS_KEYS: [
        'positionTitle',
    ],
};

export const POSITION_PARAMS = {
    SORTING_KEYS: [
        'createdAt',
    ],
};

export const WORKPLACE_MODAL_ROLE = 'ROLE_STABLO_SWITCHABLE_WORKPLACE';
export const STORAGE_CURRENT_WORKPLACE = 'current_workplace';
