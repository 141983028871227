import {
    type ComponentPropsWithoutRef,
    type ElementRef,
    forwardRef,
} from 'react';
import { TypographyWithTranslation } from './TypographyWithTranslation';
import { TypographyStyled } from './typography.styles';

interface TypographyProps
    extends ComponentPropsWithoutRef<typeof TypographyStyled> {
    withT?: boolean;
    children?: string;
}

export const Typography = forwardRef<
    ElementRef<typeof TypographyStyled>,
    TypographyProps
>(
    (
        {
            fontWeightOption,
            colorOption,
            withT = false,
            variant = 'body1',
            ...rest
        },
        ref,
    ) => {
        const typographyProps = {
            ...rest,
            variant,
            ref,
            variantMapping: {
                body1: 'p',
            },
        };

        return withT ? (
            <TypographyWithTranslation
                {...typographyProps}
                fontWeightOption={fontWeightOption}
                colorOption={colorOption}
            />
        ) : (
            <TypographyStyled
                {...typographyProps}
                fontWeightOption={fontWeightOption}
                colorOption={colorOption}
            />
        );
    },
);

export default Typography;
