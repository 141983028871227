import { Badge } from 'components/Badge/badge';

import { formatDate, NO_VALUE_PLACEHOLDER } from 'utils/formatting';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { TRANSITION_TYPES } from '../../../services/production/constants';

export const formatProductionOrderStatus = (currentState = {}) => {
    const color = Badge.TYPE.PRIMARY;

    if (!currentState) {
        return {
            value: null,
            color,
        };
    }

    const { code = '', label = '' } = currentState;

    if (!code || !label) {
        return {
            value: null,
            color,
        };
    }

    return {
        value: label,
        color,
    };
};

export const formatProductionOrderDetails = (currentState = {}) => {
    if (!currentState?.user || !currentState?.statusAt) {
        return NO_VALUE_PLACEHOLDER;
    }

    const { statusAt = '', user = {} } = currentState;

    return `${user?.name} ${formatDate(statusAt, true)}`;
};

export const getPartsCount = (parts = []) => {
    return parts?.reduce((acc, cur) => {
        return cur ? acc + 1 : acc;
    }, 0);
};

export const isSimpArtgeistOrder = (simpCompany) => simpCompany === 'artgeist';

export const isSimpCompanyMissing = (simpCompany) => simpCompany === null;

export const getTransitionMap =
    ({ transitions, id, transitionTypes = TRANSITION_TYPES.AWE }) =>
    (previousTransitions) => {
        const mappedTransitions = transitions.reduce(
            (acc, cur) => ({
                ...acc,
                [transitionTypes === TRANSITION_TYPES.AWE ? cur.id : cur.code]:
                    [id],
            }),
            {},
        );

        const appendedTransitions = Object.entries(mappedTransitions).reduce(
            (transitions, [id, transitionArray]) => {
                if (!previousTransitions[id]) {
                    return {
                        ...transitions,
                        [id]: [...transitionArray],
                    };
                }

                return {
                    ...transitions,
                    [id]: uniq([
                        ...previousTransitions[id],
                        ...transitionArray,
                    ]),
                };
            },
            {},
        );

        return {
            ...previousTransitions,
            ...appendedTransitions,
        };
    };

export const getTransitionsData = (transitions) => (prevState) =>
    uniqBy([...prevState, ...transitions], 'id');

export const getSortedTransitions = ({
    transitionMap,
    transitions,
    transitionTypes = TRANSITION_TYPES.AWE,
}) => {
    const transitionMapSorted = Object.entries(transitionMap)
        .sort((a, b) => a[1].length - b[1].length)
        .reverse()
        .reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: value,
            }),
            {},
        );

    return (
        Object.keys(transitionMapSorted).map((id) => ({
            id,
            ...(transitions.find((tr) =>
                transitionTypes === TRANSITION_TYPES.AWE
                    ? tr.id === id
                    : tr.code === id,
            ) ?? {}),
        })) ?? []
    );
};

export const getTransitionCount = ({ transitionMap, id }) =>
    transitionMap[id]?.length ?? 0;

export const getTransitionCountLabel = ({ t, transitionMap, id }) =>
    `(${t('labels.productionOrders', {
        ns: 'production',
        count: getTransitionCount({ transitionMap, id }),
    })})`;

export const getFailedOrderNumbers = ({
    productionOrdersList = [],
    successfulOrderIds = [],
    productionOrderIdsToUpdate = [],
}) =>
    productionOrdersList
        .filter(
            ({ id: orderId }) =>
                !successfulOrderIds.includes(orderId) &&
                productionOrderIdsToUpdate.includes(orderId),
        )
        .map(({ orderNumber }) => orderNumber);

export const getSuccessfulOrderIds = ({ promiseResponsesArray = [] }) =>
    promiseResponsesArray
        .filter(({ value }) => value)
        .map(({ value }) => value);

export const getNotUpdatedOrderNumbers = ({
    productionOrdersList = [],
    successfulOrderIds = [],
    selectedOrders = [],
}) =>
    productionOrdersList
        .filter(
            ({ id: orderId }) =>
                !successfulOrderIds.includes(orderId) &&
                selectedOrders.includes(orderId),
        )
        .map(({ orderNumber }) => orderNumber);

export const createProductionOrderItemComponentValue = (component) =>
    component.type.armCode === 'colorFrame'
        ? component.value.legacyValue
        : component.value.armCode || component.value.legacyValue;

export const formatProductionOrderHistoryList = (history) => {
    const mappedHistory = history.map((historyItem) => {
        const date = moment(historyItem.date);
        const day = date.format('DD/MM/YY');
        const time = date.format('HH:mm');

        return {
            date,
            day,
            time,
            user: historyItem.user.name,
            transition: historyItem.transition.label,
            placeTo: historyItem.placeTo.label,
            color: historyItem.placeTo.color,
        };
    });

    return orderBy(mappedHistory, [(item) => item.date.valueOf()], ['asc']);
};
