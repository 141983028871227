import { fromJS, OrderedMap } from 'immutable';
import * as C from './constants';
import { getNavBarExtended, toggleNavBar } from 'utils/navBarHelpers';

const initialState = fromJS({
    navBarExtended: getNavBarExtended(),
    mobileNavBarExtended: false,
    alertsById: new OrderedMap({}),
});

export default function common(state = initialState, action) {
    let key = '';
    let alert = {};

    switch (action.type) {
        case C.TOGGLE_NAVIGATION_BAR:
            toggleNavBar();

            return state
                .set('navBarExtended', !state.get('navBarExtended'));

        case C.TOGGLE_MOBILE_NAVIGATION_BAR:
            return state
                .set('mobileNavBarExtended', !state.get('mobileNavBarExtended'));

        case C.ADD_ALERT:

            return state
                .setIn(['alertsById', key], fromJS({
                    key,
                    ...alert,
                }));
        case C.CLOSE_ALERT:
            return state
                .setIn(['alertsById', action.payload.key, 'dismissed'], true);
        case C.REMOVE_ALERT:
            return state
                .deleteIn(['alertsById', action.payload.key]);

        default:
            return state;
    }
}
