import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function RadioCheckedIcon(props) {
    return (
        <SvgIcon
            viewBox="-2 -2 16 16"
            { ...props }
        >
            <circle
                cx="6"
                cy="6"
                r="5.5"
                fill="white"
                stroke="#9B9B9B"
            />
            <circle
                cx="6"
                cy="6"
                r="2.5"
                fill="#9B9B9B"
                stroke="#9B9B9B"
            />
        </SvgIcon>
    );
}

RadioCheckedIcon.defaultProps = {
    titleAccess: 'radio-checked-icon',
};

RadioCheckedIcon.propTypes = svgIconPropTypes;
