import { get } from 'lodash';

const ODA_URL = get(window, 'env.ODA_API_URL', '');

export const URLS = {
    GET_WZ_SHIPMENTS: `${ODA_URL}/api/wz_shipments`,
    GET_WZ_SHIPMENTS_EXPORT: `${ODA_URL}/api/wz_shipments/export`
};

export const DEFAULTS = {
    WZ_SHIPMENTS_PER_PAGE: 20
};
