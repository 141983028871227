import get from 'lodash/get';

const STABLO_URL = get(window, 'env.STABLO_API_URL');
const DICTIONARIES_API_URL = get(window, 'env.DICTIONARIES_API_URL', '');
const DICTIONARIES_BASE_URL = `${STABLO_URL}/production-orders-dictionaries`;

export const URLS = {
    GET_DICTIONARIES: DICTIONARIES_BASE_URL,

    DICTIONARIES: `${DICTIONARIES_API_URL}/api/dictionaries`,
    DICTIONARY_CODE_VALUES: `${DICTIONARIES_API_URL}/api/dictionaries/{code}/values`,
};

export const DEFAULTS = {
    PER_PAGE: 20,
    AUTOCOMPLETE_MULTISELECT: {
        SELECTED_STATES: ['expecting-production'],
    },
};

export const AVAILABLE_DICTIONARY_CODES = [
    'submissionType',
    'complainSuggestedSolution',
    'returnSuggestedSolution',
    'customer_request_reason_complaints',
    'customer_request_reason_returns',
    'refundMethod',
];
