import get from 'lodash/get';

import { configError } from 'services/utils/utils';
import { mapJobDetailFromApi, mapJobFromApi } from 'services/userService/mappers/jobs';
import { ApplicationJsonHeaders } from '../../constants';

class Jobs {
    constructor(userService) {
        this.userService = userService;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.downloadJobAttachmentById = this.downloadJobAttachmentById.bind(this);
    }

    getList(params = {}) {
        const { ajaxService, errorHandlers, constants } = this.userService;
        const { URLS, HEADERS, DEFAULTS } = constants;
        const {
            page = 1,
            per_page = DEFAULTS.JOBS_PER_PAGE,
        } = params;

        const url = URLS.GET_JOBS;
        const config = {
            headers: HEADERS.basic,
            params: {
                page,
                itemsPerPage: per_page,
            }
        };
        const errorConfig = configError('get', errorHandlers);

        return ajaxService
            .get({ url, config, errorConfig })
            .then((response) => {
                const items = get(response, 'data.results' , []);
                const totalItems = get(response, 'data.total', 0);

                return {
                    items: items.map(mapJobFromApi),
                    totalItems,
                };
            })
            .catch(() => ({
                items: [],
                totalItems: 0,
            }));
    }

    async getById(jobId = '') {
        const { ajaxService, errorHandlers, constants: { URLS } } = this.userService;

        const url = URLS.GET_JOB_BY_ID.replace('{id}', jobId);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('get', errorHandlers);

        try {
            const { data = {} } = await ajaxService
                .get({ url, config, errorConfig });

            return mapJobDetailFromApi(data);
        } catch {
            return ({});
        }
    }

    async downloadJobAttachmentById(mediaFileId = '', fileName = '') {
        const { ajaxService, errorHandlers, constants: { URLS } } = this.userService;

        const url = URLS.DOWNLOAD_JOB_ATTACHMENT_BY_ID
            .replace('{id}', mediaFileId);
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('get', errorHandlers);

        try {
            return await ajaxService
                .download({ url, config, errorConfig, fileName });
        } catch {
            return ({});
        }
    }
}

export default Jobs;
