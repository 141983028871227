import React from 'react';
import PropTypes from 'prop-types';
import { TileStyled } from 'components/OldTable/Pagination/pagination.styles';

class Tile extends React.PureComponent {
    render() {
        const { children, active, onClick, disabled } = this.props;

        return (
            <TileStyled
                size="small"
                variant={ active ? 'outlined' : 'text' }
                active={ active }
                disabled={ active || disabled }
                onClick={ onClick }
            >
                { children }
            </TileStyled>
        );
    }
}

Tile.defaultProps = {
    onClick: () => {},
};

Tile.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default Tile;
