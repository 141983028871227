import * as rolesVotingConfig from './rolesVotingConfig';
import { voteConditionsPassed } from './voteContitionsHandlers';
import { jwtDecode } from 'jwt-decode';

export let access = {};

const parseAccessObject = (accessObject = {}) => {
    const parsedAccessObject = {};

    Object.keys(accessObject).forEach((accessName) => {
        const voteType = accessObject[accessName];

        parsedAccessObject[accessName] = voteType.allow;
    });

    return parsedAccessObject;
};

const castSingleVote = ({ prevVote = {}, nextVote = {}, allRoles }) => {
    const prevStr = prevVote.strength || 0;
    const nextStr = nextVote.strength || 0;

    if (
        nextStr > prevStr &&
        voteConditionsPassed({ vote: nextVote, allRoles })
    ) {
        return nextVote;
    }

    return prevVote;
};

export const reduceRolesToAccesses = (roles) => {
    return roles.reduce((newAccess, role) => {
        const roleVoteConfig = rolesVotingConfig[role] || {};

        if (roleVoteConfig.votes) {
            Object.keys(roleVoteConfig.votes).forEach((accessName = '') => {
                newAccess[accessName] = castSingleVote({
                    prevVote: newAccess[accessName],
                    nextVote: roleVoteConfig.votes[accessName],
                    allRoles: roles,
                });
            });
        }

        return newAccess;
    }, {});
};

export const getActiveToken = () => {
    try {
        return jwtDecode(localStorage.getItem('token'));
    } catch (e) {
        return {};
    }
};
export const makeAllRolesVote = (roles = getActiveToken().roles || []) => {
    const newAccess = reduceRolesToAccesses(roles);

    access = parseAccessObject(newAccess);
};

export const checkAccess = (key) => {
    return access[key];
};

export const checkAccessArray = (array) => {
    let accessGranted = false;

    array.forEach((access) => {
        if (checkAccess(access)) {
            accessGranted = true;

            return false;
        }
    });

    return accessGranted;
};
/*
    Token Expire Date
 */
export const isTokenExpire = () => {
    const token = getActiveToken();

    if (token && token.exp > 0) {
        const current_time = new Date().getTime() / 1000;

        return current_time > token.exp;
    } else {
        return true;
    }
};
