import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import productionOrders from './productionOrders/reducer';
import productionOrdersSaga from './productionOrders/saga';
import * as productionOrdersActions from './productionOrders/actions';

export const productionReducers = combineReducers({
    productionOrders,
});

export const productionSagas = [
    fork(productionOrdersSaga),
];

export const productionActions = [
    productionOrdersActions,
];
