import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function SendIcon(props) {
    return (
        <SvgIcon
            viewBox="-0.5 -0.5 16 16"
            { ...props }
        >
            <path
                d="M14.7525 7.25062C14.7269 7.23206 14.6986 7.21792 14.6677 7.2082L1.7276 2.04632C1.61181 2.00124 1.48011 2.02864 1.39084 2.11438C1.30069 2.201 1.27063 2.33181 1.31041 2.44937L3.00039 7.50518L1.31041 12.561C1.27063 12.6786 1.30068 12.8094 1.39084 12.896C1.48011 12.9817 1.61181 13.0091 1.7276 12.964L14.6677 7.80217C14.7755 7.76151 14.8542 7.66516 14.8709 7.55114C14.8895 7.43712 14.8435 7.32222 14.7525 7.25062ZM12.8928 7.18698H3.55901L2.13065 2.90192L12.8928 7.18698ZM12.8928 7.82338L2.13065 12.1084L3.55901 7.82338L12.8928 7.82338Z"
            />
        </SvgIcon>
    );
}

SendIcon.defaultProps = {
    titleAccess: 'send-icon',
};

SendIcon.propTypes = svgIconPropTypes;
