import type { AjaxService } from 'services/ajax/AjaxService';
import type { default as RequestCache } from 'services/requestCache/RequestCache';

export class ProfileService {
    private constructor(
        private readonly ajax: AjaxService,
        private readonly URLS: unknown,
        private readonly cache: RequestCache,
    ) {
        this.getMe = this.getMe.bind(this);
    }

    private static instance: ProfileService | undefined;

    public static getInstance(
        ajax: AjaxService,
        URLS: unknown,
        cache: RequestCache,
    ): ProfileService {
        if (!ProfileService.instance) {
            ProfileService.instance = new ProfileService(ajax, URLS, cache);
        }

        return ProfileService.instance;
    }

    getMe(props = {}) {
        const { noCache = false } = props;
        const url = this.URLS.GET_USER_ME;
        const request = this.cache.getRequest(url);

        if (!request && !noCache) {
            const newRequest = this.ajax.get({ url }).then(({ data }) => data);

            this.cache.setRequest(url, null, newRequest);

            return newRequest;
        }

        return request;
    }
}
