import { fromJS } from 'immutable';
import {
    setInitialState,
    clearErrors,
    createReduxMethods,
    removeReduxMethods,
    editReduxMethods,
    fetchReduxMethods,
    fetchListReduxMethods,
} from 'utils/redux/handleTableView/reduxMethods';
import * as C from './constants';

const options = {
    singularName: 'property',
    pluralName: 'properties',
};

export const initialState = setInitialState(options, {
    propertiesForAutocomplete: fromJS({}),
});

export default function technologiesReducer(state = initialState, action = {}) {
    const actionProps = { options, state, action };

    /* istanbul ignore next */
    switch (action.type) {
        case C.CLEAR_ERRORS: return clearErrors(actionProps);

        case C.CREATE_PROPERTY.request: return createReduxMethods.request(actionProps);
        case C.CREATE_PROPERTY.failure: return createReduxMethods.failure(actionProps);
        case C.CREATE_PROPERTY.success: return createReduxMethods.success(actionProps);

        case C.REMOVE_PROPERTY.request: return removeReduxMethods.request(actionProps);
        case C.REMOVE_PROPERTY.failure: return removeReduxMethods.failure(actionProps);
        case C.REMOVE_PROPERTY.success: return removeReduxMethods.success(actionProps);

        case C.EDIT_PROPERTY.request: return editReduxMethods.request(actionProps);
        case C.EDIT_PROPERTY.failure: return editReduxMethods.failure(actionProps);
        case C.EDIT_PROPERTY.success: return editReduxMethods.success(actionProps);

        case C.FETCH_PROPERTIES.request: return fetchListReduxMethods.request(actionProps);
        case C.FETCH_PROPERTIES.failure: return fetchListReduxMethods.failure(actionProps);
        case C.FETCH_PROPERTIES.success: return fetchListReduxMethods.success(actionProps);

        case C.FETCH_PROPERTY.request: return fetchReduxMethods.request(actionProps);
        case C.FETCH_PROPERTY.failure: return fetchReduxMethods.failure(actionProps);
        case C.FETCH_PROPERTY.success: return fetchReduxMethods.success(actionProps);

        default:
            return state;
    }
}
