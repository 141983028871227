import { mapSortArrayToObj, omitNegative, parsePostFilters } from 'utils/filters';
import { mapTranslationPatternFromApi } from '../mappers/translationPatterns';
import get from 'lodash/get';
import { defaultFilters } from 'modules/translations/components/PatternsTableView/defaultFilters';
import { configError } from 'services/utils/utils';

const T_PROCESSING_ERROR = 'userService:alerts.processingError';

class TranslationPatterns {
    constructor(searchService) {
        this.searchService = searchService;
        this.constants = searchService.constants;

        this.exportToCsv = this.exportToCsv.bind(this);
        this.getList = this.getList.bind(this);

        this._getCSVFile = this._getCSVFile.bind(this);
    }

    getList(channelId, params = {}) {
        const {
            searchService: { ajaxService, errorHandlers },
            constants: { URLS, DEFAULTS, HEADERS },
        } = this;
        const {
            page = 1,
            per_page: itemsPerPage = DEFAULTS.TRANSLATION_PATTERNS_PER_PAGE,
            filters = {},
            sort = {}
        } = params;

        const postFilters = parsePostFilters({
            filters: {
                ...filters,
                channelId
            },
            sort,
            config: defaultFilters,
        });
        const url = URLS.GET_TRANSLATION_PATTERNS;
        const config = {
            data: postFilters,
            params: {
                page,
                itemsPerPage
            },
            headers: HEADERS.basic,
        };
        const errorConfig = configError('post', errorHandlers);

        return ajaxService.post({ url, config, errorConfig })
            .then((response) => {
                const items = get(response, 'data.results', []);
                const itemsTotal = get(response, 'data.total', 0);

                return {
                    items: items.map(mapTranslationPatternFromApi),
                    itemsTotal,
                };
            })
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    async exportToCsv({ filters, filtersConfig, sort }) {
        const {
            searchService: { ajaxService, alert: { addSuccess, addError }, translator: { t }, errorHandlers },
            constants: { URLS, HEADERS }
        } = this;

        const postSort = mapSortArrayToObj(sort);
        const postFilters = parsePostFilters({ filters: omitNegative(filters), config: filtersConfig, sort: postSort });

        try {
            const res = await ajaxService.post({
                url: URLS.POST_TRANSLATION_PATTERNS_EXPORT,
                config: { headers: HEADERS.basic },
                data: {
                    ...postFilters,
                    separator: ',',
                    enclosure: '"'
                },
                errorConfig: {
                    addGenericAlert: false,
                    throwError: errorHandlers.post,
                }
            });

            if (res?.data?.jobId) {
                addSuccess({ message: t('translations:alerts.translationExportedToCSV') });
                this._getCSVFile(res.data.jobId);
            } else {
                addError({ message: t(T_PROCESSING_ERROR) });
            }
        } catch {
            addError({ message: t(T_PROCESSING_ERROR) });
        }
    }

    _getCSVFile(jobId) {
        let interval;

        const {
            searchService: {
                alert: { addError },
                translator: { t },
                userService: { jobs }
            },
            constants: {
                DEFAULTS: {
                    EXPORT_TRANSLATION_POLLING_INTERVAL,
                },
            }
        } = this;

        try {
            interval = setInterval(async () => {
                const { status, jobAttachments = [] } = await jobs.getById(jobId);

                if (status === 'failed') {
                    clearInterval(interval);

                    addError({ message: t(T_PROCESSING_ERROR) });
                } else if (['succeeded', 'cancelled'].includes(status) && jobAttachments.length) {
                    clearInterval(interval);

                    for await (const attachment of jobAttachments) {
                        const fileName = attachment.id;
                        const mediaFileId = attachment.media_file.id;
                        const isOutputFile = !!attachment.output;

                        if (isOutputFile) {
                            jobs.downloadJobAttachmentById(mediaFileId, `${fileName}.csv`);
                        }
                    }
                }
            }, EXPORT_TRANSLATION_POLLING_INTERVAL);
        } catch (e) {
            clearInterval(interval);

            addError({ message: t(T_PROCESSING_ERROR) });
        }
    }
}

export default TranslationPatterns;
