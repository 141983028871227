import { makeOrderImportNote } from 'utils/note/orderImportNote';
import { makeFeedNote } from 'utils/note/feedNote';
import { MAX_NOTE_LENGTH } from 'configs/notes';

/**
 * @typedef {import('./notes.type').NoteOptions} NoteOptions
 */

/**
 * @typedef {import('./notes.type').ImportOrderOptions & import('./notes.type').ImportOrderType} ImportOrderOptions
 */

/**
 * @typedef {import('./notes.type').FeedOptions & import('./notes.type').FeedType} FeedOptions
 */

/**
 * @param {(ImportOrderOptions & NoteOptions) | (FeedOptions & NoteOptions)} options
 * @return {string}
 */
const makeNote = ({
    type,
    language,
    priceList,
    includePrice,
    note,
    channel,
    alwaysIncludePrice,
}) => type === 'importOrder' ? makeOrderImportNote({ channel, note }) : makeFeedNote({
    language,
    priceList,
    includePrice,
    note,
    alwaysIncludePrice,
});

/**
 * @param {ImportOrderOptions|FeedOptions} options
 * @return {number}
 */
const calculateNoteLength = (options) => makeNote(options).replace(/\n/g, '  ').length;

/**
 * @param {ImportOrderOptions|FeedOptions} options
 * @return {number}
 */
const getAvailableNoteLength = (options) => MAX_NOTE_LENGTH - calculateNoteLength(options);

export { makeNote, getAvailableNoteLength };
