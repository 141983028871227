import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import type { DeepPartial } from '../../../../utils/types/deepPartial';
import type {
    CustomerRequestCollectionResponse,
    CustomerRequestGetTransitionsDto,
    CustomerRequestInputDto,
    CustomerRequestOutputDto,
    CustomerRequestsParameters,
} from './customerRequest.type';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import { stringify } from 'qs';

export class CustomerRequestActions {
    private static instance: CustomerRequestActions | undefined;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ) {
        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.getTransitions = this.getTransitions.bind(this);
        this.applyTransition = this.applyTransition.bind(this);
    }

    static getInstance(
        ajaxService: AjaxService,
        errorHandlers: ReturnType<typeof errorHandlersHelper>,
    ): CustomerRequestActions {
        if (!CustomerRequestActions.instance) {
            CustomerRequestActions.instance = new CustomerRequestActions(
                ajaxService,
                errorHandlers,
            );
        }

        return CustomerRequestActions.instance;
    }

    async getList(
        parameters: DeepPartial<CustomerRequestsParameters>,
    ): Promise<CustomerRequestCollectionResponse> {
        const response: { data: CustomerRequestCollectionResponse } =
            await this.ajaxService.get({
                url: URLS.CUSTOMER_REQUESTS,
                config: {
                    headers: ApplicationJsonHeaders,
                    params: parameters,
                    paramsSerializer: stringify,
                },
                errorConfig: {
                    onError: this.errorHandlers.get,
                },
                onError: this.errorHandlers.get,
            });

        return response.data;
    }

    async create(
        payload: CustomerRequestInputDto,
    ): Promise<CustomerRequestOutputDto> {
        const response: { data: CustomerRequestOutputDto } =
            await this.ajaxService.post({
                url: URLS.CUSTOMER_REQUESTS,
                config: {
                    headers: ApplicationJsonHeaders,
                },
                data: payload,
                errorConfig: {
                    onError: this.errorHandlers.post,
                },
                onError: this.errorHandlers.post,
            });

        return response.data;
    }

    async delete(id: string): Promise<void> {
        await this.ajaxService.delete({
            url: URLS.CUSTOMER_REQUESTS_DELETE.replace(`{id}`, id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.delete,
            },
            onError: this.errorHandlers.delete,
        });
    }

    async getTransitions(
        id: string,
    ): Promise<CustomerRequestGetTransitionsDto> {
        const response: { data: CustomerRequestGetTransitionsDto } =
            await this.ajaxService.get({
                url: URLS.TRANSITIONS.replace('{id}', id),
                config: {
                    headers: ApplicationJsonHeaders,
                },
                errorConfig: {
                    onError: this.errorHandlers.get,
                },
                onError: this.errorHandlers.get,
            });

        return response.data;
    }

    async applyTransition(
        customerRequestId: string,
        transitionId: string,
        payload: Record<string, unknown>,
    ): Promise<CustomerRequestOutputDto> {
        const response: { data: CustomerRequestOutputDto } =
            await this.ajaxService.post({
                url: URLS.APPLY_TRANSITION.replace(
                    '{id}',
                    customerRequestId,
                ).replace('{transitionId}', transitionId),
                data: payload,
                config: {
                    headers: ApplicationJsonHeaders,
                },
                errorConfig: {
                    onError: this.errorHandlers.post,
                },
                onError: this.errorHandlers.post,
            });

        return response.data;
    }
}
