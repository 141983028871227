import { getErrorsFromResponse } from '../helpers';
import { fromJS } from 'immutable';

export function editRequest({ options, state }) {
    const { singularName } = options;

    return state
        .setIn(['loaders',`${ singularName }Details`], true);
}

export function editFailure({ options, state, action }) {
    const { singularName } = options;
    const errorsByField = getErrorsFromResponse(action.payload.response);

    return state
        .set('errorsByField', fromJS(errorsByField))
        .setIn(['loaders', `${ singularName }Details`], false);
}

export function editSuccess({ options, state, action }) {
    const { pluralName, singularName } = options;
    const editedItem = action.payload.response || { id: null };

    return state
        .set('errorsByField', fromJS({}))
        .setIn([`${ pluralName }ById`, editedItem.id.toString()], fromJS(editedItem))
        .setIn(['loaders', `${ singularName }Details`], false);
}
