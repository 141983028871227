import get from 'lodash/get';

export const mapJobFromApi = (data = {}) => {
    const {
        created_at: createdAt = '',
        finished_at: finishedAt = '',
        user = {},
        type = '',
        message = '',
        status = '',
        id,
        note,
    } = data;

    return {
        id,
        message,
        type,
        status,
        userId: get(user, 'id', ''),
        createdAt,
        finishedAt,
        note,
    };
};

export const mapJobDetailFromApi = (data = {}) => ({
    ...mapJobFromApi(data),
    jobAttachments: (data?.job_attachments ?? []).map((obj) => ({ ...obj, mediaFiles: obj?.media_files })),
});
