import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function AddIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -1 16 16"
            { ...props }
        >
            <path
                d="M7 0C3.13761 0 0 3.13761 0 7C0 10.8624 3.13761 14 7 14C10.8624 14 14 10.8624 14 7C14 3.13761 10.8624 0 7 0ZM7 0.608696C10.5334 0.608696 13.3913 3.46658 13.3913 7C13.3913 10.5334 10.5334 13.3913 7 13.3913C3.46658 13.3913 0.608696 10.5334 0.608696 7C0.608696 3.46658 3.46658 0.608696 7 0.608696ZM6.99524 4.56106C6.91463 4.56232 6.83781 4.59551 6.78164 4.65335C6.72547 4.71119 6.69455 4.78895 6.69565 4.86957V6.69565H4.86957C4.82924 6.69508 4.78919 6.70253 4.75177 6.71757C4.71434 6.73261 4.68028 6.75494 4.65156 6.78326C4.62284 6.81157 4.60003 6.84532 4.58447 6.88253C4.5689 6.91973 4.56088 6.95967 4.56088 7C4.56088 7.04033 4.5689 7.08027 4.58447 7.11747C4.60003 7.15468 4.62284 7.18843 4.65156 7.21674C4.68028 7.24506 4.71434 7.26739 4.75177 7.28243C4.78919 7.29747 4.82924 7.30492 4.86957 7.30435H6.69565V9.13043C6.69508 9.17076 6.70253 9.2108 6.71757 9.24823C6.73261 9.28566 6.75494 9.31972 6.78326 9.34844C6.81157 9.37716 6.84532 9.39997 6.88253 9.41553C6.91973 9.4311 6.95967 9.43912 7 9.43912C7.04033 9.43912 7.08027 9.4311 7.11747 9.41553C7.15468 9.39997 7.18843 9.37716 7.21674 9.34844C7.24506 9.31972 7.26739 9.28566 7.28243 9.24823C7.29747 9.2108 7.30492 9.17076 7.30435 9.13043V7.30435H9.13043C9.17076 7.30492 9.2108 7.29747 9.24823 7.28243C9.28566 7.26739 9.31972 7.24506 9.34844 7.21674C9.37716 7.18843 9.39997 7.15468 9.41553 7.11747C9.4311 7.08027 9.43912 7.04033 9.43912 7C9.43912 6.95967 9.4311 6.91973 9.41553 6.88253C9.39997 6.84532 9.37716 6.81157 9.34844 6.78326C9.31972 6.75494 9.28566 6.73261 9.24823 6.71757C9.2108 6.70253 9.17076 6.69508 9.13043 6.69565H7.30435V4.86957C7.3049 4.82885 7.29729 4.78843 7.28194 4.75071C7.2666 4.71299 7.24384 4.67873 7.21502 4.64996C7.1862 4.62119 7.15189 4.5985 7.11414 4.58323C7.07639 4.56796 7.03596 4.56042 6.99524 4.56106Z"
            />
        </SvgIcon>
    );
}

AddIcon.defaultProps = {
    titleAccess: 'add-icon',
};

AddIcon.propTypes = svgIconPropTypes;
