import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import type { CustomerRequestReturnGetDto } from './customerRequestReturn.type';

export class CustomerRequestReturnActions {
    private static instance: CustomerRequestReturnActions | undefined;

    private constructor(private readonly ajaxService: AjaxService, private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>) {
        this.getById = this.getById.bind(this);
    }

    static getInstance(ajaxService: AjaxService, errorHandlers: ReturnType<typeof errorHandlersHelper>): CustomerRequestReturnActions {
        if (!CustomerRequestReturnActions.instance) {
            CustomerRequestReturnActions.instance = new CustomerRequestReturnActions(ajaxService, errorHandlers);
        }

        return CustomerRequestReturnActions.instance;
    }

    async getById(id: string): Promise<CustomerRequestReturnGetDto> {
        const response: { data: CustomerRequestReturnGetDto } = await this.ajaxService.get({
            url: URLS.CUSTOMER_REQUEST_RETURN.replace('{id}', id),
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.get,
            },
            onError: this.errorHandlers.get,
        });

        return response.data;
    }
}
