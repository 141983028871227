export const ROLE_OPTIMA_GENERATE_INVOICE = {};

export const ROLE_OPTIMA_PROCESS_INVOICE = {};

export const ROLE_OPTIMA_GET_INVOICE_DETAILS = {};

export const ROLE_OPTIMA_ACCESS_CUSTOMER = {};

export const ROLE_OPTIMA_EDIT_CUSTOMER = {};

export const ROLE_OPTIMA_ADD_CUSTOMER = {};
