import i18next from 'i18next';

import { URLS, DEFAULTS } from './constants';
import parser from 'qs';
import errorHandlers from './errorHandlers';
import { SearchService } from './SearchService';
import { ApplicationJsonHeaders, HttpPatchHeaders } from '../constants';
import type { UserService } from '../userService/UserService';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const searchServiceFactory = (
    userService: UserService,
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    SearchService.getInstance({
        CONSTANTS: {
            URLS,
            DEFAULTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
                patch: HttpPatchHeaders,
            },
        },
        errorHandlersConfig: errorHandlers,
        services: {
            ajax: ajaxService,
            alert: alertService,
            parser,
            userService,
            translator: i18next,
        },
    });
