import styled from '@emotion/styled';

export const NotFoundPageStyled = styled('div')`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
`;
NotFoundPageStyled.displayName = 'NotFoundPage';

export const ImageContainerStyled = styled('div')`
  margin: 200px auto 30px;
  width: 270px;
  
  & img {
    width: 100%;
  }
`;
ImageContainerStyled.displayName = 'ImageContainer';
