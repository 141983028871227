import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDrawer from '@mui/material/Drawer';

class Drawer extends Component {
    getDrawerContent() {
        const {
            content,
            close,
        } = this.props;

        if (typeof content === 'function') {
            return content(close);
        }

        return content;
    }
    getDrawerProps() {
        const {
            drawerProps = {},
            close,
        } = this.props;

        if (typeof drawerProps === 'function') {
            return drawerProps(close);
        }

        return drawerProps;
    }
    render() {
        const { close } = this.props;

        return (
            <MUIDrawer
                anchor="right"
                open={ true }
                onClose={ close }
                { ...this.getDrawerProps() }
            >
                {this.getDrawerContent()}
            </MUIDrawer>
        );
    }
}

Drawer.defaultProps = {
    drawerProps: {},
    drawerTitleProps: {},
    drawerContentProps: {},
    drawerActionsProps: {},
};

Drawer.propTypes = {
    id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    actions: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    drawerProps: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    drawerTitleProps: PropTypes.shape({}),
    drawerContentProps: PropTypes.shape({}),
    drawerActionsProps: PropTypes.shape({}),
};

export default Drawer;
