import React, { Component } from 'react';
import Modal from './Modal';
import withServices from 'hoc/withServices/withServices';
import PropTypes from 'prop-types';

class ModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modals: new Set(),
        };
    }
    modalOpenListener = (modal) => {
        const { modals } = this.state;

        modals.add(modal);
        this.setState({ modals });
    };
    modalCloseListener = (modal) => {
        const { modals } = this.state;

        modals.delete(modal);
        this.setState({ modals });
    };
    componentDidMount() {
        this.props.services.modal.listen('open', this.modalOpenListener);
        this.props.services.modal.listen('close', this.modalCloseListener);
    }
    componentWillUnmount() {
        this.props.services.modal.unlisten('open', this.modalOpenListener);
        this.props.services.modal.unlisten('close', this.modalCloseListener);
    }
    getModals() {
        const modalsList = [];

        this.state.modals.forEach((modal) => {
            const closeModal = () => {
                this.modalCloseListener(modal);
            };

            modalsList.push(
                <Modal
                    key={modal.id}
                    {...modal}
                    close={closeModal}
                />,
            );
        });

        return modalsList;
    }
    render() {
        return <div>{this.getModals()}</div>;
    }
}

ModalContainer.propTypes = {
    services: PropTypes.shape({
        modal: PropTypes.shape({
            listen: PropTypes.func.isRequired,
            unlisten: PropTypes.func.isRequired,
        }),
    }).isRequired,
};

const ModalContainerWithHOC = withServices(ModalContainer);

export default ModalContainerWithHOC;
