import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ProductCatalogsTable from 'modules/stablo/containers/ProductCatalogsTable/mapProps';

class ProductCatalogs extends React.PureComponent {
    render() {
        const { t } = this.props;

        return <ProductCatalogsTable t={t} />;
    }
}

ProductCatalogs.propTypes = {
    t: PropTypes.func,
};

const ProductCatalogsWithHOC = withTranslation('stablo')(ProductCatalogs);

export default ProductCatalogsWithHOC;
