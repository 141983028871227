import get from 'lodash/get';

export const POST_SALES_URL = get(window, 'env.POST_SALES_API_URL', '');

export const POST_SALES_BASE_URL = `${POST_SALES_URL}/api`;
export const POST_SALES_API_V1 = `${POST_SALES_BASE_URL}/v1`;

export const URLS = {
    CUSTOMER_REQUESTS: `${POST_SALES_API_V1}/customer_requests`,
    CUSTOMER_REQUESTS_DELETE: `${POST_SALES_API_V1}/customer_requests/{id}`,
    CUSTOMER_REQUEST_COMPLAINT: `${POST_SALES_API_V1}/customer_request_complaints/{id}`,
    CUSTOMER_REQUEST_RETURN: `${POST_SALES_API_V1}/customer_request_returns/{id}`,
    PLACES: `${POST_SALES_API_V1}/places`,
    TRANSITION_HISTORY: `${POST_SALES_API_V1}/customer_requests/{id}/history`,
    CUSTOMER_REQUEST_PHOTOS: `${POST_SALES_API_V1}/customer_request_complaints/{id}/photos`,
    PHOTOS: `${POST_SALES_API_V1}/photos/{id}`,
    TRANSITIONS: `${POST_SALES_API_V1}/customer_requests/{id}/transitions`,
    APPLY_TRANSITION: `${POST_SALES_API_V1}/customer_requests/{id}/transition_apply/{transitionId}`,
};
