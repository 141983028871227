import styled from '@emotion/styled';
import Paper from 'components/Paper/paper';
import { css } from '@emotion/react';
import { excludePropForward } from 'utils/styles';
import Typography from 'components/Typography';

const flatStyles = ({ theme }) => css`
  background: ${theme.palette.grey[100]};
`;

const paddingLeftStyles = ({ theme }) => css`
  padding-left: ${theme.spacing(4)};
`;

const activeStyles = ({ theme }) => css`
  color: ${theme.palette.text.primary};
`;

export const RootStyled = styled(Paper, excludePropForward(['flatStyle', 'paddingLeft']))`
  position: relative;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: start;
  
  ${({ flatStyle }) => flatStyle && flatStyles};
  ${({ paddingLeft }) => paddingLeft && paddingLeftStyles};
`;
RootStyled.displayName = 'Root';

export const LabelStyled = styled(Typography, excludePropForward('active'))`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.action.active};
  
  ${({ active }) => active && activeStyles};
`;
LabelStyled.displayName = 'Label';
