import parser from 'qs';
import { URLS, FILES_TYPES, APP_ALERTS, DEFAULTS, PARAMS } from './constants';
import type { MediaFilesService } from '../mediaFiles/MediaFilesService';
import i18next from 'i18n';
import { ArmApiService } from './ArmApiService';
import errorHandlersConfig from './errorHandlers';
import { uploadServicesFactory } from './uploadServices/uploadServicesFactory';
import { filesUploadFactory } from 'services/filesUpload/filesUploadFactory';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { AuthenticationService } from 'services/authentication/AuthenticationService';

export const armApiServiceFactory = (
    mediaFilesService: MediaFilesService,
    ajaxService: AjaxService,
    alertService: AlertService,
    authenticationService: AuthenticationService,
) =>
    ArmApiService.getInstance({
        URLS,
        APP_ALERTS,
        FILES_TYPES,
        DEFAULTS,
        PARAMS,
        errorHandlersConfig,
        services: {
            ajax: ajaxService,
            alert: alertService,
            mediaFiles: mediaFilesService,
            parser,
            translator: i18next,
            upload: uploadServicesFactory((config) =>
                filesUploadFactory(config, authenticationService),
            ),
        },
    });
