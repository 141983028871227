import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { excludePropForward } from 'utils/styles';
import Paper from 'components/Paper/paper';

const flatTableStyles = css`
  padding: 0;
  margin: 0;
`;

export const EmptyTableStyled = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;
EmptyTableStyled.displayName = 'EmptyTable';

export const RootStyled = styled('div', excludePropForward('flatStyle'))`
  flex: 1;
  padding: 2px;
  margin: -2px;
  overflow-x: auto;
  
  ${({ flatStyle }) => flatStyle && flatTableStyles};
`;
RootStyled.displayName = 'Root';

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-x: auto;
`;
PaperStyled.displayName = 'Paper';
