import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function FilterHdrIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -4 18 18"
            { ...props }
        >
            <path
                d="M6.55777 0.000480479C6.3016 -0.00824863 6.05749 0.102318 5.9068 0.303083L0.15056 7.81287C-0.0212231 8.03982 -0.0483464 8.33951 0.0812446 8.58974C0.213849 8.84288 0.479058 9 0.77139 9H12.1573C12.1754 9 12.1935 9 12.2115 9C12.2146 9 12.2146 9 12.2176 9L17.2415 8.9389C17.5368 8.93599 17.802 8.76723 17.9286 8.50827C18.0522 8.24931 18.013 7.94671 17.8292 7.72558L12.8083 1.77827C12.6606 1.60078 12.4346 1.49895 12.1995 1.50186C11.9554 1.50767 11.7294 1.62115 11.5877 1.81028L9.9422 3.99542L7.15148 0.305993C7.00983 0.122686 6.79284 0.00920959 6.55777 0.000480479ZM6.52462 2.00231L9.32439 5.70629C9.3274 5.71211 9.33041 5.71502 9.33343 5.71793L10.6896 7.51026H2.30237L6.52462 2.00231ZM12.2447 3.46877L15.6231 7.46953L12.6003 7.50735L10.9006 5.26111L12.2447 3.46877Z"
            />
        </SvgIcon>
    );
}

FilterHdrIcon.defaultProps = {
    titleAccess: 'filter-hdr-icon',
};

FilterHdrIcon.propTypes = svgIconPropTypes;
