import { ProfileService } from './ProfileService';
import RequestCache from '../requestCache/RequestCache';
import { DEFAULTS, URLS } from './constants';
import type { AjaxService } from 'services/ajax/AjaxService';

export const profileServiceFactory = (ajaxService: AjaxService) =>
    ProfileService.getInstance(
        ajaxService,
        URLS,
        new RequestCache({ cacheTime: DEFAULTS.CACHE_TIME }),
    );
