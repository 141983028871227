import type { AjaxService } from 'services/ajax/AjaxService';
import type { SimpService } from '../SimpService';
import { type URLS as URLS_CONFIG } from '../constants';

class Package {
    private readonly errorHandlers: SimpService['errorHandlers'];

    constructor(
        simpService: SimpService,
        private readonly ajaxService: AjaxService,
        private readonly URLS: typeof URLS_CONFIG,
    ) {
        this.errorHandlers = simpService.errorHandlers;

        this.getListByOrderId = this.getListByOrderId.bind(this);
    }

    private configError(requestType, config = {}) {
        return {
            throwError: this.errorHandlers[requestType],
            addGenericAlert: false,
            ...config,
        };
    }

    async getListByOrderId(orderId, company = 'artgeist') {
        const url = (
            company === 'artgeist'
                ? this.URLS.GET_PACKAGES_BY_ORDER_ID
                : this.URLS.DECO_GET_PACKAGES_BY_ORDER_ID
        ).replace('{id}', orderId);

        const errorConfig = this.configError('get');

        const response = await this.ajaxService.get({
            url,
            config: {},
            errorConfig,
        });

        return response.data['hydra:member'];
    }
}

export default Package;
