import styled from '@emotion/styled';
import { amber } from '@mui/material/colors';
import { AlertIcon, CheckCircleFilledIcon } from 'components/Icons/Icons';
import { css } from '@emotion/react';
import { SnackbarProvider } from 'notistack';

const baseIconStyles = ({ theme }) => css`
  opacity: 0.9;
  margin-right: ${theme.spacing(1)};
`;

const baseSnackbarStyles = ({ theme }) => css`
  white-space: pre-wrap;
  align-items: flex-start;
  max-height: 80vh;
  overflow: auto;
  font-family: ${theme.typography.fontFamily} !important;
`;

export const SuccessIconStyled = styled(CheckCircleFilledIcon)`
  ${baseIconStyles};
`;
SuccessIconStyled.displayName = 'SuccessIcon';

export const ErrorIconStyled = styled(AlertIcon)`
  ${baseIconStyles};
`;
ErrorIconStyled.displayName = 'ErrorIcon';

export const WarningIconStyled = styled(AlertIcon)`
  ${baseIconStyles};
`;
WarningIconStyled.displayName = 'WarningIcon';

export const InfoIconStyled = styled(AlertIcon)`
  ${baseIconStyles};
  transform: rotateX(180deg);
`;
InfoIconStyled.displayName = 'InfoIcon';

export const SnackbarProviderStyled = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    ${baseSnackbarStyles};
  }
  
  &.SnackbarItem-variantSuccess {
    background-color: ${({ theme }) => theme.palette.secondary.main} !important;
    color: ${({ theme }) => theme.palette.secondary.contrastText} !important;
  }

  &.SnackbarItem-variantError {
    background-color: ${({ theme }) => theme.palette.error.main} !important;
    color: ${({ theme }) => theme.palette.error.contrastText} !important;
  }

  &.SnackbarItem-variantWarning {
    background-color: ${amber[700]} !important;
    color: ${({ theme }) => theme.palette.primary.contrastText} !important;
  }

  &.SnackbarItem-variantInfo {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    color: ${({ theme }) => theme.palette.primary.contrastText} !important;
  }
`;
SnackbarProviderStyled.displayName = 'SnackbarProvider';
