import React from 'react';
import Table from 'components/OldTable/OldTableWithState';
import * as PropTypes from 'prop-types';
import {
    DownloadCellStyled,
    DownloadIconStyled,
} from 'modules/stablo/components/ProductCatalogsTable/productCatalogsTable.styles';

export class ProductCatalogsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.props.fetchProductCatalogs();
    }

    renderDownloadButton(catalog) {
        const { t, downloadProductCatalog } = this.props;
        const iconSize = 20;

        return (
            <DownloadCellStyled
                onClick={ () => downloadProductCatalog({ catalog }) }
            >
                { `${ t('actions.downloadCatalog') }:` }
                <DownloadIconStyled
                    size={ iconSize }
                    name='download'
                />
            </DownloadCellStyled>
        );
    }

    render() {
        const { t, productCatalogs, loading } = this.props;

        return (
            <Table
                t={ t }
                loading={ loading }

                itemsById={ productCatalogs }
                itemsTotal={ productCatalogs.size }
                withPagination={ true }

                columnTemplate="80px auto 200px"
                columns={ [
                    {
                        label: 'Id',
                        sortKey: 'id',
                        cell: (productCatalog) => productCatalog.get('id'),
                    },
                    {
                        label: t('common:glossary.name'),
                        sortKey: 'name',
                        cell: (productCatalog) => productCatalog.get('name'),
                    },
                    {
                        label: t('labels.catalogSummary'),
                        cell: (productCatalog) => this.renderDownloadButton(productCatalog),
                    }
                ] }
            />
        );
    }
}

ProductCatalogsTable.propTypes = {
    t: PropTypes.func.isRequired,
    fetchProductCatalogs: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    productCatalogs: PropTypes.object.isRequired,
    downloadProductCatalog: PropTypes.func.isRequired,
};

export default ProductCatalogsTable;
