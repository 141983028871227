import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import Cell from '../Cell/cell';
import SortArrows from '../SortArrows/sortArrows';
import CheckBoxCell from '../CheckBoxCell/checkBoxCell';
import { LabelStyled, RootStyled } from 'components/OldTable/Head/head.styles';

class Head extends React.PureComponent {
    getSortArrowsProps(column) {
        const { order } = this.props;

        if (typeof column.sortKey === 'string') {
            return {
                upActive: order[column.sortKey] === 'asc',
                downActive: order[column.sortKey] === 'desc',
            };
        } else if (typeof column.sortKey === 'object') {
            return {
                upActive: order[column.sortKey[0]] === 'asc',
                downActive: order[column.sortKey[0]] === 'desc',
            };
        }
    }

    render() {
        const {
            t,
            id,
            flatStyle,
            columns,
            columnStyles,
            onSelectAll,
            extendable,
            onSortAll,
            isAnySelected,
            isSelectedAll,
            withActions,
            smallRows,
        } = this.props;

        return (
            <RootStyled
                id={ id }
                square={ flatStyle }
                elevation={ flatStyle ? 0 : 2 }
                style={ columnStyles }
                flatStyle={ flatStyle }
                paddingLeft={ !onSelectAll && !extendable }
            >
                { onSelectAll && (
                    <CheckBoxCell
                        top
                        checked={ isSelectedAll }
                        indeterminate={ isAnySelected && !isSelectedAll }
                        onClick={ onSelectAll }
                    />
                ) }
                { extendable && <Cell /> }
                {
                    columns.map((column) => {
                        const handleSort = () => onSortAll(column.sortKey);
                        const sortProps = this.getSortArrowsProps(column) || {};

                        return (
                            <LabelStyled
                                active={ sortProps.downActive || sortProps.upActive }
                                basic={ true }
                                top={ true }
                                component={ Cell }
                                variant={ smallRows ? 'body2' : 'body1' }
                                onClick={ column.sortKey ? handleSort : null }
                                key={ `header - ${ column.label }` }
                            >
                                { column.sortKey && <SortArrows { ...sortProps } /> }
                                <Tooltip title={ column.label || '' } >
                                    <span key={ `header - label - ${ column.label }` }>
                                        { column.label }
                                    </span>
                                </Tooltip>
                            </LabelStyled>
                        );
                    })
                }
                { withActions && (
                    <Cell basic>
                        <LabelStyled
                            variant={ smallRows ? 'body2' : 'body1' }
                        >
                            { t('common:glossary.action') }
                        </LabelStyled>
                    </Cell>
                ) }
                <div />
            </RootStyled>
        );
    }
}

Head.defaultProps = {
    t: (key) => key,
};

Head.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    flatStyle: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    columnStyles: PropTypes.object,
    onSelectAll: PropTypes.func,
    extendable: PropTypes.bool,
    onSortAll: PropTypes.func,
    isAnySelected: PropTypes.bool,
    isAllSelected: PropTypes.bool,
    withActions: PropTypes.bool,
    order: PropTypes.object.isRequired,
    smallRows: PropTypes.bool,
    isSelectedAll: PropTypes.bool,
};

export default Head;
