import * as S from '../sections';
import * as A from '../accesses';
import { ACCEPT } from '../voteTypes';

export const ROLE_USERSERVICE_JOBS_MANAGE = {
    section: S.USER_SERVICE,
    type: 'main',
    votes: {
        [A.USER_SERVICE.MODULE]: ACCEPT,
        [A.USER_SERVICE.PATHS.JOBS]: ACCEPT,
    },
};
