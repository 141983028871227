import { ACCEPT, CONDITIONAL } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

export const ROLE_STOCK_SOURCE_READ = {
    section: S.STOCK_SOURCES,
    type: 'display',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.SOURCES]: ACCEPT,
        [A.STOCK.PATHS.SOURCES_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.SOURCES.FETCH]: ACCEPT,
    },
};

export const ROLE_STOCK_SOURCE_CREATE = {
    section: S.STOCK_SOURCES,
    type: 'create',
    votes: {
        [A.STOCK.MODULE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ'],
        }),
        [A.STOCK.PATHS.SOURCES]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ'],
        }),
        [A.STOCK.RESOURCES.SOURCES.CREATE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ'],
        }),
    },
};

export const ROLE_STOCK_SOURCE_UPDATE = {
    section: S.STOCK_SOURCES,
    type: 'edit',
    votes: {
        [A.STOCK.PATHS.SOURCES_DETAILS]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ'],
        }),
        [A.STOCK.RESOURCES.SOURCES.EDIT]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ'],
        }),
    },
};

export const ROLE_STOCK_SOURCE_DELETE = {
    section: S.STOCK_SOURCES,
    type: 'delete',
    votes: {
        [A.STOCK.RESOURCES.SOURCES.DELETE]: ACCEPT,
    },
};

export const ROLE_STOCK_AUTHOR_READ = {
    section: S.STOCK_AUTHORS,
    type: 'display',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.AUTHORS]: ACCEPT,
        [A.STOCK.PATHS.AUTHORS_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.AUTHORS.FETCH]: ACCEPT,
    },
};

export const ROLE_STOCK_AUTHOR_CREATE = {
    section: S.STOCK_AUTHORS,
    type: 'create',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.AUTHORS]: ACCEPT,
        [A.STOCK.RESOURCES.AUTHORS.CREATE]: ACCEPT,
    },
};

export const ROLE_STOCK_AUTHOR_UPDATE = {
    section: S.STOCK_AUTHORS,
    type: 'edit',
    votes: {
        [A.STOCK.PATHS.AUTHORS_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.AUTHORS.EDIT]: ACCEPT,
    },
};

export const ROLE_STOCK_AUTHOR_DELETE = {
    section: S.STOCK_AUTHORS,
    type: 'delete',
    votes: {
        [A.STOCK.RESOURCES.AUTHORS.DELETE]: ACCEPT,
    },
};

export const ROLE_STOCK_PATTERN_READ = {
    section: S.STOCK_PATTERNS,
    type: 'display',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.PATTERNS]: ACCEPT,
        [A.STOCK.PATHS.PATTERNS_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.PATTERNS.FETCH]: ACCEPT,
        [A.STOCK.PATHS.PREVIEWS]: ACCEPT,
        [A.STOCK.PATHS.BASE_PATTERNS]: ACCEPT,
        [A.STOCK.PATHS.BASE_PATTERNS_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.BASE_PATTERNS.FETCH]: ACCEPT,
    },
};

export const ROLE_STOCK_PATTERN_CREATE = {
    section: S.STOCK_PATTERNS,
    type: 'create',
    votes: {
        [A.STOCK.MODULE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.PATHS.PATTERNS]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.RESOURCES.PATTERNS.CREATE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.PATHS.BASE_PATTERNS]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.RESOURCES.BASE_PATTERNS.CREATE]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
    },
};

export const ROLE_STOCK_PATTERN_UPDATE = {
    section: S.STOCK_PATTERNS,
    type: 'edit',
    votes: {
        [A.STOCK.PATHS.PATTERNS_DETAILS]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.PATHS.BASE_PATTERNS_DETAILS]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.RESOURCES.PATTERNS.EDIT]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
        [A.STOCK.RESOURCES.BASE_PATTERNS.EDIT]: CONDITIONAL(ACCEPT, {
            reqRoles: ['ROLE_STOCK_AUTHOR_READ', 'ROLE_STOCK_SOURCE_READ'],
        }),
    },
};

export const ROLE_STOCK_PATTERN_DELETE = {
    section: S.STOCK_PATTERNS,
    type: 'delete',
    votes: {
        [A.STOCK.RESOURCES.PATTERNS.DELETE]: ACCEPT,
        [A.STOCK.RESOURCES.BASE_PATTERNS.DELETE]: ACCEPT,
    },
};

export const ROLE_STOCK_PREVIEW_TYPE_READ = {
    section: S.STOCK_PREVIEW_TYPES,
    type: 'display',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.ARM.MODULE]: ACCEPT,
        [A.STOCK.PATHS.PREVIEW_TYPES]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEW_TYPES]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEW_TYPES_DETAILS]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEWS]: ACCEPT,
        [A.STOCK.RESOURCES.PREVIEW_TYPES.FETCH]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEW_TYPES.FETCH]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEWS.FETCH]: ACCEPT,
    },
};

export const ROLE_STOCK_PREVIEW_TYPE_CREATE = {
    section: S.STOCK_PREVIEW_TYPES,
    type: 'create',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.ARM.MODULE]: ACCEPT,
        [A.STOCK.PATHS.PREVIEW_TYPES]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEW_TYPES]: ACCEPT,
        [A.STOCK.PATHS.PREVIEW_TYPES_DETAILS]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEW_TYPES_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.PREVIEW_TYPES.CREATE]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEW_TYPES.CREATE]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEWS.CREATE]: ACCEPT,
    },
};

export const ROLE_STOCK_PREVIEW_TYPE_UPDATE = {
    section: S.STOCK_PREVIEW_TYPES,
    type: 'edit',
    votes: {
        [A.STOCK.RESOURCES.PREVIEW_TYPES.EDIT]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEW_TYPES.EDIT]: ACCEPT,
        [A.ARM.PATHS.PRODUCT_SET_PREVIEW_TYPES_DETAILS]: ACCEPT,
    },
};

export const ROLE_STOCK_PREVIEW_TYPE_DELETE = {
    section: S.STOCK_PREVIEW_TYPES,
    type: 'delete',
    votes: {
        [A.STOCK.RESOURCES.PREVIEW_TYPES.DELETE]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEW_TYPES.DELETE]: ACCEPT,
        [A.STOCK.RESOURCES.PRODUCT_SET_PREVIEWS.DELETE]: ACCEPT,
    },
};

export const ROLE_STOCK_CATEGORY_READ = {
    section: S.STOCK_CATEGORIES,
    type: 'display',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.CATEGORIES]: ACCEPT,
        [A.STOCK.PATHS.CATEGORIES_DETAILS]: ACCEPT,
        [A.STOCK.RESOURCES.CATEGORIES.FETCH]: ACCEPT,
    },
};

export const ROLE_STOCK_CATEGORY_CREATE = {
    section: S.STOCK_CATEGORIES,
    type: 'create',
    votes: {
        [A.STOCK.MODULE]: ACCEPT,
        [A.STOCK.PATHS.CATEGORIES]: ACCEPT,
        [A.STOCK.RESOURCES.CATEGORIES.CREATE]: ACCEPT,
    },
};

export const ROLE_STOCK_CATEGORY_UPDATE = {
    section: S.STOCK_CATEGORIES,
    type: 'edit',
    votes: {
        [A.STOCK.RESOURCES.CATEGORIES.EDIT]: ACCEPT,
    },
};
