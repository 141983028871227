import convert from 'color-convert';

export const getContrastTextColor = (hex: string) => {
    let fixedHex = hex.slice();

    if (fixedHex.indexOf('#') === 0) {
        fixedHex = fixedHex.slice(1);
    }

    if (fixedHex.length === 3) {
        fixedHex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (fixedHex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }

    const rgbArray = [parseInt(fixedHex.slice(0, 2), 16),
        parseInt(fixedHex.slice(2, 4), 16),
        parseInt(fixedHex.slice(4, 6), 16)] as const;

    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const rgbLuminanceArray = rgbArray.map((color) => {
        let col = color / 255.0;

        if (col <= 0.04045) {
            col = col / 12.92;
        } else {
            col = Math.pow(((col + 0.055) / 1.055), 2.4);
        }

        return col;
    });

    const [r, g, b] = rgbLuminanceArray;

    // https://www.w3.org/TR/WCAG20/
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 0.179
        ? '#000000'
        : '#FFFFFF';
};

export const anyColorToHex = (color: string) => {
    if (color.startsWith('#')) {
        return color;
    }

    if (color.startsWith('rgb')) {
        const colorValues = color
            .replace('rgb(', '')
            .replace(')', '')
            .split(',')
            .map((el) => el.trim());

        return `#${convert.rgb.hex(colorValues)}`;
    }

    if (color.startsWith('hsl')) {
        const colorValues = color
            .replace('rgb(', '')
            .replace(')', '')
            .split(',')
            .map((el) => el.trim());

        return `#${convert.rgb.hex(colorValues)}`;
    }

    try {
        return `#${convert.keyword.hex(color.toLowerCase())}`
    } catch {
        return `#${convert.xyz.hex(color)}`;
    }
};
