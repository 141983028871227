import { ApplicationJsonHeaders } from '../../../constants';
import type { StockService } from '../../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../../stockService.type';
import { IGenerateDTO } from './hexColor.type';

export class HexColor {
    private readonly URLS: IStockServiceConst['URLS'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = this.stockService.URLS;

        this.postGenerate = this.postGenerate.bind(this);
    }

    /**
     * @param {string} uploadedFilePath - `${namespace}/${fileId}`
     */
    postGenerate = async (uploadedFilePath: string): Promise<IGenerateDTO> => {
        const { data } = await this.services.ajax.post({
            url: this.URLS.POST_HEX_COLOR_GENERATE,
            config: { headers: ApplicationJsonHeaders },
            data: { file: uploadedFilePath },
        });

        return data;
    };
}
