import Shipment from './actions/Shipment';
import Carriers from './actions/Carriers';
import Document from './actions/Document';
import CarrierRule from './actions/CarrierRule';
import CarrierType from './actions/CarrierType';

import { CustomShipmentActions } from './actions/customShipment/customShipment.actions';
import { CustomShipmentCarrierActions } from './actions/customShipmentCarrier/customShipmentCarrier.actions';
import type { IProdisServiceConstructorParams } from './prodisService.type';

export class ProdisService {
    private static instance: ProdisService | undefined;

    readonly ajaxService: IProdisServiceConstructorParams['services']['ajax'];
    readonly parser: IProdisServiceConstructorParams['services']['parser'];
    readonly mediaFiles: IProdisServiceConstructorParams['services']['mediaFiles'];
    readonly errorHandlers: ReturnType<
        IProdisServiceConstructorParams['errorHandlers']
    >;
    readonly constants: IProdisServiceConstructorParams['constants'];

    readonly shipment: Shipment;
    readonly carriers: Carriers;
    readonly document: Document;
    readonly carrierType: CarrierType;
    readonly carrierRule: CarrierRule;

    private readonly customShipmentActions: CustomShipmentActions;
    private readonly customShipmentCarrierActions: CustomShipmentCarrierActions;

    private constructor({
        services,
        errorHandlers,
        constants,
    }: IProdisServiceConstructorParams) {
        this.ajaxService = services.ajax;
        this.parser = services.parser;
        this.mediaFiles = services.mediaFiles;
        this.errorHandlers = errorHandlers(services.alert);
        this.constants = constants;

        this.customShipmentActions = CustomShipmentActions.getInstance(
            this,
            services,
            constants.URLS,
        );
        this.customShipmentCarrierActions =
            CustomShipmentCarrierActions.getInstance(this, services.ajax);
        this.shipment = new Shipment(this, services, constants.URLS);
        this.carriers = new Carriers(this, services, constants.URLS);
        this.document = new Document(this, services, constants.URLS);
        this.carrierType = new CarrierType(this, services, constants.URLS);
        this.carrierRule = new CarrierRule(this, services, constants);

        this.paramsSerializer = this.paramsSerializer.bind(this);
        this.getCustomShipmentActions =
            this.getCustomShipmentActions.bind(this);
        this.getCustomShipmentCarrierActions =
            this.getCustomShipmentCarrierActions.bind(this);
    }

    static getInstance({
        services,
        errorHandlers,
        constants,
    }: IProdisServiceConstructorParams): ProdisService {
        if (!ProdisService.instance) {
            ProdisService.instance = new ProdisService({
                services,
                errorHandlers,
                constants,
            });
        }

        return ProdisService.instance;
    }

    paramsSerializer = (params: unknown) => this.parser.stringify(params);

    getCustomShipmentActions(): CustomShipmentActions {
        return this.customShipmentActions;
    }

    getCustomShipmentCarrierActions(): CustomShipmentCarrierActions {
        return this.customShipmentCarrierActions;
    }
}
