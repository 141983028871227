import { cmToMm, formatDate, mmToCm } from 'utils/formatting';
import { keysCamelToSnake } from 'utils/mappers';

const createNumberParser = ({ isFloat = false, prepareValue = (v) => v }) => (value = '') => isFloat ? parseFloat(prepareValue(value)) : parseInt(prepareValue(value), 10);

const parseSemicolonSeparatedNumbers = (value = '', { isFloat = false, prepareValue = (v) => v } = {}) =>
    value.split(';').map(createNumberParser({ isFloat, prepareValue }))
        .filter((v) => v);

const mapBoxRuleGetListFiltersParams = ({
    width,
    height,
    widthGte,
    widthLte,
    heightGte,
    heightLte,
    partsCount,
    partsCountGte,
    partsCountLte,
    createdAtAfter,
    updatedAtAfter,
    createdAtBefore,
    updatedAtBefore,
    boxGroupId,
    technologyId,
}) => {
    const createdAt = (createdAtBefore || createdAtAfter) && {
        created_at: {
            after: createdAtAfter,
            before: createdAtBefore
        }
    };

    const updatedAt = (updatedAtBefore || updatedAtAfter) && {
        updated_at: {
            after: updatedAtAfter,
            before: updatedAtBefore
        }
    };

    const parseSizeNumber = createNumberParser({ isFloat: true, prepareValue: cmToMm });
    const parsePartNumber = createNumberParser({ isFloat: false });

    return ({
        ...createdAt,
        ...updatedAt,
        ...(boxGroupId && { 'box_group.id': boxGroupId }),
        ...(technologyId && { 'technology.id': technologyId }),
        ...(width && { width: parseSemicolonSeparatedNumbers(width, { isFloat: true, prepareValue: cmToMm }) }),
        ...(height && { height: parseSemicolonSeparatedNumbers(height, { isFloat: true, prepareValue: cmToMm }) }),
        ...(partsCount && { parts_count: parseSemicolonSeparatedNumbers(partsCount) }),
        ...(widthGte && { 'width[gte]': parseSizeNumber(widthGte) }),
        ...(widthLte && { 'width[lte]': parseSizeNumber(widthLte) }),
        ...(heightGte && { 'height[gte]': parseSizeNumber(heightGte) }),
        ...(heightLte && { 'height[lte]': parseSizeNumber(heightLte) }),
        ...(partsCountGte && { 'parts_count[gte]': parsePartNumber(partsCountGte) }),
        ...(partsCountLte && { 'parts_count[lte]': parsePartNumber(partsCountLte) }),
    });
};

const mapBoxRuleGetListOrderParams = ({ boxGroup, technology, ...sort }) => {
    return ({
        ...keysCamelToSnake(sort),
        ...(boxGroup && { 'box_group.code': boxGroup }),
        ...(technology && { 'technology.code': technology }),
    });
};

export const mapBoxRuleGetListParams = ({
    page,
    per_page,
    sort,
    filters,
    ...params
} = {}) => ({
    ...params,
    ...mapBoxRuleGetListFiltersParams(filters),
    order: mapBoxRuleGetListOrderParams(sort),
    page,
    per_page,
});

export const mapBoxRuleGetList = ({ results, total }) => ({
    items: results.map(({ id, box_group, technology, width, height, parts_count, created_at, updated_at }) => ({
        id,
        boxGroup: box_group.code,
        technology: technology.name,
        width: `${mmToCm(width)} cm`,
        height: `${mmToCm(height)} cm`,
        partsCount: parts_count,
        createdAt: formatDate(created_at),
        updatedAt: formatDate(updated_at),
    })),
    totalItems: total,
});

export const mapPostPatchBoxRule = ({
    boxGroupId,
    technologyId,
    width,
    height,
    partsCount,
    description,
}) => ({
    box_group: {
        id: boxGroupId,
    },
    technology: {
        id: technologyId
    },
    width: cmToMm(parseFloat(width)),
    height: cmToMm(parseFloat(height)),
    parts_count: parseInt(partsCount, 10),
    description,
});

export const mapBoxRuleRemoveBatch = (ids) => ({
    box_rules: ids.map((id) => ({ id })),
});

export const mapGetBoxRule = ({
    box_group: {
        id: boxGroupId,
    },
    technology: {
        id: technologyId
    },
    width: width,
    height: height,
    parts_count: partsCount,
    description
}) => ({
    boxGroupId,
    technologyId,
    width: mmToCm(width)?.toString(),
    height: mmToCm(height)?.toString(),
    partsCount: partsCount?.toString(),
    description: typeof description === 'string' ? description : '',
});
