import { MODULE_NAME } from './constants';
import { PATHS } from './configs/paths.config';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        RETURN_REASONS: {
            CREATE: `${MODULE_NAME}_resources_return_reasons_create`,
            FETCH: `${MODULE_NAME}_resources_return_reasons_fetch`,
            EDIT: `${MODULE_NAME}_resources_return_reasons_edit`,
            DELETE: `${MODULE_NAME}_resources_return_reasons_delete`,
        },
        COMPLAINT_REASONS: {
            CREATE: `${MODULE_NAME}_resources_complaint_reasons_create`,
            FETCH: `${MODULE_NAME}_resources_complaint_reasons_fetch`,
            EDIT: `${MODULE_NAME}_resources_complaint_reasons_edit`,
            DELETE: `${MODULE_NAME}_resources_complaint_reasons_delete`,
        },
        CUSTOMER_REQUESTS: {
            FETCH: `${MODULE_NAME}_resources_customer_requests_fetch`,
            EDIT: `${MODULE_NAME}_resources_customer_requests_edit`,
            DELETE: `${MODULE_NAME}_resources_customer_requests_delete`,
        },
        CUSTOMER_REQUESTS_DETAILS: {
            CREATE: `${MODULE_NAME}_resources_customer_requests_create`,
            CREATE_NEW: `${MODULE_NAME}_resources_customer_requests_create_new`,
            FETCH: `${MODULE_NAME}_resources_customer_requests_fetch`,
            EDIT: `${MODULE_NAME}_resources_customer_requests_edit`,
            DELETE: `${MODULE_NAME}_resources_customer_requests_delete`,
        },
        PRODUCTS: {
            FETCH: `${MODULE_NAME}_resources_products_fetch`,
            EDIT: `${MODULE_NAME}_resources_products_edit`,
            CREATE: `${MODULE_NAME}_resources_products_create`,
            DELETE: `${MODULE_NAME}_resources_products_delete`,
        },
        PHOTOS: {
            FETCH: `${MODULE_NAME}_resources_photos_fetch`,
            CREATE: `${MODULE_NAME}_resources_photos_create`,
            DELETE: `${MODULE_NAME}_resouces_photos_delete`,
        },
    },
} as const;
