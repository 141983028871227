export const mapCarrierType = (carrierType) => ({
    code: carrierType.code,
    name: carrierType.name,
    isSelectable: carrierType.isSelectable,
});

export const mapCarrierTypeGetList = ({ results, total }) => ({
    items: results.map(mapCarrierType),
    totalItems: total,
});
