import { Collapse, ListItemText } from '@mui/material';
import { SectionItemStyled, SectionStyled } from './navBar.styles';
import { DEV_ENV_NAME } from 'utils/enviromentNames';
import type { NavBarModule, NavBarSection } from './navBar.type';
import { checkAccess } from 'access/votingMechanism';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';

interface IExpandedModuleListProps {
    isOpen: boolean;
    sections: NavBarSection[];
    module: NavBarModule;
    additionalItems?: ReactNode | undefined;
}

export const ExpandedModuleList = ({
    isOpen,
    sections,
    module,
    additionalItems,
}: IExpandedModuleListProps) => {
    const isDevEnv = window?.env?.APP_ENV === DEV_ENV_NAME;

    const isSectionDevOnly = (section: NavBarSection) =>
        section.testOnly || module.testOnly;

    const isSectionVisible = (section: NavBarSection) =>
        isDevEnv || !isSectionDevOnly(section);

    const isSectionAllowed = (section: NavBarSection) => {
        const forceAccess = section.forceAccess || module.forceAccess;

        return (
            section.name &&
            !section.hideInNavbar &&
            (forceAccess || checkAccess(section.path))
        );
    };

    const sectionsToShow = sections
        .filter(isSectionVisible)
        .filter(isSectionAllowed);

    const { t } = useTranslation(['app']);

    return (
        <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
        >
            <SectionStyled
                component="div"
                disablePadding
            >
                {sectionsToShow.map((section) => (
                    <SectionItemStyled
                        key={section.name}
                        component={NavLink}
                        to={
                            typeof section?.search === 'string'
                                ? `${section.path}?${section.search}`
                                : section.path
                        }
                    >
                        <ListItemText
                            primary={t(
                                `navigation.${module.name}.${section.name}`,
                                {
                                    defaultValue: section.name,
                                    ns: 'app',
                                },
                            )}
                        />
                    </SectionItemStyled>
                ))}
                {additionalItems}
            </SectionStyled>
        </Collapse>
    );
};
