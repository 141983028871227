import * as S from '../sections';
import * as A from '../accesses';
import { ACCEPT } from '../voteTypes';

export const ROLE_ARM_BOX_EDIT = {
    section: S.BOXES,
    type: 'main',
    votes: {
        [A.BOXES.MODULE]: ACCEPT,
        [A.BOXES.PATHS.UNASSIGNED_BOXES]: ACCEPT,
        [A.BOXES.PATHS.UNASSIGNED_BOX]: ACCEPT,
        [A.BOXES.PATHS.ALL_BOXES]: ACCEPT,
        [A.BOXES.PATHS.ALL_BOX]: ACCEPT,
        [A.BOXES.PATHS.RULE_MANAGEMENT]: ACCEPT,
        [A.BOXES.PATHS.RULE_MANAGEMENT_DETAILS]: ACCEPT,
    },
};

export const ROLE_ARM_BOX_DELETE = {
    section: S.BOXES,
    type: 'delete',
    votes: {
        [A.BOXES.RESOURCES.BOX.DELETE]: ACCEPT,
    },
};