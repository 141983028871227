import get from 'lodash/get';

export const mapTechnologyGroupFromApi = (technologyGroup = {}) => ({
    id: get(technologyGroup, 'id', ''),
    '@id': get(technologyGroup, '@id', ''),
    code: get(technologyGroup, 'code', '').toUpperCase(),
    name: get(technologyGroup, 'name', ''),
    patternCode: get(technologyGroup, 'pattern_code', ''),
    technologies: get(technologyGroup, 'technologies', []),
    createdAt: get(technologyGroup, 'created_at', ''),
});

export const mapTechnologyGroupToApi = (technologyGroup = {}) => ({
    name: get(technologyGroup, 'name', ''),
    code: get(technologyGroup, 'code', ''),
    pattern_code: get(technologyGroup, 'patternCode', ''),
});
