import { paramsSerializer } from '../../../utils/utils';
import { ApplicationJsonHeaders, HttpPatchHeaders } from 'services/constants';

import type { ProductsService } from '../../ProductsService';
import type { DeepPartial } from 'utils/types/deepPartial';
import type {
    ICreateProductSetTypeDto,
    IProductSetType,
    IProductSetTypeListParameters,
    IUpdateProductSetTypeDto,
    TProductSetTypeOutputDto,
    TProductSetTypesResponse,
} from './productSetTypes.actions.type';
import type {
    IProductsServiceServices,
    IStockServiceConst,
} from '../../productsService.type';

export class ProductSetTypesActions {
    private static instance: ProductSetTypesActions | undefined;

    private constructor(
        private readonly productsService: ProductsService,
        private readonly services: IProductsServiceServices,
        private readonly URLS: IStockServiceConst['URLS'],
    ) {
        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.getById = this.getById.bind(this);
    }

    static getInstance(
        productsService: ProductsService,
        services: IProductsServiceServices,
        URLS: IStockServiceConst['URLS'],
    ) {
        if (!ProductSetTypesActions.instance) {
            ProductSetTypesActions.instance = new ProductSetTypesActions(
                productsService,
                services,
                URLS,
            );
        }

        return ProductSetTypesActions.instance;
    }

    async getList(parameters: DeepPartial<IProductSetTypeListParameters>) {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;

        const config = {
            params: parameters,
            paramsSerializer,
            headers: ApplicationJsonHeaders,
        };

        const response: { data: TProductSetTypesResponse } = await ajax.get({
            url: this.URLS.GET_PRODUCT_SET_TYPES,
            config,
            onError: errorHandlers.get,
        });

        return response.data;
    }

    async getById(id: string): Promise<IProductSetType> {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response: { data: IProductSetType } = await ajax.get({
            url: this.URLS.DELETE_PRODUCT_SET_TYPE.replace('{id}', id),
            config,

            onError: errorHandlers.get,
        });

        return response.data;
    }

    delete(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        return ajax.delete({
            url: this.URLS.DELETE_PRODUCT_SET_TYPE.replace('{id}', id),
            config,
            onError: errorHandlers.delete,
        });
    }

    async create(
        payload: ICreateProductSetTypeDto,
    ): Promise<TProductSetTypeOutputDto> {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const response: { data: TProductSetTypeOutputDto } = await ajax.post({
            url: this.URLS.POST_PRODUCT_SET_TYPE,
            config,
            data: payload,
            onError: errorHandlers.post,
        });

        return response.data;
    }

    async edit(
        id: string,
        payload: IUpdateProductSetTypeDto,
    ): Promise<TProductSetTypeOutputDto> {
        const { ajax } = this.services;
        const { errorHandlers } = this.productsService;

        const config = {
            headers: HttpPatchHeaders,
        };

        const response: { data: TProductSetTypeOutputDto } = await ajax.patch({
            url: this.URLS.PATCH_PRODUCT_SET_TYPE.replace('{id}', id),
            config,
            data: payload,
            onError: errorHandlers.patch,
        });

        return response.data;
    }
}
