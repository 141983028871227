import Previews from './actions/Previews';
import Authors from './actions/Authors';
import Tags from './actions/Tags';
import Patterns from './actions/Patterns';
import Categories from './actions/Categories';
import PreviewTypes from './actions/PreviewTypes';
import Sources from './actions/Sources';
import Colors from 'services/stock/actions/Colors';
import SourceCategories from 'services/stock/actions/sourceCategories/SourceCategories';
import BasePatterns from './actions/basePatterns/BasePatterns';
import { ProductSetPreviewTypesActions } from './actions/productSetPreviewTypes/productSetPreviewTypes.actions';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';
import type { UserService } from 'services/userService/UserService';
import type { i18n } from 'i18next';
import type { uploadServicesFactory as UploadServicesFactory } from './uploadServices/uploadServicesFactory';
import type { default as RequestCache } from 'services/requestCache/RequestCache';
import type { default as ErrorHandlersFactory } from './errorHandlers';
import { ProductSetPreviewsActions } from './actions/productSetPreviews/productSetPreviews.actions';

import {
    IStockServiceConst,
    IStockServiceConstructorParams,
} from './stockService.type';
import { ColorDefinition } from './actions/colorDefinition/ColorDefinition';
import { HexColor } from './actions/hexColor/HexColor';

export class StockService {
    private readonly productSetPreviewTypesActions: ProductSetPreviewTypesActions;
    private readonly productSetPreviewsActions: ProductSetPreviewsActions;

    private readonly ajax: AjaxService;
    private readonly alert: AlertService;
    private readonly userServiceApi: UserService;
    private readonly translator: i18n;
    private readonly requestCache: RequestCache;
    public readonly upload: ReturnType<typeof UploadServicesFactory>;
    public readonly errorHandlers: ReturnType<typeof ErrorHandlersFactory>;
    public readonly URLS: IStockServiceConst['URLS'];
    public readonly HEADERS: IStockServiceConst['HEADERS'];
    public readonly DEFAULTS: IStockServiceConst['DEFAULTS'];
    public readonly APP_ALERTS: IStockServiceConst['APP_ALERTS'];

    public readonly previews: Previews;
    public readonly authors: Authors;
    public readonly tags: Tags;
    public readonly patterns: Patterns;
    public readonly categories: Categories;
    public readonly previewTypes: PreviewTypes;
    public readonly sources: Sources;
    public readonly colors: Colors;
    public readonly sourceCategories: SourceCategories;
    public readonly basePatterns: BasePatterns;
    public readonly colorDefinition: ColorDefinition;
    public readonly hexColor: HexColor;

    private constructor({
        services,
        constants,
        errorHandlers,
    }: IStockServiceConstructorParams) {
        this.ajax = services.ajax;
        this.alert = services.alert;
        this.userServiceApi = services.userServiceApi;
        this.translator = services.translator;
        this.requestCache = services.requestCache;
        this.upload = {
            patterns: services.upload.patterns,
            previewTypes: services.upload.previewTypes,
            previews: services.upload.previews,
            paintColorPalettes: services.upload.paintColorPalettes,
            drafts: services.upload.drafts,
            basePatternsFiles: services.upload.basePatternsFiles,
        };
        this.errorHandlers = errorHandlers;
        this.URLS = constants.URLS;
        this.HEADERS = constants.HEADERS;
        this.DEFAULTS = constants.DEFAULTS;
        this.APP_ALERTS = constants.APP_ALERTS;

        this.previews = new Previews(this, services);
        this.authors = new Authors(this, services);
        this.tags = new Tags(this, services);
        this.patterns = new Patterns(this, services);
        this.categories = new Categories(this, services);
        this.previewTypes = new PreviewTypes(this, services);
        this.sources = new Sources(this, services);
        this.colors = new Colors(this, services);
        this.sourceCategories = new SourceCategories(this, services);

        this.basePatterns = new BasePatterns(this, services);
        this.productSetPreviewTypesActions =
            ProductSetPreviewTypesActions.getInstance(this, services);
        this.productSetPreviewsActions = ProductSetPreviewsActions.getInstance(
            this,
            services.ajax,
        );
        this.colorDefinition = new ColorDefinition(this, services);
        this.hexColor = new HexColor(this, services);

        this.addPreviewToPattern = this.addPreviewToPattern.bind(this);
        this.addPreviewsToPattern = this.addPreviewsToPattern.bind(this);
        this.getProductSetPreviewTypesActions =
            this.getProductSetPreviewTypesActions.bind(this);
        this.getProductSetPreviewsActions =
            this.getProductSetPreviewsActions.bind(this);
    }

    private static instance: StockService | undefined;

    public static getInstance({
        services,
        constants,
        errorHandlers,
    }: IStockServiceConstructorParams): StockService {
        if (!StockService.instance) {
            StockService.instance = new StockService({
                services,
                constants,
                errorHandlers,
            });
        }

        return StockService.instance;
    }

    addPreviewToPattern(patternId, preview) {
        const url = this.URLS.POST_ADD_PREVIEW_TO_PATTERN.replace(
            '{id}',
            patternId,
        );

        const data = {
            type: {
                id: preview.type,
            },
            preview_file: {
                id: preview.fileId,
            },
        };
        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.post,
        };

        return this.ajax.post({ url, data, errorConfig });
    }

    addPreviewsToPattern(patternId, previews) {
        return Promise.all(
            previews.map((preview) =>
                this.addPreviewToPattern(patternId, preview),
            ),
        );
    }

    getProductSetPreviewTypesActions(): ProductSetPreviewTypesActions {
        return this.productSetPreviewTypesActions;
    }

    getProductSetPreviewsActions(): ProductSetPreviewsActions {
        return this.productSetPreviewsActions;
    }
}
