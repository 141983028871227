import React from 'react';
import { fromJS } from 'immutable';
import Button from 'components/Button/button';
import Typography from 'components/Typography';
import Table from 'components/OldTable/OldTableWithState';
import SearchPanel from '../SearchPanel/SearchPanel';
import ProductCatalogSelect from '../../containers/ProductCatalogSelect/mapProps';
import withSelectableList from 'hoc/withSelectableList/withSelectableList';
import { AddIcon } from 'components/Icons/Icons';
import { PRODUCT_POSSIBILITIES_PER_PAGE_OPTIONS } from '../../constants';
import * as PropTypes from 'prop-types';
import {
    MainContainerStyled,
    RibbonStyled,
    RowButtonStyled,
    TableWrapperStyled,
    TopPanelStyled,
} from 'modules/stablo/components/ProductPossibilitiesTable/productPossibilitiesTable.styles';
import { getString } from 'utils/immutable/map';

class ProductPossibilitiesTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.pages = fromJS({});
        this.state = {
            selectedCatalog: '',
            catalogSelectorError: false,
            catalogSelectorTouched: false,
        };
    }

    componentDidMount() {
        this.fetchProductPossibilities();
    }

    fetchProductPossibilities = (catalogId) => {
        const { patternQuery = '', fetchProductPossibilities } = this.props;
        const { selectedCatalog } = this.state;
        const catalog = catalogId || selectedCatalog;
        const patterns = patternQuery
            .split(',')
            .map((pattern) => pattern.replace(/[\n  ]/g, ''))
            .filter((pattern) => pattern.length > 0);

        if (!catalog) {
            this.setState({ catalogSelectorError: true });
        } else {
            this.setState({ catalogSelectorError: false });
            if (patterns.length > 0) {
                fetchProductPossibilities({
                    query: patternQuery,
                    params: {
                        catalog: catalogId || selectedCatalog,
                        pattern: patterns,
                    },
                });
            }
        }
    };

    setPaginatedProductPossibilities() {
        const { productPossibilities, per_page } = this.props;
        const itemsCount = productPossibilities.size;
        const pageCount = Math.ceil(itemsCount / per_page);

        const pagination = [];

        for (let i = 0; i < pageCount; i += 1) {
            const startIndex = i * per_page;

            pagination.push(
                productPossibilities.slice(startIndex, startIndex + per_page),
            );
        }

        this.pages = pagination;
    }

    handleSelectCatalog = (e, target) => {
        const catalogId = target.props.value;

        this.setState({
            selectedCatalog: catalogId,
        });
        this.fetchProductPossibilities(catalogId);
    };

    handleSetCatalogTouched = () => {
        this.setState({
            catalogSelectorTouched: true,
        });
    };

    getActivePage() {
        const { activePage = 1 } = this.props;

        return this.pages[activePage - 1] || fromJS({});
    }

    possibilityToRequestObject = (productPossibility) => {
        return {
            pattern: productPossibility.get('pattern'),
            size: productPossibility.get('size'),
            catalog: productPossibility.get('catalog'),
            group: productPossibility.get('group'),
            variantCode: productPossibility.get('variantCode'),
            id: productPossibility.get('id'),
            accessories: productPossibility.get('accessories'),
            colorCode: productPossibility.get('colorCode'),
        };
    };

    generateSkuForProductPossibility = (productPossibility) => {
        this.props.generateSkuForProductPossibilities({
            items: [this.possibilityToRequestObject(productPossibility)],
        });
    };

    generateSkuForProductPossibilities = () => {
        const { generateSkuForProductPossibilities, selectedItems } =
            this.props;
        const possibilitiesPage = this.getActivePage();
        const items = [];

        possibilitiesPage.map((possibility) => {
            if (selectedItems.get(getString(possibility, 'id'))) {
                items.push(this.possibilityToRequestObject(possibility));
            }
        });

        generateSkuForProductPossibilities({ items });
    };

    possibilityToRequestSkuObject = (productPossibility) => {
        return {
            pattern: productPossibility.get('pattern'),
            size: productPossibility.get('size'),
            catalog: productPossibility.get('catalog'),
            group: productPossibility.get('group'),
            variantCode: productPossibility.get('variantCode'),
            id: productPossibility.get('id'),
            skus: productPossibility.get('skus'),
            accessories: productPossibility.get('accessories'),
            colorCode: productPossibility.get('colorCode'),
        };
    };

    generateAdditionalSkuForProductPossibility = (productPossibility) => {
        this.props.generateAdditionalSkuForProductPossibilities({
            items: [this.possibilityToRequestSkuObject(productPossibility)],
        });
    };

    generateAdditionalSkuForProductPossibilities = () => {
        const { generateAdditionalSkuForProductPossibilities, selectedItems } =
            this.props;
        const possibilitiesPage = this.getActivePage();
        const items = possibilitiesPage.reduce((possibilities, possibility) => {
            if (selectedItems.get(getString(possibility, 'id'))) {
                possibilities.push(
                    this.possibilityToRequestSkuObject(possibility),
                );
            }

            return possibilities;
        }, []);

        generateAdditionalSkuForProductPossibilities({ items });
    };

    renderSkuFrField(possibility) {
        const { t } = this.props;
        const skus = possibility.get('skus');

        return possibility.get('skus') && possibility.get('skus').size > 0 ? (
            <div>
                {skus.toJS().join(', ')}
                <RowButtonStyled
                    size="small"
                    aria-label="add"
                    padding="20px"
                    onClick={() =>
                        this.generateAdditionalSkuForProductPossibility(
                            possibility,
                        )
                    }
                >
                    <AddIcon
                        variant="text"
                        color="primary"
                        cursor="pointer"
                    />
                </RowButtonStyled>
            </div>
        ) : (
            <Button
                fullWidth
                variant="text"
                color="primary"
                onClick={() =>
                    this.generateSkuForProductPossibility(possibility)
                }
            >
                {t('common:actions.generate')}
            </Button>
        );
    }

    render() {
        const {
            t,
            loading,
            invalidPatterns,
            defaultQuery,
            resetProductPossibilities,
            handleSelectItem,
            handleSelectAllFetched,
            isSelectedAllFetched,
            isAnySelected,
            selectedItems,
            productPossibilities,
        } = this.props;
        const {
            selectedCatalog,
            catalogSelectorError,
            catalogSelectorTouched,
        } = this.state;
        const itemsCount = productPossibilities.size;
        const patternsError =
            invalidPatterns && invalidPatterns.length > 0
                ? `${t('alerts.invalidPatterns')}: ${invalidPatterns.join(', ')}`
                : '';

        this.setPaginatedProductPossibilities();

        const count1 = this.getActivePage().size;
        const count2 = itemsCount;

        const showError = catalogSelectorTouched && catalogSelectorError;

        return (
            <MainContainerStyled>
                <SearchPanel
                    t={t}
                    urlPushTimeout={0}
                    initialValue={defaultQuery}
                    searchedField="patterns"
                    onPick={this.fetchProductPossibilities}
                    onClear={resetProductPossibilities}
                    placeholderLabel={`${t('common:actions.search')}...`}
                    title="Wyszukaj po wzorze:"
                    error={patternsError}
                />
                <ProductCatalogSelect
                    t={t}
                    value={selectedCatalog}
                    onChange={this.handleSelectCatalog}
                    onBlur={this.handleSetCatalogTouched}
                    helperText={showError ? t('alerts.requiredCatalog') : ' '}
                    error={showError}
                />
                {selectedCatalog && (
                    <TopPanelStyled>
                        <Button
                            baseWidth
                            color="primary"
                            onClick={this.generateSkuForProductPossibilities}
                        >
                            {t('common:actions.generateForMany')}
                        </Button>
                    </TopPanelStyled>
                )}
                {selectedCatalog && (
                    <RibbonStyled>
                        <Typography
                            variant="body1"
                            component="div"
                        >
                            {t('stablo:labels.countMessages.view', {
                                count1,
                                count2,
                            })}
                        </Typography>
                    </RibbonStyled>
                )}
                {selectedCatalog && (
                    <TableWrapperStyled>
                        <Table
                            t={t}
                            loading={loading}
                            itemsById={this.getActivePage()}
                            itemsTotal={itemsCount}
                            withPagination={true}
                            perPageOptions={
                                PRODUCT_POSSIBILITIES_PER_PAGE_OPTIONS
                            }
                            minWidth="1000px"
                            selectedItems={selectedItems}
                            onSelect={handleSelectItem}
                            onSelectAll={handleSelectAllFetched}
                            isSelectedAll={isSelectedAllFetched}
                            isAnySelected={isAnySelected}
                            columnTemplate="140px 140px 100px 400px auto"
                            columns={[
                                {
                                    label: t('common:glossary.pattern'),
                                    cell: (productPossibility) =>
                                        productPossibility.get('pattern'),
                                },
                                {
                                    label: t('common:glossary.variantCode'),
                                    cell: (productPossibility) =>
                                        productPossibility.get('variantCode'),
                                },
                                {
                                    label: t('common:glossary.group'),
                                    cell: (productPossibility) =>
                                        productPossibility.get('group'),
                                },
                                {
                                    label: t('common:glossary.size'),
                                    withTitle: true,
                                    cell: (productPossibility) =>
                                        productPossibility.get('size'),
                                },
                                {
                                    label: 'SKU',
                                    cell: (productPossibility) =>
                                        this.renderSkuFrField(
                                            productPossibility,
                                        ),
                                },
                            ]}
                        />
                    </TableWrapperStyled>
                )}
            </MainContainerStyled>
        );
    }
}

ProductPossibilitiesTable.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    patternQuery: PropTypes.string.isRequired,
    fetchProductPossibilities: PropTypes.func.isRequired,
    per_page: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    generateSkuForProductPossibilities: PropTypes.func.isRequired,
    selectedItems: PropTypes.object.isRequired,
    invalidPatterns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    defaultQuery: PropTypes.string.isRequired,
    resetProductPossibilities: PropTypes.func.isRequired,
    handleSelectItem: PropTypes.func.isRequired,
    handleSelectAllFetched: PropTypes.func.isRequired,
    isSelectedAllFetched: PropTypes.bool.isRequired,
    isAnySelected: PropTypes.bool.isRequired,
    productPossibilities: PropTypes.object.isRequired,
    generateAdditionalSkuForProductPossibilities: PropTypes.func.isRequired,
};

const ProductPossibilitiesTableWithHOC = withSelectableList(
    ProductPossibilitiesTable,
    {
        listName: 'productPossibilities',
        keyName: 'id',
        autoCheckForSelected: true,
    },
);

export default ProductPossibilitiesTableWithHOC;
