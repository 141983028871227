import get from 'lodash/get';
import parser from 'qs';
import moment from 'moment';
import {
    mapBoxUnassignmentToApi,
    mapIntermediateProductFromApi,
} from '../mappers/intermediateProduct';
import isEmpty from 'lodash/isEmpty';
import { mapFiltersToApi } from 'utils/filters';
import { ApplicationJsonHeaders, HeadersAcceptType } from 'services/constants';
import { configError } from 'services/utils/utils';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class IntermediateProducts {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.URLS = URLS;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.unassignFromBox = this.unassignFromBox.bind(this);
    }

    private paramsSerializer = (params) => parser.stringify(params);
    mapFilters = (filters = {}, onlySearch = false) => {
        const {
            search,
            technologyId,
            width,
            height,
            created_at,
            shippingBox,
            internalBox,
            ...rest
        } = filters;
        const parsedFilters = { ...rest };

        if (search) {
            if (onlySearch) {
                parsedFilters.search = search;
            } else {
                parsedFilters.name = { match: search };
            }
        }
        if (shippingBox) parsedFilters.shipping_box = { eq: shippingBox };
        if (internalBox) parsedFilters.internal_box = { eq: internalBox };
        if (technologyId) parsedFilters.group = { eq: technologyId };
        if (width) parsedFilters.width = { gte: width.gte * 10 };
        if (height) parsedFilters.height = { gte: height.gte * 10 };
        if (created_at) {
            parsedFilters.created_at = {};
            Object.keys(created_at).forEach((key) => {
                parsedFilters.created_at[key] = moment
                    .unix(created_at[key])
                    .format();
            });
        }

        return parsedFilters;
    };

    async getList({
        filters = {},
        per_page = 50,
        page = 1,
        searchOnly,
        sort = {},
    }) {
        const { ajax } = this.services;
        const url = this.URLS.GET_INTERMEDIATE_PRODUCTS;
        const config = {
            params: {
                page,
                per_page,
                sort,
            },
            paramsSerializer: this.paramsSerializer,
        };
        const filtersParams = {
            filter: this.mapFilters(filters, searchOnly),
        };

        config.params = {
            ...config.params,
            ...filtersParams,
        };

        const response = await ajax.get({ url, config });

        return {
            items: get(response, 'data.hydra:member', []).map((interProduct) =>
                mapIntermediateProductFromApi(interProduct),
            ),
            itemsTotal: get(response, 'data.hydra:totalItems', 0),
        };
    }

    async getById(id) {
        const { ajax } = this.services;
        const url = this.URLS.GET_INTERMEDIATE_PRODUCT.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
            throwError:
                this.armApiService.errorHandlers.intermediateProductNotFound,
        };

        const response = await ajax.get({ url, errorConfig });

        return mapIntermediateProductFromApi(response.data);
    }

    async getCsvFile(
        { filters = {}, per_page = 100, page = 1, sort = {} },
        fileName,
    ) {
        const { ajax } = this.services;

        const config = {
            params: {
                per_page,
                page,
                sort,
            },
            headers: {
                Accept: HeadersAcceptType.csv,
            },
            paramsSerializer: this.paramsSerializer,
        };

        if (!isEmpty(filters)) {
            const filtersParams = mapFiltersToApi(filters);

            config.params = {
                ...config.params,
                ...filtersParams,
            };
        }

        return await ajax.download({
            url: this.URLS.GET_INTERMEDIATE_PRODUCTS_CSV_FILE,
            config,
            fileName,
        });
    }

    async unassignFromBox({ id, removeFrom }) {
        const { errorHandlers } = this.armApiService;
        const { ajax } = this.services;

        const url = this.URLS.PATCH_INTERMEDIATE_PRODUCT.replace('{id}', id);
        const data = mapBoxUnassignmentToApi({ removeFrom });
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const errorConfig = configError('patch', errorHandlers);

        const response = await ajax.patch({ url, data, errorConfig, config });

        return mapIntermediateProductFromApi(response.data);
    }
}

export default IntermediateProducts;
