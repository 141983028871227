import get from 'lodash/get';

const MEDIA_FILES_SERVICE_URL = get(window, 'env.MEDIA_FILES_URL', '');

export const URLS = {
    MEDIA_FILES_API_V1: `${MEDIA_FILES_SERVICE_URL}/api/v1`,
    UPLOAD_CUSTOM_FILES: `${MEDIA_FILES_SERVICE_URL}/api/v1/custom-files`,
    PRODIS_MEDIA_FILES: `${MEDIA_FILES_SERVICE_URL}/api/v1/prodis`,
    CUSTOMER_REQUESTS_MEDIA_FILES: `${MEDIA_FILES_SERVICE_URL}/api/v1/customer-requests`,
};
