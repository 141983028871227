import { getNamespaces } from '../helpers/getNamespaces';

const defaultNamespaces = {
    itemsById: 'itemsById',
    itemsCount: 'itemsCount',
    errors: 'errors',
    createLoader: 'createLoader',
};

export function request({ namespaces: configNamespaces, initialState }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state }) {
        const errors = initialState.get(namespaces.errors);

        return state
            .set(namespaces.errors, errors)
            .set(namespaces.createLoader, true);
    };
}

export function failure({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state, responseModel }) {
        const errors = responseModel.getErrors(options && options.model);

        return state
            .set(namespaces.errors, errors)
            .set(namespaces.createLoader, false);
    };
}

export function success({ options, namespaces: configNamespaces }) {
    const namespaces = getNamespaces(configNamespaces, defaultNamespaces);

    return function({ state, responseModel }) {
        const item = responseModel.getItemFromResponse(options && options.model);
        const id = item.getId ? item.getId() : item.id && item.id.toString();
        const itemsCount = state.get(namespaces.itemsCount) + 1;

        return state
            .setIn([namespaces.itemsById, id], item)
            .set(namespaces.itemsCount, itemsCount)
            .set(namespaces.createLoader, false);
    };
}
