import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function LessIcon(props) {
    return (
        <SvgIcon
            viewBox="-4 -3 16 16"
            { ...props }
        >
            <path
                d="M0.838745 10.1092C0.838765 10.19 0.862905 10.269 0.908079 10.3361C0.953252 10.4031 1.0174 10.4552 1.09232 10.4856C1.16725 10.516 1.24953 10.5233 1.32865 10.5067C1.40777 10.49 1.48012 10.4502 1.53646 10.3922L4.49923 7.42942L7.46199 10.3922C7.49943 10.4312 7.54426 10.4623 7.59388 10.4837C7.64349 10.5052 7.69689 10.5165 7.75094 10.5171C7.80498 10.5176 7.8586 10.5074 7.90864 10.4869C7.95868 10.4665 8.00414 10.4363 8.04236 10.3981C8.08057 10.3599 8.11078 10.3144 8.13121 10.2644C8.15164 10.2143 8.16188 10.1607 8.16133 10.1067C8.16079 10.0526 8.14946 9.99923 8.12801 9.94961C8.10657 9.9 8.07544 9.85516 8.03646 9.81773L4.78646 6.56773C4.71027 6.49157 4.60695 6.44879 4.49923 6.44879C4.3915 6.44879 4.28818 6.49157 4.21199 6.56773L0.961995 9.81773C0.922999 9.85559 0.891998 9.9009 0.870827 9.95097C0.849657 10.001 0.838747 10.0548 0.838745 10.1092ZM0.839274 1.17539C0.842349 1.2809 0.886362 1.38106 0.961995 1.45469L4.21199 4.70469C4.28818 4.78085 4.3915 4.82363 4.49923 4.82363C4.60695 4.82363 4.71027 4.78085 4.78646 4.70469L8.03646 1.45469C8.07544 1.41725 8.10657 1.37242 8.12801 1.3228C8.14946 1.27319 8.16078 1.21979 8.16133 1.16575C8.16188 1.1117 8.15164 1.05809 8.13121 1.00804C8.11078 0.958005 8.08057 0.912544 8.04236 0.874324C8.00414 0.836106 7.95868 0.805896 7.90864 0.785467C7.85859 0.765037 7.80498 0.754797 7.75093 0.755346C7.69689 0.755896 7.64349 0.767223 7.59388 0.788666C7.54426 0.81011 7.49943 0.841237 7.46199 0.880225L4.49923 3.84299L1.53646 0.880225C1.47938 0.821617 1.40593 0.781605 1.32574 0.765427C1.24554 0.749249 1.16233 0.757658 1.087 0.789554C1.01166 0.821448 0.947706 0.875345 0.903506 0.944188C0.859306 1.01303 0.836918 1.09361 0.839274 1.17539Z"
            />
        </SvgIcon>
    );
}

LessIcon.defaultProps = {
    titleAccess: 'less-icon',
};

LessIcon.propTypes = svgIconPropTypes;
