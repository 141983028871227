import { fromJS } from 'immutable';
import {
    setInitialState,
    clearErrors,
    createReduxMethods,
    removeReduxMethods,
    editReduxMethods,
    fetchReduxMethods,
    fetchListReduxMethods,
    fetchTechnologyComponentsReduxMethods,
} from 'utils/redux/handleTableView/reduxMethods';
import * as C from './constants';

export const options = {
    singularName: 'intermediateProduct',
    pluralName: 'intermediateProducts',
};

export const initialState = setInitialState(options, {
    postResponseSuccess: null,
});

export default function intermediateProductsReducer(state = initialState, action = {}) {
    const actionProps = { options, state, action };

    /* istanbul ignore next */
    switch (action.type) {
        case C.CLEAR_ERRORS: return clearErrors(actionProps);

        case C.CREATE_INTERMEDIATE_PRODUCT.request: return createReduxMethods.request(actionProps);
        case C.CREATE_INTERMEDIATE_PRODUCT.failure: return createReduxMethods.failure(actionProps);
        case C.CREATE_INTERMEDIATE_PRODUCT.success: {
            const technology = action.payload.pattern;
            const editedItem = action.payload.response || { id: null };

            return createReduxMethods.success(actionProps)
                .setIn([`${options.pluralName}ById`, editedItem.id.toString(), 'groups'], fromJS([technology]));
        }

        case C.REMOVE_INTERMEDIATE_PRODUCT.request: return removeReduxMethods.request(actionProps);
        case C.REMOVE_INTERMEDIATE_PRODUCT.failure: return removeReduxMethods.failure(actionProps);
        case C.REMOVE_INTERMEDIATE_PRODUCT.success: return removeReduxMethods.success(actionProps);

        case C.EDIT_INTERMEDIATE_PRODUCT.request: return editReduxMethods.request(actionProps);
        case C.EDIT_INTERMEDIATE_PRODUCT.failure: return editReduxMethods.failure(actionProps);
        case C.EDIT_INTERMEDIATE_PRODUCT.success: {
            const technology = action.payload.pattern;
            const editedItem = action.payload.response || { id: null };

            return editReduxMethods.success(actionProps)
                .setIn([`${options.pluralName}ById`, editedItem.id.toString(), 'groups'], fromJS([technology]));
        }

        case C.FETCH_INTERMEDIATE_PRODUCTS.request: return fetchListReduxMethods.request(actionProps);
        case C.FETCH_INTERMEDIATE_PRODUCTS.failure: return fetchListReduxMethods.failure(actionProps);
        case C.FETCH_INTERMEDIATE_PRODUCTS.success: return fetchListReduxMethods.success(actionProps);

        case C.FETCH_INTERMEDIATE_PRODUCT.request: return fetchReduxMethods.request(actionProps);
        case C.FETCH_INTERMEDIATE_PRODUCT.failure: return fetchReduxMethods.failure(actionProps);
        case C.FETCH_INTERMEDIATE_PRODUCT.success: return fetchReduxMethods.success(actionProps);

        case C.FETCH_TECHNOLOGY_COMPONENTS.request: return fetchTechnologyComponentsReduxMethods.request(actionProps);
        case C.FETCH_TECHNOLOGY_COMPONENTS.success: return fetchTechnologyComponentsReduxMethods.success(actionProps);
        case C.FETCH_TECHNOLOGY_COMPONENTS.failure: return fetchTechnologyComponentsReduxMethods.failure(actionProps);

        default:
            return state;
    }
}
