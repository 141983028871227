import { put, select, takeEvery } from 'redux-saga/effects';
import qs from 'qs';
import {
    FETCH_ITEMS,
} from './constants';
import {
    fetchItems,
} from './actions';
import { getSearch } from 'utils/router/selectors';
import { selectItemsParam } from 'modules/demo/reducers/items/selectors';

export default function* productsSaga() {
    yield takeEvery(FETCH_ITEMS.request, getItems);
}

function delay(ms = Math.random() * 4000 + 1000) {
    return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

const serverItems = [];

for (let ii = 0; ii < 300; ii++) {
    serverItems.push(
        {
            id: ii.toString(),
            name: 'Item ' + ii + ' There is one restriction when you want to make a large column table',
            enabled: false,
        }
    );
}

function* getItems(action) {
    const selectParams = yield select(selectItemsParam);
    const urlParams = yield select(getSearch);
    const params = {
        ...selectParams.toJS(),
        ...qs.parse(urlParams),
    };

    const items = serverItems.slice(
        (params.page - 1) * params.per_page,
        (params.page) * params.per_page
    );

    yield delay();
    yield put(fetchItems.success({
        response: {
            ['hydra:member']: items,
            ['hydra:totalItems']: serverItems.length,
        },
        refresh: action.payload.refresh,
        nextPage: params.page + 1,
    }));
}
