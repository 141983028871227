import PropTypes from 'prop-types';

export const svgIconPropTypes = {
    titleAccess: PropTypes.string,
    colors: PropTypes.oneOf([
        'action',
        'disabled',
        'error',
        'inherit',
        'primary',
        'secondary',
    ]),
    fontSize: PropTypes.oneOf([
        'default',
        'inherit',
        'large',
        'medium',
        'small',
    ]),
};
