import { ApplicationJsonHeaders } from '../../../constants';
import type { StockService } from '../../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../../stockService.type';
import type { IGetColorDefinitionResponse } from './colorDefinition.type';

export class ColorDefinition {
    private readonly URLS: IStockServiceConst['URLS'];

    constructor(
        stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = stockService.URLS;
        this.getColorDefinition = this.getColorDefinition.bind(this);
    }

    async getColorDefinition(): Promise<IGetColorDefinitionResponse> {
        const url = this.URLS.GET_COLOR_DEFINITION;
        const { ajax } = this.services;

        const { data } = await ajax.get({
            url,
            config: { headers: ApplicationJsonHeaders },
        });

        return data;
    }
}
