import React from 'react';

import { FILTER_DEFAULT_VALUES, FILTER_FIELD_TYPES, FILTER_MATCHER_TYPES } from 'services/constants';

import {
    AssignmentIcon,
    CalendarIcon,
    DialpadIcon,
    EnvelopeIcon,
    GearIcon,
    GlobeIcon,
    StorageIcon,
    UserIcon,
} from 'components/Icons/Icons';

export default {
    orderNumber: {
        name: 'orderNumber',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.orderNumber',
        isModalFilter: false,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    orderName: {
        name: 'orderName',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.orderName',
        isModalFilter: false,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    customerEmail: {
        name: 'customerEmail',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.customerEmail',
        isModalFilter: false,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <EnvelopeIcon size="small" />,
    },
    customerNick: {
        name: 'customerNick',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.customerNick',
        isModalFilter: false,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <UserIcon size="small" />,
    },
    billingAddressName: {
        name: 'billingAddressName',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.billingAddressName',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <UserIcon size="small" />,
    },
    billingAddressCompany: {
        name: 'billingAddressCompany',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.billingAddressCompany',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <StorageIcon size="small" />,
    },
    billingAddressPhoneNumber: {
        name: 'billingAddressPhoneNumber',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.billingAddressPhoneNumber',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <DialpadIcon size="small" />,
    },
    billingAddressCity: {
        name: 'billingAddressCity',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.billingAddressCity',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_PARTIAL,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <GlobeIcon size="small" />,
    },
    billingAddressCountryCode: {
        name: 'billingAddressCountryCode',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'sales:labels.orderFilters.billingAddressCountryCode',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.AUTOCOMPLETE_FIELD,
        icon: <GlobeIcon size="small" />,
    },
    purchaseDateBefore: {
        name: 'purchaseDate',
        value: FILTER_DEFAULT_VALUES.DATE_RANGE_PICKER_FIELD,
        label: 'sales:labels.orderFilters.orderPurchaseDateBefore',
        labelAlt: 'sales:labels.orderFilters.orderPurchaseDate',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.DATE_RANGE,
        fieldType: FILTER_FIELD_TYPES.DATE_RANGE_PICKER_FIELD,
        icon: <CalendarIcon size="small" />,
        order: 1,
    },
    purchaseDateAfter: {
        name: 'purchaseDate',
        value: FILTER_DEFAULT_VALUES.DATE_RANGE_PICKER_FIELD,
        label: 'sales:labels.orderFilters.orderPurchaseDateAfter',
        labelAlt: 'sales:labels.orderFilters.orderPurchaseDate',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.DATE_RANGE,
        fieldType: FILTER_FIELD_TYPES.DATE_RANGE_PICKER_FIELD,
        icon: <CalendarIcon size="small" />,
        order: 0,
    },
    'orderLines.listingId': {
        name: 'orderLines.listingId',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.orderLinesListingId',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    channelId: {
        name: 'channelId',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'sales:labels.orderFilters.orderChannelId',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.MULTI_SELECT_FIELD,
        icon: <StorageIcon size="small" />,
    },
    company: {
        name: 'company',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'sales:labels.orderFilters.company',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        fieldType: FILTER_FIELD_TYPES.MULTI_SELECT_FIELD,
    },
    'order.marketplaceCountryCode': {
        name: 'order.marketplaceCountryCode',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'sales:labels.orderFilters.orderMarketplaceCountryCode',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.MULTI_SELECT_FIELD,
        icon: <GlobeIcon size="small" />,
    },
    productionOrderNumber: {
        name: 'productionOrderNumber',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.stabloFullCode',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    'orderLine.packageTrackingNumber': {
        name: 'orderLine.packageTrackingNumber',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.orderLinePackageTrackingNumber',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    'order.invoices.invoiceNumber': {
        name: 'order.invoices.invoiceNumber',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.orderInvoicesInvoiceNumber',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.INSENSITIVE_EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    pattern: {
        name: 'pattern',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'sales:labels.orderFilters.pattern',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.EXACT,
        fieldType: FILTER_FIELD_TYPES.TEXT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    technology: {
        name: 'technology',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'sales:labels.orderFilters.technology',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.MULTI_SELECT_FIELD,
        icon: <AssignmentIcon size="small" />,
    },
    status: {
        name: 'status',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'sales:labels.orderFilters.status',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.AUTOCOMPLETE_FIELD,
        icon: <GearIcon size="small" />,
    },
    stabloStatus: {
        name: 'stabloStatus',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'sales:labels.orderFilters.stabloStatus',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.IN_VALUE_ARRAY,
        fieldType: FILTER_FIELD_TYPES.MULTI_SELECT_FIELD,
        icon: <GearIcon size="small" />,
    },
    'productionOrders.statusChangeAtBefore': {
        name: 'productionOrders.statusChangeAt',
        value: FILTER_DEFAULT_VALUES.DATE_RANGE_PICKER_FIELD,
        label: 'sales:labels.orderFilters.productionOrdersStatusChangeAtBefore',
        labelAlt: 'sales:labels.orderFilters.productionOrdersStatusChangeAt',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.DATE_RANGE,
        fieldType: FILTER_FIELD_TYPES.DATE_RANGE_PICKER_FIELD,
        icon: <CalendarIcon size="small" />,
        order: 3,
    },
    'productionOrders.statusChangeAtAfter': {
        name: 'productionOrders.statusChangeAt',
        value: FILTER_DEFAULT_VALUES.DATE_RANGE_PICKER_FIELD,
        label: 'sales:labels.orderFilters.productionOrdersStatusChangeAtAfter',
        labelAlt: 'sales:labels.orderFilters.productionOrdersStatusChangeAt',
        isModalFilter: true,
        matcher: FILTER_MATCHER_TYPES.DATE_RANGE,
        fieldType: FILTER_FIELD_TYPES.DATE_RANGE_PICKER_FIELD,
        icon: <CalendarIcon size="small" />,
        order: 2,
    },
};
