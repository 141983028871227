import snakeCase from 'lodash/snakeCase';
import moment from 'moment';

export const keysCamelToSnake = (obj = {}) =>
    Object.entries(obj).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [snakeCase(key)]: value,
        }),
        {},
    );

export const mapMomentDateToStringWithNulls = (
    value: moment.MomentInput[],
    format = 'YYYY-MM-DD[T]HH:mm:ssZ',
): string[] => value.map((item) => (item ? moment(item).format(format) : null));

export const mapMomentToDateString = (
    value: moment.MomentInput[],
    format = 'YYYY-MM-DD[T]HH:mm:ssZ',
): string[] =>
    mapMomentDateToStringWithNulls(value, format).filter((item) => !!item);
