import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';

import { NoteIcon } from 'components/Icons/Icons';

import ProductPossibilities from '../pages/ProductPossibilities/ProductPossibilities';
import ProductCatalogs from '../pages/ProductCatalogs/ProductCatalogs';

export const stabloRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            name: 'productPossibilities',
            path: PATHS.PRODUCT_POSSIBILITIES,
            component: ProductPossibilities,
        },
        {
            name: 'productCatalogs',
            path: PATHS.PRODUCT_CATALOG,
            component: ProductCatalogs,
        },
    ],
    icon: NoteIcon
};
