import { GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';

export const parseResponseToStateHelper = ({
    items,
    itemsTotal,
    totalItems,
    total,
    results,
}) => ({
    items: items || results || [],
    itemsTotal: itemsTotal || totalItems || total || 0,
});

export const parsePropsToFetchHelper = ({ page, pageSize, filters, sort = [] }) => ({
    page,
    per_page: pageSize,
    filters,
    sort: sort.reduce((sortObject, field) => {
        sortObject[field.field] = field.sort;

        return sortObject;
    }, {}),
});

export const parseGroupedSortParams = (sort = {}, groupParam) => {
    if (Object.keys(sort).length === 0) {
        return sort;
    }

    const [entry] = Object.entries(sort);
    const [key, value] = entry;

    return key === GRID_TREE_DATA_GROUPING_FIELD ? { [groupParam]: value } : sort;
};

export const mapIds = (objects = []) => objects.map((object) => object.id);
