import { keyBy, get } from 'lodash';

function createTree(array, rootNodes, customID, childrenProperty) {
    const tree = [];

    if (rootNodes) Object.keys(rootNodes).forEach((key) => {
        const node = rootNodes[key];
        const childNode = array[node[customID]];

        if (childNode) {
            node[childrenProperty] = createTree(
                array,
                childNode,
                customID,
                childrenProperty
            );
        }

        tree.push(node);
    });

    return tree;
}

function groupByParents(array, options) {
    const arrayByID = keyBy(array, options.customID);

    return array.reduce((prev, item) => {
        let parentID = get(item, options.parentProperty);

        if (!parentID || !Object.prototype.hasOwnProperty.call(arrayByID, parentID)) {
            parentID = options.rootID;
        }

        if (parentID && Object.prototype.hasOwnProperty.call(prev, parentID)) {
            prev[parentID].push(item);

            return prev;
        }
        prev[parentID] = [item];

        return prev;
    }, {});
}

function injectParentDataToItemParent(itemsArray) {
    const items = itemsArray;
    let itemsWithInjectedParent = [];

    items.forEach((item) => {
        if (Object.keys(item['parent']).length > 0) {
            const itemParentData = items.find((e) => e.id === item['parent'].id);

            let parentDataWithoutChildren;

            if (typeof itemParentData !== 'undefined') {
                const { children, ...rest } = itemParentData;

                parentDataWithoutChildren = rest;
            }

            const itemWithInjectedParent = item;

            itemsWithInjectedParent.push(itemWithInjectedParent);
            item['parent'] = parentDataWithoutChildren;
        } else {
            itemsWithInjectedParent.push(item);
        }
    });

    return itemsWithInjectedParent;
}

export default function arrayToTree(data, config = {}) {
    const options = {
        parentProperty: 'parent.id',
        childrenProperty: 'children',
        customID: 'id',
        rootID: '0',
        ...config,
    };

    if (!Array.isArray(data)) {
        throw new TypeError('Expected an array but got an invalid argument');
    }
    let itemsToGroup = data;

    if (options && options['injectDataToParent']) {
        itemsToGroup = injectParentDataToItemParent([ ...data ]);
    }
    const grouped = groupByParents([ ...itemsToGroup ], options);

    return createTree(
        grouped,
        grouped[options.rootID],
        options.customID,
        options.childrenProperty
    );
}
