import { URLS, DEFAULTS } from './constants';
import type { MediaFilesService } from '../mediaFiles/MediaFilesService';
import errorHandlers from './errorHandlers';
import parser from 'qs';
import { ProdisService } from './prodisService';
import {
    AcceptTextCsv,
    ApplicationJsonHeaders,
    HttpPatchHeaders,
} from '../constants';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const prodisServiceFactory = (
    mediaFilesService: MediaFilesService,
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    ProdisService.getInstance({
        services: {
            ajax: ajaxService,
            alert: alertService,
            parser,
            mediaFiles: mediaFilesService,
        },
        errorHandlers,
        constants: {
            URLS,
            DEFAULTS,
            HEADERS: {
                basic: ApplicationJsonHeaders,
                patch: HttpPatchHeaders,
                AcceptTextCsv,
            },
        },
    });
