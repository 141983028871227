import get from 'lodash/get';

class Carriers {
    constructor(ProdisService, services, URLS) {
        this.errorHandlers = ProdisService.errorHandlers;
        this.services = services;
        this.constants = ProdisService.constants;
        this.URLS = URLS;

        this.getList = this.getList.bind(this);
    }

    getList(params = {}) {
        const { DEFAULTS } = this.constants;
        const { page = 1, per_page = DEFAULTS.CARRIERS_PER_PAGE } = params;
        const { ajax } = this.services;
        const { GET_CARRIERS } = this.URLS;
        const config = {
            params: {
                page,
                itemsPerPage: per_page,
            },
        };

        return ajax
            .get({ url: GET_CARRIERS, config })
            .then((response) => {
                const items = get(response, 'data.hydra:types', []);
                const itemsTotal = get(response, 'data.hydra:totalItems', 0);

                return {
                    items: items,
                    itemsTotal,
                };
            })
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }
}

export default Carriers;
