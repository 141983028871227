import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function UsersIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 16 16"
            { ...props }
        >
            <path
                d="M6 0.75C5.40326 0.75 4.83097 0.987053 4.40901 1.40901C3.98705 1.83097 3.75 2.40326 3.75 3C3.75 3.59674 3.98705 4.16903 4.40901 4.59099C4.83097 5.01295 5.40326 5.25 6 5.25C6.59674 5.25 7.16903 5.01295 7.59099 4.59099C8.01295 4.16903 8.25 3.59674 8.25 3C8.25 2.40326 8.01295 1.83097 7.59099 1.40901C7.16903 0.987053 6.59674 0.75 6 0.75ZM12 0.75C11.4033 0.75 10.831 0.987053 10.409 1.40901C9.98705 1.83097 9.75 2.40326 9.75 3C9.75 3.59674 9.98705 4.16903 10.409 4.59099C10.831 5.01295 11.4033 5.25 12 5.25C12.5967 5.25 13.169 5.01295 13.591 4.59099C14.0129 4.16903 14.25 3.59674 14.25 3C14.25 2.40326 14.0129 1.83097 13.591 1.40901C13.169 0.987053 12.5967 0.75 12 0.75ZM2.82275 3.75146C2.49415 3.76409 1.77915 3.84878 1.31396 4.31396C0.693715 4.93421 0.751465 5.99854 0.751465 5.99854C0.751465 5.99854 1.81579 6.05629 2.43604 5.43604C3.05629 4.81578 2.99854 3.75146 2.99854 3.75146C2.99854 3.75146 2.93229 3.74726 2.82275 3.75146ZM6 6.75C3.75 6.75 0.75 7.848 0.75 9.375V10.5C0.75 10.914 1.086 11.25 1.5 11.25H10.5C10.914 11.25 11.25 10.914 11.25 10.5V9.375C11.25 7.848 8.25 6.75 6 6.75ZM12 6.75C11.7622 6.75 11.5151 6.76484 11.2661 6.78809C12.1526 7.43684 12.75 8.29425 12.75 9.375V10.5C12.75 10.914 13.086 11.25 13.5 11.25H16.5C16.914 11.25 17.25 10.914 17.25 10.5V9.375C17.25 7.848 14.25 6.75 12 6.75Z"
            />
        </SvgIcon>
    );
}

UsersIcon.defaultProps = {
    titleAccess: 'users-icon',
};

UsersIcon.propTypes = svgIconPropTypes;
