import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import i18next from 'i18next';
import countries from 'utils/countries';

export const NO_VALUE_PLACEHOLDER = '---';

export function formatDate(
    date,
    withTime = false,
    emptyStringOnInvalid = false,
    customFormat = undefined,
) {
    if (!date || !moment(new Date(date)).isValid()) {
        return emptyStringOnInvalid ? '' : NO_VALUE_PLACEHOLDER;
    } else {
        const format =
            customFormat ?? (withTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY');

        return moment(date).format(format);
    }
}

export const formatRange = (t, rangeArr) => {
    const valueFrom = rangeArr[0];
    const valueTo = rangeArr[1];

    if (!valueFrom && !valueTo) {
        return '';
    }

    if (!!valueFrom && !valueTo) {
        return `${t('common:glossary.from')} ${valueFrom}`;
    }

    if (!valueFrom && !!valueTo) {
        return `${t('common:glossary.to')} ${valueTo}`;
    }

    return `${valueFrom} - ${valueTo}`;
};

export const formatRangeDate = (t, dateRangeArr, withTime = false) => {
    const dateFrom = formatDate(dateRangeArr[0], withTime, true);
    const dateTo = formatDate(dateRangeArr[1], withTime, true);

    return formatRange(t, [dateFrom, dateTo]);
};

export function formatDateForFilename(date) {
    return moment(date).format('DD_MM_YYYY_HH_mm');
}

export function formatPrice(price, currency = 'PLN', { negative } = { negative: false }) {
    if (typeof price === 'undefined' || price === null || price === '') {
        return null;
    } else {
        return `${negative ? '(-) ' : ''}${parseFloat(price)
            .toFixed(2)
            .replace('.', ',')} ${currency}`;
    }
}

export function snakeToCamel(str) {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
}

export function getNullableValue(value) {
    return value || NO_VALUE_PLACEHOLDER;
}

export function getFileNameFromUrl(url) {
    return url?.substring(url?.lastIndexOf('/') + 1) || '';
}

export function getProductThumbnailUrl(patternCode, extension = 'jpg') {
    return (
        patternCode &&
        `${window.env.STOCK_CDN_URL}/thumbnails/by_pattern_code/${patternCode}.${extension}`
    );
}

export const getSentryFingerprint = ({ url: fullUrl, method, status }) => {
    const url = new URL(fullUrl)?.pathname;
    const segmentedUrl = url?.split('/');
    const lastSegment = segmentedUrl?.[segmentedUrl?.length - 1];
    const secondLastSegment = segmentedUrl?.[segmentedUrl?.length - 2];

    const hasUuidLastSegment = lastSegment?.length === 36;
    const hasUuidSecondLastSegment = secondLastSegment?.length === 36;
    const hasLongIdLastSegment = lastSegment?.length === 26;
    const hasLongIdSecondLastSegment = secondLastSegment?.length === 26;
    const hasNumberIdLastSegment = !!+lastSegment;
    const hasNumberIdSecondLastSegment = !!+secondLastSegment;

    const isParametrizedLastSegment =
        hasUuidLastSegment || hasLongIdLastSegment || hasNumberIdLastSegment;
    const isParametrizedSecondLastSegment =
        hasUuidSecondLastSegment ||
        hasLongIdSecondLastSegment ||
        hasNumberIdSecondLastSegment;

    if (
        status &&
        (isParametrizedLastSegment || isParametrizedSecondLastSegment)
    ) {
        const trimmedUrl = segmentedUrl
            ?.slice(0, isParametrizedSecondLastSegment ? -2 : -1)
            ?.join('/');

        return [method, status, trimmedUrl];
    }

    return status ? [method, status, url] : [method, url];
};

export const getTaxNumberLabel = ({
    taxNumberCountry,
    taxNumber,
    withBrackets,
}) => {
    if (!taxNumberCountry || !taxNumber) {
        return '';
    }

    return withBrackets
        ? `[${taxNumberCountry}${taxNumber}]`
        : `${taxNumberCountry}${taxNumber}`;
};

export const getUserName = ({ firstName, lastName }) => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName && !lastName) {
        return `${firstName}`;
    } else if (!firstName && lastName) {
        return `${lastName}`;
    } else {
        return NO_VALUE_PLACEHOLDER;
    }
};

export const getSizeString = ({ width, height, unit = 'mm' }) => {
    // input always in milimeters
    let parsedWidth = width;
    let parsedHeight = height;

    if (!width || !height) {
        return NO_VALUE_PLACEHOLDER;
    }

    if (unit === 'cm') {
        parsedWidth = width / 10;
        parsedHeight = height / 10;
    }

    if (unit === 'm') {
        parsedWidth = Number(width / 1000).toFixed(2);
        parsedHeight = Number(height / 1000).toFixed(2);
    }

    return `${parsedWidth} ${unit} x ${parsedHeight} ${unit}`;
};

export const getDimensionsString = ({ width, height, depth, unit = 'mm' }) => {
    // input always in milimeters
    let parsedWidth = width;
    let parsedHeight = height;
    let parsedDepth = depth;

    if (unit === 'cm') {
        parsedWidth = width / 10;
        parsedHeight = height / 10;
        parsedDepth = depth / 10;
    }

    if (unit === 'm') {
        parsedWidth = Number(width / 1000).toFixed(2);
        parsedHeight = Number(height / 1000).toFixed(2);
        parsedDepth = Number(depth / 1000).toFixed(2);
    }

    if (isUndefined(width) || width === 0) {
        parsedWidth = 0;
    }

    if (isUndefined(height) || height === 0) {
        parsedHeight = 0;
    }

    if (isUndefined(depth) || depth === 0) {
        parsedDepth = 0;
    }

    return `${parsedWidth} x ${parsedHeight} x ${parsedDepth} ${unit}`;
};

export const trimPriceInput = ({ price }) => {
    let newPrice = typeof price === 'number' ? price.toString() : price || '';

    const trimZero = newPrice.startsWith('0') && newPrice.length === 2;

    if (trimZero) {
        newPrice = newPrice.substring(1);
    }

    const hasDecimals = newPrice.includes('.');

    if (hasDecimals) {
        const decimalDigitsCount = newPrice.split('.')[1].length;
        const trimDecimalDigits = decimalDigitsCount > 2;

        if (trimDecimalDigits) {
            newPrice = newPrice.substring(0, newPrice.length - 1);
        }
    }

    return newPrice;
};

export const getFileIdFromUploadURL = (uploadURL) => {
    const urlStaged = uploadURL.split('/');

    return urlStaged[urlStaged.length - 1];
};

export const trimText = (text) => text?.trim() ?? '';

export const formatSizeValuesArray = (
    value,
    { separator = ';', unit = 'cm' } = {},
) => {
    const segmentedValue = value?.split(separator) ?? [];
    const parsedSegmentedValue = segmentedValue.map((val) =>
        val.endsWith('.') ? val.slice(0, -1) : val,
    );

    return parsedSegmentedValue
        .reduce((acc, cur) => {
            const valueMerged = acc + cur;
            const unitWithDisplayedSeparator = cur && ` ${unit}, `;

            return `${valueMerged}${unitWithDisplayedSeparator}`;
        }, '')
        .slice(0, -2);
};

export const formatPartsCountValuesArray = (value, separator = ';') => {
    const segmentedValue = value?.split(separator) ?? [];

    return segmentedValue
        .reduce((acc, cur) => {
            const valueMerged = acc + cur;
            const displayedSeparator = cur && ', ';

            return `${valueMerged}${displayedSeparator}`;
        }, '')
        .slice(0, -2);
};

export const formatAuthorName = (value) => {
    const { fullName, companyName } = value;
    const label = companyName ? `${fullName} [${companyName}]` : fullName;

    if (label) return label;

    // For backwards compatibility. Remove when all authors are mapped.
    const { first_name, last_name, company_name, id } = value;
    const oldLabel = [first_name, last_name].join(' ');

    if (company_name) return `${oldLabel} [${company_name}]`;
    if (oldLabel) return oldLabel;

    return id;
};

export const formatCountryLabel = (countryCode) => {
    const hasCountryCodeTranslation = countries.includes(countryCode);

    if (countryCode && hasCountryCodeTranslation) {
        const countryTranslated = i18next.t(
            `libraries:countries.${countryCode}`,
        );

        return `${countryCode}: ${countryTranslated}`;
    }

    if (countryCode && !hasCountryCodeTranslation) {
        return countryCode;
    }

    return '';
};

export const formatContractorLabel = (contractor = {}) => {
    if (contractor?.name && contractor?.nip) {
        return `${contractor.name} [${contractor.nip}]`;
    }

    if (contractor?.name && !contractor?.nip) {
        return contractor.name;
    }

    return '';
};

export const cmToMm = (value = 0) => value * 10;

export const mmToCm = (value = 0) => value / 10;

export const removeAllWhiteSpaces = (value) => value.replaceAll(/\s/g, '');
