import React from 'react';
import { svgIconPropTypes } from 'utils/propTypes';
import { SvgIcon } from './SvgIconBase';

export function SortDataGridPro(props) {
    const { titleAccess = 'arrow-up-sort-icon', fontSize, className } = props;

    return (
        <SvgIcon
            viewBox="0 0 24 24"
            titleAccess={ titleAccess }
            fontSize={ fontSize }
            className={ className }
        >
            <path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
        </SvgIcon>
    );
}

SortDataGridPro.propTypes = svgIconPropTypes;
