import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ChevronUpIcon(props) {
    return (
        <SvgIcon
            viewBox="-3.5 -6 16 16"
            { ...props }
        >
            <path
                d="M8.51131 4.90576L9 4.40621L4.49864 -0.000120246L-4.36718e-08 4.40621L0.483257 4.90576L4.49864 0.971825L8.51131 4.90576Z"
            />
        </SvgIcon>
    );
}

ChevronUpIcon.defaultProps = {
    titleAccess: 'chevron-up-icon',
};

ChevronUpIcon.propTypes = svgIconPropTypes;
