import { select, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as C from './constants';
import * as A from './actions';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import qs from 'qs';
import { getSearch } from 'utils/router/selectors';
import { SaleResponse } from '../../models/saleResponse';

const SIMP_URL = get(window, 'env.SIMP_API_URL', '');
const orders = makeEndPoint(SIMP_URL + '/api/orders', SaleResponse);

export default function* saleSaga() {
    yield takeLatest(C.FETCH_ORDERS.request, getOrders);
}

function* getOrders() {
    const urlParams = qs.parse(yield select(getSearch));
    const params = SaleResponse.getListRequestParams({ urlParams });

    yield orders.get({
        action: A.fetchOrders,
        params: params,
        alerts: {
            failure: true,
        },
    });
}
