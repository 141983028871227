import type { Store } from 'redux';

export class StoreService {
    private constructor(private readonly store: Store) {
        this.dispatch = this.dispatch.bind(this);
        this.get = this.get.bind(this);
        this.select = this.select.bind(this);
    }

    private static instance: StoreService | undefined;

    public static getInstance(store: Store): StoreService {
        if (!StoreService.instance) {
            StoreService.instance = new StoreService(store);
        }

        return StoreService.instance;
    }

    dispatch(action) {
        this.store.dispatch(action);
    }
    get(path) {
        return this.store.getState().getIn(path);
    }
    select(selector, path) {
        return selector(this.store.getState(), path);
    }
}
