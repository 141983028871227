import { ApplicationJsonHeaders } from '../../constants';

export class Workplaces {
    constructor(services, storage, { URLS, WORKPLACE_MODAL_ROLE, STORAGE_CURRENT_WORKPLACE }) {
        this.services = services;
        this.storage = storage;

        this.URLS = URLS;
        this.WORKPLACE_MODAL_ROLE = WORKPLACE_MODAL_ROLE;
        this.STORAGE_CURRENT_WORKPLACE = STORAGE_CURRENT_WORKPLACE;

        this.doShowWorkplaceModal = this.doShowWorkplaceModal.bind(this);
        this.removeLocalWorkplace = this.removeLocalWorkplace.bind(this);
        this.patchWorkplace = this.patchWorkplace.bind(this);
    }

    doShowWorkplaceModal () {
        const {
            STORAGE_CURRENT_WORKPLACE,
            services: {
                authentication: {
                    getActiveToken,
                }
            },
            storage,
        } = this;

        return getActiveToken()?.roles?.includes(this.WORKPLACE_MODAL_ROLE) && !storage.getItem(STORAGE_CURRENT_WORKPLACE);
    }

    #_setLocalWorkplace(workplaceId) {
        const {
            STORAGE_CURRENT_WORKPLACE,
            storage,
        } = this;

        storage.setItem(STORAGE_CURRENT_WORKPLACE, workplaceId);
    }

    removeLocalWorkplace() {
        const {
            STORAGE_CURRENT_WORKPLACE,
            storage,
        } = this;

        storage.removeItem(STORAGE_CURRENT_WORKPLACE);
    }

    /**
     * Patch current workplace
     */
    async patchWorkplace(workplaceId) {
        const {
            URLS: {
                PATCH_USER_WORKPLACE,
            },
            services: {
                ajax,
                authentication,
            }
        } = this;

        const { data } = await ajax.patch({
            url: PATCH_USER_WORKPLACE,
            config: { headers: ApplicationJsonHeaders },
            data: {
                workplace: {
                    id: workplaceId
                }
            },
            errorConfig: { addGenericAlert: false },

        });

        this.#_setLocalWorkplace(workplaceId);

        authentication.setActiveToken(data.token);
        authentication.setRefreshToken(data.refresh_token);

        return data;
    }
}
