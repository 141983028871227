import SkuGenerator from 'services/sku/actions/SkuGenerator';

export class SkuService {
    private constructor({ services, constants, errorHandlers }) {
        this.ajax = services.ajax;
        this.errorHandlers = errorHandlers;

        this.URLS = constants.URLS;
        this.DEFAULTS = constants.DEFAULTS;

        this.skuGenerator = new SkuGenerator(this);
    }

    private static instance: SkuService | undefined;

    public static getInstance({
        services,
        constants,
        errorHandlers,
    }): SkuService {
        if (!SkuService.instance) {
            SkuService.instance = new SkuService({
                services,
                constants,
                errorHandlers,
            });
        }

        return SkuService.instance;
    }
}
