export const makeAction = (constant, preprocessor) => {
    return (rawData) => {
        const data = (
            preprocessor ?
                preprocessor(rawData)
                :
                rawData
        ) || {};

        return {
            type: constant,
            payload: data,
        };
    };
};

export const makeRequestActions = (constants, preprocessor) => {
    const actions = {};

    Object.keys(constants).forEach((constant) => {
        actions[constant] = makeAction(constants[constant], preprocessor);
    });

    return actions;
};
