import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function Chat2Icon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 16 16"
            { ...props }
        >
            <path
                d="M1.73913 0C0.783967 0 0 0.751302 0 1.66667V9.66667C0 10.582 0.783967 11.3333 1.73913 11.3333H3.16304C3.2106 11.7344 3.20516 12.1055 3.09783 12.4167C2.96603 12.7995 2.72011 13.1211 2.26087 13.3854C2.13179 13.4635 2.07201 13.6133 2.11141 13.7539C2.15082 13.8945 2.28261 13.9948 2.43478 14C3.91033 14 5.6019 13.2617 6.45652 11.3333H14.2609C15.216 11.3333 16 10.582 16 9.66667V1.66667C16 0.751302 15.216 0 14.2609 0H1.73913ZM1.73913 0.666667H14.2609C14.837 0.666667 15.3043 1.11458 15.3043 1.66667V9.66667C15.3043 10.2188 14.837 10.6667 14.2609 10.6667H6.26087C6.11549 10.6667 5.98505 10.7539 5.93478 10.8854C5.39402 12.2995 4.42799 12.9727 3.42391 13.2188C3.55978 13.0326 3.68886 12.8359 3.76087 12.625C3.94158 12.099 3.92663 11.5234 3.81522 10.9375C3.78397 10.7826 3.64402 10.6693 3.47826 10.6667H1.73913C1.16304 10.6667 0.695652 10.2188 0.695652 9.66667V1.66667C0.695652 1.11458 1.16304 0.666667 1.73913 0.666667Z"
            />
        </SvgIcon>
    );
}

Chat2Icon.defaultProps = {
    titleAccess: 'chat-2-icon',
};

Chat2Icon.propTypes = svgIconPropTypes;
