import get from 'lodash/get';

const DISCOUNTS_URL = get(window, 'env.DISCOUNTS_API_URL', '');

const TECHNOLOGY_DISCOUNTS_URLS = {
    GET_DISCOUNTS: `${DISCOUNTS_URL}/api/v2/technology_discounts`,
    POST_DISCOUNT: `${DISCOUNTS_URL}/api/v2/technology_discounts`,
    DEACTIVATE_DISCOUNT: `${DISCOUNTS_URL}/api/v2/technology_discounts/{id}/deactivate`,
};

const PRODUCT_SET_DISCOUNTS_URLS = {
    getProductSetDiscounts: `${DISCOUNTS_URL}/api/product_set_discounts`,
    postCreateProductSetDiscounts: `${DISCOUNTS_URL}/api/product_set_discounts`,
    patchProductSetDiscountsDeactivate: `${DISCOUNTS_URL}/api/product_set_discounts/{id}/deactivate`,
};

export const URLS = {
    ...TECHNOLOGY_DISCOUNTS_URLS,
    ...PRODUCT_SET_DISCOUNTS_URLS,
} as const;
