import {
    mapOfferPriceListsFromApi,
    mapPriceListIntermediateProductFromApi,
    mapPriceIntermediateProductToApi,
    mapPriceListProductSetFromApi,
    mapPriceProductSetToApi,
} from '../../mappers/offerPriceLists';

import {
    ApplicationJsonHeaders,
    HeadersAcceptType,
    HttpPatchHeaders,
} from '../../../constants';
import { configError } from 'services/utils/utils';
import type { SalesChannelsService } from '../../SalesChannelsService';
import type { DeepPartial } from 'utils/types/deepPartial';
import type {
    CreatePriceListDto,
    ListResponse,
    PriceListIntermediateProductOutputDto,
    PriceListIntermediateProductsParams,
    PriceListItemOutputDto,
    PriceListProductSetOutputDto,
    PriceListProductSetsParams,
    UpdatePriceListIntermediateProductsDto,
    UpdatePriceListProductSetsDto,
} from './offer-price-lists.type';
import type { PriceImportExportType } from 'modules/manageOffers/config/priceType.config';

export class OfferPriceLists {
    private readonly params:
        | SalesChannelsService['OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS']
        | {};
    private readonly errorHandlers: SalesChannelsService['errorHandlers'];

    private constructor(
        private readonly salesChannelsService: SalesChannelsService,
    ) {
        this.params =
            salesChannelsService.OFFER_PRICING_INTERMEDIATE_PRODUCTS_PARAMS ||
            {};
        this.errorHandlers = salesChannelsService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.create = this.create.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.uploadFromCsv = this.uploadFromCsv.bind(this);
        this.getIntermediateProductsListByPriceListId =
            this.getIntermediateProductsListByPriceListId.bind(this);
        this.editIntermediateProductsListByPriceListId =
            this.editIntermediateProductsListByPriceListId.bind(this);
        this.getProductSetsListByPriceListId =
            this.getProductSetsListByPriceListId.bind(this);
        this.editProductSetsListByPriceListId =
            this.editProductSetsListByPriceListId.bind(this);
    }

    private static instance: OfferPriceLists | undefined;

    public static getInstance(
        salesChannelsService: SalesChannelsService,
    ): OfferPriceLists {
        if (!OfferPriceLists.instance) {
            OfferPriceLists.instance = new OfferPriceLists(
                salesChannelsService,
            );
        }

        return OfferPriceLists.instance;
    }

    private configError(
        requestType: keyof typeof this.errorHandlers,
        config = {},
    ) {
        return {
            throwError: this.errorHandlers[requestType],
            addGenericAlert: false,
            ...config,
        };
    }

    async getIntermediateProductsListByPriceListId({
        id,
        page,
        pageSize,
        filters,
    }: {
        id: string;
        page: number;
        pageSize: number;
        filters: DeepPartial<PriceListIntermediateProductsParams>;
    }): Promise<{
        itemsTotal: ListResponse<PriceListIntermediateProductOutputDto>['total'];
        items: ReturnType<typeof mapPriceListIntermediateProductFromApi>[];
    }> {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_PRICE_LIST_INTERMEDIATE_PRODUCTS.replace(
            '{id}',
            id,
        );
        const config = {
            headers: HttpPatchHeaders,
            params: {
                page,
                itemsPerPage: pageSize,
                ...filters,
            },
        };
        const errorConfig = this.configError('get');

        const response: {
            data: ListResponse<PriceListIntermediateProductOutputDto>;
        } = await ajax.get({
            url,
            config,
            errorConfig,
            onError: undefined,
        });

        return {
            items: response.data.results?.map(
                mapPriceListIntermediateProductFromApi,
            ),
            itemsTotal: response.data.total,
        };
    }

    async editIntermediateProductsListByPriceListId({
        id,
        intermediateProducts,
    }: {
        id: string;
        intermediateProducts: Parameters<
            typeof mapPriceIntermediateProductToApi
        >[0][];
    }): Promise<void> {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.PATCH_PRICE_LIST_INTERMEDIATE_PRODUCTS.replace(
            '{id}',
            id,
        );

        const data = {
            price_list_intermediate_products: intermediateProducts.map(
                mapPriceIntermediateProductToApi,
            ),
        } satisfies UpdatePriceListIntermediateProductsDto;

        const config = {
            headers: HttpPatchHeaders,
            data,
        };
        const errorConfig = this.configError('patch');

        await ajax.patch({
            url,
            config,
            errorConfig,
            data: config.data,
            onError: undefined,
        });
    }

    async getProductSetsListByPriceListId({
        id,
        page,
        pageSize,
        filters,
    }: {
        id: string;
        page: number;
        pageSize: number;
        filters: DeepPartial<PriceListProductSetsParams>;
    }): Promise<{
        itemsTotal: ListResponse<PriceListProductSetOutputDto>['total'];
        items: ReturnType<typeof mapPriceListProductSetFromApi>[];
    }> {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_PRICE_LIST_PRODUCT_SETS.replace('{id}', id);
        const config = {
            headers: HttpPatchHeaders,
            params: {
                page,
                itemsPerPage: pageSize,
                ...filters,
            },
        };
        const errorConfig = this.configError('get');

        const response: {
            data: ListResponse<PriceListProductSetOutputDto>;
        } = await ajax.get({
            url,
            config,
            errorConfig,
            onError: undefined,
        });

        return {
            items: response.data.results?.map(mapPriceListProductSetFromApi),
            itemsTotal: response.data.total,
        };
    }

    async editProductSetsListByPriceListId({
        id,
        productSets,
    }: {
        id: string;
        productSets: Parameters<typeof mapPriceProductSetToApi>[0][];
    }): Promise<void> {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.PATCH_PRICE_LIST_PRODUCT_SETS.replace('{id}', id);

        const data = {
            price_list_product_sets: productSets.map(mapPriceProductSetToApi),
        } satisfies UpdatePriceListProductSetsDto;

        const config = {
            headers: HttpPatchHeaders,
            data,
        };
        const errorConfig = this.configError('patch');

        await ajax.patch({
            url,
            config,
            errorConfig,
            data: config.data,
            onError: undefined,
        });
    }

    async getList({ id }: { id: string }) {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_OFFER_PRICE_LISTS.replace('{id}', id);
        const errorConfig = this.configError('get');

        const response: { data: PriceListItemOutputDto[] } = await ajax.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig,
            onError: undefined,
        });

        return {
            items: response.data.map((item) => mapOfferPriceListsFromApi(item)),
            totalItems: response.data.length,
        };
    }

    async create(formData: CreatePriceListDto) {
        const {
            salesChannelsService: { ajax, URLS, errorHandlers },
        } = this;
        const url = URLS.POST_PRICE_LIST;
        const errorConfig = configError('post', errorHandlers);
        const config = {
            data: formData,
        };

        return await ajax.post({
            url,
            config,
            errorConfig,
            onError: undefined,
            data: config.data,
        });
    }

    async exportToCsv({
        id,
        fileName,
        type,
    }: {
        id: string;
        fileName: string;
        type: string;
    }): Promise<void> {
        const { ajax, URLS } = this.salesChannelsService;
        const url = URLS.GET_EXPORT_PRICE_LIST.replace('{id}', id);
        const errorConfig = this.configError('get');
        const config = {
            params: { type },
            headers: {
                'Content-Type': HeadersAcceptType.csv,
                Accept: HeadersAcceptType.csv,
            },
        };

        await ajax.download({
            url,
            config,
            errorConfig,
            fileName,
            onError: undefined,
        });
    }

    async uploadFromCsv({
        file,
        type,
        priceListId,
    }: {
        priceListId: string;
        type: PriceImportExportType;
        file: string | Blob | File;
    }): Promise<{ job_id: string }> {
        const {
            salesChannelsService: {
                ajax,
                URLS,
                translator,
                alert,
                errorHandlers,
                mediaFiles: { uploadCustomFiles },
            },
        } = this;

        const { id: uploadedFileId } = await uploadCustomFiles(file);

        const uploadData = {
            media_file: {
                id: `custom-files/${uploadedFileId}`,
            },
            type,
            separator: ',',
            enclosure: '"',
        } satisfies {
            media_file: {
                id: string;
            };
            separator: string;
            enclosure: string;
            type?: PriceImportExportType | null;
            note?: string | null;
        };

        const response: { data: { job_id: string } } = await ajax.post({
            url: URLS.POST_IMPORT_PRICE_LIST.replace('{id}', priceListId),
            errorConfig: configError('post', errorHandlers),
            config: {
                headers: ApplicationJsonHeaders,
            },
            data: uploadData,
            onError: undefined,
        });

        alert.addSuccess({
            message: translator.t(
                'manageOffers:alerts.fileUploadedSuccessfully',
            ),
        });

        return response.data;
    }
}

export type PriceListFromAPI = Awaited<
    ReturnType<OfferPriceLists['getList']>
>['items'][number];

export type PriceListIntermediateProductFromAPI = Awaited<
    ReturnType<OfferPriceLists['getIntermediateProductsListByPriceListId']>
>['items'][number];

export type PriceListProductSetFromAPI = Awaited<
    ReturnType<OfferPriceLists['getProductSetsListByPriceListId']>
>['items'][number];
