import * as PATHS from './paths';
import { MODULE_NAME } from '../constants';
import { lazy } from 'utils/codeSplitting';

import { UsersIcon } from 'components/Icons/Icons';

const Users = lazy(() => import('../pages/Users/UsersPage'));
const UserDetails = lazy(() => import('../pages/UserDetails/UserDetails'));
const Tos = lazy(() => import('../pages/Tos/TosPage'));
const TosDetails = lazy(() => import('../pages/TosDetails/TosDetails'));
const Positions = lazy(() => import('../pages/Positions/PositionPage'));
const PositionDetails = lazy(() => import('../pages/PositionDetails/PositionDetails'));
const RoleGroups = lazy(() => import('../pages/RoleGroups/RoleGroups'));
const RoleGroupDetails = lazy(() => import('../pages/RoleGroupDetails/RoleGroupDetails'));
const Workplaces = lazy(() => import('../pages/Workplaces/Workplaces'));
const WorkplacesDetails = lazy(() =>
    import('../pages/WorkplacesDetails/WorkplacesDetails'),
);

export const pdpRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            name: 'users',
            exact: true,
            path: PATHS.USERS,
            component: Users,
        },
        {
            name: null,
            exact: true,
            path: PATHS.USERS_DETAILS,
            component: UserDetails,
        },
        {
            name: 'tos',
            exact: true,
            path: PATHS.TOS,
            component: Tos,
        },
        {
            name: null,
            exact: true,
            path: PATHS.TOS_DETAILS,
            component: TosDetails,
        },
        {
            name: 'positions',
            exact: true,
            path: PATHS.POSITIONS,
            component: Positions,
        },
        {
            name: null,
            exact: true,
            path: PATHS.POSITION_DETAILS,
            component: PositionDetails,
        },
        {
            name: 'roleGroups',
            exact: true,
            path: PATHS.ROLE_GROUPS,
            component: RoleGroups,
        },
        {
            name: null,
            exact: true,
            path: PATHS.ROLE_GROUP_DETAILS,
            component: RoleGroupDetails,
        },
        {
            name: 'workplaces',
            exact: true,
            path: PATHS.WORKPLACES,
            component: Workplaces,
        },
        {
            name: null,
            exact: true,
            path: PATHS.WORKPLACES_DETAILS,
            component: WorkplacesDetails,
        },
    ],
    icon: UsersIcon,
};
