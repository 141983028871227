import { errorHandlers as errorHandlersHelper } from '../../errorHandlers';
import type { AjaxService } from '../../../ajax/AjaxService';
import { URLS } from '../../constants';
import { ApplicationJsonHeaders } from '../../../constants';
import type { PlaceDto, PlacesResponse } from './place.type';

export class PlaceActions {
    private static instance: PlaceActions | undefined;

    private constructor(private readonly ajaxService: AjaxService, private readonly errorHandlers: ReturnType<typeof errorHandlersHelper>) {
        this.getList = this.getList.bind(this);
    }

    static getInstance(ajaxService: AjaxService, errorHandlers: ReturnType<typeof errorHandlersHelper>): PlaceActions {
        if (!PlaceActions.instance) {
            PlaceActions.instance = new PlaceActions(ajaxService, errorHandlers);
        }

        return PlaceActions.instance;
    }

    async getList(): Promise<PlaceDto[]> {
        const response: { data: PlacesResponse } = await this.ajaxService.get({
            url: URLS.PLACES,
            config: {
                headers: ApplicationJsonHeaders,
            },
            errorConfig: {
                onError: this.errorHandlers.get,
            },
            onError: this.errorHandlers.get,
        });

        return response.data.places;
    }
}
