import arrayToTree from 'utils/arrayToTree';
import { configError, paramsSerializer } from 'services/utils/utils';

import { mapCategoryFromApi } from '../mappers/category';
import { StockResponse } from 'modules/stock/models/stockResponse';

import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';
import type { StockService } from '../StockService';

class Categories {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly DEFAULTS: IStockServiceConst['DEFAULTS'];
    private readonly HEADERS: IStockServiceConst['HEADERS'];
    private readonly APP_ALERTS: IStockServiceConst['APP_ALERTS'];
    private readonly errorHandlers: StockService['errorHandlers'];
    private getListPromise: unknown;
    private requestPending: boolean = false;
    private fullList: unknown;

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = stockService.URLS;
        this.DEFAULTS = stockService.DEFAULTS;
        this.HEADERS = stockService.HEADERS;
        this.APP_ALERTS = stockService.APP_ALERTS;
        this.errorHandlers = stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getListAutocomplete = this.getListAutocomplete.bind(this);
        this.getById = this.getById.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    async getById(id) {
        const { ajax } = this.services;
        const { URLS, errorHandlers } = this;

        const url = URLS.GET_CATEGORY.replace('{id}', id);
        const errorConfig = configError('get', errorHandlers);

        const response = await ajax.get({ url, errorConfig });

        return response.data;
    }

    async edit(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const { id, ...formData } = data;

        const url = URLS.PATCH_CATEGORY.replace('{id}', id);
        const config = { headers: HEADERS.patch };
        const errorConfig = configError('patch', errorHandlers);

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: formData,
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.EDIT_CATEGORY_SUCCESS),
        });

        return response.data;
    }

    async create(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const url = URLS.POST_CATEGORY;
        const config = { headers: HEADERS.basic };
        const errorConfig = configError('post', errorHandlers);

        const response = await ajax.post({ url, config, errorConfig, data });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.CREATE_CATEGORY_SUCCESS),
        });

        return response.data;
    }

    async _getMore(page) {
        const { ajax } = this.services;
        const { URLS, HEADERS, DEFAULTS, errorHandlers } = this;

        const url = URLS.GET_CATEGORIES;
        const params = {
            page,
            per_page: DEFAULTS.CATEGORIES_LIST_PER_PAGE,
        };
        const config = {
            params,
            headers: HEADERS.basic,
        };
        const onError = errorHandlers.get;

        const response = await ajax.get({ url, config, onError });

        return (response?.data?.results ?? []).map(mapCategoryFromApi);
    }

    _getFullList() {
        return arrayToTree(this.fullList);
    }

    async getListAutocomplete(search) {
        const { ajax } = this.services;
        const { URLS, HEADERS, DEFAULTS, errorHandlers } = this;

        const params = StockResponse.getListRequestParams({
            defaults: {
                page: 1,
                per_page: DEFAULTS.CATEGORIES_AUTOCOMPLETE_PER_PAGE,
                name: search,
            },
        });

        const url = URLS.GET_CATEGORIES;
        const onError = errorHandlers.get;
        const config = {
            params,
            headers: HEADERS.basic,
            paramsSerializer: paramsSerializer,
        };

        const response = await ajax.get({ url, config, onError });

        return (response?.data?.results ?? []).map(mapCategoryFromApi);
    }

    getList() {
        const { ajax } = this.services;
        const { URLS, HEADERS, DEFAULTS, errorHandlers } = this;

        const url = URLS.GET_CATEGORIES;
        const params = {
            page: 1,
            per_page: DEFAULTS.CATEGORIES_LIST_PER_PAGE,
        };
        const config = {
            params,
            headers: HEADERS.basic,
        };
        const onError = errorHandlers.get;

        if (this.requestPending) {
            return this.getListPromise;
        }

        this.requestPending = true;
        this.getListPromise = ajax
            .get({ url, config, onError })
            .then((response) => {
                const categories = (response?.data?.results ?? []).map(
                    mapCategoryFromApi,
                );
                const totalPages = response?.data?.total_pages ?? 0;

                if (totalPages > 1) {
                    const requests = [];

                    for (let i = 2; i <= totalPages; i++) {
                        requests.push(this._getMore(i));
                    }

                    return Promise.all(requests).then((responses) => {
                        this.fullList = [...categories, ...responses.flat()];
                        this.requestPending = false;

                        return this._getFullList();
                    });
                } else {
                    this.fullList = categories;
                    this.requestPending = false;

                    return this._getFullList();
                }
            });

        return this.getListPromise;
    }
}

export default Categories;
