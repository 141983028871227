import { applicationJson } from '../../constants';
import { IArmApiServiceServices, IArmApiServiceConst } from '../armApi.type';

export class PrintSpecFile {
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.services = services;
        this.URLS = URLS;

        this.deletePrintSpecFile = this.deletePrintSpecFile.bind(this);
        this.downloadPrintSpecFile = this.downloadPrintSpecFile.bind(this);
    }

    async deletePrintSpecFile(id) {
        const { ajax } = this.services;

        const url = this.URLS.DELETE_ARM_PRINT_SPEC_FILES.replace('{id}', id);

        return ajax.delete({ url }).then((response) => response);
    }

    async downloadPrintSpecFile(id, fileName) {
        const { ajax } = this.services;

        const url = this.URLS.DOWNLOAD_PRINT_SPEC_FILES.replace('{id}', id);

        const config = {
            headers: {
                Accept: applicationJson,
            },
        };

        return ajax.download({ url, fileName, config });
    }
}
