import { MODULE_NAME } from '../constants';
import { makeRequestConstant } from 'utils/redux/makeConstants';

export const LOGOUT_USER = MODULE_NAME + '_LOGOUT_USER';
export const SET_LOGGED_USER = MODULE_NAME + '_SET_LOGGED_USER';
export const HIDE_WORKPLACE_MODAL = MODULE_NAME + '_HIDE_WORKPLACE_MODAL';

export const LOGIN_USER = makeRequestConstant(MODULE_NAME + '_LOGIN_USER');
export const RESET_TOKEN = makeRequestConstant(MODULE_NAME + '_RESET_TOKEN');
export const REQUEST_CHANGE_PASSWORD = makeRequestConstant(MODULE_NAME + '_REQUEST_CHANGE_PASSWORD');
export const CHANGE_PASSWORD = makeRequestConstant(MODULE_NAME + '_CHANGE_PASSWORD');
