import get from 'lodash/get';
import moment from 'moment';

export const mapPositionFromApi = (data = {}) => ({
    ['@id']: get(data, '@id', ''),
    id: get(data, 'id', ''),
    title: get(data, 'title', ''),
    description: get(data, 'description', ''),
    positionCreate: data?.createdBy?.username || '---',
    parent: data?.parent?.title || '---',
    createdAt: moment(get(data, 'createdAt', '')).format('DD/MM/YYYY'),
});
