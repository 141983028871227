import type { AlertService } from '../alert/AlertService';

type AjaxServiceError = any;

export class ErrorHandler {
    constructor(private readonly alertService: AlertService) {
        this.handleGet = this.handleGet.bind(this);
    }

    handleGet(error: AjaxServiceError): never {
        const { response = {} } = error;
        const { data = {}, status } = response;
        const alert = {
            message: 'An unknown error has occurred',
        };

        if (status === 500 && data.detail) {
            alert.message = data.detail;
        }
        this.alertService.addError(alert);
        throw error;
    }
}
