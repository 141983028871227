import ProductionOrders from './actions/ProductionOrders';

export class StabloService {
    private constructor({ services, errorHandlersConfig, URLS, CONSTANTS }) {
        this.ajaxService = services.ajax;
        this.parser = services.parser;
        this.errorHandlers = errorHandlersConfig(services.alert);
        this.constants = CONSTANTS;
        this.URLS = URLS;

        this.productionOrders = new ProductionOrders(this, CONSTANTS);

        this.paramsSerializer = this.paramsSerializer.bind(this);
    }

    private static instance: StabloService | undefined;

    public static getInstance({
        services,
        errorHandlersConfig,
        URLS,
        CONSTANTS,
    }): StabloService {
        if (!StabloService.instance) {
            StabloService.instance = new StabloService({
                services,
                errorHandlersConfig,
                URLS,
                CONSTANTS,
            });
        }

        return StabloService.instance;
    }

    paramsSerializer = (params) => this.parser.stringify(params);
}
