import type { AjaxService } from 'services/ajax/AjaxService';

import { URLS } from './constants';
import errorHandlers from './errorHandlers';

import { MediaFilesService } from './MediaFilesService';
import type { AlertService } from 'services/alert/AlertService';

export const mediaFilesServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    MediaFilesService.getInstance({
        URLS,
        errorHandlers: errorHandlers(alert),
        services: {
            ajax: ajaxService,
            alert: alertService,
        },
    });
