import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function PhotoIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -1 16 16"
            { ...props }
        >
            <path
                d="M7.8 0.280029C7.19125 0.280029 6.62375 0.732529 6.38 1.36003L6.18 1.88003H4.48V1.56003C4.48 1.38378 4.33625 1.24003 4.16 1.24003H1.92C1.74375 1.24003 1.6 1.38378 1.6 1.56003V2.01003C0.68375 2.29128 0 3.11503 0 4.12003V10.84C0 12.0738 1.00625 13.08 2.24 13.08H13.76C14.9937 13.08 16 12.0738 16 10.84V4.12003C16 2.88628 14.9937 1.88003 13.76 1.88003H13.02L12.82 1.36003C12.5763 0.732529 12.01 0.280029 11.4 0.280029H7.8ZM7.8 0.920029H11.4C11.645 0.920029 12.0675 1.19753 12.22 1.59003L12.5 2.32003C12.5487 2.44253 12.6687 2.52128 12.8 2.52003H13.76C14.6475 2.52003 15.36 3.23253 15.36 4.12003V10.84C15.36 11.7275 14.6475 12.44 13.76 12.44H2.24C1.3525 12.44 0.64 11.7275 0.64 10.84V4.12003C0.64 3.32378 1.21375 2.66753 1.97 2.54003C2.12375 2.51628 2.2375 2.38503 2.24 2.23003V1.88003H3.84V2.20003C3.84 2.37628 3.98375 2.52003 4.16 2.52003H6.4C6.53125 2.52128 6.65125 2.44253 6.7 2.32003L6.98 1.59003C7.1325 1.19753 7.555 0.920029 7.8 0.920029ZM9.6 3.16003C7.3075 3.16003 5.44 5.02753 5.44 7.32003C5.44 9.61253 7.3075 11.48 9.6 11.48C11.8925 11.48 13.76 9.61253 13.76 7.32003C13.76 5.02753 11.8925 3.16003 9.6 3.16003ZM2.24 3.48003C1.88625 3.48003 1.6 3.76628 1.6 4.12003C1.6 4.47378 1.88625 4.76003 2.24 4.76003C2.59375 4.76003 2.88 4.47378 2.88 4.12003C2.88 3.76628 2.59375 3.48003 2.24 3.48003ZM9.6 3.80003C11.5475 3.80003 13.12 5.37253 13.12 7.32003C13.12 9.26753 11.5475 10.84 9.6 10.84C7.6525 10.84 6.08 9.26753 6.08 7.32003C6.08 5.37253 7.6525 3.80003 9.6 3.80003Z"
            />
        </SvgIcon>
    );
}

PhotoIcon.defaultProps = {
    titleAccess: 'photo-icon',
},

PhotoIcon.propTypes = svgIconPropTypes;
