import { slimServicesFactory } from 'services/servicesFactory';

export function* putAlert(alerts = {}, response = {}, variant = 'warning') {
    let status = response.status || 'UNKNOWN';

    if (response?.message === 'Network Error') {
        status = 'NETWORK_ERROR';
    }

    const slimServices = slimServicesFactory();
    const alert = slimServices.alerts.generateAlert(status, alerts, variant);

    yield slimServices.alerts.addAlert(alert);
}
