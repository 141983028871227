/* istanbul ignore file */
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';

const mapStateToProps = (state) => {
    return {
        stagedPath: state.getIn(['router', 'location', 'pathname']).split('/').splice(1),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        moveTo: (data) => dispatch(push(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
