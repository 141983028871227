import { connect } from 'react-redux';
import {
    fetchProductCatalogs,
    downloadProductCatalog,
} from 'modules/stablo/reducers/productCatalogs/actions';
import ProductCatalogsTable from 'modules/stablo/components/ProductCatalogsTable/ProductCatalogsTable';
import {
    selectLoader,
    selectProductCatalogs,
} from 'modules/stablo/reducers/productCatalogs/selectors';

const mapStateToProps = (state) => {
    return {
        productCatalogs: selectProductCatalogs(state),
        loading: selectLoader(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProductCatalogs: (data) => dispatch(fetchProductCatalogs.request(data)),
        downloadProductCatalog: (data) => dispatch(downloadProductCatalog.request(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogsTable);
