import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ShopIcon(props) {
    return (
        <SvgIcon
            viewBox="0 0 18 18"
            { ...props }
        >
            <path
                d="M9.00001 0C7.61626 0 6.48001 1.13625 6.48001 2.52H4.61251L4.57876 2.835L3.60001 11.475L3.55501 11.88H14.445L14.4 11.475L13.4213 2.835L13.3875 2.52H11.52C11.52 1.13625 10.3838 0 9.00001 0ZM9.00001 0.72C9.99141 0.72 10.8 1.52859 10.8 2.52H7.20001C7.20001 1.52859 8.0086 0.72 9.00001 0.72ZM5.25376 3.24H6.48001V3.70125C6.26485 3.82641 6.12001 4.05422 6.12001 4.32C6.12001 4.71797 6.44204 5.04 6.84001 5.04C7.23798 5.04 7.56001 4.71797 7.56001 4.32C7.56001 4.05422 7.41516 3.82641 7.20001 3.70125V3.24H10.8V3.70125C10.5848 3.82641 10.44 4.05422 10.44 4.32C10.44 4.71797 10.762 5.04 11.16 5.04C11.558 5.04 11.88 4.71797 11.88 4.32C11.88 4.05422 11.7352 3.82641 11.52 3.70125V3.24H12.7463L13.635 11.16H4.36501L5.25376 3.24ZM2.82376 9.64125C2.79282 9.64828 2.76188 9.65953 2.73376 9.675L0.303757 10.8C0.292507 10.8028 0.281257 10.807 0.270007 10.8113L0.236257 10.8225C0.199694 10.8323 0.165944 10.8478 0.135007 10.8675C0.127976 10.8703 0.119538 10.8745 0.112507 10.8788C0.112507 10.883 0.112507 10.8858 0.112507 10.89C-0.0238995 10.9913 -0.0703059 11.1741 6.7614e-06 11.3288V12.6C-0.00139949 12.7294 0.0675067 12.8503 0.180007 12.915L8.82001 17.955C8.9311 18.0197 9.06891 18.0197 9.18001 17.955L17.82 12.915C17.9325 12.8503 18.0014 12.7294 18 12.6V11.34C18.0788 11.1741 18.0211 10.9758 17.865 10.8788C17.8411 10.8605 17.8144 10.845 17.7863 10.8338L17.775 10.8225C17.7539 10.8127 17.73 10.8056 17.7075 10.8L15.2663 9.675C15.2142 9.64969 15.1552 9.63844 15.0975 9.64125C14.9231 9.64266 14.7755 9.76781 14.7445 9.93797C14.715 10.1095 14.8106 10.2783 14.9738 10.3388L16.8525 11.1938L9.00001 15.7838L1.14751 11.1938L3.02626 10.3388C3.20344 10.2797 3.30751 10.0941 3.26251 9.91266C3.21891 9.72985 3.04313 9.61172 2.85751 9.64125C2.84626 9.64125 2.83501 9.64125 2.82376 9.64125ZM0.720007 11.79L8.82001 16.515C8.9311 16.5797 9.06891 16.5797 9.18001 16.515L17.28 11.79V12.3863L9.00001 17.2238L0.720007 12.3863V11.79Z"
            />
        </SvgIcon>
    );
}

ShopIcon.defaultProps = {
    titleAccess: 'shop-icon',
};

ShopIcon.propTypes = svgIconPropTypes;
