export const ACCEPT = {
    strength: 1,
    allow: true,
};
export const DENY = {
    strength: 2,
    allow: false,
};
export const FORCE_ACCEPT = {
    strength: 3,
    allow: true,
};
export const FORCE_DENY = {
    strength: 4,
    allow: false,
};
export const CONDITIONAL = (voteType, conditions) => {
    return {
        ...voteType,
        conditions,
    };
};
