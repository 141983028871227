import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        PATTERNS: {
            FETCH: `${MODULE_NAME}_resources_patterns_fetch`,
            EDIT: `${MODULE_NAME}_resouces_patterns_edit`,
            TRANSLATION_GROUP: {
                FETCH: `${MODULE_NAME}_resources_patterns_translation_group_fetch`,
                CREATE: `${MODULE_NAME}_resources_patterns_translation_group_create`,
            },
            CATEGORIES: {
                FETCH: `${MODULE_NAME}_resources_patterns_categories_fetch`,
                CREATE: `${MODULE_NAME}_resources_patterns_categories_create`,
                EDIT: `${MODULE_NAME}_resources_patterns_categories_edit`,
            },
            TAGS: {
                EDIT: `${MODULE_NAME}_resouces_patterns_tags_edit`,
            },
            TITLES: {
                EDIT: `${MODULE_NAME}_resouces_patterns_titles_edit`,
            },
        },
    },
};
