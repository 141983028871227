import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function PapersIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -1 16 16"
            { ...props }
        >
            <path
                d="M8 0.182617L7.70264 0.311523L0.5 3.43018V4.56689L8 7.81738L15.5 4.56689V3.43018L8 0.182617ZM8 1.81738L13.0376 4L8 6.18262L2.9624 4L8 1.81738ZM15.5 6.1665L14 6.81543V6.81689L8 9.41699L0.5 6.17236V7.80127L8 11.0503L15.5 7.80127V6.1665ZM15.5 9.4624L14 10.1128L8 12.7129L0.5 9.46826V11.0986L8 14.3477L15.5 11.0986V9.4624Z"
            />
        </SvgIcon>
    );
}

PapersIcon.defaultProps = {
    titleAccess: 'papers-icon',
};

PapersIcon.propTypes = svgIconPropTypes;
