import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changePassword } from 'modules/app/reducers/userProfile/actions';
import {
    selectUserProfileLoaders,
    selectChangePasswordError,
} from 'modules/app/reducers/userProfile/selectors';
import ChangePasswordForm from 'modules/app/components/ChangePasswordForm/ChangePasswordForm';

const mapStateToProps = (state) => {
    return {
        loading: selectUserProfileLoaders(state, 'loginForm'),
        error: selectChangePasswordError(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (data) => dispatch(changePassword.request(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('app')(ChangePasswordForm));
