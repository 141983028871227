import { get } from 'lodash';

export const mapAuthorFromApi = (author) => {
    const firstName = get(author, 'first_name', '');
    const lastName = get(author, 'last_name', '');
    const fullName = [firstName, lastName].join(' ').trim();

    return {
        id: get(author, 'id', ''),
        firstName,
        lastName,
        fullName,
        nickname: get(author, 'nickname', ''),
        companyName: get(author, 'company_name', ''),
        shopUrl: get(author, 'shop_url', ''),
        street: get(author, 'street', ''),
        city: get(author, 'city', ''),
        postalCode: get(author, 'postal_code', ''),
        countryCode: get(author, 'country_code', ''),
        nipNumber: get(author, 'nip_number', ''),
        phone: get(author, 'phone', ''),
        email: get(author, 'email', ''),
        createdAt: get(author, 'created_at', ''), // "2020-03-30T09:51:02+00:00"
        deleted: get(author, 'deleted', false),
    };
};
