import { fromJS } from 'immutable';
import { getErrorCodeFromPayload } from '../helpers';
import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';

export function fetchTechnologyComponentsRequest({ options, state, action }) {
    const { singularName } = options;
    const { payload = {} } = action;
    const loaderName = payload.loaderName || singularName;

    return state
        .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
        .setIn(['loaders', `${loaderName}Details`], true)
        .setIn(['loaders', `${loaderName}Create`], true);
}

export function fetchTechnologyComponentsFailure({ options, state, action }) {
    const { singularName } = options;
    const { payload = {} } = action;
    const loaderName = payload.loaderName || singularName;
    const errorCode = getErrorCodeFromPayload(action.payload);

    return state
        .set(`fetch${upperFirst(singularName)}ErrorCode`, errorCode)
        .setIn(['loaders', `${loaderName}Details`], false)
        .setIn(['loaders', `${loaderName}Create`], false);
}

export function fetchTechnologyComponentsSuccess({ options, state, action }) {
    const { pluralName, singularName } = options;
    const { payload = {} } = action;
    const { technologyComponents, intermediateProductId } = payload.response;
    const loaderName = payload.loaderName || singularName;
    const intermediateProduct = state.get('intermediateProductsById').toJS();
    const intermediateProductComponents =
        intermediateProduct[intermediateProductId]?.components;
    const intermediateProductComponentIds = intermediateProductComponents?.map(
        (component) => component.id,
    );
    const technologyComponentIds = technologyComponents?.map(
        (component) => component.id,
    );

    const concatenatedComponents = technologyComponents?.map(
        (techComponent) => {
            const sameComponent = intermediateProductComponents?.find(
                (intermediateComponent) => {
                    return intermediateComponent.id === techComponent.id;
                },
            );

            return sameComponent ? sameComponent : techComponent;
        },
    );

    if (technologyComponents && intermediateProductComponents) {
        if (
            technologyComponents.length >
                intermediateProductComponents.length ||
            !isEqual(intermediateProductComponentIds, technologyComponentIds)
        ) {
            return state
                .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
                .setIn(
                    [`${pluralName}ById`, intermediateProductId, 'components'],
                    fromJS(concatenatedComponents),
                )
                .setIn(['loaders', `${loaderName}Details`], false)
                .setIn(['loaders', `${loaderName}Create`], false);
        } else {
            return state
                .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
                .setIn(['loaders', `${loaderName}Details`], false)
                .setIn(['loaders', `${loaderName}Create`], false);
        }
    } else {
        return state
            .set(`fetch${upperFirst(singularName)}ErrorCode`, null)
            .setIn(['loaders', `${loaderName}Details`], false)
            .setIn(['loaders', `${loaderName}Create`], false);
    }
}
