import { lazy as reactLazy } from 'react';

const PAGE_REFRESHED_LS_KEY = 'pageHasBeenForceRefreshed';

export const lazy = (componentImport) =>
    reactLazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                PAGE_REFRESHED_LS_KEY
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                PAGE_REFRESHED_LS_KEY,
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed && (/Loading chunk \d+ failed/.test(error.message))) {
                window.localStorage.setItem(
                    PAGE_REFRESHED_LS_KEY,
                    'true'
                );

                return window.location.reload();
            }

            throw error;
        }
    });
