import { DynamicFormDictionariesService } from './dynamicFormDictionariesService';
import { ErrorHandler } from './errorHandler';
import type { AjaxService } from 'services/ajax/AjaxService';
import type { AlertService } from 'services/alert/AlertService';

export const dynamicFormDictionariesServiceFactory = (
    ajaxService: AjaxService,
    alertService: AlertService,
) =>
    DynamicFormDictionariesService.getInstance(
        ajaxService,
        new ErrorHandler(alertService),
    );
