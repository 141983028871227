import get from 'lodash/get';
import parser from 'qs';
import {
    mapTechnologyGroupFromApi,
    mapTechnologyGroupToApi,
} from '../mappers/technologyGroup';
import errorHandlers from '../errorHandlers';

import type {
    IArmApiServiceConst,
    IArmApiServiceServices,
} from '../armApi.type';

const filtersConfig = [
    { field: 'name', operator: 'match' },
    { field: 'code', operator: 'match' },
    { field: 'pattern_code', operator: 'eq' },
    { field: 'search', operator: 'match' },
];

class TechnologiesGroups {
    private readonly errorHandlers: ReturnType<typeof errorHandlers>;
    private readonly URLS: IArmApiServiceConst['URLS'];

    constructor(
        private readonly services: IArmApiServiceServices,
        { URLS }: Pick<IArmApiServiceConst, 'URLS'>,
    ) {
        this.services = services;
        this.URLS = URLS;
        this.errorHandlers = errorHandlers(services.alert);

        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.getById = this.getById.bind(this);
        this.removeById = this.removeById.bind(this);
        this.getList = this.getList.bind(this);
    }
    private paramsSerializer = (params) => parser.stringify(params);

    _mapFilters = (filters = {}) => {
        filtersConfig.forEach(({ field, operator }) => {
            const value = get(filters, `${field}[$${operator}]`, '');

            if (value) filters[field] = { [operator]: value };
        });

        return filters;
    };
    async edit(technologyGroup) {
        const { id } = technologyGroup;
        const { ajax } = this.services;
        const url = this.URLS.PUT_TECHNOLOGY_GROUP.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.put,
        };

        const response = await ajax.put({
            url,
            data: mapTechnologyGroupToApi(technologyGroup),
            errorConfig,
        });

        return mapTechnologyGroupFromApi(response.data);
    }
    async create(technologyGroup) {
        const { ajax } = this.services;
        const url = this.URLS.POST_TECHNOLOGY_GROUP;
        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.post,
        };

        const response = await ajax.post({
            url,
            data: mapTechnologyGroupToApi(technologyGroup),
            errorConfig,
        });

        return mapTechnologyGroupFromApi(response.data);
    }
    async getById(id) {
        const { ajax } = this.services;
        const url = this.URLS.GET_TECHNOLOGY_GROUP.replace('{id}', id);

        const response = await ajax.get({ url });

        return mapTechnologyGroupFromApi(response.data);
    }
    async removeById(id) {
        const { ajax } = this.services;
        const url = this.URLS.GET_TECHNOLOGY_GROUP.replace('{id}', id);
        const errorConfig = {
            addGenericAlert: false,
            throwError: this.errorHandlers.delete,
        };

        return await ajax.delete({ url, errorConfig });
    }
    async getList({ filters, order, per_page = 20, page = 1 }) {
        const { ajax } = this.services;
        const url = this.URLS.GET_TECHNOLOGY_GROUPS;
        const filter = this._mapFilters(filters);

        const config = {
            params: {
                page,
                per_page,
                filter,
                sort: order,
            },
            paramsSerializer: this.paramsSerializer,
        };

        const response = await ajax.get({ url, config });
        const items = get(response, 'data.hydra:member', []).map(
            (technologyGroup) => mapTechnologyGroupFromApi(technologyGroup),
        );
        const itemsCount = get(response, 'data.hydra:totalItems', 0);

        return { items, itemsCount };
    }
}

export default TechnologiesGroups;
