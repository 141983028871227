import { ACCEPT } from '../voteTypes';
import * as S from '../sections';
import * as A from '../accesses';

export const ROLE_PDP_USER_DISPLAY = {
    section: S.USER,
    type: 'display',
    votes: {
        [A.PDP.MODULE]: ACCEPT,
        [A.PDP.PATHS.USERS]: ACCEPT,
        [A.PDP.PATHS.USERS_DETAILS]: ACCEPT,
        [A.DROPSHIPPING.PATHS.PATRONS]: ACCEPT,
        [A.PDP.RESOURCES.USER.FETCH]: ACCEPT,
    },
};

export const ROLE_WORKPLACES_WORKPLACES_MANAGE = {
    section: S.USER,
    type: 'manage',
    votes: {
        [A.PDP.PATHS.WORKPLACES]: ACCEPT,
        [A.PDP.PATHS.WORKPLACES_DETAILS]: ACCEPT,
    },
};

export const ROLE_PDP_USER_MANAGEMENT = {
    section: S.USER,
    type: 'manage',
    votes: {
        [A.PDP.RESOURCES.USER.EDIT]: ACCEPT,
        [A.PDP.RESOURCES.USER.CREATE]: ACCEPT,
        [A.PDP.RESOURCES.USER.DELETE]: ACCEPT,
        [A.PDP.PATHS.ROLE_GROUPS]: ACCEPT,
        [A.PDP.PATHS.ROLE_GROUP_DETAILS]: ACCEPT,
        [A.PDP.RESOURCES.ROLE_GROUPS.FETCH]: ACCEPT,
        [A.PDP.RESOURCES.ROLE_GROUPS.CREATE]: ACCEPT,
        [A.PDP.RESOURCES.ROLE_GROUPS.EDIT]: ACCEPT,
        [A.PDP.RESOURCES.ROLE_GROUPS.DELETE]: ACCEPT,
    },
};

export const ROLE_PDP_TOS_UPDATE = {
    section: S.TOS,
    type: 'edit',
    votes: {
        [A.PDP.RESOURCES.TOS.EDIT]: ACCEPT,
        [A.PDP.PATHS.TOS]: ACCEPT,
        [A.PDP.PATHS.TOS_DETAILS]: ACCEPT,
    },
};

export const ROLE_PDP_TOS_CREATE = {
    section: S.TOS,
    type: 'create',
    votes: {
        [A.PDP.RESOURCES.TOS.CREATE]: ACCEPT,
        [A.PDP.PATHS.TOS]: ACCEPT,
        [A.PDP.PATHS.TOS_DETAILS]: ACCEPT,
    },
};

export const ROLE_PDP_POSITION_READ = {
    section: S.POSITIONS,
    type: 'display',
    votes: {
        [A.PDP.MODULE]: ACCEPT,
        [A.PDP.PATHS.POSITIONS]: ACCEPT,
        [A.PDP.PATHS.POSITION_DETAILS]: ACCEPT,
        [A.PDP.RESOURCES.POSITION.FETCH]: ACCEPT,
    },
};

export const ROLE_PDP_POSITION_CREATE = {
    section: S.POSITIONS,
    type: 'create',
    votes: {
        [A.PDP.PATHS.POSITIONS]: ACCEPT,
        [A.PDP.PATHS.POSITION_DETAILS]: ACCEPT,
        [A.PDP.RESOURCES.POSITION.CREATE]: ACCEPT,
    },
};

export const ROLE_PDP_POSITION_UPDATE = {
    section: S.POSITIONS,
    type: 'edit',
    votes: {
        [A.PDP.PATHS.POSITIONS]: ACCEPT,
        [A.PDP.PATHS.POSITION_DETAILS]: ACCEPT,
        [A.PDP.RESOURCES.POSITION.EDIT]: ACCEPT,
    },
};

export const ROLE_PDP_POSITION_DELETE = {
    section: S.POSITIONS,
    type: 'delete',
    votes: {
        [A.PDP.PATHS.POSITIONS]: ACCEPT,
        [A.PDP.PATHS.POSITION_DETAILS]: ACCEPT,
        [A.PDP.RESOURCES.POSITION.DELETE]: ACCEPT,
    },
};
