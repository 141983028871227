import React from 'react';
import displayHOCName from 'utils/displayHOCName';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getSearch, getHash } from 'utils/router/selectors';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => {
    return {
        search: getSearch(state),
        hash: getHash(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        push: (data) => dispatch(push(data)),
    };
};

const withUrlPushHOC = (WrappedComponent) => {
    class WithUrlPush extends React.PureComponent {
        render() {
            const { search, hash, push, ...other } = this.props;

            return (
                <WrappedComponent
                    search={search}
                    hash={hash}
                    push={push}
                    {...other}
                />
            );
        }
    }

    WithUrlPush.propTypes = {
        search: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired,
        hash: PropTypes.string.isRequired,
    };

    WithUrlPush.displayName = `WithUrlPush(${displayHOCName(WrappedComponent)})`;

    return connect(mapStateToProps, mapDispatchToProps)(WithUrlPush);
};

export default withUrlPushHOC;
