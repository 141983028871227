import get from 'lodash/get';
import moment from 'moment';

export const mapSalesChannelFromApi = (channel) => {
    const {
        code,
        name,
        description,
        offer,
        company,
        country_code,
        profile,
        created_at,
        created_by_user,
        id,
        platform,
        type,
        languages,
        generate_gtins,
        gtin_group,
    } = channel;

    const userName = `${created_by_user ? created_by_user.first_name : ''} ${
        created_by_user ? created_by_user.last_name : ''
    }`;

    return {
        id,
        code: code || '',
        name: name || '',
        description: description || '',
        offer: offer || '',
        company: company || {},
        countryCode: country_code || '',
        profile: profile || '',
        createdAt: created_at ? moment(created_at).format('DD/MM/YYYY') : null,
        createdByName: userName,
        createdById: (created_by_user && created_by_user.id) || null,
        platform: platform || {},
        type: type || '',
        languages,
        ean: generate_gtins,
        gtinGroup: gtin_group || '',
    };
};

export const mapCreateSalesChannelToApi = (data) => {
    const companyId = get(data, 'company.id');
    const platformId = get(data, 'platform.id');
    const offerId = get(data, 'offer.id');
    const languages = data.languages?.length
        ? data.languages.map((l) => l)
        : null;

    return {
        name: data.name || '',
        code: data.code || '',
        country_code: data.countryCode || '',
        type: data.type || '',
        company: {
            id: companyId,
        },
        languages: languages,
        ...(platformId && {
            platform: { id: platformId },
        }),
        ...(offerId && {
            offer: { id: offerId },
        }),
        generate_gtins: data.ean,
        gtin_group: data.gtinGroup,
    };
};

export const mapEditSalesChannelToApi = (data) => {
    const languages = data.languages?.map((l) => l);
    const offerId = get(data, 'offer.id');

    return {
        ...(offerId && {
            offer: { id: offerId },
        }),
        languages: languages,
        generate_gtins: data.ean,
        ...(data?.gtinGroup && { gtin_group: data.gtinGroup }),
    };
};
