import { OrderedMap, fromJS } from 'immutable';
import upperFirst from 'lodash/upperFirst';
import {
    createRequest,
    createFailure,
    createSuccess,
} from './createItem';
import {
    removeRequest,
    removeFailure,
    removeSuccess,
} from './removeItem';
import {
    removeListRequest,
    removeListFailure,
    removeListSuccess,
} from './removeItems';
import {
    editRequest,
    editFailure,
    editSuccess,
} from './editItem';
import {
    fetchListRequest,
    fetchListFailure,
    fetchListSuccess,
} from './fetchItems';
import {
    fetchRequest,
    fetchFailure,
    fetchSuccess,
} from './fetchItem';
import {
    fetchListForAutocompleteRequest,
    fetchListForAutocompleteFailure,
    fetchListForAutocompleteSuccess,
} from './fetchItemsForAutocomplete';
import {
    fetchTechnologyComponentsRequest,
    fetchTechnologyComponentsFailure,
    fetchTechnologyComponentsSuccess,
} from './fetchTechnologyComponents';

export function clearErrors({ state }) {
    return state
        .set('errorsByField', fromJS({}));
}

export const createReduxMethods = {
    request: createRequest,
    failure: createFailure,
    success: createSuccess,
};

export const removeReduxMethods = {
    request: removeRequest,
    failure: removeFailure,
    success: removeSuccess,
};

export const removeListReduxMethods = {
    request: removeListRequest,
    failure: removeListFailure,
    success: removeListSuccess,
};

export const editReduxMethods = {
    request: editRequest,
    failure: editFailure,
    success: editSuccess,
};

export const fetchListReduxMethods = {
    request: fetchListRequest,
    failure: fetchListFailure,
    success: fetchListSuccess,
};

export const fetchReduxMethods = {
    request: fetchRequest,
    failure: fetchFailure,
    success: fetchSuccess,
};

export const fetchTechnologyComponentsReduxMethods = {
    request: fetchTechnologyComponentsRequest,
    failure: fetchTechnologyComponentsFailure,
    success: fetchTechnologyComponentsSuccess,
};

export const fetchAutocompleteReduxMethods = {
    request: fetchListForAutocompleteRequest,
    failure: fetchListForAutocompleteFailure,
    success: fetchListForAutocompleteSuccess,
};

export function setInitialState(options, state = {}) {
    const { singularName, pluralName } = options;

    return fromJS({
        [`${ pluralName }ById`]: OrderedMap(),
        [`${ pluralName }ForAutocomplete`]: fromJS({}),

        [`${ pluralName }Count`]: 0,

        [`${ pluralName }Params`]: {
            page: 1,
            per_page: 20,
        },

        // Loaders
        loaders: {
            [`${ singularName }Create`]: false,
            [`${ singularName }Details`]: false,
            [`${ pluralName }Table`]: false,
        },

        // Errors
        [`fetch${ upperFirst(singularName) }ErrorCode`]: null,
        errorsByField: {},

        ...state,
    });
}
