import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ReplyIcon(props) {
    return (
        <SvgIcon
            viewBox="0 -2 16 16"
            { ...props }
        >
            <path
                d="M5.6 4.44774e-08L-1.34784e-06 5.6L5.6 11.2L5.6 8C15.2 8 16 12 16 12C16 12 15.2 3.2 5.6 3.2L5.6 4.44774e-08Z"
            />
        </SvgIcon>
    );
}

ReplyIcon.defaultProps = {
    titleAccess: 'reply-icon',
};

ReplyIcon.propTypes = svgIconPropTypes;
