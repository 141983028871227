import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from 'modules/app/components/LoginForm/LoginForm';
import SentChangePasswordForm from 'modules/app/components/SentChangePasswordForm/SentChangePasswordForm';
import Button from 'components/Button/button';
import { Loader } from 'components/Loader/Loader';
import LanguagePicker from 'modules/app/components/LanguagePicker/LanguagePicker';
import { FooterStyled } from './loginContent.styles';

class LoginContent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            resetPass: false,
        };
    }

    togglePasswordReset = () => {
        const { resetPass } = this.state;

        this.setState({
            resetPass: !resetPass,
        });
    };

    renderContent = (props) => {
        const {
            login,
            requestChangePassword,
            loginError,
            requestChangePasswordError,
        } = this.props;
        const { resetPass } = this.state;

        if (resetPass) {
            return (
                <SentChangePasswordForm
                    requestChangePassword={requestChangePassword}
                    requestChangePasswordError={requestChangePasswordError}
                    {...props}
                />
            );
        }

        return (
            <LoginForm
                loginError={loginError}
                login={login}
                {...props}
            />
        );
    };

    render() {
        const { t, loading } = this.props;
        const { resetPass } = this.state;
        const Content = this.renderContent;

        return (
            <div>
                {loading && <Loader />}
                <Content t={t} />
                <FooterStyled>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={this.togglePasswordReset}
                    >
                        {resetPass
                            ? t('app:labels.backToLogin')
                            : t('app:labels.remindPassword')}
                    </Button>
                    <LanguagePicker />
                </FooterStyled>
            </div>
        );
    }
}

LoginContent.defaultProps = {
    t: (key) => key,
};

LoginContent.propTypes = {
    t: PropTypes.func,
    loginError: PropTypes.string,
    login: PropTypes.func.isRequired,
    requestChangePasswordError: PropTypes.string,
    requestChangePassword: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default LoginContent;
