import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function MicrophoneIcon(props) {
    return (
        <SvgIcon
            viewBox="-3 0 16 16"
            { ...props }
        >
            <path
                d="M5.00005 0.666748C3.34914 0.666748 2.00005 2.01584 2.00005 3.66675V8.66675C2.00005 10.3177 3.34914 11.6667 5.00005 11.6667C6.65096 11.6667 8.00005 10.3177 8.00005 8.66675V3.66675C8.00005 2.01584 6.65096 0.666748 5.00005 0.666748ZM0.492239 6.99292C0.359744 6.99499 0.233489 7.04956 0.141204 7.14466C0.0489186 7.23975 -0.00184913 7.36758 5.15067e-05 7.50008V8.66675C5.15067e-05 11.2536 1.97806 13.3895 4.50005 13.6414V15.1667C4.49911 15.233 4.51135 15.2988 4.53606 15.3603C4.56077 15.4218 4.59745 15.4777 4.64397 15.5249C4.69049 15.5721 4.74593 15.6096 4.80706 15.6351C4.86819 15.6607 4.93379 15.6739 5.00005 15.6739C5.06631 15.6739 5.13192 15.6607 5.19304 15.6351C5.25417 15.6096 5.30961 15.5721 5.35613 15.5249C5.40265 15.4777 5.43933 15.4218 5.46404 15.3603C5.48875 15.2988 5.50099 15.233 5.50005 15.1667V13.6414C8.02204 13.3895 10.0001 11.2536 10.0001 8.66675V7.50008C10.001 7.43383 9.98875 7.36804 9.96404 7.30656C9.93933 7.24508 9.90265 7.18911 9.85613 7.14193C9.80961 7.09475 9.75417 7.05728 9.69304 7.0317C9.63192 7.00613 9.56631 6.99296 9.50005 6.99296C9.43379 6.99296 9.36819 7.00613 9.30706 7.0317C9.24593 7.05728 9.19049 7.09475 9.14397 7.14193C9.09745 7.18911 9.06077 7.24508 9.03606 7.30656C9.01135 7.36804 8.99911 7.43383 9.00005 7.50008V8.66675C9.00005 10.8619 7.24661 12.6317 5.0593 12.6635C5.03706 12.6607 5.01465 12.6594 4.99224 12.6596C4.97395 12.6599 4.9557 12.6612 4.93755 12.6635C2.75176 12.6299 1.00005 10.8608 1.00005 8.66675V7.50008C1.00101 7.43316 0.988527 7.36672 0.963339 7.30472C0.938151 7.24271 0.900771 7.18639 0.853415 7.13909C0.806059 7.0918 0.74969 7.05449 0.687649 7.02938C0.625609 7.00427 0.559159 6.99187 0.492239 6.99292Z"
            />
        </SvgIcon>
    );
}

MicrophoneIcon.defaultProps = {
    titleAccess: 'microphone-icon',
};

MicrophoneIcon.propTypes = svgIconPropTypes;
