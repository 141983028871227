import { mapAuthorFromApi } from '../mappers/author';

import { paramsSerializer } from 'services/utils/utils';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';

class Authors {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly DEFAULTS: IStockServiceConst['DEFAULTS'];
    private readonly HEADERS: IStockServiceConst['HEADERS'];
    private readonly APP_ALERTS: IStockServiceConst['APP_ALERTS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = stockService.URLS;
        this.DEFAULTS = stockService.DEFAULTS;
        this.HEADERS = stockService.HEADERS;
        this.APP_ALERTS = stockService.APP_ALERTS;
        this.errorHandlers = stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getListAutocomplete = this.getListAutocomplete.bind(this);
        this.getById = this.getById.bind(this);
        this.delete = this.delete.bind(this);
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    async create(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const url = URLS.POST_AUTHOR;
        const config = { headers: HEADERS.basic };
        const errorConfig = {
            throwError: errorHandlers.post,
            addGenericAlert: false,
        };

        const response = await ajax.post({ url, config, errorConfig, data });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.CREATE_AUTHOR_SUCCESS),
        });

        return response.data;
    }

    async edit(data) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, HEADERS, errorHandlers } = this;

        const { id, ...formData } = data;

        const url = URLS.PATCH_AUTHOR.replace('{id}', id);
        const config = { headers: HEADERS.patch };
        const errorConfig = {
            throwError: errorHandlers.patch,
            addGenericAlert: false,
        };

        const response = await ajax.patch({
            url,
            config,
            errorConfig,
            data: formData,
        });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.EDIT_AUTHOR_SUCCESS),
        });

        return response.data;
    }

    getListAutocomplete({
        filters = {},
        per_page = this.DEFAULTS.AUTHORS_AUTOCOMPLETE_PER_PAGE,
        page = 1,
    }) {
        const { ajax, requestCache } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_AUTHORS;
        const params = {
            page,
            per_page,
            ...filters,
        };
        const config = { params, headers: HEADERS.basic };
        const request = requestCache.getRequest(url, params);
        const onError = errorHandlers.get;

        if (!request) {
            const newRequest = ajax
                .get({ url, config, onError })
                .then((response) => {
                    return (response?.data?.results ?? []).map((author) =>
                        mapAuthorFromApi(author),
                    );
                });

            requestCache.setRequest(url, params, newRequest);

            return newRequest;
        }

        return request;
    }

    async getList({
        sort = {},
        filters = {},
        per_page = this.DEFAULTS.AUTHORS_LIST_PER_PAGE,
        page = 1,
    }) {
        const { ajax } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_AUTHORS;
        const params = {
            ...filters,
            page,
            per_page,
            order: sort,
        };
        const config = {
            params,
            headers: HEADERS.basic,
            paramsSerializer,
        };
        const errorConfig = {
            throwError: errorHandlers.get,
            addGenericAlert: false,
        };

        const response = await ajax.get({ url, config, errorConfig });

        return {
            items: (response?.data?.results ?? []).map((author) =>
                mapAuthorFromApi(author),
            ),
            itemsTotal: response?.data?.total ?? 0,
        };
    }

    async getById(authorId) {
        const { ajax } = this.services;
        const { URLS, HEADERS, errorHandlers } = this;

        const url = URLS.GET_AUTHOR.replace('{id}', authorId);
        const config = { headers: HEADERS.basic };
        const onError = errorHandlers.get;

        const { data } = await ajax.get({ url, config, onError });

        return data;
    }

    async delete(authorId) {
        const { ajax, alert, translator } = this.services;
        const { URLS, APP_ALERTS, errorHandlers } = this;

        const url = URLS.DELETE_AUTHOR.replace('{id}', authorId);
        const errorConfig = {
            throwError: errorHandlers.delete,
            addGenericAlert: false,
        };

        const response = await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t(APP_ALERTS.DELETE_AUTHOR_SUCCESS),
        });

        return response.data;
    }

    async getByName(authorName) {
        const { ajax } = this.services;
        const { URLS, errorHandlers, HEADERS } = this;

        const url = URLS.GET_AUTHORS;
        const params = {
            page: 1,
            per_page: 2,
            search: authorName,
        };
        const config = { params, headers: HEADERS.basic };
        const onError = errorHandlers.get;

        const response = await ajax.get({ url, config, onError });
        const authors = response?.data?.results ?? [];

        if (authors.length === 1) {
            return mapAuthorFromApi(authors[0]);
        }
        throw new Error('There is no author for this user.');
    }
}

export default Authors;
