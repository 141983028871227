import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import EntryPageTitle from 'modules/app/components/EntryPageTitle/EntryPageTitle';
import { LoginButtonStyled } from 'modules/app/components/ConfirmChangePassword/confirmChangePassword.styles';

export class ConfirmPasswordChange extends React.PureComponent {
    render() {
        const { t, goToLogin } = this.props;

        return (
            <div>
                <EntryPageTitle
                    title={t('titles.passwordDidChange')}
                    subtitle={t('contents.youCanLogin')}
                />
                <LoginButtonStyled
                    fullWidth
                    color="primary"
                    onClick={goToLogin}
                >
                    {t('actions.goToLogin')}
                </LoginButtonStyled>
            </div>
        );
    }
}

ConfirmPasswordChange.defaultProps = {
    t: (key) => key,
};

ConfirmPasswordChange.propTypes = {
    t: PropTypes.func,
    goToLogin: PropTypes.func.isRequired,
};

const ConfirmPasswordChangeWithHOC = withTranslation('app')(
    ConfirmPasswordChange,
);

export default ConfirmPasswordChangeWithHOC;
