export function toggleNavBar() {
    localStorage.setItem(
        'navBarExtended',
        getNavBarExtended() ? 'false' : 'true'
    );
}

export function getNavBarExtended() {
    return localStorage.getItem('navBarExtended') === 'true';
}
