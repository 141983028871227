import React from 'react';
import PropTypes from 'prop-types';
import { CellStyled } from 'components/OldTable/Cell/cell.styles';

const Cell = React.forwardRef(({
    actions,
    basic,
    children,
    className,
    customCell,
    flex,
    isExtended,
    onClick,
    small,
    top,
    ...other
}, ref) => (
    <CellStyled
        { ...other }
        onClick={ onClick }
        ref={ ref }
        clickable={ onClick }
        extended={ isExtended }
        basic={ basic }
        actions={ actions }
        flex={ flex }
        custom={ customCell }
        smallCustom={ small && customCell }
        small={ small }
        smallActions={ small && actions }
        className={ className }
    >
        {children}
    </CellStyled>
));

export default Cell;

Cell.propTypes = {
    customCell: PropTypes.bool,
    basic: PropTypes.bool,
    onClick: PropTypes.func,
    isExtended: PropTypes.bool,
    top: PropTypes.bool,
    flex: PropTypes.bool,
    small: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    actions: PropTypes.bool,
};
