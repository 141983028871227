import { getActiveLanguage } from 'i18n';
import { ApplicationJsonHeaders } from '../../constants';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';

class Colors {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = this.stockService.URLS;
        this.errorHandlers = this.stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.mapColors = this.mapColors.bind(this);
    }

    async getList(per_page = 100, page = 1) {
        const { ajax } = this.services;
        const url = this.URLS.GET_COLORS;
        const onError = this.errorHandlers.get;
        const config = {
            headers: ApplicationJsonHeaders,
            params: {
                page,
                per_page,
            },
        };

        const response = await ajax.get({ url, config, onError });

        return response.data.results;
    }

    async getById(id) {
        const { ajax } = this.services;
        const url = this.URLS.GET_COLOR.replace('{id}', id);
        const onError = this.errorHandlers.get;

        const response = await ajax.get({ url, onError });

        return response.data;
    }

    mapColors(color) {
        const language = getActiveLanguage();
        const colorTranslation = color['color_translations'].find(
            (item) => item.language === language,
        );

        return {
            ...color,
            description: language === 'en' ? color.name : colorTranslation.name,
        };
    }
}

export default Colors;
