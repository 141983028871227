export const MODULE_NAME = 'stablo';

export const PRODUCT_POSSIBILITIES_PER_PAGE_OPTIONS = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '500', value: 500 },
];
