import Typography from 'components/Typography';
import styled from '@emotion/styled';

export const TitleStyled = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};

`;
TitleStyled.displayName = 'Title';

export const SubtitleStyled = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;
SubtitleStyled.displayName = 'Subtitle';
