import parser from 'qs';
import type { SalesChannelsService } from '../../SalesChannelsService';
import {
    mapChannelIntermediateProductPreviewsCreateToApi,
    mapChannelIntermediateProductWithPreviewTypes,
} from '../../mappers/channelIntermediateProductPreviews';
import { configError } from 'services/utils/utils';
import type {
    IChannelIntermediateProductPreviewTypesForm,
    IGetIntermediateProductWithPreviewTypesParams,
    IIntermediateProductWithPreviewTypesResponse,
    IItemId,
} from './channelIntermediateProductPreviews.type';

export class ChannelIntermediateProductPreviews {
    private paramsSerializer = (params) => parser.stringify(params);

    constructor(private readonly salesChannelsService: SalesChannelsService) {
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.getIntermediateProductWithPreviewTypes =
            this.getIntermediateProductWithPreviewTypes.bind(this);
    }

    async getIntermediateProductWithPreviewTypes({
        itemId,
        page = 1,
        per_page = 10,
        sort = {},
    }: IGetIntermediateProductWithPreviewTypesParams): Promise<IIntermediateProductWithPreviewTypesResponse> {
        const { technologyName, ...sortParams } = sort;
        const { ajax, URLS, HEADERS, errorHandlers } =
            this.salesChannelsService;

        const url =
            URLS.GET_CHANNEL_INTERMEDIATE_PRODUCT_WITH_PREVIEW_TYPES.replace(
                '{id}',
                itemId,
            );

        const config = {
            headers: HEADERS.basic,
            params: {
                page,
                itemsPerPage: per_page,
                sort: {
                    ...sortParams,
                    ...(sort?.technologyName
                        ? { 'technology.name': sort.technologyName }
                        : {}),
                },
            },
            paramsSerializer: this.paramsSerializer,
        };
        const errorConfig = configError('get', errorHandlers);

        const emptyResponse = {
            items: [],
            totalItems: 0,
        };

        try {
            const { data } = await ajax.get({ url, errorConfig, config });

            if (data) {
                const { total, results } = data;

                return mapChannelIntermediateProductWithPreviewTypes({
                    results,
                    total,
                });
            }

            return emptyResponse;
        } catch {
            return emptyResponse;
        }
    }

    async delete(itemId: IItemId): Promise<void> {
        const { ajax, URLS, errorHandlers, alert, translator } =
            this.salesChannelsService;

        const url =
            URLS.DELETE_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPE_ITEM.replace(
                '{id}',
                itemId,
            );
        const errorConfig = configError('delete', errorHandlers);

        await ajax.delete({ url, errorConfig });

        alert.addSuccess({
            message: translator.t('manageOffers:alerts.channelPreviewDeleted'),
        });
    }

    async create(
        itemId: IItemId,
        data: IChannelIntermediateProductPreviewTypesForm,
    ): Promise<void> {
        const { ajax, URLS, HEADERS, errorHandlers, alert, translator } =
            this.salesChannelsService;

        const url =
            URLS.POST_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES.replace(
                '{id}',
                itemId,
            );
        const errorConfig = configError('post', errorHandlers);
        const config = {
            headers: HEADERS.basic,
        };

        await ajax.post({
            url,
            errorConfig,
            config,
            data: mapChannelIntermediateProductPreviewsCreateToApi(data),
        });

        data.formActions.resetForm();

        alert.addSuccess({
            message: translator.t('manageOffers:alerts.channelPreviewCreated'),
        });
    }

    async edit(
        data: IChannelIntermediateProductPreviewTypesForm,
    ): Promise<void> {
        const { ajax, URLS, HEADERS, errorHandlers, alert, translator } =
            this.salesChannelsService;

        const { id, priority } = data.formData;

        const url =
            URLS.PATCH_CHANNEL_INTERMEDIATE_PRODUCT_PREVIEW_TYPES.replace(
                '{id}',
                id,
            );
        const errorConfig = configError('patch', errorHandlers);
        const config = {
            headers: HEADERS.patch,
        };

        await ajax.patch({
            url,
            errorConfig,
            config,
            data: { priority },
        });

        alert.addSuccess({
            message: translator.t('manageOffers:alerts.channelPreviewEdited'),
        });
    }
}
