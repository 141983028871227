import type { IPagedResponse } from '../../../types/services.type';
import type {
    IChannelIntermediateProductPreviewTypesForm,
    IChannelIntermediateProductPreviewTypesList,
    IIntermediateProductWithPreviewTypesResponse,
} from '../actions/channelIntermediateProductPreviews/channelIntermediateProductPreviews.type';

export const mapChannelIntermediateProductWithPreviewTypes = ({
    results,
    total,
}: Pick<
    IPagedResponse<IChannelIntermediateProductPreviewTypesList>,
    'total' | 'results'
>): IIntermediateProductWithPreviewTypesResponse => ({
    items: results.map(
        ({
            id,
            name,
            code,
            technology: { name: technologyName },
            channel_intermediate_product_preview_types,
        }) => ({
            id,
            name,
            code,
            technologyName,
            suffixCount: Object.values(
                channel_intermediate_product_preview_types,
            ).length,
            channelIntermediateProductPreviewTypes:
                channel_intermediate_product_preview_types,
        }),
    ),
    totalItems: total,
});

export const mapChannelIntermediateProductPreviewsCreateToApi = ({
    formData: { intermediateProduct, previewTypeCode, priority },
}: IChannelIntermediateProductPreviewTypesForm) => ({
    intermediate_product: intermediateProduct,
    preview_type_code: previewTypeCode,
    priority: priority,
});
