import { makeReducerSelector } from 'utils/redux/makeSelectors';
import { MODULE_NAME } from '../../constants';

const selector = makeReducerSelector(MODULE_NAME, 'items');

export const selectItems = selector('itemsById');
export const selectItemsListComplete = selector('itemsListComplete');
export const selectItemsCount = selector('itemsCount');
export const selectItemsParam = selector('itemsParams');
export const selectItemsLoader = selector('loaders');
