import { HttpPatchHeaders, ApplicationJsonHeaders } from '../../constants';

export class User {
    constructor(services, { URLS }) {
        this.services = services;

        this.URLS = URLS;

        this.patchWorkplace = this.patchWorkplace.bind(this);
        this.getWorkplace = this.getWorkplace.bind(this);
    }

    /**
     * Patch default workplace
     */
    async patchWorkplace(workplaceId) {
        const {
            URLS: {
                PATCH_USER_WORKPLACE,
            },
            services: {
                ajax,
                authentication: {
                    getActiveToken,
                }
            }
        } = this;

        await ajax.patch({
            url: PATCH_USER_WORKPLACE.replace('{id}', getActiveToken().user_id),
            config: { headers: HttpPatchHeaders },
            data: {
                workplace: {
                    id: workplaceId
                }
            },
            errorConfig: { addGenericAlert: false },

        });
    }

    /**
     * Get default workplace
     */
    async getWorkplace() {
        const {
            URLS: {
                GET_USER_WORKPLACE,
            },
            services: {
                ajax,
                authentication: {
                    getActiveToken,
                }
            }
        } = this;

        const { data } = await ajax.get({
            url: GET_USER_WORKPLACE.replace('{id}', getActiveToken().user_id),
            config: { headers: ApplicationJsonHeaders },
            errorConfig: { addGenericAlert: false },
        });

        return data;
    }
}
