import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function CheckXlIcon(props) {
    return (
        <SvgIcon
            viewBox="-1 -4 24 24"
            { ...props }
        >
            <path
                d="M20.4837 0.991856C20.2672 0.998306 20.0617 1.08879 19.9108 1.24413L7.16666 13.9883L1.92251 8.74413C1.84573 8.66416 1.75375 8.6003 1.65198 8.55632C1.55021 8.51233 1.44068 8.48909 1.32981 8.48796C1.21894 8.48683 1.10897 8.50784 1.00632 8.54975C0.90367 8.59165 0.810416 8.65362 0.732017 8.73202C0.653618 8.81042 0.59165 8.90367 0.549743 9.00632C0.507835 9.10897 0.48683 9.21895 0.487958 9.32981C0.489086 9.44068 0.512324 9.55021 0.556312 9.65198C0.600299 9.75376 0.664152 9.84573 0.744129 9.92252L6.57746 15.7559C6.73375 15.9121 6.94568 15.9998 7.16666 15.9998C7.38763 15.9998 7.59956 15.9121 7.75585 15.7559L21.0892 2.42252C21.2096 2.30547 21.2918 2.15477 21.3251 1.99017C21.3584 1.82557 21.3412 1.65475 21.2758 1.50009C21.2103 1.34544 21.0997 1.21416 20.9584 1.12347C20.817 1.03278 20.6516 0.986905 20.4837 0.991856Z"
            />
        </SvgIcon>
    );
}

CheckXlIcon.defaultProps = {
    titleAccess: 'check-xl-icon',
};

CheckXlIcon.propTypes = svgIconPropTypes;
