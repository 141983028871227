import isEqual from 'lodash/isEqual';

export const mapPatternsGetList = ({ results, total }) => ({
    items: results.map(
        ({
            id,
            name = '',
            tags = [],
            previews_count: previewsCount = 0,
            categories = [],
            source = {},
            thumbnail_file: thumbnailFile = {},
            technology = {},
            author = {},
            code = '',
            overall_size: overallSize = '',
            techniques = '',
            parts = '',
            created_at: createdAt = '',
            dedicated_channels: channels = [],
            pattern_files: patternFiles = [],
            hex_additional_colors,
            hex_main_color,
        }) => ({
            id,
            name,
            tags,
            previewsCount,
            categories,
            source,
            thumbnailFile: {
                httpUrl: thumbnailFile?.http_url,
                id: thumbnailFile?.id,
            },
            technology,
            author: {
                firstName: author?.first_name,
                lastName: author?.last_name,
            },
            code,
            overallSize,
            techniques,
            parts,
            createdAt,
            channels,
            patternFiles: patternFiles?.map((patternFile) => ({
                id: patternFile?.id,
                file: patternFile?.file,
                description: patternFile?.description,
                isActive: patternFile?.is_active,
            })),
            hexAdditionalColors: hex_additional_colors,
            hexMainColor: hex_main_color,
        }),
    ),
    totalItems: total,
});

export const mapPatternsToApiForBatchEdit = (items = []) =>
    items.filter((item) => item && { id: item?.id });

export const mapPatternData = ({
    name,
    nameEn,
    description,
    descriptionEn,
    code,
    author,
    source,
    tags,
    categories,
    technology,
    printSpec,
    patternFiles,
    draftFiles,
    thumbnail,
    paintColorPalette,
    height,
    width,
    channels,
    baseColorPattern,
    baseTechnologyPattern,
    hexMainColor,
    hexAdditionalColors,
}) => {
    const mapped = {
        name,
        name_en: nameEn,
        description,
        description_en: descriptionEn,
        code: code === '' ? null : code,
        author: author?.id && { id: author.id },
        source: source?.id && { id: source.id },
        tags: tags,
        categories: categories,
        technology: technology?.id && { id: technology.id },
        print_spec: printSpec?.id && { id: printSpec.id },
        pattern_files: patternFiles,
        draft_files: draftFiles,
        thumbnail_file: thumbnail?.id && { id: thumbnail.id },
        palette_file: paintColorPalette?.fileId && {
            id: paintColorPalette.fileId,
        },
        height,
        width,
        dedicated_channels: channels,
        base_color_pattern: baseColorPattern?.id
            ? { id: baseColorPattern.id }
            : null,
        base_technology_pattern: baseTechnologyPattern?.id
            ? { id: baseTechnologyPattern.id }
            : null,
        intermediates_to_connect: [],
        hex_main_color: hexMainColor?.rals?.[0] && {
            code:
                // if the user does not change the RAL to UI we send the generatedPrecisionHex
                hexMainColor?.generatedPrecisionHex?.[0] ??
                hexMainColor.rals[0],
        },
        hex_additional_colors: hexAdditionalColors?.flatMap(
            ({ generatedPrecisionHex, rals }) =>
                // if the user does not change the RAL to UI we send the generatedPrecisionHex
                generatedPrecisionHex?.length
                    ? generatedPrecisionHex.map((code) => ({ code }))
                    : rals.map((code) => ({ code })),
        ),
    };

    Object.keys(mapped).forEach((key) => {
        if (mapped[key] === undefined) {
            delete mapped[key];
        }
    });

    return mapped;
};

export const mapExternalPatternData = (data, type = 'create') => {
    const {
        code,
        thumbnail_file,
        technology,
        print_spec,
        author,
        hex_main_color,
        hex_additional_colors,
    } = mapPatternData(data);

    const { external_producer } = data;

    if (type === 'create') {
        return {
            code,
            thumbnail_file,
            technology,
            print_spec,
            author,
            external_producer,
            hex_main_color,
            hex_additional_colors,
        };
    }

    return {
        thumbnail_file,
        technology,
        print_spec,
        author,
        external_producer,
        hex_main_color,
        hex_additional_colors,
    };
};

export const getDiffKeys = (prevVal, newVal) => {
    const diffKeys = [];

    Object.entries(newVal).forEach(([key, value]) => {
        if (!isEqual(value, prevVal?.[key])) diffKeys.push(key);
    });

    return diffKeys;
};

export const getDiffData = (diffKeys, patternData) => {
    return diffKeys.reduce((prevVal, key) => {
        if (patternData[key] !== undefined) {
            return {
                ...prevVal,
                [key]: patternData[key],
            };
        }

        return prevVal;
    }, {});
};
