import React from 'react';
import PropTypes from 'prop-types';
import { ClearIcon as CloseIcon } from 'components/Icons/Icons';
import withServices from 'hoc/withServices/withServices';
import { withSnackbar } from 'notistack';
import { ExitIconStyled } from './alertsBox.styles';
import {
    ALERTS_DEFAULT_TIMEOUT,
    ALERTS_ERROR_TIMEOUT,
    ALERTS_VARIANTS,
} from 'services/alert/constants';
import { withTranslation } from 'react-i18next';

export class AlertsBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
        };
    }

    componentDidMount() {
        this.props.services.alerts.subscribe('addAlert', this.addAlert);
    }

    displayed = [];

    translationStringRegex = /\S:\S/g; // matches any string with 'ns:key' pattern

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    addAlert = (alert) => {
        const key = new Date().getTime() + Math.random();

        this.setState({
            alerts: [...this.state.alerts, { ...alert, key }],
        });
    };

    removeAlert = (key) => {
        this.setState({
            alerts: this.state.alerts.filter((alert) => alert.key !== key),
        });
    };

    shouldComponentUpdate(nextProps, { alerts: newSnacks = [] }) {
        if (!newSnacks.length) {
            this.displayed = [];

            return false;
        }
        const { alerts: currentSnacks } = this.state;
        let notExists = false;

        for (let i = 0; i < newSnacks.length; i += 1) {
            const newSnack = newSnacks[i];

            if (newSnack.dismissed) {
                this.props.closeSnackbar(newSnack.key);
            }

            if (notExists) continue;
            notExists =
                notExists ||
                !currentSnacks.filter(({ key }) => newSnack.key === key).length;
        }

        return notExists;
    }

    componentDidUpdate() {
        const { t } = this.props;
        const { alerts } = this.state;

        alerts.forEach(({ key, message, options = {} }) => {
            if (this.displayed.includes(key)) return;

            if (this.translationStringRegex.test(message)) {
                message = t(message);
            }

            const isError = options?.variant === ALERTS_VARIANTS.ERROR;
            const autoHideDuration = isError
                ? ALERTS_ERROR_TIMEOUT
                : ALERTS_DEFAULT_TIMEOUT;

            this.props.enqueueSnackbar(message, {
                action: (key) => (
                    <ExitIconStyled
                        onClick={() => this.props.closeSnackbar(key)}
                        size="large"
                    >
                        <CloseIcon color="inherit" />
                    </ExitIconStyled>
                ),
                onExited: () => this.removeAlert(key),
                autoHideDuration,
                ...options,
            });
            this.storeDisplayed(key);
        });
    }

    render() {
        return null;
    }
}

AlertsBox.propTypes = {
    t: (key) => key,
};

AlertsBox.propTypes = {
    t: PropTypes.func,
    services: PropTypes.shape({
        alerts: PropTypes.shape({
            subscribe: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
    closeSnackbar: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
};

const AlertsBoxWithHOC = withTranslation()(
    withSnackbar(withServices(AlertsBox)),
);

export default AlertsBoxWithHOC;
