import * as Yup from 'yup';
import { ItemModel } from 'reducerConfigurator/itemModel/itemModel';
import moment from 'moment';

const getOrderSchema = (t = (key) => key) => Yup.object().shape({
    orderNumber: Yup.string()
        .required(t('app:validation.required'))
        .ensure()
        .trim()
        .max(255, t('app:validation.maxLength', { max: 255 })),
});

export class Order extends ItemModel {
    static channelOptions = [
        {
            label: 'haveaniceart',
            value: 'haveaniceart',
        },
        {
            label: 'trademax',
            value: 'trademax',
        },
        {
            label: 'arm_b2b',
            value: 'arm_b2b',
        },
        {
            label: 'allegro_artgeist_spzoo',
            value: 'allegro_artgeist_spzoo',
        },
        {
            label: 'amazon_murando',
            value: 'amazon_murando',
        },
        {
            label: 'amazon_canvasxxl',
            value: 'amazon_canvasxxl',
        },
        {
            label: 'haveaniceart_us',
            value: 'haveaniceart_us',
        },
        {
            label: 'tradebyte',
            value: 'tradebyte',
        },
        {
            label: 'ebay_premiumarte',
            value: 'ebay_premiumarte',
        }
    ];

    static statusOptions = [
        {
            label: 'canceled',
            value: 'canceled',
        },
        {
            label: 'error',
            value: 'error',
        },
        {
            label: 'pending',
            value: 'pending',
        },
        {
            label: 'collecting_information',
            value: 'collecting_information'
        },
        {
            label: 'exported',
            value: 'exported',
        },
        {
            label: 'shipped',
            value: 'shipped',
        },
    ];

    assignField = (value, defaultValue = '') => value || defaultValue;

    constructor(props = {}) {
        super(props);

        this.id = this.assignField(props.id, undefined);
        this.orderNumber = this.assignField(props.orderNumber);
        this.customerName = this.assignField(props.customerName);
        this.createdAt = this.assignField(props.created_at || props.createdAt);
        this.updatedAt = this.assignField(props.updated_at || props.updatedAt);
        this.channel = this.assignField(props.channel);
        this.status = this.assignField(props.status);
        this.customerEmail = this.assignField(props.customerEmail);
        this.customerNick = this.assignField(props.customerNick);
        this.shippingAddress = this.assignField(props.shippingAddress);
        this.billingAddress = this.assignField(props.billingAddress);
        this.customerPhone = this.assignField(props.customerPhone);
        this.totalPrice = this.assignField(props.totalPrice);
        this.balance = this.assignField(props.balance);
        this.purchaseDate = this.assignField(props.purchaseDate);
        this.taxId = this.assignField(props.taxId);
        this.orderNotes = this.assignField(props.orderNotes,[]);
        this.statusDetails = this.assignField(props.statusDetails);
        this.invoices = this.assignField(props.invoices, []);
    }

    static getSchema = getOrderSchema;

    getId = () => this.id && this.id.toString();
    getOrderNumber = () => this.orderNumber;
    getCustomerName = () => this.customerName;
    getCreatedAt = () => moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss');
    getUpdatedAt = () => moment(this.updatedAt).format('DD/MM/YYYY HH:mm:ss');
    getChannel = () => this.channel.name;
    getStatus = () => this.status;
    getCustomerEmail = () => this.customerEmail;
    getCustomerNick = () => this.customerNick;
    getShippingAddressNameLine = () => this.shippingAddress.name;
    getShippingAddressCompanyLine = () => this.shippingAddress.company;
    getShippingAddressStreetLine = () => this.shippingAddress.streetLine;
    getShippingAddressCityLine = () => [this.shippingAddress.postalCode, this.shippingAddress.city].join(' ');
    getBillingAddressNameLine = () => this.billingAddress.name;
    getBillingAddressCompanyLine = () => this.billingAddress.company;
    getBillingAddressStreetLine = () => this.billingAddress.streetLine;
    getBillingAddressCityLine = () => [this.billingAddress.postalCode, this.billingAddress.city].join(' ');
    getCustomerPhone = () => this.customerPhone;
    getTotalPrice = () => [this.totalPrice.amount, this.totalPrice.currency].join(' ');
    getBalance = () => [this.balance.amount, this.balance.currency].join(' ');
    getPurchaseDate = () => moment(this.purchaseDate).format('DD/MM/YYYY');
    getTaxId = () => this.taxId;
    getNoteBody = () => this.orderNotes;
    getStatusDetails = () => this.statusDetails;
    getInvoices = () => this.invoices;

    updateOrder = (newData = {}) => {
        const updateField = this.updateFieldFromNewData(newData);

        updateField('orderNumber');

        return this;
    };

    getOrderForPOSTRequest = () => {
        const orderObject = {};

        if (this.orderNumber) orderObject.orderNumber = this.orderNumber;

        return orderObject;
    };

    getOrderForPATCHRequest = () => {
        const orderObject = {};

        if (this.updatedFields.includes('orderNumber')) orderObject.orderNumber = this.orderNumber;

        return orderObject;
    };
    getOrderPlainObject = () => ({
        orderNumber: this.orderNumber,
    });
}
