import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { TrolleyIcon } from 'components/Icons/Icons';

const UnassignedBoxes = lazy(() => import('../containers/UnassignedBoxesContainer'));
const AllBoxes = lazy(() => import('modules/boxes/containers/AllBoxesContainer'));
const Box = lazy(() => import('modules/boxes/containers/BoxContainer'));
const BoxRuleManagement = lazy(() => import('../containers/BoxRuleManagementContainer'));
const BoxRuleDetailsContainer = lazy(() => import('../containers/BoxRuleDetailsContainer'));

export const boxesRouting = {
    name: MODULE_NAME,
    path: `/${ MODULE_NAME }`,
    routes: [
        {
            exact: true,
            name: 'unassignedBoxes',
            path: PATHS.UNASSIGNED_BOXES,
            component: UnassignedBoxes,
        },
        {
            name: null,
            path: PATHS.UNASSIGNED_BOX,
            component: Box,
            exact: true,
        },
        {
            exact: true,
            name: 'allBoxes',
            path: PATHS.ALL_BOXES,
            component: AllBoxes,
        },
        {
            name: null,
            path: PATHS.ALL_BOX,
            component: Box,
            exact: true,
        },
        {
            name: 'ruleManagement',
            path: PATHS.RULE_MANAGEMENT,
            component: BoxRuleManagement,
            exact: true,
        },{
            name: null,
            path: PATHS.RULE_MANAGEMENT_DETAILS,
            component: BoxRuleDetailsContainer,
            exact: true,
        },
    ],
    icon: TrolleyIcon
};
