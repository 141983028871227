import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import TextField from 'components/TextField/TextField';
import EntryPageTitle from 'modules/app/components/EntryPageTitle/EntryPageTitle';
import { ButtonStyled } from './sentChangePasswordForm.styles';
import Typography from '../../../../components/Typography';
import { ErrorStyled } from '../LoginForm/loginForm.styles';

class SentChangePasswordForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { requestChangePassword } = this.props;
        const { login } = this.state;

        requestChangePassword({
            login,
        });
    };

    render() {
        const { t, requestChangePasswordError } = this.props;
        const { login } = this.state;
        const fieldName = upperFirst(t('titles.loginAndEmail'));
        const showLoginError =
            typeof requestChangePasswordError === 'string' &&
            requestChangePasswordError !== '';

        return (
            <div>
                <EntryPageTitle
                    title={t('titles.passwordChange')}
                    subtitle={t('contents.changePassword')}
                />
                {showLoginError && (
                    <ErrorStyled>
                        <Typography
                            align="center"
                            color="error"
                        >
                            {t('alerts.requestChangePasswordError')}
                        </Typography>
                    </ErrorStyled>
                )}
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        fullWidth
                        id="email-login-input"
                        name="login"
                        value={login}
                        onChange={this.handleChange}
                        label={fieldName}
                        helperText={' '}
                    />
                    <ButtonStyled
                        fullWidth
                        color="primary"
                        type="submit"
                    >
                        {t('common:actions.sent')}
                    </ButtonStyled>
                </form>
            </div>
        );
    }
}

SentChangePasswordForm.defaultProps = {
    t: (key) => key,
};

SentChangePasswordForm.propTypes = {
    t: PropTypes.func,
    requestChangePassword: PropTypes.func.isRequired,
    requestChangePasswordError: PropTypes.string,
};

export default SentChangePasswordForm;
