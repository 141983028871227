import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { CardsIcon } from 'components/Icons/Icons';

const IntermediateProductsTablePage = lazy(() =>
    import('../pages/IntermediateProductsTablePage'),
);
const IntermediateProductDetailsPage = lazy(() =>
    import('../pages/IntermediateProductDetailsPage'),
);
const ProductsTablePage = lazy(() => import('../pages/ProductsTablePage'));
const PropertiesTablePage = lazy(() => import('../pages/PropertiesTablePage'));
const PropertyDetailsPage = lazy(() => import('../pages/PropertyDetailsPage'));
const TechnologiesTablePage = lazy(() =>
    import('../pages/TechnologiesTablePage'),
);
const TechnologyDetailsPage = lazy(() =>
    import('../pages/TechnologyDetailsPage'),
);
const TechnologiesGroupsTablePage = lazy(() =>
    import('../pages/TechnologiesGroupsTablePage'),
);
const TechnologyGroupDetailsPage = lazy(() =>
    import('../pages/TechnologyGroupDetailsPage'),
);
const EanPoolsTablePage = lazy(() => import('../pages/EanPoolsTablePage'));
const EanPoolDetailsPage = lazy(() => import('../pages/EanPoolDetailsPage'));
const EanPoolGtinsPage = lazy(() => import('../pages/EanPoolGtinsPage'));
const PrintSpecTablePage = lazy(() => import('../pages/PrintSpecTablePage'));
const PrintSpecDetailsPage = lazy(() =>
    import('../pages/PrintSpecDetailsPage'),
);

const ProductSetTableViewContainer = lazy(() =>
    import('../containers/ProductSetTableViewContainer'),
);
const ProductSetContainer = lazy(() =>
    import('../containers/ProductSetContainer'),
);

const ProductSetTypes = lazy(() =>
    import('../pages/ProductSetTypesPage').then((module) => ({
        default: module.ProductSetTypesPage,
    })),
);

const ProductSetPreviewTypesTable = lazy(() =>
    import('../pages/ProductSetPreviewTypesTablePage').then((module) => ({
        default: module.ProductSetPreviewTypesTablePage,
    })),
);

const ProductSetPreviewTypesDetails = lazy(() =>
    import('../pages/ProductSetPreviewTypesDetailsPage').then((module) => ({
        default: module.ProductSetPreviewTypesDetailsPage,
    })),
);

const ProductSetPreviewsTable = lazy(() =>
    import('../pages/ProductSetPreviewsTablePage').then((module) => ({
        default: module.ProductSetPreviewsTablePage,
    })),
);

const ExternalProducersTable = lazy(() =>
    import('../pages/ExternalProducersTablePage').then((module) => ({
        default: module.ExternalProducersTablePage,
    })),
);

const ExternalProducerDetals = lazy(() =>
    import('../pages/ExternalProducerDetailsPage').then((module) => ({
        default: module.ExternalProducerDetailsPage,
    })),
);

export const armRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            forceAccess: true,
            name: null,
            path: PATHS.PROPERTIES,
            component: PropertiesTablePage,
        },
        {
            exact: true,
            forceAccess: true,
            name: null,
            path: PATHS.PROPERTIES_DETAILS,
            component: PropertyDetailsPage,
        },
        {
            exact: true,
            name: 'technologies',
            path: PATHS.TECHNOLOGIES,
            component: TechnologiesTablePage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.TECHNOLOGIES_DETAILS,
            component: TechnologyDetailsPage,
        },
        {
            forceAccess: true,
            exact: true,
            name: 'technologies-groups',
            path: PATHS.TECHNOLOGIES_GROUPS,
            component: TechnologiesGroupsTablePage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.TECHNOLOGIES_GROUPS_DETAILS,
            component: TechnologyGroupDetailsPage,
        },
        {
            exact: true,
            name: 'intermediate-products',
            path: PATHS.INTERMEDIATE_PRODUCTS,
            component: IntermediateProductsTablePage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.INTERMEDIATE_PRODUCTS_DETAILS,
            component: IntermediateProductDetailsPage,
        },
        {
            exact: true,
            name: 'products',
            path: PATHS.PRODUCTS,
            component: ProductsTablePage,
        },
        {
            exact: true,
            name: 'ean-pools',
            path: PATHS.EAN_POOLS,
            component: EanPoolsTablePage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.EAN_POOL_DETAILS,
            component: EanPoolDetailsPage,
        },
        {
            exact: true,
            name: null,
            path: PATHS.EAN_POOL_GTINS,
            component: EanPoolGtinsPage,
        },
        {
            exact: true,
            name: 'print-spec',
            path: PATHS.PRINT_SPEC,
            component: PrintSpecTablePage,
        },
        {
            exact: true,
            path: PATHS.PRINT_SPEC_DETAILS,
            component: PrintSpecDetailsPage,
        },
        {
            exact: true,
            name: 'external-producers',
            path: PATHS.EXTERNAL_PRODUCERS,
            component: ExternalProducersTable,
        },
        {
            exact: true,
            path: PATHS.EXTERNAL_PRODUCER_DETAILS,
            component: ExternalProducerDetals,
        },
    ],
    submodules: [
        {
            name: `${MODULE_NAME}/product-set`,
            routes: [
                {
                    exact: true,
                    name: 'product-set-types',
                    path: PATHS.PRODUCT_SET_TYPES,
                    component: ProductSetTypes,
                },
                {
                    exact: true,
                    name: 'product-set',
                    path: PATHS.PRODUCT_SET,
                    component: ProductSetTableViewContainer,
                },
                {
                    exact: true,
                    name: 'product-set-preview-types',
                    path: PATHS.PRODUCT_SET_PREVIEW_TYPES,
                    component: ProductSetPreviewTypesTable,
                },
                {
                    exact: true,
                    name: null,
                    path: PATHS.PRODUCT_SET_PREVIEW_TYPES_DETAILS,
                    component: ProductSetPreviewTypesDetails,
                },
                {
                    exact: true,
                    name: null,
                    path: PATHS.PRODUCT_SET_DETAILS,
                    component: ProductSetContainer,
                },
                {
                    exact: true,
                    name: 'product-set-previews',
                    path: PATHS.PRODUCT_SET_PREVIEWS,
                    component: ProductSetPreviewsTable,
                },
            ],
        },
    ],
    icon: CardsIcon,
};
