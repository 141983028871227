import React from 'react';
import { SvgIcon } from './SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

export function ArrowLeftIcon(props) {
    return (
        <SvgIcon
            viewBox="-3 -2 16 16"
            { ...props }
        >
            <path
                d="M-9.1375e-05 6.01862C0.00102386 6.09335 0.0311375 6.16361 0.0835576 6.21603L5.794 11.9265C5.86538 12.0012 5.97245 12.0313 6.07171 12.0045C6.17209 11.9789 6.25016 11.9008 6.27582 11.8004C6.30258 11.7012 6.27247 11.5941 6.19774 11.5227L0.689177 6.01416L6.19774 0.505592C6.27247 0.434213 6.30258 0.327142 6.27582 0.227877C6.25016 0.127498 6.17209 0.0494269 6.07171 0.023774C5.97245 -0.00299375 5.86538 0.0271204 5.794 0.101847L0.0835577 5.81229C0.028907 5.86694 -0.00120661 5.94166 -9.1375e-05 6.01862Z"
            />
        </SvgIcon>
    );
}

ArrowLeftIcon.defaultProps = {
    titleAccess: 'arrow-left-icon',
};

ArrowLeftIcon.propTypes = svgIconPropTypes;
