export const voteConditionsPassed = ({ vote, allRoles }) => {
    if (!vote.conditions) {
        return true;
    }

    const { reqRoles } = vote.conditions;
    let allConditionsPassed = true;

    if (reqRoles) {
        let allReqRolesArePresent = true;

        reqRoles.forEach((reqRole) => {
            if (!allRoles.includes(reqRole)) {
                allReqRolesArePresent = false;

                return false;
            }
        });

        allConditionsPassed = allReqRolesArePresent;
    }

    return allConditionsPassed;
};
