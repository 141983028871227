import { makeReducerSelector } from 'utils/redux/makeSelectors';
import { MODULE_NAME } from '../constants';

const selector = makeReducerSelector(MODULE_NAME, 'userProfile');

export const selectIsLoggedIn = selector('isLoggedIn');
export const selectShowWorkplaceModal = selector('showWorkplaceModal');
export const selectLoginError = selector('loginError');
export const selectChangePasswordError = selector('changePasswordErrorCode');
export const selectRequestChangePasswordError = selector('requestChangePasswordError');
export const selectUserProfileLoaders = selector('loaders');
