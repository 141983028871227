class Document {
    constructor(prodisService, services, URLS) {
        this.prodisService = prodisService;
        this.services = services;
        this.URLS = URLS;
    }

    downloadDocument(id, fileName, contentType = 'application/pdf') {
        const { ajax } = this.services;
        const url = this.URLS.GET_DOWNLOAD_DOCUMENT.replace('{id}', id);

        const config = {
            headers: {
                'Content-Type': contentType,
                Accept: contentType,
            },
        };

        const errorConfig = {
            throwError: this.prodisService.errorHandlers.download,
            addGenericAlert: false,
        };

        return ajax.download({ url, fileName, config, errorConfig });
    }
}

export default Document;
