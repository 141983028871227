export const getRawActiveToken = () => {
    return localStorage.getItem('token');
};

export const removeActiveToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('access');
};

export const getAccess = () => {
    return JSON.parse(localStorage.getItem('access')) || {};
};

/*
    Refresh Token
 */
export const setRefreshToken = (token) => {
    localStorage.setItem('refresh_token', token);
};

export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
};

export const removeRefreshToken = () => {
    localStorage.removeItem('refresh_token');
};
