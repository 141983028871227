export const mapSortToOrder = (
    sort: Record<string, string>,
): Record<string, string> => {
    const orderEntries = Object.entries(sort).map(([key, value]) => [
        `order[${key}]`,
        value,
    ]);

    return Object.fromEntries(orderEntries);
};

export const mapGetListFilters = (
    filters: Record<string, string>,
): Record<string, string> => {
    if (Object.keys(filters).length) {
        return Object.entries(filters).reduce((calcValue, [key, value]) => {
            switch (key) {
                case 'technologyId':
                    return {
                        ...calcValue,
                        ...(value ? { technology_id: { $eq: value } } : {}),
                    };
                case 'suffix':
                    return {
                        ...calcValue,
                        pattern_suffix: { $match: value },
                    };
                case 'height':
                    return {
                        ...calcValue,
                        image_height: {
                            ...(typeof value === 'string'
                                ? { $gte: Number.parseInt(value, 10) }
                                : {}),
                        },
                    };
                case 'width':
                    return {
                        ...calcValue,
                        image_width: {
                            ...(typeof value === 'string'
                                ? { $gte: Number.parseInt(value, 10) }
                                : {}),
                        },
                    };
                case 'fileType':
                    return { ...calcValue, file_type: { $match: value } };
                case 'createdAtFrom': {
                    const after = Math.round(new Date(value).getTime() / 1000);

                    return {
                        ...calcValue,
                        ...(after
                            ? {
                                  created_at: {
                                      ...calcValue?.created_at,
                                      ...(after ? { $gt: after } : {}),
                                  },
                              }
                            : {}),
                    };
                }
                case 'createdAtTo': {
                    const before = Math.round(new Date(value).getTime() / 1000);

                    return {
                        ...calcValue,
                        ...(before
                            ? {
                                  created_at: {
                                      ...calcValue?.created_at,
                                      ...(before ? { $lte: before } : {}),
                                  },
                              }
                            : {}),
                    };
                }
                case 'description':
                case 'code':
                default: {
                    return { ...calcValue, [key]: { $match: value } };
                }
            }
        }, {});
    }

    return {};
};

export const mapGetListV2Filters = (
    filters: Record<string, string | string[]>,
): Record<string, string> => {
    if (Object.keys(filters).length) {
        return Object.entries(filters).reduce((calcValue, [key, value]) => {
            switch (key) {
                case 'technologyId':
                    return { ...calcValue, 'technology.id': value };
                case 'suffix':
                    return { ...calcValue, file_name_pattern: value };
                case 'height':
                    return { ...calcValue, 'height[gte]': value };
                case 'width':
                    return { ...calcValue, 'width[gte]': value };
                case 'fileType':
                    return { ...calcValue, file_type: value };
                case 'createdAt': {
                    const after = value[0];
                    const before = value[1];

                    return {
                        ...calcValue,
                        ...(after ? { 'created_at[after]': after } : {}),
                        ...(before ? { 'created_at[before]': before } : {}),
                    };
                }
                case 'description':
                case 'code':
                default: {
                    return { ...calcValue, [key]: value };
                }
            }
        }, {});
    }

    return {};
};
