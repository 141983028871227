import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ConfirmChangePassword from 'modules/app/components/ConfirmChangePassword/ConfirmPasswordChange';

const mapDispatchToProps = (dispatch) => {
    return {
        goToLogin: () => dispatch(push({
            pathname: '/',
        })),
    };
};

export default connect(null, mapDispatchToProps)(ConfirmChangePassword);
