import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';

import { ShopIcon } from 'components/Icons/Icons';

const OrdersTableViewPage = lazy(() => import('../pages/OrdersTableViewPage'));
const OrderDetailsPage = lazy(() => import('../pages/OrderDetailsPage'));

export const salesRouting = {
    name: MODULE_NAME,
    path: `/${ MODULE_NAME }`,
    routes: [
        {
            name: 'orders',
            path: PATHS.ORDERS,
            component: OrdersTableViewPage,
            exact: true,
        },
        {
            name: null,
            path: PATHS.ORDER_DETAILS,
            component: OrderDetailsPage,
            exact: true,
        },
    ],
    icon: ShopIcon
};
