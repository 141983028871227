import React from 'react';
import { SvgIcon } from 'components/Icons/SvgIconBase';
import { svgIconPropTypes } from 'utils/propTypes';

const ClockIcon = ({ titleAccess = 'clock-icon', ...rest }) => (
    <SvgIcon
        { ...rest }
        viewBox="-5 -3 24 24"
        titleAccess={ titleAccess }
    >
        <path
            d="M2.05435 0.965927C2.0394 0.968644 2.02446 0.97272 2.01087 0.976796C1.84918 1.01348 1.73505 1.15886 1.73913 1.32462V4.80288H5.21739C5.34239 4.80424 5.45924 4.73902 5.5231 4.63033C5.5856 4.52163 5.5856 4.38848 5.5231 4.27979C5.45924 4.17109 5.34239 4.10587 5.21739 4.10723H2.90217C4.2106 2.82191 6.00679 2.02027 8 2.02027C12.0571 2.02027 15.3043 5.26756 15.3043 9.32462C15.3043 13.3817 12.0571 16.629 8 16.629C3.94294 16.629 0.695652 13.3817 0.695652 9.32462C0.695652 8.13033 0.990489 6.96185 1.48913 5.96593L0.869565 5.65071C0.324728 6.74174 0 8.01484 0 9.32462C0 13.754 3.57065 17.3246 8 17.3246C12.4293 17.3246 16 13.754 16 9.32462C16 4.89527 12.4293 1.32462 8 1.32462C5.8356 1.32462 3.86685 2.19283 2.43478 3.58549V1.32462C2.43886 1.22408 2.3981 1.12761 2.32609 1.05968C2.25272 0.990383 2.15353 0.956416 2.05435 0.965927ZM7.65217 3.7594V8.7268C7.44429 8.84772 7.30435 9.06783 7.30435 9.32462C7.30435 9.3844 7.31114 9.44283 7.32609 9.49853L4.96739 11.8572L5.46739 12.3572L7.82609 9.99853C7.88179 10.0135 7.94022 10.0203 8 10.0203C8.38451 10.0203 8.69565 9.70913 8.69565 9.32462C8.69565 9.06783 8.55571 8.84772 8.34783 8.7268V3.7594H7.65217Z"
        />
    </SvgIcon>
);

ClockIcon.propTypes = svgIconPropTypes;

export default ClockIcon;
