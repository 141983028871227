import type { ComponentProps } from 'react';
import { TypographyStyled } from 'components/Typography/typography.styles';
import { useTranslation } from 'react-i18next';

interface TypographyWithTranslationProps extends ComponentProps<typeof TypographyStyled> {
    children?: string;
}

export const TypographyWithTranslation = ({
    children,
    ...restProps
}: TypographyWithTranslationProps) => {
    const { t } = useTranslation();
    const translation = t(children);

    return (
        <TypographyStyled { ...restProps } >
            {translation}
        </TypographyStyled>
    );
};
