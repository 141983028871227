import get from 'lodash/get';
import { fromJS } from 'immutable';
import moment from 'moment';

const getLength = (intermediateProduct, field) => {
    const value = get(intermediateProduct, field);

    return value ? value / 10 : 0;
};

const mapPropertyValuesFromApi = (propertyValues) => {
    const values = {};
    const deprecatedProperties = ['width', 'height', 'length', 'weight'];

    propertyValues
        .filter((propertyValue) => deprecatedProperties.indexOf(propertyValue.property.code) === -1)
        .forEach((property) => {
            const id = get(property, 'property.id');
            const name = get(property, 'property.name');
            const type = get(property, 'property.type');
            const value = get(property, 'value');
            const code = get(property, 'property.code');
            const propertyValue = {
                id,
                name,
                type,
                code,
                value,
            };

            if (type === 'number') {
                const defaultUnitCode = 'ml';
                const unitCode = get(property, 'property.options.unit', '');

                if (unitCode !== null) {
                    propertyValue.unit = get(property, 'property.options.unit', '');
                } else {
                    propertyValue.unit = defaultUnitCode;
                }
            }
            values[code] = propertyValue;
        });

    return values;
};

const mapComponentsFromApi = (components, mode = 'create') => {
    return components.map((component) => {
        const personalName = get(component, 'group_component.name');

        return {
            id: get(component, 'group_component.id'),
            personalName: get(component, 'group_component.name'),
            required: get(component, 'group_component.required'),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            selectedIntermediateProduct: mapIntermediateProductFromApi(
                get(component, 'component'),
            ),
            // At creation, data is taken from /api/v2/intermediate_products and technologyId is available in group_component/component/id.
            // On the other hand, when downloading data for editing from /api/intermediate_product_groups,
            // in API the nomenclature has been changed and the technology is not a technology but has been hidden in
            // group_component/component/group_components/FindSomehowYourComponent/component
            technologyId:
                mode !== 'create'
                    ? get(
                          component,
                          'group_component.component.group_components',
                      )?.find(({ name }) => {
                          return name === personalName;
                      })?.component
                    : get(component, 'group_component.component.id'),
            technologyName: get(component, 'group_component.component.name'),
            technologyCode: get(component, 'group_component.component.code'),
            technologyDescription: get(
                component,
                'group_component.component.description',
            ),
        };
    });
};

const getDisplayPrice = (intermediateProduct) => {
    const price = get(intermediateProduct, 'price');

    return price ? price / 100 : price;
};

export const mapIntermediateProductFromApi = (intermediateProduct, mode = 'create') => {
    const allPropertyValues = mapPropertyValuesFromApi(get(intermediateProduct, 'property_values', []));
    const { color, manual, ...propertyValues } = allPropertyValues;
    const createdAt = moment(get(intermediateProduct, 'created_at')).format('DD/MM/YYYY');

    const height = getLength(intermediateProduct, 'height');
    const width = getLength(intermediateProduct, 'width');

    return {
        id: get(intermediateProduct, 'id'),
        code: get(intermediateProduct, 'code'),
        createdAt,
        parts: get(intermediateProduct, 'parts'),
        name: get(intermediateProduct, 'name'),
        componentsDescription: get(intermediateProduct, 'components_description', ''),
        width,
        height,
        weight: get(intermediateProduct, 'weight'),
        size: `${width} cm x ${height} cm`,
        technology: get(intermediateProduct, 'technology'),
        components: mapComponentsFromApi(get(intermediateProduct, 'components', []), mode),
        price: getDisplayPrice(intermediateProduct),
        length: getLength(intermediateProduct, 'length'),
        description: get(intermediateProduct, 'description'),
        isRawMaterial: get(intermediateProduct, 'raw_material'),
        property_values: get(intermediateProduct, 'property_values', fromJS([])),
        propertyValues,
        shippingBox: get(intermediateProduct, 'shipping_box'),
        internalBox: get(intermediateProduct, 'internal_box'),
        accessories: intermediateProduct?.accessories || [],
    };
};

export const mapTechnologyComponentsFromApi = (components) => {
    return components.map((component) => ({
        id: get(component, 'id'),
        personalName: get(component, 'name'),
        required: get(component, 'required'),
        technologyId: get(component, 'component.id'),
        technologyName: get(component, 'component.name'),
        technologyCode: get(component, 'component.code'),
        technologyDescription: get(component, 'component.description'),
    }));
};

export const mapBoxUnassignmentToApi = ({ removeFrom }) => ({
    [removeFrom]: null,
});
